import React, { useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import { META_DESCRIPTION, SECONDARY_COLOR } from '../../../../../utils/constants'
import { Layout, Space, Typography } from 'antd'
import './assets/css/application-details.scss'
import { PaperClipOutlined } from '@ant-design/icons'
import ClientJobDetails from '../../../common/private/job-post/components/client-details'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { convertBudget } from '../../../common/private/job-post/utils/helper'
import { capitalizeFirstLetter, truncateText } from '../../../../../utils/helper'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { useEffect } from 'react'
import PageFallback from '../../../../components/page-fallback'
import ROUTES from '../../../../../utils/routes'
import PageHeaderWrapper from '../../../../components/page-header-breadcrumbs'
import WithdrawModal from './components/withdraw-modal'
import JobInfo from './components/job-info'


const {Content} = Layout
const {Paragraph, Title, Text} = Typography

const JobApplicationDetails = () => {
    const tech = useSelector(state => state.tech)
    const [visible, setVisible] = useState(false)
    const [loadingDetails, setLoadingDetails] = useState(false)
    const [appliedJobDetails, setAppliedJobDetails] = useState(null)
    const [jobApplication, setJobApplication] = useState(null)
    const [loadingApplication, setLoadingApplication] = useState(false)
    const [applicationAttachments, setApplicationAttachments] = useState([])
    const location = useLocation()
    const  history = useHistory()
    const { id } = useParams()

    const { jobPostingId, action } = location?.state

    const fetchAppliedJobDetails = () => {
        setLoadingDetails(true)
        ApiBaseHelper.get({
            url: `job-post/${jobPostingId}`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setAppliedJobDetails(res?.data?.data[0])
            setLoadingDetails(false)
        }).catch((error) => {
            console.error(error)
            setLoadingDetails(false)
        })       
    }

    const fetchJobApplication = () => {
        setLoadingApplication(true)
        ApiBaseHelper.get({
            url: `job-post/applications`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setJobApplication(res?.data?.data.find((item) => item?.id === id))
            setLoadingApplication(false)
        }).catch((error) => {
            console.error(error)
            setLoadingApplication(false)
        })       
    }


    const fetchApplicationAttachments = () => {
        ApiBaseHelper.get({
            url: `job-post/media/${id}`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setApplicationAttachments(res?.data?.data)
        }).catch((error) => {
            console.error(error)
        })       
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        fetchJobApplication()
        fetchApplicationAttachments()
    }, [id])

    useEffect(() => {
        fetchAppliedJobDetails()
    }, [jobPostingId])

    return (
        <>
            <MetaTags>
                <title> Job Application Details - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <PageHeaderWrapper
                routes={[
                    {
                        path: ROUTES.MANAGE_JOB_APPLICATIONS,
                        breadcrumbName: 'My Applications'
                    },
                    {
                        breadcrumbName: truncateText(capitalizeFirstLetter(appliedJobDetails?.Title), 22)
                    },
                ]}
            />
            <Content className="main">
                <div className='page'>
                    { 
                        (loadingDetails || loadingApplication) ? <PageFallback /> :
                        <div className='auth-container application-details-ctn'>
                            <div className='application-details-header'>
                                <Title level={3}>Application Details</Title>
                            </div>
                            <div className="application-details-content">
                                <JobInfo jobDetails={appliedJobDetails}/>
                                <div className='client-job-details'>
                                    <ClientJobDetails clientDetails={appliedJobDetails?.custom_data}/>
                                </div>
                                { (action === "view") &&
                                    <div className="budget-terms">
                                        <Title level={4}>Your budget terms</Title>
                                        <Paragraph style={{fontSize:14, color:"rgba(0, 0, 0, 0.65)"}}>It will be shown to client in your proposal</Paragraph>
                                        <Paragraph style={{fontSize:12, fontWeight:500}}>{convertBudget(jobApplication?.Payment_Amount, jobApplication?.Job_Posting?.Payment_Type)}</Paragraph>
                                        <Paragraph style={{fontSize:14, marginBottom:34, color:"rgba(0, 0, 0, 0.65)"}}>+20%  service fee</Paragraph>
                                    </div>
                                }                                
                                <div className="your-application">
                                    <Title level={4}>Your Application</Title>
                                    {
                                        jobApplication?.Cover_Letter.split('\n').map((line, index) => (
                                            <Paragraph style={{fontSize:14, lineHeight:"22px"}} key={index}>
                                              {line}
                                            </Paragraph>
                                        ))
                                    }
                                </div>
                                <div className="application-attachments">
                                    {
                                        applicationAttachments?.data?.length > 0 &&
                                        applicationAttachments?.data?.map((item, index) => {
                                            return(
                                                <div className='mb-1' key={item?.id}>
                                                    <Space align='center' className="download-cv">
                                                        <PaperClipOutlined style={{color:"rgba(0, 0, 0, 0.45)", position:"relative", top:2}}/>
                                                        <a href={item?.Body} style={{color:SECONDARY_COLOR, textDecoration:"none"}}>{item?.Title}</a>
                                                    </Space>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    }      
                </div>
            </Content>
            <WithdrawModal 
                visible={visible}
                setVisible={setVisible}
                jobApplication={jobApplication}
                fetchData={fetchJobApplication}
            />
        </>
    )
}

export default JobApplicationDetails