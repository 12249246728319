import { Input, List, Skeleton, Typography } from 'antd';
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { updateGlobalUnReadNotifications, updateInAppNotifications } from '../../../../../redux/reducers/app';
import useNotification from '../../../../components/notification-modal/hooks/useNotification';
import moment from 'moment';
import { TECME_LOGO } from '../../../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import BellIcon from '../../../../components/notification-modal/assets/icons/bell-icon.svg';

import './css/styles.css'
import { useEffect } from 'react';
import NotificationTimestamp from '../../../../components/notification-modal/notification-timestamp';
import SearchInput from '../../../../components/search-input';
import { capitalizeFirstLetter, getTableTimeFormat } from '../../../../../utils/helper';
import { decode } from 'html-entities';



const {Search} = Input;
const {Paragraph, Title} = Typography

const AccountNotifications = () => {
    const {numOfUnreadNotifications, handleReadNotification, handleReadAllNotifications, loadingNotifications } = useNotification()
    const {unReadNotifications, notificationData} = useSelector(state => state.inAppNotifications)
    const history = useHistory()
    const dispatch = useDispatch()

    const [filteredNotifications, setfilteredNotifications] = useState()

    useEffect(() => {
        setfilteredNotifications(notificationData)
    }, [notificationData])

  return (
    <div className='settings-tab-ctn'>
        <div className='account-notifications-heading'>
            <div>
                <Title level={4} style={{color:"#101828", fontSize:18, marginBottom:4}}>Notification history</Title>
                <Paragraph style={{color: "rgba(0, 0, 0, 0.65)", margin:0}}>A comprehensive history of all your notifications, allowing you to review past updates and actions.</Paragraph>
            </div>
            {
                notificationData?.length > 0 &&
                <div style={{minWidth: '300px', width: '30%', margin: '1rem 0'}}>
                    <SearchInput
                        placeholder="Search Notifications"
                        onChange={(e) => {
                            // setSearchTerm(e.target.value)
                            let filterResults =  notificationData.filter((ele) => (ele?.Notification.toLowerCase().includes(e.target.value)))
                            setfilteredNotifications(filterResults)
                            if(e.target.value == ""){
                                setfilteredNotifications(notificationData)
                            }
                        }}
                    />
                </div>
            }
        </div>
        <div>
            {
                loadingNotifications ?
                [0, 1, 2, 3].map((ele) => 
                    <div className='notification-item'>
                        <Skeleton avatar paragraph={{ rows: 1 }} active />
                    </div>  
                ) :
                notificationData?.length  === 0 ?
                <div className='empty-notification'>
                    <div>
                        <img src={BellIcon} alt="bell-icon" />
                    </div>
                    <p>No Notifications yet</p>
                </div> :
                <div>
                <List
                    dataSource={filteredNotifications}
                    pagination={{
                        total: filteredNotifications?.length,
                        defaultPageSize:8
                    }}
                    renderItem={
                        item => {
                            const formattedNotification = (notificationDetails) => {
                                const notificationArr = notificationDetails?.split(" ")
                                const getUTCDate = notificationArr?.filter(item => item.includes("UTC_TIME"))[0]
                                const UTCDateIndex = notificationArr.indexOf(getUTCDate)
                                const convertedUTCDate = getTableTimeFormat(getUTCDate?.replace("UTC_TIME:", ""))
                                if(UTCDateIndex !== -1){
                                    notificationArr[UTCDateIndex] = convertedUTCDate
                                }

                                return capitalizeFirstLetter(notificationArr.join(" ")) 
                            }
                            return(
                            <List.Item>
                                <div className='account-notification-item' key={item?.id} onClick={() =>
                                {
                                    history.push(`${item?.URL}`)
                                    handleReadNotification(item?.id)
                                    if(item?.Read === "0"){
                                        dispatch(updateInAppNotifications({unReadNotifications: numOfUnreadNotifications - 1,notificationData:notificationData}))
                                    }
                                }}>
                                    <div className='notification-info'>
                                        <div className='notification-avatar-details'>
                                            <div>
                                                {
                                                    item?.Picture !== null ? 
                                                    <div className='notification-avatar'>
                                                        <img src={item?.Picture} alt={"avatar"} />
                                                    </div> :
                                                    <div>
                                                        <div className='notification-avatar'>
                                                            <img src={TECME_LOGO} alt={"tecme logo"} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className='account-notification-details'>
                                                <p>{item?.Title ? item?.Title : "Notification"}</p>
                                                <p>{item?.Notification.includes("UTC_TIME") ? formattedNotification(decode(item?.Notification)) : capitalizeFirstLetter(decode(item?.Notification))} <span> - <NotificationTimestamp timeStamp = {item?.Timestamp}/></span> </p>
                                                
                                            </div>
                                        </div>
                                        <div className={`status-badge ${(item?.Read !== "0" || unReadNotifications == 0) && 'no-status-badge'}`}></div>
                                    </div>
                                </div> 
                            </List.Item>
                        )}
                    }
                /> 
                 { 
                    ( unReadNotifications === 0 ?
                        <div  className='account-mark-all disabled-mark'>Mark all as read</div> :
                        <div  className='account-mark-all' onClick={() => {
                            handleReadAllNotifications()
                            dispatch(updateInAppNotifications({unReadNotifications:0, notificationData:notificationData}))
                            }}>{"Mark all as read"}
                        </div>)
                    }
                </div>   
            }
        </div>
       
    </div>
  )
}

export default AccountNotifications