import React, { useState } from 'react'
import { Modal, Typography, Form, Input, Button, Radio, Space, notification } from 'antd'
import { useEffect } from 'react'
import { BulbTwoTone } from '@ant-design/icons'
import { SECONDARY_COLOR } from '../../../../../../utils/constants'
import BackIcon from '../assets/images/back-icon.svg';
import AiIcon from '../assets/images/ai-icon.svg';
import { generateAiResponse } from '../../../../../../utils/openai-helper'
import { useSelector } from 'react-redux'


const {Title, Text} = Typography

const AiChatModal = ({openAIModal, onCloseAIModal, showAIResults, chatAiText, setChatAiText, setShowAIResults, handleSendMessage, chatUser}) => {
    const [loadingGeneratedChat, setLoadingGeneratedChat] = useState(false)
    const client = useSelector((state) => state.user)
    const tech = useSelector((state) => state.tech)
    const [form] = Form.useForm()

    const activeUser = client || tech


    const handleGenerateChatWithAI = async (values) => {   
      
        const prompt = `generate a chat message ${chatUser ? `addressing ${chatUser}` :  ""}  ${values?.tone ? `with ${values?.tone} tone` :  ""} in one paragraph and less than 100 words for the following: ${values.keywords}`

        setLoadingGeneratedChat(true)
        const response = await generateAiResponse(prompt, activeUser?.token)
        setLoadingGeneratedChat(false)
        if(response){
            // setJobPostingData({...jobPostingData, job_description: response?.content})
            setShowAIResults(true)
            form.setFieldsValue({
                "ai-chat": response?.content,
                keywords: values?.keywords,
                tone: values?.tone
            })
            setChatAiText(response?.content)
        }else{
            notification.error({
                message: "TecMe",
                description: "We are unable to generate a response. Please try again."
            })
        }
    }

    useEffect(() => {
        if(openAIModal === false){
            form.setFieldsValue({
                "ai-chat": "",
                keywords: "",
                tone: ""	
            })
        }
    }, [openAIModal])

  return (
    <Modal
        title={
            <Space>
                <img src={AiIcon} alt="ai icon"  style={{width:21}}/>
                <Title level={4} style={{marginBottom:0}}>Generate Chat with AI</Title>
            </Space>
        }
        className='ai-log-modal'
        visible={openAIModal}
        onOk={() => {}}
        onCancel={() => {
            onCloseAIModal()
        }}
        width={550}
        footer={
            !showAIResults ? [
            <Button key="back" type='secondary' onClick={() => onCloseAIModal()}>
            Not Now
            </Button>,
            <Button 
                key="submit" 
                type="primary" 
                htmlType='submit' 
                form='generate-chat-with-ai-form'
                loading={loadingGeneratedChat}
            >
                Generate
            </Button>,
        ] :
        [
            <Button key="back" type='secondary' onClick={() => {
                onCloseAIModal()
            }}>
                Cancel
            </Button>,
            <Button 
                key="submit" 
                type="primary"
                // loading={loading} 
                onClick={() => {
                    handleSendMessage()
                    onCloseAIModal()
                }}
            >
                Send as chat
            </Button>,
        ]
        }
    >
        {
            !showAIResults ? (
                <Form
                    form={form}
                    name='generate-chat-with-ai-form'
                    layout='vertical'
                    onFinish={handleGenerateChatWithAI}
                >
                    <Form.Item
                        label="Write some keywords or give some hints for the chat message"
                        name="keywords"
                        rules={[{ required: true, message: 'Please input some keywords' }]}
                        style={{marginBottom:30}}
                    >
                        <Input.TextArea 
                            rows={4} 
                            placeholder="Let's talk about the project, position, or support need."
                            maxLength={500}
                            showCount
                        />
                    </Form.Item>
                    <Form.Item
                        label="Tone of voice"
                        name="tone"   
                    >
                        <Radio.Group
                            
                        >
                            <Space size={10} direction='vertical'>
                            {
                                ["Expert", "Supportive", "Casual", "Professional"].map((tone) => {
                                    return(
                                        <Radio value={tone}>{tone}</Radio>
                                    )
                                })
                            }
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            ) : (
                <div>
                    <Space className='mb-4 cursor-pointer' onClick={() => setShowAIResults(false)}>
                        <img src={BackIcon} alt="back icon" />
                        <Text style={{fontSize:14, color:"#000", fontWeight:300}}>Back</Text>
                    </Space>
                    <Form
                        form={form}
                        name='ai-chat-form'
                        layout='vertical'
                        // onFinish={handleGenerateChatWithAI}
                    >
                        <Form.Item
                            label="AI generated chat:"
                            name="ai-chat"
                        >
                            <Input.TextArea 
                                rows={8} 
                                style={{color:"#000"}}
                                onChange={(e) => {setChatAiText(e?.target?.value)}}
                            />
                        </Form.Item>
                    </Form>
                    <Space align='center'>
                        <BulbTwoTone twoToneColor={SECONDARY_COLOR} style={{fontSize:12}} />
                        <Text style={{fontSize:12, color:"#000", fontWeight:300}}>Note: You can modify the text above before sending it as a chat message</Text>
                    </Space>
                </div>
            )
        }
    </Modal>
  )
}

export default AiChatModal