import { Checkbox, Collapse, Modal, Tag, Typography, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import '../assets/css/edit-categories.scss'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper';
import { useSelector } from 'react-redux';
import { truncateText } from '../../../../../../utils/helper';


const { Title, Paragraph, Text } = Typography
const { Panel } = Collapse;

const EditCategoriesModal = ({showEditCategoriesModal, setShowEditCategoriesModal, workCategoriesData, fetchWorkCategories, screenWidth}) => {
  const tech = useSelector((state) => state.tech);
  const { token } = tech

  const [categorySkillsData, setCategoriesSkillsData] = useState([])
  const [loadingSkills, setLoadingSkills] = useState(false)
  const [allSelectedSubCategories, setAllSelectedSubCategories] = useState([])
  const [formattedSubCategories, setFormattedSubCategories] = useState([])
  const [checkedSkills, setCheckedSkills] = useState([])
  const [updatingCategory, setUpdatingCategory] = useState(false)
  const [allAvailableSkills, setAllAvailableSkills] = useState([])
  const [allInitialSkills, setAllInitialSkills] = useState([])

  const fetchCategorySkills = () => {
    setLoadingSkills(true)
    ApiBaseHelper.get({
        url: `work-categories`, 
        headers: {
            Authorization: 'Bearer ' + token
        }
    }).then((res) => {
        setCategoriesSkillsData(res.data)
        setLoadingSkills(false)
    }).catch((error) => {
        console.error(error)
        setLoadingSkills(false)
    })       
  }


  // handle update selected work categories and skills by technician
  const handleUpdateWorkCategories = () => {
    setUpdatingCategory(true)
    ApiBaseHelper.put({
        url: `work-categories`, 
        payload: formattedSubCategories,
        headers: {
            Authorization: 'Bearer ' + token,
        },
    }).then((res) => {
        if(res){
          setShowEditCategoriesModal(false)
            notification['success']({
                message:"TecMe",
                description:"Work Categories updated successfully"
            })
            fetchWorkCategories()
            setUpdatingCategory(false)
        }
    }).catch((error) => {
        console.log(error)
        setUpdatingCategory(false)
        notification['error']({
            message: "TecMe",
            description:"An Error Occurred"
        })
    })
  }
  
  // handle tag close
  const handleTagClose = (id) => {
    const updatedSubCategories = allSelectedSubCategories.filter((item) => item.sub_category_id !== id);
    setAllSelectedSubCategories(updatedSubCategories);
    
    if(allAvailableSkills.length > 0) {
      const checked = allAvailableSkills.reduce((acc, curr) => {
        acc[curr] = [].concat(...updatedSubCategories.map(obj => obj.sub_category_id)).includes(curr);
        return acc;
      }, []);
      setCheckedSkills(checked)
      
    }
  };

  useEffect(() => {
    fetchCategorySkills()
  }, [])

  // set and display all selected skills to initial skills
  useEffect(() => {
    const newSelectedSkills = [];
    workCategoriesData.forEach((category) => {
      category.Child_Categories.forEach((sub_category) => {
        const isSkillSelected = allSelectedSubCategories.some(
          (selectedSkill) =>
            selectedSkill.sub_category_id === sub_category?.id &&
            selectedSkill.sub_category_title === sub_category?.Title
        );
        if (!isSkillSelected) {
          newSelectedSkills.push({
            sub_category_id: sub_category?.id,
            sub_category_title: sub_category?.Title,
          });
        }
      });
    });
    setAllSelectedSubCategories((prevSelectedSkills) => [...prevSelectedSkills, ...newSelectedSkills]);
  }, [workCategoriesData]);

  // format selected skills into an array of objects with parent_id and skills
  // useEffect(() => {
  //   const formatSkills = allSelectedSubCategories.reduce((acc, {skill, parent_id}) => {
  //     const group = acc.find((group) => group.id === parent_id);
  //     if (group) {
  //       group.Skills.push(skill);
  //     } else {
  //       acc.push({id: parent_id, Skills: [skill]});
  //     }
  //     return acc;
  //   }, []);
  //   setFormattedSkills(formatSkills)
  // }, [allSelectedSubCategories])

  // format selected sub categories into an array of sub category ids
  useEffect(() => {
    const formatSubCategories = []
    allSelectedSubCategories.map((item) => {
      formatSubCategories.push(item.sub_category_id)
    })
    setFormattedSubCategories(formatSubCategories)
  }, [allSelectedSubCategories])

  useEffect(() => {
    if(categorySkillsData.length > 0) {
      let all_sub_categories = []
      categorySkillsData.map((category) => {
        all_sub_categories.push([{
          id: category.id,
          Name: null,
          Active: true,
          Description: null,
          Organization_Info: category.Child_Categories[0]?.Organization_Info,
          Parent_Category: category?.id,
          Title: category.Title,
      }, ...category.Child_Categories].map((sub_category) => sub_category.id))
      })
      setAllAvailableSkills(all_sub_categories.flat())
    }
  }, [categorySkillsData])

  useEffect(() => {
    if(workCategoriesData?.length > 0) {
      let all_sub_categories = []
      workCategoriesData.map((category) => {
        all_sub_categories.push(category.Child_Categories.map((sub_category) => sub_category.id))
      })
      setAllInitialSkills(all_sub_categories.flat())
    }
  }, [workCategoriesData])

  useEffect(() => {
    if(allAvailableSkills.length > 0) {
      const checked = allAvailableSkills.reduce((acc, curr) => {
        acc[curr] = allInitialSkills.includes(curr);
        return acc;
      }, []);
      setCheckedSkills(checked)
    }
    
  }, [allInitialSkills, allAvailableSkills])

  let totalSubCategories = 0;

  return (
    <Modal
        className='edit-cat-modal'
        open={showEditCategoriesModal} 
        okText="Save"
        onOk={() => {
          handleUpdateWorkCategories()
        }} 
        onCancel={() => {
            setShowEditCategoriesModal(false)
        }}
        okButtonProps={{loading: updatingCategory}}
        cancelButtonProps={{type:'secondary'}}
        width={900}
        centered
    >
      <div className="edit-cat-title">
        <Title level={4} style={{color:"#000000"}}>Categories</Title>
        <Title level={5} style={{color:"#000000"}}>What categories of IT work do you have experience in?</Title>
      </div>
      <div className='selected-skills-tags'>
        <Paragraph style={{fontWeight:500, color:"#7A7A7D", fontSize:16}}>Select up to 10 categories</Paragraph>
        {
          allSelectedSubCategories.map((item) => (
            <Tag 
              closable 
              key={item.sub_category_id}
              onClose={() => handleTagClose(item.sub_category_id)}
            >
              {screenWidth > 420 ? item?.sub_category_title  : truncateText(item?.sub_category_title, 30)}
            </Tag>
          ))
        }
      </div>
      <div className="categories-collapse">
          <Collapse accordion>
          {categorySkillsData.map((category, categoryIndex) => {
            const handleCheckboxChange = (sub_category_id, subCategoryIndex) => (e) => {
              setCheckedSkills((prev) => ({
                ...prev,
                [sub_category_id]: e.target.checked,
              }));
              let currentChildCategory = [{
                id: category.id,
                Name: null,
                Active: true,
                Description: null,
                Organization_Info: category.Child_Categories[0]?.Organization_Info,
                Parent_Category: category?.id,
                Title: category.Title,
            }, ...category.Child_Categories].find((item) => item.id === sub_category_id)
      
    
              if(e.target.checked) {
                setAllSelectedSubCategories(allSelectedSubCategories => [...allSelectedSubCategories, {sub_category_id:sub_category_id, sub_category_title:currentChildCategory?.Title}])
              } else {
                setAllSelectedSubCategories(allSelectedSubCategories.filter((item) => item.sub_category_id !== sub_category_id))
              }
            };
            
            totalSubCategories += category.Child_Categories.length;
            return (
              <Panel header={category.Title} key={category.id}>
                {[{
                id: category.id,
                Name: null,
                Active: true,
                Description: null,
                Organization_Info: category.Child_Categories[0]?.Organization_Info,
                Parent_Category: category?.id,
                Title: category.Title,
            }, ...category.Child_Categories].map((sub_category, subCategoryIndex) => {
                  // const index = totalSubCategories - category.Child_Categories.length + subCategoryIndex;
            
                  return (
                  <div className='categories-checkbox' key={sub_category?.id}>
                    <Checkbox 
                    className={`${sub_category?.id === category?.id && 'parent-categories-checkbox-item'}`}
                    onChange={handleCheckboxChange(sub_category?.id, subCategoryIndex)} 
                    checked={checkedSkills[sub_category?.id]}
                    disabled={allSelectedSubCategories.length >= 10 && !checkedSkills[sub_category?.id]}
                    >
                      {sub_category?.Title}
                    </Checkbox>
                  </div>
                  )
              })}
              </Panel>
            );
          })}
        </Collapse>
      </div>
    </Modal>
  )
}

export default EditCategoriesModal