import { Button, Result, Typography } from 'antd'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ROUTES from '../../../../../utils/routes'
import { useDispatch } from 'react-redux'
import { updateUser } from '../../../../../redux/reducers/app'

const { Title, Paragraph } = Typography

const SuccessOnboarding = ({user}) => { 

    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateUser({
            ...user,
            new_signup: false
        }))
    }, [])

    return (
        <div className="success-onboarding-section">
            <Result
                className='success-onboarding-result'
                status="success"
                title={<Title level={3}>Congratulations!</Title>}
                subTitle={<Paragraph className='success-onboarding-text' >We are thrilled to have you with us. To kickstart your journey, click the button below and explore our platform. </Paragraph>}
                extra={[
                    <Button 
                        type="primary" 
                        htmlType="submit" 
                        style={{height:40, fontSize:16}} 
                        onClick={() => { 
                            history.push(ROUTES.DASHBOARD)
                            dispatch(updateUser({
                                ...user,
                                new_signup: false
                            }))
                        }}
                    >
                        Explore the platform
                    </Button>
                    
                ]}
            />
        </div>
    )
}

export default SuccessOnboarding