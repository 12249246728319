import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { ContractRepository } from "../repository"
import Moment from 'moment'
import {extendMoment} from 'moment-range';
const moment = extendMoment(Moment);

export const useTimeSheet = (contractId, updatedTimesheetData,setUpdatedTimesheetData,setTimeSpent,upComingInvoice) => {
    const user = useSelector(state => state.user)
    const tech = useSelector(state => state.tech)
    const admin = useSelector(state => state.admin)
    const token = user?.token || tech?.token || admin?.token
    const [timeSheetData, setTimeSheetData] = useState([])
    const [filteredTimeSheetData, setFilteredTimeSheetData] = useState([])
    const startOfBillingWeek = upComingInvoice ? moment.unix(upComingInvoice.next_payment_attempt).subtract(1, 'week') :  moment().startOf('week');
    const endOfBillingWeek = upComingInvoice ? moment.unix(upComingInvoice.next_payment_attempt) : moment().endOf('week');
    const repository = new ContractRepository(token)

    const getTimeSheetData = () =>{

        repository?.getTimesheets(contractId).then(async res => {
            if (res.data?.data?.length) {
                const sortedTimesheetData = [...res?.data.data]
                sortedTimesheetData?.sort((a, b) => moment(b.Clock_Out_Date_Time).local().diff(moment(a.Clock_Out_Date_Time).local()))
                setTimeSheetData(sortedTimesheetData)
                // setUpdatedTimesheetData(false)
            }
        }).catch(error => {
            // setUpdatedTimesheetData(false)
            console.error(error)
        })

    }

     useEffect(() => {
        if(updatedTimesheetData && contractId !== undefined){
            getTimeSheetData()
        }
     }, [updatedTimesheetData,contractId])

    useEffect(() => {
        setFilteredTimeSheetData(timeSheetData.filter((item) => {
            return moment(item.Clock_Out_Date_Time) > startOfBillingWeek && moment(item.Clock_Out_Date_Time) <= endOfBillingWeek
        })
)
    }, [timeSheetData])

   

    function getWeeklyTimesheetData(){
        const thisWeekTimesheet = timeSheetData.filter((item) => {
            return moment(item.Clock_Out_Date_Time) > startOfBillingWeek && moment(item.Clock_Out_Date_Time) <= endOfBillingWeek
        })

        return thisWeekTimesheet
    }

    function getWeeklyTrackedHours(){
        const filteredTrackedTime = timeSheetData?.filter((item) => item?.Tags === null && moment(item.Clock_Out_Date_Time) >= startOfBillingWeek && moment(item.Clock_Out_Date_Time) <= endOfBillingWeek)
        const totalTrackedHours = filteredTrackedTime.reduce((acc, record) => {
            let timeDuration= record?.Total_Time;
            let roundDuration = Math.round(timeDuration / 10) * 10;;
            if(roundDuration - timeDuration == 1)
                timeDuration = roundDuration

            return acc + timeDuration/60
        }, 0)
        return totalTrackedHours
    }

    function getWeeklyManualHours(){
        const filteredManualTime = timeSheetData?.filter((item) => item?.Tags === "manual" && moment(item.Clock_Out_Date_Time) >= startOfBillingWeek && moment(item.Clock_Out_Date_Time) <= endOfBillingWeek)
        const totalManualHours = filteredManualTime.reduce((acc, record) => {
            let timeDuration= record?.Total_Time;
            let roundDuration = Math.round(timeDuration / 10) * 10;;
            if(roundDuration - timeDuration == 1)
                timeDuration = roundDuration

            return acc + timeDuration/60
        }, 0)
        
        return totalManualHours
    }

    function getTrackedHours(){
        const filteredTrackedTime = filteredTimeSheetData?.filter((item) => item?.Tags === null)
        const totalTrackedHours = filteredTrackedTime.reduce((acc, record) => {
            let timeDuration= record?.Total_Time;
            let roundDuration = Math.floor(timeDuration) * 10;
            if(roundDuration - timeDuration == 1)
                timeDuration = roundDuration

            return acc + timeDuration/60
        }, 0)

        return totalTrackedHours
    }

    function getManualHours(){
        const filteredManualTime = filteredTimeSheetData?.filter((item) => item?.Tags === "manual")
        const totalManualHours = filteredManualTime.reduce((acc, record) => {
            let timeDuration= record?.Total_Time;
            let roundDuration = Math.floor(timeDuration / 10) * 10;
            if(roundDuration - timeDuration == 1)
                timeDuration = roundDuration

            return acc + timeDuration/60
        }, 0)

        return  totalManualHours
    }

    const totalHours = getTrackedHours() + getManualHours()
    const billingWeekTotalHours = getWeeklyTrackedHours() + getWeeklyManualHours()

    return {timeSheetData, setTimeSheetData, filteredTimeSheetData, setFilteredTimeSheetData, totalHours, billingWeekTotalHours, startOfBillingWeek, endOfBillingWeek, getTrackedHours, getManualHours, getTimeSheetData, getWeeklyTimesheetData}
}