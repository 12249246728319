import { Avatar, Form, Input, Modal, Select, Space, Typography, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import '../css/add-chat-member-modal.scss'
import { useSelector } from 'react-redux';
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper';
import { FirestoreHelper } from '../../../../../../utils/firebase-helper';
import { SAMPLE_HIPAA_ID } from '../../../../../../utils/constants';
import { RtmRepository } from '../repository/rtm-repository';
import { encryptString } from '../../../../../../utils/helper';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const OPTIONS = [
  {
    label: 'Diana Khosky',
    value: 'Diana Khosky',
  },
  {
    label: 'Mark Musk',
    value: 'Mark Musk',
  },
  {
    label: 'Elon Zuck',
    value: 'Elon Zuck',
  },
]

const { TextArea } = Input;
const { Paragraph } = Typography
const {useForm} = Form
const moment = extendMoment(Moment);


const AddChatMemberModal = ({openAddMemberModal, closeAddMemberModal, participants, conversation, setActiveTeamMembers, activeTeamMembers, fromGroupConversationsPage, fetchParticipants, handleGetConversations}) => {

	const user = useSelector((state) => state.user)
	const [addingParticipants, setAddingParticipants] = useState(false)
	const [form] = useForm();
	const [searchValue, setSearchValue] = useState('');

	const rtmRepository = new RtmRepository(user['token']);

	const filteredOptions = activeTeamMembers.filter(
		(item) =>
		  !conversation.participants.includes(item?.Contact?.id) &&
		  item?.Contact?.Full_Name.toLowerCase().includes(searchValue.toLowerCase())
	  );

	const sendNewMessage = ({message, isAdmin=false}) => {
        if (message.length == 0)
            return;

        FirestoreHelper.sendMessage({
            conversation_id: conversation.id,
            message: encryptString(message.replace(/(?:\r\n|\r|\n)/g, '<br>')),
            user_id: isAdmin ? SAMPLE_HIPAA_ID : user.id,
        });
        rtmRepository.sendMessage({
            conversationId:conversation.id,
            message,
            createdAt: moment().utc().toISOString(),
        });
        // this.formRef.current.resetFields();
		form.resetFields()
    }

	const handleSearch = (value) => {
		setSearchValue(value);
	};


	const handleAddMemberSubmit = async (values) => {
		setAddingParticipants(true)

		let newlyAddedUsers = [];
		// activeTeamMembers.map(async (record) => {
		// 	if (values?.teamMembers?.includes(record.Contact.id)) {
		// 		await FirestoreHelper.createUser({
		// 			hipaa_id: record?.Contact.id,
		// 			display_name: record?.Contact?.Full_Name,
		// 			profile_picture: record?.Contact?.imgUrl,
		// 		});
		// 	}
		// });

		activeTeamMembers.map(async (record) => {
			if (values?.teamMembers?.includes(record.Contact.id)) {
				newlyAddedUsers.push(record.Contact.Full_Name);
			}
		});

		const newParticipants = [...conversation.participants, ...values?.teamMembers];


		let owners = [];
		let participantsNames = [];

		if(participants.length === 2 && !conversation.owners){
			participantsNames = [];
			participants.map((item) => {
					owners.push(item)
					participantsNames.push(item.display_name);
				}
			)
			participantsNames = participantsNames.join(" | ");
		}

		if(conversation.owners && participants.length >= 2){
			conversation.owners.map((item) => {
					participantsNames.push(item.display_name);
				}
			)
			participantsNames = participantsNames.join(" | ");
		}

		participantsNames = `${participantsNames} & ${newParticipants.length - 2} other${newParticipants.length - 2 > 1 ? 's' : ''}`

		await FirestoreHelper.updateGroupConversationMembers({
			participants: newParticipants,
			conversation_id: conversation.id,
			owners,
			group: participantsNames
		});

        let participantsResponse = await FirestoreHelper.getUsers(newParticipants);
        let updatedParticipants = [];
        participantsResponse.forEach((doc) => {
            let item = doc.data();
            if (item.hipaa_id != SAMPLE_HIPAA_ID)
                updatedParticipants.push(item);
        });

		try {
			await ApiBaseHelper.post({
				url: `conversation/${conversation.id}/participant`,
				headers: {
				Authorization: "Bearer " + user.token,
				},
				payload: {
					Title: participantsNames,
					Participants: [...values?.teamMembers]
				}
			}).then((res) => {
				console.log(res)
				setAddingParticipants(false)
				closeAddMemberModal()
				form.resetFields()
				notification["success"]({
					message: "TecMe",
					description: "Participant added successfully.",
				});
				fetchParticipants(updatedParticipants)
				handleGetConversations()
			})
			
		} catch (error) {
			console.error(error)    
			setAddingParticipants(false)            
		};

		sendNewMessage({
			message: `${user.firstname} has added ${newlyAddedUsers.join(", ")} to the conversation.`,
		})
		
		if(values.chatMessage !== undefined){
			let chatMessage = values?.chatMessage ;
			chatMessage = chatMessage.trim();
			sendNewMessage({
				message: chatMessage,
			})
		}
	}

	return (
		<Modal
			title="Add Team Member"
			className='add-chat-member-modal'
			visible={openAddMemberModal}
			okText="Add"
			okButtonProps={{htmlType:"submit", loading:addingParticipants, form:'add-chat-member-form'}}
			cancelButtonProps={{type:"secondary"}}
			onCancel={() => closeAddMemberModal()}
			width={572}
		>
		<Form
			layout="vertical"
			name='add-chat-member-form'
			className='add-chat-member-form'
			onFinish={handleAddMemberSubmit}
			form={form}
		>
			<Paragraph style={{color:"rgba(0, 0, 0, 0.65)", fontSize:14, marginBottom:26}}>Enter the names of individuals you want to invite to the chat</Paragraph>
			<Form.Item 
				name="teamMembers"
				label="Add team member" 
				className='mb-4'
				rules={[{ required: true, message: 'Please select team member' }]}
			>
				<Select
					mode="multiple"
					allowClear
					style={{
					width: '100%',
					}}
					placeholder="Start by adding names"
					onSearch={handleSearch}
					filterOption={false}
					
				>
					{filteredOptions?.map((item) => (
						<Select.Option key={item?.Contact.id} value={item?.Contact.id}>
							<Space size={8}>
								<Avatar size={24} src={item?.Contact.imgUrl} alt={item?.Contact?.Full_Name} style={{fontSize:12}}>
									{item?.Contact.Full_Name[0].toUpperCase()}
								</Avatar>
								<Paragraph style={{margin:0, fontSize:14}}>{item?.Contact.Full_Name}</Paragraph>
							</Space>
						</Select.Option>
					))}
				</Select>
				</Form.Item>
				<Form.Item
					label="Write your message (optional)"
					name="chatMessage"
				>
					<TextArea rows={4}  placeholder="Type your message here"/> 
				</Form.Item>
			</Form>
		</Modal>
	)
}

export default AddChatMemberModal