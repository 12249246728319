import { useEffect } from "react";
import { useState } from "react";

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState(getWindowSize())

    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
      }
    useEffect(() => {
    function handleWindowResize() {
        setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
        window.removeEventListener('resize', handleWindowResize);
    };
    }, []);
    
    return {windowWidth: windowSize.innerWidth, windowHeight: windowSize.innerHeight};
}

export default useWindowSize