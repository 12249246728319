// External Imports
import { Button, Layout, Space, Typography } from "antd"
import { useSelector } from "react-redux";
import { MetaTags } from "react-meta-tags";

// Internal Imports
import EditProfile from "../../../common/private/edit-profile/index1"
import PageHeaderWrapper from "../../../../components/page-header-breadcrumbs";
import ShareTechProfile from "../../../../components/share-tech-profile";
import { META_DESCRIPTION } from "../../../../../utils/constants";
import ROUTES from "../../../../../utils/routes";
import PageTitle from "../../../../components/page-title";

const { Content } = Layout;
const { Text } = Typography;

const Profile = (props) => {
    const tech = useSelector(state => state.tech);

  return (
    <Content>
        <MetaTags>
            <title>Profile Details | TecMe </title>
            <meta name="description" content={META_DESCRIPTION}/>
            <meta name="robots" content="noindex, nofollow"/>
        </MetaTags>
        <Content className='page'>
            <Content>
                <PageHeaderWrapper
                    routes={[
                        {
                            path: ROUTES.MANAGE_PROFILE_DETAILS,
                            breadcrumbName: 'Profile Details',
                        },
                    ]}
                    // extra={tech ? [
                    //     <Space direction='vertical' align='end'>
                    //         <Space>
                    //             <Text>{window.innerWidth > 400 ? "Share your profile:" : "Share on:"}</Text>
                    //             <ShareTechProfile />
                    //         </Space>
                    //         <Button
                    //             onClick={() => {
                    //                 if(tech.profileUrl) {
                    //                     props.history.push(ROUTES.TECH_PROFILE_NEW.replace(":profileUrl", tech.profileUrl) + "?view=customer")
                    //                 } else {
                    //                     props.history.push(ROUTES.TECH_PROFILE_WITH_ID.replace(":id", tech.id.replace("#", '/')) + "?view=customer")
                    //                 }
                    //             }}
                    //         >
                    //             View your profile as a customer
                    //         </Button>
                    //     </Space>
                    // ]: null}
                />

                <Content className="auth-container" style={{marginTop:20}}>
                    <div  className="auth-page-ctn">
                        <PageTitle 
                            title="Profile Details"
                            extra={
                                tech && <Button
                                onClick={() => {
                                    if(tech.profileUrl) {
                                        props.history.push(ROUTES.TECH_PROFILE_NEW.replace(":profileUrl", tech.profileUrl) + "?view=customer")
                                    } else {
                                        props.history.push(ROUTES.TECH_PROFILE_WITH_ID.replace(":id", tech.id.replace("#", '/')) + "?view=customer")
                                    }
                                }}
                            >
                                View your profile as a customer
                            </Button>
                            }
                            search={
                                tech &&
                                <Space>
                                    <Text>{window.innerWidth > 400 ? "Share your profile:" : "Share on:"}</Text>
                                    <ShareTechProfile />
                                </Space>
                            }
                        />
                        <EditProfile isFromProfileDetails />
                    </div>
                </Content>
            </Content>
        </Content>
    </Content>
  )
}

export default Profile