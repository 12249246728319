import { Button, Space } from 'antd'
import React from 'react'
import AiIcon from '../../assets/images/ai-icon.svg'

const AIButton = ({loading=false, type, onClick,  children, ...otherProps}) => {
  return (
    <Button 
        // loading={loading}
        onClick={() => onClick()}
        disabled={loading}
        icon={<img src={AiIcon} alt="ai-icon" style={{marginRight:8}}/>}
        className={type === "chat" ? "ai-chat-btn" : ""}
        {...otherProps}
    >
        {
            loading	? 
            <Space size={4}>
                <span>Generating</span>
                <div className="loading-dots"></div>
            </Space> : 
            children
        }
    </Button>
  )
}

export default AIButton