import {createSlice} from '@reduxjs/toolkit'
import moment from "moment";
import {generateSearchQueryParams} from "../../views/pages/common/public/instant-search/utils/helper";
// import {SERVICES_SUGGESTIONS, BRANDS} from "../../utils/helper";

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        user: null,
        tech: null,
        admin: null,
        token: null,
        fcmToken: null,
        techReserve:null,
        encryptedPassword:null,
        users:[],
        organizations:[],
        inAppNotifications:{
            unReadNotifications: 0,
            notificationData:[]
        },
        workCategoriesGlobal: [],
        generalSkillsGlobal: [], // includes all categories and their sub categories
        activeSettingsTab:"account",
        locationDetails: {
            city: "",
            state: "",
            manualSave: false
        },
        onboardingForm: {
            firstName: null,
            lastName: null,
            email: null,
            password: '',
            confirmPassword: null,

            //locations 1
            address: null,
            address2: null,
            city: null,
            state: null,
            zipCode: null,

            //profile info 2
            file: null,
            imageUrl: null,
            profileBio: null,
            dateOfBirth: null,
            phoneNumber: null,
            emergencyFirstName: null,
            emergencyLastName: null,
            emergencyPhoneNumber: null,

            //technical info 3
            skills: [],
            homeRate: null,
            remoteRate: null,
            skillsExperience: null,
            knowledgeSource: null,
            radius: 30,

            //more info 4
            ambitions: null,
            militaryStatus: null,
            militaryRelationship: null,

            suggestedServices: [],

            schedule: [
                {
                    day: 'MON',
                    fullDay: 'Monday',
                    active: true,
                    slots: [
                        {
                            startTime: moment('09:00:00', 'HH:mm:ss'),
                            endTime: moment('18:00:00', 'HH:mm:ss'),
                        },

                    ],
                },
                {
                    day: 'TUE',
                    fullDay: 'Tuesday',
                    active: true,
                    slots: [
                        {
                            startTime: moment('09:00:00', 'HH:mm:ss'),
                            endTime: moment('18:00:00', 'HH:mm:ss'),
                        },
                    ],
                },
                {
                    day: 'WED',
                    fullDay: 'Wednesday',
                    active: true,
                    slots: [
                        {
                            startTime: moment('09:00:00', 'HH:mm:ss'),
                            endTime: moment('18:00:00', 'HH:mm:ss'),
                        },
                    ],
                },
                {
                    day: 'THU',
                    fullDay: 'Thursday',
                    active: true,
                    slots: [
                        {
                            startTime: moment('09:00:00', 'HH:mm:ss'),
                            endTime: moment('18:00:00', 'HH:mm:ss'),
                        },
                    ],
                },
                {
                    day: 'FRI',
                    fullDay: 'Friday',
                    active: true,
                    slots: [
                        {
                            startTime: moment('09:00:00', 'HH:mm:ss'),
                            endTime: moment('18:00:00', 'HH:mm:ss'),
                        },
                    ],
                },
                {
                    day: 'SAT',
                    fullDay: 'Saturday',
                    active: true,
                    slots: [
                        {
                            startTime: moment('09:00:00', 'HH:mm:ss'),
                            endTime: moment('18:00:00', 'HH:mm:ss'),
                        },
                    ],
                },
                {
                    day: 'SUN',
                    fullDay: 'Sunday',
                    active: true,
                    slots: [
                        {
                            startTime: moment('09:00:00', 'HH:mm:ss'),
                            endTime: moment('18:00:00', 'HH:mm:ss'),
                        },
                    ],
                },


            ],
        },
        quickBooking: {
            isModalVisible: false,
            loginModal: false,
            servicesLoading: false,
            devicesLoading: false,
            requireRegistration: false,
            services: [],
            deviceTypes: [],
            authenticated: null,
            resetPasswordDialog: false,
            selectedService: null,
            dialogMessage: '',
            current: 0, // @todo: change it to 0
            loading: false,
            operatingSystemValue: null,
            switchAccount: false,
            completed: false,
            deviceSelectionMode: 'none', // possible values: 'existing', 'new'
            loginLoading: false,
            customDescription: null, // @todo: change it null,
            availableSlots: [],
            steps: [],
            otherBrand: false,
            isDeviceType: true,
            selected: [],
            clearBrand: false,
            itemLoading: false,
            brandError: false,
            date: null,
            currentOperatingSystem: null,
            typeName: '',
            cardValidation: {
                number: undefined,
                expiry: undefined,
                cvc: undefined,
            },
            bookingFormData: {
                date: new Date(),
                timeSlot: null,
                description: null,
                deviceId: null,
                homeId: null,
                paymentMethodId: null
            },
            deviceFormData: {
                category: '',
                name: '',
                description: '',
                manufacturer: '',
                model: '',
                brandName: '',
                operating_system: '',
                operating_system_list: []
            },
            userFormData: { // @todo: remove test data
                firstName: '',
                lastName: '',
                email: '',
            },
            locationFormData: {
                type: '',
                address: '',
                address2: '',
                zipCode: '',
                city: '',
                state: ''
            },
            paymentMethod: {
                cvc: '',
                expiry: '',
                number: '',
            },
            resetPassword: {
                otp: '',
                password: '',
                confirmPassword: '',
                passwordId: null,
                userId: null,
                pinCodeErrorMessage: '',
                pinCodeErrorBox: false,

            },
            password: '',
            promo: {
                loading: false,
                code: "",
                message: "",
                data: null,
                invalid: false,
            },
            invalidPassword: false,
            noCardDetails: false,
            cardValidationMessage: "",
            schedule: null,
            images: [],
            isImage: false,
            brands: [],
            selectedTypee: ''

        },
        searchFilters: {
            query: "",
            rating: 0,
            hourlyRate: {
                min:10,
                max:300
            },
            compliances: [],
            itProjects: [],
            certifications: [],
            skills: [],
            deviceTypes: [],
            brands: [],
            categories: [],
            sub_categories: [],
            operatingSystems: [],
            test: [],
            geoLocation: {
                lat: null,
                lng: null,
                zipCode: null,
                city: null,
                state: null
            }
        }
    },
    reducers: {
        updateUser: (state, action) => {
            state.user = action.payload
        },
        syncFilterCategories: (state, action) => {
            state.searchFilters['categories'] = action.payload
            let currentSubCategories = state.searchFilters['sub_categories'];
            let categories = action.payload.map((item)=>(item.toLowerCase().replace(" ","_")))

            let removeListOfParentCategories = currentSubCategories
                .map((item, index) => {
                    return !categories.includes(item.category.toLowerCase()) ? index : -1;
                })
                .filter((item)=> (item != -1))

            if (removeListOfParentCategories.length > 0) {
                removeListOfParentCategories.forEach((index) => {
                    currentSubCategories.splice(index, 1);
                });
                state.searchFilters['sub_categories'] = currentSubCategories
            }
        },
        syncFilterSubCategoryByParentCategory: (state, action) => {
            let indexOfSubCategory = -1;
            let currentSubCategories = state.searchFilters['sub_categories'];

            currentSubCategories.some((item, index) => {
                if (item.category.toLowerCase() == action.payload.category.toLowerCase()) {
                    indexOfSubCategory = index;
                    return true
                }
            });

            // if no brands sent in payload, then remove index from array
            if (action.payload.sub_categories.length == 0 && indexOfSubCategory > -1) {
                currentSubCategories.splice(indexOfSubCategory, 1);
            }
            else if (action.payload.sub_categories.length > 0) {
                if (indexOfSubCategory > -1)
                    currentSubCategories[indexOfSubCategory] = action.payload;
                else
                    currentSubCategories.push(action.payload);
            }
            state.searchFilters['sub_categories'] = currentSubCategories
        },
        syncFilterSubCategories: (state, action) => {
            state.searchFilters['sub_categories'] = action.payload
        },
        syncFilterSkills: (state, action) => {
            state.searchFilters['skills'] = action.payload
        },
        syncFilterITProjects: (state, action) => {
            state.searchFilters['itProjects'] = action.payload
        },
        syncFilterQuery: (state, action) => {
            state.searchFilters['query'] = action.payload
        },
        syncFilterRating: (state, action) => {
            state.searchFilters['rating'] = action.payload
        },
        syncFiltersWithQueryParams: (state, action) => {
            generateSearchQueryParams({
                deviceTypesFilter:state.searchFilters['deviceTypes'],
                brandsFilter:state.searchFilters['brands'],
                skills:state.searchFilters['skills'],
                compliancesFilter:state.searchFilters['compliances'],
                certificationsFilter:state.searchFilters['certifications'],
                geoLocationFilter:state.searchFilters['geoLocation'],
                operatingSystemsFilters:state.searchFilters['operatingSystems'],
                searchQueryFilter:state.searchFilters['query'],
                ratingFilter:state.searchFilters['rating'],
                hourlyRateFilter:state.searchFilters['hourlyRate'],
                itProjects:state.searchFilters['itProjects'],
                categories:state.searchFilters['categories'],
                subCategories:state.searchFilters['sub_categories'],
            })
        },
        syncFilterHourlyRate: (state, action) => {
            state.searchFilters['hourlyRate'] = action.payload
        },
        syncFilterHourlyRateMin: (state, action) => {
            state.searchFilters['hourlyRate']['min'] = action.payload
        },
        syncFilterHourlyRateMax: (state, action) => {
            state.searchFilters['hourlyRate']['max'] = action.payload
        },
        syncFilterCompliances: (state, action) => {
            state.searchFilters['compliances'] = action.payload
        },
        syncFilterCertifications: (state, action) => {
            state.searchFilters['certifications'] = action.payload
        },
        syncFilterOperatingSystems: (state, action) => {
            state.searchFilters['operatingSystems'] = action.payload
        },
        syncFilterGeoLocation: (state, action) => {
            state.searchFilters['geoLocation'] = {...state.searchFilters.geoLocation, ...action.payload}
        },
        syncFilterGeoLocationZipCode: (state, action) => {
            state.searchFilters['geoLocation']['zipCode'] = action.payload
        },
        clearSearchFilters: (state, action) => {
            state.searchFilters = {
                query: "",
                rating: 0,
                hourlyRate: {
                    min:10,
                    max:300
                },
                compliances: [],
                itProjects: [],
                certifications: [],
                skills: [],
                deviceTypes: [],
                brands: [],
                categories: [],
                sub_categories: [],
                operatingSystems: [],
                test: [],
                geoLocation: {
                    lat: null,
                    lng: null,
                    zipCode: null,
                    city: null,
                    state: null
                }
            }
        },
        updateAdmin: (state, action) => {
            state.admin = action.payload
        },
        updateToken: (state, action) => {
            state.token = action.payload
        },
        updateTech: (state, action) => {
            state.tech = action.payload
            if (action.payload == null)
                localStorage.removeItem('techConnectModal')
        },
        updateUsers: (state, action) => {
            state.users = action.payload
        },
        updateOnBoardingForm: (state, action) => {
            state.onboardingForm = action.payload
        },
        updateQuickBookingForm: (state, action) => {
            state.quickBooking = action.payload
        },
        updateLocationDetails: (state, action) => {
            state.locationDetails = action.payload
        },
        updateFcmToken: (state, action) => {
            state.fcmToken = action.payload
        },
        updateInAppNotifications: (state, action) => {
            state.inAppNotifications = action.payload
        },
        updateActiveSettingsTab: (state, action) => {
            state.activeSettingsTab = action.payload
        },
        updateWorkCategories: (state, action) => {
            state.workCategoriesGlobal = action.payload
        },
        updateGeneralSkills: (state, action) => {
            state.generalSkillsGlobal = action.payload
        },
        updateOrganizations: (state, action) => {
            state.organizations = action.payload
        },
        updateEncryptedPassword: (state, action) => {
            state.encryptedPassword = action.payload
        },
        updateTechReserve: (state, action) => {
            state.techReserve = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    updateUser,
    updateTech,
    updateOnBoardingForm,
    updateLocation,
    updateLocationDetails,
    updateAdmin,
    updateUsers,
    updateFcmToken,
    updateQuickBookingForm,
    updateGlobalUnReadNotifications,
    updateInAppNotifications,
    updateActiveSettingsTab,
    updateWorkCategories,
    updateGeneralSkills,
    updateOrganizations,
    updateTechReserve,
    updateEncryptedPassword,
    syncFilterCategories,
    syncFilterSubCategories,
    syncFilterOperatingSystems,
    syncFilterGeoLocation,
    syncFilterSubCategoryByParentCategory,
    syncFilterCompliances,
    syncFilterCertifications,
    syncFilterQuery,
    syncFilterRating,
    syncFilterHourlyRate,
    syncFilterHourlyRateMin,
    syncFilterHourlyRateMax, 
    syncFiltersWithQueryParams,
    syncFilterGeoLocationZipCode,
    syncFilterITProjects,
    clearSearchFilters,
    syncFilterSkills,
} = appSlice.actions

export default appSlice.reducer