import React, { useEffect } from 'react'
import GetStartedImg from '../assets/icons/get-started-icon.svg'
import { Button, Radio, Select, Space, Typography } from 'antd'
import { useState } from 'react'

const {Paragraph, Title} = Typography

const GettingStarted = ({handleNextClick}) => {
  const [jobPostType, setJobPostType] = useState('')

  // Skip the getting started page
  useEffect(() => {
    handleNextClick()
  }, [])

  return (
    <div className='getting-started-ctn'>
      <div className='getting-started-content'>
        <div className="getting-started-inner">
          <Title level={3}  className='post-job-title-mobile'>Post a job</Title>
          <div className='getting-started-img'>
            <img src={GetStartedImg} alt="Getting started image" />
          </div>
          <Title level={3}  className='getting-started-title'>Getting started</Title>
          <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", marginBottom:32}}>To make your job post a success, we'll guide you through a simple and efficient process. We'll collect essential details about your requirements and ensure your progress is seamlessly saved along the way. You can always come back to it later without any worries.</Paragraph>
          <Radio.Group onChange={(e) => setJobPostType(e.target.value)}>
              <Space direction="vertical">
                  <Radio value="new-job">Create a new job post</Radio>
                  <Radio value="previous-job">Reuse a previous job post</Radio>
                  {
                    jobPostType === "previous-job" &&
                    <Select className='select-job' size='large'  placeholder="Select a previous job post">
                      {
                        [1,2,3,4,5].map((item, index) => <Select.Option key={index} value={item}>Job post {item}</Select.Option>)
                      }
                    </Select>
                  }
                  <Radio value="draft-job">Continue with a draft</Radio>
                  {
                    jobPostType === "draft-job" &&
                    <Select className='select-job' size='large'  placeholder="Select a previous job post">
                      {
                        [1,2,3,4,5].map((item, index) => <Select.Option key={index} value={item}>Job post {item}</Select.Option>)
                      }
                    </Select>
                  }
              </Space>
          </Radio.Group>
        </div>
      </div>
      <div className="getting-started-btn-ctn">
        <Space size={24}>
          <Button type="secondary" className='getting-started-btn'  onClick={() => {}}>Cancel</Button>
          <Button type="primary" className='getting-started-btn' onClick={() => handleNextClick()}>Continue</Button>
        </Space>
      </div>
    </div>
  )
}

export default GettingStarted