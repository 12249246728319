import {ucwords} from "../../../../../../utils/helper";
import {
    syncFilterSubCategoryByParentCategory, syncFilterCertifications, syncFilterCompliances,
    syncFilterCategories,
    syncFilterGeoLocation,
    syncFilterHourlyRateMax,
    syncFilterHourlyRateMin, syncFilterITProjects, syncFilterOperatingSystems,
    syncFilterQuery, syncFilterRating,
    syncFiltersWithQueryParams, syncFilterSkills
} from "../../../../../../redux/reducers/app";
import history from "../../../../../../utils/history";
import ROUTES from "../../../../../../utils/routes";
import {BASE_URL} from "../../../../../../utils/api-base-helper";
import {
    ACCEPTANCE_SERVER,
    DEV_SERVER,
    LOCAL_SERVER,
    PROD_SERVER,
    STAGING_SERVER
} from "../../../../../../utils/constants";


export function generateAppliedFilters(
    {
        searchQueryFilter,
        geoLocationFilter,
        hourlyRateFilter,
        ratingFilter,
        deviceTypesFilter,
        brandsFilter,
        operatingSystemsFilters,
        compliancesFilter,
        certificationsFilter,
        itProjectsFilter,
        categoriesFilter,
        subCategoriesFilter,
        dispatch,
        skillsFilter
    }
) {
    let filters = [];
    if (searchQueryFilter != "")
        filters.push({
            type: "Search:",
            value: ucwords(searchQueryFilter),
            onClose: () => {
                dispatch(syncFilterQuery(""))
                dispatch(syncFiltersWithQueryParams());
            }
        })
    if (geoLocationFilter.zipCode != null) {
        filters.push({
            type: "Zip Code:",
            value: geoLocationFilter.zipCode,
            onClose: () => {
                dispatch(syncFilterGeoLocation({
                    lat: null,
                    lng: null,
                    zipCode: null,
                    city: null,
                    state:null
                }))
                dispatch(syncFiltersWithQueryParams());
            }
        })
    }
    if(geoLocationFilter.city != null){
        filters.push({
            type: "City:",
            value: geoLocationFilter.city,
            onClose: () => {
                dispatch(syncFilterGeoLocation({
                    lat: null,
                    lng: null,
                    zipCode: null,
                    city: null,
                    state:null
                }))
                dispatch(syncFiltersWithQueryParams());
            }
        })
    }
    if(geoLocationFilter.state != null){
        filters.push({
            type: "State:",
            value: geoLocationFilter.state,
            onClose: () => {
                dispatch(syncFilterGeoLocation({
                    lat: null,
                    lng: null,
                    zipCode: null,
                    city: null,
                    state:null
                }))
                dispatch(syncFiltersWithQueryParams());
            }
        })
    }
    if (geoLocationFilter.lat != null && geoLocationFilter.lng != null) {
        // filters.push({
        //     type: "Current Location",
        //     value: '',
        //     onClose: () => {
        //         dispatch(syncFilterGeoLocation({
        //             lat: null,
        //             lng: null,
        //             zipCode: null
        //         }))
        //         dispatch(syncFiltersWithQueryParams());
        //     }
        // })
    }
    if (hourlyRateFilter.min != null && (hourlyRateFilter.min != 10))
        filters.push({
            type: "Hourly Rate Minimum:",
            value: `$${hourlyRateFilter.min}`,
            onClose: () => {
                dispatch(syncFilterHourlyRateMin(null))
                dispatch(syncFiltersWithQueryParams());
            }
        })

    if (hourlyRateFilter.max != null && (hourlyRateFilter.max !=300))
        filters.push({
            type: "Hourly Rate Maximum:",
            value: `$${hourlyRateFilter.max}`,
            onClose: () => {
                dispatch(syncFilterHourlyRateMax(null))
                dispatch(syncFiltersWithQueryParams());
            }
        })

    if (ratingFilter > 0)
        filters.push({
            type: "Rating>",
            value: ratingFilter,
            onClose: () => {
                dispatch(syncFilterRating(0))
                dispatch(syncFiltersWithQueryParams());
            }
        })

    categoriesFilter.forEach((item, index) => {
        filters.push({
            type: "Category:",
            value: ucwords(item),
            onClose: () => {
                let payload = JSON.parse(JSON.stringify(categoriesFilter));
                payload.splice(index, 1);
                dispatch(syncFilterCategories(payload))
                dispatch(syncFiltersWithQueryParams());
            }
        })
    })

    skillsFilter.forEach((item, index) => {
        filters.push({
            type: "Skills:",
            value: ucwords(item),
            onClose: () => {
                let payload = JSON.parse(JSON.stringify(skillsFilter));
                payload.splice(index, 1);
                dispatch(syncFilterSkills(payload))
                dispatch(syncFiltersWithQueryParams());
            }
        })
    })
    
    subCategoriesFilter.forEach((item, index) => {
        item.sub_categories.forEach((subCategory, categoryIndex) => {
            filters.push({
                type: `Sub Category: `,
                value: ucwords(subCategory)
                    .replace("(Erp)", "(ERP)")
                    .replace("(Crm)","(CRM)")
                    .replace("(Nas)","(NAS)")
                    .replace("(Bcdr)","(BCDR)")
                    .replace("(Itam)","(ITAM)")
                    .replace("(Itsm)","(ITSM)")
                    .replace("(Mdm)","(MDM)")
                ,
                onClose: () => {
                    let payload = JSON.parse(JSON.stringify(item.sub_categories));
                    payload.splice(categoryIndex, 1);
                    dispatch(syncFilterSubCategoryByParentCategory({
                        "category": item.category,
                        "sub_categories": payload
                    }));
                    dispatch(syncFiltersWithQueryParams());
                }
            })
        })
    })

    compliancesFilter.forEach((item, index) => {
        filters.push({
            type: "Compliance:",
            value: item.toUpperCase(),
            onClose: () => {
                let payload = JSON.parse(JSON.stringify(compliancesFilter));
                payload.splice(index, 1);
                dispatch(syncFilterCompliances(payload))
                dispatch(syncFiltersWithQueryParams());
            }
        })
    })

    certificationsFilter.forEach((item, index) => {
        filters.push({
            type: "Certification:",
            value: ucwords(item),
            onClose: () => {
                let payload = JSON.parse(JSON.stringify(certificationsFilter));
                payload.splice(index, 1);
                dispatch(syncFilterCertifications(payload))
                dispatch(syncFiltersWithQueryParams());
            }
        })
    })

    return filters;
}

export const generateSearchQueryParams =
    ({
         deviceTypesFilter,
         brandsFilter,
         compliancesFilter,
         certificationsFilter,
         operatingSystemsFilters,
         geoLocationFilter,
         searchQueryFilter,
         ratingFilter,
         hourlyRateFilter,
         itProjects,
        categories,
        subCategories,
        skills,
     }) => {
        let queryParams = [];
        if (skills.length > 0) {
            queryParams.push(`skills=${skills.join(',')}`)
        }
        if (deviceTypesFilter.length > 0) {
            queryParams.push(`device=${deviceTypesFilter.join(',')}`)
        }
        if(categories.length > 0){
            queryParams.push(`categories=${categories.join(',')}`)
        }
        if (subCategories.length > 0) {
            subCategories.forEach((subCategory) => {
                queryParams.push(`${subCategory.category.toLowerCase().replace(" ", "_")}_sub_categories=${subCategory.sub_categories.join(',')}`)
            })
        }

        if (brandsFilter.length > 0) {
            brandsFilter.forEach((skill) => {
                queryParams.push(`${skill.device_type.toLowerCase().replace(" ", "_")}_brand=${skill.brands.join(',')}`)
            })
        }

        if (operatingSystemsFilters.length > 0) {
            queryParams.push(`operating_system=${operatingSystemsFilters.join(',')}`)
        }
        if (compliancesFilter.length > 0) {
            queryParams.push(`compliance=${compliancesFilter.join(',')}`)
        }
        if (certificationsFilter.length > 0) {
            queryParams.push(`certification=${certificationsFilter.join(',')}`)
        }
        // if (geoLocationFilter.lat != null && geoLocationFilter.lng != null)
        //     queryParams.push(`lat=${geoLocationFilter.lat}&lng=${geoLocationFilter.lng}`)
        if (geoLocationFilter.zipCode != null)
            queryParams.push(`zip_code=${geoLocationFilter.zipCode}`)
        if (geoLocationFilter.city != null)
            queryParams.push(`city=${geoLocationFilter.city}`)
        if (geoLocationFilter.state != null)
            queryParams.push(`state=${geoLocationFilter.state}`)
        if (searchQueryFilter != '')
            queryParams.push(`query=${searchQueryFilter}`)
        if (ratingFilter > 0)
            queryParams.push(`rating=${ratingFilter}`)
        if (hourlyRateFilter.min != null && hourlyRateFilter.min != 10)
            queryParams.push(`min=${hourlyRateFilter.min}`)
        if (hourlyRateFilter.max != null && hourlyRateFilter.max != 300)
            queryParams.push(`max=${hourlyRateFilter.max}`)
        if (itProjects.length > 0)
            queryParams.push(`project=${itProjects.join(',')}`)

        history.push({
            pathname: ROUTES.DISCOVERY_EXPERIENCE_SEARCH,
            search: `?${queryParams.join('&')}`
        })
    }

export const getAlgoliaIndex = () => {
    let index = 'production';
    if (BASE_URL === DEV_SERVER)
        index = 'development';
    if (BASE_URL === STAGING_SERVER || BASE_URL === LOCAL_SERVER)
        index = 'staging';
    else if (BASE_URL === ACCEPTANCE_SERVER || BASE_URL === PROD_SERVER)
        index = 'production';
    return index;
}

export const getBrandIcon = (name) => {
    if(name == null)
        name = 'other'

    return `https://firebasestorage.googleapis.com/v0/b/tecme-mobile-prod.appspot.com/o/assets%2Fbrands%2F${name.toLowerCase().replace(/\s+/g,"_").replace(/-/g,"_")}.png?alt=media&token=f5b85b9e-3311-437d-abdc-2f3b6e849170`;
}
