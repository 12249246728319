import { useState } from 'react'
import { Modal, Typography, Form, Space, Input, Divider, Button, notification, Select } from 'antd'
import moment from "moment";

const { Text, Title, Paragraph } = Typography
const { Item, useForm } = Form
const { TextArea } = Input
const { Option } = Select

const resolveList = [
    'Communicate via chat with your customer to explain the reason of updating the ticket to pending state.',
    'You can resume the support anytime and start the service timer to continue servicing.',
]

const reasonsList = [
    {
        label: 'Additional information required',
        value: 'additional information'
    },
    {
        label: 'On-site visit required',
        value: 'on-site visit'
    },
    {
        label: 'Device parts required',
        value: 'part'
    }
]

const UpdateToPendingModal = ({ visible = true, onOk, onCancel, ticketDetails, ticketRepository, getAndSetTicketDetails, getAndSetActivities }) => {
    const [form] = useForm()
    const [loading, setLoading] = useState(false)
    const timerStart = ticketDetails?.custom_data?.timer_start;
    let quantity = ticketDetails?.custom_data?.duration;

    if(quantity) {
        quantity = Number(quantity)
    }

    return (
        <Modal
            visible={visible}
            onCancel={loading ? () => false : onCancel}
            className="resolve-ticket-modal"
            footer={false}
        >
             <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
            <Paragraph className='my-2 disclaimer p-2'>
            Are you sure you want to mark this ticket as pending? This action will change the status of the ticket to pending and may affect the resolution of the issue.
            </Paragraph>
            <Space direction='vertical' className='w-100'>
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={async (values) => {
                        setLoading(true)
                        let Duration = (!!timerStart ? moment.duration(moment().diff(moment(timerStart).utc(false))).asMilliseconds() : 0) + (quantity ? quantity * 60 * 1000 : 0)

                        try {
                            const payload = {
                                Customer_Contact: ticketDetails?.Reporter_Contact?.id,
                                Duration: Duration / 60 / 1000,
                                Description: values.Description,
                                Reason: values.Reason
                            }
                            
                            await ticketRepository?.updateToPendingTicket(ticketDetails?.id, payload)
                            await getAndSetTicketDetails()
                            await getAndSetActivities()
                            Modal.success({
                                className: 'ticket-updated-successfully-wrapper',
                                icon: null,
                                closable: true,
                                okText: 'Okay',
                                width: 500,
                                title: (
                                    <Title level={3} className='font-weight-500 m-0'>
                                        Ticket status changed to Pending
                                    </Title>
                                ),
                                content: (
                                    <div className='mt-2'>
                                        <Text className='font-weight-500'>What’s Next:</Text>
                                        <ol>
                                            {
                                                resolveList.map((item, index) => {
                                                    return <li className={"info-list mt-2"} key={`list-item-${index}`}>{item}</li>
                                                })
                                            }
                                        </ol>
                                    </div>
                                )
                            });
                            onOk()
                        } catch (error) {
                            notification.error({
                                message: 'TecMe',
                                description: 'Ticket resume failed'
                            })
                        }
                        setLoading(false)
                    }}
                >
                    <Item
                        name="Reason"
                        label="Select a reason for updating the ticket status to pending"
                        initialValue={reasonsList[0].value}
                        rules={[
                            {
                                required: true,
                                message: 'Please select reason',
                            }
                        ]}
                    >
                        <Select>
                            {reasonsList.map(reason => <Option key={reason.value} value={reason.value}>{reason.label}</Option>)}
                        </Select>
                    </Item>
                    <Item
                        name="Description"
                        label="Describe the reason"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter description',
                            },
                            {
                                min: 10,
                                message: 'It should be at least 10 characters or more.',
                            },
                        ]}
                    >
                        <TextArea
                            rows={4}
                            showCount
                            maxLength={250}
                            placeholder="Please enter description"
                        />
                    </Item>
                    <Divider />
                    <Space align='center' className='w-100 justify-content-end'>
                        <Item>
                            <Button type='text' onClick={loading ? () => false : onCancel}>Cancel</Button>
                        </Item>
                        <Item>
                            <Button type='primary' htmlType="submit" loading={loading}>Update</Button>
                        </Item>
                    </Space>
                </Form>
            </Space>
        </Modal>
    )
}

export default UpdateToPendingModal