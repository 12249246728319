import { Progress } from "antd";
import React, { Component } from "react";

export default class ProgressBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            uploadProgress: 0
        }
        this.uploadProgressInterval = null;
    }

    componentDidMount() {
        let _this = this;
        this.uploadProgressInterval = setInterval(() => {
            _this.setState({
                uploadProgress: _this.state.uploadProgress + 3
            })
        }, 150);
    }

    componentWillUnmount() {
        clearInterval(this.uploadProgressInterval);
    }

    render() {
        return <div style={{width: '100%'}}>
            <Progress percent={this.state.uploadProgress} status={'active'}/>
        </div>
    }
}
