import axios from "axios";
import {ApiBaseHelper} from "../../../../../../utils/api-base-helper";

export class RtmRepository {
    constructor(accessToken) {
        this.accessToken = accessToken;
    }

    async getConversations() {
        return ApiBaseHelper.get(
            {
                url: 'conversation',
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    async getMessages(conversationId) {
        return ApiBaseHelper.get(
            {
                url: `conversation/${conversationId}/messages`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    async validateNewChat(techId) {
        return ApiBaseHelper.get(
            {
                url: `conversation/isNewChat?techId=${techId}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    async sendMessage({conversationId, message, createdAt, bookingId, serviceId}) {
        return ApiBaseHelper.post(
            {
                url: `conversation/${conversationId}`,
                payload: {
                    message,
                    createdAt,
                    bookingId,
                    serviceId
                },
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    async createConversation(techId) {
        return ApiBaseHelper.post(
            {
                url: `conversation/`,
                payload: {
                    techId
                },
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    async createGroupConversation(participants) {
        return ApiBaseHelper.post(
            {
                url: `conversation/`,
                payload: {
                    participants
                },
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }


}
