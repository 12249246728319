import { Input, Modal, Radio, Space, Typography, notification } from 'antd'
import React, { useState } from 'react'
import {useHistory} from 'react-router-dom'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import { useSelector } from 'react-redux'
import ROUTES from '../../../../../../utils/routes'

const { Paragraph } = Typography

const WithdrawModal = ({visible, setVisible, jobApplication, fetchData}) => {
    const tech = useSelector(state => state.tech)
    const [withdrawReason, setWithdrawReason] = useState(null)
    const [otherReason, setOtherReason] = useState(null)
    const [withdrawing, setWithdrawing] = useState(false)
    const history = useHistory()
    const handleApplicationWithdrawal = () => {
        if(withdrawReason === "other" && !otherReason || !withdrawReason){
            notification.error({
                message: 'TecMe',
                description: 'Please select a reason or enter a reason',
            });
            setWithdrawing(false)
            return
        }
        setWithdrawing(true)
        ApiBaseHelper.post({
            url: `job-post/application/withdraw`,	
            headers: {
                Authorization: 'Bearer ' + tech['token'],
            },
            payload: {
                Reason: withdrawReason === "other" ? otherReason : withdrawReason,
                Job_ID: jobApplication?.Job_Posting?.id,
                Application_ID: jobApplication?.id,
            }
        }).then((res) => {
            if(res){
                notification.success({
                    message: 'TecMe',
                    description: 'Application withdrawn Successfully',
                });
                setVisible(false)
                setWithdrawing(false)
                history.push(ROUTES.MANAGE_JOB_APPLICATIONS)
                fetchData()
            }
        }).catch((err) => {
            setWithdrawing(false)
            notification.error({
                message: 'TecMe',
                description: 'An error occured!',
            });
            console.log(err)
        })
    }
    return (
        <Modal
            title="Withdraw application"
            visible={visible}
            onOk={() => handleApplicationWithdrawal()}
            onCancel={() => setVisible(false)}
            cancelButtonProps={{type:"secondary"}}
            okButtonProps={{loading:withdrawing}}
            okText="Withdraw application"
            width={801}
        >
            <Paragraph>We're sorry to see you go, and understand that circumstances can change, and we're here to support you every step of the way. If you have a moment, we'd appreciate hearing about the reasons for your withdrawal. Your feedback helps us improve our platform and services for everyone.</Paragraph>
            <Radio.Group onChange={(e) => setWithdrawReason(e.target.value)}>
                <Space direction="vertical">
                    <Radio value="mistake">Applied by mistake</Radio>
                    <Radio value="availability">Changed my availability</Radio>
                    <Radio value="opportunity"> Found a suitable opportunity elsewhere</Radio>
                    <Radio value="personal-reasons">Personal reasons</Radio>
                    <Radio value="other">Other</Radio>
                </Space>
            </Radio.Group>
            {
                withdrawReason === "other" && 
                <Input.TextArea
                    rows={3}
                    onChange={(e) => setOtherReason(e.target.value)}
                    placeholder='Enter a reason' 
                    style={{marginTop:20}}
                />
            }
        </Modal>
    )
}

export default WithdrawModal