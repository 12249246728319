// External Imports
import React, { useState, useEffect } from "react"
import {Button, Card, Space, Form, Image, Input, Layout, Typography, Skeleton, Modal, notification} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import MetaTags from "react-meta-tags";
import PasswordStrengthBar from 'react-password-strength-bar';
import { useLocation, useHistory } from "react-router-dom";
import queryString from 'query-string';

// Internal Imports
import {META_DESCRIPTION} from "../../../../../../utils/constants";
import ROUTES from "../../../../../../utils/routes";
import { FirebaseHelper, FirestoreHelper } from "../../../../../../utils/firebase-helper";
import { callPostApiWithErrorHandling, encryptString, getUserProfile } from "../../../../../../utils/helper";
import Lottie from "lottie-react";
import LoaderAnimation from "../../../../../../assets/animations/main-loading.json";
import Result from "antd/es/result";
import { ApiBaseHelper } from "../../../../../../utils/api-base-helper";
import {isBrowser, isTablet, isMobileOnly, isMobile} from 'react-device-detect';
import { useDispatch } from "react-redux";

const { Content } = Layout;
const { Title } = Typography;
const { useForm, Item } = Form;

const SetPassword = () => {
    const [form] = useForm();
    const history = useHistory()
    const reactDomLocation = useLocation()
	const params = queryString.parse(reactDomLocation.search);
    const [password, setPassword] = useState('')
    const [loader, setLoader] = useState(true)
    const [loading, setLoading] = useState(false)
    const [firebaseSetPasswordRecord, setFirebaseSetPasswordRecord] = useState(undefined)
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
    const [isSubmitLoading, setIsSubmitLoading] = useState(false)
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false)

    let fcmToken =  FirebaseHelper.getFcmToken();
    let deviceType = 'Desktop';
    let frontEnd = 'Website';
    if (isMobileOnly) {
        deviceType = 'Phone';
    }
    else if (isTablet) {
        deviceType = 'Tablet';
    }

    useEffect(() => {
      if(!params?.code) {
        history.push(ROUTES.HOME)
      }

      if(params?.code) {
        getAndSetFirebaseRecord()
      }
    }, [])

    const getAndSetFirebaseRecord = async () => {
        await FirebaseHelper.login();
        const response = await FirestoreHelper.getSetPassworRecord(params?.code);
        const record = response.data()
        // if(!record) {
        //     history.push(ROUTES.HOME)
        // }
        setLoader(false)
        setFirebaseSetPasswordRecord(record)
    }

    const handleOnFinish = async (values) => {
        if(firebaseSetPasswordRecord) {
            setIsSubmitLoading(true)
            const payload = encryptString(JSON.stringify({
                hipaa_id: firebaseSetPasswordRecord.hipaa_id,
                org_id: firebaseSetPasswordRecord.org_id,
                password: values.password
            }));

            const response = await callPostApiWithErrorHandling({
                url: 'web/user/set-password',
                payload: {
                    payload
                }
            })
            if(response) {
                await FirebaseHelper.login();
                await FirestoreHelper.removeSetPassworRecord(params?.code);
            }
            setIsSubmitLoading(false)
            if(response) {
                // return(
                //     <Modal
                //         centered
                //         title="Welcome to TecMe!"
                //         visible={true}
                //         okText="Continue"
                //         okButtonProps={{loading: loading}}
                //         closable={false}
                //         cancelButtonProps={{style: {display: 'none'}}}
                //         onOk={
                //             () => {
                //             setLoading(true)
                //                 ApiBaseHelper.post({
                //                     url: `login`,
                //                     payload: {
                //                         "email": firebaseSetPasswordRecord.email,
                //                         "password": values.password,
                //                         "fcm_token": fcmToken,
                //                         "device_type": deviceType,
                //                         "front_end": frontEnd,
                //                     }
                //                 }).then(async (res) => {
                //                     await getUserProfile({
                //                         orgId: res.data.data.orgId,
                //                         token: res.data.data.token,
                //                         tech: res.data.data.technician,
                //                         dispatch: dispatch,
                //                         redirectTarget: "_self",
                //                         touchPoint: res.data.data.touchPoint,
                //                         redirect: true,
                //                         role: res.data.data.role,
                //                         organization: res.data.data?.organization,
                //                         email: firebaseSetPasswordRecord.email,
                //                         password: values.password,
                //                         newSignup: true
                //                     })
                //                     setLoading(false)
                //                     notification.success({
                //                         message: 'TecMe',
                //                         description: 'Login Successful!',
                //                     })
                //                 }).catch((e) => {
                //                     setLoading(false)
                //                     notification.error({message: 'TecMe', description: 'Login Failed! Email or Password is invalid'})
                //                 })
                //             }
                //         }
                //     >
                //         <p>You have successfully set a new password for your account. Now, you'll be automatically directed to the onboarding form, where you can fill out your company and team information.</p>
                //     </Modal>
                // )
                Modal.success({
                    centered: true,
                    title: 'Welcome to TecMe!',
                    content: "You have successfully set a new password for your account. Now, you'll be automatically directed to the onboarding form, where you can fill out your company and team information.",
                    onOk: ()=>{
                        // history.push({
                        //     pathname: ROUTES.LOGIN,
                        //     state: { email: firebaseSetPasswordRecord.email }
                        // })
                        setLoading(true)
                        ApiBaseHelper.post({
                            url: `login`,
                            payload: {
                                "email": firebaseSetPasswordRecord.email,
                                "password": values.password,
                                "fcm_token": fcmToken,
                                "device_type": deviceType,
                                "front_end": frontEnd,
                            }
                        }).then(async (res) => {
                            await getUserProfile({
                                orgId: res.data.data.orgId,
                                token: res.data.data.token,
                                tech: res.data.data.technician,
                                dispatch: dispatch,
                                redirectTarget: "_self",
                                touchPoint: res.data.data.touchPoint,
                                redirect: true,
                                role: res.data.data.role,
                                organization: res.data.data?.organization,
                                email: firebaseSetPasswordRecord.email,
                                password: values.password,
                                newSignup: true
                            })
                            setLoading(false)
                            // notification.success({
                            //     message: 'TecMe',
                            //     description: 'Login Successful!',
                            // })
                        }).catch((e) => {
                            setLoading(false)
                            notification.error({message: 'TecMe', description: 'Error occured'})
                        })
                        return new Promise((resolve, reject) => {
                            setTimeout(Math.random() > 0.5 ? resolve : reject, 5000);
                          }).catch(() => console.log('Oops errors!'));
                    },
                    okText: "Continue"
                });
            }
        }
    }

    const handleOnValuesChange = (values) => {
        if(values.password) {
            setPassword(values.password)
        }
    }

    return (
        <>
            <MetaTags>
                <title >Set Password | TecMe </title>
                <meta name="description" content={META_DESCRIPTION} />
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>

            <Content className="main">
                <div className="container d-flex justify-content-center align-items-center" style={{ minHeight: 'calc(100vh - 110px)' }}>
                    <Card className=" w-100 border" style={{ maxWidth: 500 }}>

                        {
                            loader &&
                            <Result
                                status="success"
                                icon={<Lottie
                                    style={{height: 250}}
                                    animationData={LoaderAnimation}
                                    loop={true}
                                />}
                                title="Please wait for a while ..."
                                subTitle={"We are verifying the request."}
                            />
                        }

                        {
                            !loader && !firebaseSetPasswordRecord &&
                            <Result
                                status="info"
                                title="Seems like you have already set the password"
                                subTitle={"To reset your password, please click on the \"account settings\" button. If you have forgotten your password, please click on the \"forgot password\" button."}
                                extra={[
                                    <Button type="primary" key="account-settings">
                                        <a href={ROUTES.ACCOUNT_SETTING}>
                                            Account Settings
                                        </a>
                                    </Button>,
                                    <Button key="forgot-password">
                                        <a href={ROUTES.FORGOT_PASSWORD}>
                                            Forgot Password
                                        </a>
                                    </Button>,
                                ]}
                            />
                        }

                        {
                            firebaseSetPasswordRecord &&
                            <>
                                <Title>
                                    Set New Password
                                </Title>
                                <Typography.Text type="secondary">
                                    Create a strong, secure password that's easy for you to remember and hard for others to guess.
                                </Typography.Text>
                                {/*<Image loading="lazy" preview={false} src="/images/reset-password.png" />*/}
                                <Form
                                    layout="vertical"
                                    onFinish={handleOnFinish}
                                    form={form}
                                    onValuesChange={handleOnValuesChange}
                                    size="large"
                                >
                                    <Item
                                        label="New Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter password',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (value.length > 0 && isSubmitDisabled) {
                                                        return Promise.reject(new Error('Please enter strong password'));
                                                    }
                                                    return Promise.resolve();

                                                },
                                            }),
                                        ]}
                                        // help={isSubmitDisabled ? "Please enter strong password" : ''}
                                        // validateStatus={isSubmitDisabled ? 'error' : 'validating'}
                                    >
                                        <Input.Password
                                            size="large"
                                            placeholder="Something that you won't forgot"
                                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        />
                                    </Item>
                                    <PasswordStrengthBar
                                        className="mt-4"
                                        password={password}
                                        onChangeScore={(passwordValue) => {
                                            if (passwordValue === 3 || passwordValue === 4) {
                                                setIsSubmitDisabled(false)
                                            } else {
                                                setIsSubmitDisabled(true)
                                            }
                                        }}
                                    />
                                    <Item
                                        label="Confirm New Password"
                                        name="confirmPassword"
                                        dependencies={['password']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please re-enter password',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password
                                            size={"large"}
                                            placeholder="*********"
                                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        />
                                    </Item>

                                    <Item className="mt-3">
                                        <Space className='w-100 justify-content-end'>
                                            <Button
                                                type="primary"
                                                size="large"
                                                loading={isSubmitLoading}
                                                htmlType="submit"
                                                disabled={isSubmitDisabled}
                                            >
                                                Confirm
                                            </Button>
                                        </Space>
                                    </Item>
                                </Form>
                            </>
                        }
                    </Card>
                </div>
            </Content>
        </>
    )
}

export default SetPassword;
