import axios from "axios";
import { ApiBaseHelper } from "./api-base-helper";
import { useSelector } from "react-redux";
import { DEFAULT_SERVER } from "./constants";

export async function generateAiResponse(prompt, token){

    try {
      // Make the API request to ChatGPT
    //   https://api.openai.com/v1/chat/completions
        const response = await axios.post(DEFAULT_SERVER + '/web/v2/generative-ai',
           {
                prompt: prompt,
                model: 'gpt-4'
            },
            {
                headers: {
                    Authorization: 'Bearer ' + token
                },
            },
        );
        return response?.data?.choices[0]?.message;
    } catch (error) {
        console.error('Error:', error);
    }

    // ApiBaseHelper.post({
    //     url: `web/v2/generative-ai`,
    //     headers: {
    //         Authorization: 'Bearer ' + token
    //     },
    //     payload: {
    //         prompt: prompt,
    //     },
    // }).then((res) => {
    //     console.log(res)
    //     return res?.data?.choices[0]?.message;
    // }).catch((error) => {
    //     console.error(error)
    // })
};
