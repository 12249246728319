import React from 'react'
import { MetaTags } from 'react-meta-tags'
import { META_DESCRIPTION, META_IMAGE } from '../../../../../../utils/constants'
import { Button, Col, Form, Input, Layout, Row, Space, Typography, notification } from 'antd'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import UtilityHeader from '../../../../../components/utility-header'
import routes from '../../../../../../utils/routes'
import {Link} from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { useRef } from 'react'
import { useEffect } from 'react'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import { getUserProfile } from '../../../../../../utils/helper'
import ReCAPTCHA from 'react-google-recaptcha';
import PasswordStrengthBar from 'react-password-strength-bar';
import { FirebaseHelper } from "../../../../../../utils/firebase-helper";
import { isBrowser, isTablet, isMobileOnly } from 'react-device-detect';
import './styles.scss'

const {Title, Paragraph, Text} = Typography
const {Content} = Layout

const GuestSignUp = (props) => {
    const dispatch = useDispatch();
    const [phone, setPhone] = useState("")
    const [error, setError] = useState();
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [IsFirstName, setIsFirstName] = useState(false);
    const [IsLastName, setIsLastName] = useState(false);
    let history = useHistory();
    const captchaRef = useRef(null)

    const onFinish = async (values) => {
        console.log(values)
        setLoading(true);

        let fcmToken = await FirebaseHelper.getFcmToken();
        let deviceType = 'Desktop';
        let frontEnd = 'Website';
        if (isMobileOnly) {
            deviceType = 'Phone';
        }
        else if (isTablet) {
            deviceType = 'Tablet';
        }
        ApiBaseHelper.post({
            url: `register`,
            payload: {
                "firstName": values.fname,
                "lastName": values.lname,
                "email": props.email,
                "password": values.password,
                "phone": values.phoneNumber,
                "fcm_token": fcmToken,
                "device_type": deviceType,
                "front_end": frontEnd,
            }
        }).then((res) => {
            return ApiBaseHelper.post({
                url: `user-management/accept-invite/${props.ocaId}/${props.orgId}`,
                headers: {
                    Authorization: 'Bearer ' + res.data.data.token,
                },
            })
        }).then((acceptRes) => {
            if(acceptRes){
                return ApiBaseHelper.post({
                    url: `login`,
                    payload: {
                        "email": props.email,
                        "password": values.password,
                        "org_id": props.orgId,
                        "switch": true,
                        "device_type": deviceType,
                        "front_end": frontEnd,
                    }
                })
            }
        }).then(async(loginRes) => {
            await getUserProfile({
                orgId: loginRes.data.data.orgId,
                token: loginRes.data.data.token,
                tech: loginRes.data.data.technician,
                dispatch: dispatch,
                redirectTarget: "_self",
                touchPoint: loginRes.data.data.touchPoint,
                isSendVerificationEmail: true,
                role: loginRes.data.data.role,
                organization: loginRes.data.data.organization,
                email: props.email,
                password: values.password,
            })
            setLoading(false)
            history.push(routes.DASHBOARD)
        }).catch((e) => {
            setLoading(false)
            openNotification({message: 'TecMe', description: 'Something went wrong. Please try again',})

        })
    };


    const openNotification = (value) => {
        notification.open({
            message: value.message,
            description: value.description,
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

  return (
    <>
        <MetaTags>
            <title>Guest Sign Up - TecMe </title>
            <meta name="description" content={META_DESCRIPTION}/>
            <meta name="robots" content="noindex, nofollow" />
        </MetaTags>
        <Content className="main">
            <div className="page">
                <div className="guest-signup-section">
                    <div className="guest-signup-ctn">
                        <div className="heading-section">
                            <Title level={2} style={{color:"rgba(0, 0, 0, 0.85)", marginBottom:16}}>Welcome!</Title>
                            <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", marginBottom:30, fontWeight:500}}>Please provide your personal details to create your TecMe account and join the {props?.company} team</Paragraph>
                        </div>
                        <div className="guest-signup-form-section">
                            <Form
                                layout='vertical'
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                            >
                                <Row gutter={12}>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            label="First Name"
                                            name="fname"
                                            onChange={(event) => {
                                                event.target.value.match(/^[A-Za-z]*$/) ? setIsFirstName(false) : setIsFirstName(true)
                                            }}
                                            rules={[
                                                {
                                                    required: true,
        
                                                    message: 'Please enter your first name',
                                                },
                                                {
        
                                                    max: 20,
                                                    message: 'First name should not be more than 20 characters',
                                                },
                                            ]}
                                            style={{marginBottom:12}}
                                        >
                                            <Input className='guest-signup-input' />
                                        </Form.Item>
                                        {IsFirstName && <p style={{ padding: 0, marginBottom: 16, color: '#F04A22' }}>Numbers, spaces and special characters are not allowed</p>}
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Form.Item
                                            label="Last Name"
                                            name="lname"
                                            onChange={(event) => {
                                                event.target.value.match(/^[A-Za-z]*$/) ? setIsLastName(false) : setIsLastName(true)
                                                }}
                                            rules={[{ required: true, message: 'Please enter your last name' }]}
                                            style={{marginBottom:12}}
                                        >
                                            <Input className='guest-signup-input' />
                                        </Form.Item>
                                        {IsLastName && <p style={{ padding: 0, marginBottom: 16, color: '#F04A22' }}>Numbers, spaces and special characters are not allowed</p>}
                                    </Col>
                                </Row>
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    style={{marginBottom:12}}
                                    rules={[
                                        {
                                            required: true,
                                            min: 0,
                                            message: 'Please enter new password',
                                        },
                                        {

                                            min: 8,
                                            message: 'Password should be at least 8 characters or more',
                                        },
                                    ]}
                                >
                                    <Input.Password 
                                        type="password"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        autoComplete='off'
                                        className='guest-signup-input' />
                                </Form.Item>
                                <Form.Item
                                    label="Phone Number (optional)"
                                    name="phoneNumber"
                                    rules={[
                                        {
                                                                                        
                                            pattern: new RegExp(/^[0-9+]{3}[0-9+]{3}[0-9]{4}$/),
                                            message: "Phone number should be 10 digits"
                                        },
                                    ]}
                                >
                                    <Input
                                        className='guest-signup-input' 
                                        autoComplete='off'
                                    />
                                </Form.Item>
                                {/* <Form.Item>
                                {error && <p style={{ color: "red" }}>{error}</p>}
                                    { isVerified == false &&
                                        <ReCAPTCHA style={{ marginBottom: '1rem' }}
                                            onChange={(value) => {
                                                // console.log("Captcha  Value", value)
                                                setIsVerified(true)
                                                setStatus(false)
                                                isVerified == true && captchaRef.current.reset()
                                            }}
                                            sitekey={process.env.REACT_APP_CAPTCHA_KEY != undefined ? process.env.REACT_APP_CAPTCHA_KEY : '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'}
                                            ref={captchaRef}
                                        />}
                                    {status == true && < p style={{ color: 'red' }}>Please verify you're human.</p>}
                                    {
                                    isVerified == true &&
                                        <div style={{ color: 'green', marginBottom: '1rem' }}><CheckCircleOutlineIcon fontSize="large" /> You're human
                                        </div>
                                    }
                                </Form.Item> */}
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="guest-signup-button" loading={loading}>
                                        Accept Invitation
                                    </Button>
                                </Form.Item>
                                <Paragraph style={{color:"#787C81", fontWeight:500, marginTop:40, textAlign:"center"}}>By accepting invitation, I agree to the <a style={{color:"#787C81", textDecoration:"underline"}} href='https://tecme.io/privacy-policy/' target='_blank'>Privacy Policy</a> and <br /> <a href='https://tecme.io/terms-of-service/' target='_blank' style={{color:"#787C81", textDecoration:"underline"}} to={routes.TERM_OF_SERVICES}>Terms of Service</a>.</Paragraph>
                            </Form>
                        </div>
                    </div> 
                </div>
            </div>
        </Content>
    </>
  )
}

export default GuestSignUp