import { ApiBaseHelper } from "../../../../../../utils/api-base-helper";

export class EditProfileRepository {
  constructor(accessToken) {
    this.accessToken = accessToken;
  }

  validateEmail(email) {
    return ApiBaseHelper.get({
        url: `web/account?email=${email}`,
    });
  }

  saveUserData(data) {
    return ApiBaseHelper.put({
        url: "user",
        headers: {
            Authorization: 'Bearer ' + this.accessToken,
        },
        payload: data
    })
  }

  getWorkHistoryAndProjects() {
    return ApiBaseHelper.get({
        url: `dynamic/project`,
        headers: {
            Authorization: 'Bearer ' + this.accessToken,
        },
    });
  }

  deleteWorkHistoryAndProjects(id) {
    return ApiBaseHelper.delete({
        url: `dynamic/project/${id}`,
        headers: {
            Authorization: 'Bearer ' + this.accessToken,
        },
    });
  }

  addWorkHistoryAndProjects(data) {
    return ApiBaseHelper.post({
        url: `dynamic/project`,
        headers: {
            Authorization: 'Bearer ' + this.accessToken,
        },
        payload: data
    });
  }

  changeWorkHistoryAndProjects(id, data) {
    return ApiBaseHelper.put({
        url: `dynamic/project/${id}`,
        headers: {
            Authorization: 'Bearer ' + this.accessToken,
        },
        payload: data
    });
  }

}
