// External Imports
import { Row, Layout, Card, Col, Tag, Typography, Space, Button, Divider, Avatar, Image, Rate, Skeleton, notification } from 'antd';
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ArrowRightOutlined, MessageOutlined, SelectOutlined, BarsOutlined } from '@ant-design/icons'
import { isMobile, isTablet } from 'react-device-detect';
import BottomNavigation from 'reactjs-bottom-navigation';
import Moment from 'moment';
import {extendMoment} from 'moment-range';

// Internal Imports
import { SECONDARY_COLOR } from '../../../../../../../utils/constants';
import ROUTES from '../../../../../../../utils/routes';
import PageHeaderWrapper from '../../../../../../components/page-header-breadcrumbs';
import RequestChangeModal from './request-change-modal';
import EditContractModal from './edit-contract-modal';


// Assets Imports
import HipaaComplianceImage from '../../../../../../../assets/images/hipaa-compliance-light.png';
import HipaaComplianceMobile from '../../../../../../../assets/images/hipaa-compliance-mobile.png';
import Bulb from '../../../../public/create-send-contract/assets/images/bulb.svg'
import RejectWithdrawContractModal from './reject-withdraw-modal';
import { convertCentsIntoDollarsString, encryptString, nameFormat, getTableTimeFormat, capitalizeFirstWordOfEveryLetter, capitalizeFirstLetter, canReadPageOnly, convertIfLight, stripHtml } from '../../../../../../../utils/helper';
import { FirebaseHelper, FirestoreHelper } from '../../../../../../../utils/firebase-helper';
import { RtmRepository } from '../../../real-time-messaging/repository/rtm-repository';
import AcceptBookingModal from '../../../service-details/components/accept-booking-modal';
import GatherInformationModal from './gather-information-modal';
import TechConnectModal from '../../../../../../components/modals/tech-connect-modal';
import MessagesRightPaneView from '../../../real-time-messaging/components/messages-right-pane-view';
import { ApiBaseHelper } from '../../../../../../../utils/api-base-helper';
import { decode } from 'html-entities';

const { Content } = Layout
const { Title, Text, Paragraph } = Typography
const moment = extendMoment(Moment);

const acceptProposalPoints = [
    "I agree to take on responsibility for servicing this contract.",
    "I have confirmed via chat that I understand the contract and its requirements.",
    "Likewise, I possess all the required tools to work on this contract.",
    "I agree to work on the rates mentioned in the contract."
]

const ERROR_MESSAGE_TO_SHOW_ACCOUNT_SETUP_MODAL = "Technician does not have stripe connect account"

const DraftContract = ({ getAndSetContractDetails, contractDetails = {}, repository }) => {
    const params = useParams();
    const history = useHistory();
    const contractId = params?.contractId?.toUpperCase()
    const isHippaComplianceRequired = contractDetails.HIPAA_Compliance === '1' || contractDetails.HIPAA_Compliance === 'true'
    const requiredSkillsList = contractDetails.Skills_Required?.length ? contractDetails.Skills_Required.split(",") : []
    const user = useSelector((state) => state.user);
    const tech = useSelector((state) => state.tech);
    const [requestAChangeModalInfo, setRequestAChangeModalInfo] = useState({
        isVisible: false
    })
    const [editContractModalInfo, setEditContractModalInfo] = useState({
        isVisible: false
    })
    const [activeTabId, setActiveTabId] = useState(0)
    const [rejectWithdrawModalInfo, setRejectWithdrawModalInfo] = useState({
        isVisible: false,
        type: ''
    })
    const [loading, setLoading] = useState(false);
    const [activeConversation, setActiveConversation] = useState(undefined);
    const [messagesCard, setMessagesCard] = useState(undefined);
    const [rtmRepository, setRtmRepository] = useState(undefined);
    const [isAcceptBookingModalVisible, setIsAcceptBookingModalVisible] = useState(false);
    const [technicianCardPaymentCapabilities, setTechnicianCardPaymentCapabilities] = useState(undefined);
    const [isGatherInformationModaLVisible, setIsGatherInformationModaLVisible] = useState(false);
    const [isTechnicianAccountSetupModalVisible, setIsTechnicianAccountSetupModalVisible] = useState(false);
    const [itcDetails, setItcDetails] = useState({})
    const [loadingItc, setLoadingItc] = useState(true)

    const fetchItcDetails = () => {
        setLoadingItc(true)
        ApiBaseHelper.get({
            url: `itc/${contractDetails?.ITC}`
        }).then((res) => {
            setItcDetails(res?.data?.data)
            setLoadingItc(false)
        }).catch((error) => {
            console.error(error)
            setLoadingItc(false)
        })
    }

    useEffect(async () => {
        if((user || tech) && contractDetails && !activeConversation) {
            const activeUser = user || tech
            const hipaaID = user ? contractDetails?.Provider_Org?.HIPAA_Primary_Contact : contractDetails?.Customer_Org?.HIPAA_Primary_Contact;

            if(activeUser) {
                setRtmRepository(new RtmRepository(activeUser.token))
            }
    
            await FirebaseHelper.login();
            FirestoreHelper.getConversations({
                user_id: activeUser['id'],
                snapshot: (querySnapshot) => {
                    querySnapshot.forEach(async (doc) => {
                        let item = doc.data();
                        item.id = doc.id;
                        if(!item.group && item.participants?.includes(hipaaID)) {
                            setActiveConversation(item)
                        }
                    });
                }
            });
        }
    }, [user, tech, contractDetails])

    useEffect(async () => {
        if(!!tech && repository && !technicianCardPaymentCapabilities) {
            const response = await repository.getTechnicianCardPaymentCapabilities();
            setTechnicianCardPaymentCapabilities(response !== ERROR_MESSAGE_TO_SHOW_ACCOUNT_SETUP_MODAL ? response?.data?.data : ERROR_MESSAGE_TO_SHOW_ACCOUNT_SETUP_MODAL)
        }
    }, [repository])

    useEffect(() => {
      if(activeConversation) {
        const messages = (
            <MessagesRightPaneView
                key={`messages-${activeConversation.id}`}
                conversation={activeConversation}
                user={user || tech}
                chatBoxStatus={true}
                serviceDetails={true}
                contractPage
                isTech={!!tech}
                // service={service}
                // booking={booking}
                onClickBack={() => ''}
            />
        )
        setMessagesCard(messages)
      }
    }, [activeConversation])
    
    const handleRequestAChangeClick = () => {
        setRequestAChangeModalInfo(prevState => ({
            ...prevState,
            isVisible: true
        }))
    }

    const handleEditContractClick = () => {
        setEditContractModalInfo(prevState => ({
            ...prevState,
            isVisible: true
        }))
    }

    const handleAcceptContractProposalClick = () => {
        setIsAcceptBookingModalVisible(false)
        setLoading(true)
        repository.acceptProposal(params?.contractId, {
            Customer_Contact: contractDetails.Customer_Org?.HIPAA_Primary_Contact
        }).then(async (res) => {
            if(res.data?.status === 'success') {
                const techName = nameFormat(`${tech?.firstname} ${tech?.lastname}`);
                const location = window.location.href;
                // await sendMessage(`${techName} has accepted your contract proposal.<br><br> To view the details, simply click on the link below:<br> ${location}`)
                await getAndSetContractDetails()
            }
            setLoading(false)
        }).catch(error => {
            console.error(error)
            notification['error']({
                message: 'Error',
                description: error?.response?.data?.message,
            });
            setLoading(false)
        })
    }

    useEffect(() => {
        if(contractDetails){
            fetchItcDetails()
        }
    }, [contractDetails])

    const getRightSectionButtons = () => {
        const handleRejectWithdrawClick = (type) => setRejectWithdrawModalInfo({ isVisible: true, type });

        if(tech) {
            return (
                <>
                    <Button
                        size='large'
                        type='primary'
                        className='w-100'
                        onClick={async () => {
                            setLoading(true)
                            const response = await repository.getTechnicianCardPaymentCapabilities();
                            const filteredResponse = response !== ERROR_MESSAGE_TO_SHOW_ACCOUNT_SETUP_MODAL ? response?.data?.data : ERROR_MESSAGE_TO_SHOW_ACCOUNT_SETUP_MODAL 
                            setTechnicianCardPaymentCapabilities(filteredResponse)
                            if(filteredResponse === ERROR_MESSAGE_TO_SHOW_ACCOUNT_SETUP_MODAL) {
                                setIsTechnicianAccountSetupModalVisible(true)
                                setLoading(false)
                                return
                            }

                            if(!!filteredResponse?.requirements?.length) {
                                setIsGatherInformationModaLVisible(true)
                            } else {
                                setIsAcceptBookingModalVisible(true)
                            }
                            setLoading(false)
                        }}
                        loading={loading}
                    >
                        Accept Contract Proposal
                    </Button>
                    <Button size='large' className='w-100' onClick={() => handleRejectWithdrawClick('Reject')}>
                        Reject Contract Proposal
                    </Button>
                    {/* <Button size='large' className='w-100' onClick={handleRequestAChangeClick}>
                        Request a change
                    </Button> */}
                </>
            )
        }
        if(user && !canReadPageOnly(user?.role)) {
            return (
                <>
                    <Button id="outline-default-btn" size='large' className='w-100' onClick={handleEditContractClick} disabled={canReadPageOnly(user?.role)}>
                        Edit Contract
                    </Button>
                    <Button id="outline-default-btn" size='large' className='w-100' onClick={() => handleRejectWithdrawClick('Withdraw')} disabled={canReadPageOnly(user?.role)}>
                        Withdraw Contract Proposal
                    </Button>
                </>
            )
        }
    }

    const getRightSectionContent = () => {
        const handleViewFullProfileClick = () => {
            history.push(ROUTES.TECH_PROFILE_NEW.replace(':profileUrl', contractDetails.Provider_Org?.Profile_URL))
        }

        if(tech) {
            if(isMobile || isTablet) {
                return (
                    <>
                        <Space align='center' size="middle">
                            <Avatar shape='circle' size={50} src={contractDetails.Customer_Org?.Logo} alt={contractDetails.Customer_Org?.Name}>
                                {contractDetails.Customer_Org?.Name[0].toUpperCase()}    
                            </Avatar>
                            <Title level={5} className='d-flex flex-column m-0'>
                                {capitalizeFirstWordOfEveryLetter(nameFormat(contractDetails.Customer_Org?.Name))}
                                <Text type='secondary' className='mobile-sub-heading'>Customer</Text>
                            </Title>
                        </Space>
                    </>
                )
            }

            return (
                <>
                    <Text strong className='fs-6'>
                        About the client
                    </Text>
                    <Space size="large">
                        <Avatar shape='circle' size={60} src={contractDetails.Customer_Org?.Logo} alt={contractDetails.Customer_Org?.Name}>
                            {contractDetails.Customer_Org?.Name[0].toUpperCase()}    
                        </Avatar>
                        <Title level={5}>
                            {capitalizeFirstWordOfEveryLetter(contractDetails.Customer_Org?.Name.replace(/\s+/g, ' '))}
                        </Title>
                    </Space>
                    {/* <Space direction='vertical' size={2}>
                        <Text strong className='fs-6'>7 Active Contracts</Text>
                        <Paragraph type='secondary' className='secondary-color-sub-heading'>
                            Contracts History
                        </Paragraph>
                    </Space> */}
                    <Space direction='vertical' size={2}>
                        <Text strong className='fs-6'>Verified</Text>
                        <Paragraph type='secondary' className='secondary-color-sub-heading'>
                            Payment Method
                        </Paragraph>
                    </Space>
                </>
            )
        }
        if(user) {
            if(isMobile || isTablet) {
                return (
                    <>
                        {
                            contractDetails?.ITC &&
                            <Space align='center' size="middle">
                                <Avatar 
                                    src={itcDetails?.Brand} 
                                    size={'small'} 
                                    className='contract-avatar'
                                    style={{
                                        backgroundColor: convertIfLight(`#${itcDetails?.Brand_Color}`)
                                    }}
                                >
                                    {`${capitalizeFirstLetter(itcDetails?.Company_Name?.[0])}`}
                                </Avatar>
                                <Title level={5} className='d-flex flex-column m-0'>
                                    {capitalizeFirstWordOfEveryLetter(itcDetails?.Company_Name)}
                                    <Text type='secondary' className='mobile-sub-heading'>IT Service Company</Text>
                                </Title>
                            </Space> 
                        }
                        <Divider />
                        <Space align='center' size="middle">
                            <Avatar shape='circle' size={50} src={contractDetails.Provider_Org?.Contact_Image} alt={contractDetails?.Provider_Org?.HIPAA_Contact_Name || contractDetails.Provider_Org?.Name}>
                                {contractDetails?.Provider_Org?.HIPAA_Contact_Name ? contractDetails?.Provider_Org?.HIPAA_Contact_Name[0].toUpperCase() : contractDetails.Provider_Org?.Name[0].toUpperCase()}    
                            </Avatar>
                            <Title level={5} className='d-flex flex-column m-0'>
                                {capitalizeFirstWordOfEveryLetter(nameFormat(contractDetails?.Provider_Org?.HIPAA_Contact_Name || contractDetails.Provider_Org?.Name))}
                                <Text type='secondary' className='mobile-sub-heading'>Technician</Text>
                            </Title>
                        </Space>
                    </>
                )
            }

            return (
                <>
                    {
                        contractDetails?.ITC &&
                        <>
                           <Text strong className='fs-6'>
                                About IT Service Company
                            </Text> 
                            <Space size="large">
                                <Avatar 
                                    src={itcDetails?.Brand} 
                                    size={'small'} 
                                    className='contract-avatar'
                                    style={{
                                        backgroundColor: convertIfLight(`#${itcDetails?.Brand_Color}`)
                                    }}
                                >
                                    {`${capitalizeFirstLetter(itcDetails?.Company_Name?.[0])}`}
                                </Avatar>
                                <Title level={5} className='m-0'>
                                    {capitalizeFirstWordOfEveryLetter(itcDetails?.Company_Name)}
                                </Title>
                            </Space>
                            <Text strong>Company Bio:</Text>
                            <Paragraph className='m-0' ellipsis={{ rows: 7 }} >
                                {(itcDetails?.Description && itcDetails.Description.toLowerCase() !== 'undefined') ? capitalizeFirstLetter(decode(stripHtml(itcDetails.Description))) : 'Company bio unavailable'}
                            </Paragraph>
                            <Text 
                                strong 
                                className='tecme-secondary-color d-flex align-items-center cursor-pointer' 
                                onClick={(e) => {
                                    history?.push(ROUTES.ITC_HOME.replace(':subdomain', itcDetails?.Subdomain))
                                    e.stopPropagation()
                                }}
                            >
                                View ITSC Webpage <ArrowRightOutlined 
                                    className='ms-2' 
                                    style={{
                                        position: 'relative',
                                        top: '2px'
                                    }}
                                />
                            </Text>
                        </>
                    }
                    <Divider />
                     <>
                        <Text strong className='fs-6'>
                            About the technician
                        </Text>
                        <Space size="large">
                            <Avatar shape='circle' size={65} src={contractDetails.Provider_Org?.Contact_Image} alt={contractDetails?.Provider_Org?.HIPAA_Contact_Name || contractDetails.Provider_Org?.Name}>
                                {contractDetails?.Provider_Org?.HIPAA_Contact_Name ? contractDetails?.Provider_Org?.HIPAA_Contact_Name[0].toUpperCase() : contractDetails.Provider_Org?.Name[0].toUpperCase()}
                            </Avatar>
                            <Space direction='vertical' size={0}>
                                <Title level={5} className='m-0'>
                                    {capitalizeFirstWordOfEveryLetter(nameFormat(contractDetails?.Provider_Org?.HIPAA_Contact_Name ||contractDetails.Provider_Org?.Name))}
                                </Title>
                                {contractDetails.Provider_Org?.Average_Rating && (
                                    <Space className='rating-wrapper'>
                                        <Rate disabled allowHalf value={contractDetails.Provider_Org?.Average_Rating} /> {contractDetails.Provider_Org?.Average_Rating.toFixed(1)}
                                    </Space>
                                )}
                            </Space>
                        </Space>
                        <Text>
                            <Text strong>Location:</Text> {capitalizeFirstLetter(contractDetails.Provider_Org?.City)}, {contractDetails.Provider_Org?.State}
                        </Text>
                        {contractDetails.Provider_Org?.Completed_Services && (
                            <Text>
                                <Text strong>Completed Services:</Text> {contractDetails.Provider_Org.Completed_Services}
                            </Text>
                        )}
                        <Text strong>Profile Bio:</Text>
                        <Paragraph className='m-0' ellipsis={{ rows: 9 }} >
                            {(contractDetails.Provider_Org?.Bio && contractDetails.Provider_Org.Bio.toLowerCase() !== 'undefined') ? capitalizeFirstLetter(contractDetails.Provider_Org.Bio) : 'Profile bio unavailable'}
                        </Paragraph>
                        <Text strong className='tecme-secondary-color d-flex align-items-center cursor-pointer' onClick={handleViewFullProfileClick}>
                            View Full Profile <ArrowRightOutlined className='ms-2' />
                        </Text>
                    </>
                </>
            )
        }
    }

    const contractProposalHeader = () => {
        // let contractCreationTime;
        // if(contractDetails.Active_Date_Time) {
        //     contractCreationTime = moment(moment.utc(contractDetails.Active_Date_Time).toDate()).local().fromNow()
        // }

        return (
            <Space size="large" className='mid-section flex-wrap w-100' align='center'>
                <Space size="large" align='center'>
                    <Title className='m-0' style={{
                        fontSize: (isMobile || isTablet) ? 18 : 25
                    }}>
                        Contract Proposal
                    </Title>
                    <Tag className='contract-status-tag'>{contractDetails.Status}</Tag>
                </Space>
                {contractDetails.Active_Date_Time && (
                    <Text> <Text strong> Created at: </Text> <Text type='secondary'>{getTableTimeFormat(contractDetails.Active_Date_Time)}</Text></Text>
                )}
                {
                    contractDetails?.ITC &&
                    <Text> <Text strong> Contract via: </Text> <Text type='secondary'>{itcDetails?.Company_Name} ITSC</Text></Text>
                }
            </Space>
        )
    }

    const contractProposalDetails = () => {
        let billingCycle = '/hr'
        if(contractDetails.Type === 'Fixed') {
            switch (contractDetails.Billing_Frequency) {
                case "Monthly":
                    billingCycle = '/month'
                    break;
                case "Weekly":
                    billingCycle = '/week'
                    break;
                case "Bi-weekly":
                    billingCycle = ' bi-weekly'
                    break;
                default:
                    break;
            }
        }

        return (
            <Card className='w-100'>
                <Space className='w-100' direction='vertical' size="large">
                    <Space className='justify-content-between w-100'>
                        {(isMobile || isTablet) ? (
                            <>
                                <Title level={5} className='d-flex flex-column m-0'>
                                    ${convertCentsIntoDollarsString(contractDetails.Billing_Rate)}{billingCycle}
                                    <Text type='secondary' className='mobile-sub-heading'>{contractDetails.Type}</Text>
                                </Title>
                                {isHippaComplianceRequired && (
                                    <Image preview={false} src={HipaaComplianceMobile} alt="hipaa image" />
                                )}
                            </>
                        ) : (
                            <>
                                <Space direction='vertical'>
                                    <Title level={4} className='m-0'>
                                        Contract Type
                                    </Title>
                                    <Text>
                                        {capitalizeFirstLetter(contractDetails.Type == 'Fixed' ? 'Retainer' : contractDetails.Type == 'Per Ticket' ? 'Per Issue' : contractDetails.Type)}
                                    </Text>
                                </Space>
                                <Space direction='vertical'>
                                    <Title level={4} className='m-0'>
                                        Contract Rate
                                    </Title>
                                    <Text>
                                        ${convertCentsIntoDollarsString(contractDetails.Billing_Rate)}{billingCycle}
                                    </Text>
                                </Space>
                                {isHippaComplianceRequired && (
                                    <Space direction='vertical'>
                                        <Title level={4} className='m-0'>
                                            Compliance Required
                                        </Title>
                                            <Image preview={false} src={HipaaComplianceImage} alt="hipaa image" width={170} />
                                    </Space>
                                )}
                            </>
                        )}
                    </Space>
                    <Space direction='vertical'>
                        <Title level={4} className='m-0'>
                            Support Description
                        </Title>
                        <Text>
                            {capitalizeFirstLetter(contractDetails.Description)}
                        </Text>
                    </Space>
                    {!!requiredSkillsList.length && (
                        <Space direction='vertical'>
                            {(!isMobile && !isTablet) && (
                                <Title level={4} className='m-0'>
                                    Skills Required
                                </Title>
                            )}
                            <Space className='flex-wrap'>
                                {requiredSkillsList.map(skill => <Tag key={skill}>{skill}</Tag>)}
                            </Space>
                        </Space>
                    )}
                </Space>
            </Card>
        )
    }

    const contractProposalMessages = (isDesktop = false) => {
        const skeleton = (
            <>
                <Skeleton active />
                <Skeleton active />
            </>
        )

        return isDesktop ? (
            <Card title="Messages" className='w-100'>
                {messagesCard || skeleton}
            </Card>
        )  : (messagesCard || skeleton)
    }

    const contractActionsAndProfileDetails = (showCompleteContent = false) => {
        return (
            <Card className='h-100 right-side-card'>
                <Space direction='vertical' className='w-100'>
                    {getRightSectionButtons()}
                </Space>
                {showCompleteContent && (
                    <>
                        {!canReadPageOnly(user?.role) && <Divider />}
                        <Space direction='vertical' size="middle">
                            {getRightSectionContent()}
                        </Space>
                    </>
                )}
            </Card>
        )
    }

    const disclaimer = (
        <Space className='w-100 disclaimer p-2 ps-3' size={25}>
            <Image src={Bulb} preview={false} alt="disclaimer" width={28} height={28} className='ms-2' />
            <Text>Waiting for the technician to review and accept your contract proposal</Text>
        </Space>
    )

    const sendMessage = async (message) => {
        const activeUser = user || tech
        await FirebaseHelper.login();
        await FirestoreHelper.sendMessage({
            conversation_id: activeConversation.id,
            message: encryptString(message),
            user_id: activeUser.id
        });
        await rtmRepository?.sendMessage({
            conversationId: activeConversation.id,
            message,
            createdAt: moment().utc().toISOString()
        })
    }

    return (
        <>
            <Content className="main">
                <div className="page view-edit-contract-proposal">
                    <Row gutter={[20, (isMobile || isTablet) ? 0 : 20]}>
                        <Col span={24}>
                            <PageHeaderWrapper
                                routes={[
                                    {
                                        path: ROUTES.CONTRACTS,
                                        breadcrumbName: 'Contracts',
                                    },
                                    {
                                        breadcrumbName: `Contract`,
                                    },
                                ]}
                            />
                        </Col>
                        {(!isMobile && !isTablet) && (
                            <Col span={24}>
                                <div className="auth-container">
                                    <Space direction='vertical' className='w-100'>
                                        {contractProposalHeader()}
                                        {!!user && disclaimer}
                                        <Row gutter={[12,12]} className="main-content">
                                            <Col>
                                                <Row gutter={[0,12]} className='h-100'>
                                                    {contractProposalDetails()}
                                                    {contractProposalMessages(true)}
                                                </Row>
                                            </Col>
                                            <Col>
                                                {contractActionsAndProfileDetails(true)}
                                            </Col>
                                        </Row>
                                    </Space>
                                </div>
                            </Col>
                        )}
                        {(isMobile || isTablet) && (
                            <Col span={24}>
                                <div className="auth-container">
                                    <Space direction='vertical' className='w-100'>
                                        {(activeTabId === 0 || activeTabId === 2) && contractProposalHeader()}
                                        {activeTabId === 1 && contractProposalMessages(false)}
                                        {activeTabId === 2 && contractActionsAndProfileDetails()}
                                        {activeTabId === 0 && (
                                            <>
                                                {getRightSectionContent()}
                                                {contractProposalDetails()}   
                                            </>
                                        )}
                                    </Space>
                                </div>
                            </Col>
                        )}
                    </Row>
                    {!!tech && (
                        <RequestChangeModal
                            tech={tech}
                            sendMessage={sendMessage}
                            contractDetails={contractDetails}
                            contractId={params?.contractId}
                            repository={repository}
                            visible={requestAChangeModalInfo.isVisible}
                            onCancel={() => setRequestAChangeModalInfo(prevState => ({
                                ...prevState,
                                isVisible: false
                            }))}
                            onOk={() => setRequestAChangeModalInfo(prevState => ({
                                ...prevState,
                                isVisible: false
                            }))}
                        />
                    )}
                    <EditContractModal
                        user={user}
                        isActiveMode={false}
                        sendMessage={sendMessage}
                        getAndSetContractDetails={getAndSetContractDetails}
                        contractDetails={contractDetails}
                        contractId={params?.contractId}
                        repository={repository}
                        data={editContractModalInfo}
                        visible={editContractModalInfo.isVisible}
                        onCancel={() => setEditContractModalInfo(prevState => ({
                            ...prevState,
                            isVisible: false
                        }))}
                        onOk={() => setEditContractModalInfo(prevState => ({
                            ...prevState,
                            isVisible: false
                        }))}
                    />
                    <RejectWithdrawContractModal
                        user={user}
                        tech={tech}
                        sendMessage={sendMessage}
                        getAndSetContractDetails={getAndSetContractDetails}
                        contractDetails={contractDetails}
                        contractId={params?.contractId}
                        repository={repository}
                        visible={rejectWithdrawModalInfo.isVisible}
                        type={rejectWithdrawModalInfo.type}
                        onCancel={() => setRejectWithdrawModalInfo(prevState => ({
                            ...prevState,
                            isVisible: false,
                        }))}
                        onOk={() => {
                            setRejectWithdrawModalInfo(prevState => ({
                                ...prevState,
                                isVisible: false,
                            }));
                            
                        }}
                    />
                    <AcceptBookingModal
                        visible={isAcceptBookingModalVisible}
                        onClose={() => setIsAcceptBookingModalVisible(false)}
                        onAccept={handleAcceptContractProposalClick}
                        points={acceptProposalPoints}
                    />
                    {!!tech && (
                        <>
                            <GatherInformationModal
                                visible={isGatherInformationModaLVisible}
                                technicianCardPaymentCapabilities={technicianCardPaymentCapabilities}
                                onCancel={() => setIsGatherInformationModaLVisible(false)}
                                onOk={() => setIsGatherInformationModaLVisible(false)}
                            />
                            <TechConnectModal
                                tech={tech}
                                serviceDetails={isTechnicianAccountSetupModalVisible}
                                onCancel={() => setIsTechnicianAccountSetupModalVisible(false)}
                            />
                        </>
                    )}
                </div>
            </Content>
            {(isMobile || isTablet) && (
                <BottomNavigation
                    activeBgColor={SECONDARY_COLOR}
                    activeTextColor={'white'}
                    items={[
                        {
                            title: 'Details',
                            icon: <BarsOutlined style={{ fontSize: '14px' }} />,
                            activeIcon: <BarsOutlined style={{fontSize: '14px', color: 'white'}}/>
                        },
                        {
                            title: 'Messages',
                            icon: <MessageOutlined style={{fontSize: '14px'}}/>,
                            activeIcon: <MessageOutlined style={{fontSize: '14px', color: 'white'}}/>
                        },
                        {
                            title: 'Actions',
                            icon: <SelectOutlined style={{fontSize: '14px'}}/>,
                            activeIcon: <SelectOutlined style={{fontSize: '14px', color: 'white'}}/>
                        }
                    ]}
                    defaultSelected={activeTabId}
                    onItemClick={({ id }) => {
                        setActiveTabId(id)
                        document.querySelector("body")?.scrollIntoView()
                    }}
                />
            )}
        </>
    )
}

export default DraftContract