import React, {useState} from 'react'
import { AddedItemCard } from './added-item'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import ActionModal from './delete-modal'
import { useSelector } from 'react-redux'
import { Avatar, Button, List, Space, Typography, notification } from 'antd'
import { nameFormat } from '../../../../../../utils/helper'
import '../assets/css/added-item.scss'
import CustomRenderEmpty from '../../../../../components/custom-render-empty'


const {Paragraph} = Typography

const SpecializationList = ({specializations, setShowAddedSpecialization, fetchSpecializations, handleNextClick, setShowBackBtn, loadingSpecializations, setEditSpecializationData, isFromItcProfile=false}) => {
    const tech = useSelector(state => state.tech)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deletingSpecialization, setDeletingSpecialization] = useState(false)
    const [deleteId, setDeleteId] = useState(null)

    const deleteSpecialization = () => {
        setDeletingSpecialization(true)
        ApiBaseHelper.delete({
            url: `itc/specialization/${deleteId}`,
            headers: {
                Authorization: `Bearer ${tech.token}`
            },
        }).then((res) => {
            if (res.data) {
                notification['success']({
                    message: 'TecMe',
                    description: "Specialization deleted successfully",
                });
                fetchSpecializations()
                setDeleteModal(false)
                setDeletingSpecialization(false)
            }
        }).catch((error) => {
            setDeletingSpecialization(false)
            notification['error']({
                message: 'TecMe',
                description: "An error Occurred",
            });
            console.log(error)
        })
    }
    return (
        <>
            <div className="added-item-list added-specialization-list">
                <CustomRenderEmpty
                    description={"No Specialization Added yet"}
                >
                    <List
                        loading={loadingSpecializations}
                        dataSource={specializations}
                        renderItem={ele => (
                            <List.Item
                                key={ele.id}
                            >
                                <AddedItemCard 
                                    isSpecialization={true}
                                    onClose={() => {
                                        setDeleteId(ele.id)
                                        setDeleteModal(true)
                                    }}
                                    onEdit={() => {
                                        setShowAddedSpecialization(isFromItcProfile ? true : false)
                                        setEditSpecializationData(ele)   
                                    }}
                                >
                                    <p>{ele?.Title}</p>
                                </AddedItemCard>
                            </List.Item>
                        )}
                    />
                </CustomRenderEmpty>
                <Space direction='vertical' className='d-block'>
                    <Button 
                        className='add-new-btn'
                        onClick={() => setShowAddedSpecialization(isFromItcProfile ? true : false)}
                    >
                        + Add New Specialization
                    </Button>
                    {
                        !isFromItcProfile &&
                        <Button 
                            className='itc-next-btn' 
                            type='primary' 
                            onClick={() => handleNextClick()}
                        >
                            Next
                        </Button>
                    }
                </Space>
            </div>
            <ActionModal
                visible={deleteModal}
                handleCancel={() => setDeleteModal(false)}
                handleClick={() => deleteSpecialization()}
                message={"This action will permanently delete the specialization. Please make sure you want to delete the item before proceeding."}
                loading={deletingSpecialization}
                deleteText={"Delete"}
            />
        </>
    )
}

export default SpecializationList