import { Button, Result, Typography } from 'antd'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ROUTES from '../../../../../utils/routes'
import { useDispatch } from 'react-redux'
import { updateUser } from '../../../../../redux/reducers/app'

const { Title, Paragraph } = Typography

const SuccessJobPost = ({user}) => { 

    const history = useHistory()


    return (
        <div className="success-job-section">
            <Result
                className='success-job-result'
                status="success"
                title={<Title level={3}>Congratulations on Posting Your Job!</Title>}
                subTitle={<Paragraph className='success-job-text'>You've taken a significant step towards finding the perfect candidate for your needs. Your job listing is now live and visible to a vast pool of talented individuals who are eager to join your team.</Paragraph>}
                extra={[
                    <Button 
                        type="primary" 
                        style={{height:40, fontSize:16}} 
                        onClick={() => { 
                            history.push(ROUTES.JOB_POSTINGS)
                        }}
                    >
                        View job posts
                    </Button>
                    
                ]}
            />
        </div>
    )
}

export default SuccessJobPost