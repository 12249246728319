// External Imports
import { useState, useEffect } from 'react'
import { Button, Card, Checkbox, Col, Space, Modal, Row, Skeleton, Switch, Typography, Layout, Tabs, Empty, notification, Tooltip, Tag, Popover } from 'antd'
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, EllipsisOutlined, ExclamationCircleOutlined, EyeOutlined, ProfileOutlined, UserSwitchOutlined } from '@ant-design/icons'
import { MetaTags } from 'react-meta-tags'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import EditCategoriesModal from './components/edit-categories-modal'

// Internal Imports
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { META_DESCRIPTION } from '../../../../../utils/constants'
import ROUTES from '../../../../../utils/routes'
import PageHeaderWrapper from '../../../../components/page-header-breadcrumbs'
import { capitalizeFirstLetter, capitalizeFirstWordOfEveryLetter, truncateText } from '../../../../../utils/helper'

// Assets Imports
import './assets/css/styles.css'
import EmptyFileIcon from './assets/icons/empty-file-icon.svg'


const { Content } = Layout
const { TabPane } = Tabs
const { Title, Paragraph, Text } = Typography;

const ManageSkills = () => {
    const tech = useSelector((state) => state.tech);
    const history = useHistory()
    const { token } = tech

    const [showEditCategoriesModal, setShowEditCategoriesModal] = useState(false)
    const [loadingCategories, setLoadingCategories] = useState(false)
    const [workCategoriesData, setWorkCategoriesData] = useState([])
    const [specializationsData, setSpecializationsData] = useState([])
    const [showDeleteSpecializationModal, setShowDeleteSpecializationModal] = useState(false)
    const [deletingSpecialization, setDeletingSpecialization] = useState(false)
    const [specializationId, setSpecializationId] = useState(null)
    const [windowSize, setWindowSize] = useState(getWindowSize())
    const [hasLoaded, setHasLoaded] = useState(false)

    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
      }

    const fetchWorkCategories = () => {
        setLoadingCategories(true)
        ApiBaseHelper.get({
            url: `work-categories/selected`, headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setWorkCategoriesData(res.data.data)
            setLoadingCategories(false)
            setHasLoaded(true)
        }).catch((error) => {
            console.error(error)
            setLoadingCategories(false)
            setHasLoaded(true)
        })       
    }

    const fetchSpecializations = () => {
        setLoadingCategories(true)
        ApiBaseHelper.get({
            url: `work-categories/specialization`, headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setSpecializationsData(res.data.data.sort((a, b) => b?.Active.localeCompare(a?.Active)))
            setLoadingCategories(false)
        }).catch((error) => {
            console.error(error)
            setLoadingCategories(false)
        })       
    }

    console.log(specializationsData)

    const handleSpecializationDelete = () => {
        setDeletingSpecialization(true)
        ApiBaseHelper.delete({
            url: `work-categories/specialization/${specializationId}`, 
            headers: {
                Authorization: 'Bearer ' + token,
            },
        }).then((res) => {
            if(res){
                notification['success']({
                    message:"TecMe",
                    description:"Specialization deleted successfully"
                })
                setShowDeleteSpecializationModal(false)
                setDeletingSpecialization(false)
                fetchSpecializations()
            }
        }).catch((error) => {
            console.log(error)
            setDeletingSpecialization(false)
            notification['error']({
                message: "TecMe",
                description:"An Error Occurred"
            })
        })
    }

    const popoverContent = (item) => {
        return(
            <div className="specialization-popover-content">
                {
                    item?.Active === "1" && (
                        <Space className='popover-action-item' onClick={() =>  history?.push({
                            pathname: ROUTES.TECH_PROFILE_WITH_ID.replace(':id', tech?.id),
                            state: {categorySkillsId: item?.id},
                            search: `specialization=${item?.Category.Title.toLowerCase().replaceAll(" ", "-")}`
                        })}>
                            <EyeOutlined />
                            <Paragraph style={{marginBottom:0}}>View</Paragraph> 
                        </Space>
                    )
                }
                <Space className='popover-action-item' 
                    onClick={() => history.push({
                    pathname: ROUTES.UPDATE_SPECIALIZATION.replace(':specialization_id', item?.id),
                    state: {categorySkillsData: item},
                    search:'?option=edit'
                    })}>
                    <EditOutlined />
                    <Paragraph style={{marginBottom:0}}>Edit</Paragraph> 
                </Space>
                <Space className='popover-action-item' onClick={() => {
                    setSpecializationId(item?.id)
                    setShowDeleteSpecializationModal(true)
                    }}>
                    <DeleteOutlined />
                    <Paragraph style={{marginBottom:0}}>Delete</Paragraph> 
                </Space>
            </div>
        )
    }

   
    useEffect(() => {
    function handleWindowResize() {
        setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
        window.removeEventListener('resize', handleWindowResize);
    };
    }, []);


    useEffect(() => {
        fetchWorkCategories()
        fetchSpecializations()
    }, [])

    useEffect(() => { 
        window.scrollTo(0, 0)
    },[])
 
    return (
    <>
        <MetaTags>
            <title>Manage Work Preferences | TecMe </title>
            <meta name="description" content={META_DESCRIPTION}/>
            <meta name="robots" content="noindex, nofollow"/>
        </MetaTags>
        <Content className="main">
            <div className="page">
                <div style={{minHeight: '800px'}}>
                    <PageHeaderWrapper
                        className="tech-skill-page-breadcrumb-header"
                        routes={[
                            {
                                breadcrumbName: 'Work Preferences',
                            },
                        ]}
                    />
                    <div className='auth-container'>
                        <div className='auth-page-ctn skills-ctn'>
                            <Card>
                                {
                                    loadingCategories  ?
                                    <>
                                        <Skeleton active paragraph={{rows:1}} className='mb-5'/> 
                                        {
                                            Array(3).fill().map((item, index) => (
                                                <Space key={index} size={24} className='d-flex align-items-center mb-4'>
                                                    <Skeleton.Input active/>
                                                    <Skeleton.Input active/>
                                                </Space>
                                            ))  
                                        }
                                    </> 
                                    :
                                    <>
                                        <div className="work-categories-heading">
                                            <Space className='d-flex justify-content-between align-items-center mb-2'>
                                                <Space className='d-flex align-items-center'>
                                                    <ProfileOutlined style={{fontSize:20, color: "#F04A22"}}/>
                                                    <Title level={4} style={{margin:0}}>Work Categories</Title>
                                                </Space>
                                                { !!workCategoriesData.length && <EditOutlined style={{fontSize:20, color: "#F04A22"}} onClick={() => {
                                                    setShowEditCategoriesModal(true)
                                                }}/>
                                                }
                                            </Space>
                                            {
                                                !!workCategoriesData.length &&
                                                <Paragraph style={{fontSize:16, color: "rgba(0, 0, 0, 0.85)"}}>Choosing accurate categories helps match your profile with relevant projects, jobs, and businesses that lead to rewarding opportunities.</Paragraph>
                                            }
                                        </div>
                                        <div className='skills-and-subskills-ctn'>
                                            {
                                              (!loadingCategories && hasLoaded && !!workCategoriesData.length) ? workCategoriesData.map((item, index) => {
                                                    return(
                                                        <div key={item?.id} className='skills-and-subskills-item'>
                                                            <Title level={4}>{item?.Title}</Title>
                                                            <div className='subskills-tags'>
                                                                {
                                                                    item.Child_Categories.map((category, index) => {
                                                                        return(
                                                                            <Tag key={index}>{windowSize.innerWidth > 550 ? category?.Title : truncateText(category?.Title, 30)}</Tag>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }) : (hasLoaded && workCategoriesData.length === 0)?
                                                <div className='d-flex flex-column justify-content-center align-items-center h-50 text-center'>
                                                    <img src={EmptyFileIcon} alt="empty file icon" />
                                                    <Paragraph style={{fontSize:16, color:"rgba(0, 0, 0, 0.85)", marginTop:10}}>Selecting accurate categories helps match your profile with relevant <br /> projects, jobs, and businesses that lead to rewarding opportunities.</Paragraph>
                                                    <Button type="primary" onClick={() => setShowEditCategoriesModal(true)}>Select Categories</Button>
                                                </div> : 
                                                 <>
                                                 <Skeleton active paragraph={{rows:1}} className='mb-5'/> 
                                                 {
                                                     Array(3).fill().map((item, index) => (
                                                         <Space key={index} size={24} className='d-flex align-items-center mb-4'>
                                                             <Skeleton.Input active/>
                                                             <Skeleton.Input active/>
                                                         </Space>
                                                     ))  
                                                 }
                                             </> 
                                            }
                                        </div>
                                    </>
                                }
                            </Card>
                            <Card className='specialization-card'>
                                {
                                    loadingCategories ? 
                                    <>
                                        <Skeleton active /> 
                                        <Skeleton.Button active className='mt-3' size='large'/>
                                    </>
                                    :
                                    <>
                                        <div className="specialization-heading">
                                        <Space className='d-flex align-items-center mb-4'>
                                            <UserSwitchOutlined style={{fontSize:20, color: "#F04A22"}}/>
                                            <Title level={4} style={{margin:0}}>Your Specializations</Title>
                                        </Space>
                                        {
                                            specializationsData?.length > 0 &&
                                            <Title level={5} style={{color:"#7A7A7D"}}>{specializationsData.filter(item => item.Active === "1")?.length} out of 3 published</Title>
                                        }
                                        </div>
                                        <div className='learn-more-info'>
                                            <Paragraph style={{color:"#000000", fontSize:16}}>Add up to three specializations to highlight your skills and expertise in a category directly on your profile. <br />
                                            <a href='https://help.tecme.io/content/why-you-should-add-specializations-to-your-tecme-technician-profile' target='_blank'>Learn More</a>
                                            </Paragraph>
                                        </div>
                                        <div className='specialization-list-ctn'>
                                            {
                                                specializationsData?.length > 0 && specializationsData?.map((item, index) => {
                                                    return(
                                                        <Space className="d-flex align-items-center justify-content-between">
                                                            <Paragraph style={{color:"#000000", fontSize:16, margin:0}}>{capitalizeFirstLetter(item?.Category.Title)} - <Text>{item?.Active === "1" ? "Published" : "Draft"}</Text></Paragraph>
                                                            <Popover content={popoverContent(item)} trigger="click" placement='bottomRight' overlayClassName="specialization-popover" arrowPointAtCenter>
                                                                <EllipsisOutlined style={{fontSize:20, color:"rgba(0, 0, 0, 0.85)"}}/>
                                                            </Popover>
                                                        </Space>
                                                    )
                                                }) 
                                            }
                                        </div>
                                        <Tooltip placement="top" title={specializationsData.filter(item => item.Active === "1")?.length >= 3 && "You can't create and publish more than 3 specializations"}>
                                            <Button type='primary' style={{height:40}} onClick={() => history.push(ROUTES.CREATE_SPECIALIZATION)} disabled={specializationsData.filter(item => item.Active === "1")?.length >= 3 ? true : false}>Create Specialization</Button>
                                        </Tooltip>
                                    </>
                                }
                                
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </Content>  
        <EditCategoriesModal 
            showEditCategoriesModal={showEditCategoriesModal}
            setShowEditCategoriesModal={setShowEditCategoriesModal}
            workCategoriesData={workCategoriesData}
            fetchWorkCategories={fetchWorkCategories}
            screenWidth={windowSize.innerWidth}
        />
        <Modal
            open={showDeleteSpecializationModal} 
            okText="Yes"
            onOk={() => {
                handleSpecializationDelete()
            }} 
            okButtonProps={{loading:deletingSpecialization}}
            cancelButtonProps={{type:'secondary'}}
            onCancel={() => {
                setShowDeleteSpecializationModal(false)
            }}
            width={555}
            closable={false}
            centered
            className='confirm-specialization-modal'
        >
            <Space align='start'>
                <ExclamationCircleOutlined style={{fontSize:22, color:"#FAAD14"}} />
                <Typography.Paragraph style={{marginBottom:0, fontSize:16, fontWeight:500, color:"rgba(0, 0, 0, 0.85)"}}>All changes to this specialization will be lost. Are you sure you want to delete it?</Typography.Paragraph>
            </Space>
        </Modal>
    </>
  )
}

export default ManageSkills