// External Imports
import { useEffect, useState } from 'react'
import { Modal, Form, Input, Divider, Typography, Button, Select, Space, Switch, Row , notification, Radio} from 'antd'

// Internal Imports
import { ApiBaseHelper } from '../../../../../../../utils/api-base-helper'
import { nameFormat } from '../../../../../../../utils/helper'

const { Item, useForm } = Form
const { TextArea } = Input
const { Text } = Typography

const EditContractModal = ({ visible, onOk, onCancel, data, contractStatus = 'draft', contractDetails = {}, repository, contractId, getAndSetContractDetails, sendMessage, isActiveMode, user }) => {
    const [form] = useForm()
    const [totalSkills, setTotalSkills] = useState([]);
    const [isWeekLimitInputDisabled, setIsWeekLimitInputDisabled] = useState(false)
    const [isFormValuesChanged, setIsFormValuesChanged] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(contractDetails) {
            if(contractDetails.Type === 'Hourly' && (!contractDetails.Hours_Upper_Limit || contractDetails.Hours_Upper_Limit === '0')) {
                setIsWeekLimitInputDisabled(true)
            }
            form.setFieldsValue({
                Description: contractDetails.Description,
                Skills_Required: contractDetails.Skills_Required?.split(",").map(skill => ({ value: skill, label: skill })) || [],
                Allow_Manual_Entry: !!(contractDetails.Allow_Manual_Entry === '1' || contractDetails.Allow_Manual_Entry === 'true'),
                Hours_Upper_Limit_Check: !!(contractDetails.Hours_Upper_Limit && contractDetails.Hours_Upper_Limit !== '0'),
                Billing_Rate: Number(contractDetails.Billing_Rate) / 100,
                Hours_Upper_Limit: contractDetails.Hours_Upper_Limit,
                Billing_Frequency: contractDetails.Billing_Frequency,
                Type: contractDetails.Type == 'Fixed' ? 'Retainer' : contractDetails.Type == 'Per Ticket' ? 'Per Issue' : contractDetails.Type
            })
        }
        if(contractStatus === 'draft') {
            ApiBaseHelper.get({
                url: `web/work-categories`,
            }).then(res => {
                let workCategories = [];
                res.data.forEach((category) => {
                    workCategories.push({ ...category, value: category.Title, label: category.Title });
                    if (category['Child_Categories'].length > 0) {
                        category['Child_Categories'].forEach((child) => {
                            workCategories.push({ ...child, value: child.Title, label: child.Title });
                        })
                    }
                });
                setTotalSkills(workCategories)
                document.querySelector('.ant-select-selection-search-input')?.setAttribute("placeholder", '+ Add Skill');
            }).catch(error => console.error(error))
            // ApiBaseHelper.get({
            //     url: 'web/skills/filters',
            // }).then(res => {
            //     if(res?.data?.data?.length) {
            //         setTotalSkills(res.data.data.map(skill => ({ ...skill, value: skill.Title, label: skill.Title })))
            //     }
            //     document.querySelector('.ant-select-selection-search-input')?.setAttribute("placeholder", '+ Add Skill');
            // }).catch(error => console.error(error))
        }
    }, [])

    const handleOnValuesChange = (values) => {
        setIsFormValuesChanged(true)
        if('Hours_Upper_Limit_Check' in values) {
            if(!values.Hours_Upper_Limit_Check) setIsWeekLimitInputDisabled(true)
            if(values.Hours_Upper_Limit_Check) setIsWeekLimitInputDisabled(false)
        }
    }

    const handleOnFinish = (values) => {
        let payload = {
            ...values,
            Billing_Rate: Number(values.Billing_Rate) * 100,
            Technician_Contact: contractDetails?.Provider_Org?.HIPAA_Primary_Contact
        }
        if(contractStatus === 'draft') {
            payload = {
                ...payload,
                Skills_Required: values.Skills_Required.every(skill => typeof skill === 'object') ? values.Skills_Required.map(skill => skill.value).join() : values.Skills_Required.join()
            }   
        }
        
        if(contractDetails?.Type === 'Hourly') {
            payload = {
                ...payload,
                Allow_Manual_Entry: values.Allow_Manual_Entry ? "1" : "0",
                Hours_Upper_Limit: values.Hours_Upper_Limit_Check ? values.Hours_Upper_Limit : "0"
            }
        }

        if(isFormValuesChanged) {
            setLoading(true)
            repository.updateContract(contractId, payload).then(async (res) => {
                if(res.data?.status === 'success') {
                    const userName = nameFormat(`${user?.firstname} ${user?.lastname}`) 
                    const location = window.location.href;
                    // await sendMessage(`${userName} has modified your contract proposal.<br><br> To view the details, simply click on the link below:<br> ${location}`)
                    await getAndSetContractDetails()
                }
                setLoading(false)
                onOk()
            }).catch(error => {
                console.error(error)
                setLoading(false)
            })
        } else {
            onOk()
        }
    }

    const getBillingCycle = () => {
        let billingCycle = 'per hour'
        if(contractDetails?.Type === 'Fixed') {
            switch (contractDetails.Billing_Frequency) {
                case "Monthly":
                    billingCycle = 'per month'
                    break;
                case "Weekly":
                    billingCycle = 'per week'
                    break;
                case "Bi-weekly":
                    billingCycle = 'bi-weekly'
                    break;
                default:
                    break;
            }
        }

        return billingCycle
    }

    return (
        <Modal
            title={<Text strong>Edit Contract</Text>}
            visible={visible}
            onCancel={() => {
                onCancel()
                form.setFieldsValue({
                    Description: contractDetails.Description,
                    Skills_Required: contractDetails.Skills_Required?.split(",").map(skill => ({ value: skill, label: skill })) || [],
                    Allow_Manual_Entry: !!(contractDetails.Allow_Manual_Entry === '1' || contractDetails.Allow_Manual_Entry === 'true'),
                    Hours_Upper_Limit_Check: !!(contractDetails.Hours_Upper_Limit && contractDetails.Hours_Upper_Limit !== '0'),
                    Billing_Rate: Number(contractDetails.Billing_Rate) / 100,
                    Hours_Upper_Limit: contractDetails.Hours_Upper_Limit,
                    Billing_Frequency: contractDetails.Billing_Frequency,
                    Type: contractDetails.Type == 'Fixed' ? 'Retainer' : contractDetails.Type == 'Per Ticket' ? 'Per Issue' : contractDetails.Type
                })
            }}
            onOk={onOk}
            footer={false}
            centered
            className='edit-contract-modal edit-contract-modal-component'
        >
            <Form
                layout="vertical"
                onValuesChange={handleOnValuesChange}
                onFinish={handleOnFinish}
                onFinishFailed={error => console.error(error)}
                form={form}
                // size="large"
            >
                <Item
                    label="Contract Type"
                    name="Type"
                >
                    <Input disabled readOnly />
                </Item>
                {contractStatus === 'draft' && (
                    <>
                        <Item
                            label="Support Description"
                            name="Description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter support description'
                                },
                                {
                                    min: 25,
                                    message: 'It should be at least 25 characters or more.',
                                }
                            ]}
                        >
                            <TextArea
                                rows={4}
                                showCount
                                maxLength={150}
                            />
                        </Item>
                        <Item
                            label="IT skills required"
                            name="Skills_Required"
                        >
                            <Select
                                mode="tags"
                               // size="large"
                                popupClassName='edit-contract-proposal-it-skills-select'
                                className="w-100 edit-contract-proposal-it-skills-select"
                                options={totalSkills}
                                virtual={false}
                                listHeight={254}
                            />
                        </Item>
                    </>
                )}
                {contractDetails?.Type === 'Hourly' && (
                    <>
                        <Space align='start' size="large" className='w-100 justify-content-between'>
                            <Space direction='vertical' size={0}>
                                <Text strong>
                                    Allow technician to manually create time entries
                                </Text>
                                <Text type='secondary'>This allows the technician to add billable time without needing to use the built-in time tracker.</Text>
                            </Space>
                            <Item
                                valuePropName="checked"
                                name="Allow_Manual_Entry"
                            >
                                <Switch />
                            </Item>
                        </Space>
                        <Row className='d-flex align-items-center w-100 justify-content-between'>
                            <Text strong>
                                Set weekly limit on billable hours
                            </Text>
                            <Item valuePropName="checked" name="Hours_Upper_Limit_Check">
                                <Switch />
                            </Item>
                        </Row>
                        <Item name="Hours_Upper_Limit">
                            <Input
                                disabled={isWeekLimitInputDisabled}
                                placeholder='40'
                                addonAfter="per week"
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                onPaste={(e)=>{
                                    e.preventDefault()
                                    return false;
                                }}
                            />
                        </Item>
                    </>
                )}
                {contractDetails?.Type === 'Fixed' && !isActiveMode && (
                    <Item
                        label="Select your billing interval"
                        name="Billing_Frequency"
                        rules={[
                            {
                                required: true,
                                message: 'Please select the billing interval'
                            }
                        ]}
                    >
                        <Radio.Group>
                            <Radio.Button value="Weekly">Weekly</Radio.Button>
                            <Radio.Button value="Bi-weekly">Bi-weekly</Radio.Button>
                            <Radio.Button value="Monthly">Monthly</Radio.Button>
                        </Radio.Group>
                    </Item>
                )}
                <Item
                    label='Contract Rate'
                    name="Billing_Rate"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter contract rate'
                        },
                        isActiveMode ? ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (value >= Number(contractDetails.Billing_Rate) / 100) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(`It should be greater than or equal to ${Number(contractDetails.Billing_Rate) / 100}`));
                            }
                        }) : () => ({
                            validator(_) {
                                return Promise.resolve();
                            }
                        }),
                    ]}
                >
                    <Input
                        addonBefore='$'
                        placeholder='75'
                        addonAfter={getBillingCycle()}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        onPaste={(e)=>{
                            e.preventDefault()
                            return false;
                        }}
                    />
                </Item>
                <Item>
                    <Space className='mt-3 w-100 justify-content-end'>
                        <Button type="text" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Save
                        </Button>
                    </Space>
                </Item>
            </Form>
        </Modal>
    )
}

export default EditContractModal