import React, { useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import { META_DESCRIPTION } from '../../../../../utils/constants'
import { Button, Card, Col, Layout, Modal, Popover, Radio, Rate, Row, Space, Tag, Typography, notification } from 'antd'
import './assets/css/styles.scss'
import { ClockCircleOutlined, CloseOutlined, EditOutlined, EnvironmentOutlined, EyeOutlined, HomeOutlined, PaperClipOutlined, PushpinOutlined, SyncOutlined } from '@ant-design/icons'
import SafetyIcon from './assets/icons/safety-icon.svg'
import { useHistory } from 'react-router-dom'
import ROUTES from '../../../../../utils/routes'
import { useParams } from 'react-router-dom'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import PageFallback from '../../../../components/page-fallback'
import { getDurationInfo, getSpecializationTitle, getWorkTypeInfo } from '../../../common/private/job-post/utils/helper'
import { capitalizeFirstLetter, getDottedTimeFormat, truncateText } from '../../../../../utils/helper'
import JobPostDetails from '../../../common/private/job-post/components/job-post-details'
import JobApplications from '../../job-postings/applications'
import { capitalize } from 'instantsearch.js/es/lib/utils'
import PageHeaderWrapper from '../../../../components/page-header-breadcrumbs'
import useWindowSize from '../../../../../hooks/useWindowSize'
import ActionIcon from './assets/icons/action-icon.svg'

const {Content} = Layout
const {Paragraph, Title} = Typography

const ManageJobPosting = () => {
    const generalSkills = useSelector(state => state.generalSkillsGlobal)
    const [visible, setVisible] = useState(false)
    const [loadingJob, setLoadingJob] = useState(false)
    const [jobPost, setJobPost] = useState(null)
    const [attachments, setAttachments] = useState([])
    const user = useSelector(state => state.user)
    const history = useHistory()
    const { id } = useParams()
    const [cancelReason, setCancelReason] = useState(null)
    const [closingJob, setClosingJob] = useState(false)

    const { windowWidth } = useWindowSize()

    const fetchJobPosting = () => {
        setLoadingJob(true)
        ApiBaseHelper.get({
            url: `job-post/client`, 
            headers: {
                Authorization: 'Bearer ' + user?.token
            }
        }).then((res) => {
            setJobPost(res?.data?.data.find((item) => item.id === id))
            setLoadingJob(false)
        }).catch((error) => {
            console.error(error)
            setLoadingJob(false)
        })       
    }

    const fetchAttachments = () => {
        ApiBaseHelper.get({
            url: `job-post/media/${id}`, 
            headers: {
                Authorization: 'Bearer ' + user?.token
            }
        }).then((res) => {
            setAttachments(res?.data?.data)
        }).catch((error) => {
            console.error(error)
        })       
    }

    const handleJobClose = () => {
        setClosingJob(true)
        ApiBaseHelper.post({
            url: `job-post/close/${id}`,	
            headers: {
                Authorization: 'Bearer ' + user['token'],
            },
            payload: {
               Reason: cancelReason,
            }
        }).then((res) => {
            if(res){
                notification.success({
                    message: 'TecMe',
                    description: 'Job Post Closed Successfully',
                });
                setVisible(false)
                fetchJobPosting()
            }
            setClosingJob(false)
        }).catch((err) => {
            setClosingJob(false)
            notification.error({
                message: 'TecMe',
                description: 'An error occured!',
            });
            console.log(err)
        })
    }

    useEffect(() => {
        fetchJobPosting()
    }, [])

    useEffect(() => {
        fetchAttachments()
    }, [id])

   

    const MANAGE_JOB_CTA = [
        {
            title: "View job posting as technician",
            click: () => window.open(ROUTES.JOB_POST.replace(":id", id)),
            icon: <EyeOutlined style={{color: windowWidth > 991 ? "#F04A22" : "rgba(0, 0, 0, 0.85)"}}/>
        },
        jobPost?.Status === "Closed" ? null : {
            title: "Edit job posting",
            click: () => history.push(ROUTES.EDIT_JOB_POSTING.replace(":id", id)),
            icon: <EditOutlined style={{color:"#F04A22"}}/>
        },
        // {
        //     title: "Reuse job posting",
        //     click: () => {},
        //     icon: <SyncOutlined style={{color:"#F04A22"}} />
        // },
        jobPost?.Status === "Closed" ? null : {
            title: "Close job",
            click: () => setVisible(true),
            icon: <CloseOutlined style={{color: windowWidth > 991 ? "#F04A22" : "rgba(0, 0, 0, 0.85)"}}/>
        }
    ]

    const activeJobPostPopoverContent = () => {
        return(
            <div className="job-posting-popover-content"> 
            {
                MANAGE_JOB_CTA?.filter(item => item?.title !== "Edit job posting")?.map((item, index) => {
                    return (
                        <Space className='popover-action-item' 
                            align='center' 
                            onClick={item?.click}>
                            {item?.icon}
                            <Paragraph style={{marginBottom:0}}>{item?.title}</Paragraph> 
                        </Space>
                    )
                })
            }  
            </div>
        )
    }

    return (
        <>
            <MetaTags>
                <title>Manage Job Posting - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <PageHeaderWrapper
                routes={[
                    {
                        path: ROUTES.JOB_POSTINGS,
                        breadcrumbName: 'Job Postings',
                    },
                    {
                        breadcrumbName: truncateText(capitalizeFirstLetter(jobPost?.Title), 22) 
                    },
                ]}
            />
            <Content className="main">
                <div className='page'>
                   { 
                        loadingJob ? <PageFallback /> :
                        <div className='auth-container manage-job-ctn'>
                            {/* <div className='manage-job-header'>
                                <Title level={3}>Job Post Details</Title>
                            </div> */}
                            <div className="manage-job-content">
                                <div className='manage-job-card'>
                                    <Row gutter={40}>
                                        <Col xs={24} sm={24} md={24} lg={17} xl={17} className='manage-job-details'>
                                            <Title level={4}>{capitalizeFirstLetter(jobPost?.Title)}</Title>
                                            <Paragraph style={{color:"#F04A22", fontWeight:500, marginBottom:30}}>{getSpecializationTitle(generalSkills, jobPost?.Category)}</Paragraph>
                                            <div className='manage-job-description'>
                                                <Title level={5}>Job Overview</Title>
                                                {
                                                    jobPost?.Description?.split('\n').map((line, index) => (
                                                        <Paragraph key={index}>
                                                        {line}
                                                        </Paragraph>
                                                    ))
                                                }
                                                {
                                                    attachments?.data?.length > 0 &&
                                                    attachments?.data?.map((item, index) => {
                                                        return(
                                                            <div key={item?.id}>
                                                                <Space align='center' className="download-cv">
                                                                    <PaperClipOutlined style={{color:"rgba(0, 0, 0, 0.45)", position:"relative", top:2}}/>
                                                                    <a href={item?.Body}>{item?.Title}</a>
                                                                </Space>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
    
                                            <div className="job-skills">
                                                <Title level={5}>Skills and Expertise</Title>
                                                {
                                                    jobPost?.Skills_Required?.split(', ').map((item, index) => {
                                                        return (
                                                            <Tag className="custom-ant-tag" key={item} style={{marginBottom:10}}>{item}</Tag>
                                                        )   
                                                    }
                                                    )
                                                }
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                                            <div className="manage-job-cta-ctn">
                                               {
                                                windowWidth > 991 && 
                                                    <div className='manage-job-cta'>
                                                        {
                                                            MANAGE_JOB_CTA.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='manage-job-cta-item' onClick={item?.click}>
                                                                        {item?.icon}
                                                                        <Paragraph className='manage-job-cta-title'> {item?.title}</Paragraph>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                                <JobPostDetails jobPost={jobPost}/>
                                                {/* <div className="manage-job-cta-info">
                                                    <div className='my-details'>
                                                        <Title level={4}>My details</Title>
                                                        <div className="details-rate">
                                                            <Paragraph>5.00 of 48 reviews</Paragraph>
                                                            <Rate disabled defaultValue={4} style={{color:"#FEB000", fontSize:20}}/>
                                                        </div>
                                                    </div>
                                                    <div className="payment-info">
                                                        <Paragraph style={{fontSize:14, marginBottom:8}}>60k+ total spent</Paragraph>
                                                        <Space>
                                                            <img src={SafetyIcon} alt="safety icon" />
                                                            <Paragraph style={{margin:0, fontSize:14}}>Payment verified</Paragraph>
                                                        </Space>
                                                    </div>
                                                    <div className="projects-info">
                                                        <Paragraph style={{fontSize:14, marginBottom:8}}>12 success projects</Paragraph>
                                                        <Space align='end'>
                                                            <EnvironmentOutlined />
                                                            <Paragraph style={{margin:0, fontSize:14}}>St. Louis, Missouri</Paragraph>
                                                        </Space>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="job-applications-section">
                                <JobApplications 
                                    jobPost={jobPost}                                          
                                />
                            </div>
                        </div>
                    }
                </div>
                <div className="mobile-manage-job-posting">
                    <Popover content={activeJobPostPopoverContent()}  trigger="click" placement='topLeft' overlayClassName="job-posting-popover job-details-posting-popover" arrowPointAtCenter>
                        <img className='action-job-icon' src={ActionIcon} alt="action icon" />  
                    </Popover>
                    <Button 
                        type='secondary' 
                        className='edit-job-btn' 
                        icon={<EditOutlined />}
                        onClick={() => history.push(ROUTES.EDIT_JOB_POSTING.replace(":id", id))}
                        >Edit job post</Button>
                </div>
            </Content>
            <Modal
                title="Close Job Posting"
                visible={visible}
                onCancel={() => setVisible(false)}
                onOk={handleJobClose}
                cancelButtonProps={{type:"secondary"}}
                okText="Close the job"
                okButtonProps={{type:"primary", loading:closingJob}}
                width={801}
            >
                <Paragraph>Are you sure you want to close this job post? After closing applicants won’t be able to send proposals.</Paragraph>
                <Paragraph style={{fontWeight:500}}>Reason for closing (This will help us improve the quality of posts on the TecMe platform).</Paragraph>
                <Radio.Group onChange={(e) => {
                    setCancelReason(e.target.value)
                }}>
                    <Space direction="vertical">
                        <Radio value="Position filled">Position filled</Radio>
                        <Radio value="Changed job requirements">Changed job requirements</Radio>
                        <Radio value="Accidental job posting creation">Accidental job posting creation</Radio>
                        <Radio value="Filled by alternative resource">Filled by alternative resource</Radio>
                        <Radio value="Project was cancelled">Project was cancelled</Radio>
                    </Space>
                </Radio.Group>
            </Modal>
        </>
    )
}

const LoadingSkeleton = () => {
    return(
        <div className='auth-container manage-job-ctn'>
        <div className='manage-job-header'>
            <Skeleton.Input style={{width:300, height:40}} active={true} size="large"/>
        </div>
        <div className="manage-job-content">
            <div className='manage-job-card'>
                <Row gutter={40}>
                    <Col xs={24} sm={24} md={24} lg={17} xl={17} className='manage-job-details'>
                      <Skeleton active={true} paragraph={{rows: 4}}/>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                        <div className="manage-job-cta-ctn">
                            <Skeleton active={true} paragraph={{rows: 4}}/>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
    )
}

export default ManageJobPosting