import { Button, Form, Input, Select, Space, Typography, notification} from 'antd'
import React from 'react'
import useWindowSize from '../../../../../hooks/useWindowSize'
import { useDispatch } from 'react-redux'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { useEffect } from 'react'
import { useState } from 'react'
import { updateOrganizations, updateUser } from '../../../../../redux/reducers/app'
import { INDUSTRIES, SOURCES, TEAM_SIZE } from '../../../../../utils/company-dropdown'

const {Title, Paragraph} = Typography
const {useForm} = Form

const ExtraCompanyDetails = ({handleNextClick, setProgressIndex, extraCompanyDetails, setExtraCompanyDetails, user}) => {

    const {windowWidth} = useWindowSize()
    const [form] = useForm();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    
    const handleCompanyDetailsSubmit = async (values) => {
        setLoading(true)
        const data = new FormData();

        data.append("Industry", values.companyIndustry === null ? "" : values.companyIndustry)
        data.append("Company_Size", values.companySize === null ? "" : values.companySize)
        data.append("Hear_About_Us", values.companySize === null ? "" : values.source)

        ApiBaseHelper.put({
            url: `user-management/organization/${user?.orgId}`,	
            headers: {
                Authorization: 'Bearer ' + user['token'],
            },
            payload: data
        }).then((res) => {
            if(res){
                dispatch(updateUser({
                    ...user,
                    new_signup: false,
                    organization:{
                        ...user.organization,
                        Industry: res?.data?.data?.Industry,
                        Company_Size: res?.data?.data?.Company_Size,
                        Hear_About_Us: res?.data?.data?.Hear_About_Us,
                    }
                }))

                ApiBaseHelper.get({
                    url: 'user-management/organizations',
                    headers: {
                        Authorization: 'Bearer ' + user?.token,
                    },
                }).then((res) => {
                    console.log(res)
                    dispatch(updateOrganizations(res?.data?.data))
                })
            }
        }).then(() => {
            setLoading(false)
        }).catch((e) => {
            console.log(e)
            setLoading(false)
            notification.error({
                message: 'TecMe',
                description: 'Company Details Update Failed',
            });
        })
        handleNextClick()
        setProgressIndex(1)
        setExtraCompanyDetails(values)
    }

    useEffect(() => {
        form.setFieldsValue({
            companyIndustry: extraCompanyDetails?.companyIndustry,
            companySize: extraCompanyDetails?.companySize,
            source: extraCompanyDetails?.source,
        })
    }, [])


    return (
        <div className='company-details-section'>
            <div className="company-details-form-ctn">
                <Title level={4} className='form-heading'>Let’s get to know you {windowWidth > 991 && '(2/2 steps)'}</Title>
                <Form
                    onFinish={handleCompanyDetailsSubmit}
                    form={form} 
                    layout='vertical'
                    className="company-details-form">
                    <Form.Item name="source" label="How did you hear about us?" className='company-details-form-item' 
                    rules={[{ required: true, message: 'Please select how you discovered us' }]}>
                        <Select 
                            placeholder="Select how you discovered us" className='company-details-form-select'>
                            {
                                SOURCES.map((item) => (
                                    <Select.Option value={item}>
                                       {item}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item> 
                    <Form.Item label="Industry" name="companyIndustry" className='company-details-form-item'rules={[{required: true, message: 'Please select your company' }]}>
                        <Select className='company-details-form-select' placeholder="Select your company industry">
                            {
                                INDUSTRIES?.map((industry) => (
                                    <Select.Option value={industry} key={industry}>
                                        {industry}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item> 
                    <Form.Item name="companySize" label="What is your company size?" className='company-details-form-item' rules={[{required: true, message: 'Please select your company size' }]}>
                        <Select className='company-details-form-select'
                        placeholder="Select company size">
                            {
                                TEAM_SIZE?.map((size) => (
                                    <Select.Option value={size} key={size}>
                                        {size}
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item> 
                    <Form.Item className='company-details-form-item'>
                        <Button className='onboarding-next-btn' type='primary' htmlType='submit' loading={loading}>Next</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default ExtraCompanyDetails