import { ApiBaseHelper } from "../../../../../../utils/api-base-helper";

export class InvoiceRepository {
    constructor(accessToken,role) {
        this.accessToken = accessToken;
        this.role = role;
    }

    getInvoices(type) {
        return ApiBaseHelper.get(
            {
                url: `invoice/${type}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

}

