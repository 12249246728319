import { Avatar, Button, Col, Form, Input, Modal, Row, Space, Typography, Upload, message, notification } from 'antd'
import React from 'react'
import '../css/my-details.scss'
import '../css/styles.scss'
import { InboxOutlined, UserOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useState } from 'react'
import { getBase64, sendVerificationEmail } from '../../../../../../utils/helper'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import { updateTech, updateUser } from '../../../../../../redux/reducers/app'
import { EditProfileRepository } from '../../edit-profile/repository'
import ImgCrop from 'antd-img-crop'

const {Title, Paragraph, Text} = Typography
const { Dragger } = Upload;
const { useForm } = Form

const MyDetails = () => {
    const client = useSelector(state => state.user)
    const tech = useSelector(state => state.tech)
    const [form] = useForm();
    const [file, setFile] = useState(null)
    const [profileUpdateMessage, setProfileUpdateMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const authUser = client || tech
    const [imageUrl, setImageUrl] = useState(authUser?.imageUrl);
    const editProfileRepository = new EditProfileRepository(authUser?.token)

    const props = {
        accept: 'image/*',
        multiple: false,
        showUploadList: false,
        beforeUpload: (file) => {
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                setProfileUpdateMessage('Image must smaller than 5MB!');
            } else {
                getBase64(file, imageUrl =>
                    setImageUrl(imageUrl)
                );
                setFile(file)
                console.log(file)
                setProfileUpdateMessage(file.name)
            }
            return false;
        },
        onDrop(e) {
           setFile(e.dataTransfer.files[0]);
        },
        onRemove: (file) => {
            setFile(null);
            return true;
        }
    };
    
    const handleDetailsSubmit = async (values) => {
        console.log(values)
        setLoading(true)

        const data = new FormData();

        data.append("firstName", values.firstName)
        data.append("lastName", values.lastName)
        data.append("phoneNumber", values.phone)
        data.append('file', file)
        if (values.email != authUser?.email) {
            let validateAccountResponse = await editProfileRepository.validateEmail(values.email);

            if (validateAccountResponse.data['data'].length > 0) { // account exists
                Modal.info({
                    title: "Email already exists",
                    content: 'Email address that have entered is already registered in our database. Please use different email to continue.',
                });
                setLoading(false);
                return;
            }
            sendVerificationEmail({
                email: values.email,
                userId: authUser.id,
                sendNotification: false,
                token: authUser['token']
            });
            Modal.info({
                title: "Verify New Email Address",
                content: 'In order to change the email address of your account, you are required to verify the ownership of email address. Please check your inbox and verify the new email address with the given link.',
            });
            setLoading(false);
            return;
        }

        ApiBaseHelper.put({
            url: "user",
            headers: {
                Authorization: 'Bearer ' + authUser['token'],
            },
            payload: data
        }).then((res) => {
            const name = res.data.data.Full_Name.split(" ");
            tech ? dispatch(updateTech({
                    ...tech,
                    "firstname": name[0],
                    "lastname": name[1],
                    "phone": res.data.data.Personal_Phone,
                    "imageUrl": res.data.data.imgUrl,
                    "email": res.data.data.Email_Address,
                    "bio": res.data.data.Profile_Bio,
                    "radius": res.data.data.Radius,
                    "skillExp": res.data.data.Skills_Experience,
                    'Stripe_Connect_Id': res.data.data.Stripe_Connect_Id,
                    'Drive_URL': res.data.data.Drive_URL,
                    'Provider_Service': 'Provider_Service' in  res.data.data ?  res.data.data.Provider_Service : [],
                }))
                : dispatch(updateUser({
                    ...client,
                    "firstname": name[0],
                    "lastname": name[1],
                    "phone": res.data.data.Personal_Phone,
                    "imageUrl": res.data.data.imgUrl,
                    "email": res.data.data.Email_Address,
                    'Provider_Service': 'Provider_Service' in  res.data.data ?  res.data.data.Provider_Service : [],
                }))

        }).then(() => {
            setLoading(false)
            notification.success({
                message: 'TecMe',
                description: 'Profile Updated Successfully',
            });

        }).catch((e) => {
            console.log(e)
            setLoading(false)
            notification.error({
                message: 'TecMe',
                description: 'Profile Update Failed',
            });
        })
    }

    useEffect(() => {
        form.setFieldsValue({
            firstName:authUser?.firstname,
            lastName: authUser?.lastname,
            email:authUser?.email,
            phone:authUser?.phone
        })
    }, [])

    return (
        <div className="my-details-section settings-tab-ctn">
            <div className="my-details-ctn">
                <div className="my-details-heading">
                    <Title level={4} style={{color:"#101828", fontSize:18, marginBottom:4}}>My details</Title>
                    <Paragraph style={{color: "rgba(0, 0, 0, 0.65)", margin:0}}>Update your photo and personal details here.</Paragraph>
                </div>
                <div className="my-details-form-section">
                    <Form  
                        form={form} 
                        onFinish={handleDetailsSubmit}
                        layout='vertical'
                        >
                        <Row gutter={{ xs:24, sm:24, md:12, lg:12, xl:12}} className='my-details-form-row'>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item 
                                    label="First name"
                                    name="firstName"
                                    rules={[{ required: true, message: 'Please input your firstname' }]}
                                >
                                    <Input className='my-details-form-input' />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item
                                    label="Last name"
                                    name="lastName"
                                    rules={[{ required: true, message: 'Please input your lastname' }]}
                                >
                                    <Input className='my-details-form-input'/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={{ xs:24, sm:24, md:12, lg:12, xl:12}} className='my-details-form-row'>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[{ required: true, message: 'Please input your email' }, {type: 'email', message: 'Please enter a valid email'}]}
                                >
                                    <Input className='my-details-form-input'/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item
                                    label="Phone number"
                                    name="phone"
                                    rules={[
                                        {
                                            required: tech ? true : false,
                                            pattern: new RegExp(/^[0-9+]{3}[0-9+]{3}[0-9+]{4}$/),
                                            message: "Phone number should be 10 digits"
                                        },
                                    ]}
                                >
                                    <Input className='my-details-form-input'/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className='my-details-form-row profile-image-row'>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item name="image">
                                    <Row gutter={[20, 20]} align='top'>
                                        <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                                            <Avatar size={64} src={imageUrl} icon={<UserOutlined />} />
                                        </Col>
                                        <Col xs={24} sm={24} md={21} lg={21} xl={21}>
                                            <ImgCrop 
                                                modalTitle='Crop Image'
                                                rotate
                                            >
                                               <Dragger {...props}>
                                                    <p className="ant-upload-drag-icon">
                                                    <InboxOutlined />
                                                    </p>
                                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                                    <p className="ant-upload-hint">
                                                    SVG, PNG, JPG or GIF (max. 800x400px)
                                                    </p>
                                                </Dragger>
                                            </ImgCrop>
                                            
                                            <Text style={{color: "rgba(0, 0, 0, 0.65)"}}>{profileUpdateMessage}</Text>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className='my-details-save-btn' loading={loading}>
                                        Save
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </div>
  )
}

export default MyDetails