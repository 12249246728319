import React, { Fragment } from "react";
import { Layout, Row, Col, Typography, Space, Menu } from 'antd';
import Lottie from "lottie-react";
import LoadingAnimation from '../../assets/animations/loading.json';
function Loader(props) {
  return (
    <Fragment>
      <Lottie
          style={{ height: props.height, width: props.width}}
          animationData={LoadingAnimation}
      />
    </Fragment>
  );
}

export default Loader;
