import React, { useEffect, useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import PageHeaderWrapper from '../../../../components/page-header-breadcrumbs'
import ROUTES from '../../../../../utils/routes'
import { Button, Layout, Modal, Space, Typography, notification } from 'antd'
import { META_DESCRIPTION, SECONDARY_COLOR } from '../../../../../utils/constants'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { useParams, Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PageFallback from '../../../../components/page-fallback'
import RightArrow from '../../../../../assets/images/right-arrow.svg'
import AcceptInviteImg from './assets/images/accept-invite.svg'
import { FirebaseHelper, FirestoreHelper } from '../../../../../utils/firebase-helper'
import { CloseCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { decode } from 'html-entities'
import './assets/css/styles.scss'
import ActionModal from '../create-itc/components/delete-modal'
import { stripHtml } from '../../../../../utils/helper'

const { Content } = Layout
const { Title, Paragraph } = Typography

const InviteDetails = () => {
    const history = useHistory()
    const tech = useSelector(state => state.tech)
    const [itcDetails, setItcDetails] = useState({})
    const [inviteDetails, setInviteDetails] = useState({})
    const [loadingDetails, setLoadingDetails] = useState(false)
    const [accepting, setAccepting] = useState(false)
    const [declining, setDeclining] = useState(false)
    const [groupConversation, setGroupConversation] = useState(null)
    const [loadingGroupConversation, setLoadingGroupConversation] = useState(false)
    const [acceptInviteModal, setAcceptInviteModal] = useState(false)
    const [invalidLink, setInvalidLink] = useState(false)
    const [declineModal, setDeclineModal] = useState(false)
    const params = useParams()

    const getItcDetails = () => {
        setLoadingDetails(true)
        ApiBaseHelper.get({
            url: `itc/${params?.id}`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setItcDetails(res?.data?.data)
            setLoadingDetails(false)
        }).catch((error) => {
            console.error(error)
            setLoadingDetails(false)
        })       
    }

    const getItcInviteDetails = () => {
        ApiBaseHelper.get({
            url: `itc/member/status/${params.id}/${tech.id}?status=Invited`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            console.log(res)
            if(res?.data?.data.length === 0){
                setInvalidLink(true)
                return
            }
            setInviteDetails(res?.data?.data[0])
        }).catch((error) => {
            console.error(error)
        })       
    }

    // Function to get and set group conversations for the commpany
    const getAndSetCoversation = async () => {
        setLoadingGroupConversation(true)
        await FirebaseHelper.login();
        const participantsResponse = await FirestoreHelper.getAllGroupConversations();
        const groupConversations = [];
        console.log(participantsResponse)
        participantsResponse.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          if (item.group && item.participants?.length) {
            groupConversations.push({ ...item});
          }
        });
        setGroupConversation(groupConversations.find((c) => c.group.toLowerCase() === (itcDetails?.Company_Name?.toLowerCase())))
        setLoadingGroupConversation(false)
    }

    // Function to add member to group chat after accepting invite
    const addMemberToGroupConverstation = async () => {
        const techName = `${tech?.firstname} ${tech?.lastname}`
        await FirestoreHelper.createUser({
            hipaa_id: tech?.id,
            display_name: techName,
            profile_picture: tech?.imageUrl,
            role: "Technician",
        });
        const newParticipants = [
            ...groupConversation.participants,
            tech?.id,
          ];
        await FirestoreHelper.updateGroupConversationMembers({
            participants: newParticipants,
            conversation_id: groupConversation?.id,
        })

        try {
            await ApiBaseHelper.post({
              url: `conversation/${groupConversation.id}/participant?object=itc`,
              headers: {
                Authorization: "Bearer " + tech?.token,
              },
              payload: {
                Title: groupConversation.group,
                Participants: [
                    // ...groupConversation.participants,
                    tech?.id
                ]
              }
            });
          } catch (error) {
            console.error(error)                
        }

        setAccepting(false) 
        setAcceptInviteModal(true)
    }
  

    const handleAcceptInvite = () => {
        if(itcDetails?.Active === "0"){
            notification.error({
                message: 'TecMe',
                description: 'This ITSC profile has been disabled by the owner'
            })
            return
        }
        setAccepting(true)
        ApiBaseHelper.put({
            url: `itc/member/${inviteDetails?.id}?type=invite`,	
            headers: {
                Authorization: 'Bearer ' + tech['token'],
            },
            payload: {
                Status: 'Active'
            }
        }).then((res) => {
            if(res){
                if(groupConversation){
                    addMemberToGroupConverstation()
                }else{
                    setAccepting(false)
                    setAcceptInviteModal(true)
                }   
            }
        }).catch((err) => {
            notification.error({
                message: 'TecMe',
                description: 'An error occurred while accepting invite'
            })
            setAccepting(false)
            console.log(err)
        })
    }
console.log(itcDetails)
    const handleDeclineInvite = () => {
        if(itcDetails?.Active === "0"){
            notification.error({
                message: 'TecMe',
                description: 'This ITSC profile has been disabled by the owner'
            })
            return
        }
        setDeclining(true)
        ApiBaseHelper.put({
            url: `itc/member/${inviteDetails?.id}?type=decline`,	
            headers: {
                Authorization: 'Bearer ' + tech['token'],
            },
            payload: {
                Status: 'Inactive'
            }
        }).then((res) => {
            setDeclining(false)
            notification.success({
                message: 'TecMe',
                description: 'Invite declined successfully'
            })
            history.push(ROUTES.ITC_INVITES_LISTING)
            setDeclineModal(false)
        }).catch((err) => {
            notification.error({
                message: 'TecMe',
                description: 'An error occurred while declining invite'
            })
            setDeclining(false)
            console.log(err)
        })
    }



    useEffect(() => {
        getItcDetails()
        getItcInviteDetails()
        
    }, [])

    useEffect(() => {
        if(itcDetails?.Company_Name){
            getAndSetCoversation()
        }
    }, [itcDetails])

    useEffect(() => {
        if(invalidLink){
            history.push(ROUTES.ITC_PROFILES)
        }
    },[invalidLink])

    if(loadingDetails || loadingGroupConversation) return <PageFallback />


    return (
        <>
            <MetaTags>
                <title>{itcDetails?.Company_Name} - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <PageHeaderWrapper
                routes={[
                    {
                        path: ROUTES.ITC_INVITES_LISTING,
                        breadcrumbName: 'ITSC Invites',
                    },
                    { 
                        path: ROUTES.ITC_INVITE_DETAILS.replace(':id', params?.id), 
                        breadcrumbName: itcDetails?.Company_Name 
                    }
                ]}
            />
            <Content className="main">
                <div className='page'>
                    <div className="auth-container">
                        <div className="auth-page-ctn invite-details-ctn">
                           {
                            itcDetails?.Active === "0" ?
                            <div className="invite-details-card">
                                <Title level={2}>ITSC profile disabled</Title>
                                <Paragraph  className="invite-details-paragraph mt-0">
                                    It appears that <i><strong>{itcDetails?.Company_Name}</strong></i> ITSC has been disabled by the owner. If you believe this is a mistake, please contact the owner through their <Link style={{color:SECONDARY_COLOR}} to={`${ROUTES.TECH_PROFILE_WITH_ID.replace(":id", `${itcDetails?.Owner_Contact}`)}?search=true`}>profile</Link>. 
                                </Paragraph>
                                <Button 
                                    size='large' 
                                    type="primary" 
                                    className="invite-details-btn"
                                    loading={accepting}
                                    onClick={() => history.push(ROUTES.DASHBOARD)}
                                >
                                    Go Home
                                </Button>
                            </div> :
                            <div className="invite-details-card">
                                <Title level={2} className="invite-details-title">Invitation to join <Link to={ROUTES.ITC_HOME.replace(':subdomain', itcDetails?.Subdomain)}>{itcDetails?.Company_Name}</Link></Title>
                                {/* <Paragraph 
                                    className="invite-details-paragraph"
                                    ellipsis={{ rows: 6, expandable: true, symbol: 'Read more' }}
                                >{decode(stripHtml(itcDetails?.Description))}</Paragraph> */}
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: itcDetails?.Description
                                    }}>
                                </div>
                                <Paragraph className='info-details-text'><InfoCircleOutlined style={{ marginRight:3, position:"relative", top:2}}/> You'll be added to {itcDetails?.Company_Name} group chat when you accept this invitation</Paragraph>
                                <div className="invite-details-btns">
                                    <Button 
                                        size='large' 
                                        type="secondary" 
                                        className="invite-details-btn"
                                        onClick={() => {
                                            setDeclineModal(true)
                                        }}
                                    >
                                        Decline Invite
                                    </Button>
                                    <Button 
                                        size='large' 
                                        type="primary" 
                                        className="invite-details-btn"
                                        loading={accepting}
                                        onClick={handleAcceptInvite}
                                    >
                                        Accept Invite
                                    </Button>
                                </div>
                                <div className="pending-invites-link">
                                    <Space>
                                        <Link to={ROUTES.ITC_INVITES_LISTING}>View Pending Invites</Link>
                                        <img src={RightArrow} alt="right arrow" />
                                    </Space>
                                </div>
                            </div> 
                            }
                        </div>
                    </div>
                </div>
            </Content>
            <Modal
                title={null}
                visible={acceptInviteModal}
                footer={null}
                maskClosable={false}
                closable={false}
                className='accept-invite-modal'
                onCancel={() => setAcceptInviteModal(false)}
                width={559}
            >
                <div 
                    className='close-modal'
                    onClick={() => history.push(ROUTES.ITC_INVITES_LISTING)}
                >
                    <CloseCircleOutlined />
                </div>
                <div className="accept-invite-modal-ctn">
                    <div className="accept-invite-modal-content">
                        <div className="accept-invite-modal-img">
                            <img src={AcceptInviteImg} alt=" congratulations image" />
                        </div>
                        <Title className='accept-invite-modal-title' level={4}>Congratulations!</Title>
                        <Paragraph className='accept-invite-modal-text'> You have been accepted to {itcDetails?.Company_Name} as a technician and can now work with the company.</Paragraph>
                        <div className="accept-invite-modal-btns">
                            <Button 
                                className="accept-invite-modal-btn" 
                                size='large' 
                                type="secondary" 
                                onClick={() => history.push(ROUTES.ITC_HOME.replace(':subdomain', itcDetails?.Subdomain))}
                            >
                                Visit webpage
                            </Button>
                            <Button 
                                className="accept-invite-modal-btn" 
                                size='large' type="primary" 
                                onClick={() => history.push(`${ROUTES.INBOX}?id=${groupConversation?.id}`)}
                            >
                                Go to chat
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
            <ActionModal
                visible={declineModal}
                handleCancel={() => {
                    setDeclineModal(false)
                }}
                handleClick={() => handleDeclineInvite()}
                message={"Please make sure you want to decline this invite before proceeding."}
                loading={declining}
                deleteText={"Confirm"}
            />
        </>
    )
}

export default InviteDetails