import { ApiBaseHelper } from "../../../../../../utils/api-base-helper";

export class PaymentRepository {
    constructor(accessToken) {
        this.accessToken = accessToken;
    }

    getPayments() {
        return ApiBaseHelper.get(
            {
                url: 'stripe/payment-method',
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    editPayment(paymentId, data) {

        return ApiBaseHelper.put(
            {
                url: `stripe/payment-method/${paymentId}`,
                payload: data,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    addPayment(data) {
        return ApiBaseHelper.post(
            {
                url: `stripe/payment-method`,
                payload: data,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    paymentDelete(paymentId) {
        return ApiBaseHelper.delete(
            {
                url: `stripe/payment-method/${paymentId}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }
}
