import React  from 'react'
import {  Col, Rate, Row, Space, Typography } from 'antd'
import '../assets/css/client-details.scss'
import { CheckSquareOutlined, CloseSquareOutlined, EnvironmentOutlined, SafetyCertificateFilled, UserOutlined, WalletOutlined} from '@ant-design/icons'
import SafetyIcon from '../assets/icons/safety-icon.svg'
import { altNameFormat } from '../../../../../../utils/helper'


const {Paragraph, Title} = Typography

const ClientJobDetails = (props) => {
    return (
        <div className={`job-post-client-details ${props.fromBrowseJobs && 'job-browse-client-details'}`}>
            <Title level={4}>Client details</Title>
            <Row gutter={[0, 40]}>
                <Col xs={24} sm={24} md={8} lg={props.fromBrowseJobs ? 8 : 6} xl={props.fromBrowseJobs ? 8 : 6} className='job-post-client-details-col'>
                    <div className="details-rate">
                        <Paragraph style={{fontSize:14, marginBottom:8}} strong>Client Name</Paragraph>
                        <Space align='center'>
                            <UserOutlined className='client-info-icon' />
                            <Paragraph style={{margin:0}}>{altNameFormat(props?.clientDetails?.Client_Name)}</Paragraph>
                        </Space>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={8} lg={props.fromBrowseJobs ? 8 : 6} xl={props.fromBrowseJobs ? 8 : 6} className='job-post-client-details-col'>
                    <div className="payment-info">
                        <Paragraph style={{fontSize:14, marginBottom:8}} strong>Payment Status</Paragraph>
                        {
                            props?.clientDetails?.Payment_Verified === 'true' ?
                            <Space align='center'>
                                <SafetyCertificateFilled className='client-info-icon' style={{color:"#36A7F9D9"}}/>
                                <Paragraph style={{margin:0, fontSize:14}}>Payment verified</Paragraph>
                            </Space> :
                            <Space align='center'>
                                <WalletOutlined className='client-info-icon'/>
                                <Paragraph style={{margin:0, fontSize:14}}>Payment unverified</Paragraph>
                            </Space>
                        }
                    </div>
                </Col>
                <Col xs={24} sm={24} md={8} lg={props.fromBrowseJobs ? 8 : 6} xl={props.fromBrowseJobs ? 8 : 6} className='job-post-client-details-col'>
                    <div className="projects-info">
                        <Paragraph style={{fontSize:14, marginBottom:8}} strong>Account Status</Paragraph>
                        {
                            props?.clientDetails?.Account_Verified === 'true' ?
                            <Space align='center'>
                                <CheckSquareOutlined  className='client-info-icon'/>
                                <Paragraph style={{margin:0, fontSize:14}}>Verified Account</Paragraph>
                            </Space> :
                            <Space align='center'>
                                <CloseSquareOutlined className='client-info-icon'/>
                                <Paragraph style={{margin:0, fontSize:14}}>Unverified Account</Paragraph>
                            </Space>
                        }
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ClientJobDetails