import { Col, Row, Skeleton } from 'antd'
import React from 'react'


const DashboardLoader = () => {

    return (
        <div className='new-user-ctn'> 
            <Skeleton active={true} className='mb-5'/>
            <div className='quick-start-guide'>
                <div className='quick-start-heading'>
                    <Skeleton active={true}/>
                </div>
                <Row className="card-section" gutter={[30, 30]} align={"stretch"}>
                    {
                        [0, 1, 2].map((step) => {
                            return(
                                <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                    <div className="guide-card" onClick={() => step.click()}>
                                        <div className="guide-icon">
                                            <Skeleton.Avatar active={true} size={64} shape="circle" />
                                        </div>
                                        <div className='guide-content'>
                    
                                            <Skeleton active={true} paragraph={{rows:2}}/>
                                            <Skeleton.Button active={true}/>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
            <div className='quick-start-guide'>
                <div className='quick-start-heading'>
                    <Skeleton active={true}/>
                </div>
                {
                <Row className="set-up-cards" gutter={[30, 30]} align={"stretch"}>
                    {
                        [0, 1, 2].map((step) => {
                            return(
                                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                    <div className="set-up-card">
                                        <Skeleton avatar active={true} paragraph={{rows:2}}/>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
                }
            </div>
        </div>
    )
}

export default DashboardLoader