import React, { Component, Fragment } from "react";
import {

    isMobile
} from "react-device-detect";
import {
    Layout,
    Select,
    Row,
    Col,
    Divider,
    Typography,
    Button,
    Card,
    Spin,
    Form,
    Checkbox,
    message,
    notification,
    Modal, Input, Table
} from 'antd';
import moment from 'moment';
import { ApiBaseHelper } from "../../../../../utils/api-base-helper";
import { DeleteOutlined, LoadingOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { Skeleton, Switch, List, Avatar } from 'antd';
import { StarOutlined, LikeOutlined, MessageOutlined } from '@ant-design/icons';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from "react-router-dom";
import ROUTES from '../../../../../utils/routes';
import {META_DESCRIPTION} from "../../../../../utils/constants";
import MetaTags from "react-meta-tags";
import PageHeaderWrapper from "../../../../components/page-header-breadcrumbs";
import { fontSize } from "@mui/system";
import SearchInput from "../../../../components/search-input";
import CustomRenderEmpty from "../../../../components/custom-render-empty";
import PageTitle from "../../../../components/page-title";
const antIcon = <LoadingOutlined style={{ fontSize: 22, color: "black" }} spin />;

const { Content } = Layout;
const { Title, Paragraph, Text } = Typography;
const { confirm } = Modal;
const { Option } = Select;
const listData = [];
for (let i = 0; i < 6; i++) {
    listData.push({
        href: 'https://ant.design',
        title: `ant design part ${i}`,
        description:
            'Ant Design, a design language for background applications, is refined by Ant UED Team.',
    });
}
class Certification extends Component {
    form = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            name: '',
            hint: '',
            description: '',
            id: '',
            loading: false,
            deleteLoading: false,
            formStatus: false,
            deleteIndex: null,
            visible: false,
            dataLoading: false,
            showData: [],
            deleteModal: false,
            deleteId: null,
            selectedCertification: null
        };
        this.columns = [
            {
                title: 'Certification Name',
                dataIndex: 'Cert_Name',
                width: '15%',
                key: 'Cert_Name',
                sorter: {
                    compare: (a, b) => a.Cert_Name.localeCompare(b.Cert_Name),
                    multiple: 1,
                },
                web: true,
                mobile: true
            },
            {
                title: 'Description',
                dataIndex: 'Description',
                width: '15%',
                key: 'Description',
                sorter: {
                    compare: (a, b) => a.Description.localeCompare(b.Description),
                    multiple: 1,
                },
                web: true,
                mobile: true
            },
            {
                title: 'Action',
                width: '15%',
                dataIndex: 'Id',
                key: 'Id',
                web: true,
                mobile: true,
                render: (text, record) => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <EditRoundedIcon fontSize="large" style={{ borderRadius: '50%', backgroundColor: '#F3F3F3', padding: '0.3rem', marginRight: '00.5rem' }} onClick={() => {
                        this.setState({ name: record.Cert_Name, hint: record.Hint, description: record.Description, id: record.id, visible: true, });
                        this.form.current.setFieldsValue({
                            name: record.Cert_Name, hint: record.Hint, description: record.Description, id: record.id, visible: true
                        });

                    }} />
                    <div>   {<DeleteRoundedIcon fontSize="large" style={{ cursor: 'pointer', color: '#F04A22', borderRadius: '50%', backgroundColor: '#F3F3F3', padding: '0.3rem' }}
                        onClick={() => { this.setState({ selectedCertification: record, deleteModal: true }) }} />}</div>
                </div>,
            },


        ];
        this.openNotification = (value) => {
            notification.open({
                message: value.message,
                description: value.description,
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
        };
        this.table = (responsive) => {

            return (
                <CustomRenderEmpty 
                    description="Show off your IT certifications on your tech profile to potential customers"
                    extra={
                        <Button onClick={() => {
                            this.setState({ visible: true, formStatus: true })
                            this.form.current.setFieldsValue({
                            name: null, hint: null, description: null, id: null
                            })}}
                        >
                            + Add IT Certifications
                        </Button>
                    }>
                <Table showSorterTooltip={false} rowKey="id" columns={this.columns.filter(item => responsive == true ? item.web : item.mobile == true)} dataSource={this.state.showData} scroll={{ x: '100%' }} loading={this.state.dataLoading || this.state.deleteLoading} />
                </CustomRenderEmpty>
            )

        }




    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({ dataLoading: true })
        ApiBaseHelper.get({
            url: "certification", headers: {
                Authorization: 'Bearer ' + this.props.tech.token
            }
        }).then((res) => {
            this.setState({ dataLoading: false, data: res.data.data, showData: res.data.data })
        }).catch((error) => {
            this.setState({ dataLoading: false })
        })
       
    }
   

    render() {
        return <Fragment>
            <MetaTags>
                <title >Manage Certifications | TecMe </title>
                <meta name="description" content={META_DESCRIPTION} />
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <Content className="main">
                <div className="page">
                    <div className="" style={{ minHeight: '800px' }}>
                        <Modal transitionName=""
                            maskTransitionName="" visible={this.state.deleteModal}  centered closable={false}
                               onCancel={(e)=>this.setState({ deleteModal: false })}
                               okText="Delete"
                               onOk={() => {
                                   this.setState({
                                       deleteModal: false,
                                       deleteLoading: true

                                   });
                                   ApiBaseHelper.delete({
                                       url: "certification/" + this.state.selectedCertification.id,
                                       headers: {
                                           Authorization: 'Bearer ' + this.props.tech.token,
                                       },
                                   }).then((response) => {

                                       notification['success']({
                                           message: 'TecMe',
                                           description:
                                               'Certification deleted successfully',
                                       });
                                       let filterData = this.state.data.filter(certification => certification.id != this.state.selectedCertification.id)
                                       this.setState({
                                           data: filterData,
                                           showData: filterData,
                                           deleteLoading: false,

                                       });


                                   }).catch((error) => {
                                       this.setState({
                                           deleteLoading: false,

                                       })
                                       console.log(error)
                                   })
                               }}

                        >
                            <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
                            <Paragraph className='my-2 disclaimer p-2'>
                            This action will permanently delete the certification and cannot be undone. Please make sure you want to delete the item before proceeding. 
                            </Paragraph>

                        </Modal>

                        <Modal
                            transitionName=""
                            maskTransitionName=""
                            className="ant-mdl"
                            centered
                            footer={false}
                            title={false}
                            visible={this.state.visible}
                            forceRender={this.state.visible ? false : true}
                            onCancel={() => { this.setState({ visible: false, formStatus: false }); this.check = {}; }}
                            width={700}
                        >
                            <Row >

                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                                        <Typography.Title level={3}>{this.state.formStatus ? "Add Certification" : "Edit Certification"}</Typography.Title>
                                        <Form
                                            layout="vertical"
                                            style={{ maxWidth: '100%' }}
                                            name="normal_login"
                                            className="login-form"
                                            initialValues={{
                                                name: this.state.name,
                                                description: this.state.description
                                            }}
                                            ref={this.form}
                                            onFinish={(values) => {

                                                if (values.name == this.state.name && values.description == this.state.description) {
                                                    this.setState({ loading: false })
                                                    return
                                                }
                                                this.setState({ loading: true, name: values.name, description: values.description })
                                                const payload = {
                                                    name: values.name,
                                                    hint: "null",
                                                    description: values.description
                                                }
                                                {
                                                    !this.state.formStatus ? ApiBaseHelper.put({
                                                        url: `certification/${this.state.id}`,
                                                        headers: {
                                                            Authorization: 'Bearer ' + this.props.tech['token'],
                                                        },
                                                        payload: payload
                                                    }).then((res) => {
                                                        const objIndex = this.state.data.findIndex(obj => obj.id == this.state.id)
                                                        let dataCopy = [...this.state.data];
                                                        dataCopy[objIndex] = { ...dataCopy[objIndex], Cert_Name: this.state.name, Description: this.state.description, Hint: this.state.hint };
                                                        this.setState({ data: dataCopy, showData: dataCopy });

                                                        notification['success']({
                                                            message: 'TecMe',
                                                            description:
                                                                'Certification updated successfully',
                                                        });

                                                        this.setState({ loading: false })
                                                        this.setState({ visible: false })

                                                    }).catch((error) => {

                                                        this.setState({ loading: false, visible: false })

                                                        notification['error']({
                                                            message: 'TecMe',
                                                            description:
                                                                'Certification not updated',
                                                        });
                                                    }) : ApiBaseHelper.post({
                                                        url: `certification`,
                                                        headers: {
                                                            Authorization: 'Bearer ' + this.props.tech['token'],
                                                        },
                                                        payload: payload
                                                    }).then((res) => {

                                                        this.setState({ loading: false, visible: false })
                                                        const newData = [...this.state.data];
                                                        newData.unshift({
                                                            id: res.data.data.id,
                                                            Cert_Name: this.state.name,
                                                            Hint: this.state.hint,
                                                            Description: this.state.description
                                                        })
                                                        this.setState({ data: newData, showData: newData })
                                                        this.form.current.setFieldsValue({
                                                            name: null, hint: null, description: null, id: null
                                                        });
                                                        this.setState({ formStatus: false })
                                                        // window.locations.reload(true);
                                                        notification['success']({
                                                            message: 'TecMe',
                                                            description:
                                                                'Certification added successfully',
                                                        });
                                                    }).catch((error) => {

                                                        this.setState({ loading: false, visible: false })

                                                        notification['error']({
                                                            message: 'TecMe',
                                                            description:
                                                                'Certification not added',
                                                        });
                                                    })
                                                }

                                            }
                                            }
                                        >
                                            <Form.Item
                                                style={{ marginBottom: 16, display: "flex", flexDirection: 'column', textAlign: 'left', }}
                                                name="name"
                                                label="Name"
                                                rules={[{ required: true, message: 'Please enter certification name' }]}
                                            >
                                                <Input style={{ borderRadius: "0.1rem" }} size={"large"} placeholder="Name of certification" />
                                            </Form.Item>

                                            <Form.Item
                                                style={{ marginBottom: 16, display: "flex", flexDirection: 'column', textAlign: 'left', }}
                                                name="description"
                                                label="Description"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter certification's description.",
                                                    },
                                                    {
                                                        min: 25,
                                                        message: 'It should be at least 25 characters or more.',
                                                    },
                                                ]}
                                            >
                                                <Input.TextArea
                                                    size="large"
                                                    rows={5}
                                                    showCount
                                                    placeholder="What does this certification allow you to do?"
                                                />
                                            </Form.Item>

                                             <div className="d-flex justify-content-end w-100 mt-4">    
                                                <Button type={"primary"} htmlType="submit" style={{ margin: 0 }}
                                                        loading={this.state.loading}
                                                >
                                                    Save
                                                </Button>
                                            </div> 
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </Modal>

                        <Row gutter={[15, 15]} justify={"center"}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div >
                                    
                                        <Row gutter={[20, 20]} align={"middle"} justify={"space-between"}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >

                                                <PageHeaderWrapper
                                                    routes={[
                                                        {
                                                            path: ROUTES.CERTIFICATION,
                                                            breadcrumbName: 'Certifications',
                                                        },
                                                    ]}
                                                />
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <div className={"auth-container"}>
                                                    <div style={{backgroundColor: "#FFFFFF",minHeight:"70vh"}}>
                                                        <PageTitle 
                                                            title={"Certifications"}
                                                            extra={
                                                                <Button
                                                                    onClick={() => {
                                                                    this.setState({ visible: true, formStatus: true })
                                                                    this.form.current.setFieldsValue({
                                                                        name: null, hint: null, description: null, id: null
                                                                    });
                                                                }}>
                                                                    + Add Certification  
                                                                </Button>
                                                            }
                                                            search={
                                                                <SearchInput
                                                                    placeholder="Search Certifications"
                                                                    onChange={(event) => {
                                                                        this.searchData = this.state.data.filter((val) => {
                                                                            if (event.target.value == "") {

                                                                                return val
                                                                            }
                                                                            else if (val.Cert_Name.toLowerCase().includes(event.target.value.toLowerCase()) || val.Description.toLowerCase().includes(event.target.value.toLowerCase())) {

                                                                                return val
                                                                            }

                                                                        })

                                                                        this.setState({ showData: this.searchData })
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    <Row gutter={[16, 16]} >
                                                        <Col xs={0} sm={0} md={24} lg={24} xl={24}  >
                                                            {this.table(true)}
                                                        </Col>
                                                        <Col xs={24} sm={24} md={0} lg={0} xl={0}  >

                                                            {/* {this.table(false)} */}
                                                            {
                                                                this.state.dataLoading ? <LoadingCards /> :
                                                                <CustomRenderEmpty 
                                                                description="Show off your IT certifications on your tech profile to potential customers"
                                                                extra={
                                                                    <Button onClick={() => {
                                                                        this.setState({ visible: true, formStatus: true })
                                                                        this.form.current.setFieldsValue({
                                                                        name: null, hint: null, description: null, id: null
                                                                        })}}
                                                                    >
                                                                        + Add IT Certifications
                                                                    </Button>
                                                                }> 
                                                                  <List
                                                                      grid={{ gutter: 16,  xs: 1,
                                                                          sm: 2,
                                                                          md: 2,
                                                                          lg: 3,
                                                                          xl: 3,
                                                                          xxl: 3, }}
                                                                      dataSource={this.state.showData}
                                                                      pagination={{
                                                                          pageSize: 10,
                                                                      }}
                                                                      renderItem={item => (
                                                                      <List.Item>
                                                                         <Card 
                                                                            key={item.id} 
                                                                            title={
                                                                            <Paragraph style={{margin:0}} strong>{item?.Cert_Name}</Paragraph>
                                                                            }
                                                                            style={{marginBottom:20}}>
                                                                            <Paragraph  ellipsis={{rows:2}} strong>
                                                                                {item?.Description}
                                                                            </Paragraph>
                                                                            <div style={{display:'flex', justifyContent:'space-between', columnGap:20}}>
                                                                                <Button 
                                                                                    style={{ borderRadius: 2, border: '1px solid #F04A22', color:'#F04A22', padding:'0 12px', cursor: 'pointer', width:"100%", display:'block', height:38}}
                                                                                    onClick={() => {
                                                                                        this.setState({ name: item.Cert_Name, hint: item.Hint, description: item.Description, id: item.id, visible: true, });
                                                                                        this.form.current.setFieldsValue({
                                                                                            name: item.Cert_Name, hint: item.Hint, description: item.Description, id: item.id, visible: true
                                                                                        });
                                                                
                                                                                    }} >
                                                                                        Edit  
                                                                                </Button> 
                                                                                <Button 
                                                                                    style={{ borderRadius: 2, border: '1px solid #F04A22', color:'#F04A22', padding:'0 12px', cursor: 'pointer', width:"100%", display:'block', height:38}}
                                                                                    onClick={() => { this.setState({ selectedCertification: item, deleteModal: true, deleteId:item.id }) }}>
                                                                                    {
                                                                                       (this.state.deleteId === item.id) &&this.state.deleteLoading ? <Spin indicator={antIcon} style={{color:"#F04A22"}} /> : "Delete" 
                                                                                    }
                                                                                </Button>
                                                                            </div>
                                                                        </Card>
                                                                      </List.Item>
                                                                      )}
                                                                  />  
                                                              </CustomRenderEmpty>
                                                            }
                                                            {/* {
                                                                this.state.showData?.map((item) => {
                                                                    return(
                                                                        <Card 
                                                                            key={item.id} 
                                                                            title={
                                                                            <Paragraph style={{margin:0}} strong>{item?.Cert_Name}</Paragraph>
                                                                            }
                                                                            style={{marginBottom:20}}>
                                                                            <Paragraph  ellipsis={{rows:2}} strong>
                                                                                {item?.Description}
                                                                            </Paragraph>
                                                                            <div style={{display:'flex', justifyContent:'space-between', columnGap:20}}>
                                                                                <Button 
                                                                                    style={{ borderRadius: 2, border: '1px solid #F04A22', color:'#F04A22', padding:'0 12px', cursor: 'pointer', width:"100%", display:'block', height:38}}
                                                                                    onClick={() => {
                                                                                        this.setState({ name: item.Cert_Name, hint: item.Hint, description: item.Description, id: item.id, visible: true, });
                                                                                        this.form.current.setFieldsValue({
                                                                                            name: item.Cert_Name, hint: item.Hint, description: item.Description, id: item.id, visible: true
                                                                                        });
                                                                
                                                                                    }} >
                                                                                        Edit  
                                                                                </Button> 
                                                                                <Button 
                                                                                    style={{ borderRadius: 2, border: '1px solid #F04A22', color:'#F04A22', padding:'0 12px', cursor: 'pointer', width:"100%", display:'block', height:38}}
                                                                                    onClick={() => { this.setState({ selectedCertification: item, deleteModal: true, deleteId:item.id }) }}>
                                                                                    {
                                                                                       (this.state.deleteId === item.id) &&this.state.deleteLoading ? <Spin indicator={antIcon} style={{color:"#F04A22"}} /> : "Delete" 
                                                                                    }
                                                                                </Button>
                                                                            </div>
                                                                        </Card>
                                                                    )
                                                                })
                                                            } */}
                                                        </Col>
                                                    </Row>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    
                                </div>
                            </Col>

                        </Row>
                    </div>
                </div>
            </Content >
        </Fragment >
    }

}

const LoadingCards = () => {
    return(
        <Row gutter={[20, 20]}>
        { [...Array(7).keys()].map(() => {
            return(
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <Card title={<Skeleton.Input />}
                        style={{
                            height: "auto",
                            width:"auto",
                        }}
                    >
                        <Skeleton />
                    </Card> 
                </Col>
            )
        }) }
     </Row>
    )
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(Certification);
