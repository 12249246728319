import React, {Component, Fragment, useEffect} from "react";
import {Button, Collapse, Input, Layout, notification, Select, Spin, Steps, Typography, Upload} from 'antd';
import {connect, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import Loader from "../../../../components/loader";
import Result from "antd/es/result";
import ROUTES from "../../../../../utils/routes";

const {Content} = Layout;

const DefaultPage = () => {
    const user = useSelector(state => state.user)
    const tech = useSelector(state => state.tech)
    const isLoggedIn = user || tech
    const history = useHistory();

    useEffect(() => {
        history.push(isLoggedIn ? ROUTES.DASHBOARD : ROUTES.LOGIN)
    }, [])

    return (
        <Fragment>
            <Content className="main">
                <Result
                    status="success"
                    icon={
                        <center>
                            <Spin size="large" />
                        </center>
                    }
                    // title="Connecting your stripe account ..."
                />
            </Content>
        </Fragment>
    );
};

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(DefaultPage);
