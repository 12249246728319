// External Imports
import { useState, useEffect } from 'react'
import { Modal, Typography, Space, Input, Rate, Button, notification, Popover,message } from 'antd'

import {DollarOutlined,DownloadOutlined,ExclamationCircleOutlined,EllipsisOutlined} from "@ant-design/icons/lib/icons";
import easyinvoice from 'easyinvoice';
import Moment from 'moment'
import { extendMoment } from 'moment-range';
import {
    canReadPageOnly
} from '../../../../../../../utils/helper';


const { Text, Title, Paragraph } = Typography
const { TextArea } = Input
const moment = extendMoment(Moment);

const BillingPopOver = ({record,contractDetails,handleFileDisputeClick,fileDisputeCheck,user,isContractManuallyPaused}) => {
   
    const [openBillingPopOver, setOpenBillingPopOver] = useState(false);
    const isDisputeAllowed = record?.status?.toLowerCase() == 'paid' && (Math.abs(moment(moment.utc(record?.created * 1000).toDate()).local().diff(moment(), 'days')) <= 7)
    const [messageApi, contextHolder] = message.useMessage();

    return <Popover
        open={openBillingPopOver}
        onOpenChange={(e) => setOpenBillingPopOver(e)}
        content={(item) => {
            return (
                <div className="card-popover-content">
                    {contextHolder}
                    <Space className='popover-action-item' onClick={(e) => {
                        var invoiceData = {
                            "images": {
                                // The logo on top of your invoice
                                "logo": "https://firebasestorage.googleapis.com/v0/b/tecme-mobile-prod.appspot.com/o/assets%2Fmisc%2Flogo.png?alt=media&token=29fb8540-2980-46a6-b206-d969a132f2c2",
                                // "logo": "https://public.easyinvoice.cloud/img/logo_en_original.png",
                                // The invoice background
                                "background": record.status == 'paid' ? "https://firebasestorage.googleapis.com/v0/b/tecme-mobile-prod.appspot.com/o/assets%2Fmisc%2Fpaid-invoice.png?alt=media&token=63401f35-ee96-4eb4-97e1-e832f43394cf" : null
                            },
                            "sender": {
                                // "company": `${record.account_name}`,
                                "company": `TecMe Inc.`,
                                "zip": "+1 888-678-3263",
                                "city": "support@tecme.io",
                                "country": "https://www.tecme.io"
                            },
                            "client": {
                                "company": record.customer_name,
                                "address": record.customer_email,
                                "zip": contractDetails.Type == "Per Ticket" ?  moment.unix(record.created).format('MMM D'): `${moment.unix(record.period_start).format('MMM D HH:mm A')}`,
                                "city":  contractDetails.Type == "Per Ticket" ? moment.unix(record.created).format('YYYY HH:mm A') : moment.unix(record.period_end).format('MMM D HH:mm A (YYYY)'),
                            },
                            "information": {
                                // Invoice number
                                "number": record.number.split('-')[0],
                                // Invoice data
                                "date":  moment.unix(record.created).format('MM-DD-YYYY'),
                                "due-date": moment.unix(record.created).format('MM-DD-YYYY'),
                            },
                            "products": record?.lines?.data?.map((line) => {
                                const hours = Math.floor(line.quantity / 60);
                                const minutes = line.quantity % 60;
                                // const timeSpent = moment.utc().startOf('day').add(hours, 'hours').add(minutes, 'minutes');
                                return {
                                    // "quantity": line.quantity/60,
                                    "quantity": 1,
                                    "description": contractDetails.Type == "Hourly" || contractDetails.Type == 'Per Ticket' ? `${contractDetails.Type} Contract - ${hours}h ${minutes}m at $${contractDetails.Billing_Rate / 100}/hr` : contractDetails.Description,
                                    "price": (line.amount / 100),
                                    "tax-rate": 0
                                }
                            }),
                            "settings": {
                                "currency": "USD",
                            },
                            "translate": {
                                // "quantity" : contractDetails.Type == "Fixed" ? "Quantity" : "Hourly Unit",
                                "number": "Invoice Number",
                                "price": "Sub Total",
                                "products": "Description",
                                "date": "Created"
                            },
                        };
                        easyinvoice.createInvoice(invoiceData, function (result) {
                            easyinvoice.download(`${record.number}.pdf`, result.pdf);
                        });
                        setOpenBillingPopOver(false)
                        messageApi.open({
                            key: 'download-invoice',
                            type: 'loading',
                            content: 'Downloading invoice ...',
                            duration: 6,
                            style: {
                                marginTop: '40vh',
                            },
                        });
                        // e.stopPropagation()
                    }}>
                        {/* <DownloadOutlined /> */}
                        <Paragraph style={{ marginBottom: 0 }}>Download Invoice</Paragraph>
                    </Space>

                    {(fileDisputeCheck && isDisputeAllowed && !canReadPageOnly(user?.role)) && (
                        <Space className='popover-action-item'
                            onClick={(e) => {
                                handleFileDisputeClick(record)
                                setOpenBillingPopOver(false)
                                e.stopPropagation()
                            }}
                        >
                            {/* <ExclamationCircleOutlined /> */}
                            <Paragraph style={{ marginBottom: 0 }}>File Dispute</Paragraph>
                        </Space>
                    )}

                    {
                        (record?.status === "open" && user && !isContractManuallyPaused && !canReadPageOnly(user?.role)) && (
                            <Space className='popover-action-item'
                                onClick={(e) => {
                                    window.open(record?.hosted_invoice_url)
                                    setOpenBillingPopOver(false)
                                    e.stopPropagation()
                                }}
                            >
                                {/* <DollarOutlined /> */}
                                <Paragraph style={{ marginBottom: 0 }}>Pay Invoice</Paragraph>
                            </Space>
                        )
                    }

                </div>
            )
        }} trigger="click" placement='bottomRight' overlayClassName="list-card-popover" arrowPointAtCenter>
        <EllipsisOutlined onClick={(e) => e.stopPropagation()} className='card-ellipsis' />
    </Popover>
}

export default BillingPopOver