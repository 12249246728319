import React, {Component} from 'react'
import {
    Avatar,
    Card,
    Col,
    Form,
    Input,
    Layout,
    Modal,
    notification,
    Row,
    Select,
    Skeleton,
    Space,
    Spin,
    Typography,
} from 'antd';

import Chat from './assets/images/chat.png'
import './css/real-time-messaging.css';
import {LoadingOutlined,MessageOutlined} from "@ant-design/icons";
import {META_DESCRIPTION, SECONDARY_COLOR} from "../../../../../utils/constants";
import {RtmRepository} from './repository/rtm-repository';
import {connect} from "react-redux";
import {extendMoment} from 'moment-range';
import {FirebaseHelper, FirestoreHelper} from "../../../../../utils/firebase-helper";
import { encryptString, ucwords} from "../../../../../utils/helper";
import Moment from 'moment';
import MetaTags from "react-meta-tags";
import {ApiBaseHelper} from "../../../../../utils/api-base-helper";
import NewArchiveIcon from './assets/images/archive-icon.svg';
import  MessagesRightPaneView from './components/messages-right-pane-view';
import ConversationCell from './components/conversation-cell';

const moment = extendMoment(Moment);
const {Content} = Layout;
const {Title, Paragraph} = Typography;
const { Search } = Input;
let filteredConversations = [];

class RealTimeMessaging extends Component {

    unsubscribeConversations = null;
    createConversationFormRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            chatBoxStatus: false,
            mobileWidth: false,
            loading: true,
            messageLoading: false,
            createConversationModal: false,
            createConversationLoader: false,
            data: [],
            activeConversation: null,
            newMessage: '',

            technicianList: [],
            technicianListLoading: true,

            //firebase
            filterApply: false,
            conversations: [],
            conversationsOriginal: [],
            messages: [],
            generalConversations:[],
            archivedConversations:[],
            conversationStatus: 'Active'

        };
        this.loadingUI = this.loadingUI.bind(this);
        this.noMessagesUI = this.noMessagesUI.bind(this);
        this.changeBackground = () => {
            if (window.innerWidth <= 769) {
                this.setState({mobileWidth: true})
            } else {
                this.setState({mobileWidth: false})
            }
        }
        window.addEventListener('resize', this.changeBackground);

        if (this.props.user == null && this.props.tech == null) {
            this.props.history('/login');
            return;
        }
        this.user = this.props.user ? this.props.user : this.props.tech;
        this.rtmRepository = new RtmRepository(this.user['token']);
    }


    handleGetConversations = async () => {
        await FirebaseHelper.login();
        this.unsubscribeConversations = FirestoreHelper.getConversations({
            user_id: this.user['id'],
            snapshot: (querySnapshot) => {
                let conversations = [];
                let generalConversations = [];
                let pinnedgeneralConversations = [];
                let pinnedArchivedConversations = [];
                let archivedConversations = [];

                querySnapshot.forEach(async (doc) => {
                    let item = doc.data();
                    item.id = doc.id;
                    let archived = item.archived ? item.archived : [];
                    if (archived.includes(this.user.id)) {
                        if (item.marked_favorite.includes(this.user.id))
                            pinnedArchivedConversations.push(item)
                        else
                            archivedConversations.push(item);
                    } else {
                        if (item.marked_favorite.includes(this.user.id))
                            pinnedgeneralConversations.push(item)
                        else
                            generalConversations.push(item);
                    }

                });

                archivedConversations = pinnedArchivedConversations.concat(archivedConversations);
                generalConversations = pinnedgeneralConversations.concat(generalConversations);

                conversations = this.state.conversationStatus == 'Active' ? generalConversations : archivedConversations;
                this.setState({
                    conversations: conversations,
                    conversationsOriginal: conversations,
                    archivedConversations,
                    generalConversations,
                    loading: false
                });
                if (this.state.activeConversation == null && conversations.length > 0) {
                    let paramId = this.props.location?.search?.includes("id") && this.props.location.search.slice(4);
                    let selectedConversation
                    if(paramId) {
                        selectedConversation = conversations.find(({id}) => id === paramId)
                    }
                    this.setState({
                        activeConversation: selectedConversation || conversations[0],
                    })
                }
            }
        });
    }

    async componentDidMount() {
        // window.scrollTo(0, 0);
        window.addEventListener('resize', this.changeBackground);
        
        this.handleGetConversations()

        // get tech list to populate them in create conversation modal
        ApiBaseHelper.get({url: "web/top-techs"}).then((res) => {
            let technicianList = res.data.data;
            if (technicianList.length > 0) {
                this.setState({
                    technicianList,
                    technicianListLoading: false
                })
            }
        });
        this.forceUpdate()
    }

    componentWillUnmount() {
        if (this.unsubscribeConversations != null) {
            this.unsubscribeConversations();
        }
    }


    loadingUI() {
        return (
            <Col span={24} style={{margin: '1rem auto',}}>
                <div>
                    <Row gutter={[0, 15]}>
                        <Col xl={6} md={11} lg={8} sm={this.state.chatBoxStatus ? 0 : 24}
                             xs={this.state.chatBoxStatus ? 0 : 24}>
                            {this.state.mobileWidth ?
                                <Card className="ant-chat" style={{overflow: 'hidden',}}>
                                    <div className="user-card">
                                        <img loading="lazy"  src={Chat} style={{width: '100px'}}/>
                                        <Title level={5}>Loading your inbox</Title>
                                        <Spin indicator={<LoadingOutlined style={{fontSize: 22, color: SECONDARY_COLOR}} spin/>}/>
                                    </div>
                                </Card>
                                :
                                <Card className="ant-chat" style={{
                                    overflow: 'hidden',
                                    height: '85vh',
                                    padding: '1rem'
                                }}>
                                    <Paragraph className="paragraph">Your conversations will appear here.</Paragraph>
                                    {[1, 2, 3, 4, 6, 7, 8, 9, 0].map(item => <div key={item} style={{marginBottom: '1rem'}}>
                                        <Skeleton loading={true}
                                                  active
                                                  avatar={<Avatar
                                                      className="ant-avatar-shap"
                                                      shape="circle"/>}
                                                  paragraph={false}/>
                                    </div>)}
                                </Card>}
                        </Col>
                        <Col xl={18} md={13} lg={16} sm={this.state.chatBoxStatus ? 24 : 0}
                             xs={this.state.chatBoxStatus ? 24 : 0}>
                            <Card className="ant-chat" style={{overflow: 'hidden', height: '85vh '}}>
                                <div className="user-card">
                                    <img loading="lazy"  src={Chat} style={{width: '100px'}}/>
                                    <Title level={5}>Loading your inbox</Title>
                                    <Spin indicator={<LoadingOutlined style={{fontSize: 22, color: SECONDARY_COLOR}} spin/>}/>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Col>
        );
    }

    noMessagesUI() {
        return (
            <Col span={24} style={{margin: '1rem auto',}}>
                <Card className="ant-chat" style={{overflow: 'hidden', height: '85vh'}}>
                    <div className="user-card">
                        <img loading="lazy"  src={Chat} style={{width: '100px'}}/>
                        <Title level={5}>No Messages, yet.</Title>
                        <Paragraph className="paragraph">No messages in your inbox, yet! Start chatting with technicians by
                            going to their profile page.</Paragraph>
                    </div>
                </Card>
            </Col>
        );
    }

    render() {
        const {loading, activeConversation, conversations,
            chatBoxStatus, filterApply, createConversationModal, technicianList,
            createConversationLoader,conversationStatus,generalConversations,archivedConversations} = this.state;
        return (
            <Content className="main">
                <MetaTags>
                    <title>Messages | TecMe </title>
                    <meta name="description" content={META_DESCRIPTION}/>
                    <meta name="robots" content="noindex, nofollow" />
                </MetaTags>
                <div className='page' style={{paddingBottom:0}}>
                    <div className="">
                        <Row>
                            <Col span={24}
                                 style={{
                                     marginTop: 10
                                 }}
                            >
                                <Modal
                                    title="Start group conversation"
                                    visible={createConversationModal}
                                    onOk={(e) => {
                                        this.createConversationFormRef.current.submit();
                                    }}
                                    confirmLoading={createConversationLoader}
                                    onCancel={() => (this.setState({createConversationModal: false}))}
                                    okText={"Submit"}
                                >
                                    <Form
                                        layout="vertical"
                                        name={"create-conversation-form"}
                                        ref={this.createConversationFormRef}
                                        onFinish={async (values) => {
                                            let group = ucwords(values.group);
                                            let members = values.members;
                                            let user = this.props.user ? this.props.user : this.props.tech;
                                            this.setState({
                                                createConversationLoader: true
                                            })
                                            this.rtmRepository.createGroupConversation(members).then(async (response) => {
                                                let conversationId = response.data.data.id;
                                                technicianList.map(async (tech, index) => {
                                                    if (members.includes(tech.data.Hipaa_id)) {
                                                        await FirestoreHelper.createUser({
                                                            hipaa_id: tech.data.Hipaa_id,
                                                            display_name: tech.data.Full_Name,
                                                            profile_picture: tech.data.imgUrl
                                                        });
                                                    }
                                                })
                                                await FirestoreHelper.createUser({
                                                    hipaa_id: user.id,
                                                    display_name: `${user['firstname']} ${user['lastname'][0]}.`,
                                                    profile_picture: user['imageUrl']
                                                });

                                                let message = `Group "${group}" created by ${user['firstname']} ${user['lastname'][0]}`;

                                                await FirestoreHelper.createConversation({
                                                    conversation_id: conversationId,
                                                    message: encryptString(message),
                                                    participants: members.concat([user.id]),
                                                    user_id: user.id,
                                                    group
                                                });

                                                await FirestoreHelper.sendMessage({
                                                    conversation_id: conversationId,
                                                    message: encryptString(message),
                                                    user_id: user.id
                                                });

                                                await this.rtmRepository.sendMessage({
                                                    conversationId: conversationId,
                                                    message: message,
                                                    createdAt: moment().utc().toISOString()
                                                })

                                                this.setState({
                                                    createConversationLoader: false,
                                                    createConversationModal: false
                                                })

                                                notification['success']({
                                                    message: 'TecMe',
                                                    description:
                                                        `Group ${group} created successfully.`,
                                                });
                                                this.createConversationFormRef.current.resetFields();
                                            })
                                        }}
                                    >
                                        <Form.Item label="Group Name" required name={"group"}
                                                   rules={[{
                                                       required: true,
                                                       message: 'Please enter group name'
                                                   }]}
                                        >
                                            <Input placeholder="Enter group name here"/>
                                        </Form.Item>

                                        <Form.Item label="Select group members" required name={"members"}
                                                   rules={[
                                                       ({getFieldValue}) => ({
                                                           validator(_, value) {
                                                               let members = value ? value : [];
                                                               if (members.length < 2) {
                                                                   return Promise.reject(new Error('Please select at least 2 members from the list.'));
                                                               }
                                                               return Promise.resolve();
                                                           },
                                                       }),
                                                   ]}
                                        >
                                            <Select
                                                mode="multiple"
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Select group members"
                                                optionLabelProp="label"
                                                filterOption={(input, option) =>
                                                    option.props.label !== null
                                                    &&
                                                    (option.props.label).toLowerCase().includes((input).toLowerCase())
                                                }
                                            >
                                                {
                                                    technicianList.map((tech, index) => {
                                                        return <Select.Option key={`tech-option-${index}`}
                                                                              value={tech.data.Hipaa_id}
                                                                              label={tech.data.Full_Name}>
                                                            <Row gutter={[10, 10]} align={"middle"}>
                                                                <Col>
                                                                    <span role="img" aria-label={tech.data.Full_Name}>
                                                                    <Avatar src={tech.data.imgUrl}/>
                                                                </span>
                                                                </Col>
                                                                <Col>
                                                                    <Typography.Text>{tech.data.Full_Name}</Typography.Text>
                                                                </Col>
                                                            </Row>
                                                        </Select.Option>
                                                    })
                                                }

                                            </Select>
                                        </Form.Item>
                                    </Form>
                                </Modal>

                                {
                                    loading && this.loadingUI()
                                }
                                {
                                    archivedConversations.length == 0 && generalConversations.length == 0 && loading == false && filterApply == false && this.noMessagesUI()
                                }
                                {
                                    (archivedConversations.length > 0 || generalConversations.length>0 || filterApply == true) &&
                                    <div style={{margin: '10px auto 0px auto',}}>
                                        <Row gutter={[0, 15]}>
                                            <Col sm={this.state.chatBoxStatus ? 0 : 24}
                                                 xs={this.state.chatBoxStatus ? 0 : 24}
                                                 md={9}
                                                 lg={9}
                                                 xl={6}
                                            >
                                                <Card className="ant-chat ant-chat-conversation" style={{overflow: 'hidden', height: '85vh'}}>
                                                    <Card className='chat-list-header'>
                                                        <Row align={"middle"} justify={"space-between"}>
                                                            <Col><Typography.Title level={4} className={"mt-2"}>Messages</Typography.Title></Col>
                                                            <Col>
                                                               { this.state.conversationStatus === "Active" ?
                                                                <Space 
                                                                    style={{cursor:"pointer"}}
                                                                    onClick={() => {
                                                                        let conversations = this.state.archivedConversations;
                                                                        this.setState({
                                                                            conversations,
                                                                            conversationStatus: "Archived",
                                                                            conversationsOriginal: conversations,
                                                                            activeConversation: conversations.length>0? conversations[0] : null
                                                                        });
                                                                    }}
                                                                >
                                                                    <img src={NewArchiveIcon} alt="archive Icon" />
                                                                    <Paragraph style={{margin:0, color:"rgba(0, 0, 0, 0.65)", fontSize:14, fontWeight:500}}>Archived chats</Paragraph>
                                                                </Space> :
                                                                <Space 
                                                                    style={{cursor:"pointer"}}
                                                                    onClick={() => {
                                                                        let conversations = this.state.generalConversations;
                                                                        this.setState({
                                                                            conversations,
                                                                            conversationStatus: "Active",
                                                                            conversationsOriginal: conversations,
                                                                            activeConversation: conversations.length>0? conversations[0] : null
                                                                        });
                                                                    }}
                                                                >
                                                                    <MessageOutlined />
                                                                    <Paragraph style={{margin:0, color:"rgba(0, 0, 0, 0.65)", fontSize:14, fontWeight:500}}>Active chats</Paragraph>
                                                                </Space>
                                                                }
                                                                {/* <Radio.Group buttonStyle="solid"
                                                                             style={{width: '100%'}}
                                                                             defaultValue={"Active"}
                                                                             onChange={(e) => {
                                                                                 let conversations = e.target.value == 'Active' ? this.state.generalConversations : this.state.archivedConversations;
                                                                                 this.setState({
                                                                                     conversations,
                                                                                     conversationStatus: e.target.value,
                                                                                     conversationsOriginal: conversations,
                                                                                     activeConversation: conversations.length>0? conversations[0] : null
                                                                                 });
                                                                             }}>
                                                                    <Radio.Button value="Active">Active</Radio.Button>
                                                                    <Radio.Button value="Archived">Archived</Radio.Button>
                                                                </Radio.Group> */}
                                                            </Col>
                                                        </Row>
                                                    </Card>

                                                    <Card className="ant-card-buttom" style={{padding: '16px 24px'}}>
                                                        <Search
                                                            className='conversation-search'
                                                            placeholder="Find a conversation"
                                                            onChange={(e) => {
                                                                let value = e.target.value;
                                                                if (value.length > 0) {
                                                                    let conversations = [];
                                                                    let conversationIds = [];
                                                                    filteredConversations.map((item, index) => {
                                                                        if (item.title.toLowerCase().includes(value.toLowerCase()) && !conversationIds.includes(item.conversation.id)) {
                                                                            conversations.push(item.conversation);
                                                                            conversationIds.push(item.conversation.id);
                                                                        }
                                                                    });
                                                                    this.setState({
                                                                        conversations,
                                                                        filterApply: true
                                                                    });
                                                                } else {
                                                                    this.setState({
                                                                        conversations: this.state.conversationsOriginal,
                                                                        filterApply: false
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </Card>
                                                    <div className="scroll" style={{height: '65vh', margin: 0, overflowY: 'auto'}}>
                                                        {conversations.length == 0 &&
                                                        <Paragraph style={{textAlign: 'center', marginTop: '1rem'}}>No conversations found</Paragraph>}
                                                        {
                                                            conversations.map((item, index) => {
                                                                return <ConversationCell
                                                                    key={`conversation-${Date.now()}-${item.id}`}
                                                                    item={item}
                                                                    user={this.user}
                                                                    activeConversation={this.state.activeConversation}
                                                                    filteredConversations={filteredConversations}
                                                                    onClickConversation={(activeConversation) => {
                                                                        if (activeConversation.id != this.state.activeConversation.id)
                                                                            this.setState({
                                                                                activeConversation
                                                                            })

                                                                        this.setState({
                                                                            chatBoxStatus: true,
                                                                        })
                                                                    }}
                                                                />

                                                            })
                                                        }
                                                    </div>
                                                </Card>
                                            </Col>
                                            {
                                                activeConversation != null &&
                                                <MessagesRightPaneView
                                                    key={`messages-${activeConversation.id}`}
                                                    conversation={activeConversation}
                                                    conversationStatus={this.state.conversationStatus}
                                                    user={this.user}
                                                    isTech={this.props.tech ? true : false}
                                                    chatBoxStatus={this.state.chatBoxStatus}
                                                    unsubscribeConversations={this.unsubscribeConversations}
                                                    onClickBack={(e) => {
                                                        this.setState({
                                                            chatBoxStatus: false
                                                        })
                                                    }}
                                                    getConversations={this.handleGetConversations}  
                                                    getConversation={(conversation) => {
                                                        this.setState({
                                                            activeConversation: conversation
                                                        })
                                                    }}
                                                    onArchive={(activeConversation)=>{
                                                        this.setState({
                                                            activeConversation: null
                                                        });
                                                    }}
                                                />
                                            }

                                        </Row>
                                    </div>
                                }
                            </Col>


                        </Row>


                    </div>

                </div>
            </Content>
        )
    }


}

const mapStateToProps = store => {
    return store;
};
export default connect(mapStateToProps)(RealTimeMessaging);


