// External Imports
import { useState, useEffect } from 'react'
import { Modal, Typography, Space, Input, Rate, Button, notification } from 'antd'

// Internal Imports
import { ApiBaseHelper } from '../../../../../../../utils/api-base-helper'

const { Text, Title, Paragraph } = Typography
const { TextArea } = Input

const FeedbackModal = ({ visible, onOk, onCancel, repository, contractId, contractDetails, getAndSetContractDetails, role, user, tech }) => {
    const [loading, setLoading] = useState(false);
    const [rating, setRating] = useState(5);
    const [feedback, setFeedback] = useState('');
    const [feedbackError, setFeedbackError] = useState('');

    const handleSubmitClick = async () => {
        if(feedback === ""){
            setFeedbackError("Pleace specify your feedback")
            return
        }
        setLoading(true)
        try {
            const orgId = role === 'customer' ? contractDetails?.Provider_Org?.Org_Id : contractDetails?.Customer_Org?.id
            const hipaaId = role === 'customer' ? contractDetails?.Provider_Org?.HIPAA_Primary_Contact : contractDetails?.Customer_Org?.HIPAA_Primary_Contact
            const payload = {
                techHipaaId: hipaaId,
                rating,
                comment: feedback || ' '
            }
            await ApiBaseHelper.post({
                url: `technician/${orgId}/review`,
                payload
            })
            onOk()
        } catch (error) {
            notification.error({
                message: 'TecMe',
				description: 'Submit review failed'
            })
        }
                
        setLoading(false)
    }

    return (
        <Modal
            title={<Title level={4} className='m-0 font-weight-500 fs-4'>Contract Ended Successfully</Title>}
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            footer={false}
            centered
            className='edit-contract-modal'
        >
            <Space direction='vertical' className='w-100'>
                <Space>
                    <Rate value={rating} onChange={newRating => setRating(newRating)} /> 
                    <Text>{rating}.0</Text>
                </Space>
                <Title level={4} className='font-weight-500'>
                    {`Give feedback to ${role === 'customer' ? (contractDetails?.Provider_Org?.HIPAA_Contact_Name || contractDetails?.Provider_Org?.Name) : contractDetails?.Customer_Org?.Name}`}
                </Title>
                <TextArea
                    value={feedback}
                    placeholder='Please specify the feedback'
                    rows={4}
                    showCount
                    maxLength={250}
                    onChange={e =>{ 
                        setFeedback(e.target.value)
                        setFeedbackError("")
                    }}
                />
                { feedbackError && <Text style={{color:"#F04A22", position:"relative", top:-20}}>{feedbackError}</Text>}
                <Space className='mt-2 w-100 justify-content-end'>
                    <Button type='text' onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button type="primary" loading={loading} onClick={handleSubmitClick}>
                        Submit
                    </Button>
                </Space>
            </Space>
        </Modal>
    )
}

export default FeedbackModal