import { useState, useEffect } from 'react'
import { Modal, Typography, Avatar, Divider } from 'antd'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'

const { Title, Text, Paragraph } = Typography

const ContactTechnicianModal = ({ visible, onCancel, ticketDetails }) => {
    const [tech, setTech] = useState({})

    useEffect(() => {
        if(ticketDetails?.Provider_Contact?.Profile_URL) {
            ApiBaseHelper.get({
                url: `web/search-tech?profileUrl=${ticketDetails?.Provider_Contact?.Profile_URL}`
            }).then(res => {
                if(res?.data?.data?.Data) {
                    setTech(res.data.data.Data)
                }
            }).catch(error => console.error(error))
        }
    }, [ticketDetails?.Provider_Contact?.Profile_URL])

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            className="remote-access-session-modal"
            footer={false}
            width={600}
        >
            <Title level={3} className="m-0">Phone your technician</Title>
            <Text>
                Give your technician a phone call to discuss any issues related to your ticket. Follow the instructions below to call your technician using their TecMe code.
            </Text>
            <div className='step-wrapper mt-3'>
                <Avatar className='step-number-wrapper' size={30}>
                    <Text className='step-number'>1</Text>
                </Avatar>
                <Text className='fs-6 font-weight-500'>Call TecMe</Text>
            </div>
            <Text>
                First, give our business line a call.
            </Text>
            <Paragraph className='fs-6 font-weight-500 m-0'>(888) 678-3263</Paragraph>
            <Divider className='my-2' />
            <div className='step-wrapper'>
                <Avatar className='step-number-wrapper' size={30}>
                    <Text className='step-number'>2</Text>
                </Avatar>
                <Text className='fs-6 font-weight-500'>
                    Press 2
                </Text>
            </div>
            <Text>
                Enter the technician connect workflow by pressing 2 after the operator speaks.
            </Text>
            <Divider className='my-2' />
            <div className='step-wrapper'>
                <Avatar className='step-number-wrapper' size={30}>
                    <Text className='step-number'>3</Text>
                </Avatar>
                <Text className='fs-6 font-weight-500'>
                    Enter the technician's code
                </Text>
            </div>
            <Text>
                Enter the technicians code and you will be directly connected with the technician. {ticketDetails?.Provider_Contact?.Full_Name}'s code is:
            </Text>
            <Paragraph className='fs-6 font-weight-500 m-0'>
                {tech?.Tech_code?.split("#")?.[1] || ''}
            </Paragraph>
        </Modal>
    )
}

export default ContactTechnicianModal