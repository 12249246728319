import React from 'react'
import moment from 'moment';
import { Tooltip } from 'antd';
import { getLocalTimeFormat } from '../../../utils/helper';

const NotificationTimestamp = ({timeStamp}) => {
  const localTimestamp = moment(moment.utc(timeStamp).toDate()).local()
  return (
    <Tooltip title={getLocalTimeFormat(timeStamp)} placement="right">
      <small>{ timeStamp !== null ? (localTimestamp.fromNow()): "N/A"}</small>
    </Tooltip>
  )
}

export default NotificationTimestamp