import {DatePicker, Form, notification, Modal, Select, Space, Button, Typography, List, Avatar} from 'antd'
import moment from 'moment';
import React, {useEffect, useState} from 'react'
import {useTimeSheet} from '../hooks/useTimesheet';
import TextArea from "antd/es/input/TextArea";
import {ContractRepository} from '../repository';
import {useSelector} from "react-redux";
import {SECONDARY_COLOR} from "../../../../../../../utils/constants";
import {capitalizeFirstLetter, getTableTimeFormat} from "../../../../../../../utils/helper";

const {RangePicker} = DatePicker;
const {useForm} = Form


// purpose of this component is to show the history of time entries for a contract
const ViewTimeEntryHistory = ({
                                  showTimeEntryHistoryModal,
                                  setShowTimeEntryHistoryModal,
                                  setSelectedTimeEntry,
                                  selectedTimeEntry,
                              }) => {


    const user = useSelector(state => state.user)
    const tech = useSelector(state => state.tech)
    const token = user?.token || tech?.token
    const [activitiesList, setActivitiesList] = useState([]);

    const [loading, setLoading] = useState(false);


    // initiate constant with ContractRepository
    let contractRepository = new ContractRepository(token);

    const fetchData = () => {
        if (selectedTimeEntry && activitiesList.length == 0 && loading == false) {
            setLoading(true);
            contractRepository.getActivities(selectedTimeEntry?.id).then((response) => {
                if (response.data?.data?.length) {
                    setActivitiesList(response.data.data.sort((a, b) => moment(b.CreatedDate).diff(moment(a.CreatedDate))))
                }
                setLoading(false);
            });

        }
    }

    useEffect(() => {
        fetchData();
    }, [selectedTimeEntry])


    return (
        <Modal title="Time Entry: Activity Logs"
               open={showTimeEntryHistoryModal}
               footer={null}
               onCancel={() => {
                   setSelectedTimeEntry(undefined);
                   setShowTimeEntryHistoryModal(false)
               }}
        >
            <List
                dataSource={activitiesList}
                loading={loading}
                renderItem={(activity, index) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={
                                <Avatar shape='circle' size={42} src={activity.Contact?.imgUrl} alt={activity.Contact?.Full_Name || 'TecMe'} style={{background: SECONDARY_COLOR}}>
                                    {capitalizeFirstLetter(activity.Contact?.Full_Name?.[0]) || 'T'}
                                </Avatar>
                            }
                            title={activity.Contact.Full_Name}
                            description={<div style={{wordWrap:'break-word',whiteSpace:'pre-line'}}>
                                {activity.custom_data?.message}
                            </div>}
                        />
                        <div>{getTableTimeFormat(activity?.CreatedDate)}</div>
                    </List.Item>
                )}
            />

        </Modal>
    )
}

export default ViewTimeEntryHistory