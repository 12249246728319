import React from 'react'
import { Avatar, Button, Card, Col, Input, List, Rate, Row, Skeleton, Space, Typography, notification } from 'antd'
import ROUTES from '../../../../../../utils/routes'
import { SECONDARY_COLOR } from '../../../../../../utils/constants'
import { EnvironmentOutlined } from '@ant-design/icons'


const { Title, Paragraph } = Typography

const TechCard = ({techDetails,  selectedMemberId,  setSelectedMemberId, addMemberToTeam, invitingMember, techTeam}) => {
  return (
    <div className="tech-team-item">
        <div className="tech-team-tech-info">
            <Space size={12}>
                <Avatar
                    size={74} 
                    src={techDetails?.image}
                    shape='square'
                    style={{color:SECONDARY_COLOR}}
                >
                    {techDetails?.name.split(" ")[0][0]}{techDetails?.name.split(" ")[1][0].toUpperCase()}
                </Avatar>
                <div className="tech-team-tech-details">
                    <Paragraph style={{margin:0, color:"#000", fontSize:18, fontWeight:500}}>{techDetails?.name}</Paragraph>
                    <Space size={3}>
                        <EnvironmentOutlined style={{marginTop:4}}/>
                        <Paragraph  style={{margin:0, color:"#000"}}>{techDetails?.city}{techDetails?.state ? `, ${techDetails?.state}` : ""}</Paragraph>
                    </Space>
                    {techDetails?.rating ?
                        <div className="rating-label">
                            <Paragraph className="rating-text">{Math.round(techDetails?.rating)}</Paragraph>
                            <Rate disabled defaultValue={Math.round(techDetails?.rating)} count={1} style={{color:SECONDARY_COLOR, fontSize:14, marginTop:4}} />
                            {/* <Paragraph className="reviews-text">({techDetails?.data?.Total_Reviews})</Paragraph> */}
                        </div> :
                        <div className="no-rating-label"></div>
                    }
                </div>
            </Space>
        </div>
        <div className="tech-team-item-summary">
            <Paragraph ellipsis={{rows:2}} style={{fontWeight:300, color:"#000", margin:0, fontSize:14}}>{techDetails?.bio}</Paragraph>
            <div className='summary-btn-ctn'>
                <Button 
                    type='secondary' 
                    className='summary-btn'
                    onClick={() => {window.open(
                        `${ROUTES.TECH_PROFILE_WITH_ID.replace(":id", `${techDetails?.objectID}`)}`, '_blank'
                    )
                    }}
                    
                >
                    View Profile
                </Button>
            <Button 
                    type='primary' 
                    className='summary-btn' 
                    onClick={() => {
                        setSelectedMemberId(techDetails?.objectID)
                        addMemberToTeam(techDetails)
                    }}
                    loading={invitingMember && selectedMemberId === techDetails?.objectID}
                    disabled={techTeam.find((member) => member.Member_Contact === techDetails?.objectID && (member.Status === "Invited" || member.Status === "Active" || member.Status === "Applied"))}
                >
                    + Invite
                </Button>
            </div>
        </div>
    </div>
  )
}

export default TechCard