import { useState, useEffect } from 'react'
import { Modal, notification, Typography } from 'antd'
import { LocationTechnicians } from '..'

const { Text, Title, Paragraph } = Typography

const TechSelectModal = ({ visible, onOk, onCancel, ticketDetails, ticketRepository, getAndSetTicketDetails, getAndSetActivities, isFromReassignAction }) => {
    const [selectedContract, setSelectedContract] = useState({})
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    useEffect(async () => {
      if(Object.keys(selectedContract)?.length) {
        try {
            setApiCallInProgress(true)
            const payload = {
                Technician_Contact: selectedContract?.Provider_Org?.HIPAA_Primary_Contact,
                Technician_Name: (selectedContract?.Provider_Org?.HIPAA_Contact_Name || selectedContract?.Provider_Org?.Name),
                Contract: selectedContract?.id
            }
            await ticketRepository?.reassignTicket(ticketDetails?.id, payload)
            await getAndSetTicketDetails()
            await getAndSetActivities()
            onOk()
        } catch (error) {
            // notification.error({
            //     message: 'TecMe',
			// 	description: 'Tech selection failed'
            // })
            setSelectedContract({})
        }
        setApiCallInProgress(false)
      }
    }, [Object.keys(selectedContract)?.length])

    return (
        <Modal
            visible={visible}
            // title={<Title level={3} className='font-weight-500' style={{marginBottom:0}}>{isFromReassignAction ? 'Reassign' : 'Assign A'} Technician</Title>}
            onCancel={onCancel}
            className="resolve-ticket-modal"
            footer={false}
        >
             <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
            <Paragraph className='my-2 disclaimer p-2'>
               Are you sure you want {isFromReassignAction ? 'reassign' : 'assign a'} technician?
            </Paragraph>
            <LocationTechnicians
                title={<Paragraph strong>Select a technician whom to {isFromReassignAction ? 'reassign' : 'assign'} this ticket</Paragraph>}
                selectedContract={selectedContract}
                setSelectedContract={setSelectedContract}
                isFromTechSelectModal
                apiCallInProgress={apiCallInProgress}
                onOk={onOk}
                ticketDetails={ticketDetails}
                isFromReassignAction={isFromReassignAction}
            />
        </Modal>
    )
}

export default TechSelectModal