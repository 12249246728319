import { CheckCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Radio, Space, Typography } from 'antd'
import React from 'react'

const { Title, Paragraph } = Typography

const JobDuration = ({handleNextClick, jobPostingData, setJobPostingData}) => {
    
    return (
        <div className='job-duration-ctn'>
            <div className="job-duration-content">
                <div className="job-duration-heading">
                    <Space>
                        <CheckCircleOutlined style={{color:"#F04A22", fontSize:22}} />
                        <Title level={4} style={{marginBottom:0, fontSize:20, color:"#000"}}>What is the expected project duration?</Title>
                    </Space>
                    <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", fontSize:14, marginTop:17}}>Provide an estimated duration or timeline for the project to help IT professionals understand the expected time commitment.</Paragraph>
                </div>
            <JobDurationForm 
                    jobPostingData={jobPostingData}
                    setJobPostingData={setJobPostingData}
            />
                <div className="job-post-next-btn-ctn">
                    <Button 
                        type='primary' 
                        className='job-post-next-btn' 
                        onClick={handleNextClick}
                        disabled={!jobPostingData.job_duration}
                        >Next</Button>
                </div>
            </div>
        </div>
    )
}

export const JobDurationForm = ({jobPostingData, setJobPostingData}) => {

    return(
        <div className="job-duration-form">
            <Form>
                <Form.Item>
                    <Radio.Group 
                        value={jobPostingData.job_duration}
                        onChange={(e) => {
                            setJobPostingData({
                                ...jobPostingData,
                                job_duration: e.target.value
                            })
                        }}
                    >
                        <Space direction="vertical">
                            {/* <Radio value="one-time">One-time project</Radio>
                            <Title level={5} style={{fontWeight:400,  color:"rgba(0, 0, 0, 0.65)", fontSize:14}}>This is a single, standalone project that has a specific goal or deliverable to be completed within a defined timeframe.</Title> */}

                            <Radio value="Short" >Short-term</Radio>
                            <Title level={5} style={{fontWeight:400,  color:"rgba(0, 0, 0, 0.65)", fontSize:14}}>This project has a relatively brief duration, typically ranging from a few weeks to a couple of months.</Title>

                            <Radio value="Medium" >Medium-term</Radio>
                            <Title level={5} style={{fontWeight:400,  color:"rgba(0, 0, 0, 0.65)", fontSize:14}}>This project has a moderate duration, typically spanning a few months up to half a year.</Title>

                            <Radio value="Long">Long-term</Radio>
                            <Title level={5} style={{fontWeight:400,  color:"rgba(0, 0, 0, 0.65)", fontSize:14}}>This project has an extended duration, lasting for six months or more.</Title>
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </div>
    )
}
export default JobDuration