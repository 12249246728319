import { Button, ConfigProvider, Empty } from 'antd';
import React from 'react'

const CustomRenderEmpty = ({children, description, extra, image}) => {
    const customizeRenderEmpty = () => (
        <div style={{ textAlign: 'center', display:'flex', alignItems:"center", justifyContent:"center", height:"40vh" }}>
            <Empty 
                image={image ? image : Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                    <p>{description}</p>
                  }
                >
                  {extra && extra}
            </Empty>
        </div>
    );

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
        {children}
    </ConfigProvider>
  )
}

export default CustomRenderEmpty