import {initializeApp} from "firebase/app";
import {getFirebaseConfig} from "./firebase-helper";
import { getStorage, ref, uploadBytes,getDownloadURL,uploadBytesResumable } from "firebase/storage";

const FIREBASE_CONFIG = getFirebaseConfig();
const firebaseApp = initializeApp(FIREBASE_CONFIG);
const storage = getStorage(firebaseApp);

export class FirebaseStorageHelper {
    static async upload({path,file}) {
        const storageRef = ref(storage, path);
        const metadata = {
            contentType: file.type,
        };
        let snapshot = await uploadBytes(storageRef, file,metadata);
        let downloadURL = await getDownloadURL(snapshot.ref)
        return downloadURL;
    }

    static async uploadFile({path,file,setProgressPercent,setImgUrl}) {
        const storageRef = ref(storage, path);
        const uploadTask = uploadBytesResumable(storageRef, file.originFileObj);
        uploadTask.on("state_changed",
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                if(typeof setProgressPercent === 'function') {
                    setProgressPercent(progress);
                }
            },
            (error) => {
                alert(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setImgUrl(downloadURL)
                });
            }
        );
    }
}




