import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Modal, notification, Space, Typography } from 'antd'
import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import routes from '../../../../../utils/routes'

const DeleteListModal = ({showDeleteListModal, setShowDeleteListModal, listId, getSavedTechniciansList, routeAfterDeleting}) => {
    const user = useSelector((state) => state.user);
    const [deletingList, setDeletingList] = useState(false)
    const history = useHistory()

    const handleDeleteList = () => {
        setDeletingList(true) 
        ApiBaseHelper.delete({
            url: `saved-technician/list/${listId}`, 
            headers: {
                Authorization: 'Bearer ' + user?.token,
            },
        }).then((res) => {
            if(res){
                notification['success']({
                    message:"TecMe",
                    description:"List Deleted Successfully"
                })
                getSavedTechniciansList()
                setDeletingList(false)
                setShowDeleteListModal(false)
                if(routeAfterDeleting){
                    history.push(routes.SAVED_PROFILES)
                }
            }
        }).catch((error) => {
            console.log(error)
            notification['error']({
                message: "TecMe",
                description:"An Error Occurred"
            })
            setDeletingList(false)
        })
    }

    return (
        <Modal
            open={showDeleteListModal} 
            onOk={() => setShowDeleteListModal(false)} 
            onCancel={() => {
                setShowDeleteListModal(false)
            }}
            width={555}
            closable={false}
            footer={null}
            centered
        >
            <Space>
                <ExclamationCircleOutlined style={{fontSize:22, color:"#FAAD14"}} />
                <Typography.Paragraph style={{marginBottom:0, fontSize:16, fontWeight:500, color:"rgba(0, 0, 0, 0.85)"}}>Are you sure you want to delete this list?</Typography.Paragraph>
            </Space>
            <Space size={16} className='mt-3 w-100 justify-content-end'>
                <Button type='secondary' style={{height:40, fontSize:16}} className="neutral-btn" onClick={() => {
                   setShowDeleteListModal(false)
                }}>
                    Cancel
                </Button>
                <Button type="primary" style={{height:40, fontSize:16}} loading={deletingList} onClick={handleDeleteList}>
                Yes
                </Button>
            </Space>
        </Modal>
    )
}

export default DeleteListModal