import { ApiBaseHelper } from "../../../../../../utils/api-base-helper";

export class BookingRepository {
    constructor(accessToken,role) {
        this.accessToken = accessToken;
        this.role = role;
    }

    getBookings() {
        return ApiBaseHelper.get(
            {
                url: `v2/booking/?type=${this.role}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    getSubscriptions() {
        return ApiBaseHelper.get(
            {
                url: `v2/booking/subscriptions?type=${this.role}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    cancelSubscription(id) {
        return ApiBaseHelper.delete(
            {
                url: `v2/booking/subscription/${id}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    changeSubscriptionPaymentMethod(subscriptionId, paymentId) {
        return ApiBaseHelper.put(
            {
                url: `v2/booking/subscription/${subscriptionId}`,
                payload: {
                    "payment_method_id": paymentId
                },
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },

            }
        )
    }

    getRequests() {
        return ApiBaseHelper.get(
            {
                url: `v2/booking/request`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

}

