export const getDurationInfo = (duration) => {
    switch (duration) {
        case "Short":
            return { 
                text: "Short Term",
                subText: "Less than 3 months"
            }
        case "Medium":
            return {
                text: "Medium Term",
                subText: "3 to 6 months"
            }
        case "Long":
            return {
                text: "Long Term",  
                subText: "6 months and more"
            }
        default:
           return {
                text: "Not Specified",
                subText: "Not Specified"
           }

    }
}

export const getWorkTypeInfo = (workType) => {
    switch (workType) {
        case "Remote":
            return {
                text: "Remote",
                subText: "Work from home"
            }
        case "On_Site":
            return {
                text: "On-site",
                subText: "Work from office"
            }
        case "Both":
            return {
                text: "Remote & On-site",
                subText: "Mix of work type"
            }
        default:
            return {
                text: "Not Specified",
                subText: "Not Specified"
            }

    }
}

export const convertBudget = (budget, payment_type) => {
    if(budget?.includes('-')) {
        return `$${budget.split('-')[0]/100} - $${budget.split('-')[1]/100} ${payment_type === "Hourly" ? '/hr' : ' Fixed cost'}`
    }
    return '$' + budget/100 + (payment_type === "Hourly" ? '/hr' : ' Fixed cost')
}

export const getSpecializationTitle = (workCategories, categoryId) => {
    return workCategories?.find((item) => item.id === (categoryId))?.Title
}

export const formatText = (text, fontSize) => {
    text?.split('\n').map((line, index) => (
        <p style={{fontSize:fontSize, lineHeight:"22px"}} key={index}>
          {line}
        </p>
    ))
}