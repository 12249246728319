import { CheckCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Space, Typography } from 'antd'
import React from 'react'

const { Title, Paragraph } = Typography

const JobTitle = ({handleNextClick, jobPostingData, setJobPostingData}) => {


    return (
        <div className='job-title-ctn'>
            <div className="job-title-content">
                <div className="job-title-heading">
                    <Space>
                        <CheckCircleOutlined style={{color:"#F04A22", fontSize:22}} />
                        <Title level={4} style={{marginBottom:0, fontSize:20, color:"#000"}}>Let's get started with a strong title</Title>
                    </Space>
                    <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", fontSize:14, marginTop:17}}>Your job post's title is the gateway to attracting the perfect candidates. It's the first impression they'll have, so let's make it unforgettable!</Paragraph>
                </div>
                <JobTitleForm 
                    jobPostingData={jobPostingData} 
                    setJobPostingData={setJobPostingData}
                />
                <div className="job-post-next-btn-ctn">
                    <Button 
                        type='primary' 
                        className='job-post-next-btn' 
                        onClick={handleNextClick} 
                        disabled={!jobPostingData?.job_title}>
                            Next
                    </Button>
                </div>
            </div>
        </div>
    )
}

export const JobTitleForm = ({jobPostingData, setJobPostingData}) => {

    const handleJobTitleChange = (e) => {
        setJobPostingData({...jobPostingData, job_title: e.target.value})
    }

    return(
        <>
            <div className="job-title-form">
                <Form>
                    <Paragraph style={{fontWeight:500}}>Write a title for your job post:</Paragraph>
                    <Form.Item>
                        <Input 
                            type="text" 
                            placeholder="Type here"
                            value={jobPostingData.job_title}
                            onChange={handleJobTitleChange}
                        />
                    </Form.Item>
                </Form>
            </div>
            <div className="job-title-examples">
                <Paragraph style={{fontSize:14, fontWeight:500}}>Examples:</Paragraph>
                <ul>
                    <li>Systems Administrator Expert for managing and maintaining computer systems</li>
                    <li>Network admin to upgrade infrastructure for remote work</li>
                    <li>Virtualization Specialist skilled in deploying and managing virtualized environments</li>
                </ul>
            </div>
        </>
    )
}

export default JobTitle