import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import GuestSignUp from '../guest-sign-up'
import Login from '../login'
import PageFallback from '../../../../../components/page-fallback'
import { FirebaseHelper } from '../../../../../../utils/firebase-helper'
import TagManager from 'react-gtm-module'
import { updateTech, updateUser } from '../../../../../../redux/reducers/app'
import { TouchPointLogout } from '../../../../../../utils/helper'
import { useDispatch, useSelector } from 'react-redux'
import { GTM_ID } from '../../../../../../utils/constants'
import ROUTES from '../../../../../../utils/routes'
import { useHistory } from 'react-router-dom'
import { notification } from 'antd'

const AcceptInvite = () => {
    const {search} = useLocation()
    const user = useSelector(state => state.user)
    const params = new URLSearchParams(search)
    const [userExists, setUserExists] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    const orgId = params.get('org_id')
    const ocaId = params.get('oca_id')
    const email = params.get('email')
    const company = params.get('company')

    // check if user is registered or not
    const checkAccountAvailability = () => {
        setLoading(true)
        ApiBaseHelper.get({
            url: `web/account?email=${email}`,
        }).then((res) => {
            console.log(res)
            if(res?.data?.data?.length > 0) {
                setUserExists(true)
            } else {
                setUserExists(false)
            }
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    }

    // logout user, if user is logged in
    const logoutUser = () => {
        FirebaseHelper.detachAllEventListeners({path: `users/${user?.id}`});
        if (user?.touch != null) {
            TouchPointLogout(user?.touch)
        }
        dispatch(updateTech(null));
        dispatch(updateUser(null));
        <Login isGuest={true} email={email} ocaId={ocaId} orgId={orgId} company={company}/>
        const tagManagerArgs = {
            gtmId: GTM_ID,
            dataLayer: {
                user_id: null,
                org_id: null
            }
        }
        TagManager.initialize(tagManagerArgs)
    }

     // verify if invite link is valid
     const verifyInviteLink = () => {
        setLoading(true)
        ApiBaseHelper.post({
            url: `web/verify-invite/${ocaId}/${orgId}`,
        }).then((res) => {
            console.log(res)
            if(res?.data?.data !== null) {
                checkAccountAvailability()
                logoutUser()
            } else {
                notification.error({
                    message: 'TecMe',
                    description: 'The link to the invitation you are attempting to access is invalid. It is possible that you have already accepted the invitation, or the link may have expired',
                })
                history.push(ROUTES.DASHBOARD)
            }
            setLoading(false)   
        }).catch((err) => {
            console.log(err)
            notification.error({
                message: 'TecMe',
                description: 'The link to the invitation you are attempting to access is invalid. It is possible that you have already accepted the invitation, or the link may have expired',
            })
            history.push(ROUTES.DASHBOARD)
            setLoading(false)
        })
    }

    useEffect(() => {  
        verifyInviteLink()
    }, [])

    if(loading) {
        return (
            <PageFallback />
        )
    }

    if(userExists) {
        return(
            <Login isGuest={true} email={email} ocaId={ocaId} orgId={orgId} company={company}/>
        )
    }
    return (
        <GuestSignUp email={email} ocaId={ocaId} orgId={orgId} company={company}/>
    )
}

export default AcceptInvite