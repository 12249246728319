import React, { Component, Fragment } from 'react';
import { Row, Col, Layout, Input, Card, Avatar, Button, Typography, Space, Form, Tabs, notification, Skeleton, List, Modal, message } from 'antd'
import MessageIcon from '@mui/icons-material/Message';
import ManageBookingImage from './assets/images/manage.png'
import ReceiptIcon from '@mui/icons-material/Receipt';
import Calendar from './assets/images/calender.png';
import Mobile from './assets/images/mobile.png'
import Process from './assets/images/Process.png'
import { connect } from "react-redux";
import SearchIcon from '@mui/icons-material/Search';
import NoBookingFound from './assets/images/booking ui-whitebg.png'
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Route } from "react-router-dom";
import ROUTES from '../../../../../utils/routes';
import './assets/css/booking.css'
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import { BookingRepository } from './repository/repository';
import { throwStatement } from '@babel/types';
import { Link } from "react-router-dom";
import { DATE_TIME_FORMAT, META_DESCRIPTION } from "../../../../../utils/constants";
import CheckIcon from "@mui/icons-material/Check";
import {getHomePageURL, toCustomStatus} from "../../../../../utils/helper";
import { ServiceRepository } from "../service-details/repository/repository";
import Divider from "antd/lib/divider";
import TypeImage from "../service-details/assets/images/service-type.png";
import Icon from '../../../../../assets/images/icon.png'
import MetaTags from "react-meta-tags";
import TechConnectModal from "../../../../components/modals/tech-connect-modal";
const { Content, } = Layout
const { TabPane } = Tabs;
const { Title, Paragraph } = Typography
const customerHeader = [
    { status: "Pending", bookings: [], message: "Your IT support requests (a technician must accept your request before it's scheduled)." },
    { status: "Scheduled", bookings: [], message: "Bookings that a technician has accepted and are scheduled for service." },
    { status: "Cancelled", bookings: [], message: "Bookings that have been cancelled." },
    { status: "In Progress", bookings: [], message: "Bookings that a technician is currently servicing." },
    { status: "Completed", bookings: [], message: "Services that are marked completed by the technician." },
    { status: "Awaiting Payment", bookings: [], message: "Bookings that have all services completed and are awaiting final confirmation & payment from you." },
    { status: "Paid", bookings: [], message: "Confirmed bookings that you have paid for." },
];
const techHeader = [
    { status: "Direct Request", bookings: [], message: "Customer requests made directly to you for support.", },
    { status: "Open Request", bookings: [], message: "Customer requests open for any qualified technician to support." },
    { status: "Scheduled", bookings: [], message: "Bookings that you have accepted and are scheduled for service." },
    { status: "Cancelled", bookings: [], message: "Bookings that have been cancelled." },
    { status: "In Progress", bookings: [], message: "Bookings that you are currently servicing (on the clock)." },
    { status: "Completed", bookings: [], message: "Bookings that you've completed all services for and are awaiting customer payment (can still add more services if needed)." },
    { status: "Awaiting Payment", bookings: [], message: "Bookings that you've completed all services for and are awaiting customer payment (can still add more services if needed)." },
    { status: "Paid", bookings: [], message: "Confirmed bookings that have received payment from the customer." },
];

class ManageBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            visible: false,
            dataLoading: false,
            yourRequests: [],
            openRequests: [],
            filteredData: [],
            yourRequestsLength: null,
            searchTerm: "",
            showAddServiceModal: false,
            techId: null,
            techOrg: null,
            bookingId: null,
            bookingHeaders: this.props.user ? JSON.parse(JSON.stringify(customerHeader)) : JSON.parse(JSON.stringify(techHeader))
        };
        this.role = this.props.user ? "customer" : "tech";
        this.user = this.props.user ? this.props.user : this.props.tech;
        this.getBookings = this.getBookings.bind(this);
        this.getBookingById = this.getBookingById.bind(this);
        this.getRequests = this.getRequests.bind(this);
        this.bookingCell = this.bookingCell.bind(this);
        this.searchBooking = () => {
            let searchTerm = this.state.searchTerm.toLowerCase();
            let filteredData = this.state.data.filter((booking) => {
                let services = booking.items.filter(a => a.type == "service");
                const deepObject = services.filter(service => {
                    return moment(service.scheduledDateTime).format(DATE_TIME_FORMAT).toLowerCase().includes(searchTerm) ||
                        //  service.user.type.toLowerCase().includes(searchTerm) ||
                        //  service.user? service.user.name.toLowerCase().includes(searchTerm) : false
                        service.issue.title.toLowerCase().includes(searchTerm)
                        || service.mode.replace("_", " ").toLowerCase().includes(searchTerm)
                        || toCustomStatus(service.status, booking.status).toLowerCase().includes(searchTerm)
                        || service.issue.description.toLowerCase().includes(searchTerm);
                })
                if (searchTerm == "") {
                    return booking
                }
                else if (booking.orgName.toLowerCase().includes(searchTerm)) {
                    return booking
                }
                else if (deepObject.length > 0) {
                    booking['items'] = deepObject;
                    return booking;
                }
            });

            this.setState({
                filteredData
            });
        };
        this.bookingRepository = new BookingRepository(this.props.user ? this.props.user.token : this.props.tech.token)
        this.serviceRepository = new ServiceRepository(this.props.user ? this.props.user.token : this.props.tech.token);
    }


    componentDidMount() {
        this.props.history.push(getHomePageURL(this.user.id));
        return
        this.setState({ dataLoading: true, bookingHeaders: this.props.user ? JSON.parse(JSON.stringify(customerHeader)) : JSON.parse(JSON.stringify(techHeader)) });
        if (this.props.match.params.id != undefined) {
            this.props.history.push(ROUTES.BOOKING_DETAILS_NEW.replace(':id',this.props.match.params.id));
            return;
            this.getBookingById();
        }
        else {
            this.props.history.push(getHomePageURL(this.user.id));
            return
            this.getBookings();
        }
    }

    getBookingById() {
        this.bookingRepository.getBookingDetails({
            bookingId: this.props.match.params.id,
            userType: this.props.user ? "customer" : "tech"
        })
            .then(res => {
                let booking = res.data.data;
                let services = booking.items.filter(service => (service.type == "service"));
                booking.items = services;
                let bookingHeaders = [{ status: "Booking Details", bookings: [booking], message: "" }];

                this.setState({ dataLoading: false, bookingHeaders, data: [booking] })
            })
            .catch(err => this.setState({ dataLoading: false }))
    }

    getBookings() {
        this.bookingRepository.getBookings(this.props.user ? "customer" : "tech")
            .then(res => {

                let responseData = res.data.data;
                let bookingHeaders = this.state.bookingHeaders;
                this.setState({ data: res.data.data })
                res.data.data.map((booking, index) => {
                    let userPendingRecord = 0;
                    let userAcceptedRecord = 0;
                    let techAcceptedRecord = 0;
                    let userInProgressRecord = 0;
                    let techInProgressRecord = 0;
                    let userCancelledRecord = 0;
                    let techCancelledRecord = 0;
                    let userCompletedRecord = 0;
                    let techCompletedRecord = 0;

                    if (this.role == 'customer') {
                        booking.items.map((service, index) => {
                            if (service.type == "service") {
                                if (service.status == "Pending" && userPendingRecord == 0) {
                                    userPendingRecord++
                                    let deepCopyofBooking = { ...booking }
                                    let pending = deepCopyofBooking.items.filter(val => (val.status == "Pending"));
                                    let refactorBooking = { ...booking };

                                    refactorBooking.items = pending;
                                    bookingHeaders[0].bookings.push(refactorBooking);
                                } else if (service.status == "Accepted" && userAcceptedRecord == 0) {

                                    userAcceptedRecord++
                                    let deepCopyofBooking = { ...booking }
                                    let pending = deepCopyofBooking.items.filter(val => (val.status == "Accepted"));

                                    let refactorBooking = { ...booking }
                                    refactorBooking.items = pending;

                                    bookingHeaders[1].bookings.push(refactorBooking);

                                } else if (service.status == "In Progress" && userInProgressRecord == 0) {
                                    userInProgressRecord++
                                    let deepCopyofBooking = { ...booking }
                                    let pending = deepCopyofBooking.items.filter(val => (val.status == "In Progress"));
                                    let refactorBooking = { ...booking }
                                    refactorBooking.items = pending;

                                    bookingHeaders[3].bookings.push(refactorBooking);

                                } else if (service.status == "Cancelled" && userCancelledRecord == 0) {
                                    userCancelledRecord++
                                    let deepCopyofBooking = { ...booking }
                                    let pending = deepCopyofBooking.items.filter(val => (val.status == "Cancelled"));
                                    let refactorBooking = { ...booking }
                                    refactorBooking.items = pending;

                                    bookingHeaders[2].bookings.push(refactorBooking);

                                }
                                else if (service.status == "Completed" && booking.status == "Pending" && userCompletedRecord == 0) {
                                    userCompletedRecord++
                                    let deepCopyofBooking = { ...booking }
                                    let pending = deepCopyofBooking.items.filter(val => (val.status == "Completed"));
                                    let refactorBooking = { ...booking }
                                    refactorBooking.items = pending;

                                    bookingHeaders[4].bookings.push(refactorBooking);

                                }
                            }

                        })
                        if ((booking.status == "Completed")) {
                            bookingHeaders[5].bookings.push(booking);
                        } else if ((booking.status == "Confirmed")) {
                            bookingHeaders[6].bookings.push(booking);
                        }

                    } else {
                        booking.items.map(service => {
                            if (service.type == "service") {
                                if (service.status == "Accepted" && techAcceptedRecord == 0) {
                                    techAcceptedRecord++
                                    let deepCopyofBooking = { ...booking }
                                    let pending = deepCopyofBooking.items.filter(val => (val.status == "Accepted"));
                                    let refactorBooking = { ...booking }
                                    refactorBooking.items = pending;

                                    bookingHeaders[2].bookings.push(refactorBooking);

                                } else if (service.status == "In Progress" && techInProgressRecord == 0) {
                                    techInProgressRecord++
                                    let deepCopyofBooking = { ...booking }
                                    let pending = deepCopyofBooking.items.filter(val => (val.status == "In Progress"));
                                    let refactorBooking = { ...booking }
                                    refactorBooking.items = pending;

                                    bookingHeaders[4].bookings.push(refactorBooking);

                                } else if (service.status == "Cancelled" && techCancelledRecord == 0) {
                                    techCancelledRecord++
                                    let deepCopyofBooking = { ...booking }
                                    let pending = deepCopyofBooking.items.filter(val => (val.status == "Cancelled"));
                                    let refactorBooking = { ...booking }
                                    refactorBooking.items = pending;

                                    bookingHeaders[3].bookings.push(refactorBooking);

                                }
                                // else if ((service.status == "Completed" || service.status == "Cancelled") && booking.status == "Pending" && techCompletedRecord == 0) {
                                //     techCompletedRecord++;
                                //     let deepCopyofBooking = { ...booking }
                                //     let pending = deepCopyofBooking.items.filter(val => (val.status == "Completed" || service.status == "Cancelled"));
                                //     let refactorBooking = { ...booking }
                                //     refactorBooking.items = pending;
                                //     if (pending.length != booking.items.length - 1) {
                                //         bookingHeaders[5].bookings.push(refactorBooking);
                                //     }

                                // }
                            }

                        }
                        )

                        if ((booking.status == "Completed") && booking.accepted == true) {
                            bookingHeaders[6].bookings.push(booking);
                        } else if ((booking.status == "Confirmed")) {
                            bookingHeaders[7].bookings.push(booking);
                        }
                        else if (booking.status == "Pending") {
                            let deepCopyofBooking = { ...booking }
                            let pending = deepCopyofBooking.items.filter(val => ((val.status == "Cancelled" || val.status == "Completed")));
                            let refactorBooking = { ...booking }
                            let refactorBookingOfItems = { ...booking }

                            if ((pending.length) == (booking.items.length - 1)) {
                                refactorBooking = { ...booking, completed: true }

                                bookingHeaders[5].bookings.push(refactorBooking);
                            }
                            else {
                                if (pending.length != 0) {
                                    refactorBookingOfItems.items = pending;
                                    bookingHeaders[5].bookings.push(refactorBookingOfItems);
                                }
                            }


                        }
                    }
                });
                if (this.role == 'tech') {
                    this.getRequests(responseData);
                    return
                } else
                    this.setState({ dataLoading: false, bookingHeaders })
            })
            .catch(err => this.setState({ dataLoading: false }))
    }

    getRequests(bookingsData) {
        this.bookingRepository.getRequests()
            .then(res => {

                let responseData = res.data.data;
                let bookingHeaders = this.state.bookingHeaders;

                let yourRequests = [];
                responseData.yourRequests.map((booking) => {
                    if (booking['status'] == 'Pending') {
                        yourRequests.push(booking)
                    }
                });

                bookingHeaders[1].bookings = responseData.openRequests;
                bookingHeaders[0].bookings = yourRequests;


                this.setState({ bookingHeaders, dataLoading: false, });
            })
            .catch(err => this.setState({ dataLoading: false }))
    }

    bookingCell(item, index, status, booking) {
        return <div>
            {/* <p>{(index == 0 && this.state.yourRequestsLength > 0 && status == "Request") ? <><Title level={4}>Direct Request</Title><p>Customer requests made directly to you for support.</p></> : (index > this.state.yourRequestsLength - 1 && status == "Request") ? <><Title level={4}>Open Request</Title><p>Customer requests open for any qualified technician to support.</p></> : null}</p> */}
            <Card key={item.bookingId} style={{ margin: 0, padding: 0, marginBottom: '1rem', borderRadius: '0.5rem', }}
                title={
                    <Row gutter={[16, 16]} align="middle">
                        <Col xs={24} sm={24} md={6} lg={14} xl={15}>
                            <Row gutter={[16, 16]} align='middle'>
                                <Col xs={3} sm={2} md={5} lg={1} xl={1} >
                                    {item.orgPic == null && item.orgName != null ?
                                        <Avatar style={{
                                            color: '#f56a00',
                                            backgroundColor: '#fde3cf',
                                            fontSize: 20,
                                            textTransform: 'uppercase'
                                        }}>{item.orgName[0]}</Avatar> : item.orgPic != null ?
                                            <Avatar
                                                src={item.orgPic} />
                                            : <Avatar style={{
                                                color: '#f56a00',
                                                backgroundColor: '#fde3cf',
                                                fontSize: 20
                                            }}>N</Avatar>
                                    }
                                </Col>
                                <Col xs={11} sm={15} md={10} lg={15} xl={16}>
                                    <p style={{ margin: 0, marginLeft: '1rem', textTransform: 'capitalize' }}>
                                        {item.orgName != null ? item.orgName : "Searching for a technician..."}
                                    </p>
                                </Col>

                            </Row>

                        </Col>

                        <Col xs={24} sm={24} md={18} lg={10} xl={9}>
                            <Row gutter={[16, 16]} align="middle" justify="end" style={{ textAlign: 'right' }}>


                                {
                                    (item.status == "Confirmed"
                                        || item.status == "Cancelled"
                                        || item.status == "Completed")
                                    &&
                                    <Col xs={24} sm={24} md={8} lg={10} xl={11} >
                                        {<><Button className="booking-btn" size="large" style={{ width: '100%' }}>
                                            <Link
                                                to={{
                                                    pathname: `${ROUTES.INVOICE.replace(':id', item.bookingId)}`,
                                                }}
                                            >
                                                <ReceiptIcon fontSize="small" style={{ marginRight: '0.5rem' }} />
                                                VIEW INVOICE
                                            </Link>
                                        </Button>
                                        </>
                                        }

                                    </Col>
                                }
                                {/*{*/}

                                {/*    item.completed == true && <Col xs={24} sm={24} md={10} lg={14} xl={13} ><Button className="booking-btnn" size="large" style={{ width: '100%' }}>*/}
                                {/*        {*/}

                                {/*            <Link*/}
                                {/*                to={{*/}
                                {/*                    pathname: `${ROUTES.REVIEW_INVOICE.replace(':id', item.bookingId)}`,*/}
                                {/*                }}*/}
                                {/*            >*/}
                                {/*                <ReceiptIcon fontSize="small" style={{ marginRight: '0.5rem' }} />*/}
                                {/*                REVIEW & SEND INVOICE*/}
                                {/*            </Link>*/}
                                {/*        }*/}


                                {/*    </Button></Col>*/}



                                {/*}*/}

                                {
                                    ((item.status == "Pending"
                                        || item.status == "Completed"
                                        || item.status == "In Progress"

                                    ) && (item.accepted == true))
                                    &&

                                    <Col xs={24} sm={24} md={8} lg={10} xl={11} >
                                        <Button className="booking-btn-user" size="large" style={{ width: '100%' }}
                                            onClick={() => {
                                                if (this.role == 'tech') {
                                                    Modal.info({
                                                        title: "Add service",
                                                        content: 'New services can only be added via the customer\'s account at this moment. Please have your customer click the same Add Service button in their account to add a new service into this booking.',
                                                        okText: "Okay",
                                                        centered: true,
                                                    });
                                                }
                                                else {
                                                    // this.setState({
                                                    //     showAddServiceModal: true,
                                                    //     techId: item.items[1]['techContact'],
                                                    //     techOrg: item.orgId,
                                                    //     bookingId: item.bookingId
                                                    // });
                                                    let techContact = item.items[0]['techContact'] ? item.items[0]['techContact'] : item.items[1]['techContact'];
                                                    this.props.history.push(ROUTES.ADD_NEW_SERVICE.replace(":id", techContact).replace(":booking", item.bookingId))
                                                }
                                            }}
                                        >
                                            <AddCircleOutlineIcon fontSize="small" style={{ marginRight: '0.5rem' }} />
                                            ADD A SERVICE
                                        </Button>
                                    </Col>
                                }

                            </Row>

                        </Col>
                    </Row>
                }>

                {item.items.map((value, index) => {

                    return (value.type == "service") ? <Row key={index} gutter={[16, 16]} style={{ alignItems: 'center', justify: "space-between" }}>

                        <Col xs={24} sm={24} md={18} lg={20} xl={20}>
                            <Row gutter={[16, 16]}>

                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    {/*<Link to={{*/}
                                    {/*    pathname: `${ROUTES.SERVICE_DETAILS}`,*/}
                                    {/*    state: {*/}
                                    {/*        booking: item,*/}
                                    {/*        service: value*/}
                                    {/*    }*/}
                                    {/*}}>*/}
                                    <Title level={5} style={{ textDecoration: 'none !important' }}>{value.issue.title}</Title>
                                    {/*</Link>*/}
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                            <Row gutter={[16, 16]}>
                                                <Col xs={4} sm={6} md={6} lg={4} xl={4}>

                                                    <img loading="lazy"  src={Calendar} />
                                                </Col>
                                                <Col xs={20} sm={18} md={16} lg={20} xl={20}>
                                                    <Row>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <Paragraph style={{
                                                                color: '#000000',
                                                                fontWeight: 500,
                                                                margin: 0
                                                            }}>
                                                                {moment(value.scheduledDateTime).format(DATE_TIME_FORMAT)}
                                                            </Paragraph>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <Paragraph style={{ color: '#949494' }}>
                                                                Scheduled Date/Time</Paragraph>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                            <Row gutter={[16, 16]}>
                                                <Col xs={4} sm={6} md={6} lg={4} xl={4}>

                                                    <img loading="lazy"  src={Process} />

                                                </Col>
                                                <Col xs={20} sm={18} md={16} lg={20} xl={20}>
                                                    <Row>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <Paragraph style={{
                                                                color: '#000000',
                                                                fontWeight: 500,
                                                                margin: 0
                                                            }}>
                                                                {toCustomStatus(value.status, item.status)}
                                                            </Paragraph>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <Paragraph style={{ color: '#949494' }}>
                                                                Status</Paragraph>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                            <Row gutter={[16, 16]}>
                                                <Col xs={4} sm={6} md={6} lg={4} xl={4}>

                                                    <img loading="lazy"  src={TypeImage} />

                                                </Col>
                                                <Col xs={20} sm={18} md={16} lg={20} xl={20}>
                                                    <Row>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <Paragraph style={{
                                                                color: '#000000',
                                                                fontWeight: 500,
                                                                margin: 0
                                                            }}>
                                                                {value.mode.replace("_", " ").toUpperCase()}
                                                            </Paragraph>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <Paragraph style={{ color: '#949494' }}>
                                                                Service Type</Paragraph>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        {/*{*/}
                                        {/*    value.user.profilePic != null &&*/}
                                        {/*    <Col xs={24} sm={8} md={8} lg={8} xl={8}>*/}
                                        {/*        <Row gutter={[16, 16]}>*/}
                                        {/*            <Col xs={4} sm={6} md={6} lg={4} xl={4}>*/}

                                        {/*                <Avatar size="large" src={value.user.profilePic}*/}
                                        {/*                    style={{ border: '1px solid #DCDCDC' }} />*/}

                                        {/*            </Col>*/}
                                        {/*            <Col xs={20} sm={18} md={16} lg={20} xl={20}>*/}
                                        {/*                <Row>*/}
                                        {/*                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>*/}
                                        {/*                        <Paragraph style={{*/}
                                        {/*                            color: '#000000',*/}
                                        {/*                            fontWeight: 500,*/}
                                        {/*                            margin: 0*/}
                                        {/*                        }}>*/}
                                        {/*                            {value.user.name}*/}
                                        {/*                        </Paragraph>*/}
                                        {/*                    </Col>*/}
                                        {/*                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>*/}
                                        {/*                        <Paragraph style={{ color: '#949494' }}>*/}
                                        {/*                            {value.user.type == 'tech' ? "Technician" : "Customer"}*/}
                                        {/*                        </Paragraph>*/}
                                        {/*                    </Col>*/}
                                        {/*                </Row>*/}
                                        {/*            </Col>*/}
                                        {/*        </Row>*/}
                                        {/*    </Col>*/}
                                        {/*}*/}


                                    </Row>
                                </Col>

                            </Row>
                        </Col>

                        <Col xs={24} sm={24} md={6} lg={4} xl={4} align="right">

                            <Button className="booking-btn"
                                size="large"
                                style={{ width: '100%' }}
                            >
                                <Link to={{
                                    pathname: `${ROUTES.SERVICE_DETAILS.replace(':service_id', value.scheduledServiceId).replace(":booking_id", item.bookingId)}`,
                                }}>
                                    <VisibilityIcon fontSize="small" style={{ marginRight: '0.5rem' }} />VIEW DETAILS
                                </Link>
                            </Button>

                        </Col>
                        {
                            item.items.length > 1 && (item.items.length - 1) != index &&
                            <Divider />
                        }

                    </Row> : <div key={value}></div>
                })}
            </Card>
        </div >

    }

    render() {
        const { showAddServiceModal, techId, techOrg } = this.state;
        return <div></div>
        return (
            <Fragment>
                <MetaTags>
                    <title >Manage Bookings | TecMe </title>
                    <meta name="description" content={META_DESCRIPTION} />
                    <meta name="robots" content="noindex, nofollow" />
                </MetaTags>
                <Content className="main">
                    <TechConnectModal />
                    <div className="page">
                        <div className="container">
                            {
                                this.props.match.params.id == undefined &&
                                <Row gutter={[15, 15]} justify={"center"}>
                                    <Col xs={0} sm={0} md={24} lg={24} xl={24}>

                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                            {/* <Title level={4} style={{ margin: 0 }}>My Services</Title> */}
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img loading="lazy"  src={Icon} style={{ width: '70px', marginRight: '1.5rem' }} />
                                                <div >
                                                    <Title className='remove-margin blue' level={3} >Bookings</Title>
                                                    <Paragraph className='remove-margin' style={{ fontSize: '18px' }} >Manage your bookings</Paragraph>
                                                </div>
                                            </div>
                                            {this.state.data.length != 0 &&
                                                <Input bordered={false} value={this.state.searchTerm} className="ant-chat-inpuut"
                                                    style={{ width: '245px', height: '45px', borderRadius: '0.5rem', marginRight: '0.5rem' }}

                                                    suffix={this.state.searchTerm.length == 0 ? <SearchIcon style={{ color: '#959595' }} /> :
                                                        <CloseIcon style={{ color: '#959595' }} onClick={() => {
                                                            this.setState({ searchTerm: "" })
                                                        }} />} placeholder="Search booking..."
                                                    onChange={(event) => {
                                                        this.setState({ searchTerm: event.target.value });
                                                        this.searchBooking();
                                                    }}
                                                />}
                                        </div>


                                    </Col>
                                    <Col xs={24} sm={24} md={0} lg={0} xl={0}>
                                        <Row gutter={[16, 16]} align='middle'>
                                            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                                                <Row>
                                                    <Col xs={18} sm={20} md={21} lg={22} xl={22}>
                                                        <Title className='remove-margin blue' level={3} >Bookings</Title>
                                                        <Paragraph className='remove-margin' style={{ fontSize: '18px' }} >Manage your bookings</Paragraph>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={6} xl={6} style={{ textAlign: 'left' }}>
                                                {this.state.data.length != 0 &&
                                                    <Input bordered={false} value={this.state.searchTerm} className="ant-chat-input"
                                                        style={{ width: '245px', height: '45px', borderRadius: '0.5rem', marginRight: '0.5rem' }}

                                                        suffix={this.state.searchTerm.length == 0 ? <SearchIcon style={{ color: '#959595' }} /> :
                                                            <CloseIcon style={{ color: '#959595' }} onClick={() => {
                                                                this.setState({ searchTerm: "" })
                                                            }} />} placeholder="Search booking..."
                                                        onChange={(event) => {
                                                            this.setState({ searchTerm: event.target.value });
                                                            this.searchBooking();
                                                        }}
                                                    />}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Divider />
                                    </Col>
                                </Row>
                            }


                            {this.state.dataLoading == true &&
                                <Row gutter={[16, 16]}>
                                    {
                                        [1, 2, 3, 4].map((item, index) => {
                                            return <Col key={index} xl={24} md={24} lg={24} sm={24} xs={24}>
                                                <Card style={{ width: "100%", marginBottom: '0.3rem', padding: 0, borderRadius: '0.3rem' }}>
                                                    <Skeleton loading={true} active avatar>
                                                        <List.Item.Meta
                                                            avatar={<Avatar src={"test"} />}
                                                            title={<a href={"test"}>{"test"}</a>}
                                                            description={"test"}
                                                        />

                                                    </Skeleton>
                                                </Card>
                                            </Col>
                                        })
                                    }
                                </Row>}



                            {this.state.searchTerm == 0 && this.state.dataLoading == false && <Tabs defaultActiveKey="0">
                                {this.state.bookingHeaders.map((val, index) => {
                                    return < TabPane key={index} tab={val.status + " (" + val.bookings.length + ")"} key={index}>


                                        {val.bookings.length != 0 && <p>{val.message}</p>}
                                        < Row gutter={[15, 15]} justify={"center"}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <Row gutter={[16, 16]}>
                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                                                        {
                                                            !(val.bookings.length == 0) && <div style={{ minHeight: '600px' }}>
                                                                {
                                                                    this.state.bookingHeaders[index].bookings.map((item, index) => {
                                                                        return this.bookingCell(item, index, val.status, val)
                                                                    })

                                                                }
                                                            </div>

                                                        }

                                                        {
                                                            (val.bookings.length == 0) ?
                                                                <div className="records-not-found" style={{ height: val.status == "Request" ? "400px" : "600px" }}>
                                                                <img loading="lazy"  src={NoBookingFound} className="mobile-no-data-image" />

                                                                <>
                                                                    <Title level={3} style={{ marginTop: '1rem' }}>{this.role == 'customer' ? "Book A Technician" : "Find New Customer's"}</Title>
                                                                    <Paragraph style={{ fontSize: '12px', color: '#909090' }}>{this.role == 'customer' ? "Search for local technicians that can repair your IT devices." : "Begin marketing your profile with the help of TecMe's marketing suite."}</Paragraph>
                                                                    {this.role == 'customer' ? <Button style={{ backgroundColor: '#1D3466', color: 'white', height: '40px', borderRadius: "0.3rem" }}
                                                                        onClick={() => {
                                                                            this.props.history.push('/');
                                                                        }}
                                                                    >Search Now</Button>
                                                                        : <a style={{ backgroundColor: '#1D3466', color: 'white', height: '40px', borderRadius: "0.3rem", display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100px', textDecoration: 'none' }} href="http://site.tecme.io/startup-guide">Market Now</a>
                                                                    }
                                                                </>
                                                            </div> : null
                                                        }

                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                    </TabPane>
                                })
                                }

                            </Tabs>}


                            {this.state.searchTerm != 0 &&
                                <Row gutter={[15, 15]} >
                                    {this.state.filteredData.length != 0 &&
                                        <p style={{ color: '727272', margin: '1rem 0rem 0rem 1rem' }}>{this.state.filteredData.length + " booking(s) found"}</p>}
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                {this.state.filteredData.map(item => {
                                                    return this.bookingCell(item)
                                                })}
                                                <Row gutter={[16, 16]}>
                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                        {this.state.filteredData.length == 0 && <div className="records-not-found" style={{ minHeight: '600px' }}>
                                                            <img loading="lazy"  src={NoBookingFound} className="mobile-no-data-image" />
                                                            <Title level={5}>No search found.</Title>
                                                        </div>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                            }
                        </div>
                    </div>
                </Content>
            </Fragment >
        )
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(ManageBooking);
