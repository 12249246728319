import React, { useEffect, useState } from 'react'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import '../assets/css/job-info.scss'
import { Col, Row, Space, Tag, Typography } from 'antd'
import { getSpecializationTitle } from '../../../../common/private/job-post/utils/helper'
import JobPostDetails from '../../../../common/private/job-post/components/job-post-details'
import { capitalizeFirstLetter } from '../../../../../../utils/helper'
import { useSelector } from 'react-redux'
import { PaperClipOutlined } from '@ant-design/icons'


const {Paragraph, Title} = Typography

const JobInfo = ({jobDetails}) => {
    const generalSkills = useSelector(state => state.generalSkillsGlobal)
    const tech = useSelector(state => state.tech)
    const [jobAttachments, setJobAttachments] = useState([])
    

    const fetchJobAttachments = () => {
        ApiBaseHelper.get({
            url: `job-post/media/${jobDetails?.id}`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setJobAttachments(res?.data?.data)
        }).catch((error) => {
            console.error(error)
        })       
    }

    useEffect(() => {
        fetchJobAttachments()
    }, [jobDetails])

    return (
        <div className='application-details-card'>
            <Row gutter={80} align={"top"}>
                <Col xs={24} sm={24} md={24} lg={17} xl={17} className='application-details-details'>
                    <Title level={4}>{capitalizeFirstLetter(jobDetails?.Title)}</Title>
                    <Paragraph style={{color:"#F04A22", fontWeight:500, marginBottom:30}}>{getSpecializationTitle(generalSkills, jobDetails?.Category)}</Paragraph>
                    <div className='application-details-description'>
                        <Title level={5}>About the job</Title>
                        {
                            jobDetails?.Description?.split('\n').map((line, index) => (
                                <Paragraph key={index}>
                                {line}
                                </Paragraph>
                            ))
                        }
                        {
                            jobAttachments?.data?.length > 0 &&
                            jobAttachments?.data?.map((item, index) => {
                                return(
                                    <div key={item?.id} className='mb-1'>
                                        <Space align='center' className="download-cv">
                                            <PaperClipOutlined style={{color:"rgba(0, 0, 0, 0.45)", position:"relative", top:2}}/>
                                            <a href={item?.Body}>{item?.Title}</a>
                                        </Space>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="job-skills">
                        <Title level={5}>Skills and Expertise</Title>
                        {
                            jobDetails?.Skills_Required?.split(', ').map((item, index) => {
                                return (
                                    <Tag className="custom-ant-tag" key={item} style={{marginBottom:10}}>{item}</Tag>
                                )   
                            }
                            )
                        }
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                    <div className="application-details-cta-ctn">
                        <JobPostDetails jobPost={jobDetails} />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default JobInfo