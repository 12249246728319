export const PrimaryButton = ({brandColor, buttonStyle, onClick, children}) => {
    return(
        <button 
            onClick={onClick}
            style={{ 
                backgroundColor: brandColor, 
                border:'none', 
                color:"#FFF" ,
                height: '40px',
                borderRadius: 2,
                ...buttonStyle
            }}
        >
            {children}
        </button>
    )
}

export const SecondaryButton = ({buttonStyle, onClick, children}) => {
    return(
        <button 
            style={{ 
                backgroundColor: "transparent", 
                borderRadius: 2,
                border: "1px solid #D9D9D9",
                height: '40px',
                boxShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.02)",
                ...buttonStyle
            }}
            onClick={onClick}
        >
            {children}
        </button>
    )
}