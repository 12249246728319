import { CheckCircleOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, Space, Typography, Upload, message, notification } from 'antd'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { SECONDARY_COLOR } from '../../../../../utils/constants'
import AIButton from '../../../../components/ai-button'
import { generateAiResponse } from '../../../../../utils/openai-helper'
import { getSpecializationTitle } from '../../../common/private/job-post/utils/helper'
import { useSelector } from 'react-redux'

const { Title, Paragraph } = Typography
const { TextArea } = Input

const JobDescription = ({handleNextClick, jobPostingData, setJobPostingData}) => {  


    return (
        <div className='job-description-ctn'>
            <div className="job-description-content">
                <div className="job-description-heading">
                    <Space>
                        <CheckCircleOutlined style={{color:"#F04A22", fontSize:22}} />
                        <Title level={4} style={{marginBottom:0, fontSize:20, color:"#000"}}>Now let’s describe your project</Title>
                    </Space>
                    <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", fontSize:14, marginTop:17}}>Provide a clear and detailed description of the job to attract the right IT professionals. Include specific responsibilities, required skills, and any additional information that will help applicants understand the role and requirements.</Paragraph>
                </div>
                <JobDescriptionForm  jobPostingData={jobPostingData} setJobPostingData={setJobPostingData}/>
                <div className="job-post-next-btn-ctn">
                    <Button type='primary' className='job-post-next-btn' onClick={handleNextClick} disabled={!jobPostingData?.job_description}>Next</Button>
                </div>
            </div>
        </div>
    )
}


export const JobDescriptionForm = ({ jobPostingData, setJobPostingData}) => {
    const user = useSelector(state => state.user)
    const generalSkills = useSelector(state => state.generalSkillsGlobal)
    const [error, setError] = useState("")
    const [loadingGeneratedDescription, setLoadingGeneratedDescription] = useState(false)
    // const [generatedDescription, setGeneratedDescription] = useState("")
   
    const handleJobDescriptionChange = (e) => {
        setJobPostingData({...jobPostingData, job_description: e.target.value})
    }
   
    const [fileList, setFileList] = useState([...jobPostingData.job_files]);

    const fetchGeneratedDescription = async () => {
        //  const prompt = `In less than 5000 words, generate job description for ${jobPostingData?.job_title} in the ${getSpecializationTitle(generalSkills, jobPostingData?.job_category)} specialization with the following skills set ${jobPostingData?.job_skills.join(", ")}. Considering job is for IT professionals within USA. Don't include job title, locations as headings. Only description is needed`

         const prompt = `Provide a job description under 3000 words for ${jobPostingData?.job_title} in the U.S. The skills required are ${jobPostingData?.job_skills.join(", ")}. Exclude job title and location headings; only the description is needed.`

        setLoadingGeneratedDescription(true)
        const response = await generateAiResponse(prompt, user?.token)
        setLoadingGeneratedDescription(false)
        if(response){
            setJobPostingData({...jobPostingData, job_description: response?.content})
        }else{
            notification.error({
                message: "TecMe",
                description: "We are unable to generate a response. Please try again."
            })
        }
    }


    const handleBeforeUpload = (file) => {
      // Define the allowed file types here
      const allowedTypes = [  
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/webp",
        "image/bmp",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.oasis.opendocument.spreadsheet",
        "application/vnd.ms-powerpoint",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
    ];
  
      if (allowedTypes.includes(file.type)) {
        setFileList([...fileList, file]);
        return true;
      }
  
      message.error('Unsupported file type. Please upload JPEG/PNG, Word, Excel, PDF, or PPT files.');
      return false;
    };

    // handle file remove
    const handleFileRemove = (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    };

    useEffect(() => {
        setJobPostingData({...jobPostingData, job_files: fileList})
    }, [fileList])

    // useEffect(() => {
    //     if(jobPostingData?.job_description?.length < 25){
    //         setError("It should be at least 25 characters or more.")
    //     }else{
    //         setError("")
    //     }
    // },[jobPostingData?.description])

    return(
        <>
            <div className="job-description-form">
                <Form>
                    <Space align='center' style={{marginBottom:15}}>
                        <Paragraph style={{fontWeight:500, marginBottom:0}}>Write a description for your job post:</Paragraph>
                        <AIButton 
                            onClick={() => fetchGeneratedDescription()}
                            loading={loadingGeneratedDescription}
                        > 
                            Generate Description
                        </AIButton>
                    </Space>
                    <Form.Item
                    >
                        <TextArea  
                            placeholder="Type your description here" 
                            maxLength={5000}
                            showCount
                            rows={5}
                            className='job-description-textarea'
                            value={jobPostingData.job_description}
                            onChange={handleJobDescriptionChange}
                        />
                    </Form.Item>
                    {/* {
                        error &&
                        <Paragraph style={{color:SECONDARY_COLOR, fontSize:14}}>{error}</Paragraph>
                    } */}
                    <Form.Item className="upload-form-item">
                        <Upload  
                            fileList={jobPostingData.job_files} 
                            beforeUpload={handleBeforeUpload}
                            onRemove={handleFileRemove}
                            className='document-upload'
                        >
                            <Button 
                                icon={<UploadOutlined />}
                                disabled={fileList?.length >= 10}
                            >
                                Upload
                            </Button>
                            {
                                fileList?.length >= 10 &&
                                <Paragraph style={{fontSize:14, marginTop:12, color:SECONDARY_COLOR}}>Maximum of 10 files allowed</Paragraph>
                            }
                            <Paragraph  style={{color:"rgba(0, 0, 0, 0.65)", fontSize:14, marginTop:8, marginBottom:0}}>Max file size: 100MB</Paragraph>
                        </Upload>
                    </Form.Item>
                </Form>
            </div>
            <div className="job-description-examples">
                <Paragraph style={{fontSize:14, fontWeight:500}}>Tips:</Paragraph>
                <ol>
                    <li><strong>Clear Expectations:</strong> Clearly outline tasks, deliverables, and expectations to help IT professionals understand the job's scope and requirements.</li>
                    <li><strong>Required Skills:</strong> List specific skills and qualifications needed, enabling applicants to assess their fit for the role.</li>
                    <li><strong>Effective Communication:</strong> Highlight the importance of good communication, including regular check-ins, team collaboration, or preferred communication channels.</li>
                    <li><strong>Work Style and Environment:</strong> Describe your team's work style and environment, such as valuing autonomy or emphasizing collaborative teamwork. This helps applicants determine if their work style aligns with your expectations.</li>
                </ol>
            </div>
        </>
    )
}

export default JobDescription