import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { updateInAppNotifications} from "../../../../redux/reducers/app"
import { ApiBaseHelper } from "../../../../utils/api-base-helper"
import moment from 'moment';


const useNotification = () => {
    const customer = useSelector(state => state.user)
    const tech = useSelector(state => state.tech)
    const [loadingNotifications, setLoadingNotifications] = useState(false)
    const [notificationData, setNotificationData] = useState([])
    const [markingAll, setMarkingAll] = useState(false)


    const dispatch = useDispatch()

    const user = customer ? customer : tech

    const fetchNotifications = () => {
        setLoadingNotifications(true)
        ApiBaseHelper.get({
            url: `notification/in-app`, headers: {
                Authorization: 'Bearer ' + user?.token
            }
        }).then((res) => {
            setNotificationData(res.data.data)
            setLoadingNotifications(false)
        }).catch((error) => {
            console.error(error)
            setLoadingNotifications(false)
        })       
    }

    let numOfUnreadNotifications =  notificationData?.filter((ele) => ele.Read === "0")?.length

    const sortedNotificationData =  [...notificationData]
    sortedNotificationData?.sort((a, b) => moment(b.Timestamp).local().diff(moment(a.Timestamp).local()))

    const handleReadNotification = (id) => {
        ApiBaseHelper.put({
            url: `admin/notification/${id}`,
            headers: {
                Authorization: `Bearer ${user?.token}`
            },
            payload: {
                "Read" : 1
            }
        }).then((res) => {
           console.log(res.data)
            if(res.data){
                fetchNotifications()
            }
        }).catch((error) => {
            console.error(error)
        })
    }

    const handleReadAllNotifications = () => {
        setMarkingAll(true)
        ApiBaseHelper.put({
            url: `notification/in-app/read`,
            headers: {
                Authorization: `Bearer ${user?.token}`
            },
        }).then((res) => {
           console.log(res.data)
           if(res.data){
            fetchNotifications()
            setMarkingAll(false)
           }
        }).catch((error) => {
            console.error(error)
            setMarkingAll(false)
        })
    }

   

    useEffect(() => {
        fetchNotifications()
    }, [])

    useEffect(() => {
        dispatch(updateInAppNotifications({
            unReadNotifications: numOfUnreadNotifications,
            notificationData: sortedNotificationData
        }))
    }, [notificationData])


    return {fetchNotifications, handleReadNotification, handleReadAllNotifications,  loadingNotifications, numOfUnreadNotifications}

}

export default useNotification