import algoliasearch from "algoliasearch";
import { getAlgoliaIndex } from "../views/pages/common/public/instant-search/utils/helper";


const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APPLICATION_ID, process.env.REACT_APP_ALGOLIA_WRITE_API_KEY)
export const algoliaIndex = client.initIndex(getAlgoliaIndex())


export class AlgoliaHelper {
    static async getObjects(ids) { // getObjects(["HIPAA_ID"])
       return algoliaIndex.getObjects(ids)
    }

    static async updateObject(data) {
        /*
        AlgoliaHelper.update()
        {
            objectID: TECHNICIAN_HIPAA_CONTACT,
            active: true/false
        }
         */
        return algoliaIndex.partialUpdateObject(data)
    }

}

