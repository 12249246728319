import { Col, Row, Space, Typography } from 'antd'
import React from 'react'


const {Title} = Typography

const PageTitle = ({title, search, extra}) => {


    return(
        <Row className={`page-title-row mb-3`} gutter={[15, 15]} justify='space-between' align='middle'>
            <Col>
                <Space align='middle' size={[20, 10]} wrap>
                    <Title level={3} style={{margin:0}}>{title}</Title>
                    {
                        extra && 
                        <div>
                            {extra}
                        </div>
                    }
                </Space>
            </Col>
            {
                search &&
                <Col className='page-search-col'>
                    {search}
                </Col>
            }
        </Row>
    )
}

export default PageTitle