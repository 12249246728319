import React, { Fragment } from "react";
import {  PageHeader } from "antd";
import Breadcrumb from "antd/lib/breadcrumb";
import { Link } from "react-router-dom";
import { isDesktop } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import ROUTES from "../../utils/routes";
import { capitalizeFirstLetter } from "../../utils/helper";
import { Tooltip } from "@mui/material";

function itemRender(route, params, routes, paths) {
  const last = routes.indexOf(route) === routes.length - 1;
  if(!route.breadcrumbName){
    return
  }
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`${route.path}`}>{route.breadcrumbName}</Link>
  );
}

const PageHeaderWrapper = (props) => {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.user);
  const tech = useSelector((state) => state.tech);
  const user = customer ? customer : tech;

  return (
    <PageHeader
      className={`site-page-header ${props.className ?? ""}`}
      // style={{
      //   marginTop: user != null && user.email_verification_banner == "false" ? 30 : 0
      // }}
      title={props.title}
      breadcrumb={
        <Breadcrumb routes={[  
          {
            path: user ? ROUTES.DASHBOARD : ROUTES.ADMIN_USERS,
            breadcrumbName: (customer && customer?.organization?.Name) ? 
            <Tooltip title={`You are logged into ${capitalizeFirstLetter(customer?.organization?.Name)} team`}>
              {capitalizeFirstLetter(customer?.organization?.Name)}
            </Tooltip> :  (customer && !customer?.organization?.Name) ? "TecMe" :
            'TecMe',
          }, 
          ...props.routes]} 
          itemRender={itemRender} 
        />
      }
      subTitle={isDesktop ? props.subTitle : null}
      extra={props.extra}
    />
  );
};

export default PageHeaderWrapper;
