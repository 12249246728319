import React, { useEffect, useState } from 'react'
import { Typography } from 'antd'
import { TestimonialForm } from '../../create-itc/components/testimonials'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import TestimonialList from '../../create-itc/components/testimonial-list'

const { Title, Paragraph } = Typography

const ProfileTestimonials = ({itcId, token}) => {
    const [showAddedTestimonial, setShowAddedTestimonial] = useState(false)
    const [testimonials, setTestimonials] = useState([])
    const [loadingTestimonials, setLoadingTestimonials] = useState(false)
    const [editTestimonialData, setEditTestimonialData] = useState(null)

    const fetchTestimonials = () => {
        setLoadingTestimonials(true)
        ApiBaseHelper.get({
            url: `itc/testimonial/all?itc=${itcId}`, 
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setTestimonials(res?.data?.data)
            setLoadingTestimonials(false)
        }).catch((error) => {
            console.error(error)
            setLoadingTestimonials(false)
        })       
    }


    useEffect(() => {
        fetchTestimonials()
    }, [])

    useEffect(() => {
        fetchTestimonials()
    }, [showAddedTestimonial])

    if(showAddedTestimonial) {
        return(
            <div className="profile-testimonial-form-ctn">
               <TestimonialForm 
                    setShowAddedTestimonial={setShowAddedTestimonial}
                    isFromItcProfile={true}
                    fetchTestimonials={fetchTestimonials}
                    editTestimonialData={editTestimonialData}
                    setEditTestimonialData={setEditTestimonialData}
               />
            </div>
        )
    }

    return (
        <>
            <Title level={4} className="itc-form-title">Testimonials</Title>
            <TestimonialList
                testimonials={testimonials} 
                setShowAddedTestimonial={setShowAddedTestimonial}
                fetchTestimonials={fetchTestimonials}
                isFromItcProfile={true}
                loadingTestimonials={loadingTestimonials}
                setEditTestimonialData={setEditTestimonialData}
            />
        </>
    )
}

export default ProfileTestimonials