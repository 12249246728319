import { CreditCardOutlined, UploadOutlined } from '@ant-design/icons'
import { Avatar, Button, Checkbox, Col, Form, Input, Row, Select, Space, Typography, Upload, notification } from 'antd'
import React from 'react'
import InputMask from 'react-input-mask';
import { useEffect } from 'react';
import { ApiBaseHelper } from '../../../../../utils/api-base-helper';
import { useDispatch } from 'react-redux';
import { updateOrganizations, updateUser } from '../../../../../redux/reducers/app';
import { useState } from 'react';
import { STATES } from '../../../../../utils/us-states-city';
import { getCreditCardInfo } from '../../../../../utils/helper';

const {Title, Paragraph} = Typography

const { useForm } = Form;

const PaymentDetails = ({handleNextClick, setProgressIndex, setPaymentDetails, paymentDetails, user}) => {
    const [form] = useForm();
    const [loading, setLoading] = useState(false)
    const [expiry, setExpiry] = useState('')
    const [expiryError, setExpiryError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [cardType, setCardType] = useState(null)
    const dispatch = useDispatch();

    const [fieldsValue, setFieldsValue] = useState({cardName:paymentDetails?.cardName || "", cardNumber:paymentDetails?.cardNumber || "", cvc: paymentDetails?.cvc || "", street:paymentDetails?.street || "", building:paymentDetails?.building || "", city: paymentDetails?.city || "", state: paymentDetails?.state || "", zipCode: paymentDetails?.zipCode || ""})


    const checkPaymentValues = fieldsValue?.cardName || fieldsValue?.cardNumber || fieldsValue?.cvc || expiry
    const checkBillingValues = fieldsValue?.street || fieldsValue?.building || fieldsValue?.city || fieldsValue?.state || fieldsValue?.zipCode
    const checkAllValues = checkPaymentValues || checkBillingValues

    const isPaymentFieldRequired = checkPaymentValues || !checkAllValues
    const isBillingFieldRequired = checkBillingValues || !checkAllValues

    const handlePaymentDetailsSubmit = async (values) => {
        console.log(values)
        setLoading(true)
        const data = new FormData();
        data.append("City", values.city === null ? "" : values.city)
        data.append("State", values.state === null ? "" : values.state)
        data.append("Address", values.street === null ? "" : values.street)
        data.append("Zip_Code", values.zipCode === null ? "" : values.zipCode)
        data.append("Address_2", values.building === null ? "" : values.building)

        if(!!checkBillingValues){
            ApiBaseHelper.put({
                url: `user-management/organization/${user?.orgId}`,	
                headers: {
                    Authorization: 'Bearer ' + user['token'],
                },
                payload: data
            }).then((res) => {
                if(res){
                    dispatch(updateUser({
                        ...user,
                        new_signup: false,
                        organization:{
                            ...user.organization,
                            Name: res?.data?.data?.Name,
                            Website: res?.data?.data?.Website,
                            Description: res?.data?.data?.Description,
                            Industry: res?.data?.data?.Industry,
                            Company_Size: res?.data?.data?.Company_Size,
                            Address: res?.data?.data?.Address,
                            City: res?.data?.data?.City,
                            State: res?.data?.data?.State,
                            Zip_Code: res?.data?.data?.Zip_Code,
                            Address_2: res?.data?.data?.Address_2, 
                            Logo: res?.data?.data?.Logo,   
                        }
                    }))
    
                    ApiBaseHelper.get({
                        url: 'user-management/organizations',
                        headers: {
                            Authorization: 'Bearer ' + user?.token,
                        },
                    }).then((res) => {
                        dispatch(updateOrganizations(res?.data?.data))
                    })
                }
            }).then(() => {
                setLoading(false)
                
    
            }).catch((e) => {
                console.log(e)
                setLoading(false)
                notification.error({
                    message: 'TecMe',
                    description: 'Company Details Update Failed',
                });
            })

            if(values.addAddress){
                ApiBaseHelper.post({
                    url: `home`,
                    payload:{
                        city: values.city,
                        address: values.street,
                        state: values.state,
                        zipCode: values.zipCode,
                        type: 'Office',
                    },
                    headers: {
                        Authorization: 'Bearer ' + user.token
                    }
                }).then((res) => {
                    console.log(res)
                }).catch((e) => {
                    console.log(e)
                })
            }
        }

        if (expiryError == true) {
            return
        }
        if(!!checkPaymentValues){
            let expiry = values.expiry?.split("/")
            ApiBaseHelper.post({
                url: `stripe/payment-method`,	
                headers: {
                    Authorization: 'Bearer ' + user['token'],
                },
                payload: {
                    Card_Holder_Name:values.cardName,
                    number: values.cardNumber.replaceAll(" ", ""),
                    exp_month: expiry[0],
                    exp_year: expiry[1],
                    cvc: values.cvc,
                } 
            }).then((res) => {
                console.log("success")
    
            }).catch((e) => {
                console.log(e)
            })
        }


        handleNextClick()
        setProgressIndex(2)
        setPaymentDetails(values)
    }

    useEffect(() => {
        form.setFieldsValue({
            state: paymentDetails?.state,
            city: paymentDetails?.city,
            street: paymentDetails?.street,
            building: paymentDetails?.building,
            zipCode: paymentDetails?.zipCode,
            cardName: paymentDetails?.cardName,
            cardNumber: paymentDetails?.cardNumber,
            cvc: paymentDetails?.cvc,
            expiry: paymentDetails?.expiry,
            addAddress: paymentDetails?.addAddress
        })
    }, [])


    return (
        <div className='payment-details-section'>
            <div className="payment-details-heading">
                <Title level={5} className='add-payment-heading'>Add your payment & billing details</Title>
                <Paragraph className='add-payment-text'>Don’t worry. You will not be charged anything by adding in your payment method, this is just to get your account set up.</Paragraph>
            </div>
            <div className="payment-details-form-ctn">
                <Form 
                    onFinish={handlePaymentDetailsSubmit}
                    form={form}
                    layout='vertical'
                    className="payment-details-form">
                        <div className="payment-billing-details">
                            <Title level={4} className='payment-method-heading'>Payment Method</Title>
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Form.Item name="cardName" label="Name on card" className='payment-details-form-item'
                                    rules={[{ required:!!isPaymentFieldRequired , message: 'Please input your name on card' }]}
                                    >
                                        <Input 
                                            type="text" 
                                            placeholder='Olivia Rhye'
                                            className="payment-billing-input"
                                            onChange={(e) => {
                                                setFieldsValue({
                                                    ...fieldsValue,
                                                    cardName: e.target.value
                                                })
                                            }}
                                        />
                                    </Form.Item>  
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <Form.Item
                                        label="Expiry" 
                                        name="expiry"
                                        className='payment-details-form-item'
                                        style={{marginBottom: 0}}
                                        rules={[{ required: !!isPaymentFieldRequired, message: 'Please input your expiry date' }]}
                                    >
                                        <InputMask mask="\ 99/99" maskChar=" "
                                            onChange={(e) => {
                                                if (e.target.value.replace('/', '').trim().length == 0) {
                                                    return
                                                }

                                                if (e.target.value.search("_") == -1 && e.target.value != "") {
                                                    setErrorMessage("")
                                                    setExpiryError(false)
                                                    let expiry = e.target.value.split("/")
                                                    let month = new Date().getMonth()
                                                    let year = new Date().getFullYear()
                                                    year = parseInt(year.toString().substr(-2));
                                                    let current_month = parseInt(expiry[0])
                                                    let current_year = parseInt(expiry[1])
                                                    if (current_month > 0 && current_month <= 12 && current_year >= year && current_year != year) {
                                                        setErrorMessage("")
                                                        setExpiryError(false)
                                                        setExpiry((current_month < 10 ? "0" + String(current_month) + String(current_year) : String(current_month) + String(current_year)))
                                                    }
                                                    else if (current_month >= month + 1 && current_month <= 12) {
                                                        if (current_year >= year) {
                                                            setErrorMessage("")
                                                            setExpiryError(false)
                                                            setExpiry((current_month < 10 ? "0" + String(current_month) + String(current_year) : String(current_month) + String(current_year)))
                                                        }
                                                        else {
                                                            setErrorMessage( "Expiry year cannot be in the past")
                                                            setExpiryError(true)
                                                            setExpiry((current_month < 10 ? "0" + String(current_month) + String(current_year) : String(current_month) + String(current_year)))

                                                        }
                                                    }
                                                    else if (current_year < year) {
                                                        setErrorMessage("Expiry year cannot be in the past")
                                                        setExpiryError(true)
                                                        setExpiry((current_month < 10 ? "0" + String(current_month) + String(current_year) : String(current_month) + String(current_year)))
                                                    }
                                                    else if (current_year == year && current_month < month + 1) {
                                                        setErrorMessage("Expiry month cannot be in the past")
                                                        setExpiryError(true)
                                                        setExpiry((current_month < 10 ? "0" + String(current_month) + String(current_year) : String(current_month) + String(current_year)))
                                                    }
                                                    else {
                                                        setErrorMessage("Expiry month must be between 01 and 12")
                                                        setExpiryError(true)
                                                        setExpiry((current_month < 10 ? "0" + String(current_month) + String(current_year) : String(current_month) + String(current_year)))
                                                    }
                                                }
                                                else {
                                                    setErrorMessage("")
                                                    setExpiryError(true)
                                                }

                                            }}
                                        >{(inputProps) => <Input  className="payment-billing-input" size='large' placeholder='mm / yy' />}</InputMask>
                                    </Form.Item>
                                    {expiryError && <p style={{ margin: 0, fontSize:14, color: '#F04A22', padding: 0 }}>{errorMessage}</p>}
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Form.Item
                                        name="cardNumber" 
                                        label="Card Number" 
                                        className='payment-details-form-item'
                                        rules={[
                                            {
                                                pattern: new RegExp(/^\d+$/),
                                                message: 'Input must be numeric only!',
                                            },
                                            {
                                                required: !!isPaymentFieldRequired,
                                                message: 'Please input your card number',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (value.length < 15 && getCreditCardInfo(value)?.name.toLowerCase() === "american express" ) {
                                                        return Promise.reject(new Error('Card number should be 15 digits'));
                                                    }else if(value.length < 16  &&  getCreditCardInfo(value)?.name.toLowerCase() !== "american express"){
                                                        return Promise.reject(new Error('Card number should be 16 digits'));
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),

                                        ]}
                                        >
                                        <Input 
                                            type="text" 
                                            placeholder='1234 1234 1234 1234'
                                            className="payment-billing-input"
                                            maxLength={getCreditCardInfo(fieldsValue.cardNumber)?.name.toLowerCase() === "american express" ? 15 : 16}
                                            prefix={cardType !== null ? <img src={cardType?.logo}  width={20}/> : <CreditCardOutlined />}
                                            onChange={(e) => {
                                                setCardType(getCreditCardInfo(e.target.value))
                                                // Remove all non-digit characters from the card number
                                                const cleanedCardNumber = e.target.value.replace(/\D/g, '');

                                                // Remove spaces from the input
                                                const unspacedCardNumber = cleanedCardNumber.replace(/\s/g, '');

                                                // Add spaces after every four digits
                                                let spacedCardNumber = '';
                                                for (let i = 0; i < unspacedCardNumber.length; i += 4) {
                                                spacedCardNumber += unspacedCardNumber.slice(i, i + 4) + ' ';
                                                }

                                                setFieldsValue({
                                                    ...fieldsValue,
                                                    cardNumber: e.target.value
                                                })
                                                // form.setFieldsValue({
                                                //     cardNumber: spacedCardNumber
                                                // })
                                            }}
                                        />
                                    </Form.Item>  
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <Form.Item 
                                        label="CVC" 
                                        name="cvc"
                                        className='payment-details-form-item'
                                        rules={[
                                            {
                                                pattern: new RegExp(/^\d+$/),
                                                message: 'Input must be numeric only!',
                                            },
                                            {
                                                required: !!isPaymentFieldRequired,
                                                message: 'Please input your CVC',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (value.length < 4 && getCreditCardInfo(getFieldValue('cardNumber'))?.name.toLowerCase() === "american express" ) {
                                                        return Promise.reject(new Error('CVC should be 4 digits'));
                                                    }else if(value.length < 3  &&  getCreditCardInfo(value)?.name.toLowerCase() !== "american express"){
                                                        return Promise.reject(new Error('CVC should be 3 digits'));
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                        >
                                        <Input 
                                            type="text" 
                                            placeholder= {getCreditCardInfo(fieldsValue.cardNumber)?.name.toLowerCase() === "american express" ? "••••" : "•••"}
                                            className="payment-billing-input"
                                            onChange={(e) => {
                                                setFieldsValue({
                                                    ...fieldsValue,
                                                   cvc: e.target.value
                                                })
                                            }}
                                            maxLength={getCreditCardInfo(fieldsValue.cardNumber)?.name.toLowerCase() === "american express" ? 4 : 3}
                                        />
                                    </Form.Item>  
                                </Col>
                            </Row>
                        </div>
                        <div className="payment-billing-details">
                            <Title level={4} className='payment-method-heading'>Billing Details</Title>
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item 
                                        className='payment-details-form-item'
                                        label="Street Address" 
                                        name="street"
                                        rules={[{required: !!isBillingFieldRequired, message: 'Please input your street address',}]}
                                    >
						                <Input  type="text"  placeholder='123 Main Street' className="payment-billing-input" 
                                        onChange={(e) => {
                                            setFieldsValue({
                                                ...fieldsValue,
                                                street: e.target.value
                                            })
                                        }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Form.Item label="Building, floor, suite #" className='payment-details-form-item' name="building"
                                    >
                                        <Input 
                                            type="text" 
                                            placeholder='36/3/41'
                                            className="payment-billing-input"
                                            onChange={(e) => {
                                                setFieldsValue({
                                                    ...fieldsValue,
                                                    building: e.target.value
                                                })
                                            }}
                                        />
                                    </Form.Item>  
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Form.Item name="city" label="City" className='payment-details-form-item'
                                    rules={[{required: !!isBillingFieldRequired, message: 'Please input your city',}]}  
                                    >
                                        <Input 
                                            type="text" 
                                            placeholder='St. Louis'
                                            className="payment-billing-input"
                                            onChange={(e) => {
                                                setFieldsValue({
                                                    ...fieldsValue,
                                                    city: e.target.value
                                                })
                                            }}
                                        />
                                    </Form.Item>  
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Form.Item 
                                        label="State"
                                        name="state"
                                        className='payment-details-form-item'
                                        rules={[{required: !!isBillingFieldRequired, message: 'Please select your state',}]}
                                        >
                                        <Select 
                                            className='company-details-form-select'
                                            placeholder="Select state"
                                            onChange={(value) => {
                                                setFieldsValue({
                                                    ...fieldsValue,
                                                    state: value
                                                })
                                            }}
                                            >
                                            {
                                                STATES?.map((state) => (
                                                    <Select.Option value={state?.Code} key={state?.Code}>
                                                        {`(${state?.Code}) ${state?.State}`}
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Form.Item 
                                        name="zipCode" 
                                        label="ZIP code" 
                                        className='payment-details-form-item' 
                                        rules={[
                                                    {
                                                        pattern: new RegExp(/^(\d{5})?$/),
                                                        message: "5 digits only"
                                                    },
                                                    {
                                                        required: !!isBillingFieldRequired,
                                                        message: 'Please input your ZIP code',
                                                    },
                                                ]}
                                            >
                                        <Input 
                                            type="text" 
                                            placeholder='63101'
                                            className="payment-billing-input"
                                            maxLength={5}
                                            onChange={(e) => {
                                                setFieldsValue({
                                                    ...fieldsValue,
                                                    zipCode: e.target.value
                                                })
                                            }}
                                        />
                                    </Form.Item>  
                                </Col>
                            </Row>
                        </div>
                        <Form.Item 
                            className='payment-details-form-item'
                            name="addAddress"
                            valuePropName="checked"
                        >
                            <Checkbox className='payment-details-checkbox'>I would you like to add this billing address as a location for on-site support as well</Checkbox>
                        </Form.Item>
                        <Form.Item className='payment-details-form-item'>
                            <Button className='onboarding-next-btn' type='primary' htmlType='submit' >Next</Button>
                        </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default PaymentDetails