import React, { useEffect, useState } from 'react'
import SuccessImg from '../assets/images/success-img.png'
import { Button, Result, Space, Typography } from 'antd'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import PageFallback from '../../../../../components/page-fallback'
import { useSelector } from 'react-redux'
import ROUTES from '../../../../../../utils/routes'
import { useHistory } from 'react-router-dom'
import { FirebaseHelper, FirestoreHelper } from '../../../../../../utils/firebase-helper'
import { addSubdomainToBaseUrl, createGroupChat, encryptString, ucwords } from '../../../../../../utils/helper'
import { RtmRepository } from '../../../../common/private/real-time-messaging/repository/rtm-repository'
import { SAMPLE_HIPAA_ID, TecMeLogoURL } from '../../../../../../utils/constants'
import { generateAiResponse } from '../../../../../../utils/openai-helper'

const { Title, Paragraph } = Typography

const ItcSuccess = ({companyDetails, itcId}) => {
    const tech = useSelector(state => state.tech)
    const history = useHistory()
    const [error, setError] = useState(false)
    const [creatingGroupChat, setCreatingGroupChat] = useState(false)
    const [loadingGeneratedContent, setLoadingGeneratedContent] = useState(false)
    const [heroHeader, setHeroHeader] = useState("")
    const [tagline, setTagline] = useState("")
    const [processList, setProcessList] = useState([])

    const rtmRepository = new RtmRepository(tech?.token);
    const techName = `${tech?.firstname} ${tech?.lastname}`

    // const createGroupChat = async () => {  
    //     let group = ucwords(companyDetails?.name);
    //     setCreatingGroupChat(true)
    //     await FirebaseHelper.login();
    //     rtmRepository.createGroupConversation([tech?.id])
    //     .then(async (response) => {
    //         let conversationId = response.data.data.id;
    //         await FirestoreHelper.createUser({
    //             hipaa_id: tech?.id,
    //             display_name: techName,
    //             profile_picture: tech?.imageUrl,
    //             role: "Technician",
    //         });
    //         // await FirestoreHelper.createUser({
    //         //     hipaa_id: SAMPLE_HIPAA_ID,
    //         //     display_name: `TecMe`,
    //         //     profile_picture: TecMeLogoURL,
    //         // });

    //         let message = `${group} ITSC group chat created by ${techName} to manage company conversations`;

    //         await FirestoreHelper.createConversation({
    //             conversation_id: conversationId,
    //             message: encryptString(message),
    //             participants: [tech?.id],
    //             user_id: tech?.id,
    //             group,
    //         });

    //         await FirestoreHelper.sendMessage({
    //             conversation_id: conversationId,
    //             message: encryptString(message),
    //             user_id: tech?.id,
    //         });

    //         setCreatingGroupChat(false)
    //     });
    // }

    function correctJsonFormat(generatedString) {
        // Remove any leading/trailing non-JSON characters (if necessary)
        let trimmedString = generatedString.trim().replace(/^['"]+|['"]+$/g, '');
    
        // Correctly quote property names: look for patterns like propertyName: and replace with "propertyName":
        let correctedString = trimmedString.replace(/(['"])?([a-zA-Z0-9_]+)(['"])?:/g, '"$2":');
    
        // Replace single quotes with double quotes around values, trying to avoid affecting single quotes within the data
        correctedString = correctedString.replace(/: '([^']*)'/g, ': "$1"');
    
        // Attempt to parse the corrected string as JSON
        try {
            return JSON.parse(correctedString);
        } catch (error) {
            console.error("Failed to parse corrected string as JSON:", error, correctedString);
            return null; // or return the original string, or handle the error as appropriate
        }
    }

    // Generate landing page contents with AI
    const handleGenerateContentWithAI = async () => {   
      
        const prompt = `Given this IT service company website for the company with the name: ${companyDetails?.name} and with the following description: ${companyDetails?.description}, provide a summary object including an hero section title, a tagline, and 4 steps to book service, let the steps each have an heading (without mentioning mentioning and itemizing "step") then the body (must be less than 15 words). Format the response like this: { heroHeader: '', tagline: '', steps: [] }`

        setLoadingGeneratedContent(true)
        const response = (companyDetails?.name && companyDetails?.description) && await generateAiResponse(prompt, tech?.token)

        if(response){
            ApiBaseHelper.put({
                url: `itc/${itcId}`,	
                headers: {
                    Authorization: 'Bearer ' + tech['token'],
                },
                payload: {
                    Active:true,
                    Hero_Header: correctJsonFormat(response?.content)?.heroHeader,
                    Tagline: correctJsonFormat(response?.content)?.tagline,
                    Process_List: JSON.stringify(correctJsonFormat(response?.content)?.steps)
                }
            }).then((res) => {
                setLoadingGeneratedContent(false)
            }).catch((err) => {
                setLoadingGeneratedContent(false)
                console.log(err)
            })
        }else{
           console.log("An error occured")
           setLoadingGeneratedContent(false)
        }
    }


    useEffect(() => {
       window.scrollTo(0, 0)
    }, [])

    useEffect(async() => {
        if(companyDetails?.name){
            handleGenerateContentWithAI()
            try{
                setCreatingGroupChat(true)
                await createGroupChat(companyDetails?.name, tech); // Await the group chat creation   
                setCreatingGroupChat(false)
            }catch(error){
                console.error(error)
                setCreatingGroupChat(false)
                setError(true)
            }
        }
    }, [companyDetails?.name])

    if(creatingGroupChat || loadingGeneratedContent){
        return(
            <PageFallback loadingText={`Creating Group chat and generating landing page content with AI for ${companyDetails?.name}`}/>
        )
    }

    if(error){
        <div className='itc-success-section'>
            <div className="itc-success-ctn">
                <Result
                    status="error"
                    title={<Title level={2} className="itc-success-title">Couldn't create group chat</Title>}
                    subTitle={<Paragraph  className="itc-success-subtitle">Group chat creation for your company was unsuccessful. Please check your internet connection and try creating it manually from your dashboard, or reach out to our support team for assistance.</Paragraph>}
                    extra={[
                        <Button 
                            size='large'
                            onClick={() => history.push(ROUTES.ITC_DASHBOARD.replace(':id', itcId))  }
                        >Go to Dashboard</Button>
                    ]}
                />
            </div>
        </div>
    }

    return (
        <div className='itc-success-section'>
            <div className="itc-success-ctn">
                <img src={SuccessImg} alt="success Img" />
                <Title level={2} className="itc-success-title">Congratulations, Your ITSC '{companyDetails?.name}' has been created successfully!</Title>
                <Paragraph  className="itc-success-subtitle">Congratulations! Your IT Service Company (ITSC) portal is now live on TecMe. You've taken a significant step towards expanding your professional IT services. You can now start customizing your portal, showcasing your expertise, and connecting with clients. Welcome to a new horizon of opportunities!</Paragraph>
                <Space>
                    <Button 
                        type="secondary" 
                        size='large'
                        onClick={() => history.push(ROUTES.ITC_PROFILE.replace(':id', itcId))}
                    >
                        Edit ITSC Settings
                    </Button>
                    <Button 
                        type="primary" 
                        size='large' 
                        onClick={() =>  history?.push(ROUTES.ITC_HOME.replace(':subdomain', companyDetails?.subdomain))}
                    >
                        View ITSC
                    </Button>
                </Space>
            </div>
        </div>
    )
}

export default ItcSuccess
