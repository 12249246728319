import {useEffect, useState} from 'react'
import {Modal, Typography, Form, Space, Input, Divider, Button, notification, Select, Avatar} from 'antd'
import moment from "moment";
import {ApiBaseHelper} from "../../../../../../utils/api-base-helper";
import {InfoCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {getBrandIcon} from "../../../public/instant-search/utils/helper";
import ROUTES from "../../../../../../utils/routes";
import {SECONDARY_COLOR} from "../../../../../../utils/constants";
import {useHistory} from "react-router-dom";

const { Text, Paragraph, Title } = Typography
const { Item, useForm } = Form
const { TextArea } = Input
const { Option } = Select

const resolveList = [
    'The technician has been notified about the new device.',
    'The technician may request that you purchase necessary device parts (if they are required for this ticket)',
]

const AttachDeviceModal = ({ visible= true, onOk, onCancel, ticketDetails, ticketRepository, getAndSetTicketDetails, getAndSetActivities }) => {
    const [form] = useForm()
    const [loading, setLoading] = useState(false)
    const [devices, setDevices] = useState([])
    const [selectedDevice, setSelectedDevice] = useState(null);

    useEffect(() => {
        ticketRepository?.getDevices().then(res => {
            if(res?.data?.status === "success") setDevices(res.data.data)
        }).catch(error => console.error(error))

    }, [ticketRepository])
    const history = useHistory();

    return (
        <Modal
            visible={visible}
            onCancel={loading ? () => false : onCancel}
            className="resolve-ticket-modal"
            footer={false}
        >
            <Space direction='vertical'>
                <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Attach a device</Title>
                <Paragraph className='my-2 disclaimer p-2'>
                    To support a swift resolution, attach a device related to your ticket. This will provide your technician with all necessary device information for quick and efficient troubleshooting.
                </Paragraph>
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={async (values) => {
                        setLoading(true)
                        try {
                            const payload = {
                                Hardware: values.device
                            }
                            
                            await ticketRepository?.attachDevice(ticketDetails?.id, payload)
                            await getAndSetTicketDetails()
                            await getAndSetActivities()
                            Modal.success({
                                className: 'ticket-updated-successfully-wrapper',
                                icon: null,
                                closable: true,
                                okText: 'Okay',
                                width: 500,
                                title: <Title level={3} className='font-weight-500 m-0'>Device has been attached</Title>,
                                content: (
                                    <div className='mt-2'>
                                        <Text className='font-weight-500'>What's Next:</Text>
                                        <ol>
                                            {
                                                resolveList.map((item, index) => {
                                                    return <li className={"info-list mt-2"} key={`list-item-${index}`}>{item}</li>
                                                })
                                            }
                                        </ol>
                                    </div>
                                )
                            });
                            onOk()
                        } catch (error) {
                            notification.error({
                                message: 'TecMe',
                                description: 'Ticket device attachment failed'
                            })
                        }
                        setLoading(false)
                    }}
                >
                    <Item
                        name="device"
                        label="Device"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a device',
                            },
                        ]}
                    >
                        <Select placeholder="Select a device"
                                onChange={(e)=>{
                                    setSelectedDevice(e);
                                }}
                        >
                            {devices.filter((item) => item.Active === "true").map(device => (
                                    <Option value={device.id} key={device.id}>
                                        <Space align='center'>
                                            <Avatar
                                                size={22}
                                                shape="square"
                                                src={getBrandIcon(device.Make)}
                                                alt={device.Make}
                                            >
                                                {device.Make?.[0]}
                                            </Avatar>
                                            <Text>
                                                {device.Make} {device.HardwareName}{device.Operating_System_Name !=null && device.Operating_System_Name.includes("a0") == false? `, ${device.Operating_System_Name}` : "" }
                                            </Text>
                                        </Space>
                                    </Option>
                                )
                            )}
                            <Option>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.push(ROUTES.ADD_DEVICES_NEW)
                                    }}
                                    className="tecme-secondary-color w-100 text-start border-0 px-2 fw-bold"
                                    type='link'
                                >
                                    <PlusOutlined className='fs-6' style={{
                                        strokeWidth: "40",
                                        stroke: SECONDARY_COLOR
                                    }} />
                                    Add New Device
                                </Button>
                            </Option>
                        </Select>
                    </Item>
                    <Divider />
                    <Space align='center' className='w-100 justify-content-end'>
                        <Item>
                            <Button disabled={selectedDevice == null} type='primary' htmlType="submit" loading={loading}>Attach device</Button>
                        </Item>
                    </Space>
                </Form>
            </Space>
        </Modal>
    )
}

export default AttachDeviceModal