import { EditOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Space, Tag, Typography, Upload, notification } from 'antd'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { capitalizeFirstLetter, truncateText } from '../../../../../utils/helper'
import { convertBudget, getDurationInfo, getSpecializationTitle, getWorkTypeInfo } from '../../../common/private/job-post/utils/helper'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PageFallback from '../../../../components/page-fallback'
import { JobTitleForm } from '../../add-job-posting/components/job-title'
import { JobDescriptionForm } from '../../add-job-posting/components/job-description'
import { JobCategoryForm } from '../../add-job-posting/components/job-category'
import { JobSkillsForm } from '../../add-job-posting/components/job-skills'
import { JobTypeForm } from '../../add-job-posting/components/job-type'
import { JobBudgetForm } from '../../add-job-posting/components/job-budget'
import { JobDurationForm } from '../../add-job-posting/components/job-duration'
import PageHeaderWrapper from '../../../../components/page-header-breadcrumbs'
import ROUTES from '../../../../../utils/routes'
import { SECONDARY_COLOR } from '../../../../../utils/constants'
import axios from 'axios';
import { generateAiResponse } from '../../../../../utils/openai-helper'


const { Title, Paragraph } = Typography
const { TextArea } = Input

const EditJobPost = () => {
    const user = useSelector((state) => state.user)
    const generalSkills = useSelector(state => state.generalSkillsGlobal)
    const [selectedSection, setSelectedSection] = useState("")
    const [loading, setLoading] = useState(false)
    const [loadingJob, setLoadingJob] = useState(false)
    const [jobPost, setJobPost] = useState({})
    const [selectedCategoryId, setSelectedCategoryId] = useState(null)
    const [attachments, setAttachments] = useState([])
    const [generatedSkills, setGeneratedSkills] = useState([])
    const [loadingGeneratedSkills, setLoadingGeneratedSkills] = useState(false)

    const { id } = useParams()

    const [jobPostingData, setJobPostingData] = useState({
        job_title: null,
        job_description: null,
        job_category: null,
        job_skills: [],
        job_type: null,
        job_budget_from: null,
        job_budget_to: null,
        job_budget_fixed: null,
        job_duration: null,
        job_payment_type: null,
        job_files: [],
    })

    const [updatedJobPostingData, setUpdatedJobPostingData] = useState({
        job_title: null,
        job_description: null,
        job_category: null,
        job_skills: [],
        job_type: null,
        job_budget_from: null,
        job_budget_to: null,
        job_budget_fixed: null,
        job_duration: null,
        job_payment_type: null,
        job_files: [],
    })



    const handlePostJob = () => {
        setLoading(true)  
        const job_budget = updatedJobPostingData?.job_payment_type === null ? jobPostingData?.job_payment_type === 'Hourly' ? (jobPostingData?.job_budget_from * 100) + " - " + (jobPostingData?.job_budget_to * 100) : (jobPostingData.job_budget_fixed * 100) : updatedJobPostingData?.job_payment_type === 'Hourly' ? (updatedJobPostingData?.job_budget_from * 100) + " - " + (updatedJobPostingData?.job_budget_to * 100) : (updatedJobPostingData?.job_budget_fixed * 100)

        ApiBaseHelper.put({
            url: `job-post/${id}`,	
            headers: {
                Authorization: 'Bearer ' + user['token'],
            },
            payload: {
                Category: updatedJobPostingData?.job_category !== null ? updatedJobPostingData?.job_category : jobPostingData?.job_category,
                Title: updatedJobPostingData?.job_title !== null ? updatedJobPostingData?.job_title : jobPostingData?.job_title,
                Description: updatedJobPostingData?.job_description !== null ? updatedJobPostingData?.job_description : jobPostingData?.job_description,
                Duration: updatedJobPostingData?.job_duration !== null ? updatedJobPostingData?.job_duration : jobPostingData?.job_duration,
                Payment_Type: updatedJobPostingData?.job_payment_type !== null ? updatedJobPostingData?.job_payment_type : jobPostingData?.job_payment_type,
                Skills_Required: updatedJobPostingData?.job_skills?.length > 0 ? updatedJobPostingData?.job_skills?.join(', ') : jobPostingData?.job_skills?.join(', '),
                Status: "Active",
                Work_Type: updatedJobPostingData?.job_type !== null ? updatedJobPostingData?.job_type : jobPostingData?.job_type,
                Budget: job_budget
            }
        }).then((res) => {
            if(res){
                if(jobPostingData?.job_files?.length > 0){
                    handleFileSubmit(res?.data?.data?.id)
                    return
                }
                notification.success({
                    message: 'TecMe',
                    description: 'Job Post Updated Successfully',
                });
            }
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            notification.error({
                message: 'TecMe',
                description: 'An error occured!',
            });
            console.log(err)
        })
    }

    const handleFileSubmit = (res_id) => {
        const payload = new FormData();
        if (jobPostingData?.job_files?.length) {
            jobPostingData?.job_files?.forEach((file) => {
                payload.append('file', file)
            });
        }
        ApiBaseHelper.post({
            url: `job-post/media/job-posting/${res_id}`,	
            headers: {
                Authorization: 'Bearer ' + user['token'],
            },
            payload: payload
        }).then((res) => {
            if(res){
                notification.success({
                    message: 'TecMe',
                    description: 'Job Post Updated Successfully',
                });
            }
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            notification.error({
                message: 'TecMe',
                description: 'An error occured!',
            });
            console.log(err)
        })
    }

    const getGeneratedSkills = async () => {

        const prompt = `List the essential skills for a \"${jobPostingData?.job_title}\" specialized in \"${getSpecializationTitle(generalSkills, selectedCategoryId)}\". Separated by comma. A maximum of 10 skills and remove the noun adjunct (like "understanding of", "knowledge of", etc) and just give only the skills`;

        console.log(prompt)

        setLoadingGeneratedSkills(true)
        const response = await generateAiResponse(prompt, user?.token)
        console.log(response)
        if(response){
            // Extract and format the IT skills from the response
            const skillsList = response?.content?.split(', ')
            setLoadingGeneratedSkills(false)
            setGeneratedSkills(skillsList);
        }
    };

    const fetchJobPosting = () => {
        setLoadingJob(true)
        ApiBaseHelper.get({
            url: `job-post/client`, 
            headers: {
                Authorization: 'Bearer ' + user?.token
            }
        }).then((res) => {
            setJobPost(res?.data?.data.find((item) => item.id === id))
            setLoadingJob(false)
        }).catch((error) => {
            console.error(error)
            setLoadingJob(false)
        })       
    }

    
    const fetchAttachments = () => {
        ApiBaseHelper.get({
            url: `job-post/media/${id}`, 
            headers: {
                Authorization: 'Bearer ' + user?.token
            }
        }).then((res) => {
            setAttachments(res?.data?.data)
        }).catch((error) => {
            console.error(error)
        })       
    }

    useEffect(() => {
        if(jobPost){
            setJobPostingData({
                job_title: jobPost?.Title,
                job_description: jobPost?.Description,
                job_category: jobPost?.Category,
                job_skills: jobPost?.Skills_Required?.split(', '),
                job_type: jobPost?.Work_Type,
                job_budget_from: jobPost?.Payment_Type === "Hourly" ? jobPost?.Budget?.split(' - ')[0]/100 : null,
                job_budget_to: jobPost?.Payment_Type === "Hourly" ?  jobPost?.Budget?.split(' - ')[1]/100 : null,
                job_budget_fixed: jobPost?.Payment_Type === "Fixed" ? jobPost?.Budget/100 : null,
                job_duration: jobPost?.Duration,
                job_payment_type: jobPost?.Payment_Type,
                job_files: []
            })
        }
    }, [jobPost])

    const getModalContent = () => {
        switch (selectedSection) {
            case "Title":
                return {
                    content: <JobTitleForm 
                        jobPostingData={jobPostingData}
                        setJobPostingData={setJobPostingData}
                    />,
                    onClick: () => setUpdatedJobPostingData({...updatedJobPostingData, job_title: jobPostingData?.job_title})
                }
            case "Description":
                return {
                    content: <JobDescriptionForm
                        jobPostingData={jobPostingData}
                        setJobPostingData={setJobPostingData}
                    />,
                    onClick: () => setUpdatedJobPostingData({...updatedJobPostingData, job_description: jobPostingData?.job_description, job_files: jobPostingData?.job_files})
                }
             case "Job Category":
                return {
                        content:<JobCategoryForm 
                        setSelectedCategoryId={setSelectedCategoryId} 
                        allAvailableSkills={generalSkills}
                        jobPostingData={jobPostingData}
                        setJobPostingData={setJobPostingData}
                        setCategoryChanged={() => {}}
                    />,
                    onClick: () => {
                        setUpdatedJobPostingData({...updatedJobPostingData, job_category: jobPostingData?.job_category})
                        setSelectedSection("Skills") 
                        getGeneratedSkills()
                    }
                }
            case "Skills":
                return  {
                    content: 
                    <>
                        <Paragraph style={{fontWeight:500}}>Please enter skills for the {(generalSkills && getSpecializationTitle(generalSkills, updatedJobPostingData?.job_category)) || (generalSkills && getSpecializationTitle(generalSkills, jobPost?.Category))} category</Paragraph> 
                        <JobSkillsForm
                            allAvailableSkills={generalSkills}
                            jobPostingData={jobPostingData}
                            setJobPostingData={setJobPostingData}
                            selectedCategoryId={selectedCategoryId}
                            generatedSkills={generatedSkills}
                            loadingGeneratedSkills={loadingGeneratedSkills}
                            getGeneratedSkills={getGeneratedSkills}
                        />
                    </>,
                    onClick: () => setUpdatedJobPostingData({...updatedJobPostingData, job_skills: jobPostingData?.job_skills})
                }
           
            case "Work Type":
                return {
                    content: <>
                        <Paragraph style={{fontWeight:500}}>What is your preferred work type?</Paragraph> 
                        <JobTypeForm
                            jobPostingData={jobPostingData}
                            setJobPostingData={setJobPostingData}
                        />
                    </>,
                    onClick: () => setUpdatedJobPostingData({...updatedJobPostingData, job_type: jobPostingData?.job_type})
                }
            case "Project Budget":
                return { 
                    content: <>
                        <Paragraph style={{fontWeight:500}}>Set your budget</Paragraph>
                        <JobBudgetForm
                            jobPostingData={jobPostingData}
                            setJobPostingData={setJobPostingData}
                            currentStep={8}
                        />
                    </>,
                    onClick: () => setUpdatedJobPostingData({...updatedJobPostingData, job_budget_from: jobPostingData?.job_budget_from, job_budget_to: jobPostingData?.job_budget_to, job_budget_fixed: jobPostingData?.job_budget_fixed, job_payment_type: jobPostingData?.job_payment_type})
                }
            case "Project Duration":
                return{ 
                   content: <>
                            <Paragraph style={{fontWeight:500}}>Set project duration</Paragraph>
                            <JobDurationForm
                                jobPostingData={jobPostingData}
                                setJobPostingData={setJobPostingData}
                            />
                        </>,
                    onClick: () => setUpdatedJobPostingData({...updatedJobPostingData, job_duration: jobPostingData?.job_duration}) 
                }
            default:
                return null 
        }
    }

    useEffect(() => {
        fetchJobPosting()
    }, [])

    useEffect(() => {
        fetchAttachments()
    },[id])


    return (
        <>
            <PageHeaderWrapper
                routes={[
                    {
                        path: ROUTES.JOB_POSTINGS,
                        breadcrumbName: 'Job Postings',
                    },
                    {
                        path: ROUTES.MANAGE_JOB_POSTING.replace(':id', id),
                        breadcrumbName:truncateText(capitalizeFirstLetter(jobPost?.Title), 22),
                    },
                    {
                        breadcrumbName: 'Edit'
                    }
                ]}
            />
            {
                loadingJob ? <PageFallback /> :
                <div className='auth-container job-preview-ctn mt-5 mb-5'>
                    <div className='job-preview-header'>
                        <Title level={4}>Job Details</Title>
                    </div>
                    <div className="job-preview-content">
                        <div className='job-preview-section'>
                            <Space>
                                <Title level={5} className='job-preview-title'>Title</Title>
                                <EditOutlined className='job-preview-icon' onClick={() => setSelectedSection("Title")}/>
                            </Space>
                            <Paragraph className='job-preview-subtitle'>{capitalizeFirstLetter(updatedJobPostingData?.job_title) || capitalizeFirstLetter(jobPost?.Title)}</Paragraph>
                        </div>
                        <div className='job-preview-section'>
                            <Space>
                                <Title level={5} className='job-preview-title'>Description</Title>
                                <EditOutlined className='job-preview-icon' onClick={() => setSelectedSection("Description")}/>
                            </Space>
                            {
                                (updatedJobPostingData?.job_description || jobPost?.Description)?.split('\n').map((line, index) => (
                                    <Paragraph key={index}>
                                    {line}
                                    </Paragraph>
                                ))
                            }
                            {
                                attachments?.data?.length > 0 &&
                                attachments?.data?.map((item, index) => {
                                    return(
                                        <div key={item?.id} className='mb-1'>
                                            <Space align='center' className="download-cv">
                                                <PaperClipOutlined style={{color:"rgba(0, 0, 0, 0.45)", position:"relative", top:2}}/>
                                                <a  style={{color:SECONDARY_COLOR, textDecoration:"none"}} href={item?.Body}>{item?.Title}</a>
                                            </Space>
                                        </div>
                                    )
                                })
                            }
                            {
                                updatedJobPostingData?.job_files?.length > 0 &&
                                updatedJobPostingData?.job_files?.map((item, index) => {
                                    return(
                                        <div key={item?.uid} className='mb-1'>
                                            <Space align='center' className="download-cv">
                                                <PaperClipOutlined style={{color:"rgba(0, 0, 0, 0.45)", position:"relative", top:2}}/>
                                                <p style={{color:SECONDARY_COLOR, marginBottom:0}}>{item?.name}</p>
                                            </Space>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='job-preview-section'>
                            <Space>
                                <Title level={5} className='job-preview-title'>Job category</Title>
                                <EditOutlined className='job-preview-icon' onClick={() => setSelectedSection("Job Category")}/>
                            </Space>
                            <Paragraph className='job-preview-subtitle'>{(generalSkills && getSpecializationTitle(generalSkills, updatedJobPostingData?.job_category)) || (generalSkills && getSpecializationTitle(generalSkills, jobPost?.Category))}</Paragraph>
                        </div>
                        <div className='job-preview-section'>
                            <Space>
                                <Title level={5} className='job-preview-title'>Skills</Title>
                                <EditOutlined className='job-preview-icon' onClick={() => setSelectedSection("Skills")}/>
                            </Space>
                            <div>
                                <Space wrap>
                                    {
                                       updatedJobPostingData?.job_skills?.length > 0 ? updatedJobPostingData?.job_skills?.map((item, index) => {
                                            return(
                                                <Tag className='custom-ant-tag' key={index}>{item}</Tag>
                                            )
                                        }) :
                                        jobPost?.Skills_Required?.split(', ').map((item, index) => {
                                            return(
                                                <Tag className='custom-ant-tag' key={index}>{item}</Tag>
                                            )
                                        })
                                    }
                                </Space>
                            </div>
                        </div>
                        <div className='job-preview-section'>
                            <Space>
                                <Title level={5} className='job-preview-title'>Work type</Title>
                                <EditOutlined className='job-preview-icon'  onClick={() => setSelectedSection("Work Type")}/>
                            </Space>
                            <Paragraph className='job-preview-subtitle'>{updatedJobPostingData?.job_type !== null ? getWorkTypeInfo(updatedJobPostingData?.job_type)?.text : getWorkTypeInfo(jobPost?.Work_Type)?.text}</Paragraph>
                        </div>
                        <div className='job-preview-section'>
                            <Space>
                                <Title level={5} className='job-preview-title'>Budget</Title>
                                <EditOutlined className='job-preview-icon' onClick={() => setSelectedSection("Project Budget")}/>
                            </Space>
                            {
                                updatedJobPostingData?.job_budget_from || updatedJobPostingData?.job_budget_to || updatedJobPostingData?.job_budget_fixed ?
                                <Paragraph className='job-preview-subtitle'>{updatedJobPostingData?.job_payment_type === 'Hourly' ? `$${updatedJobPostingData?.job_budget_from}` + " - " + `$${updatedJobPostingData?.job_budget_to}` + " /hr" : `$${updatedJobPostingData?.job_budget_fixed} fixed cost` }</Paragraph> :
                                <Paragraph className='job-preview-subtitle'>{convertBudget(jobPost?.Budget, jobPost?.Payment_Type)}</Paragraph>

                            }
                        </div>
                        <div className='job-preview-section'>
                            <Space>
                                <Title level={5} className='job-preview-title'>Project Duration</Title>
                                <EditOutlined className='job-preview-icon'  onClick={() => setSelectedSection("Project Duration")}/>
                            </Space>
                            <Paragraph className='job-preview-subtitle'>{(updatedJobPostingData?.job_duration !== null ? getDurationInfo(updatedJobPostingData?.job_duration)?.text : getDurationInfo(jobPost?.Duration)?.text) + ' Project'}</Paragraph>
                        </div>
                        <div className='job-preview-btn-ctn'>
                            <Button type='primary' className='job-preview-btn' onClick={handlePostJob} loading={loading}>Save this job</Button>
                            {/* <Button type='secondary' className='job-preview-btn'>Save as draft</Button> */}
                        </div>
                    </div>
                    <Modal
                        title={`Edit ${selectedSection}`}
                        visible={!!selectedSection}
                        onOk={() => {
                            // if(selectedSection === "Job Category"){
                            //     setSelectedSection("Skills")
                            //     getModalContent()?.onClick()
                            //     getGeneratedSkills()
                            //     return
                            // }
                            setSelectedSection("")
                            getModalContent()?.onClick()
                        }}
                        onCancel={() => {
                            setSelectedSection("")
                        }}
                        okText={"Save"}
                        width={(selectedSection === "Title" || selectedSection === "Job Category") ? 572 : 801}
                        cancelButtonProps={{type:'secondary'}}
                    >
                        {getModalContent()?.content}
                    </Modal>
                </div>
            }
        </>
    )
}


export default EditJobPost