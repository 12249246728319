import { ApiBaseHelper } from "../../../../../utils/api-base-helper";

export class TicketListingRepository {
    constructor(accessToken) {
        this.accessToken = accessToken;
    }

    getTickets(role) {
        return ApiBaseHelper.get(
            {
                url: `ticket?role=${role}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }
}

