import { Button, Col, Form, Input, Row, Select, Space, Tooltip, Typography, message } from 'antd'
import React, { useState } from 'react'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { CloseOutlined, MailOutlined, PlusOutlined } from '@ant-design/icons'
import ConfirmPopupModal from './confirm-popup-modal'
import { useSelector } from 'react-redux'


const selectTeamRoles = [ 
    {
        name:"Administrator",
        value:"administrator",
        description:"Have unrestricted access to all features and functionalities of the platform. Can manage organization team members."
    },
    {
        name:"Manager",
        value:"manager",
        description:"Have all recruiter permissions. Can hire technicians, purchase IT services and manage payment methods."
    },
    {
        name:"Recruiter",
        value:"recruiter",
        description:"Can search, message technicians and manage saved lists."
    }
]

const { useForm } = Form
const {Paragraph, Text} = Typography

const InviteMembersForm = ({getTeamMembersList, teamMembersList, setProgressIndex, handleNextClick, isFromOnboarding=false}) => {
    const user = useSelector((state) => state.user)
    const [form] = useForm()
    const [submitting, setSubmitting] = useState(false)
    const [openResendModal, setOpenResendModal] = useState(false)
    const [selectedMember, setSelectedMember] = useState(null)
    const [actionLoading, setActionLoading] = useState(false)
    const [disableRole, setDisableRole] = useState(false)

    const invitedTeamMembers = teamMembersList?.filter((member) => member?.Status === "Invited")

    const resendInvite = (record) => {
        setActionLoading(true)
        ApiBaseHelper.post({
            url: `user-management/resend-invite`,
            headers: {
                Authorization: `Bearer ${user?.token}`
            },
            payload:{
                Invited_Email: record?.Invited_Email,
                Role_Title: record?.Role_Title,
                Organization_Name: user?.organization?.Name,
                OCA_Id: record?.id,
            }
        }).then((res) => {
            message.success({
                content:"Invite resent successfully", 
                className: 'custom-success-message-alert'
            })
            getTeamMembersList()
            setActionLoading(false)
            setOpenResendModal(false)
            setDisableRole(false)
            form.resetFields()
        }).catch((error) => {
            console.log(error)
            message.error({
                content: "Something went wrong",
                className: 'custom-error-message-alert'
            })
            setActionLoading(false)
            setDisableRole(false)
        })
    }

    const validateItem = (_, value) => {
        if (teamMembersList.some((obj) => obj.Invited_Email === value)) {
            return Promise.reject(new Error('This user has been invited already'));
        }
        return Promise.resolve(); 
      };

    const inviteTeamMembers = (payload) => {
        setSubmitting(true)
        if(payload.some(obj1 => teamMembersList.filter((member) => member?.Status === "Active").some(obj2 => obj2.Invited_Email === obj1.Invited_Email))){
            message['error']({
                content: "One or more of the emails you entered already exists in your team",
            })
            setSubmitting(false)
            return
        }
        if(payload.length > 1){
            if(payload.some(obj1 => invitedTeamMembers?.some(obj2 => obj2.Invited_Email === obj1.Invited_Email))){
                message['error']({
                    content: "One or more of the emails you entered already invited",
                })
                validateItem()
                setSubmitting(false)
                return
            }
        }else{
            if(payload.some(obj1 => invitedTeamMembers?.some(obj2 => obj2.Invited_Email === obj1.Invited_Email))){
                setOpenResendModal(true)
                setSelectedMember(teamMembersList.find(obj2 => obj2.Invited_Email === payload[0].Invited_Email))
                setSubmitting(false)
                return
            }
        }
        ApiBaseHelper.post({
            url: 'user-management/send-invite', 
            payload: payload,
            headers: {
                Authorization: 'Bearer ' + user['token'],
            },
        }).then(res => {
            if(res){
                getTeamMembersList()
                message.success({
                    content: `Invite${payload?.length > 1 ? 's' : ''} sent successfully`,
                    className: 'custom-success-message-alert',
                });
                setSubmitting(false)
                form.resetFields()
                if(isFromOnboarding){
                    handleNextClick()
                    setProgressIndex(3)
                }
            }
        }).catch((error) => {
            console.log(error)
            setSubmitting(false)
            message['error']({
                content: "An Error Occurred",
            })
        })
    }
    
    const handleFormSubmit = (values) => {
        inviteTeamMembers(values?.members)
    };


    return (
        <>
            <Form
            form={form}
            style={{width:"100%"}} 
            name="invite_team_form" 
            onFinish={handleFormSubmit} 
            autoComplete="off">
                <Form.List name="members" initialValue={[{}]}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <Row key={field.key} gutter={[0, 14]} justify={"space-between"} align={"top"} className='member-form-row'>
                                <Col xs={24} sm={24} md={13} lg={13} xl={13}>
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'Invited_Email']}
                                        fieldKey={[field.fieldKey, 'Invited_Email']}
                                        rules={[{ required: true, message: 'Email is required' },
                                        { type: 'email', message: 'Please enter a valid email'},
                                        { validator: fields.length > 1 && validateItem}]}
                                    >
                                        <Input className='member-form-input' prefix={<MailOutlined />} placeholder='you@example.com' 
                                            onChange={
                                                (e) => {
                                                    setDisableRole(invitedTeamMembers.some((obj) => obj.Invited_Email === e.target.value))
                                                }
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'Role_Title']}
                                        fieldKey={[field.fieldKey, 'Role_Title']}
                                        rules={[{ 
                                            required:  invitedTeamMembers.some((obj) => obj.Invited_Email === form.getFieldValue(['members', index, 'Invited_Email'])) ? false : true ,
                                            message: 'Role is required' 
                                    }]}
                                    >
                                        <Select 
                                            className='member-form-select'  
                                            placeholder="Select"
                                            disabled={disableRole}
                                        >
                                            {
                                                selectTeamRoles.map((role, index) => (
                                                    <Select.Option value={role?.value}>
                                                        <Tooltip
                                                        overlayClassName='select-role-overlay' placement="right" title={role?.description}><Paragraph style={{margin:0}}>{role?.name}</Paragraph></Tooltip>
                                                    </Select.Option>
                                                ))                                                
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col> 
                                <Col xs={0} sm={0} md={3} lg={3} xl={3}>
                                { 
                                    index !== 0 && 
                                    <CloseOutlined onClick={() => remove(field.name)} className='input-close-icon'/>
                                }
                                </Col>  
                                <Col xs={24} sm={24} md={0} lg={0} xl={0}>
                                { 
                                    index !== 0 && 
                                    <Paragraph onClick={() => remove(field.name)} className='input-close-icon'>Remove</Paragraph>
                                }
                                </Col> 
                            </Row> 
                        ))}
                        <Row align={"middle"} justify={"space-between"} className='member-form-row cta-form-row'>
                                <Col xs={12} sm={12} md={13} lg={13} xl={13}>
                                    <Space 
                                        onClick={() => add()}
                                        style={{cursor:"pointer"}}>
                                        <PlusOutlined />
                                        <Text>Add Another</Text>
                                    </Space>
                                </Col>
                                <Col xs={12} sm={12} md={7} lg={7} xl={7} className='d-flex justify-content-end'>
                                    <Button type='primary' htmlType='submit' className="send-invites-btn" style={{height:40}} loading={submitting}>   
                                        <MailOutlined />
                                        Send invite{fields.length > 1 && 's'}
                                    </Button>
                                </Col>
                                <Col  xs={0} sm={0} md={3} lg={3} xl={3}>

                                </Col>
                            </Row>
                    </>
                )}
                </Form.List>
            </Form>
            
            <ConfirmPopupModal
                open={openResendModal}
                setOpen={setOpenResendModal}
                title="Are you sure you want to resend the invitation?"
                sub_title="This action will send a new invitation email to the recipient with the same details."
                onOk={() => resendInvite(selectedMember)}
                okButtonProps={{loading: actionLoading}}
            />
        </>
    )
}

export default InviteMembersForm