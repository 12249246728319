import React, { useEffect, useState } from 'react'
import { CompanyDetailsForm } from '../../create-itc/components/company-details';
import { Button, Form, Typography, notification} from 'antd';
import { BusinessLocationForm } from '../../create-itc/components/extra-company-details';
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FirebaseHelper, FirestoreHelper } from '../../../../../../utils/firebase-helper';
import RightArrow from '../../../../../../assets/images/right-arrow.svg'
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { getAndSetGroupCoversation } from '../../../../../../utils/helper';

const { Title } = Typography

const ProfileCompanyDetails = ({itcDetails, locationDetails}) => {
    const tech = useSelector(state => state.tech)
    const param = useParams()
    const [form] = Form.useForm();
    const [submittingDetails, setSubmittingDetails] = useState(false)
    const [expYearsValue, setExpYearsValue] = useState(1);
    const [groupConversation, setGroupConversation] = useState(null)
    const [editorState, setEditorState] = useState("");

    const handleCompanyDetailsSubmit = (values) => {
        setSubmittingDetails(true)
        updateGroupConversationTitle(values.companyName)
        if(draftToHtml(convertToRaw(editorState?.getCurrentContent())) === "<p></p>\n"){
            notification.error({
                message: 'TecMe',
                description: 'Please enter a description'
            })
            return
        }
        ApiBaseHelper.put({
            url: `itc/${param.id}`,	
            headers: {
                Authorization: 'Bearer ' + tech['token'],
            },
            payload: {
                Company_Name: values.companyName,
                Description:  draftToHtml(convertToRaw(editorState?.getCurrentContent())).replace(/&nbsp;/g, ' '),   
                Subdomain: values.companyWebsite?.toLowerCase(),
                Years_Experience: expYearsValue,
                LinkedIn_URL: values.linkedinUrl,
                Facebook_URL: values?.facebookUrl,
                Youtube_URL: values?.youtubeUrl,
                Twitter_URL:  values?.twitterUrl,
                Tiktok_URL: values?.tiktokUrl,
                Instagram_URL: values?.instagramUrl,
                Website_URL: values?.websiteUrl,
            }
        }).then((res) => {
            setSubmittingDetails(false)
            notification.success({
                message: 'TecMe',
                description: 'Company details updated successfully'
            })
        }).catch((err) => {
            setSubmittingDetails(false)
            console.log(err)
            notification.error({
                message: 'TecMe',
                description: 'An error occurred while updating company details'
            })
        })

        ApiBaseHelper.put({
            url: `home/${locationDetails?.Id}`,	
            headers: {
                Authorization: 'Bearer ' + tech['token'],
            },
            payload: {
                city: values.city,
                state: values.state,
                zipCode: values.zipCode,
            }
        }).then((res) => {
            setSubmittingDetails(false)
        }).catch((err) => {
            setSubmittingDetails(false)
            console.log(err)
        })
    }


    // Update group converation title when company name is updated
    const updateGroupConversationTitle = async (companyName) => {
        await FirestoreHelper.updateGroupTitle({
            group: companyName,
            conversation_id: groupConversation?.id,
        })
    }

    useEffect(() => {
        form.setFieldsValue({
            companyName: itcDetails?.Company_Name,
            companyWebsite: itcDetails?.Subdomain,
            companyDescription: itcDetails?.Description,
            linkedinUrl: itcDetails?.custom_data?.linkedInUrl,
            facebookUrl: itcDetails?.custom_data?.facebookUrl,
            youtubeUrl: itcDetails?.custom_data?.youtubeUrl,
            twitterUrl: itcDetails?.custom_data?.twitterUrl,
            tiktokUrl: itcDetails?.custom_data?.tiktokUrl,
            instagramUrl: itcDetails?.custom_data?.instagramUrl,
            websiteUrl: itcDetails?.custom_data?.websiteUrl,
            city: locationDetails?.City,
            state: locationDetails?.State,
            zipCode: locationDetails?.Zip_Code,
        })
        setExpYearsValue(itcDetails?.Years_Experience)
        const contentBlock = htmlToDraft(itcDetails?.Description || "");
        const contentState = ContentState.createFromBlockArray(contentBlock?.contentBlocks);
        setEditorState(EditorState.createWithContent(contentState))
    }, [itcDetails, locationDetails])

    useEffect(async() => {
        if (itcDetails?.Company_Name) {
            const response = await getAndSetGroupCoversation(itcDetails?.Company_Name)
            setGroupConversation(response)
        }
    }, [itcDetails])

    return (
        <Form
            onFinish={handleCompanyDetailsSubmit}
            form={form}
            layout='vertical'
            className="itc-company-details-form"
        >
            <CompanyDetailsForm
                expYearsValue={Number(expYearsValue)}
                setExpYearsValue={setExpYearsValue}
                isFromItcProfile={true}
                itcDetails={itcDetails}
                editorState={editorState}
                setEditorState={setEditorState}
            />
            <div className='mt-5' >
                <Title level={4} className="itc-form-title">Business Location</Title>
                <BusinessLocationForm />
            </div>
            <Form.Item>
                <Button 
                    className='itc-next-btn mt-5' 
                    type='primary' 
                    htmlType='submit'
                    loading={submittingDetails}
                >
                    Update
                </Button>
            </Form.Item> 
        </Form>
    )
}

export default ProfileCompanyDetails