import React, { Component, Fragment, useState } from 'react'
import {Row, Col, Typography, Rate, Image, Form, notification, Input, Button, Spin, Modal, List} from 'antd';
import {LoadingOutlined, StarFilled} from '@ant-design/icons';
import moment from "moment";
import EarningImage from "../../../assets/images/earning.jpg";
import Logo from "../../../assets/images/logo2.png";
import {ApiBaseHelper} from "../../../utils/api-base-helper";
import MessageIcon from "../../../assets/images/message.png";
import CheckOutlined from "@ant-design/icons/lib/icons/CheckOutlined";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import ROUTES from "../../../utils/routes";

const { Paragraph, Text, Title } = Typography;


class TechConnectModal extends Component {
    constructor(props) {
        super(props);
        let localStorageValue = localStorage.getItem('techConnectModal');
        localStorageValue = !(localStorageValue == null || localStorageValue == undefined)
        this.state = {
            visible :
            localStorageValue == false &&
                this.props.tech != null
                && this.props.tech.Stripe_Connect_Id == null,
        }
    }

    componentDidMount() {
        if(this.props.tech != null && this.props.tech.Stripe_Connect_Id == null){
            localStorage.setItem('techConnectModal', 'true');
        }
    }

    render() {
        const {visible} = this.state;
        const {serviceDetails} = this.props;
        if(this.props.tech == null)
            return <></>;
        return (
            <Fragment>
                <Modal
                    visible={visible || serviceDetails}
                    style={{ padding: '0 1rem', borderRadius: '0.5rem' }}
                    footer={false} width={800} onCancel={() => {
                    this.setState({ visible: false });
                    if(this.props.onCancel)
                    this.props.onCancel();
                }}>
                    <Row gutter={[16, 16]} align="middle" justify="center" style={{ padding: '1rem auto' }}>
                            <Col xs={0} sm={0} md={11} lg={11} xl={11} >
                            <Image loading="lazy"  preview={false} src={EarningImage} style={{ padding: '2rem 1rem', marginTop: '1rem' }} />
                        </Col>
                        <Col xs={24} sm={24} md={13} lg={13} xl={13} >
                            <Image loading="lazy"  src={Logo} preview={false} style={{ width: '150px', margin: '1rem 0', }} />
                            <Title level={2} style={{ color: '#474747' }}>Setup Your Earnings Account</Title>
                            <List
                                itemLayout="horizontal"
                                dataSource={[
                                    "View your TecMe earnings chart",
                                    "Set up your bank account for payouts",
                                    "View your transaction activity",
                                    "Schedule or perform instant payouts",
                                ]}
                                split={false}
                                style={{marginTop: 10}}
                                renderItem={item => (
                                    <List.Item className="list-padding" style={{padding:0}} >
                                        <List.Item.Meta
                                            avatar={<CheckOutlined style={{color: '#00BF36'}}/>}
                                            title={<Text className="" style={{fontWeight: '400',fontSize:16}}>{item}</Text>}
                                        />
                                    </List.Item>
                                )}
                            />
                            <Button type={"primary"} style={{ width: '140px', marginTop: '1rem' }}
                            onClick={(e)=>{
                                window.open(this.props.tech.Stripe_Connect_URL, '_blank');
                            }}
                            >
                                Continue
                            </Button>
                        </Col>
                    </Row>
                </Modal>
            </Fragment>
        );
    }
}
const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(TechConnectModal);