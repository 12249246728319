import { ApiBaseHelper } from "../../../../../../utils/api-base-helper";

export class ServiceRepository {
    constructor(accessToken) {
        this.accessToken = accessToken;
    }

    getBookingDetails(bookingId,userType) {
        return ApiBaseHelper.get(
            {
                url: `v2/booking/${bookingId}?type=${userType}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    getTechSchedule(techId) {
        return ApiBaseHelper.get(
            {
                url: `web/tech/${techId}/schedule`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    acceptBooking(bookingId) {
        return ApiBaseHelper.post(
            {
                url: `v2/booking/${bookingId}/accept`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    declineBooking(bookingId) {
        return ApiBaseHelper.post(
            {
                url: `v2/booking/${bookingId}/decline`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    beginService(serviceId) {
        return ApiBaseHelper.post(
            {
                url: `item/${serviceId}/begin`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }
    cancelService(serviceId) {
        return ApiBaseHelper.post(
            {
                url: `item/${serviceId}/cancel`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    updateService({id,payload}) {
        return ApiBaseHelper.put(
            {
                url: `item/${id}`,
                payload,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }
    createService(payload) {
        return ApiBaseHelper.post(
            {
                url: `item`,
                payload:payload,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    completeService({serviceId,quantity,cost}) {
        return ApiBaseHelper.post(
            {
                url: `item/${serviceId}/complete`,
                payload:{
                    quantity,
                    cost
                },
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    addPart(payload) {
        return ApiBaseHelper.post(
            {
                url: `item`,
                payload:payload,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    updatePart(id,payload) {
        return ApiBaseHelper.put(
            {
                url: `item/${id}`,
                payload:payload,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    getService(id) {
        return ApiBaseHelper.get(
            {
                url: `item/${id}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    deletePart(id) {
        return ApiBaseHelper.delete(
            {
                url: `item/${id}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    getLocation() {
        return ApiBaseHelper.get(
            {
                url: `home`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }
    getEventLogs(id) {
        return ApiBaseHelper.get(
            {
                url: `event-log/${id}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }


}

