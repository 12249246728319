// External Imports
import { Row, Card, Col, Button, Space, Typography, Table, Tag, Avatar, List, Skeleton, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import moment from 'moment'
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons'

// Internal Imports
import '../../../../../customer/ticket-listing/assets/css/styles.css'
import { ApiBaseHelper } from '../../../../../../../utils/api-base-helper';
import SearchInput from '../../../../../../components/search-input';
// import { nameFormat } from '../../../../../../utils/helper';
import ROUTES from '../../../../../../../utils/routes';
import CustomRenderEmpty from '../../../../../../components/custom-render-empty';
import { TicketListingRepository } from '../../../../../customer/ticket-listing/repository';
import PageTitle from '../../../../../../components/page-title';
import { canReadPageOnly, capitalizeFirstLetter, capitalizeFirstWordOfEveryLetter, nameFormat } from '../../../../../../../utils/helper';
import RightArrow from '../../../../../../../assets/images/right-arrow.svg'

const { Title, Text, Paragraph } = Typography

let ticketTypes = [
    // {
    //     id: "all",
    //     ticket_status: "All",
    //     tooltip: "Use this filter to view all tickets."
    // },
    {
        id: "open",
        ticket_status: "Open",
        tooltip: "Includes tickets actively in service by the technician."
    },
    {
        id: "assigned",
        ticket_status: "Assigned",
        tooltip: "Includes tickets assigned to the technician but not yet initiated for servicing."
    },
    {
        id: "pending",
        ticket_status: "Pending",
        tooltip: "Includes tickets linked to a paused contract, temporarily unavailable for service until contract resumption."
    },
    {
        id: "resolved",
        ticket_status: "Resolved",
        tooltip: "Contains tickets completed by the technician, yet they remain available for additional service."
    },
    {
        id: "closed",
        ticket_status: "Closed",
        tooltip: "Includes tickets that have been concluded and are no longer open for further service."
    },
    {
        id: "new",
        ticket_status: "Unassigned",
        tooltip: "Includes tickets yet to be assigned to technician"
    },
]

const priorityTheme = {
    "High" : "#FA541C",
    "Medium" : "#FAAD14",
    "Low" : "#52C41A"
}

const AssignedTicketsContent = ({ handleNewSupportTicketButtonClick, setShowDisclaimer, isFromActiveContracts, itcTickets = null, isContractTerminated = false, isContractPaused = false, isFromDashboard = false}) => {
    const history = useHistory();
    const params = useParams();
    const customer = useSelector(state => state.user)
    const tech = useSelector(state => state.tech)
    const location = useLocation()

    const isItcDashboardPage = window.location.pathname.includes('itsc/dashboard')

    const [ loadingTickets, setLoadingTickets ] = useState(false)
    const [ ticketData, setTicketData ] = useState([])
    const [ filteredTickets, setFilteredTickets ] = useState([])
    const [ ticketStatus, setTicketStatus ] = useState("open")
    const [contractData, setContractData] = useState({})
    const queryParams = new URLSearchParams(location.search);
    const ticketQueryParam = queryParams.get('tickets');

    const user = customer || tech
    const role = tech ? 'technician' : 'customer'
    const contractId = params?.contractId;

    const filterByStatus = () => {
        if(!ticketStatus || ticketStatus === "all"){
            setFilteredTickets(isItcDashboardPage ? itcTickets : ticketData)
            return
        }

        const filterResults =  !isItcDashboardPage ? ticketData.filter((ele) => (ele?.Status?.toLowerCase().includes(ticketStatus?.toLowerCase()))) : itcTickets.filter((ele) => (ele?.Status?.toLowerCase().includes(ticketStatus?.toLowerCase()))) 
        setFilteredTickets(filterResults)
    }

    const handleTicketSearch = (e) => {
        if(!e.target.value){
            setFilteredTickets(!isItcDashboardPage ? ticketData : itcTickets)
            return
        }

        let targetValue = e.target.value.toLowerCase()
        const filterResults =  (!isItcDashboardPage ? ticketData : itcTickets)?.filter((ele) => 
        (ele.Summary?.toLowerCase().includes(targetValue)) || 
        (ele[customer ? 'Provider_Contact' : 'Reporter_Contact']?.Full_Name?.toLowerCase().includes(targetValue)) || (ele?.custom_data?.last_activity?.toLowerCase().includes(targetValue)) || (ele.Priority?.toLowerCase().includes(targetValue)) || (ele.Status?.toLowerCase().includes(targetValue)))

        setFilteredTickets(filterResults)
    }

    useEffect(() => {
        setLoadingTickets(true)
        if(isFromActiveContracts) {
            ApiBaseHelper.get({
                url: `ticket?role=${tech ? 'technician' : 'customer'}&contract=${contractId}`, 
                headers: {
                    Authorization: 'Bearer ' + user?.token
                }
            }).then((res) => {
                const sortedTicketData =  [...res?.data.data]
                if(!sortedTicketData?.length) {
                    setShowDisclaimer(true)
                }
                sortedTicketData?.sort((a, b) => {
                    const dateA = new Date(a.Active_Date_Time);
                    const dateB = new Date(b.Active_Date_Time);
                    return dateB - dateA;
                });
                setTicketData(sortedTicketData)
                setLoadingTickets(false)
            }).catch((error) => {
                console.error(error)
                setLoadingTickets(false)
            })
        } else {
            const repository = new TicketListingRepository(user?.token);
            repository.getTickets(role).then((res) => {
                const sortedTicketData =  [...res?.data.data]
                sortedTicketData?.sort((a, b) => moment(b.CreatedDate).local().diff(moment(a.CreatedDate).local()))
                setTicketData(sortedTicketData)
                setLoadingTickets(false)
            }).catch((error) => {
                console.error(error)
                setLoadingTickets(false)
            })
        }
    }, [])

    useEffect(() => {
        if(!isItcDashboardPage){
            setFilteredTickets(ticketData)
        }
    }, [ticketData, isItcDashboardPage])

    useEffect(() => {
        filterByStatus()
    }, [ticketStatus, ticketData, itcTickets])

    useEffect(() => {
        if(isItcDashboardPage){
            setFilteredTickets(itcTickets)
        }
    }, [isItcDashboardPage])

    // useEffect(() => {
    //     if(tech){
    //         ticketTypes.filter((item) => item.id !== "new")
    //     }
    // }, [tech])
   
    useEffect(() => {
        if(contractId){
            ApiBaseHelper.get({
                url: `contract/${contractId}`,
                headers: {
                    Authorization: 'Bearer ' + user?.token
                }
            }).then((res) => {
                setContractData(res?.data?.data[0])
            }).catch((error) => {
                console.error(error)
            })  
        }
    }, [ticketData])

    useEffect(() => {
        if(ticketQueryParam){
            console.log(ticketQueryParam)
            setTicketStatus(ticketQueryParam)
        }
    }, [ticketQueryParam])

    const ticketColumns =  [
        {
            title: 'Ticket Summary',
            dataIndex: 'Summary',
            key: 'Summary',
            width:"20%",
            ellipsis:true,
            render:(_, record) => (
                <Text ellipsis={true} style={{color:`${isFromDashboard ? 'rgba(0, 0, 0, 0.85)' : '#F04A22'}`, cursor:"pointer"}}>{capitalizeFirstLetter(record.Summary)}</Text>
            ),
            sorter: {
                compare: (a, b) => a.Summary.localeCompare(b.Summary),
            },
        },
        {
            title: 'Contract Description',
            dataIndex: 'Contract_Description',
            key: 'Contract_Description',
            width:"20%",
            ellipsis:true,
            render:(_, record) => (
                <Text ellipsis={true}>{capitalizeFirstLetter(record?.Contract?.Description)}</Text>
            ),
            sorter: {
                compare: (a, b) => a?.Contract?.Description.localeCompare(b?.Contract?.Description),
            },
        },
        {
            title: customer ? 'Technician' : ((tech &&  contractData?.ITC || isItcDashboardPage) ) ? "Assigned By" : 'Customer',
            dataIndex: customer ? 'Provider_Contact' : 'Reporter_Contact',
            key: customer ? 'Provider_Contact' : 'Reporter_Contact',
            width:"18%",
            render:(_, record) => {
                return record[customer ? 'Provider_Contact' : 'Reporter_Contact']?.id ? (
                    <div
                    className={`ticket-tech-details ${customer ? 'cursor-pointer' : ''}`}
                    onClick={(e) => {
                        if(customer){ 
                            e.stopPropagation()
                            history?.push(ROUTES.TECH_PROFILE_WITH_ID.replace(':id', record['Provider_Contact']?.id))

                        }
                        
                    }
                    }
                >
                    <Avatar size={'small'}  src={record[customer ? 'Provider_Contact' : 'Reporter_Contact']?.imgUrl} className='ticket-avatar'>
                        {`${record[customer ? 'Provider_Contact' : 'Reporter_Contact']?.Full_Name?.split(" ")?.[0]?.[0].toUpperCase()}`}
                    </Avatar> 
                    <div>
                        {
                            <div
                                style={{color:"#000000"}}
                            >
                                {capitalizeFirstWordOfEveryLetter(record[customer ? 'Provider_Contact' : 'Reporter_Contact']?.Full_Name)}
                            </div> 
                        }
                    </div>
                </div>
                ) : (
                    <div className='ticket-tech-details'>
                        <Avatar size={22} icon={<UserOutlined />} />
                        <div style={{color:"#000000"}}>
                            Unassigned
                        </div> 
                    </div>
                )
            },
             sorter: {
                compare: (a, b) => a[customer ? 'Provider_Contact' : 'Reporter_Contact']?.Full_Name?.localeCompare(b[customer ? 'Provider_Contact' : 'Reporter_Contact']?.Full_Name),
            },
        },
        {
            title: 'Priority',
            dataIndex: 'Priority',
            key: 'Priority',
            render:(_, record) => <Text style={{color:priorityTheme[record.Priority]}}>{record.Priority}</Text>, 
            sorter: {
                compare: (a, b) => a.Priority.localeCompare(b.Priority),
            },
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            render:(_, record) => <Space>
                <Tag>{record?.Status}</Tag>
                    {
                        record?.Status === "Pending" && 
                        <Tooltip 
                            title="The pending status is due to a paused contract, restricting any activity until the contract is resumed. "
                            placement="bottomLeft"
                            color='#fff'
                            overlayInnerStyle={{color:"#373737", fontSize:14}}
                            arrowPointAtCenter={true}
                        >
                            <InfoCircleOutlined  className='d-flex align-items-center'/>
                        </Tooltip> 
                    }
                </Space>,
            sorter: {
                compare: (a, b) => a.Status.localeCompare(b.Status),
            },
        },
        {
            title: 'Latest Activity',
            dataIndex: 'custom_data',
            key: 'custom_data',
            width:"20%",
            ellipsis:true,
            render:(_, record) => (
                <Text style={{color:"#000000"}}>{capitalizeFirstLetter(record?.custom_data?.last_activity)}</Text>
            ),
            sorter: {
                compare: (a, b) => a?.custom_data?.last_activity.localeCompare(b?.custom_data?.last_activity),
            },
        },
    ];


    return (
            <div>
                {
                   !isFromDashboard && 
                    <>
                        <PageTitle
                            style={{marginBottom:10}} 
                            title={isFromActiveContracts ? 'Assigned Tickets' : 'Tickets'} 
                            extra={((!!customer && !isContractPaused && !isContractTerminated && customer?.verified !== "false" && !canReadPageOnly(customer?.role)) || (tech && contractData?.ITC && !isContractPaused && !isContractTerminated)) ? (
                                <Button className='white-btn' onClick={handleNewSupportTicketButtonClick}> + New Ticket</Button>) : (customer?.verified === "false" && !canReadPageOnly(customer?.role)) && <Tooltip title="You need to verify your email before you can create a ticket"><InfoCircleOutlined className='cursor-pointer' style={{position:"relative", top:4, left:-10}} /></Tooltip>
                            }
                            search={ <SearchInput
                                placeholder="Search Tickets"
                                onChange={(e) => handleTicketSearch(e)}
                            />}
                        />
                       { 
                            !loadingTickets &&
                            <div className='ticket-filter-section'>
                                <div className='ticket-type-list'>
                                    {
                                        ticketTypes.filter((type) => (tech) ? type.id !== "new" : type).map((item) => (
                                            <Tooltip
                                                key={item?.id}
                                                title={item.tooltip}
                                            >
                                                <div
                                                    key={item?.id}
                                                    className={`contract-type-item ${item?.id == ticketStatus && 'contract-type-item-active'}`}
                                                    onClick={() => setTicketStatus(item?.id)}
                                                >
                                                    {item.ticket_status} ({
                                                        !isItcDashboardPage ? 
                                                        ticketData.filter((ele) => (ele?.Status?.toLowerCase().includes(item?.id)))?.length :
                                                        itcTickets.filter((ele) => (ele?.Status?.toLowerCase().includes(item?.id)))?.length
                                                    })
                                                </div>
                                            </Tooltip>
                                        ))
                                    }
                                </div>
                            </div>
                        }
                    </>
                }
                <Row>
                    <Col xs={0} lg={24} xl={24}>
                        {
                            <CustomRenderEmpty
                                description={`No ${ticketStatus === "all" ? "" : ticketStatus} support tickets found in your contract. ${ (customer?.verified === "false") ? 'You need to verify your email before you can create a ticket' : ''}`} 
                                extra={((!!customer && !isContractPaused && !isContractTerminated && customer?.verified !== "false" &&  !canReadPageOnly(customer?.role)) || (tech && contractData?.ITC && !isContractPaused && !isContractTerminated)) ? (
                                        <Button onClick={handleNewSupportTicketButtonClick}> 
                                            + Create ticket
                                        </Button>
                                    ) : null
                                }
                            >
                                <Table 
                                    loading={loadingTickets} 
                                    dataSource={isFromDashboard ? [...filteredTickets?.filter((item) => item.Status === 'Open').slice(0, 4)] : filteredTickets} 
                                    columns={ticketColumns}
                                    pagination={isFromDashboard ? false : true}
                                    onRow={(record) => {
                                        return {
                                            onClick: () => history?.push(ROUTES.TICKET_DETAILS.replace(':ticketId', record.id) + `?from=${isFromActiveContracts ? 'contracts' : 'desk'}`)
                                        };
                                    }}
                                />  
                            </CustomRenderEmpty>
                        }     
                    </Col>
                    <Col xs={24} lg={0} xl={0}>
                        {
                            loadingTickets ? <LoadingCards/> : 
                            <CustomRenderEmpty 
                                description={`No ${ticketStatus === "all" ? "" : ticketStatus} support tickets found in your contract. ${ (customer?.verified === "false") ? 'You need to verify your email before you can create a ticket' : ''}`} 
                                extra={((!!customer && !isContractPaused && !isContractTerminated && customer?.verified !== "false" && !canReadPageOnly(customer?.role)) || (contractData?.ITC)) ? (
                                    <Button onClick={handleNewSupportTicketButtonClick}> 
                                        + Create ticket
                                    </Button>
                                ) : null
                            }
                            > 
                                <List
                                    grid={{
                                        gutter: 16,
                                        xs: 1,
                                        sm: 2,
                                        md: 2,
                                        lg: 3,
                                        xl: 3,
                                        xxl: 3
                                    }}
                                    className=''
                                    dataSource={isFromDashboard ? [...filteredTickets.slice(0, 4)] : filteredTickets}
                                    pagination={isFromDashboard ? false : { pageSize: 10 }}
                                    renderItem={item => (
                                        <List.Item>
                                            <Card
                                                title={
                                                    item[customer ? 'Provider_Contact' : 'Reporter_Contact']?.id ? (
                                                        <div
                                                        className={`ticket-tech-details ${customer ? 'cursor-pointer' : ''}`}
                                                        onClick={() => {
                                                            if(customer) history?.push(ROUTES.TECH_PROFILE_WITH_ID.replace(':id', item['Provider_Contact']?.id))
                                                            
                                                        }
                                                        }
                                                    >
                                                        <Avatar src={item[customer ? 'Provider_Contact' : 'Reporter_Contact']?.imgUrl} className='ticket-avatar'>
                                                            {`${item[customer ? 'Provider_Contact' : 'Reporter_Contact']?.Full_Name?.split(" ")?.[0]?.[0].toUpperCase()}`}
                                                        </Avatar> 
                                                        <div>
                                                            {
                                                                <div
                                                                    style={{color:"#000000"}}
                                                                >
                                                                    {capitalizeFirstWordOfEveryLetter(nameFormat(item[customer ? 'Provider_Contact' : 'Reporter_Contact']?.Full_Name))}
                                                                </div> 
                                                            }
                                                        </div>
                                                    </div>
                                                    ) : (
                                                        <div className='ticket-tech-details'>
                                                            <Avatar icon={<UserOutlined />} />
                                                            <div style={{color:"#000000"}}>
                                                                Unassigned
                                                            </div> 
                                                        </div>
                                                    )
                                                } 
                                            extra={
                                                <Space>
                                                    <Tag>{item?.Status}</Tag>
                                                    {
                                                        item?.Status === "Pending" && 
                                                        <Tooltip 
                                                            title="The pending status is due to a paused contract, restricting any activity until the contract is resumed. "
                                                            placement="bottomLeft"
                                                            color='#fff'
                                                            overlayInnerStyle={{color:"#373737", fontSize:14}}
                                                            arrowPointAtCenter={true}
                                                        >
                                                            <InfoCircleOutlined  className='d-flex align-items-center'/>
                                                        </Tooltip> 
                                                    }
                                                </Space>
                                                }
                                            >
                                                <Paragraph
                                                    ellipsis
                                                    style={{color:"rgba(0, 0, 0, 0.85)"}}
                                                >
                                                    {capitalizeFirstLetter(item.Summary)}
                                                </Paragraph> 
                                                <Paragraph
                                                    ellipsis
                                                    style={{color:"rgba(0, 0, 0, 0.45)", fontStyle:"italic", fontSize:14}}
                                                >
                                                    {capitalizeFirstLetter(item?.Contract?.Description)}
                                                </Paragraph> 
                                                <div className='ticket-card-footer'>
                                                    <div>
                                                        <Text style={{color:priorityTheme[item.Priority]}}>{item.Priority}</Text>
                                                    </div>
                                                    <Space align='center'>
                                                        <Button
                                                            className='borderless-btn'
                                                            onClick={() => history?.push(ROUTES.TICKET_DETAILS.replace(':ticketId', item.id)) + `?from=${isFromActiveContracts ? 'contracts' : 'desk'}`}
                                                        >
                                                            View Ticket
                                                        </Button>
                                                        <img src={RightArrow} alt="right arrow" />
                                                    </Space>
                                                </div>
                                            </Card>
                                        </List.Item>
                                    )}
                                />  
                            </CustomRenderEmpty>    
                        }
                    </Col>
                </Row>
            </div>
    )
}

const LoadingCards = () => {
    return(
        <Row gutter={[20, 20]}>
        { [...Array(10).keys()].map((item) => {
            return(
                <Col key={item} xs={24} sm={24} md={12} lg={8} xl={8}>
                    <Card
                        style={{
                            height: "auto",
                            width:"auto",
                        }}
                    >
                        <Skeleton />
                        <Skeleton />
                    </Card> 
                </Col>
            )
        }) }
     </Row>
    )
}

export default AssignedTicketsContent