import React, { useEffect, useState } from 'react'
import { Avatar, Badge, Button, Input, Layout, List, Popover, Space, Table, Tag, Tooltip, Typography, notification } from 'antd'
import MetaTags from 'react-meta-tags'
import PageHeaderWrapper from '../../../../components/page-header-breadcrumbs'
import { META_DESCRIPTION, SECONDARY_COLOR } from '../../../../../utils/constants'
import { ApiBaseHelper, BASE_URL } from '../../../../../utils/api-base-helper'
import { useSelector } from 'react-redux'
import PageFallback from '../../../../components/page-fallback'
import CustomRenderEmpty from '../../../../components/custom-render-empty'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ROUTES from '../../../../../utils/routes'
import useWindowSize from '../../../../../hooks/useWindowSize'
import { addSubdomainToBaseUrl, capitalizeFirstLetter, getTableTimeFormat, stripHtml, truncateText } from '../../../../../utils/helper'
import ActionIcon from '../../../../../assets/images/action-icon.svg'	

import "./assets/css/styles.scss"
import { CloseCircleOutlined, DollarOutlined, EditOutlined, EyeOutlined, FundOutlined, InfoCircleOutlined, LikeOutlined, LoadingOutlined, LogoutOutlined, MessageOutlined, UsergroupAddOutlined } from '@ant-design/icons'
import ActionModal from '../create-itc/components/delete-modal'
import { decode } from 'html-entities'
import { FirebaseHelper, FirestoreHelper } from '../../../../../utils/firebase-helper'
import Axios from 'axios'
import { ContractRepository } from '../../../common/private/contracts/contract-details/repository'

const { Content } = Layout
const {Search} = Input
const { Title, Paragraph, Text } = Typography


const profileTypes = [
    {
        id: "owner",
        profile_status: "Owner",
        tooltip: "These ITSC profiles are owned by you"
    },
    {
        id: "member",
        profile_status: "Member",
        tooltip: "You are a member of these ITSC profiles"
    },
   
]

const statusColor = (status) => {
    if(status === "1") return "green"
    return "red"
}

export const membershipFeeContent = (membership_fee, fee_type) => {
    if(membership_fee !== "0" && fee_type !== "free"){
        return `$${Number(membership_fee)}${fee_type === "one-time" ? " one time fee" : fee_type === "monthly" ? "/month" : ""}`
    }else if(membership_fee  && fee_type === "free"){
        return "Free"
    }else{
        return "N/A"
    }
}


const ItcProfiles = () => {
    const tech = useSelector(state => state.tech)
    const history = useHistory()
    const { windowWidth } = useWindowSize()
    const [itcProfiles, setItcProfiles] = useState([])
    const [memberItcProfiles, setMemberItcProfiles] = useState([])
    const [filteredItcProfiles, setFilteredItcProfiles] = useState([])
    const [loadingProfiles, setLoadingProfiles] = useState(false)
    const [loadingMemberProfiles, setLoadingMemberProfiles] = useState(false)
    const [ profileStatus, setProfileStatus ] = useState("owner")
    const [disablingItc, setDisablingItc] = useState(false)
    const [itcDetails, setItcDetails] = useState({})
    const [disableModal, setDisableModal] = useState(false)
    const [groupConversation, setGroupConversation] = useState(null)
    const [loadingConversationId, setLoadingConversationId] = useState(false)
    const [activeMemberDetails, setActiveMemberDetails] = useState({})
    const [leavingItc, setLeavingItc] = useState(false)
    const [leaveModal, setLeaveModal] = useState(false)
    const [loadingConversations, setLoadingConversations] = useState(false)
    const [loadingActiveMemberDetails, setLoadingActiveMemberDetails] = useState(false)
    const [leaveAction, setLeaveAction] = useState(false)
    const [itcContracts, setItcContracts] = useState([])
    const [loadingContracts, setLoadingContracts] = useState(false)
    const [warningModal, setWarningModal] = useState(false)

    const repository = new ContractRepository(tech?.token)

    const getAllItc = () => {
        setLoadingProfiles(true)
        ApiBaseHelper.get({
            url: `itc`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setItcProfiles(res?.data?.data?.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)))
            setLoadingProfiles(false)
        }).catch((error) => {
            console.error(error)
            setLoadingProfiles(false)
        })       
    }

    // Get all ITSC profiles that the technician has an active membership with
    const getMemberItc = () => {
        setLoadingMemberProfiles(true)
        ApiBaseHelper.get({
            url: `itc/search/${tech?.id}?status=Active`,
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setMemberItcProfiles(res?.data?.data?.filter((item) => item !== null && item.Owner_Contact !== tech?.id).sort((a, b) => new Date(b?.CreatedDate) - new Date(a?.CreatedDate)))
            setLoadingMemberProfiles(false)
        }).catch((error) => {
            console.error(error)
            setLoadingMemberProfiles(false)
        })
    }

    // Get group chat id and route to group chat
    const goToGroupChat = async (record) => {
        setLoadingConversationId(true)
        await FirebaseHelper.login();
        const participantsResponse = await FirestoreHelper.getAllGroupConversations();
        const groupConversations = [];
        participantsResponse.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          if (item.group && item.participants?.length) {
            groupConversations.push({ ...item});
          }
        });
        const groupConversationId = groupConversations.find((c) => c.group.toLowerCase() === (record?.Company_Name?.toLowerCase()))?.id
        setLoadingConversationId(false)
        groupConversationId ? history.push(`${ROUTES.INBOX}?id=${groupConversationId}`) : history.push(`${ROUTES.INBOX}`)
    }


    // Function to get and set group conversations for the commpany
    const getAndSetCoversation = async () => {
        setLoadingConversations(true)
        await FirebaseHelper.login();
        const participantsResponse = await FirestoreHelper.getAllGroupConversations();
        const groupConversations = [];
        participantsResponse.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          if (item.group && item.participants?.length) {
            groupConversations.push({ ...item});
          }
        });
        setGroupConversation(groupConversations.find((c) => c.group.toLowerCase() === (itcDetails?.Company_Name?.toLowerCase())))
        setLoadingConversations(false)
        if(leaveAction){ 
            setLeaveModal(true)
        }
    }


    // Search ITC profiles
    const handleItcSearch = (e) => {
        const value = e.target.value.toLowerCase()
        const filteredData = itcProfiles.filter((item) => {
            return item?.Company_Name?.toLowerCase().includes(value) 
        })
        setFilteredItcProfiles(filteredData)
    }

    // fetch contracts to be paused on itc disable
    const fetchItcContracts = () => {
        setLoadingContracts(true)
        ApiBaseHelper.get({
            url: `contract/itc-contracts?itc=${itcDetails.id}`, headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setItcContracts(res?.data.data.filter((ele) => ele.Status === "Active"))
            res?.data.data.filter((ele) => ele.Status === "Active").length > 0 ? setWarningModal(true) : setDisableModal(true)
            setLoadingContracts(false)
        }).catch((error) => {
            console.error(error)
            setLoadingContracts(false)
        })       
    }
    
    // const pauseContracts = () => {
    //     itcContracts.map((contract) => {
    //         return(
    //             repository.pauseContract(contract.id, {
    //                 Reason: `${itcDetails.Company_Name} ITSC has been disabled`,
    //                 Technician_Contact: itcDetails?.Owner_Contact,
    //             }).then(async (res) => {
    //                 if(res.data?.status === 'success') {
    //                     console.log("Contracts paused")
    //                 }
    //             }).catch(error => {
    //                 console.error(error)
    //             })
    //         )
    //     })
    // }

    // const resumeContracts = () => {
    //     itcContracts.map((contract) => {
    //         return(
    //             repository.resumeContract(contract.id, {
    //                 Technician_Contact: itcDetails?.Owner_Contact,
    //             }).then(async (res) => {
    //                 if(res.data?.status === 'success') {
    //                     console.log("Contracts resumed")
    //                 }
    //             }).catch(error => {
    //                 console.error(error)
    //             })
    //         )
    //     })
    // }

    // Disable or enable ITC profile by owner
    const handleItcDisable = async () => {
        setDisablingItc(true)
        // if(itcDetails?.Active === "1"){
        //     await pauseContracts()
        // }
        ApiBaseHelper.put({
            url: `itc/${itcDetails?.id}`,	
            headers: {
                Authorization: 'Bearer ' + tech['token'],
            },
            payload: {
                Active: itcDetails?.Active === "1" ? "false" : "true"
            }
        }).then((res) => {
            setDisablingItc(false)
            setDisableModal(false)
            notification.success({
                message: 'TecMe',
                description: itcDetails?.Active === "1" ? 'ITSC profile disabled successfully' : "ITSC profile enabled successfully"
            })
            getAllItc()
        }).catch((err) => {
            setDisablingItc(false)
            console.log(err)
            notification.error({
                message: 'TecMe',
                description:  itcDetails?.Active === "1" ?  'An error occurred while disabling ITSC profile' : "An error occurred while enabling ITSC profile"
            })
        })
    }

    // get active ITC member details
    const getItcMemberDetails = () => {
        setLoadingActiveMemberDetails(true)
        ApiBaseHelper.get({
            url: `itc/member/status/${itcDetails?.id}/${tech.id}?status=Active`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setActiveMemberDetails(res?.data?.data[0])
            setLoadingActiveMemberDetails(false)
        }).catch((error) => {
            console.error(error)
            setLoadingActiveMemberDetails(false)
        })       
    }

    // Remove member from group conversation after leaving ITSC
    const removeMemberFromGroupConversation = async () => {
        const newParticipants =
          groupConversation.participants?.filter(
            (p) => p !== tech?.id
          );
        await FirestoreHelper.updateGroupConversationMembers({
          participants: newParticipants,
          conversation_id: groupConversation.id,
        });
        try {
          const payload = {
            Title: groupConversation.group,
            Participant: tech?.id,
          }
          await Axios.delete(`${BASE_URL}/conversation/${groupConversation.id}/participant`, {
            headers: {
              Authorization: "Bearer " + tech?.token,
            },
            data: {...payload}
          });
        } catch (error) {
          console.error(error)                
        }
        setLeavingItc(false)
        notification.success({
            message: 'TecMe',
            description: 'Left ITSC successfully'
        })
        getMemberItc()
        setLeaveModal(false)
    }

    // Leave ITSC
    const handleLeaveItc = () => {
        setLeavingItc(true)
        ApiBaseHelper.put({
            url: `itc/member/${activeMemberDetails?.id}?type=leave`,	
            headers: {
                Authorization: 'Bearer ' + tech?.token,
            },
            payload: {
                Status: 'Inactive'
            }
        }).then((res) => {
            if(groupConversation){
               removeMemberFromGroupConversation()
            }else{
                setLeavingItc(false)
                notification.success({
                    message: 'TecMe',
                    description: 'Left ITSC successfully'
                })
                setLeaveModal(false)
                getMemberItc()
            }   
        }).catch((err) => {
            notification.error({
                message: 'TecMe',
                description: 'An error occurred'
            })
            setLeavingItc(false)
            console.log(err)
        })
    }

    useEffect(() => {
        getAllItc()
    }, [])

    useEffect(() => {
        if(tech?.id){
            getMemberItc() 
        }
    }, [tech])

    useEffect(() => {
        setFilteredItcProfiles(itcProfiles)
    }, [itcProfiles])

    useEffect(() => {
        if(profileStatus === "owner") {
            setFilteredItcProfiles(itcProfiles)
        } else {    
            setFilteredItcProfiles(memberItcProfiles)
        }
    }, [profileStatus])

    useEffect(() => {
        if(itcProfiles.length === 0 && memberItcProfiles.length > 0){
            setProfileStatus("member")
        }else{
            setProfileStatus("owner")
        }
    }, [itcProfiles, memberItcProfiles])

    useEffect(() => {
        if(itcDetails?.Company_Name){
            getAndSetCoversation()
        }
    }, [itcDetails])

    useEffect(() => {
        if(itcDetails?.id){
            getItcMemberDetails()
            fetchItcContracts()
        }
    }, [itcDetails])

    
    const itcPopoverContent = (record) => {
        if(profileStatus === "owner"){
        return(
            <div className="itc-profile-popover-content"> 
                <Space className='popover-action-item' align='center'
                    onClick={(e) => {
                        history.push(ROUTES.ITC_DASHBOARD.replace(':id', record.id))  
                        e.stopPropagation()
                    }}>
                    <FundOutlined className='popover-action-icon'/>
                    <Paragraph style={{marginBottom:0}}>Visit Dashboard</Paragraph> 
                </Space>  
                <Space className='popover-action-item' 
                    align='center' 
                    onClick={(e) =>{ 
                        history?.push(ROUTES.ITC_HOME.replace(':subdomain', record?.Subdomain))
                        e.stopPropagation()
                    }}>
                    <EyeOutlined className='popover-action-icon' />
                    <Paragraph style={{marginBottom:0}}>View ITSC webpage</Paragraph> 
                </Space>
                <Space className='popover-action-item' align='center'
                    onClick={(e) => {
                        history.push(ROUTES.ITC_PROFILE.replace(':id', record.id))  
                        e.stopPropagation()
                    }}>
                    <EditOutlined className='popover-action-icon'/>
                    <Paragraph style={{marginBottom:0}}>Edit ITSC</Paragraph> 
                </Space>
                <Space className='popover-action-item' align='center'
                    onClick={(e) => {
                        history.push(ROUTES.ITC_TECH_TEAM.replace(':id', record.id))  
                        e.stopPropagation()
                    }}>
                    <UsergroupAddOutlined className='popover-action-icon'/>
                    <Paragraph style={{marginBottom:0}}>Manage Team</Paragraph> 
                </Space>
                <Space className='popover-action-item' 
                    align='center' 
                    onClick={(e) =>{ 
                        e.stopPropagation()
                        setItcDetails(record)
                        
                    }}>
                        {
                            record?.Active === "1" ?
                            <>
                                {
                                    loadingContracts ? <LoadingOutlined className='popover-action-icon'/> :
                                    <CloseCircleOutlined className='popover-action-icon'/>
                                }
                                <Paragraph style={{marginBottom:0}}>Disable ITSC</Paragraph> 
                            </> :
                            <>
                            {
                                loadingContracts ? <LoadingOutlined className='popover-action-icon'/> :
                                <LikeOutlined className='popover-action-icon'/>
                            }
                                <Paragraph style={{marginBottom:0}}>Activate ITSC</Paragraph>
                            </>
                        }
                        
                </Space>
            </div>
        )}else{
            return(
                <div className="itc-profile-popover-content"> 
                    {/* {
                        Number(record?.Membership_Fee) > 0 &&
                        <Space className='popover-action-item' align='center'
                            onClick={(e) => {
                                // history.push(ROUTES.ITC_DASHBOARD.replace(':id', record.id))  
                                e.stopPropagation()
                            }}>
                            <DollarOutlined className='popover-action-icon'/>
                            <Paragraph style={{marginBottom:0}}>Make Payment</Paragraph> 
                        </Space>  
                    } */}
                     <Space className='popover-action-item' align='center'
                        onClick={(e) => {
                            history.push(ROUTES.ITC_DASHBOARD.replace(':id', record.id))  
                            e.stopPropagation()
                        }}>
                        <FundOutlined className='popover-action-icon'/>
                        <Paragraph style={{marginBottom:0}}>View Dashboard</Paragraph> 
                    </Space>    
                    <Space className='popover-action-item' 
                        align='center' 
                        onClick={(e) =>{ 
                            e.stopPropagation()
                            setItcDetails(record)
                            setLeaveAction(true)
                        }}>
                        {
                            (loadingConversations) ? 
                            <LoadingOutlined className='popover-action-icon'/> :
                            <LogoutOutlined className='popover-action-icon' />
                        }
                        <Paragraph style={{marginBottom:0}}>Leave ITSC</Paragraph> 
                    </Space>
                    <Space className='popover-action-item' 
                        align='center' 
                        onClick={(e) =>{ 
                            e.stopPropagation()
                            goToGroupChat(record)
                        }}>
                        {loadingConversationId ? <LoadingOutlined className='popover-action-icon'/> : <MessageOutlined className='popover-action-icon' />}
                        <Paragraph style={{marginBottom:0}}>Go to chat</Paragraph> 
                    </Space>
                </div>
            )
        }
    }

    const itcProfilesColumns = [
        {
            title: 'Profile Name',
            dataIndex: 'company-name',
            key: 'company-name',
            width: '15%',
            render:(_, record) => {
                return(
                    <Space
                        onClick={(e) => {
                            history?.push(ROUTES.ITC_HOME.replace(':subdomain', record?.Subdomain))
                            e.stopPropagation()
                        }}
                    >
                        <Avatar 
                            size={32} 
                            src={record?.Brand}
                            shape='circle'
                            style={{color:SECONDARY_COLOR, fontSize:14}}
                        >
                            {record?.Company_Name?.charAt(0)?.toUpperCase() || 'T'}
                        </Avatar>
                        <Paragraph style={{margin:0, fontWeight:500, fontSize:14, color:"#F04A22"}}>{capitalizeFirstLetter(record?.Company_Name)}</Paragraph>
                    </Space>
                )
            },
            sorter: {
                compare: (a, b) => {
                    if(a?.Company_Name) return a?.Company_Name.localeCompare(b?.Company_Name)
                },
            },
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render:(_, record) => {
                return(
                    <Paragraph style={{margin:0, fontSize:14}}>{capitalizeFirstLetter(truncateText(decode(stripHtml(record?.Description)), 40))}</Paragraph>
                )
            },
            sorter: {
                compare: (a, b) => {
                    if(a?.Description) return a?.Description.localeCompare(b?.Description)
                },
            },
        },
        {
            title: <Space>
                <Text>Custom Domain </Text>
                <Tooltip placement="top" title={"These links direct you away from TecMe to your personalized webpage. You can copy and share these links publicly"}>
                    <InfoCircleOutlined style={{position:"relative", top:2}}/>
                </Tooltip>
            </Space>,
            dataIndex: 'custom-domain',
            key: 'custom-domain',
            width: '20%',
            render:(_, record) => {
                return(
                    <Paragraph 
                        className='subdomain-link'	
                        copyable={{ text: addSubdomainToBaseUrl(record?.Subdomain)}}
                        onClick={(e) => {
                            e.stopPropagation()
                            window.open(addSubdomainToBaseUrl(record?.Subdomain), '_blank')
                        }}  
                    >
                        {addSubdomainToBaseUrl(record?.Subdomain)}
                    </Paragraph>
                )
            },
            sorter: {
                compare: (a, b) => {
                    if(a?.Subdomain) return a?.Subdomain.localeCompare(b?.Subdomain)
                },
            },
            showSorterTooltip: false
        },
        {
            title: 'Membership Fee',
            dataIndex: 'membership-fee',
            key: 'membership-fee',
            render:(_, record) => {
                return(
                    <Paragraph style={{margin:0, fontSize:14}}>{membershipFeeContent(record?.Membership_Fee, record?.Fee_Type)}</Paragraph>
                )
            },
            sorter: {
                compare: (a, b) => {
                    if(a?.Membership_Fee) return a?.Membership_Fee.localeCompare(b?.Membership_Fee)
                },
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render:(_, record) => {
                return(
                    <Tag color={statusColor(record?.Active)}>{record?.Active === "1" ? "Active" : "Disabled"}</Tag>
                )
            },
            sorter: {
                compare: (a, b) => {
                    if(a?.Active) return a?.Active.localeCompare(b?.Active)
                },
            },
        },
        {
            title: 'Created On',
            dataIndex: 'date',
            key: 'date',
            render:(_, record) => {
                return(
                    <Paragraph style={{margin:0, fontSize:14}}>{getTableTimeFormat(record?.CreatedDate)}</Paragraph>
                )
            },
            sorter: {
                compare: (a, b) => {
                    if(a?.CreatedDate) return new Date(a?.CreatedDate) - new Date(b?.CreatedDate)
                },
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Popover content={itcPopoverContent(record)}  trigger="click" placement='bottomLeft' overlayClassName="itc-profile-popover" arrowPointAtCenter>
                    <img src={ActionIcon} alt="action icon" onClick={
                        (e) => {
                            e.stopPropagation()
                        }}/>  
                </Popover>
            ),
        },
    ];

    if(loadingProfiles){
        return <PageFallback />
    }

    return (
        <>
            <MetaTags>
                <title>IT Services Company Profiles - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <PageHeaderWrapper
                routes={[
                    {
                        path: ROUTES.ITC_PROFILES,
                        breadcrumbName: 'ITSC Profiles',
                    },
                ]}
            />
            <Content className="main">
                <div className='page'>
                    <div className="auth-container">
                    <div className="auth-page-ctn itc-profiles-ctn">
                            {/* <div className="itc-profiles-header">
                                <Title level={3}>ITC Profiles</Title>
                            </div> */}
                            <div className="itc-profiles-table"> 
                                <div className="itc-profiles-table-header">
                                    <Space size={20}>
                                        <Title level={3} style={{marginBottom:0}}>ITSC Profiles</Title>
                                        <Button onClick={() => history.push(ROUTES.CREATE_ITC)}> + Create ITSC</Button>
                                    </Space>
                                    {
                                        itcProfiles?.length > 0 &&
                                        <Search 
                                            placeholder="Search by profile name" className="search-itc-profiles" 
                                            onChange={handleItcSearch}
                                            size='large'
                                        />
                                    }
                                </div>
                                <>
                                { 
                                    (!loadingMemberProfiles && !loadingProfiles) &&
                                    <div className='profile-filter-section'>
                                        <div className='profile-type-list'>
                                            {
                                                profileTypes.map((item) => (
                                                    <Tooltip
                                                        key={item?.id}
                                                        title={item.tooltip}
                                                    >
                                                        <div
                                                            key={item?.id}
                                                            className={`profile-type-item ${item?.id == profileStatus && 'profile-type-item-active'}`}
                                                            onClick={() => setProfileStatus(item?.id)}
                                                        >
                                                            {item.profile_status}
                                                        </div>
                                                    </Tooltip>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                                </>
                                <CustomRenderEmpty
                                    description={ profileStatus === "owner" ? "You haven't created any ITSC profile yet" : "You have not been added to any ITSC yet"}
                                    extra={
                                        profileStatus === "owner" &&
                                        <Button onClick={() => history.push(ROUTES.CREATE_ITC)}>Create ITSC</Button>
                                    }
                                >
                                    { 
                                        windowWidth > 768 ? 
                                        <Table
                                            columns={itcProfilesColumns} 
                                            dataSource={filteredItcProfiles} 
                                            loading={loadingProfiles}
                                            onRow={(record) => {
                                                return {
                                                    onClick: () => history.push(ROUTES.ITC_DASHBOARD.replace(':id', record.id))  
                                                };
                                            }}
                                            pagination={{
                                                onChange: page => {
                                                    window.scrollTo({
                                                        top: 0,
                                                        behavior: 'smooth'
                                                    })
                                                },
                                            }}
                                        /> :
                                        <List
                                            dataSource={filteredItcProfiles}
                                            loading={loadingProfiles}
                                            pagination={{
                                                onChange: page => {
                                                    window.scrollTo({
                                                        top: 0,
                                                        behavior: 'smooth'
                                                    })
                                                },
                                                pageSize: 10,
                                            }}
                                            renderItem={
                                                item => {
                                                    return(
                                                    <List.Item key={item?.id}>
                                                        <div 
                                                            className="job-application-list-item"
                                                            onClick = { () => history.push(ROUTES.ITC_DASHBOARD.replace(':id', item.id))}
                                                            >
                                                            <div className="job-application-list-item-content">
                                                                <Paragraph 
                                                                    style={{marginBottom:4, fontWeight:500, fontSize:14, color:"#F04A22"}}
                                                                    onClick={(e) => {
                                                                        history?.push(ROUTES.ITC_HOME.replace(':subdomain', item?.Subdomain))
                                                                        e.stopPropagation()
                                                                    }}
                                                                >{capitalizeFirstLetter(item?.Company_Name)}</Paragraph>
                                                                <Space style={{marginBottom:10}}>
                                                                    <Tooltip placement="top" title={"This link directs you away from TecMe to your personalized webpage. You can copy and share this link publicly"}>
                                                                        <InfoCircleOutlined style={{position:"relative", top:2}}/>
                                                                    </Tooltip>
                                                                    <Paragraph 
                                                                        className='subdomain-link'	
                                                                        copyable={{ text: addSubdomainToBaseUrl(item?.Subdomain)}}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation() 
                                                                            window.open(addSubdomainToBaseUrl(item?.Subdomain), '_blank')
                                                                        }} 
                                                                        style={{marginBottom:0}} 
                                                                    >
                                                                        {addSubdomainToBaseUrl(item?.Subdomain)}
                                                                    </Paragraph>
                                                                </Space>
                                                                <Paragraph ellipsis={{rows:3}} style={{marginBottom:4, fontSize:14}}>{capitalizeFirstLetter(decode(stripHtml(item?.Description)))}</Paragraph>
                                                                <Badge status={item?.Active === "1" ? "success" : "error"} text={item?.Active === "1" ? "Active" : "Disabled"} />
                                                                <Paragraph style={{marginBottom:4, fontSize:14}}>{membershipFeeContent(item?.Membership_Fee, item?.Fee_Type)}</Paragraph>
                                                                <Paragraph style={{marginBottom:4, fontSize:14, color:"rgba(0, 0, 0, 0.45)"}}>{getTableTimeFormat(item?.CreatedDate)}</Paragraph> 
                                                            </div>  
                                                            <div className="job-application-list-item-action">
                                                            <Popover content={itcPopoverContent(item)}  trigger="click" placement='bottomLeft' overlayClassName="itc-profile-popover" arrowPointAtCenter>
                                                                <img src={ActionIcon} alt="action icon" onClick={
                                                                    (e) => {
                                                                        e.stopPropagation()
                                                                    }}/>  
                                                            </Popover>
                                                            </div>
                                                        </div>
                                                    </List.Item>
                                                )}
                                            }
                                        /> 
                                    }
                                </CustomRenderEmpty>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
            <ActionModal 
                visible={warningModal}
                handleCancel={() => setWarningModal(false)}
                handleClick={() => setWarningModal(false)}
                message={"You cannot disable your ITSC while you have ongoing active contracts. Please resolve all active contracts with your customers before attempting to disable your ITSC."}
                loading={false}
                title="Warning: Active Contracts"
                deleteText={"Ok"}
            />
            <ActionModal 
                visible={disableModal}
                handleCancel={() => setDisableModal(false)}
                handleClick={() => handleItcDisable()}
                message={itcDetails?.Active === "1" ?  "This action will disable your ITSC profile. Please make sure you want to disable the profile before proceeding." : "This action will activate your ITSC profile. Please make sure you want to activate the profile before proceeding."}
                loading={disablingItc}
                deleteText={itcDetails?.Active === "1" ? "Disable" : "Activate"}
            />
            <ActionModal
                visible={leaveModal}
                handleCancel={() => {
                    setLeaveModal(false)
                    setLeaveAction(false)
                }}
                handleClick={() => handleLeaveItc()}
                message={"This action will remove you from this ITSC. Please make sure you want to leave before proceeding."}
                loading={leavingItc}
                deleteText={"Confirm"}
            />
        </>
    )
}

export default ItcProfiles