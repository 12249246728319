// External Imports
import { useState } from 'react'
import { Modal, Typography } from 'antd'

// Internal Imports
import ReasonRadioList from './reason-radio-list'
import { nameFormat } from '../../../../../../../utils/helper'

const { Text, Title, Paragraph } = Typography

const rejectionPoints = [
    "The contract rates and requirements does not suit me.",
    "I do not have the skill set to proceed with this contract.",
    "I am not sure if I would have enough time to spend working for this contract."
]

const withdrawalPoints = [
    "I no longer need this contract.",
    "I do not find my technician capable enough.",
    "I have found another technician for this contract in more reasonable rates."
]

const RejectWithdrawContractModal = ({ visible, onOk, onCancel, type = "Reject", repository, contractId, contractDetails, getAndSetContractDetails, sendMessage, user, tech }) => {
    const [loading, setLoading] = useState(false);

    const handleOnFinish = (values) => {
        setLoading(true)
        const location = window.location.href;
        switch (type) {
            case 'Reject':
                repository.rejectProposal(contractId, {
                    ...values,
                    Customer_Contact: contractDetails?.Customer_Org?.HIPAA_Primary_Contact
                }).then(async (res) => {
                    if(res.data?.status === 'success') {
                        const techName = nameFormat(`${tech?.firstname} ${tech?.lastname}`);
                        // await sendMessage(`${techName}  has decided not to move forward with your contract proposal at this time. ${values.Reason}<br><br> To view the details, simply click on the link below:<br> ${location}`)
                        await getAndSetContractDetails()
                    }
                    setLoading(false)
                    onOk()
                }).catch(error => {
                    console.error(error)
                    setLoading(false)
                })
                break;
            case 'Withdraw':
                repository.withdrawProposal(contractId, {
                    ...values,
                    Technician_Contact: contractDetails?.Provider_Org?.HIPAA_Primary_Contact
                }).then(async (res) => {
                    if(res.data?.status === 'success') {
                        const customerName = nameFormat(`${user?.firstname} ${user?.lastname}`) 
                        // await sendMessage(`${customerName} has decided to withdraw their contract proposal. ${values.Reason}<br><br> To view the details, simply click on the link below:<br> ${location}`)
                        await getAndSetContractDetails()
                    }
                    setLoading(false)
                    onOk()
                }).catch(error => {
                    console.error(error)
                    setLoading(false)
                })
                break;
            default:
                break;
        }
    }

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            footer={false}
            centered
            className='edit-contract-modal'
        >
            <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
            <Paragraph className='my-2 disclaimer p-2'>
              {
                type === 'Reject' ? 'This action is irreversible. Please carefully consider the implications of this decision before proceeding. Are you sure you want to reject this contract proposal?' :
                    'Are you sure you want to withdraw from this contract? This action is irreversible.'
              }
            </Paragraph>
            <Paragraph strong>Please select a reason for {type === 'Reject' ? 'rejecting' : 'withdrawing'} the contract</Paragraph>
            <ReasonRadioList
                onCancel={onCancel}
                loading={loading}
                points={type === 'Reject' ? rejectionPoints : withdrawalPoints}
                name={type}
                handleOnFinish={handleOnFinish}
            />            
        </Modal>
    )
}

export default RejectWithdrawContractModal