import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal, Space, Typography } from 'antd'
import React from 'react'


const { Paragraph } = Typography

const ConfirmPopupModal = ({open, setOpen, title, sub_title, onOk, okButtonProps}) => {
  return (
    <Modal
        className='remove-member-modal'
        open={open} 
        onOk={onOk} 
        onCancel={() => setOpen(false)} 
        width={488}
        okText={"Yes"}
        okButtonProps={okButtonProps}
        cancelButtonProps={{type:'secondary'}}
        closable={false}
    >
        <Space align='top' size={16}>
            <ExclamationCircleOutlined style={{fontSize:22, color:"#FAAD14"}} />
            <div>
                <Paragraph style={{marginBottom:8, fontSize:16, fontWeight:500}}>{title}</Paragraph>
                <Paragraph style={{marginBottom:0, fontSize:14}}>{sub_title}</Paragraph>
            </div>
        </Space>
    </Modal>
  )
}

export default ConfirmPopupModal