import { Typography } from 'antd'
import React from 'react'


const {Title, Paragraph} = Typography

const CreateItcHeader = ({title, tagline, subTagline, image}) => {
  return (
    <div className='create-itc-header-section'>
        <div className="create-itc-header-ctn">
            <div className="create-itc-header-content">
                <Title level={3} className="create-itc-header-title">{title}</Title>
                <Paragraph className="create-itc-header-tagline">{tagline}</Paragraph>
                <Paragraph className="create-itc-header-subtagline">{subTagline}</Paragraph>
            </div>
            <div className="create-itc-header-img">
                <img src={image} alt="create itc header" />
            </div>
        </div>
    </div>
  )
}

export default CreateItcHeader