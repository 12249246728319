// External Imports
import MetaTags from 'react-meta-tags';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react'
import { Skeleton, Layout, Row, Col, Card, Space } from 'antd'
import 'reactjs-bottom-navigation/dist/index.css'

// Internal Imports
import { META_DESCRIPTION } from '../../../../../../utils/constants';
import DraftContract from './components/draft-contract';
import ActiveContract from './components/active-contract';
import { ContractRepository } from './repository';

// Assets Imports
import "./assets/css/index.scss"

const { Content } = Layout

export const contractStatuses = {
    Draft: 'Draft',
    Active: 'Active',
    Terminated: 'Terminated',
    Paused: 'Paused'
}

const ContractDetails = () => {
    const params = useParams();
    const contractId = params?.contractId
    const [contractDetails, setContractDetails] = useState(undefined)
    const [repository, setRepository] = useState(undefined)
    const user = useSelector(state => state.user)
    const tech = useSelector(state => state.tech)
    const token = user?.token || tech?.token
    const role = user ? 'customer' : 'technician'

    console.log(contractDetails)

    const getAndSetContractDetails = async () => {
        await repository.getContract(contractId, role).then(res => {
            const data = res?.data?.data[0]
            if(data) setContractDetails(data)
        }).catch(error => console.error(error))
    }

    useEffect(() => {
        setRepository(new ContractRepository(token))
        document.querySelector("body")?.scrollIntoView()
    }, [])

    useEffect(() => {
        if(repository) {
            getAndSetContractDetails()
        }
    }, [repository])

    const loadingSkeleton = (
        <Content className="main">
            <div className="page">
                <Row gutter={[24,24]} className='mt-2 p-3 h-100 view-edit-contract-proposal'>
                    <Col xs={24} lg={16}>
                        <Card className='h-100'>
                            <Space direction='vertical' className='w-100' size={25}>
                                <Skeleton.Avatar active size="large" />
                                <Skeleton active />
                                <Skeleton active />
                                <Skeleton active paragraph={{ rows: 6 }} />
                                <Skeleton.Button className='w-100' size='large' />
                            </Space>
                        </Card>
                    </Col>
                    <Col xs={24} lg={8}>
                        <Card className='h-100'>
                            <Space direction='vertical' className='w-100' size={25}>
                                <Skeleton active />
                                <Skeleton active />
                                <Skeleton active />
                                <Skeleton active />
                                <Skeleton.Button active className='w-100' size='large' />
                            </Space>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Content>
    )

    const getContractDetails = () => {
        switch (contractDetails?.Status) {
            case contractStatuses.Draft:
                return <DraftContract getAndSetContractDetails={getAndSetContractDetails} contractDetails={contractDetails} repository={repository} />
            case contractStatuses.Active:
            case contractStatuses.Terminated:
            case contractStatuses.Paused:
                return <ActiveContract isFromActiveContracts contractDetails={contractDetails} repository={repository} getAndSetContractDetails={getAndSetContractDetails} />
            default:
                return loadingSkeleton
        }
    }

    return (
        <>
            <MetaTags>
                <title> Contract {contractDetails?.Status === contractStatuses.Draft ? 'Proposal' : 'Details'} | TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            {getContractDetails()}
        </>
    )
}

export default ContractDetails