import React, { useEffect, useState } from 'react'
import { MetaTags } from 'react-meta-tags';
import { META_DESCRIPTION, SECONDARY_COLOR } from '../../../../../utils/constants';
import ROUTES from '../../../../../utils/routes';
import { Layout, Typography, Input, Table, List, Popover, Space, Avatar, Tag, Badge, Tooltip, notification } from 'antd';
import useWindowSize from '../../../../../hooks/useWindowSize';
import CustomRenderEmpty from '../../../../components/custom-render-empty';
import { addSubdomainToBaseUrl, capitalizeFirstLetter, getTableTimeFormat, stripHtml, truncateText } from '../../../../../utils/helper';
import ActionIcon from '../../../../../assets/images/action-icon.svg'	
import { useHistory } from 'react-router-dom';
import { CloseCircleOutlined, EditOutlined, ExportOutlined, EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import PageHeaderWrapper from '../../../../components/page-header-breadcrumbs';
import { membershipFeeContent } from '../itc-profiles';
import './assets/css/styles.scss'
import { useSelector } from 'react-redux';
import { ApiBaseHelper } from '../../../../../utils/api-base-helper';
import { decode } from 'html-entities';
import ActionModal from '../create-itc/components/delete-modal';


const { Content } = Layout;
const { Title, Paragraph } = Typography
const { Search } = Input;

const invitationTypes = [
    {
        id: "invites",
        invitation_status: "Received Invites",
        tooltip: "You've been invited to join these ITSCs"
    },
    {
        id: "requests",
        invitation_status: "Sent Requests",
        tooltip: "You've sent requests to join these ITSCs"
    },
   
]

const InviteListings = () => {
    const tech = useSelector(state => state.tech)
    const [itcInvites, setItcInvites] = useState([])
    const [filteredItcInvites, setFilteredItcInvites] = useState([])
    const [loadingInvites, setLoadingInvites] = useState(false)
    const [itcRequests, setItcRequests] = useState([])
    const [loadingRequests, setLoadingRequests] = useState(false)
    const [invitationStatus, setInvitationStatus] = useState("invites")
    const [withdrawModal, setWithdrawModal] = useState(false)
    const [withdrawing, setWithdrawing] = useState(false)
    const [appliedMemberDetails, setAppliedMemberDetails] = useState({})
    const [loadingAppliedMemberDetails, setLoadingAppliedMemberDetails] = useState(false)
    const { windowWidth } = useWindowSize()
    const history = useHistory()

    const params = new URLSearchParams(window.location.search)
console.log(itcInvites)
    const getMemberItcInvites = () => {
        setLoadingInvites(true)
        ApiBaseHelper.get({
            url: `itc/search/${tech?.id}?status=Invited`,
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setItcInvites(res?.data?.data?.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)))
            setLoadingInvites(false)
        }).catch((error) => {
            console.error(error)
            setLoadingInvites(false)
        })
    }

    const getMemberItcRequests = () => {
        setLoadingRequests(true)
        ApiBaseHelper.get({
            url: `itc/search/${tech?.id}?status=Applied`,
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setItcRequests(res?.data?.data?.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)))
            setLoadingRequests(false)
        }).catch((error) => {
            console.error(error)
            setLoadingRequests(false)
        })
    }

    const getAppliedItcMemberDetails = (itcId) => {
        setLoadingAppliedMemberDetails(true)
        ApiBaseHelper.get({
            url: `itc/member/status/${itcId}/${tech.id}?status=Applied`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setAppliedMemberDetails(res?.data?.data[0])
            setLoadingAppliedMemberDetails(false)
            setWithdrawModal(true)
        }).catch((error) => {
            console.error(error)
            setLoadingAppliedMemberDetails(false)
        })       
    }

    const handleItcWithdrawal = () => {
        setWithdrawing(true)
        ApiBaseHelper.put({
            url: `itc/member/${appliedMemberDetails?.id}?type=withdraw`,	
            headers: {
                Authorization: 'Bearer ' + tech?.token,
            },
            payload: {
                Status: 'Inactive'
            }
        }).then((res) => {
            setWithdrawing(false)
            notification.success({
                message: 'TecMe',
                description: 'Application Withdrawn successfully'
            })
            setWithdrawModal(false)
            getMemberItcRequests() 
        }).catch((err) => {
            notification.error({
                message: 'TecMe',
                description: 'An error occurred'
            })
            setWithdrawing(false)
            console.log(err)
        })
    }

    const handleItcInviteSearch = (e) => {
        const value = e.target.value.toLowerCase()
        let filteredData = []
        if(invitationStatus === "invites") {
            filteredData = itcInvites.filter((item) => {
                return item?.Company_Name?.toLowerCase().includes(value) 
            })
        }else{
            filteredData = itcRequests.filter((item) => {
                return item?.Company_Name?.toLowerCase().includes(value) 
            })
        }
        setFilteredItcInvites(filteredData)
    }

    useEffect(() => {
        getMemberItcInvites()
        getMemberItcRequests()
    }, [])

    useEffect(() => {
        setFilteredItcInvites(itcInvites)
    }, [itcInvites])

    useEffect(() => {
        if(invitationStatus === "invites") {
            setFilteredItcInvites(itcInvites)
        } else {    
            setFilteredItcInvites(itcRequests)
        }
    }, [invitationStatus, itcInvites, itcRequests])

    useEffect(() => {
        if(params.get('tab') === "requests"){
            setInvitationStatus("requests")
        }
    }, [params.get('tab')])


    const itcPopoverContent = (record) => {
        return(
            <div className="itc-profile-popover-content">   
               { 
                    invitationStatus === "invites" ?
                    <Space className='popover-action-item' 
                        align='center' 
                        onClick={(e) =>{ 
                            history?.push(ROUTES.ITC_INVITE_DETAILS.replace(':id', record.id))
                            e.stopPropagation()
                        }}>
                        <EyeOutlined className='popover-action-icon' />
                        <Paragraph style={{marginBottom:0}}>View Details</Paragraph> 
                    </Space> :
                    <Space className='popover-action-item' 
                        align='center' 
                        onClick={(e) =>{ 
                            e.stopPropagation()
                            getAppliedItcMemberDetails(record?.id)   
                        }}>
                        {
                            loadingAppliedMemberDetails ? <LoadingOutlined className='popover-action-icon' /> :
                            <CloseCircleOutlined className='popover-action-icon' />
                        }
                        <Paragraph style={{marginBottom:0}}>Withdraw Request</Paragraph> 
                    </Space>
                }
                <Space className='popover-action-item' align='center'
                    onClick={(e) => {
                        history?.push(ROUTES.ITC_HOME.replace(':subdomain', record?.Subdomain))
                        e.stopPropagation()
                    }}>
                    <ExportOutlined className='popover-action-icon'/>
                    <Paragraph style={{marginBottom:0}}>Visit Webpage</Paragraph> 
                </Space>
            </div>
        )
    }

    const itcInvitesColumns = [
        {
            title: 'Name',
            dataIndex: 'company-name',
            key: 'company-name',
            width: '20%',
            render:(_, record) => {
                return(
                    <Space
                        onClick={(e) => {
                            history?.push(ROUTES.ITC_HOME.replace(':subdomain', record?.Subdomain))
                            e.stopPropagation()
                        }}
                    >
                        <Avatar 
                            size={32} 
                            src={record?.Brand}
                            shape='circle'
                            style={{color:SECONDARY_COLOR, fontSize:14}}
                        >
                            {record?.Company_Name?.charAt(0)?.toUpperCase() || 'T'}
                        </Avatar>
                        <Paragraph style={{margin:0, fontWeight:500, fontSize:14, color:"#F04A22"}}>{capitalizeFirstLetter(record?.Company_Name)}</Paragraph>
                    </Space>
                )
            },
            sorter: {
                compare: (a, b) => {
                    if(a?.Company_Name) return a?.Company_Name.localeCompare(b?.Company_Name)
                },
            },
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render:(_, record) => {
                return(
                    <Paragraph style={{margin:0, fontSize:14}}>{capitalizeFirstLetter(truncateText(decode(stripHtml(record?.Description)), 60))}</Paragraph>
                )
            },
            sorter: {
                compare: (a, b) => {
                    if(a?.Description) return a?.Description.localeCompare(b?.Description)
                },
            },
        },
        {
            title: 'Membership Fee',
            dataIndex: 'membership-fee',
            key: 'membership-fee',
            render:(_, record) => {
                return(
                    <Paragraph style={{margin:0, fontSize:14}}>{membershipFeeContent(record?.Membership_Fee, record?.Fee_Type)}</Paragraph>
                )
            },
            sorter: {
                compare: (a, b) => {
                    if(a?.Membership_Fee) return a?.Membership_Fee.localeCompare(b?.Membership_Fee)
                },
            },
        },
        {
            title: invitationStatus === "invites" ? 'Invite Status' : 'Request Status',
            dataIndex: 'status',
            key: 'status',  
            render:(_, record) => {
                return(
                    <Tag color='red'>Pending</Tag>
                )
            },
            // sorter: {
            //     compare: (a, b) => {
            //         if(a?.Active) return a?.Active.localeCompare(b?.Active)
            //     },
            // },
        },
        // {
        //     title: 'Created On',
        //     dataIndex: 'date',
        //     key: 'date',
        //     render:(_, record) => {
        //         return(
        //             <Paragraph style={{margin:0, fontSize:14}}>{getTableTimeFormat(record?.CreatedDate)}</Paragraph>
        //         )
        //     },
        //     sorter: {
        //         compare: (a, b) => {
        //             if(a?.CreatedDate) return new Date(a?.CreatedDate) - new Date(b?.CreatedDate)
        //         },
        //     },
        // },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Popover content={itcPopoverContent(record)}  trigger="click" placement='bottom' overlayClassName="itc-profile-popover" arrowPointAtCenter>
                    <img src={ActionIcon} alt="action icon" onClick={
                        (e) => {
                            e.stopPropagation()
                        }}/>  
                </Popover>
            ),
        },
    ];

    return (
        <>
            <MetaTags>
                <title>IT Services Company Invites - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <PageHeaderWrapper
                routes={[
                    {
                        path: ROUTES.ITC_INVITES_LISTING,
                        breadcrumbName: 'ITSC Invites',
                    },
                ]}
            />
            <Content className="main">
                <div className='page'>
                    <div className="auth-container">
                    <div className="auth-page-ctn itc-invites-ctn">
                            <div className="itc-invites-table"> 
                                <div className="itc-invites-table-header">
                                    <Title level={3} style={{marginBottom:0}}>ITSC Invites</Title>
                                    {
                                        itcInvites?.length > 0 &&
                                        <Search
                                            placeholder="Search by profile name" 
                                            className="search-itc-invites" 
                                            onChange={handleItcInviteSearch}
                                            size='large'
                                        />
                                    }
                                </div>
                                {
                                    !loadingInvites &&
                                    <div className='invite-type-list'>
                                        {
                                            invitationTypes.map((item) => (
                                                <Tooltip
                                                    key={item?.id}
                                                    title={item.tooltip}
                                                >
                                                    <div
                                                        key={item?.id}
                                                        className={`invite-type-item ${item?.id == invitationStatus && 'invite-type-item-active'}`}
                                                        onClick={() => setInvitationStatus(item?.id)}
                                                    >
                                                        {item.invitation_status}
                                                    </div>
                                                </Tooltip>
                                            ))
                                        }
                                    </div>
                                }
                                <CustomRenderEmpty
                                    description={
                                        invitationStatus === "invites" ?
                                        "No ITSC Invites found." :
                                        "No ITSC Requests found."
                                    }
                                >
                                    {
                                        windowWidth > 768 ?
                                        <Table
                                            columns={itcInvitesColumns} 
                                            dataSource={filteredItcInvites} 
                                            loading={loadingInvites}
                                            onRow={(record) => {
                                                return {
                                                    onClick: () => history?.push({
                                                    pathname: ROUTES.ITC_INVITE_DETAILS.replace(':id', record.id),
                                                    })
                                                };
                                            }}
                                            pagination={{
                                                onChange: page => {
                                                    window.scrollTo({
                                                        top: 0,
                                                        behavior: 'smooth'
                                                    })
                                                },
                                            }}
                                        /> :
                                        <List
                                            dataSource={filteredItcInvites}
                                            loading={loadingInvites}
                                            pagination={{
                                                onChange: page => {
                                                    window.scrollTo({
                                                        top: 0,
                                                        behavior: 'smooth'
                                                    })
                                                },
                                                pageSize: 10,
                                            }}
                                            renderItem={
                                                item => {
                                                    return(
                                                    <List.Item key={item?.id}>
                                                        <div 
                                                            className="job-application-list-item"
                                                            onClick= {() => history?.push({
                                                                pathname: ROUTES.ITC_INVITE_DETAILS.replace(':id', item.id),
                                                                })}
                                                            >
                                                            <div className="job-application-list-item-content">
                                                                <Paragraph style={{marginBottom:4, fontWeight:500, fontSize:14, color:"#F04A22"}}>{capitalizeFirstLetter(item?.Company_Name)}</Paragraph>
                                                                <Paragraph ellipsis={{rows:2}} style={{margin:0, fontSize:14}}>{capitalizeFirstLetter(truncateText(decode(stripHtml(item?.Description)), 60))}</Paragraph>
                                                                <Badge status="error" text="Pending" />
                                                                <Paragraph style={{marginBottom:4, fontSize:14, color:"rgba(0, 0, 0, 0.45)"}}>{getTableTimeFormat(item?.CreatedDate)}</Paragraph> 
                                                            </div>  
                                                            <div className="job-application-list-item-action">
                                                            <Popover content={itcPopoverContent(item)}  trigger="click" placement='bottom' overlayClassName="itc-profile-popover" arrowPointAtCenter>
                                                                <img src={ActionIcon} alt="action icon" onClick={
                                                                    (e) => {
                                                                        e.stopPropagation()
                                                                    }}/>  
                                                            </Popover>
                                                            </div>
                                                        </div>
                                                    </List.Item>
                                                )}
                                            }
                                        /> 
                                    }
                                </CustomRenderEmpty>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
            <ActionModal
                visible={withdrawModal}
                handleCancel={() => {
                    setWithdrawModal(false)
                }}
                handleClick={() => handleItcWithdrawal()}
                message={"This action will withdraw your application from this ITSC. Please make sure you want to withdraw before proceeding."}
                loading={withdrawing}
                deleteText={"Confirm"}
            />
        </>
    )
}

export default InviteListings