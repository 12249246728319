import React, { Component, Fragment } from 'react';
import { connect, useDispatch } from "react-redux";
import { Layout, Row, Col, Typography, Form, Input, Button, Select, notification, Alert, Spin, Modal, Space, Switch, Tooltip } from 'antd';
import OfficeImage from './assets/images/Office.png'
import OfficeBlackImage from './assets/images/OfficeBlack.png'
import HomeImage from './assets/images/Home.png'
import HomeBlackImage from './assets/images/HomeBlack.png' 
import AppartmentImage from './assets/images/Appartment.png'
import AppartmentBlackImage from './assets/images/AppartmentBlack.png'
import { STATES } from '../../../../../../utils/us-states-city';
import { ApiBaseHelper } from "../../../../../../utils/api-base-helper";
import ROUTES from '../../../../../../utils/routes';
import { LocationRepository } from '../repository/location-repository';
import { DeleteOutlined, LoadingOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {META_DESCRIPTION} from "../../../../../../utils/constants";
import MetaTags from "react-meta-tags";
import  './assets/css/modal.scss';
import PageHeaderWrapper from "../../../../../components/page-header-breadcrumbs";
import { useState } from 'react';
import { useEffect } from 'react';
import { updateUser } from '../../../../../../redux/reducers/app';

const antIcon = <LoadingOutlined style={{ fontSize: 22, color: "black" }} spin />;
const { Paragraph, Title } = Typography
const { Content } = Layout
const { TextArea } = Input
const {useForm} = Form

const AddEditLocationModal = (props) => {
    const dispatch = useDispatch()
    const [type, setType] = useState(null)
    const [isFormChange, setIsFormChange] = useState(false)
    const [loading, setLoading] = useState(false)
    const [imageStatus, setImageStatus] = useState(false)
    const [locationDetails, setLocationDetails] = useState()
    const [form] = useForm();

    const locationRepository = new LocationRepository(props.user.token)

    const openNotification = (value) => {
        notification.open({
            message: value.message,
            description: value.description,
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    } 

    useEffect(() => {
        const isMobile = window.innerWidth < 992;
        if(props.location.state){
            if(isMobile){ 
                if(props.location.state.item){
                    setType(props.location.state.item.Type)
                }
                setLocationDetails(props.location.state['item'])
            } else {
                if(props.location.state.record){
                    setType(props.location.state.record.Type)
                }
                setLocationDetails(props.location.state['record'])
            }
        }
    }, [props.location.state])

    useEffect(() => {
        if(!!locationDetails){
            let updatedLocationDetails = {
                address:  locationDetails?.Address_Full,
                address2: "",
                city:  locationDetails?.City,
                state:  locationDetails?.State,
                zipCode:  locationDetails?.Zip_Code,
                primary: locationDetails.Id === props.user.Primary_Facility.Id ? true : false
              };
            form.setFieldsValue(updatedLocationDetails);
        }
    },[locationDetails])

    const handleFormReset = () => {
        form.resetFields()
        setLocationDetails(null)
        setType(null)
    }
    
    return(
        <Fragment>
                <MetaTags>
                    <title >{`${props.option === "add" ? "Add" : "Edit"} Location` }  | TecMe </title>
                    <meta name="description" content={META_DESCRIPTION} />
                    <meta name="robots" content="noindex, nofollow" />
                </MetaTags>
                    <Modal 
                        title={`${props.option === "add" ? "Add" : "Edit"} Location` }
                        width={800}
                        open={props.open} 
                        onOk={props.onOk} 
                        footer={null}
                        onCancel={() => {
                            props.onCancel()
                            handleFormReset()
                        }}>
                            <Row gutter={[20, 20]} >
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>   
                                    <Row gutter={[16, 16]}  >
                                        <Col span={8} >
                                            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor:'pointer' }} onClick={() => {
                                                        setType('Home')
                                                        setImageStatus(false)
                                                    }}>
                                                        {type == 'Home' ? <div><img loading="lazy"  src={HomeImage} style={{ maxWidth: '20vw' }} />
                                                            <Paragraph style={{ textAlign: 'center', marginTop: '0.5rem', padding: 0, color: '#F04A22' }}>Home</Paragraph>
                                                        </div> :
                                                            <div><img loading="lazy"  src={HomeBlackImage} style={{ maxWidth: '20vw' }} />
                                                                <Paragraph style={{ textAlign: 'center', marginTop: '0.5rem', padding: 0, color: '#9A9A9A' }}>Home</Paragraph>
                                                            </div>}
                                                    </div>
                                                </Col>

                                            </Row>
                                        </Col>
                                        <Col span={8} >
                                            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor:'pointer' }} onClick={() => {
                                                         setType('Office')
                                                         setImageStatus(false)
                                                    }} >
                                                        {type == 'Office' ? <div><img loading="lazy"  src={OfficeImage} style={{ maxWidth: '20vw' }} />
                                                            <Paragraph style={{ textAlign: 'center', marginTop: '0.5rem', padding: 0, color: '#F04A22' }}>Office</Paragraph>
                                                        </div> :
                                                            <div><img loading="lazy"  src={OfficeBlackImage} style={{ maxWidth: '20vw' }} />
                                                                <Paragraph style={{ textAlign: 'center', marginTop: '0.5rem', padding: 0, color: '#9A9A9A' }}>Office</Paragraph>
                                                            </div>}
                                                    </div>
                                                </Col>

                                            </Row>
                                        </Col>
                                        <Col span={8} >
                                            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', cursor:'pointer' }} onClick={() => {
                                                         setType('Apartment')
                                                         setImageStatus(false)
                                                    }}>
                                                        {type == 'Apartment' ? <div><img loading="lazy"  src={AppartmentImage} style={{ maxWidth: '20vw' }} />
                                                            <Paragraph style={{ textAlign: 'center', marginTop: '0.5rem', padding: 0, color: '#F04A22' }}>Apartment</Paragraph>
                                                        </div> :
                                                            <div>   <img loading="lazy"  src={AppartmentBlackImage} style={{ maxWidth: '20vw' }} />
                                                                <Paragraph style={{ textAlign: 'center', marginTop: '0.5rem', padding: 0, color: '#9A9A9A' }}>Apartment</Paragraph>
                                                            </div>}
                                                    </div>
                                                </Col>

                                            </Row>
                                        </Col>
                                        {imageStatus && <p style={{ color: 'red' }}>Select the location type</p>}
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Form
                                                layout="vertical"
                                                className="add-edit-location-form"
                                                form={form}
                                                // initialValues={{
                                                //     address: locationDetails ? locationDetails.Address_Full : null,
                                                //     address2: "",
                                                //     city: locationDetails ? locationDetails.City : null,
                                                //     state: locationDetails ? locationDetails.State : null,
                                                //     zipCode: locationDetails ? locationDetails.Zip_Code : null
                                                // }}
                                                onFinish={(values) => {
                                                    if (locationDetails != null) {
                                                        if (type == locationDetails.Type && values.address == locationDetails.Address_Full && values.city == locationDetails.City && values.state == locationDetails.State && values.zipCode == locationDetails.Zip_Code && values.primary == (locationDetails.Primary == "true")) {
                                                           setIsFormChange(true)
                                                            return
                                                        }

                                                        setIsFormChange(false)
                                                        setLoading(true)
                                                        const data = new FormData();
                                                        data.append("city", values?.city || "")
                                                        data.append("address", values?.address || "")
                                                        data.append("state", values?.state || "")
                                                        data.append("zipCode", values?.zipCode || "")
                                                        data.append("type", type)
                                                        data.append("primary", values?.primary)
                                                        data.append("role", props.user.tech ? "technician" : "customer")
                                                        locationRepository.editLocation(locationDetails.Id, data).then((res) => {
                                                            openNotification({ message: 'TecMe', description: 'Location Successfully Updated.' })
                                                             // If location is primary, update the global user details
                                                             if(values?.primary){
                                                                dispatch(updateUser({
                                                                    ...props.user,
                                                                    Primary_Facility:{
                                                                        ...props.user.Primary_Facility,
                                                                        Id: res.data.data.Id,
                                                                        Address: res.data.data.Address_Full,
                                                                        City: res.data.data.City,
                                                                        State: res.data.data.State,
                                                                        Latitude: res.data.data.Latitude, 
                                                                        Longitude: res.data.data.Longitude,
                                                                        "zip code": res.data.data.Zip_Code,
                                                                        type: res.data.data.Type,
                                                                   }
                                                                }))
                                                            }
                                                            setLoading(false)
                                                            props.history.push(ROUTES.LOCATION)
                                                            props.onCancel()
                                                            handleFormReset()
                                                            props.fetchLocations()
                                                        }).catch((e) => {
                                                            setLoading(false)
                                                            openNotification({ message: 'TecMe', description: 'Location Update Failed' })
                                                        })


                                                    } else {
                                                        console.log(values.primary);
                                                        if (type == null) {
                                                           setImageStatus(true)
                                                            return
                                                        }
                                                        setLoading(true)
                                                        setImageStatus(false)
                                                        const data = new FormData();
                                                        data.append("city", values?.city || "")
                                                        data.append("address", values?.address || "")
                                                        data.append("state", values?.state || "")
                                                        data.append("zipCode", values?.zipCode || "")
                                                        data.append("type", type)
                                                        data.append("primary", values?.primary)
                                                        data.append("role", props.user.tech ? "technician" : "customer")
                                                       locationRepository.addLocation(data).then((res) => {
                                                            setLoading(false)
                                                            openNotification({ message: 'TecMe', description: 'Location Successfully Added' })
                                                             // If location is primary, update the global user details
                                                             if(values?.primary){
                                                                dispatch(updateUser({
                                                                    ...props.user,
                                                                    Primary_Facility:{
                                                                        ...props.user.Primary_Facility,
                                                                        Id: res.data.data.Id,
                                                                        Address: res.data.data.Address_Full,
                                                                        City: res.data.data.City,
                                                                        State: res.data.data.State,
                                                                        Latitude: res.data.data.Latitude, 
                                                                        Longitude: res.data.data.Longitude,
                                                                        "zip code": res.data.data.Zip_Code,
                                                                        type: res.data.data.Type,
                                                                   }
                                                                }))
                                                            }
                                                            props.history.push(ROUTES.LOCATION)
                                                            props.onCancel()
                                                            handleFormReset()
                                                            props.fetchLocations()
                                                        }).catch((e) => {
                                                            console.log(e)
                                                            setLoading(false)
                                                            openNotification({ message: 'TecMe', description: 'Location Add Field' })
                                                        })
                                                    }
                                                }}

                                            >


                                                <Form.Item
                                                    label="Address" required
                                                    name="address"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter your address',
                                                        },
                                                    ]}
                                                >
                                                    <TextArea rows={4}

                                                        size={"large"}
                                                        placeholder="Please enter address here"

                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label="Address 2"
                                                    name="address2"
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: 'Please enter your second address',
                                                        },
                                                    ]}
                                                >
                                                    <TextArea rows={2}

                                                        size={"large"}
                                                        placeholder="Add more address information"


                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    label="City" required
                                                    name="city"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter your city',
                                                        },

                                                    ]}
                                                >
                                                    <Input
                                                        size={"large"}
                                                        placeholder="Enter your City"

                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    style={{ marginBottom: 16, display: "flex", flexDirection: 'column', textAlign: 'left', }}
                                                    name="state"
                                                    label="State"
                                                    rules={[{ required: true, message: 'Please select state' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: "100%", borderRadius: "0.3rem !important" }}
                                                        placeholder="State"
                                                        filterOption={true}
                                                        optionFilterProp={"label"}
                                                        size="large"
                                                        options={
                                                            STATES.map(value => {
                                                                return {
                                                                    label: value.State,
                                                                    value: value.Code
                                                                }
                                                            })
                                                        }
                                                    >


                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    label="Zip Code" required
                                                    name="zipCode"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            pattern: new RegExp(/^(\d{5})?$/),
                                                            message: "Please enter 5 digits only"
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        size={"large"}
                                                        placeholder="Zip Code"

                                                    />
                                                </Form.Item>
                                                {props.user.tech === true && 
                                                    <div className='add-edit-form-switch'>
                                                            <Paragraph style={{marginBottom:8}}>Select location as Primary</Paragraph>
                                                            <Tooltip
                                                                    title={locationDetails?.Id === props.user.Primary_Facility?.Id ? "Primary location cannot be turned off. Kindly select another location as primary" : ""}
                                                                >
                                                            <Form.Item 
                                                                name="primary"
                                                                valuePropName="checked"
                                                            >
                                                                
                                                                    <Switch 
                                                                        disabled={locationDetails?.Id === props.user.Primary_Facility?.Id}
                                                                    />
                                                            
                                                            </Form.Item>
                                                            </Tooltip>
                                                    </div>
                                                }
                                                {isFormChange && <Alert style={{ width: '300px' }} message="No changes found." type="info" />}


                                                <Space className='w-100 justify-content-end'>
                                                    <Button
                                                        type='text'
                                                        onClick={() => {
                                                            props.onCancel()
                                                            handleFormReset()
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button type={"primary"} block htmlType="submit" loading={loading}style={{ margin: '1rem 0'}}>
                                                    { props.option === "add" ? "Create"  : "Save"}
                                                </Button>
                                                </Space> 

                                              
                                            </Form>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                    </Modal>
            </Fragment>
    )
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(AddEditLocationModal);


