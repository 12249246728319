// External Imports
import React, { useState, useEffect, useReducer } from 'react'
import {
    Row,
    Layout,
    Col,
    Typography,
    Space,
    Avatar,
    Button,
    Tabs,
    Card,
    Tag,
    Table,
    Empty,
    Divider,
    Input,
    Image,
    Checkbox,
    Badge,
    Descriptions,
    Skeleton,
    notification,
    Spin,
    Modal,
    Tooltip,
    Form, Popover, Progress, message
} from 'antd';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
    ArrowRightOutlined,
    MessageOutlined,
    FieldTimeOutlined,
    ScheduleOutlined,
    PlusOutlined,
    ClockCircleOutlined,
    ArrowLeftOutlined,
    HourglassOutlined,
    DollarCircleOutlined,
    BarsOutlined,
    LoadingOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    EllipsisOutlined,
    EditOutlined,
    DeleteOutlined,
    HistoryOutlined,
    InfoCircleOutlined,
    DollarTwoTone,
    DownloadOutlined,
    CalendarOutlined
} from '@ant-design/icons'
import { isMobile, isTablet } from 'react-device-detect';
import BottomNavigation from 'reactjs-bottom-navigation'
import Moment from 'moment'
import { extendMoment } from 'moment-range';

// Internal Imports
import { SECONDARY_COLOR, TECME_LOGO } from '../../../../../../../utils/constants';
import ROUTES from '../../../../../../../utils/routes';
import PageHeaderWrapper from '../../../../../../components/page-header-breadcrumbs';
import EditContractModal from './edit-contract-modal';
import NewSupportTicketModal from './new-support-ticket-modal';
import AssignedTicketsContent from './assigned-tickets-content';
import CustomRenderEmpty from '../../../../../../components/custom-render-empty';
import ManualTimeModal from './manual-time-entry/manual-time-modal';
import EditTimeModal from './manual-time-entry/edit-time-modal';
import SearchInput from '../../../../../../components/search-input';

// Assets Imports
import { getCardDetails } from '../../../new-invoice/components/invoice-payment-method';
import EndContractModal from './end-contract-modal';
import PaymentChangeModal from './payment-change-modal';
import Bulb from '../../../../public/create-send-contract/assets/images/bulb.svg'
import { contractStatuses } from '..';
import { RtmRepository } from '../../../real-time-messaging/repository/rtm-repository';
import { FirebaseHelper, FirestoreHelper } from '../../../../../../../utils/firebase-helper';
import {
    canReadPageOnly,
    capitalizeFirstLetter,
    capitalizeFirstWordOfEveryLetter,
    convertCentsIntoDollarsString,
    encryptString,
    filterWeeklyTimeSheet,
    getTableTimeFormat,
    nameFormat,
    truncateText
} from '../../../../../../../utils/helper';
import { PaymentRepository } from '../../../../../customer/manage/payment-methods/payment-repository/payment-repository';
import FeedbackModal from './feedback-modal';
import ReasonRadioList from './reason-radio-list';
import FileDisputeModal from './file-dispute-modal';
import PauseContractModal from './pause-contract-modal';
import { useTimeSheet } from '../../contract-details/hooks/useTimesheet';
import ReportIssue from "./report-issue-modal";
import ReportIssueModal from "./report-issue-modal";
import ViewTimeEntryHistory from "./view-time-entry-history";
import InfoIcon from "@material-ui/icons/Info";
import useWindowSize from '../../../../../../../hooks/useWindowSize';
import MessagesRightPaneView from '../../../real-time-messaging/components/messages-right-pane-view';
import BillingPopOver from './billing-popover';
import { ApiBaseHelper } from '../../../../../../../utils/api-base-helper';

const moment = extendMoment(Moment);
const { Content } = Layout
const { Title, Text, Paragraph } = Typography
const { TabPane } = Tabs
const { Item } = Descriptions
const { useForm } = Form

let tabsList = [
    {
        key: 'assigned_tickets',
        name: "Assigned Tickets"
    },
    // {
    //     key: 'messages',
    //     name: "Messages"
    // },
    {
        key: 'timesheet',
        name: "Timesheet"
    },
    {
        key: 'billing',
        name: "Billing"
    },
    {
        key: 'details',
        name: "Details"
    }
]

const ActiveContract = ({ contractDetails, repository, getAndSetContractDetails, isFromActiveContracts }) => {
    const params = useParams();
    const user = useSelector((state) => state.user);
    const tech = useSelector((state) => state.tech);
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const history = useHistory();
    const location = useLocation();
    const contractId = params?.contractId?.toUpperCase();
    const isMessagedTabSelected = location?.search?.includes("tab=messages");
    const requiredSkillsList = contractDetails.Skills_Required?.length ? contractDetails.Skills_Required.split(",") : []
    const [selectedTab, setSelectedTab] = useState(isMessagedTabSelected ? 'messages' : 'assigned_tickets') // @todo: replace  assigned_tickets  with assigned_tickets
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(undefined)
    const [editContractModalInfo, setEditContractModalInfo] = useState({
        isVisible: false
    })

    const [isEndContractModalVisible, setIsEndContractModalVisible] = useState(false)
    const [activeTabId, setActiveTabId] = useState(isMessagedTabSelected ? 1 : 0)
    const [isMobilePaymentChangeVisible, setIsMobilePaymentChangeVisible] = useState(false)
    const [detailsMobileViewSelectedTab, setDetailsMobileViewSelectedTab] = useState('Details')
    const [messagesCard, setMessagesCard] = useState(undefined);
    const [rtmRepository, setRtmRepository] = useState(undefined);
    const [activeConversation, setActiveConversation] = useState(undefined);
    const [paymentRepository, setPaymentRepository] = useState(undefined);
    const [upComingInvoice, setUpComingInvoice] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [activitiesList, setActivitiesList] = useState([]);
    const [changingPaymentMethodId, setChangingPaymentMethodId] = useState(false)
    const [isNewSupportTicketModalVisible, setIsNewSupportTicketModalVisible] = useState(false)
    const [showDisclaimer, setShowDisclaimer] = useState(false)
    // const [timeSheetData, setTimeSheetData] = useState([])
    // const [filteredTimeSheetData, setFilteredTimeSheetData] = useState([])
    const [updatedTimesheetData, setUpdatedTimesheetData] = useState(true)
    const [currTimeFilter, setCurrTimeFilter] = useState("seven-days")

    const [selectedTimeEntry, setSelectedTimeEntry] = useState(undefined)

    const [showTimeEntryModal, setShowTimeEntryModal] = useState(false);
    const [showTimeEntryHistoryModal, setShowTimeEntryHistoryModal] = useState(false);
    const [showReportIssueModal, setShowReportIssueModal] = useState(false);
    const [showEditTimeEntryModal, setShowEditTimeEntryModal] = useState(false);
    const [ticketData, setTicketData] = useState([])
    const [invoicesData, setInvoicesData] = useState([])
    const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false)
    const [timeSpent, setTimeSpent] = useState(0)
    const [showPausedDisclaimer, setShowPausedDisclaimer] = useState(true)
    const [isFileDisputeModalVisible, setIsFileDisputeModalVisible] = useState({
        status: false,
        record: undefined
    })
    const [isPauseContractModalVisible, setIsPauseContractModalVisible] = useState(false)
    const [canEditTimeEntry, setCanEditTimeEntry] = useState(false)
    const [isContractResumeModalVisible, setIsContractResumeModalVisible] = useState(false)
    const [isContractResumeApiCallInProgress, setIsContractResumeApiCallInProgress] = useState(false)
    const [isPaymentMethodsApiCallInProgress, setIsPaymentMethodsApiCallInProgress] = useState(false)
    const [itcDetails, setItcDetails] = useState({})
    const [loadingItc, setLoadingItc] = useState(true)
    const [activating, setActivating] = useState(false)
    const { windowWidth } = useWindowSize()


    const {
        timeSheetData,
        filteredTimeSheetData,
        getTrackedHours,
        getManualHours,
        setFilteredTimeSheetData,
        totalHours,
        startOfBillingWeek,
        endOfBillingWeek,
        billingWeekTotalHours,
        getWeeklyTimesheetData
    } = useTimeSheet(params?.contractId, updatedTimesheetData, setUpdatedTimesheetData, setTimeSpent, upComingInvoice)
    const [dateRange, setDateRange] = useState({ startDate: startOfBillingWeek, endDate: endOfBillingWeek })

    const filteredTickeData = ticketData?.filter((item) => item?.Status.toLowerCase() === "open" || item?.Status.toLowerCase() === "pending")

    useEffect(() => {
        if (currTimeFilter === "seven-days") {
            setFilteredTimeSheetData(getWeeklyTimesheetData())

        }
    }, [timeSheetData])

    useEffect(() => {
        getAndSetActivities()
    }, [])

    useEffect(() => {
        handleThisWeek()
    }, [upComingInvoice, timeSheetData])

    const getAndSetActivities = async () => {
        try {
            const res = await repository?.getActivities(params?.contractId);
            if (res.data?.data?.length) {
                setActivitiesList(res.data.data.sort((a, b) => moment(b.CreatedDate).diff(moment(a.CreatedDate))))
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(async () => {
        if ((user || tech) && contractDetails && !activeConversation) {
            const activeUser = user || tech
            const hipaaID = user ? contractDetails?.Provider_Org?.HIPAA_Primary_Contact : contractDetails?.Customer_Org?.HIPAA_Primary_Contact;

            if (activeUser) {
                setRtmRepository(new RtmRepository(activeUser.token))
                setPaymentRepository(new PaymentRepository(activeUser.token))
            }

            await FirebaseHelper.login();
            FirestoreHelper.getConversations({
                user_id: activeUser['id'],
                snapshot: (querySnapshot) => {
                    querySnapshot.forEach(async (doc) => {
                        let item = doc.data();
                        item.id = doc.id;
                        if (!item.group && item.participants?.includes(hipaaID)) {
                            setActiveConversation(item)
                        }
                    });
                }
            });
        }
    }, [user, tech, contractDetails])

    useEffect(() => {
        if (activeConversation) {
            const messages = (
                <MessagesRightPaneView
                    key={`messages-${activeConversation.id}`}
                    conversation={activeConversation}
                    user={user || tech}
                    chatBoxStatus={true}
                    serviceDetails={true}
                    contractPage
                    onClickBack={() => ''}
                    isContractPaused={contractDetails.Status === contractStatuses.Paused}
                />
            )
            setMessagesCard(messages)
        }
    }, [activeConversation])

    useEffect(() => {
        if (paymentRepository) {
            setIsPaymentMethodsApiCallInProgress(true)
            paymentRepository.getPayments()
                .then(res => {
                    if (res.data?.data?.length) {
                        setSelectedPaymentMethod(res.data.data.find(method => method.id === contractDetails?.Payment_Method))
                        setPaymentMethods(res.data.data)
                        setIsPaymentMethodsApiCallInProgress(false)
                    }
                })
                .catch(error => {
                    console.error(error)
                    setIsPaymentMethodsApiCallInProgress(false)
                })
        }

    }, [paymentRepository])

    const getInvoicesData = () => {
        repository?.getInvoices(params?.contractId).then(res => {
            if (res.data?.data?.length) {
                const filterInvoices = res.data.data.filter((item) => item.total > 0)
                const sortedInvoicesData = [...filterInvoices]
                sortedInvoicesData?.sort((a, b) => moment(b.created).local().diff(moment(a.created).local()))
                setInvoicesData(sortedInvoicesData)
            }
        }).catch(error => console.error(error))
    }

    useEffect(() => {
        getInvoicesData()
    }, [])

    useEffect(() => {
        repository?.getTickets(params?.contractId).then(res => {
            if (res.data?.data?.length) {
                setTicketData(res.data.data)
            }
        }).catch(error => console.error(error))
    }, [])

    useEffect(() => {
        if(contractDetails?.ITC){
            setLoadingItc(true)
            ApiBaseHelper.get({
                url: `itc/${contractDetails?.ITC}`
            }).then((res) => {
                setItcDetails(res?.data?.data)
                setLoadingItc(false)
            }).catch((error) => {
                console.error(error)
                setLoadingItc(false)
            })
        }
    }, [contractDetails])

    const handleViewProfileClick = () => {
        history.push(ROUTES.TECH_PROFILE_NEW.replace(':profileUrl', contractDetails.Provider_Org?.Profile_URL))
    }

    const getProfileAndName = (isUser = true, avatarSize = 45) => {
        const name = (isUser && !contractDetails?.ITC) ? (contractDetails?.Provider_Org?.HIPAA_Contact_Name || contractDetails?.Provider_Org?.Name) : (isUser && contractDetails?.ITC) ? itcDetails?.Company_Name : contractDetails?.Customer_Org?.Name
        const image = (isUser && !contractDetails?.ITC) ? contractDetails?.Provider_Org?.Contact_Image : (isUser && contractDetails?.ITC) ? itcDetails?.Brand : contractDetails?.Customer_Org?.Logo

        return (
            <Space size={10}>
                <Avatar shape='circle' size={avatarSize} src={image} alt={name}>
                    {name?.[0]?.toUpperCase()}
                </Avatar>
                <Space direction='vertical' className='customer-details' size={1}>
                    <Text className='font-weight-500'>
                        {capitalizeFirstWordOfEveryLetter(name)}
                    </Text>
                    {(isUser && !contractDetails?.ITC) && (
                        <Paragraph onClick={handleViewProfileClick} className="view-profile-button">View
                            Profile</Paragraph>
                    )}
                    {
                        (isUser && contractDetails?.ITC) && 
                        <Paragraph 
                            className="view-profile-button" 
                            onClick={(e) => {
                                history?.push(ROUTES.ITC_HOME.replace(':subdomain', itcDetails?.Subdomain))
                                e.stopPropagation()
                            }}>
                                View Webpage
                        </Paragraph>

                    }
                </Space>
            </Space>
        )
    }

    const getDisclaimer = () => {
        if (contractDetails.Status === contractStatuses.Active && contractDetails?.custom_data?.pause_reason === 'payment_failure' && !!contractDetails?.custom_data?.invoice_url) {
            return showPausedDisclaimer ? (
                <>  
                    <Space className='w-100 disclaimer justify-content-between p-2 mb-2' size={20}>
                        <Space size={15} align='top'>
                            <CloseCircleOutlined style={{ position:"relative", top: '4px' }} className='ms-2 fs-5 tecme-secondary-color cursor-pointer'
                                onClick={() => setShowPausedDisclaimer(false)} />
                            <Text type='secondary' className='font-size-14'>
                                <Space size={15}>
                                    <Text>
                                        {!!tech ? " The customer's last invoice/weekly payment was unsuccessful. However, the contract remains active and available for your services. If you want the customer to process payments, please contact him directly." : "The last invoice/weekly payment was unsuccessful. However, the contract will remain active, allowing you to add more tickets and enabling technicians to continue providing services. Please ensure timely payments to avoid any disputes."}
                                    </Text>
                                    {!!user && contractDetails?.custom_data?.invoice_url && (
                                        <a className='font-weight-500 tecme-secondary-color'
                                            href={contractDetails?.custom_data?.invoice_url} target="_blank">
                                            Pay Invoice
                                        </a>
                                    )}
                                </Space>
                            </Text>
                        </Space>
                    </Space>
                </>
            ) : ''
        }

        const isContractManualPaused = contractDetails.Status === contractStatuses.Paused && contractDetails?.custom_data?.pause_reason === 'manual';

        return (
            <Space className='w-100 disclaimer justify-content-between p-2' size={20}>
                <Space size={12} align="start">
                    <img style={{width:40}} src={Bulb} preview={false} alt="disclaimer" className='ms-2 flex-shrink-0' />
                    <Space direction='vertical' size={0}>
                        <Space>
                            <Tooltip placement="top" title={contractDetails?.Description}>
                                <Text strong>{contractDetails?.Type} Contract  {contractDetails?.Status === contractStatuses.Terminated ? 'Ended' : contractDetails?.Status}</Text>
                            </Tooltip>
                            {/* <Tag className='contract-status-tag'>
                                {contractDetails?.Status === contractStatuses.Terminated ? 'Ended' : contractDetails?.Status}
                            </Tag> */}
                        </Space>
                        <Text className='font-size-14'>
                            {!!tech ? (isContractManualPaused ? 'The customer has paused this contract, indicating that no additional actions can be taken on the contract or its related tickets. Consequently, the status of all tickets associated with this contract has been automatically updated to "Pending". You are currently unable to bill time for this contract unless the customer decides to resume it. If needed, you can communicate with the customer to resume the contract.' :  'This contract has ended, you can no longer process support tickets.') : (isContractManualPaused ? "You've successfully initiated a pause on your contract. This means that no further actions can be taken on the contract or its related tickets at the moment. As a result of this pause, the status of all tickets linked to this contract has been automatically changed to 'Pending'.  During this pause, our technicians are unable to bill time for activities related to this contract unless you decide to resume it." : 'Your contract has ended, you can no longer add support tickets or change details.')}
                        </Text>
                    </Space>
                </Space>
                {/* <div style={{ width: 'max-content' }} className="me-3">
                    {!!user && getProfileAndName(true, 45)}
                    {!!tech && getProfileAndName(false, 45)}
                </div> */}
            </Space>
        )
    }

    const contractProposalHeader = () => {
        return (
            <Space className='justify-content-between align-items-start flex-wrap w-100'>
                {/* <Space size="large"> */}
                <Space size={25}>
                    <Title className='m-0 main-heading'>
                        Contract Details
                    </Title>
                    {
                        contractDetails?.ITC &&
                        <Text> <Text strong> Contract via: </Text> <Text type='secondary'>{itcDetails?.Company_Name} ITSC</Text></Text>
                    }
                </Space>
                {/* <Tag className='contract-status-tag'>{contractDetails?.Status}</Tag> */}
                {/* </Space> */}
                {!!user && getProfileAndName(true, 45)}
                {!!tech && getProfileAndName(false, 45)}
            </Space>
        )
    }

    const disclaimer = (
        <Space className='w-100 disclaimer p-2 ps-3' size={25}>
            <Image src={Bulb} preview={false} alt="disclaimer" width={28} height={28} className='ms-2' />
            <Text>
                {(!!tech && !contractDetails?.ITC) ? 'Awaiting customer to add support tickets into the contract to get you started.' : (!!tech && contractDetails?.ITC) ? 'Add support tickets in the customer contract. Additionally, you may allocate the ticket to any member of your ITSC team.' : 'Add support tickets into your contract to get the technician start service.'}
            </Text>
        </Space>
    )

    const handleNewSupportTicketButtonClick = () => {
        setIsNewSupportTicketModalVisible(true)
    }

    const getAssignedTicketsContent = () => {
        return <div className='assigned-ticket-container' style={{ padding: 20 }}>
            <AssignedTicketsContent
                isFromActiveContracts
                handleNewSupportTicketButtonClick={handleNewSupportTicketButtonClick}
                setShowDisclaimer={setShowDisclaimer}
                isContractTerminated={contractDetails.Status === contractStatuses.Terminated}
                isContractPaused={contractDetails.Status === contractStatuses.Paused}
            />
        </div>
    }

    const getMessagesContent = () => {
        const skeleton = (
            <Card className='w-100 view-edit-contract-proposal'>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
            </Card>
        )

        return messagesCard || skeleton
    }

    //  component with icon along with text and show tooltip on hover over icon
    const IconWithText = ({ text, tooltip }) => {
        return (
            <Space size={5}>
                <Tooltip title={tooltip}>
                    <InfoCircleOutlined />
                </Tooltip>
                <Text>{text}</Text>
            </Space>
        )
    }

    const localTimestamp = (timeStamp) => moment(moment.utc(timeStamp).toDate()).local().format("MMM Do YYYY")

    const convertTimeFormat = (time) => {
        return `${Math.floor(time)}h ${Math.round((time % 1) * 60)}m`
    }

    const handleThisWeek = () => {
        const daysFrom = upComingInvoice ? moment.unix(upComingInvoice.next_payment_attempt).subtract(1, 'week') : moment().startOf('week');
        const daysTo = upComingInvoice ? moment.unix(upComingInvoice.next_payment_attempt) : moment().endOf('week');

        const thisWeekTimesheet = timeSheetData.filter((item, index) => {
            // convert utc clock out time to local time and check if it is between daysFrom and daysTo
            return moment(moment.utc(item.Clock_Out_Date_Time).toDate()).local() >= daysFrom && moment(moment.utc(item.Clock_Out_Date_Time).toDate()).local() <= daysTo
            // return moment(item.Clock_Out_Date_Time) >= daysFrom && moment(item.Clock_Out_Date_Time) <= daysTo
        })

        const timeSpent2 = thisWeekTimesheet.reduce((acc, item) => {
            return acc + parseInt(item.Total_Time)
        }
            , 0)

        setTimeSpent(timeSpent2 / 60)
        setFilteredTimeSheetData(thisWeekTimesheet)
        setCanEditTimeEntry(true)
        setDateRange({ startDate: daysFrom, endDate: daysTo })
    }

    const handleLastWeek = () => {
        const daysFrom = upComingInvoice ? moment.unix(upComingInvoice.next_payment_attempt).subtract(2, 'week') : moment().subtract(1, 'week').startOf('week');
        const daysTo = upComingInvoice ? moment.unix(upComingInvoice.next_payment_attempt).subtract(1, 'week') : moment().subtract(1, 'week').endOf('week');

        const lastWeekTimesheet = timeSheetData.filter((item) => {
            return moment(moment.utc(item.Clock_Out_Date_Time).toDate()).local() >= daysFrom && moment(moment.utc(item.Clock_Out_Date_Time).toDate()).local() <= daysTo
            // return moment(item.Clock_Out_Date_Time) >= daysFrom && moment(item.Clock_Out_Date_Time) <= daysTo
        })
        setCanEditTimeEntry(false)
        setFilteredTimeSheetData(lastWeekTimesheet)
        setDateRange({ startDate: daysFrom, endDate: daysTo })
    }

    const handleThisMonth = () => {
        const startOfMonth = upComingInvoice ? moment.unix(upComingInvoice.next_payment_attempt).subtract(1, 'month') : moment().startOf('month')
        const currentDate = upComingInvoice ? moment.unix(upComingInvoice.next_payment_attempt) : moment()

        const thisMonthTimesheet = timeSheetData.filter((item) => {
            return moment(moment.utc(item.Clock_Out_Date_Time).toDate()).local() >= startOfMonth && moment(moment.utc(item.Clock_Out_Date_Time).toDate()).local() <= currentDate
            // return moment(item.Clock_Out_Date_Time) > startOfMonth && moment(item.Clock_Out_Date_Time) <= currentDate
        })
        setCanEditTimeEntry(false)
        setFilteredTimeSheetData(thisMonthTimesheet)
        setDateRange({ startDate: startOfMonth, endDate: currentDate })
    }

    const handleLastMonth = () => {
        const startOfLastMonth = upComingInvoice ? moment.unix(upComingInvoice.next_payment_attempt).subtract(2, 'month') : moment().subtract(1, 'month').startOf('month')
        const endOfLastMonth = upComingInvoice ? moment.unix(upComingInvoice.next_payment_attempt).subtract(1, 'month').subtract(1, 'day') : moment().subtract(1, 'month').endOf('month')

        const lastMonthTimesheet = timeSheetData.filter((item) => {
            return moment(moment.utc(item.Clock_Out_Date_Time).toDate()).local() >= startOfLastMonth && moment(moment.utc(item.Clock_Out_Date_Time).toDate()).local() <= endOfLastMonth
            // return moment(item.Clock_Out_Date_Time) > startOfLastMonth && moment(item.Clock_Out_Date_Time) <= endOfLastMonth
        })
        setCanEditTimeEntry(false)
        setFilteredTimeSheetData(lastMonthTimesheet)
        setDateRange({ startDate: startOfLastMonth, endDate: endOfLastMonth })
    }

    const getTimesheetContent = () => {

        let columns = [
            {
                key: 'Support Ticket',
                title: 'Support Ticket',
                dataIndex: 'Support Ticket',
                width: "30%",
                ellipsis: true,
                render: (_, record) => {
                    if (!record?.Ticket?.Summary) {
                        return <Text>N/A</Text>
                    } else {
                        return <Text ellipsis={true} style={{ cursor: "pointer" }}
                            onClick={() => history?.push(ROUTES.TICKET_DETAILS.replace(':ticketId', record?.Ticket.id) + `?from=${isFromActiveContracts ? 'contracts' : 'desk'}`)}>{record?.Ticket?.Summary}</Text>
                    }

                },
                sorter: {
                    compare: (a, b) => {
                        if (a?.Ticket?.Summary) return a?.Ticket?.Summary.localeCompare(b?.Ticket?.Summary)
                    },
                },
            },
            {
                key: 'Clock In',
                title: 'Clock In',
                dataIndex: 'Clock In',
                width: "20%",
                render: (_, record) => (
                    <Text>{getTableTimeFormat(record?.Clock_In_Date_Time)}</Text>
                ),
                sorter: {
                    compare: (a, b) => {
                        if (a?.Clock_In_Date_Time) return a?.Clock_In_Date_Time.localeCompare(b?.Clock_In_Date_Time)
                    },
                },
            },
            {
                key: 'Clock Out',
                title: 'Clock Out',
                dataIndex: 'Clock Out',
                width: "20%",
                render: (_, record) => (
                    <Text>{getTableTimeFormat(record?.Clock_Out_Date_Time)}</Text>
                ),
                sorter: {
                    compare: (a, b) => {
                        if (a?.Clock_Out_Date_Time) return a?.Clock_Out_Date_Time.localeCompare(b?.Clock_Out_Date_Time)
                    },
                },
            },
            {
                key: 'Duration',
                title: 'Duration',
                width: "10%",
                dataIndex: 'Duration',
                render: (_, record) => {
                    let timeDuration = record?.Total_Time;
                    let roundDuration = Math.round(timeDuration / 10) * 10;;
                    if (roundDuration - timeDuration == 1)
                        timeDuration = roundDuration
                    const hours = Math.floor(timeDuration / 60)
                    const mins = Math.round(timeDuration % 60)
                    return <Text>{`${hours}h ${mins}m`}</Text>
                },
                sorter: {
                    compare: (a, b) => {
                        if (a?.Total_Time) return a?.Total_Time.localeCompare(b?.Total_Time)
                    },
                },
            },
            {
                key: 'Type',
                title: 'Type',
                width: "10%",
                dataIndex: 'Type',
                render: (_, record) => (
                    <Tag className="text-capitalize">{record?.Tags === null ? 'Tracked' : record?.Tags}</Tag>
                ),
                sorter: {
                    compare: (a, b) => {
                        if (a.Tags) return a.Tags.localeCompare(b.Tags)
                    },
                },
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record, index) => {
                    return getActionButtons(record)
                },
            }
        ]


        const timeSheetFilters = [
            {
                id: "seven-days",
                name: "This Week",
                handleTimeChange: handleThisWeek
            },
            {
                id: "last-week",
                name: "Last Week",
                handleTimeChange: handleLastWeek
            },
            {
                id: "this-month",
                name: "This month",
                handleTimeChange: handleThisMonth
            },
            {
                id: "last-month",
                name: "Last Month",
                handleTimeChange: handleLastMonth
            },
            {
                id: "all-time",
                name: "All Time",
                handleTimeChange: () => {
                    setFilteredTimeSheetData(timeSheetData)
                    setCanEditTimeEntry(false)
                }
            },
        ]
       
        return (
            <>
                <Row gutter={15}>
                    <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                        <Space className='flex-wrap justify-content-between w-100 mb-2'>
                            <Title level={3} className='m-0'>Timesheet</Title>
                            {!!tech && contractDetails?.Status === contractStatuses.Active && contractDetails.Type && contractDetails?.Allow_Manual_Entry === "1" && (contractDetails?.Type === 'Hourly') && (
                                <Tooltip
                                    title={!!(contractDetails?.Hours_Upper_Limit != '0' && billingWeekTotalHours >= Number(contractDetails?.Hours_Upper_Limit)) ? "Your hourly limit has been reached. You cannot log more hours at this time. Please contact the customer to request an increase in the hourly limit." : !ticketData?.filter((item) => item.Status.toLowerCase() === "open").length ? "You don't have any open tickets, so you can't add a time entry. Please begin support for a ticket to proceed." : ""}>
                                    <Button
                                        type='ghost'
                                        onClick={() => setShowTimeEntryModal(true)}
                                        disabled={!!(contractDetails?.Hours_Upper_Limit != '0' && billingWeekTotalHours >= Number(contractDetails?.Hours_Upper_Limit)) || !ticketData?.filter((item) => item.Status.toLowerCase() === "open").length}
                                    >
                                        + Add Time Entry
                                    </Button>
                                </Tooltip>
                            )}
                        </Space>
                        <Space className='flex-wrap mb-2'>
                            {
                                timeSheetFilters.map((item) => {
                                    return (
                                        <div key={item.id}
                                            className={`contract-type-item ${currTimeFilter === item.id && 'contract-type-item-active'}`}
                                            onClick={() => {
                                                setCurrTimeFilter(item.id)
                                                item.handleTimeChange()
                                            }}
                                        >
                                            {item.name}
                                        </div>
                                    )
                                })
                            }
                        </Space>
                        {
                            dateRange && currTimeFilter != 'all-time' &&
                            <div className='mb-2'>
                                <Typography.Text strong={true}>{moment(dateRange.startDate).format('MMM D, YYYY')} - {moment(dateRange.endDate).format('MMM D, YYYY')}</Typography.Text>
                            </div>
                        }

                        <CustomRenderEmpty
                            description={"No time logged by technician yet."}>
                            <Table
                                dataSource={filteredTimeSheetData}
                                columns={columns}
                            />
                        </CustomRenderEmpty>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        {
                            (contractDetails?.Type === 'Hourly' || contractDetails?.Type === 'Fixed') &&
                            <Row gutter={[10, 10]}>
                                {
                                    contractDetails?.Type === 'Hourly' &&
                                    <Col span={24}>
                                        {
                                            getWeeklyLimit()
                                        }
                                    </Col>
                                }
                                {
                                    // display next billing cycle if upcoming invoice is available
                                    upComingInvoice &&
                                    <Col span={24}>
                                        {getNextBillingCycle()}
                                    </Col>
                                }
                                <Col span={24}>
                                    {getTimesheetSummary()}
                                </Col>
                            </Row>
                        }

                        {
                            (contractDetails?.Type == 'Per Ticket') &&
                            getTimesheetSummary()
                        }


                    </Col>
                </Row>


            </>
        )
    }

    // render timesheet modals
    const renderTimeSheetModals = () => {
        return <>
            {
                showTimeEntryModal &&
                <ManualTimeModal
                    showTimeEntryModal={showTimeEntryModal}
                    setShowTimeEntryModal={setShowTimeEntryModal}
                    filteredTickeData={filteredTickeData}
                    repository={repository}
                    contractId={params?.contractId}
                    maxAllowableHours={contractDetails?.Hours_Upper_Limit}
                    contractDetails={contractDetails}
                    setUpdatedTimesheetData={setUpdatedTimesheetData}
                    upComingInvoice={upComingInvoice}
                    isFromTimesheet={true}
                />
            }

            {
                showEditTimeEntryModal &&
                <EditTimeModal
                    showTimeEntryModal={showEditTimeEntryModal}
                    setSelectedTimeEntry={setSelectedTimeEntry}
                    setShowTimeEntryModal={setShowEditTimeEntryModal}
                    filteredTickeData={filteredTickeData}
                    selectedTimeEntry={selectedTimeEntry}
                    upComingInvoice={upComingInvoice}
                    repository={repository}
                    contractId={params?.contractId}
                    maxAllowableHours={contractDetails?.Hours_Upper_Limit}
                    contractDetails={contractDetails}
                    setUpdatedTimesheetData={setUpdatedTimesheetData}
                // getTimeSheetData={getTimeSheetData}
                />
            }
            {
                showReportIssueModal &&

                <ReportIssueModal
                    showReportIssueModal={showReportIssueModal}
                    setSelectedTimeEntry={setSelectedTimeEntry}
                    setShowReportIssueModal={setShowReportIssueModal}
                    contractDetails={contractDetails}
                    selectedTimeEntry={selectedTimeEntry}
                />
            }

            {
                showTimeEntryHistoryModal &&
                <ViewTimeEntryHistory
                    showTimeEntryHistoryModal={showTimeEntryHistoryModal}
                    setShowTimeEntryHistoryModal={setShowTimeEntryHistoryModal}
                    selectedTimeEntry={selectedTimeEntry}
                    setSelectedTimeEntry={setSelectedTimeEntry}
                />
            }

        </>
    }
    // component that returns timesheet summary
    const getTimesheetSummary = () => {
        return <Card
            // title={currTimeFilter === 'all-time' ? 'All' : `${localTimestamp(dateRange?.startDate)} - ${localTimestamp(dateRange?.endDate)}`}
            title={"Summary"}
            style={{ width: "100%" }} className="">
            <Space direction='vertical' size={12} className='w-100'>
                {/* <Space className='w-100 justify-content-between'>
                    <Text className='fw-normal'>Tracked time</Text>
                    <Text className='fw-normal'>{convertTimeFormat(getTrackedHours())}</Text>
                </Space>
                {
                    contractDetails?.Type === 'Hourly' &&
                    <Space className='w-100 justify-content-between'>
                        <Text className='fw-normal'>Manual time</Text>
                        <Text className='fw-normal'>{convertTimeFormat(getManualHours())}</Text>
                    </Space>
                } */}

                {
                    contractDetails?.Type === 'Hourly' &&
                    <Space className='w-100 justify-content-between'>
                        <Text>Hourly Rate</Text>
                        <Text  >${convertCentsIntoDollarsString(Number(contractDetails.Billing_Rate))}</Text>
                    </Space>
                }
                <Space className='w-100 justify-content-between'>
                    <Text className=' fw-normal'>Time Tracked/Manual</Text>
                    <Text
                        className=' fw-normal'>{convertTimeFormat(totalHours)}</Text>
                </Space>

                {
                    (contractDetails?.Type === 'Hourly' || contractDetails?.Type === 'Per Issue' || contractDetails?.Type === 'Per Ticket') &&
                    <>
                        <Divider className='m-0' />

                        <Space className='w-100 justify-content-between' wrap>
                            {
                                IconWithText({
                                    text: "Estimated billing",
                                    tooltip: "This is an estimated cost based on technician activity during the current billing cycle. This is not the final invoice, and the actual cost may vary depending on the billable hours spent by the technician"
                                })
                            }

                            <Text className='tecme-secondary-color' strong={true}>${(Number(contractDetails.Billing_Rate) / 100 * totalHours).toFixed(2)}</Text>
                            {/* <Text className='tecme-secondary-color' strong={true}>${convertCentsIntoDollarsString(Number(upComingInvoice.amount_due))}</Text> */}
                        </Space>
                    </>
                }

            </Space>
        </Card>
    }

    // component that returns action buttons for timesheet record
    const getActionButtons = (record) => {
        let disableEditTimeEntry = false;

        let startOfWeek = upComingInvoice ? moment.unix(upComingInvoice.next_payment_attempt).subtract(1, 'week') : moment().startOf('week')

        // disable edit time entry if clock out date time is before start of week
        if (moment(moment.utc(record.Clock_Out_Date_Time).toDate()).local().isBefore(startOfWeek) && (record?.custom_data?.timestamp)) {
            disableEditTimeEntry = true;
        }

        return <Popover content={(item) => {
            return (
                <div className="card-popover-content">
                    {/* {
                        tech && canEditTimeEntry && (record?.custom_data?.timestamp) && contractDetails?.Status === contractStatuses.Active && (contractDetails?.Type === 'Hourly' || contractDetails?.Type === 'Per Ticket') &&

                        <Space className='popover-action-item' onClick={(e) => {
                            setShowEditTimeEntryModal(true)
                            setSelectedTimeEntry(record)
                            e.stopPropagation()
                        }}>
                            <EditOutlined />
                            <Paragraph style={{ marginBottom: 0 }}>Edit</Paragraph>
                        </Space>
                    } */}
                    {
                        (!!tech) && contractDetails?.Status === contractStatuses.Active && contractDetails?.Allow_Manual_Entry === "1" && (contractDetails?.Type === 'Hourly' || contractDetails?.Type === 'Per Ticket') &&

                        <Tooltip
                            title={disableEditTimeEntry ? "You are only able to make changes to your time log entries for the current week. Please be aware that any entries that have already been billed cannot be modified. If you encounter any issues with a time entry, you can click on the \"Report Issue\" button located on the right-hand side." : ""}>
                            <Space className='popover-action-item' onClick={(e) => {
                                if (!disableEditTimeEntry) {
                                    setShowEditTimeEntryModal(true)
                                    setSelectedTimeEntry(record)
                                    e.stopPropagation()
                                }
                            }}>
                                <EditOutlined/>
                                <Paragraph style={{marginBottom: 0}}>Edit</Paragraph>
                            </Space>
                        </Tooltip>
                    }
                    <Space className='popover-action-item'
                        onClick={(e) => {
                            setSelectedTimeEntry(record)
                            setShowReportIssueModal(true)
                            e.stopPropagation()
                        }}
                    >
                        <ExclamationCircleOutlined />
                        <Paragraph style={{ marginBottom: 0 }}>Report Issue</Paragraph>
                    </Space>

                    {
                        record.custom_data?.updated == 1 &&
                        <Space className='popover-action-item'
                            onClick={(e) => {
                                setSelectedTimeEntry(record)
                                setShowTimeEntryHistoryModal(true)
                                e.stopPropagation()
                            }}
                        >
                            <HistoryOutlined />
                            <Paragraph style={{ marginBottom: 0 }}>View Logs</Paragraph>
                        </Space>
                    }
                </div>
            )
        }} trigger="click" placement='bottomRight' overlayClassName="list-card-popover" arrowPointAtCenter>
            <EllipsisOutlined onClick={(e) => e.stopPropagation()} className='card-ellipsis' />
        </Popover>
    }
    // component that returns weekly limit
    const getWeeklyLimit = () => {
        let weeklyLimit = 0
        if (contractDetails?.Hours_Upper_Limit && contractDetails?.Hours_Upper_Limit !== "null") {
            //convert string to number
            weeklyLimit = Number(contractDetails?.Hours_Upper_Limit)
        }
        let timeSpentHumanize = moment.duration(weeklyLimit - timeSpent, 'hours');

        return <Card
            title={'Weekly hours limit'}
            style={{ width: "100%" }} className="">
            <Space direction='vertical' size={12} className='w-100'>
                <Typography.Title level={4} style={{ padding: 0, margin: 0 }}>
                    {!!(contractDetails.Hours_Upper_Limit && contractDetails.Hours_Upper_Limit !== '0') ? `${Number(timeSpent).toFixed(1)} out of ${contractDetails.Hours_Upper_Limit} hours` : 'No limit'}</Typography.Title>

                <Progress percent={weeklyLimit > 0 ? (timeSpent / weeklyLimit * 100).toFixed(1) : 0} />

                {
                    weeklyLimit > 0 &&
                    <>
                        <Divider className='m-0' />
                        {
                            (weeklyLimit - timeSpent) > 0 &&
                            <Text>{`${(timeSpentHumanize.hours())}h ${(timeSpentHumanize.minutes())}m`} remaining for the current week</Text>
                        }

                        {
                            (weeklyLimit - timeSpent) < 0 &&
                            <Text>Exceeded the weekly hour threshold
                                by {moment.duration(Math.abs(weeklyLimit - timeSpent), 'hours').humanize()}</Text>
                        }

                    </>
                }
            </Space>
        </Card>
    }

    // component that returns next billing cycle
    const getNextBillingCycle = () => {
        return <Card
            title={'Billing Period'}
            style={{ width: "100%" }} className="">
            <Space direction='vertical' size={12} className='w-100'>
                {/*<Typography.Title level={4}>{moment.unix(upComingInvoice.next_payment_attempt).format('MMM Do YYYY, h:mm a')}</Typography.Title>*/}

                <Text strong={true}>Billing Period Start</Text>
                <Typography.Title level={5}>{moment.unix(upComingInvoice.next_payment_attempt).subtract(1, 'week').format('ddd, MMM D, YYYY, h:mm a')}</Typography.Title>
                <Text strong={true}>Billing Period End</Text>
                <Typography.Title level={5}>{moment.unix(upComingInvoice.next_payment_attempt).format('ddd, MMM D, YYYY, h:mm a')}</Typography.Title>

            </Space>
        </Card>
    }

    const getTimesheetMobileContent = () => {
        const timesheetList = ["", "", ""]


        const timeSheetFilters = [
            {
                id: "seven-days",
                name: "This week",
                handleTimeChange: handleThisWeek
            },
            {
                id: "last-week",
                name: "Last week",
                handleTimeChange: handleLastWeek
            },
            {
                id: "this-month",
                name: "This month",
                handleTimeChange: handleThisMonth
            },
            {
                id: "last-month",
                name: "Last Month",
                handleTimeChange: handleLastMonth
            },
            {
                id: "all-time",
                name: "All Time",
                handleTimeChange: () => setFilteredTimeSheetData(timeSheetData)
            },
        ]

        const getWorkItems = (ticket) => {
            const hours = Math.floor(ticket?.Total_Time / 60)
            const mins = Math.floor(ticket?.Total_Time % 60)
            return (
                <Card className='w-100'>
                    <Space direction='vertical' size={12} style={{ width: '100%' }}>
                        <Row gutter={[5, 5]} justify={'space-between'}>
                            <Col>
                                <Text strong>{ticket?.Ticket?.Summary ? ticket?.Ticket?.Summary : "N/A"}</Text>
                            </Col>
                            <Col>
                                {getActionButtons(ticket)}
                            </Col>
                        </Row>

                        <Text className='d-flex align-items-center'>
                            <Space size={2}>
                                <ArrowRightOutlined className='tecme-light-green-color' />
                                <ClockCircleOutlined className='me-2 tecme-light-green-color' />
                            </Space>
                            {getTableTimeFormat(ticket?.Clock_In_Date_Time)}
                        </Text>
                        <Text className='d-flex align-items-center'>
                            <Space size={2}>
                                <ArrowLeftOutlined className='tecme-secondary-color' />
                                <ClockCircleOutlined className='me-2 tecme-secondary-color' />
                            </Space>
                            {getTableTimeFormat(ticket?.Clock_Out_Date_Time)}
                        </Text>
                        <Text className='d-flex align-items-center'>
                            <HourglassOutlined className='me-2' />
                            {`${hours}${hours > 1 ? "hrs" : "hr"} ${mins}m`}
                        </Text>
                    </Space>
                </Card>
            )
        }
        const noTimesheetExistsContent = (
            <>
                <Empty
                    className='empty-timesheet-mobile'
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No time logged by technician yet."
                />
                <Space className='w-100 d-flex justify-content-end'>
                    <Button type='primary' size='large' shape='circle' className='add-ticket-button-mobile'>
                        <PlusOutlined className='fs-5' />
                    </Button>
                </Space>
            </>
        )

        return (
            <>
                <Space direction='vertical' className='w-100 h-100 px-3 pt-3 assigned-ticket-mobile-wrapper' size={12}>
                    <Space className='justify-content-between w-100'>
                        <Title level={3} className='m-0'>Timesheet</Title>
                        {!!tech && contractDetails?.Status === contractStatuses.Active && contractDetails.Type && contractDetails?.Allow_Manual_Entry === "1" && (contractDetails?.Type === 'Hourly') && (
                            <Tooltip
                                title={!!(contractDetails?.Hours_Upper_Limit != '0' && billingWeekTotalHours >= Number(contractDetails?.Hours_Upper_Limit)) ? "Your hourly limit has been reached. You cannot log more hours at this time. Please contact the customer to request an increase in the hourly limit." : !ticketData?.filter((item) => item.Status.toLowerCase() === "open").length ? "You don't have any open tickets, so you can't add a time entry. Please begin support for a ticket to proceed." : ""}>
                                <Button
                                    type='ghost'
                                    onClick={() => setShowTimeEntryModal(true)}
                                    disabled={!!(contractDetails?.Hours_Upper_Limit != '0' && billingWeekTotalHours >= Number(contractDetails?.Hours_Upper_Limit)) || !ticketData?.filter((item) => item.Status.toLowerCase() === "open").length}
                                >
                                    + Add Time Entry
                                </Button>
                            </Tooltip>
                        )}
                    </Space>
                    <Space className='justify-content-between w-100'>
                        <Space className='flex-wrap'>
                            {
                                timeSheetFilters.map((item) => {
                                    return (
                                        <div key={item.id}
                                            className={`contract-type-item ${currTimeFilter === item.id && 'contract-type-item-active'}`}
                                            onClick={() => {
                                                setCurrTimeFilter(item.id)
                                                item.handleTimeChange()
                                            }}
                                        >
                                            {item.name}
                                        </div>
                                    )
                                })
                            }
                        </Space>
                    </Space>
                    {!!timesheetList.length ? (
                        <>
                            <Space direction='vertical' className='w-100'>
                                {
                                    // display next billing cycle if upcoming invoice is available
                                    upComingInvoice &&
                                    getNextBillingCycle()
                                }
                                {getWeeklyLimit()}
                                {getTimesheetSummary()}
                                {filteredTimeSheetData.map(ticket => getWorkItems(ticket))}
                            </Space>
                        </>
                    ) : noTimesheetExistsContent}

                </Space>
                {
                    showTimeEntryModal &&
                    <ManualTimeModal
                        showTimeEntryModal={showTimeEntryModal}
                        setShowTimeEntryModal={setShowTimeEntryModal}
                        filteredTickeData={filteredTickeData}
                        repository={repository}
                        contractId={params?.contractId}
                        maxAllowableHours={contractDetails?.Hours_Upper_Limit}
                        contractDetails={contractDetails}
                        setUpdatedTimesheetData={setUpdatedTimesheetData}
                        isFromTimesheet={true}
                    // getTimeSheetData={getTimeSheetData}
                    />
                }

            </>
        )
    }

    const handleAddNewPaymentMethodClick = () => {
        history.push(ROUTES.ADD_PAYMENT)
    }

    const handlePaymentMethodCheckboxChange = async (method) => {
        setChangingPaymentMethodId(method.id)
        await repository.updateContract(params?.contractId, {
            Payment_Method: method.id
        }).then(async (res) => {
            if (res.data?.status === 'success') {
                setSelectedPaymentMethod(method);
                notification.success({
                    message: 'TecMe',
                    description: 'Payment method changed successfully.'
                })
                setChangingPaymentMethodId(undefined)
            }
        }).catch(error => {
            setChangingPaymentMethodId(undefined)
            console.error(error)
        })
    }

    const spinComponent = (
        <Spin
            className='position-absolute'
            indicator={
                <LoadingOutlined className='fs-5' style={{ color: SECONDARY_COLOR, bottom: '0.8rem', right: '0.8rem' }}
                    spin />
            }
        />
    )

    const handleFileDisputeClick = (record) => {
        setIsFileDisputeModalVisible({
            status: true,
            record
        })
        // Modal.success({
        //     className: 'ticket-updated-successfully-wrapper',
        //     icon: null,
        //     closable: true,
        //     okText: 'Okay',
        //     width: 470,
        //     title: <Title level={3} className='font-weight-500 m-0'>File Dispute</Title>,
        //     content: (
        //         <Space direction='vertical' className='w-100' size={20}>
        //             <Text>
        //                 If you experience any issues with the invoice or would like to file a dispute, please contact TecMe Support for assistance.
        //             </Text>
        //             <Space size={0} direction='vertical' className='w-100'>
        //                 <Text>To contact support representative:</Text>
        //                 <Text>Email - <a href="mailto:support@tecme.io" className='tecme-secondary-color'>support@tecme.io</a></Text>
        //                 <Text><a href="tel:+1 (888) 678-3263" className='text-dark'>Call: (888) 678-3263</a></Text>
        //             </Space>
        //         </Space>
        //     )
        // });
    }

    const getNextBillingCycleContent = () => {
        let billingCycleContent = "";
        if (user && contractDetails.Type == 'Fixed')
            billingCycleContent = `You will be charged $${convertCentsIntoDollarsString(Number(upComingInvoice.amount_due))} on`;
        else if (tech && contractDetails.Type == 'Fixed')
            billingCycleContent = `Customer will be charged $${convertCentsIntoDollarsString(Number(upComingInvoice.amount_due))} on`;
        else if (user && contractDetails.Type == 'Hourly') {
            if (upComingInvoice.amount_due == 0)
                billingCycleContent = ``;
            else
                billingCycleContent = `You will be charged approx. $${convertCentsIntoDollarsString(Number(upComingInvoice.amount_due))} (The final invoice could vary depending on the time spent by the technician) on`;
        } else if (tech && contractDetails.Type == 'Hourly') {
            if (upComingInvoice.amount_due == 0)
                billingCycleContent = ``;
            else
                billingCycleContent = `Customer will be charged approx. $${convertCentsIntoDollarsString(Number(upComingInvoice.amount_due))} (The final invoice could vary depending on the time spent by the technician) on`;
        }
        return billingCycleContent;
    }

    const getBillingContent = () => {
        const fileDisputeCheck = !!invoicesData?.length && !!user;
        const isContractManuallyPaused = (contractDetails.Status === contractStatuses.Paused && contractDetails?.custom_data?.pause_reason === 'manual');
        const firstColumnTitle = contractDetails.Type == "Hourly" || contractDetails.Type == "Fixed" ? "Billing Period" : "Issue Date";
        const columns = [
            {
                key: 'Invoice #',
                title: 'Invoice #',
                dataIndex: 'Invoice #',
                render: (_, record) => (
                    <Text>
                        {record.number}
                    </Text>
                ),
                sorter: {
                    compare: (a, b) => a?.created.localeCompare(b?.created),
                }
            },
            {
                key: 'Amount',
                title: 'Amount',
                dataIndex: 'Amount',
                render: (_, record) => (
                    <Text strong={true}>${record?.total > 0 ? (record?.total / 100).toFixed(2) : record?.total}</Text>
                ),
                sorter: {
                    compare: (a, b) => a?.total.localeCompare(b?.total),
                },
            },
            {
                key: 'status',
                title: 'Status',
                dataIndex: 'status',
                render: (_, record) => (
                    <Tag color={record?.status == 'paid' ? 'green' : null}>{capitalizeFirstLetter(record?.status)}</Tag>
                ),
                sorter: {
                    compare: (a, b) => a?.status.localeCompare(b?.status),
                },
            },

            {
                key: firstColumnTitle,
                title: firstColumnTitle,
                dataIndex: firstColumnTitle,
                render: (_, record) => (
                    <Text>
                        {
                            contractDetails.Type == "Hourly" || contractDetails.Type == "Fixed" ?
                                moment.unix(record.period_start).format('MMM D, HH:mm A') + ' - ' + moment.unix(record.period_end).format('MMM D, HH:mm A (YYYY)') :
                                moment.unix(record.created).format('MMM D HH:mm A YYYY')
                        }
                    </Text>
                ),
                sorter: {
                    compare: (a, b) => a?.created.localeCompare(b?.created),
                }
            },

            {
                key: 'Duration',
                title: 'Duration',
                dataIndex: 'Duration',
                render: (_, record) => {
                    const line = record?.lines?.data[0];
                    const hours = Math.floor(line.quantity / 60);
                    const minutes = line.quantity % 60;
                    return <Text>{contractDetails.Type == "Hourly" || contractDetails.Type == "Per Ticket" ? `${hours}h ${minutes}m at $${contractDetails.Billing_Rate / 100}/h` : 'N/A'}</Text>
                },
                sorter: {
                    compare: (a, b) => a?.total.localeCompare(b?.total),
                },
            },

            {
                key: 'Actions',
                title: 'Actions',
                dataIndex: 'Actions',
                render: (_, record) => {
                    return <BillingPopOver
                        record={record}
                        handleFileDisputeClick={handleFileDisputeClick}
                        fileDisputeCheck={fileDisputeCheck}
                        isContractManuallyPaused={isContractManuallyPaused}
                        contractDetails={contractDetails}
                        user={user}
                    />
                },
            }
        ]

        const activePaymentMethod = paymentMethods.find(method => selectedPaymentMethod?.id === method.id)

        return (
            <Row 
                // className={`${user ? 'active-contract-main-content' : ''}`}
            >
                <Space direction='vertical' className='w-100' size={12}>
                    <Space className='flex-wrap justify-content-between w-100'>
                        <Title level={3} className='m-0'>Invoices</Title>
                    </Space>
                    <Table
                        dataSource={invoicesData}
                        columns={columns}
                        locale={{
                            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={<Space direction='vertical'>
                                    <Text type='secondary'>No invoices found in the contract</Text>
                                </Space>} />
                        }}
                    />
                </Space>
                {/* {!!user && (
                    <Card title="Payment Method">
                        {isPaymentMethodsApiCallInProgress && (
                            <Space className="w-100 position-relative justify-content-center align-items-center">
                                {spinComponent}
                            </Space>
                        )}
                        {!!paymentMethods.length && (
                            <Space direction='vertical' size={12} className='scroll payment-methods-wrapper pe-1 w-100'>
                                <Space
                                    className="w-100 justify-content-between align-items-start payment-method selected-payment-method">
                                    <Space className='align-items-start'>
                                        <Image
                                            src={getCardDetails(activePaymentMethod?.card.brand)?.logo}
                                            width={40}
                                            height={25}
                                            preview={false}
                                            alt={getCardDetails(activePaymentMethod?.card.brand)?.title}
                                            className={activePaymentMethod?.card.brand === "amex" ? "payment-image" : ""}
                                        />
                                        <Space direction='vertical' size={0}>
                                            <Paragraph className='m-0 fs-6'
                                                strong>{getCardDetails(activePaymentMethod?.card.brand)?.title} **** {activePaymentMethod?.card?.last4}</Paragraph>
                                            <Paragraph type='secondary'
                                                className='m-0 font-size-14'>Expiry {activePaymentMethod?.card.exp_month}/{activePaymentMethod?.card.exp_year}</Paragraph>
                                        </Space>
                                    </Space>
                                    {contractDetails.Status !== contractStatuses.Terminated && (
                                        <Checkbox
                                            style={{ borderRadius: '50%' }}
                                            checked={activePaymentMethod?.id === selectedPaymentMethod?.id}
                                            onChange={() => handlePaymentMethodCheckboxChange(activePaymentMethod)}
                                        />
                                    )}
                                </Space>
                                {contractDetails.Status !== contractStatuses.Terminated && paymentMethods.filter(method => method.id !== activePaymentMethod.id).map(method => {
                                    const name = method.card.brand
                                    const id = method.id
                                    const expMonth = method.card.exp_month
                                    const expYear = method.card.exp_year
                                    const cardDetails = getCardDetails(name);

                                    return (
                                        <Space
                                            className={`w-100 payment-method position-relative ${changingPaymentMethodId === id ? 'selected-payment-method justify-content-center align-items-center' : 'justify-content-between align-items-start'}`}
                                            key={id}>
                                            {changingPaymentMethodId === id ? (
                                                spinComponent
                                            ) : (
                                                <>
                                                    <Space className='align-items-start'>
                                                        <Image
                                                            src={cardDetails.logo}
                                                            width={40}
                                                            height={25}
                                                            preview={false}
                                                            alt={cardDetails.title}
                                                            className={name === "amex" ? "payment-image" : ""}
                                                        />
                                                        <Space direction='vertical' size={0}>
                                                            <Paragraph className='m-0 fs-6'
                                                                strong>{cardDetails.title} **** {method.card?.last4}</Paragraph>
                                                            <Paragraph type='secondary'
                                                                className='m-0 font-size-14'>Expiry {expMonth}/{expYear}</Paragraph>
                                                        </Space>
                                                    </Space>
                                                    <Checkbox
                                                        style={{ borderRadius: '50%' }}
                                                        checked={id === selectedPaymentMethod?.id}
                                                        onChange={() => handlePaymentMethodCheckboxChange(method)}
                                                        disabled={contractDetails.Status === contractStatuses.Terminated || canReadPageOnly(user?.role)}
                                                    />
                                                </>
                                            )}
                                        </Space>
                                    )
                                })}
                            </Space>
                        )}
                        {(contractDetails.Status !== contractStatuses.Terminated && user?.role.toLowerCase() === "administrator") && (
                            <Button
                                className='w-100 mt-3 add-payment-method-button d-flex align-items-center justify-content-center'
                                onClick={handleAddNewPaymentMethodClick}
                                id='file-dispute-button'
                                disabled={canReadPageOnly(user?.role)}
                            >
                                + Add new payment method
                            </Button>
                        )}
                    </Card>
                )} */}
            </Row>
        )
    }

    const getBillingMobileContent = () => {
        const getInvoiceCard = (ticket) => {
            const fileDisputeCheck = !!invoicesData?.length && !!user;
            const isContractManuallyPaused = (contractDetails.Status === contractStatuses.Paused && contractDetails?.custom_data?.pause_reason === 'manual')
            const record = ticket;
            const line = record?.lines?.data[0];
            const hours = Math.floor(line.quantity / 60);
            const minutes = line.quantity % 60;
            return (
                <Card title={<Text strong>Invoice #{ticket.number}</Text>} className='w-100'
                    extra={
                        !canReadPageOnly(user?.role) &&
                        <BillingPopOver
                            record={ticket}
                            handleFileDisputeClick={handleFileDisputeClick}
                            fileDisputeCheck={fileDisputeCheck}
                            isContractManuallyPaused={isContractManuallyPaused}
                            contractDetails={contractDetails}
                            user={user}
                        />
                    }
                >
                    <Space direction='vertical' size={12} className="w-100">
                        <Text className='d-flex align-items-center'>
                            <CalendarOutlined className='me-2' />
                            {
                                contractDetails.Type == "Hourly" || contractDetails.Type == "Fixed" ?
                                    moment.unix(ticket.period_start).format('MMM D, HH:mm A') + ' - ' + moment.unix(ticket.period_end).format('MMM D, HH:mm A (YYYY)') :
                                    moment.unix(ticket.created).format('MMM D HH:mm A YYYY')
                            }
                        </Text>
                        <Text className='d-flex align-items-center'>
                            <ClockCircleOutlined className='me-2' />
                            {contractDetails.Type == "Hourly" || contractDetails.Type == "Per Ticket" ? `${hours}h ${minutes}m at $${contractDetails.Billing_Rate / 100}/h` : 'N/A'}
                        </Text>
                        <Text className='d-flex align-items-center' strong={true}>
                            <DollarCircleOutlined className='me-2' />
                            ${ticket?.total > 0 ? (ticket?.total / 100).toFixed(2) : ticket?.total}
                        </Text>
                        <Tag color={ticket?.status == 'paid' ? 'green' : null}>{capitalizeFirstLetter(ticket?.status)}</Tag>

                    </Space>
                </Card>
            )
        }
        const noInvoicesExistsContent = (
            <>
                <Title level={3} className='m-0'>Invoices</Title>
                <Empty
                    className='empty-invoices-mobile'
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No invoices found in the contract"
                />
            </>
        )
        const getSelectedPaymentMethod = () => {
            const name = selectedPaymentMethod?.card.brand
            const cardDetails = getCardDetails(name);

            return (
                <Space className='w-100'>
                    <Space className="align-items-start payment-method selected-payment-method">
                        <Checkbox style={{ borderRadius: '50%' }} checked onChange={(event) => event.preventDefault()} />
                        <Space className='align-items-start'>
                            <Image
                                src={cardDetails.logo}
                                width={40}
                                height={25}
                                preview={false}
                                alt={cardDetails.title}
                                className={name === "amex" ? "payment-image" : ""}
                            />
                            <Paragraph className='m-0 fs-6'
                                strong>{cardDetails.title} **** {selectedPaymentMethod?.card?.last4}</Paragraph>
                        </Space>
                    </Space>
                    {contractDetails.Status !== contractStatuses.Terminated && (
                        <Text strong onClick={() => {
                            if (contractDetails.Status !== contractStatuses.Terminated) {
                                setIsMobilePaymentChangeVisible(true)
                            }
                        }}>Change</Text>
                    )}
                </Space>
            )
        }

        return (
            <Space direction='vertical' className='w-100 h-100 px-3 assigned-ticket-mobile-wrapper' size={12}>
                {/* {!!user && (
                    <>
                        <Title level={4} className='m-0'>Select payment method</Title>
                        {getSelectedPaymentMethod()}
                    </>
                )} */}
                {!!invoicesData.length ? invoicesData.map(ticket => (
                    <Space direction='vertical' className='w-100' key={ticket.id}>
                        {getInvoiceCard(ticket)}
                    </Space>
                )) : noInvoicesExistsContent}

            </Space>
        )
    }

    const handleEditContractClick = () => {
        setEditContractModalInfo(prevState => ({
            ...prevState,
            isVisible: true
        }))
    }

    const handleEndContractClick = () => {
        setIsEndContractModalVisible(true)
    }

    const handlePauseContractClick = () => {
        setIsPauseContractModalVisible(true)
    }

    const handleResumeContractClick = () => {
        setIsContractResumeModalVisible(true)
    }

    const getDetailsContent = () => {
        return (
            <>
                <Space className='w-100 justify-content-between mb-3'>
                    <Title level={3} className='m-0'>Details</Title>
                    <Space size={6}>
                        <Badge status={contractDetails?.Status === contractStatuses.Active ? 'success' : 'error'} />
                        <Text>
                            {contractDetails?.Status === contractStatuses.Terminated ? 'Ended' : contractDetails?.Status}
                        </Text>
                    </Space>
                </Space>
                <Row className='active-contract-main-content'>
                    <Card>
                        <Space direction='vertical' className='w-100' size={12}>
                            <Title level={4} className='m-0'>Description</Title>
                            <Paragraph className='mb-3'>
                                {capitalizeFirstLetter(contractDetails.Description)}
                            </Paragraph>
                            <Descriptions bordered>
                                {!!requiredSkillsList?.length && (
                                    <>
                                        <Item span={2}>
                                            <Title level={5} className='m-0'>
                                                Required IT Skills
                                            </Title>
                                        </Item>
                                        <Item span={2}>
                                            <Space className='flex-wrap'>
                                                {requiredSkillsList?.map(skill => <Tag key={skill}>{skill}</Tag>)}
                                            </Space>
                                        </Item>
                                    </>
                                )}
                                {!!contractDetails.Active_Date_Time && (
                                    <>
                                        <Item span={2}>
                                            <Title level={5} className='m-0'>
                                                Created Date
                                            </Title>
                                        </Item>
                                        <Item span={2}>
                                            <Text>{getTableTimeFormat(contractDetails.Active_Date_Time)}</Text>
                                        </Item>
                                    </>
                                )}
                                <Item span={2}>
                                    <Title level={5} className='m-0'>
                                        Contract Type
                                    </Title>
                                </Item>
                                <Item span={2}>
                                    <Text>{contractDetails.Type == 'Fixed' ? 'Retainer' : contractDetails.Type == 'Per Ticket' ? 'Per Issue' : contractDetails.Type}</Text>
                                </Item>
                                <Item span={2}>
                                    <Title level={5} className='m-0'>
                                        Contract Rate
                                    </Title>
                                </Item>
                                <Item span={2}>
                                    <Text>${convertCentsIntoDollarsString(Number(contractDetails.Billing_Rate))}{getBillingCycle()}</Text>
                                </Item>


                                {contractDetails?.Type === 'Hourly' && (
                                    <>
                                        <Item span={2}>
                                            <Title level={5} className='m-0'>
                                                Allow manual time entries
                                            </Title>
                                        </Item>
                                        <Item span={2}>
                                            <Text>{!!(contractDetails.Allow_Manual_Entry === '1' || contractDetails.Allow_Manual_Entry === 'true') ? 'Yes' : 'No'}</Text>
                                        </Item>
                                        <Item span={2}>
                                            <Title level={5} className='m-0'>
                                                Weekly hours limit
                                            </Title>
                                        </Item>
                                        <Item span={2}>
                                            <Text>{!!(contractDetails.Hours_Upper_Limit && contractDetails.Hours_Upper_Limit !== '0') ? contractDetails.Hours_Upper_Limit : 'None'}</Text>
                                        </Item>
                                    </>
                                )}
                                {contractDetails?.Type === 'Fixed' && (
                                    <>
                                        <Item span={2}>
                                            <Title level={5} className='m-0'>
                                                Billing Interval
                                            </Title>
                                        </Item>
                                        <Item span={2}>
                                            <Text>{contractDetails.Billing_Frequency}</Text>
                                        </Item>
                                    </>
                                )}
                            </Descriptions>
                        </Space>
                    </Card>
                    <Space direction='vertical' size={12}>
                        {
                        ((!!tech && !!itcDetails) && tech?.id !== itcDetails?.Owner_Contact) ? null :
                        (contractDetails.Status !== contractStatuses.Terminated && !canReadPageOnly(user?.role)) && (
                            <Card title="Actions">
                                <Space direction='vertical' className='w-100'>
                                    {!!user && contractDetails.Status === contractStatuses.Paused && contractDetails?.custom_data?.pause_reason === 'manual' ? (
                                        <Button
                                            id='file-dispute-button'
                                            size='large'
                                            className='w-100'
                                            onClick={handleResumeContractClick}
                                            disabled={canReadPageOnly(user?.role)}
                                        >
                                            Resume Contract
                                        </Button>
                                    ) : !!user && contractDetails.Status !== contractStatuses.Paused ? (
                                        <Button
                                            id='file-dispute-button'
                                            size='large'
                                            className='w-100'
                                            onClick={handlePauseContractClick}
                                            disabled={canReadPageOnly(user?.role)}>
                                            Pause Contract
                                        </Button>
                                    ) : ''}
                                    <Button
                                        id='file-dispute-button'
                                        size='large'
                                        className='w-100'
                                        onClick={handleEndContractClick}
                                        disabled={canReadPageOnly(user?.role)}>
                                        End Contract
                                    </Button>
                                    {!!user && contractDetails.Status !== contractStatuses.Terminated && contractDetails.Status !== contractStatuses.Paused && (
                                        <Button
                                            id='file-dispute-button'
                                            size='large'
                                            className='w-100'
                                            onClick={handleEditContractClick}
                                            disabled={canReadPageOnly(user?.role)}>
                                            Edit Contract Details
                                        </Button>
                                    )}
                                </Space>
                            </Card>
                        )}
                        <Card title="Activities" className='activity-card-body'>
                            {!!activitiesList.length ? (
                                <Space direction='vertical' size={15} className='activites-wrapper-desktop'>
                                    <div className='activites-inner'>
                                        {activitiesList.map(activity => (
                                            <Space className='w-100' align='start'>
                                                <Avatar shape='circle' size={40}
                                                    src={activity?.Contact?.imgUrl ? activity?.Contact?.imgUrl : TECME_LOGO}
                                                    alt={activity?.Contact?.Full_Name && activity?.Contact?.Full_Name}>
                                                    {activity?.Contact?.Full_Name && activity?.Contact?.Full_Name[0]?.toUpperCase()}
                                                </Avatar>
                                                <Space direction='vertical' size={0}>
                                                    <Text
                                                        strong>{activity?.Contact?.Full_Name ? capitalizeFirstWordOfEveryLetter(activity?.Contact?.Full_Name) : "N/A"}</Text>
                                                    <Text>{activity.Message}</Text>
                                                    <Text
                                                        type='secondary'>{getTableTimeFormat(activity?.CreatedDate)}</Text>
                                                </Space>
                                            </Space>
                                        ))}
                                    </div>
                                </Space>
                            ) : (
                                <Paragraph className='mb-2 w-100'>No activities found</Paragraph>
                            )}
                            <Divider className='mt-4' />
                            <Paragraph type='secondary' className='mt-4 w-100 text-center'>- End of activity log
                                -</Paragraph>
                        </Card>
                    </Space>
                </Row>
            </>
        )
    }

    const getBillingCycle = () => {
        let billingCycle = '/hr'
        if (contractDetails.Type === 'Fixed') {
            switch (contractDetails.Billing_Frequency) {
                case "Monthly":
                    billingCycle = '/month'
                    break;
                case "Weekly":
                    billingCycle = '/week'
                    break;
                case "Bi-weekly":
                    billingCycle = ' bi-weekly'
                    break;
                default:
                    break;
            }
        }

        return billingCycle
    }

    const getDetailsMobileContent = () => {
        return (
            <Space direction='vertical' className='w-100 h-100 px-3 assigned-ticket-mobile-wrapper' size={12}>
                <Space className='justify-content-between w-100'>
                    <Title level={3} className='m-0'>Details</Title>
                    <Space size={6}>
                        <Badge status={contractDetails?.Status === contractStatuses.Active ? 'success' : 'error'} />
                        <Text>
                            {contractDetails?.Status === contractStatuses.Terminated ? 'Ended' : contractDetails?.Status}
                        </Text>
                    </Space>
                </Space>
                <Space>
                    {['Details', 'Activities'].map(activity => (
                        <div
                            className={`contract-type-item ${detailsMobileViewSelectedTab === activity ? 'contract-type-item-active' : ''}`}
                            key={activity}
                            onClick={() => setDetailsMobileViewSelectedTab(activity)}
                        >
                            {activity}
                        </div>
                    ))}
                </Space>
                {detailsMobileViewSelectedTab === 'Details' ? (
                    <>
                        <Card>
                            <Space className='w-100' size="large" direction='vertical'>
                                <Space direction='vertical' size={5}>
                                    <Title level={5} className='fs-6 m-0'>Details</Title>
                                    <Text>
                                        {capitalizeFirstLetter(contractDetails.Description)}
                                    </Text>
                                </Space>
                                {!!requiredSkillsList?.length && (
                                    <Space direction='vertical' size={5}>
                                        <Title level={5} className='fs-6 m-0'>Skills Required</Title>
                                        <Space className='flex-wrap'>
                                            {requiredSkillsList?.map(skill => <Tag key={skill}>{skill}</Tag>)}
                                        </Space>
                                    </Space>
                                )}
                                {!!contractDetails.Active_Date_Time && (
                                    <Space direction='vertical' size={5}>
                                        <Title level={5} className='fs-6 m-0'>Created Date</Title>
                                        <Text>
                                            {getTableTimeFormat(contractDetails.Active_Date_Time)}
                                        </Text>
                                    </Space>
                                )}
                                <Space className='justify-content-between w-100'>
                                    <Space direction='vertical' size={5}>
                                        <Title level={5} className='fs-6 m-0'>Contract Type</Title>
                                        <Text>{capitalizeFirstLetter(contractDetails.Type == 'Fixed' ? 'Retainer' : contractDetails.Type == 'Per Ticket' ? 'Per Issue' : contractDetails.Type)}</Text>
                                    </Space>
                                    <Space direction='vertical' size={5}>
                                        <Title level={5} className='fs-6 m-0'>Contract Rate</Title>
                                        <Text>${convertCentsIntoDollarsString(Number(contractDetails.Billing_Rate))}{getBillingCycle()}</Text>
                                    </Space>
                                </Space>
                                {contractDetails?.Type === 'Hourly' && (
                                    <>
                                        <Space direction='vertical' size={5}>
                                            <Title level={5} className='fs-6 m-0'>Allow manual time entries</Title>
                                            <Text>{!!(contractDetails.Allow_Manual_Entry === '1' || contractDetails.Allow_Manual_Entry === 'true') ? 'Yes' : 'No'}</Text>
                                        </Space>
                                        <Space direction='vertical' size={5}>
                                            <Title level={5} className='fs-6 m-0'>Weekly hours limit</Title>
                                            <Text>{!!(contractDetails.Hours_Upper_Limit && contractDetails.Hours_Upper_Limit !== '0') ? contractDetails.Hours_Upper_Limit : 'None'}</Text>
                                        </Space>
                                    </>
                                )}
                                {
                                    upComingInvoice != null &&
                                    <>
                                        <Space direction='vertical' size={5}>
                                            <Title level={5} className='fs-6 m-0'> Next Billing Cycle</Title>
                                            <Text style={{
                                                fontSize: 13,
                                                color: 'grey'
                                            }}>{getNextBillingCycleContent()}</Text>
                                            <br />
                                            <Text>{moment.unix(upComingInvoice.next_payment_attempt).format('MMM Do YYYY, h:mm a')}</Text>
                                        </Space>
                                    </>
                                }
                                {contractDetails?.Type === 'Fixed' && (
                                    <Space direction='vertical' size={5}>
                                        <Title level={5} className='fs-6 m-0'>Billing Interval</Title>
                                        <Text>{contractDetails.Billing_Frequency}</Text>
                                    </Space>
                                )}
                            </Space>
                        </Card>
                        {
                        ((!!tech && !!itcDetails) && tech?.id !== itcDetails?.Owner_Contact) ? null :
                        (contractDetails.Status !== contractStatuses.Terminated && !canReadPageOnly(user?.role)) && (
                            <Card title="Actions">
                                <Space direction='vertical' className='w-100'>
                                    {!!user && contractDetails.Status === contractStatuses.Paused && contractDetails?.custom_data?.pause_reason === 'manual' ? (
                                        <Button size='large' className='w-100' onClick={handleResumeContractClick}>
                                            Resume Contract
                                        </Button>
                                    ) : !!user && contractDetails.Status !== contractStatuses.Paused ? (
                                        <Button size='large' className='w-100' onClick={handlePauseContractClick}>
                                            Pause Contract
                                        </Button>
                                    ) : ''}
                                    <Button size='large' className='w-100' onClick={handleEndContractClick}>End
                                        Contract</Button>
                                    {!!user && contractDetails.Status !== contractStatuses.Terminated && contractDetails.Status !== contractStatuses.Paused && (
                                        <Button size='large' onClick={handleEditContractClick} className='w-100'>Edit
                                            Contract Details</Button>
                                    )}
                                </Space>
                            </Card>
                        )}
                    </>
                ) : (
                    <Card title="Activities">
                        {!!activitiesList.length ? (
                            <Space direction='vertical' size={15}>
                                {activitiesList.map(activity => (
                                    <Space className='w-100' align='start'>
                                        <Avatar shape='circle' size={40} src={activity.Contact?.imgUrl || TECME_LOGO}
                                            alt={activity.Contact?.Full_Name}>
                                            {activity.Contact?.Full_Name[0].toUpperCase()}
                                        </Avatar>
                                        <Space direction='vertical' size={0}>
                                            <Text
                                                strong>{capitalizeFirstWordOfEveryLetter(activity.Contact?.Full_Name)}</Text>
                                            <Text>{activity.Message}</Text>
                                            <Text type='secondary'>{getTableTimeFormat(activity.CreatedDate)}</Text>
                                        </Space>
                                    </Space>
                                ))}
                            </Space>
                        ) : (
                            <Paragraph className='mb-2 w-100'>No activities found</Paragraph>
                        )}
                        <Divider className='mt-4' />
                        <Paragraph type='secondary' className='mt-4 w-100 text-center'>- End of activity log
                            -</Paragraph>
                    </Card>
                )}

            </Space>
        )
    }

    const getSelectedTabContent = () => {
        switch (selectedTab) {
            case 'assigned_tickets':
                return getAssignedTicketsContent()
            case 'messages':
                return getMessagesContent()
            case 'timesheet':
                return (isMobile || isTablet) ? getTimesheetMobileContent() : getTimesheetContent()
            case 'billing':
                return (isMobile || isTablet) ? getBillingMobileContent() : getBillingContent()
            case 'details':
                return (isMobile || isTablet) ? getDetailsMobileContent() : getDetailsContent()
            default:
                return <Text strong className='fs-1'>No Content Found</Text>
        }
    }

    const assignedTicketsTitleMobile = (
        <Space direction='vertical' size={0}>
            <Paragraph className={`m-0 ${!activeTabId ? 'text-white' : ''}`}>Assigned</Paragraph>
            <Paragraph className={`m-0 text-center ${!activeTabId ? 'text-white' : ''}`}>Tickets</Paragraph>
        </Space>
    )

    const handleResumeContractConfirmation = async () => {
        setIsContractResumeApiCallInProgress(true)
        try {
            const res = await repository.resumeContract(params?.contractId, {
                Technician_Contact: contractDetails?.Provider_Org?.HIPAA_Primary_Contact
            })
            if (res.data?.status === 'success') {
                const name = nameFormat(`${user?.firstname} ${user?.lastname}`);
                const location = window.location.href;
                // await sendMessage(`${name} has resumed the contract. <br><br> To view the details, simply click on the link below:<br> ${location}`)
                await getAndSetContractDetails()
                await getAndSetActivities()
                setIsContractResumeApiCallInProgress(false)
                setIsContractResumeModalVisible(false)
                notification.success({
                    message: "TecMe",
                    description: "This contract has been resumed.",
                });
            }
        } catch (error) {
            console.error(error)
            setIsContractResumeApiCallInProgress(false)
        }
    }

    const sendMessage = async (message) => {
        const activeUser = user || tech
        await FirebaseHelper.login();
        await FirestoreHelper.sendMessage({
            conversation_id: activeConversation.id,
            message: encryptString(message),
            user_id: activeUser.id
        });
        await rtmRepository?.sendMessage({
            conversationId: activeConversation.id,
            message,
            createdAt: moment().utc().toISOString()
        })
    }

    // useEffect(() => {
    //     if(tech?.id !== itcDetails?.Owner_Contact){
    //         setSelectedTab('details')
    //         tabsList = tabsList.filter((ele) => ele?.key === "details")
    //     }else{
    //         setSelectedTab("assigned_tickets")
    //     }
    // }, [tech, itcDetails])

    return (
        <>

            <Content className="main">
                <div className="page view-edit-contract-proposal">
                    <Row gutter={[20, (isMobile || isTablet) ? 0 : 20]}>
                        <Col span={24}>
                            <PageHeaderWrapper
                                routes={[
                                    {
                                        path: ROUTES.CONTRACTS,
                                        breadcrumbName: 'Contracts',
                                    },
                                    {
                                        breadcrumbName: `Contract`,
                                    },
                                ]}
                            />
                        </Col>
                        <Col span={24} className='active-contract-proposal'>
                            {(isMobile || isTablet) ? (
                                <div>
                                    <div style={{padding: "0 25px"}}>
                                        {(contractDetails.Status === contractStatuses.Terminated || contractDetails.Status === contractStatuses.Paused || (contractDetails.Status === contractStatuses.Active &&contractDetails?.custom_data?.pause_reason === 'payment_failure' && !!contractDetails?.custom_data?.invoice_url)) ? getDisclaimer() : contractProposalHeader()}
                                            {contractDetails.Status === contractStatuses.Active && showDisclaimer && disclaimer}
                                    </div>
                                   {getSelectedTabContent()}
                                </div>
                            ) : (
                                <div className="auth-container">
                                    <Space direction='vertical' className='w-100'>
                                        {(contractDetails.Status === contractStatuses.Terminated || contractDetails.Status === contractStatuses.Paused || (contractDetails.Status === contractStatuses.Active &&contractDetails?.custom_data?.pause_reason === 'payment_failure' && !!contractDetails?.custom_data?.invoice_url)) ? getDisclaimer() : contractProposalHeader()}
                                        {contractDetails.Status === contractStatuses.Active && showDisclaimer && disclaimer}
                                        <Row gutter={[12, 12]}>
                                            <Col span={24}>
                                                <Tabs
                                                    defaultActiveKey={isMessagedTabSelected ? "messages" : "assigned_tickets"}
                                                    activeKey={selectedTab}
                                                    onChange={(key) => setSelectedTab(key)}>
                                                    {tabsList.filter((ele) => user?.role?.toLowerCase() === "recruiter" ? ele?.key !== "billing" : ele).map(tab => (
                                                        <TabPane key={tab.key} tab={tab.name}>
                                                            <Card
                                                                className={selectedTab === 'assigned_tickets' ? 'seleted-tab-container-assigned-ticket' : 'w-100'}>
                                                                {getSelectedTabContent()}
                                                            </Card>
                                                        </TabPane>
                                                    ))}
                                                </Tabs>
                                            </Col>
                                        </Row>
                                    </Space>
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
            </Content>
            {
                renderTimeSheetModals()
            }
            {
                editContractModalInfo.isVisible &&
                <EditContractModal
                    user={user}
                    isActiveMode={contractDetails?.Status === contractStatuses.Active}
                    data={editContractModalInfo}
                    visible={editContractModalInfo.isVisible}
                    onCancel={() => setEditContractModalInfo(prevState => ({
                        ...prevState,
                        isVisible: false
                    }))}
                    onOk={() => setEditContractModalInfo(prevState => ({
                        ...prevState,
                        isVisible: false
                    }))}
                    contractStatus='active'
                    sendMessage={sendMessage}
                    getAndSetContractDetails={getAndSetContractDetails}
                    contractDetails={contractDetails}
                    contractId={params?.contractId}
                    repository={repository}
                />
            }

            <FeedbackModal
                user={user}
                tech={tech}
                role={user ? 'customer' : 'technician'}
                getAndSetContractDetails={getAndSetContractDetails}
                contractDetails={contractDetails}
                contractId={params?.contractId}
                repository={repository}
                visible={isFeedbackModalVisible}
                onOk={() => {
                    setIsFeedbackModalVisible(false)
                }}
                onCancel={() => setIsFeedbackModalVisible(false)}
            />
            <EndContractModal
                user={user}
                tech={tech}
                role={user ? 'customer' : 'technician'}
                sendMessage={sendMessage}
                getAndSetContractDetails={getAndSetContractDetails}
                contractDetails={contractDetails}
                contractId={params?.contractId}
                repository={repository}
                visible={isEndContractModalVisible}
                ticketData={ticketData}
                upCommingInvoice={(invoice) => {
                    setUpComingInvoice(invoice)
                }}
                onOk={() => {
                    setIsEndContractModalVisible(false);
                    setIsFeedbackModalVisible(true)
                }}
                onCancel={() => setIsEndContractModalVisible(false)}
                setSelectedTab={setSelectedTab}
            />
            <PaymentChangeModal
                spinComponent={spinComponent}
                visible={isMobilePaymentChangeVisible}
                onOk={() => setIsMobilePaymentChangeVisible(false)}
                onCancel={() => setIsMobilePaymentChangeVisible(false)}
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                paymentMethods={paymentMethods}
                disabled={contractDetails.Status === contractStatuses.Terminated}
                handleAddNewPaymentMethodClick={handleAddNewPaymentMethodClick}
                handlePaymentMethodCheckboxChange={handlePaymentMethodCheckboxChange}
                changingPaymentMethodId={changingPaymentMethodId}
            />
            <NewSupportTicketModal
                user={user}
                tech={tech}
                sendMessage={sendMessage}
                getAndSetContractDetails={getAndSetContractDetails}
                contractDetails={contractDetails}
                contractId={params?.contractId}
                repository={repository}
                visible={isNewSupportTicketModalVisible}
                onOk={() => setIsNewSupportTicketModalVisible(false)}
                onCancel={() => setIsNewSupportTicketModalVisible(false)}
                isFromViewEditContractPage
            />
            <FileDisputeModal
                getAndSetActivities={getAndSetActivities}
                invoicesData={invoicesData}
                user={user}
                sendMessage={sendMessage}
                getAndSetContractDetails={getAndSetContractDetails}
                contractDetails={contractDetails}
                contractId={params?.contractId}
                repository={repository}
                visible={isFileDisputeModalVisible.status}
                activeInvoice={isFileDisputeModalVisible.record}
                onOk={() => setIsFileDisputeModalVisible({
                    status: false,
                    record: undefined
                })}
                onCancel={() => setIsFileDisputeModalVisible({
                    status: false,
                    record: undefined
                })}
            />
            <PauseContractModal
                user={user}
                tech={tech}
                role={user ? 'customer' : 'technician'}
                sendMessage={sendMessage}
                getAndSetContractDetails={getAndSetContractDetails}
                contractDetails={contractDetails}
                contractId={params?.contractId}
                repository={repository}
                visible={isPauseContractModalVisible}
                onOk={() => setIsPauseContractModalVisible(false)}
                onCancel={() => setIsPauseContractModalVisible(false)}
            />
            <Modal
                visible={isContractResumeModalVisible}
                title={null}
                onCancel={() => isContractResumeApiCallInProgress ? false : setIsContractResumeModalVisible(false)}
                className="resolve-ticket-modal"
                footer={false}
            >
                <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
                <Space className='w-100' direction='vertical'>
                    <div className='disclaimer p-2'>
                        <Text>
                            Resuming the contract will resume all the activities under the contract, including ticket
                            handling, and billing. With the contract now resumed, the respective technician will also be
                            notified and able to move forward with providing the level of service you expect.
                        </Text>
                    </div>
                </Space>
                <Space align='center' className='w-100 justify-content-end mt-4'>
                    <Button type='text'
                        onClick={() => isContractResumeApiCallInProgress ? false : setIsContractResumeModalVisible(false)}>Cancel</Button>
                    <Button
                        type='primary'
                        loading={isContractResumeApiCallInProgress}
                        onClick={handleResumeContractConfirmation}
                    >
                        Resume Contract
                    </Button>
                </Space>
            </Modal>
            {windowWidth <= 991 && (
                <BottomNavigation
                    activeBgColor={SECONDARY_COLOR}
                    activeTextColor='white'
                    items={[
                        {
                            title: assignedTicketsTitleMobile,
                            icon: <ScheduleOutlined style={{ fontSize: '14px' }} />,
                            activeIcon: <ScheduleOutlined style={{ fontSize: '14px', color: 'white' }} />
                        },
                        // {
                        //     title: 'Messages',
                        //     icon: <MessageOutlined style={{ fontSize: '14px' }} />,
                        //     activeIcon: <MessageOutlined style={{ fontSize: '14px', color: 'white' }} />
                        // },
                        {
                            title: 'Timesheet',
                            icon: <FieldTimeOutlined style={{ fontSize: '14px' }} />,
                            activeIcon: <FieldTimeOutlined style={{ fontSize: '14px', color: 'white' }} />
                        },
                        {
                            title: 'Billing',
                            icon: <DollarCircleOutlined style={{ fontSize: '14px' }} />,
                            activeIcon: <DollarCircleOutlined style={{ fontSize: '14px', color: 'white' }} />
                        },
                        {
                            title: 'Details',
                            icon: <BarsOutlined style={{ fontSize: '14px' }} />,
                            activeIcon: <BarsOutlined style={{ fontSize: '14px', color: 'white' }} />
                        }
                    ].filter((ele) => user?.role?.toLowerCase() === "recruiter" ? ele?.title !== "Billing" : ele)}
                    defaultSelected={activeTabId}
                    onItemClick={(item) => {
                        if (typeof item.title === 'string') {
                            switch (item.title) {
                                case 'Messages':
                                    setSelectedTab('messages')
                                    break;
                                case 'Timesheet':
                                    setSelectedTab('timesheet')
                                    break;
                                case 'Billing':
                                    setSelectedTab('billing')
                                    break;
                                case 'Details':
                                    setSelectedTab('details')
                                    break;
                                default:
                                    break;
                            }
                        } else {
                            setSelectedTab('assigned_tickets')
                        }
                        setActiveTabId(item.id)
                        document.querySelector("body")?.scrollIntoView()
                    }}
                />
            )}
        </>
    )
}

export default ActiveContract