import { Avatar, Image, List, Rate, Row, Space, Typography } from 'antd'
import React from 'react'
import NoReview from '../assets/images/no-review.png'
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import { capitalizeFirstLetter } from '../../../../../utils/helper';
import { UserOutlined } from '@ant-design/icons';
import { ApiBaseHelper } from '../../../../../utils/api-base-helper';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';


const {Title, Text, Paragraph } =Typography
const moment = extendMoment(Moment);
 
const TechReviews = (data) => {

    const [initialReviewsToRender, setInitialReviewsToRender] = useState(5)

    const handleSeeMoreClick = () => {
        setInitialReviewsToRender((prevState) =>  prevState + 5)
    }

    const techNameFirstWord = data?.data?.Data?.Full_Name?.split(" ")[0]

    return (
    <>
        {data?.data?.Reviews?.length ? (
            <Space direction="vertical" className="py-4 w-100">
                <Title level={3}>Reviews ({data?.data?.Reviews?.length})</Title>
                <List
                    itemLayout="horizontal"
                    size="large"
                    loadMore={initialReviewsToRender <= data?.data?.Reviews?.length ? <Paragraph type="text" className="my-3 fw-bold tech-reviews-see-more" onClick={handleSeeMoreClick}><Text underline>See More</Text></Paragraph> : ''}
                    dataSource={data?.data?.Reviews.filter((_,i) => i < initialReviewsToRender)}
                    renderItem={(item, reviewIndex) => (
                        <List.Item
                            key={item.id}
                            actions={[
                                <Space>
                                    <Rate style={{color:"#FEB000"}} disabled value={(!item.rating) ? '5.0' : Math.round(item.rating) + '.0'} />
                                    <Text>
                                        {(!item.rating) ? '5.0' : Math.round(item.rating) + '.0'}
                                    </Text>
                                </Space>
                            ]}
                            className={`align-items-start position-relative ${reviewIndex === (initialReviewsToRender - 1) ? 'tech-review-last-item' : ''}`}
                        >
                            <List.Item.Meta
                                avatar={<Avatar src={item.reviewer_profile_pic} icon={<UserOutlined />} size={40} />}
                                title={<Title level={5}>{capitalizeFirstLetter(item.reviewer_name)}</Title>}
                                description={
                                    <Space direction="vertical" className="w-100 pb-4">
                                        <Text type="secondary">
                                            {capitalizeFirstLetter(item?.review_comments) || "No feedback provided"}
                                        </Text>
                                        <Text type="secondary" className="tech-review-date">
                                            {moment().diff(moment(item.review_date), "days")}d ago
                                        </Text>
                                    </Space>
                                }
                            />
                        </List.Item>
                    )}
                />
            </Space>
        ) : (
            <Row className="m-5 tech-no-services-main-wrapper w-100">
                <Row className="tech-no-services-wrapper">
                    <Image src={NoReview} loading="lazy" width={330} height={220} preview={false} />
                </Row>
                <Space direction="vertical" size="large" className="justify-content-center">
                    <Title level={3} className="mb-2">Be {techNameFirstWord}'s First Review!</Title>
                    <Text type="secondary">
                        {techNameFirstWord} has no reviews on TecMe... yet. Be their first review by submitting an IT issue or booking one of their services today! All services on TecMe are protected under our Worry-Free Guarantee and only pay after you’re satisfied with the service.
                    </Text>
                </Space>
            </Row>
        )}
    </>
  )
}

export default TechReviews