import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import {useHistory, useParams, useLocation} from 'react-router-dom';
import {
    LaptopOutlined,
    ImportOutlined,
    DoubleRightOutlined,
    EnvironmentOutlined,
    LeftOutlined,
    MessageOutlined,
    PhoneOutlined,
    RightOutlined,
    SendOutlined,
    BarsOutlined,
    AuditOutlined,
    HomeOutlined,
    FormOutlined,
    CalendarOutlined,
    PlusOutlined,
    BulbOutlined,
    LoadingOutlined,
    CaretRightOutlined,
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    DollarOutlined,
    CloseCircleOutlined,
    EditOutlined,
    ClockCircleOutlined,
    InfoCircleOutlined
} from '@ant-design/icons';
import {
    Badge,
    Button,
    Col,
    Layout,
    Row,
    Space,
    Typography,
    Modal,
    Select,
    Card,
    Skeleton,
    Avatar,
    Tag,
    Rate,
    Radio,
    Divider,
    Tooltip,
    notification,
    Empty,
    Checkbox,
    Image
} from 'antd'
import {Calendar} from 'react-calendar';
import {canReadPageOnly, filterWeeklyTimeSheet, getTableTimeFormat, nameFormat} from '../../../../../utils/helper';
import ROUTES from '../../../../../utils/routes';
import PageHeaderWrapper from '../../../../components/page-header-breadcrumbs';
import {ContractRepository} from '../contracts/contract-details/repository';
import './assets/css/styles.scss';
import ActivitiesPanel from './components/activity-panel';
import DetailsPanel from './components/details-panel';
import Moment from "moment"
import {DATE_TIME_FORMAT, SECONDARY_COLOR} from '../../../../../utils/constants';
import {isMobile, isTablet} from 'react-device-detect';
import BottomNavigation from 'reactjs-bottom-navigation'
import {TicketDetailsRepository} from './repository';
import TicketDuration from './components/ticket-duration';
import ResolveTicketModal from './components/resolve-ticket-modal';
import queryString from "query-string";
import UpdateToPendingModal from './components/update-to-pending-modal';
import TechSelectModal from './components/tech-select-modal';
import {ApiBaseHelper} from '../../../../../utils/api-base-helper';
import OnSiteVisitModal from './components/on-site-visit-modal';
import {extendMoment} from 'moment-range';
import RescheduleModal from './components/reschedule-modal';
import RemoteAccessSessionModal from './components/remote-access-session-modal';
import ContactTechnicianModal from './components/contact-technician-modal';
import Bulb from '../../public/create-send-contract/assets/images/bulb.svg'
import {RtmRepository} from '../real-time-messaging/repository/rtm-repository';
import AttachDeviceModal from "./components/attach-device-modal";
import {contractStatuses} from '../contracts/contract-details';
import NewSupportTicketModal from '../contracts/contract-details/components/new-support-ticket-modal';
import {sassTrue} from 'sass';
import {useTimeSheet} from '../contracts/contract-details/hooks/useTimesheet';
import ManualTimeModal from "../contracts/contract-details/components/manual-time-entry/manual-time-modal";
import TechConnectModal from '../../../../components/modals/tech-connect-modal';

const moment = extendMoment(Moment);
const {Content} = Layout
const {Paragraph, Title, Text} = Typography

const skeleton = (
    <div className='auth-container'>
        <div className="ticket-heading my-4">
            <Skeleton active/>
        </div>
        <Row gutter={[12, 12]}>
            <Col xs={24} lg={16}>
                <Card
                    style={{
                        height: 'calc(100vh - 265px)',
                    }}
                >
                    <Skeleton active avatar paragraph={{rows: 1}}/>
                    <Skeleton active avatar paragraph={{rows: 1}}/>
                    <Skeleton active avatar paragraph={{rows: 1}}/>
                    <Skeleton active className='pt-4'/>
                </Card>
            </Col>
            <Col xs={24} lg={8}>
                <Card
                    style={{
                        height: 'calc(100vh - 265px)',
                    }}
                >
                    <Skeleton avatar paragraph={{rows: 1}}/>
                    <Skeleton active/>
                    <Skeleton active paragraph={{rows: 5}}/>
                </Card>
            </Col>
        </Row>
    </div>
)

const TicketDetails = () => {
    const params = useParams();
    const history = useHistory();
    const reactDomLocation = useLocation()

    const queryParams = queryString.parse(reactDomLocation.search);
    const ticketId = params?.ticketId
    const [ticketDetails, setTicketDetails] = useState({})
    const [repository, setRepository] = useState(undefined)
    const user = useSelector(state => state.user)
    const tech = useSelector(state => state.tech)
    const token = user?.token || tech?.token
    const role = user ? 'customer' : 'technician'
    const [activities, setActivities] = useState([])
    const [upComingInvoice, setUpComingInvoice] = useState(null);

    const [selectedTab, setSelectedTab] = useState({
        id: 0,
        title: 'Details'
    })
    const [ticketRepository, setTicketRepository] = useState(undefined)
    const [deviceInfo, setDeviceInfo] = useState({})
    const [isOnSiteVisitModalOpen, setIsOnSiteVisitModalOpen] = useState(false);
    const [isServiceInProgress, setIsServiceInProgress] = useState(false)
    const [isResolveTicketModalOpen, setIsResolveTicketModalOpen] = useState(false)
    const [isAttachDeviceModalOpen, setIsAttachDeviceModalOpen] = useState(false)
    const [isUpdateToPendingTicketModalOpen, setIsUpdateToPendingTicketModalOpen] = useState(false)
    const [isTechSelectionModalVisible, setIsTechSelectionModalVisible] = useState(false)
    const [isRescheduleModalVisible, setIsRescheduleModalVisible] = useState(false)
    const [isFromReassignAction, setIsFromReassignAction] = useState(false)
    const [isRemoteAccessSessionModalVisible, setIsRemoteAccessSessionModalVisible] = useState(false)
    const [isContactTechnicianModalVisible, setIsContactTechnicianModalVisible] = useState(false)
    const [startTimerOnBeginSupport, setStartTimerOnBeginSupport] = useState(false)
    const [isBeginSupportModalVisible, setIsBeginSupportModalVisible] = useState(false)
    const [isBeginSupportApiCallInProgress, setIsBeginSupportApiCallInProgress] = useState(false)
    const [startTimerOnResumeSupport, setStartTimerOnResumeSupport] = useState(false)
    const [isResumeSupportModalVisible, setIsResumeSupportModalVisible] = useState(false)
    const [isResumeSupportApiCallInProgress, setIsResumeSupportApiCallInProgress] = useState(false)
    const [isReleasePaymentApiCallInProgress, setIsReleasePaymentApiCallInProgress] = useState(false)
    const [contractDetails, setContractDetails] = useState({});
    const [loadingContractDetails, setLoadingContractDetails] = useState(false)
    const [isReleasePaymentModalVisible, setIsReleasePaymentModalVisible] = useState(false)
    const [ticketInvoiceInfo, setTicketInvoiceInfo] = useState({
        price: 0,
        duration: ''
    })
    const [activeConversationId, setActiveConversationId] = useState('')
    const [showPausedDisclaimer, setShowPausedDisclaimer] = useState(true)
    const [showTimeEntryModal, setShowTimeEntryModal] = useState(false);
    const [editTicketModalVisible, setEditTicketModalVisible] = useState(false)
    const [loadingTicketDetails, setLoadingTicketDetails] = useState(false)
    const [media, setMedia] = useState([])
    const [isTechnicianAccountSetupModalVisible, setIsTechnicianAccountSetupModalVisible] = useState(false)
    const [itcDetails, setItcDetails] = useState({})

    const contractId = ticketDetails?.Contract?.id
    const isContractPaused = contractDetails?.Status === 'Paused'
    const isContractManualPaused = contractDetails.Status === 'Paused' && contractDetails?.custom_data?.pause_reason === 'manual';

    const {filteredTimeSheetData, getTimeSheetData, billingWeekTotalHours} = useTimeSheet(contractId)

    useEffect(() => {
        if(contractDetails?.ITC){
            ApiBaseHelper.get({
                url: `itc/${contractDetails?.ITC}`
            }).then((res) => {
                setItcDetails(res?.data?.data)
            }).catch((error) => {
                console.error(error)
            })
        }
    }, [contractDetails])

    useEffect(() => {
        setTicketRepository(new TicketDetailsRepository(token))
        setRepository(new ContractRepository(token))
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (repository) {
            getAndSetTicketDetails()
            getAndSetActivities()
        }
    }, [repository])

    useEffect(() => {
        if (ticketDetails?.Contract?.id) {
            getAndSetContractDetails()
            if (!filteredTimeSheetData.length) {
                // getAndSetFilteredTimeSheetsData()
                getTimeSheetData()
            }
        }
    }, [ticketDetails?.Contract])

    useEffect(() => {
        if (ticketDetails?.Hardware && !Object.keys(deviceInfo).length) getAndSetDevice()
    }, [ticketDetails?.Hardware])

    useEffect(() => {
        if (ticketDetails?.id) getAndSetTicketMedia()
    }, [ticketDetails?.id])

    useEffect(() => {
        if (ticketDetails?.Reporter_Contact || ticketDetails?.Provider_Contact) {
            getAndSetActiveConversationId()
        }
    }, [ticketDetails?.Reporter_Contact, ticketDetails?.Provider_Contact])

    const getUpcomingInvoice = async (contractId) => {
        const res = await repository.getUpcommingInvoice(contractId);
        if (res?.data?.data?.next_payment_attempt) {
            let upComingInvoiceData = res?.data?.data;
            // convert unix timestamp to moment and subtract 1 hour and convert it back to unix
            upComingInvoiceData.next_payment_attempt = moment(upComingInvoiceData.next_payment_attempt * 1000).subtract(1, 'hour').unix();
            setUpComingInvoice(upComingInvoiceData);
        }
    }

    const getAndSetActiveConversationId = async () => {
        try {
            const rtmRepository = new RtmRepository(token)
            let conversationId = '';
            const techId = !!tech ? ticketDetails?.Reporter_Contact?.id : ticketDetails?.Provider_Contact?.id

            await rtmRepository.validateNewChat(techId).then((response) => {
                conversationId = response.data.data.Conversation;
            }).catch(async (error) => {
                await rtmRepository.createConversation(techId).then((response) => {
                    conversationId = response.data.data.id;
                })
            });

            setActiveConversationId(conversationId)
        } catch (error) {
            console.error(error)
        }
    }

    const getAndSetDevice = () => {
        ticketRepository?.getDevice(ticketDetails?.Hardware).then(res => {
            const data = res?.data?.data
            if (data) setDeviceInfo(data)
        }).catch(error => console.error(error))
    }

    const getAndSetContractDetails = async () => {
        const contractId = ticketDetails?.Contract?.id
        setLoadingContractDetails(true)
        try {
            const contractsRes = await repository.getContract(contractId, role);

            if (contractsRes.data?.data?.length) {
                setContractDetails(contractsRes.data.data?.[0])
                setLoadingContractDetails(false)
            }
        } catch (error) {
            console.log(error)
            setLoadingContractDetails(false)
        }
    }

    const getAndSetTicketDetails = async () => {
        setLoadingTicketDetails(sassTrue)
        try {
            const res = await ticketRepository?.getTicket(ticketId, role);
            const data = res?.data?.data?.[0]
            if (data) {
				setTicketDetails(data)
                getUpcomingInvoice(data.Contract?.id)
			}
                setLoadingTicketDetails(false)
            }
        catch
            (error)
            {
                setLoadingTicketDetails(false)
            }
        }

        const getAndSetTicketMedia = async () => {
            try {
                const res = await ticketRepository?.getTicketMedia(ticketDetails?.id);
                const data = res.data?.data?.data.reverse()
                if (data) {
                    setMedia(data.map((ele) => {
                        return (
                            {
                                uid: ele?.id,
                                name: ele?.Title,
                                status: "done",
                                url: ele?.Body,
                                type: "image/png"
                            }
                        )
                    }))
                }
            } catch (error) {
                console.error(error)
            }
        }

        const getAndSetActivities = async () => {
            try {
                const res = await repository.getActivities(ticketId);
                if (res?.data?.data?.length) setActivities(res.data.data.sort((a, b) => moment(b.CreatedDate).diff(moment(a.CreatedDate))))
            } catch (error) {
                // notification.error({
                // 	message: 'TecMe',
                // 	description: 'Fetch activities failed'
                // })
            }
        }

        const handleBeginTicketOnClick = () => {
            if(tech?.Stripe_Connect_Id === null){
                setIsTechnicianAccountSetupModalVisible(true)
                return
            }
            setIsBeginSupportModalVisible(true)
        }

        const resumeTicketClick = () => {
            setIsResumeSupportModalVisible(true)
        }

        const handleUpdateToPendingClick = () => {
            setIsUpdateToPendingTicketModalOpen(true)
        }

        const handleTechSelectionClick = (isFromReassign = true) => {
            if (isFromReassign) setIsFromReassignAction(true)
            setIsTechSelectionModalVisible(true)
        }

        const handleRemoteAccessClick = () => {
            setIsRemoteAccessSessionModalVisible(true)
        }

        const handleOpenChatButton = () => {
            history.push(`${ROUTES.INBOX}?id=${activeConversationId}`)
        }

        const handleReleasePaymentClick = async () => {
            setIsReleasePaymentApiCallInProgress(true)
            const res = await ticketRepository?.getTicketInvoice(ticketDetails?.id, ticketDetails?.Contract?.id);
            setIsReleasePaymentApiCallInProgress(false);
            if (res?.data?.data?.hosted_invoice_url) {
                window.open(res?.data?.data?.hosted_invoice_url, "_blank");
                Modal.success({
                    className: 'ticket-updated-successfully-wrapper',
                    icon: null,
                    closable: true,
                    okText: 'Refresh',
                    width: 500,
                    onOk: () => window.location.reload(),
                    title: <Title level={3} className='font-weight-500 m-0'>Invoice Paid?</Title>,
                    content: (
                        <div className='mt-2 disclaimer p-2'>
                            <Text>
                                Please refresh the page after paying your invoice. Thank you for your cooperation.
                            </Text>
                        </div>
                    )
                });
            }

            // if(res?.data?.data?.amount_due && res?.data?.data?.lines?.data?.[0]?.quantity) {
            // 	const hours = Math.floor(res?.data?.data?.lines?.data?.[0]?.quantity / 60)
            // 	const mins = res?.data?.data?.lines?.data?.[0]?.quantity % 60
            // 	setTicketInvoiceInfo({
            // 		price: res.data.data.amount_due / 100,
            // 		duration: hours ? `${hours}h ${mins}m` : `${mins}m`
            // 	})
            // 	setIsReleasePaymentApiCallInProgress(false)
            // 	setIsReleasePaymentModalVisible(true)
            // }
        }

        const getTicketBtnList = () => {
            if (!Object.keys(ticketDetails).length) return []

            let buttonsArray = [];

            const openChatButton = {
                text: "Open Chat",
                buttonIcon: <MessageOutlined className='d-flex'/>,
                buttonTheme: 'white-btn d-flex align-items-center justify-content-center',
                onClick: handleOpenChatButton
            }

            switch (ticketDetails.Status) {
                case 'Assigned':
                    if (user || tech.id === ticketDetails?.Reporter_Contact?.id || (tech.id === itcDetails?.Owner_Contact && tech.id !== ticketDetails?.Provider_Contact)) {
                        if (!ticketDetails?.Provider_Contact?.id) {
                            // buttonsArray = [
                            // 	...buttonsArray,
                            // 	{
                            // 		text:"Assign Technician",
                            // 		buttonIcon: <PlusOutlined />,
                            // 		buttonTheme:'d-flex align-items-center',
                            // 		type: 'primary',
                            // 		style: { height: 45 },
                            // 		onClick: () => handleTechSelectionClick(false)
                            // 	}
                            // ]
                        } else {
                            buttonsArray = [
                                ...buttonsArray,
                                // openChatButton,
                                {
                                    text: "Phone Call",
                                    buttonIcon: <PhoneOutlined/>,
                                    buttonTheme: 'white-btn d-flex align-items-center justify-content-center',
                                    onClick: () => setIsContactTechnicianModalVisible(true)
                                }
                            ]
                        }
                        buttonsArray = [
                            ...buttonsArray,
                            {
                                text: "Edit Ticket",
                                buttonIcon: <EditOutlined className='d-flex'/>,
                                buttonTheme: 'white-btn d-flex align-items-center justify-content-center',
                                onClick: () => setEditTicketModalVisible(true)
                            }
                        ]
                    }
                    if (tech && (tech.id !== ticketDetails?.Reporter_Contact?.id) && (tech.id === ticketDetails?.Provider_Contact) || (tech && tech.id === ticketDetails?.Provider_Contact)) {
                        buttonsArray = [
                            ...buttonsArray,
                            // openChatButton,
                            {
                                text: "Begin Support",
                                buttonTheme: 'd-flex align-items-center justify-content-center font-weight-500',
                                buttonIcon: <CaretRightOutlined className='caret-right-outlined'/>,
                                type: 'primary',
                                style: {height: 45},
                                onClick: handleBeginTicketOnClick
                            }
                        ]
                    }
                    break;
                case 'In Progress':
                case 'Open':
                    if (user || tech.id === ticketDetails?.Reporter_Contact?.id || (tech.id === itcDetails?.Owner_Contact && tech.id !== ticketDetails?.Provider_Contact)) {
                        buttonsArray = [
                            ...buttonsArray,
                            // openChatButton,
                            {
                                text: "Phone Call",
                                buttonIcon: <PhoneOutlined className='d-flex'/>,
                                buttonTheme: 'white-btn d-flex align-items-center justify-content-center',
                                onClick: () => setIsContactTechnicianModalVisible(true)
                            },
                            {
                                text: "Edit Ticket",
                                buttonIcon: <EditOutlined className='d-flex'/>,
                                buttonTheme: 'white-btn d-flex align-items-center justify-content-center',
                                onClick: () => setEditTicketModalVisible(true)
                            }
                        ]
                        if (!!ticketDetails?.custom_data?.customer_remote_url) {
                            buttonsArray = [
                                ...buttonsArray,
                                {
                                    text: "Join Remote Session",
                                    buttonIcon: <ImportOutlined/>,
                                    buttonTheme: 'd-flex align-items-center justify-content-center font-weight-500',
                                    type: 'primary',
                                    style: {height: 44},
                                    onClick: () => window.open(ticketDetails.custom_data.customer_remote_url, '_blank')
                                }
                            ]
                        }
                    }
                    if (tech && (tech.id !== ticketDetails?.Reporter_Contact?.id) && (tech.id === ticketDetails?.Provider_Contact) || (tech && tech.id === ticketDetails?.Provider_Contact)) {
                        buttonsArray = [
                            ...buttonsArray,
                            // openChatButton,
                            {
                                text: "Remote Access",
                                buttonIcon: <LaptopOutlined className='d-flex'/>,
                                buttonTheme: 'white-btn d-flex align-items-center justify-content-center',
                                onClick: handleRemoteAccessClick
                            },
                            // {
                            //     text: "Update To Pending",
                            //     buttonTheme: 'white-btn d-flex align-items-center justify-content-center',
                            //     buttonIcon: <ExclamationCircleOutlined className='d-flex'/>,
                            //     onClick: handleUpdateToPendingClick
                            // },
                            {
                                text: "Resolve",
                                buttonIcon: <CheckCircleOutlined className='d-flex'/>,
                                buttonTheme: 'd-flex align-items-center font-weight-500 justify-content-center',
                                type: 'primary',
                                style: {height: 45},
                                onClick: () => setIsResolveTicketModalOpen(true)
                            }
                        ]
                    }
                    break;
                case 'Resolved':
                    if (user || tech.id === ticketDetails?.Reporter_Contact?.id || (tech.id === itcDetails?.Owner_Contact && tech.id !== ticketDetails?.Provider_Contact)) {
                        buttonsArray = [
                            ...buttonsArray,
                            // openChatButton,
                            {
                                text: "Phone Call",
                                buttonIcon: <PhoneOutlined/>,
                                buttonTheme: 'white-btn d-flex align-items-center justify-content-center',
                                onClick: () => setIsContactTechnicianModalVisible(true)
                            }
                        ]
                        if (contractDetails?.Type === 'Per Ticket' && user) {
                            buttonsArray = [
                                ...buttonsArray,
                                {
                                    text: "Release Payment",
                                    buttonIcon: <SendOutlined className='d-flex'/>,
                                    buttonTheme: 'd-flex align-items-center font-weight-500 justify-content-center',
                                    type: 'primary',
                                    loading: isReleasePaymentApiCallInProgress,
                                    style: {height: 45},
                                    onClick: handleReleasePaymentClick
                                }
                            ]
                        }
                    }
                    if (tech && (tech.id !== ticketDetails?.Reporter_Contact?.id) && (tech.id === ticketDetails?.Provider_Contact) || (tech && tech.id === ticketDetails?.Provider_Contact)) {
                        buttonsArray = [
                            ...buttonsArray,
                            // openChatButton,
                            // {
                            //     text: "Resume Support",
                            //     buttonIcon: <CaretRightOutlined className='caret-right-outlined'/>,
                            //     buttonTheme: 'd-flex align-items-center font-weight-500 justify-content-center',
                            //     type: 'primary',
                            //     style: {height: 45},
                            //     onClick: resumeTicketClick
                            // }
                        ]
                    }
                    break;
                case 'Pending':
                    if (user || tech.id === ticketDetails?.Reporter_Contact?.id || (tech.id === itcDetails?.Owner_Contact && tech.id !== ticketDetails?.Provider_Contact)) {
                        buttonsArray = [
                            ...buttonsArray,
                            // openChatButton,
                            {
                                text: "Phone Call",
                                buttonIcon: <PhoneOutlined/>,
                                buttonTheme: 'white-btn d-flex align-items-center justify-content-center',
                                onClick: () => setIsContactTechnicianModalVisible(true)
                            }
                        ]
                        if (ticketDetails?.custom_data?.pending_reason === 'an on-site visit' && !ticketDetails?.Scheduled_Date_Time) {
                            buttonsArray = [
                                ...buttonsArray,
                                {
                                    text: "Schedule Visit",
                                    buttonIcon: <CalendarOutlined/>,
                                    buttonTheme: 'd-flex align-items-center justify-content-center',
                                    type: 'primary',
                                    style: {height: 45},
                                    onClick: () => setIsOnSiteVisitModalOpen(true)
                                }
                            ]
                        }
                    }
                    if (tech && (tech.id !== ticketDetails?.Reporter_Contact?.id) && (tech.id === ticketDetails?.Provider_Contact) || (tech && tech.id === ticketDetails?.Provider_Contact)) {
                        buttonsArray = [
                            ...buttonsArray,
                            // openChatButton,
                            // {
                            //     text: "Resume Support",
                            //     buttonIcon: <CaretRightOutlined className='caret-right-outlined'/>,
                            //     buttonTheme: 'd-flex align-items-center font-weight-500 justify-content-center',
                            //     type: 'primary',
                            //     style: {height: 45},
                            //     onClick: resumeTicketClick
                            // }
                        ]
                    }
                    break;
                case 'Closed':
                    buttonsArray = [
                        ...buttonsArray,
                        // openChatButton
                    ]
                    if (user || tech.id === ticketDetails?.Reporter_Contact?.id || (tech.id === itcDetails?.Owner_Contact && tech.id !== ticketDetails?.Provider_Contact)) {
                        buttonsArray = [
                            ...buttonsArray,
                            {
                                text: "Phone Call",
                                buttonIcon: <PhoneOutlined className='d-flex'/>,
                                buttonTheme: 'white-btn d-flex align-items-center justify-content-center',
                                onClick: () => setIsContactTechnicianModalVisible(true)
                            }
                        ]
                        if (ticketDetails?.custom_data?.invoice_url) {
                            buttonsArray.push({
                                text: "View Invoice",
                                buttonIcon: <DollarOutlined className='d-flex'/>,
                                buttonTheme: 'white-btn d-flex align-items-center justify-content-center',
                                onClick: () => {
                                    window.open(ticketDetails?.custom_data?.invoice_url, "_blank")
                                }
                            })
                        }
                    }
                    break;
                default:
                    break;
            }

            if (isContractPaused) {
                buttonsArray = []
                if (user || tech.id === ticketDetails?.Reporter_Contact?.id || (tech.id === itcDetails?.Owner_Contact && tech.id !== ticketDetails?.Provider_Contact)) {
                    buttonsArray = [
                        ...buttonsArray,
                        {
                            text: "Phone Call",
                            buttonIcon: <PhoneOutlined className='d-flex'/>,
                            buttonTheme: 'white-btn d-flex align-items-center justify-content-center',
                            onClick: () => setIsContactTechnicianModalVisible(true)
                        }
                    ]
                    if (ticketDetails?.custom_data?.invoice_url) {
                        buttonsArray.push({
                            text: "View Invoice",
                            buttonIcon: <DollarOutlined className='d-flex'/>,
                            buttonTheme: 'white-btn d-flex align-items-center justify-content-center',
                            onClick: () => {
                                window.open(ticketDetails?.custom_data?.invoice_url, "_blank")
                            }
                        })
                    }
                }

                return buttonsArray
            }

            return buttonsArray
        }

        const handleGoBackClick = () => history.goBack()

        const getItems = () => {
            const iconStyle = {fontSize: '14px'};
            const activeIconStyle = {fontSize: '14px', color: 'white'};

            const items = [
                {
                    title: 'Details',
                    icon: <BarsOutlined style={iconStyle}/>,
                    activeIcon: <BarsOutlined style={activeIconStyle}/>
                },
                {
                    title: 'Activity',
                    icon: <MessageOutlined style={iconStyle}/>,
                    activeIcon: <MessageOutlined style={activeIconStyle}/>
                },
                {
                    title: 'Device Parts',
                    icon: <AuditOutlined style={iconStyle}/>,
                    activeIcon: <AuditOutlined style={activeIconStyle}/>
                }
            ]

            if (ticketDetails?.Status === 'Pending' && !!ticketDetails?.Scheduled_Date_Time) {
                items.push(
                    {
                        title: 'On-Site Visit',
                        icon: <HomeOutlined style={iconStyle}/>,
                        activeIcon: <HomeOutlined style={activeIconStyle}/>
                    }
                )
            }
            if (tech && (tech.id !== ticketDetails?.Reporter_Contact?.id) && (tech.id === ticketDetails?.Provider_Contact) || (tech && tech.id === ticketDetails?.Provider_Contact)) {
                items.push({
                    title: 'Private Notes',
                    icon: <FormOutlined style={iconStyle}/>,
                    activeIcon: <FormOutlined style={activeIconStyle}/>
                })
            }

            if(user?.role?.toLowerCase() !==  "recruiter"){
                items.push({
                    title: 'Actions',
                    icon: <FormOutlined style={iconStyle}/>,
                    activeIcon: <FormOutlined style={activeIconStyle}/>
                })
            }
            

            return items
        }

        // function that determines if weekly limit has been reached
        const isWeeklyLimitReached = () => {
            return contractDetails?.Type !== "Per Ticket" && !!(contractDetails?.Hours_Upper_Limit != '0' && billingWeekTotalHours >= Number(contractDetails?.Hours_Upper_Limit));
        }

        const getAddManualTimeButton = () => {
            // const timerStart = ticketDetails?.custom_data?.timer_start;
            if (!!tech && contractDetails?.Status === contractStatuses.Active && contractDetails.Type && contractDetails?.Allow_Manual_Entry === "1" && (contractDetails?.Type === 'Hourly') && ticketDetails?.Status?.toLowerCase() === 'open') {
                return (
                    <Tooltip
                        title={isWeeklyLimitReached() ? "Your hourly limit has been reached. You cannot log more hours at this time. Please contact the customer to request an increase in the hourly limit." : ""}
                    >
                        <Button
                            id='add-manual-time-button'
                            className='white-btn d-flex align-items-center justify-content-center'
                            onClick={() => setShowTimeEntryModal(true)}
                            style={{
                                width: `${(isMobile || isTablet) ? '100%' : 'fit-content'}`,
                                height: `${(isMobile || isTablet) ? '45px' : 'inherit'}`
                            }}
                            // disabled={!!(billingWeekTotalHours >= Number(contractDetails?.Hours_Upper_Limit))}
                            disabled={!!(contractDetails?.Hours_Upper_Limit != '0' && billingWeekTotalHours >= Number(contractDetails?.Hours_Upper_Limit))}
                        >
                            <Space>
                                <span><ClockCircleOutlined className='d-flex'/></span>
                                <span>Add Manual Time</span>
                            </Space>
                        </Button>
                    </Tooltip>
                )
            }

            return ''
        }

        const actionButtons = (
            <div className="ticket-btn-list">
                {getAddManualTimeButton()}
                {getTicketBtnList().map((ele) => {
                    return (
                        <Button key={ele.text} className={ele.buttonTheme} {...ele}>
                            <Space>
                                <span>{ele.buttonIcon}</span>
                                <span>{ele.text}</span>
                            </Space>
                        </Button>
                    )
                })}
            </div>
        )

        const handleRescheduleClick = () => {
            setIsRescheduleModalVisible(true)
        }

        const getMobileAndTabletContent = () => {
            const mobileActionButtons = (
                <Space direction='vertical' className='mt-3 w-100'>
                    {getAddManualTimeButton()}
                    {getTicketBtnList().map((ele) => {
                        return (
                            <Button size='large' key={ele.text} className={`${ele.buttonTheme} w-100`} {...ele}>
                                <Space>
                                    <span>{ele.buttonIcon}</span>
                                    <span>{ele.text}</span>
                                </Space>
                            </Button>
                        )
                    })}
                </Space>
            )

            if (selectedTab.title === 'Activity') return <ActivitiesPanel activities={activities}
                                                                          ticketDetails={ticketDetails}
                                                                          ticketRepository={ticketRepository}
                                                                          getAndSetActivities={getAndSetActivities}
                                                                          isContractPaused={isContractPaused}/>
            if (selectedTab.title === 'Actions' && user?.role?.toLowerCase() !== "recruiter") return mobileActionButtons

            return <DetailsPanel
                ticketDetails={ticketDetails}
                contractDetails={contractDetails}
                selectedTab={selectedTab}
                handleTechSelectionClick={handleTechSelectionClick}
                deviceInfo={deviceInfo} ticketRepository={ticketRepository}
                setIsOnSiteVisitModalOpen={setIsOnSiteVisitModalOpen}
                handleRescheduleClick={handleRescheduleClick}
                getAndSetActivities={getAndSetActivities}
                getAndSetTicketDetails={getAndSetTicketDetails}
                isContractPaused={isContractPaused}
                onClickAddDevice={() => {
                    setIsAttachDeviceModalOpen(true)
                }}
            />
        }

        const handleBeginSupportClick = async () => {
            setIsBeginSupportApiCallInProgress(true)
            const payload = {
                Customer_Contact: ticketDetails?.Reporter_Contact?.id,
                Timer_Start: startTimerOnBeginSupport
            }

            try {
                const res = await ticketRepository?.beginTicket(ticketDetails.id, payload)
                const whatsNextList = startTimerOnBeginSupport ? [
                    "The service timer has started, and you can pause it if you don't want to log the duration or bill your customer (for hourly tickets). The timer can be resumed while the ticket is still in progress.",
                    "If the ticket requires extra information, an on-site visit, or any part/hardware, update it to \"Pending\" status and explain to the customer why.",
                    "Once you are done servicing the ticket, you can mark it as “Resolved” and bill the customer."
                ] : [
                    "You did not choose to start the service timer, but you can manually start the timer anytime to log time duration for this ticket.",
                    "If the ticket requires additional information, an on-site visit or any part/hardware, update ticket status to “Pending” and describe the reason to the customer.",
                    `Once the ticket is serviced, mark it as "Resolved" and bill the customer.`
                ]

                if (res) {
                    await getAndSetTicketDetails()
                    await getAndSetActivities()
                    Modal.success({
                        className: 'ticket-updated-successfully-wrapper',
                        icon: null,
                        closable: true,
                        okText: 'Okay',
                        width: 500,
                        title: <Title level={3} className='font-weight-500 m-0'>Ticket Opened</Title>,
                        content: (
                            <div className='mt-2'>
                                <Typography.Text className='font-weight-500'>What's Next:</Typography.Text>
                                <ol>
                                    {whatsNextList.map((item, index) => <li className={"info-list mt-2"}
                                                                            key={`list-item-${index}`}>{item}</li>)}
                                </ol>
                            </div>
                        )
                    });
                }
            } catch (error) {
                // notification.error({
                // 	message: 'TecMe',
                // 	description: 'Something went wrong'
                // })
            }
            setIsBeginSupportApiCallInProgress(false)
            setIsBeginSupportModalVisible(false)
        }

        const handleResumeSupportClick = async () => {
            setIsResumeSupportApiCallInProgress(true)
            const payload = {
                Customer_Contact: ticketDetails?.Reporter_Contact?.id,
                Timer_Start: startTimerOnResumeSupport
            }

            try {
                await ticketRepository?.resumeTicket(ticketDetails.id, payload)
                await getAndSetTicketDetails()
                await getAndSetActivities()
            } catch (error) {
                // notification.error({
                // 	message: 'TecMe',
                // 	description: 'Resume ticket failed'
                // })
            }
            setIsResumeSupportApiCallInProgress(false)
            setIsResumeSupportModalVisible(false)
        }



        return (
            <>
                {(!Object.keys(ticketDetails)?.length || loadingTicketDetails) ? skeleton : (
                    <Content className='main'>
                        <div className="page">
                            <PageHeaderWrapper
                                routes={[
                                    {
                                        path: queryParams?.from === 'contracts' ? ROUTES.CONTRACTS : ROUTES.TICKET_LISTING,
                                        breadcrumbName: queryParams?.from === 'contracts' ? 'Contracts' : 'Tickets',
                                    },
                                    {
                                        path: ROUTES.VIEW_EDIT_CONTRACT.replace(':contractId', `${contractId}`),
                                        breadcrumbName: queryParams?.from === 'contracts' && `Contract`,
                                    },
                                    {
                                        breadcrumbName: `Ticket`,
                                    },
                                ]}
                            />
                            <div className="auth-container support-ticket-container">
                                <>
                                    {(isMobile || isTablet) && selectedTab.title === 'Details' && (
                                        <div className="ticket-heading">
                                            <Space align='center' size={20}>
                                                <Title level={4} style={{margin: 0}}>Ticket Details</Title>
                                                <div className='ticket-status'>
                                                    <div className='ticket-status-badge'>{ticketDetails?.Status}</div>
                                                </div>
                                            </Space>
                                            <Text> <Text strong> Priority: </Text> <Text
                                                type='secondary'>{ticketDetails?.Priority}</Text></Text>
                                            <div className='w-100 text-start'>
                                                <TicketDuration
                                                    key={JSON.stringify(ticketDetails) + JSON.stringify(filteredTimeSheetData)}
                                                    ticketDetails={ticketDetails}
                                                    ticketRepository={ticketRepository}
                                                    getAndSetTicketDetails={getAndSetTicketDetails}
                                                    getAndSetActivities={getAndSetActivities}
                                                    isContractPaused={isContractPaused}
                                                    billingWeekTotalHours={billingWeekTotalHours}
                                                />
                                            </div>
                                            {ticketDetails?.Status === "Pending" && (
                                                <Space className='w-100 disclaimer justify-content-between p-2 my-2 mb-4'
                                                       size={20}>
                                                    <Space size={12} align='top'>
                                                        <img style={{width:40}} src={Bulb} preview={false} alt="disclaimer" />
                                                        <div>
                                                            <Paragraph style={{marginBottom:2}} strong>Pending Ticket</Paragraph>
                                                            <Text type='secondary' className='font-size-14'>
                                                                <Space size={12}>
                                                                    <Text>
                                                                        {!!tech ? (ticketDetails?.Status === "Pending" ? "The ticket is now in a pending state and is accessible only in read-only mode. This is due to the customer pausing the associated contract. As a result, please be aware that no activities, including the use of the billable timer, can be performed on this ticket during the pause. Once the customer decides to resume the contract, the ticket will automatically revert to its previous status." : "The customer needs to pay the last invoice in order for the contract to remain active. If this payment is not received, the contract will remain inactive.") : (ticketDetails?.Status === "Pending" ? "Your ticket is currently in a pending state and is in read-only mode. This is because you've paused the contract associated with this ticket. Please note that during this pause, the technician won't be able to perform any actions on the ticket, including using the billable timer. Rest assured, once you decide to resume the contract, your ticket will automatically return to its previous status." : "The last invoice payment was unsuccessful. To continue the contract, please make payment on the invoice.")}
                                                                    </Text>
                                                                    {!!user && contractDetails?.custom_data?.invoice_url && !isContractManualPaused && (
                                                                        <a className='font-weight-500 tecme-secondary-color'
                                                                        href={contractDetails?.custom_data?.invoice_url}
                                                                        target="_blank">
                                                                            Pay Invoice
                                                                        </a>
                                                                    )}
                                                                </Space>
                                                            </Text>
                                                        </div>
                                                       
                                                    </Space>
                                                </Space>
                                            )}
                                            <Divider className='m-0' style={{borderColor: '#D9D9D9'}}/>
                                        </div>
                                    )}
                                    {(!isMobile && !isTablet) && (
                                        <>
                                            <div className="ticket-heading">
                                                <Space align='center' size={20} wrap>
                                                    <Title level={2}>Ticket Details</Title>
                                                    <div className='ticket-status'>
                                                        <div
                                                            className='ticket-status-badge'>{ticketDetails?.Status}</div>
                                                        <Text> <Text strong> Priority: </Text> <Text
                                                            type='secondary'>{ticketDetails?.Priority}</Text></Text>
                                                    </div>
                                                </Space>
                                                <Space>
                                                    <TicketDuration
                                                        billingWeekTotalHours={billingWeekTotalHours}
                                                        key={JSON.stringify(ticketDetails) + JSON.stringify(filteredTimeSheetData)}
                                                        ticketDetails={ticketDetails}
                                                        ticketRepository={ticketRepository}
                                                        getAndSetTicketDetails={getAndSetTicketDetails}
                                                        getAndSetActivities={getAndSetActivities}
                                                        isContractPaused={isContractPaused}/>
                                                </Space>
                                            </div>
                                            {ticketDetails?.Status === "Pending" && (
                                                <Space className='w-100 disclaimer justify-content-between p-2 my-2 mb-4'
                                                       size={20}>
                                                    <Space size={12} align='top'>
                                                        <img style={{width:40}} src={Bulb} preview={false} alt="disclaimer" />
                                                        <div>
                                                            <Paragraph style={{marginBottom:2}} strong>Pending Ticket</Paragraph>
                                                            <Text type='secondary' className='font-size-14'>
                                                                <Space size={15}>
                                                                    
                                                                        <Text>
                                                                            {!!tech ? (ticketDetails?.Status === "Pending" ? "The ticket is now in a pending state and is accessible only in read-only mode. This is due to the customer pausing the associated contract. As a result, please be aware that no activities, including the use of the billable timer, can be performed on this ticket during the pause. Once the customer decides to resume the contract, the ticket will automatically revert to its previous status." : "The customer needs to pay the last invoice in order for the contract to remain active. If this payment is not received, the contract will remain inactive.") : (ticketDetails?.Status === "Pending" ? "Your ticket is currently in a pending state and is in read-only mode. This is because you've paused the contract associated with this ticket. Please note that during this pause, the technician won't be able to perform any actions on the ticket, including using the billable timer. Rest assured, once you decide to resume the contract, your ticket will automatically return to its previous status." : "The last invoice payment was unsuccessful. To continue the contract, please make payment on the invoice.")}
                                                                        </Text>
                                                                    
                                                                    {!!user && contractDetails?.custom_data?.invoice_url && !isContractManualPaused && (
                                                                        <a className='font-weight-500 tecme-secondary-color'
                                                                        href={contractDetails?.custom_data?.invoice_url}
                                                                        target="_blank">
                                                                            Pay Invoice
                                                                        </a>
                                                                    )}
                                                                </Space>
                                                            </Text>
                                                        </div>
                                                    </Space>
                                                </Space>
                                            )}
                                            <div className="ticket-cta-section">
                                                <Space align='center' style={{cursor: "pointer"}}
                                                       onClick={handleGoBackClick}>
                                                    <div className='back-btn'>
                                                        <LeftOutlined className="back-icon"/>
                                                    </div>
                                                    <Paragraph style={{margin: 0, fontSize: 20}}>Back</Paragraph>
                                                </Space>
                                                <div>
                                                    {user?.role.toLowerCase() !== "recruiter"  && actionButtons}
                                                    {
                                                        contractDetails?.Status?.toLowerCase() === "terminated" &&
                                                        <Space size={6}>
                                                            <Badge status={'error'} />
                                                            <Text>
                                                                Contract Ended
                                                            </Text>
                                                        </Space>
                                                    }
                                                </div>
                                                
                                            </div>
                                        </>
                                    )}
                                    <Row gutter={12}>
                                        {(isMobile || isTablet) ? getMobileAndTabletContent() : (
                                            <>
                                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                                    <ActivitiesPanel activities={activities}
                                                                     ticketDetails={ticketDetails}
                                                                     ticketRepository={ticketRepository}
                                                                     getAndSetActivities={getAndSetActivities}
                                                                     isContractPaused={isContractPaused}/>
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                                    <DetailsPanel
                                                        onClickAddDevice={() => {
                                                            setIsAttachDeviceModalOpen(true)
                                                        }}
                                                        ticketDetails={ticketDetails} selectedTab={selectedTab}
                                                        contractDetails={contractDetails}
                                                        handleTechSelectionClick={handleTechSelectionClick}
                                                        deviceInfo={deviceInfo} ticketRepository={ticketRepository}
                                                        setIsOnSiteVisitModalOpen={setIsOnSiteVisitModalOpen}
                                                        handleRescheduleClick={handleRescheduleClick}
                                                        getAndSetActivities={getAndSetActivities}
                                                        getAndSetTicketDetails={getAndSetTicketDetails}
                                                        isContractPaused={isContractPaused}
                                                        />
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                </>
                            </div>
                        </div>
                    </Content>
                )}
                <NewSupportTicketModal
                    user={user}
                    tech={tech}
                    media={media}
                    // sendMessage={sendMessage}
                    getAndSetContractDetails={getAndSetContractDetails}
                    getAndSetTicketDetails={getAndSetTicketDetails}
                    getAndSetActivities={getAndSetActivities}
                    getAndSetDevice={getAndSetDevice}
                    getAndSetTicketMedia={getAndSetTicketMedia}
                    contractDetails={contractDetails}
                    contractId={contractId}
                    repository={repository}
                    ticketRepository={ticketRepository}
                    visible={editTicketModalVisible}
                    ticketDetails={ticketDetails}
                    editTicketModalVisible={editTicketModalVisible}
                    onOk={() => setEditTicketModalVisible(false)}
                    onCancel={() => setEditTicketModalVisible(false)}
                    isFromViewEditContractPage
                />
                <ResolveTicketModal
                    visible={isResolveTicketModalOpen}
                    onOk={() => setIsResolveTicketModalOpen(false)}
                    onCancel={() => setIsResolveTicketModalOpen(false)}
                    ticketDetails={ticketDetails}
                    contractDetails={contractDetails}
                    ticketRepository={ticketRepository}
                    getAndSetTicketDetails={getAndSetTicketDetails}
                    getAndSetActivities={getAndSetActivities}
                />
                <AttachDeviceModal
                    visible={isAttachDeviceModalOpen}
                    onOk={() => setIsAttachDeviceModalOpen(false)}
                    onCancel={() => setIsAttachDeviceModalOpen(false)}
                    ticketDetails={ticketDetails}
                    ticketRepository={ticketRepository}
                    getAndSetTicketDetails={getAndSetTicketDetails}
                    getAndSetActivities={getAndSetActivities}
                />
                <UpdateToPendingModal
                    visible={isUpdateToPendingTicketModalOpen}
                    onOk={() => setIsUpdateToPendingTicketModalOpen(false)}
                    onCancel={() => setIsUpdateToPendingTicketModalOpen(false)}
                    ticketDetails={ticketDetails}
                    ticketRepository={ticketRepository}
                    getAndSetTicketDetails={getAndSetTicketDetails}
                    getAndSetActivities={getAndSetActivities}
                />
                <TechSelectModal
                    visible={isTechSelectionModalVisible}
                    onOk={() => setIsTechSelectionModalVisible(false)}
                    onCancel={() => setIsTechSelectionModalVisible(false)}
                    ticketDetails={ticketDetails}
                    isFromReassignAction={isFromReassignAction}
                    ticketRepository={ticketRepository}
                    getAndSetTicketDetails={getAndSetTicketDetails}
                    getAndSetActivities={getAndSetActivities}
                />
                <OnSiteVisitModal
                    isVisible={isOnSiteVisitModalOpen}
                    ticketDetails={ticketDetails}
                    ticketRepository={ticketRepository}
                    getAndSetTicketDetails={getAndSetTicketDetails}
                    getAndSetActivities={getAndSetActivities}
                    onOk={() => setIsOnSiteVisitModalOpen(false)}
                    onCancel={() => setIsOnSiteVisitModalOpen(false)}
                />
                <RescheduleModal
                    isVisible={isRescheduleModalVisible}
                    ticketDetails={ticketDetails}
                    ticketRepository={ticketRepository}
                    getAndSetTicketDetails={getAndSetTicketDetails}
                    getAndSetActivities={getAndSetActivities}
                    onOk={() => setIsRescheduleModalVisible(false)}
                    onCancel={() => setIsRescheduleModalVisible(false)}
                />
                <RemoteAccessSessionModal
                    visible={isRemoteAccessSessionModalVisible}
                    ticketDetails={ticketDetails}
                    ticketRepository={ticketRepository}
                    getAndSetTicketDetails={getAndSetTicketDetails}
                    getAndSetActivities={getAndSetActivities}
                    onOk={() => setIsRemoteAccessSessionModalVisible(false)}
                    onCancel={() => setIsRemoteAccessSessionModalVisible(false)}
                />
                <ContactTechnicianModal
                    visible={isContactTechnicianModalVisible}
                    ticketDetails={ticketDetails}
                    onCancel={() => setIsContactTechnicianModalVisible(false)}
                />
                <Modal
                    visible={isBeginSupportModalVisible}
                    title={null}
                    onCancel={() => isBeginSupportApiCallInProgress ? false : setIsBeginSupportModalVisible(false)}
                    className="resolve-ticket-modal"
                    footer={false}
                >
                    <Title level={3} className='font-weight-500' style={{marginTop: '-0.8rem'}}>Are you sure?</Title>
                    <Space className='w-100' direction='vertical'>
                        <div className='disclaimer p-2'>
                            <Text>
                            Are you sure you want to start servicing this ticket? Remember, you have the option to pause or resume the service timer once you begin providing support. Additionally, if necessary, you can update the ticket status to 'pending' for any reason and modify the time entry in the timesheet section.
                            </Text>
                        </div>
                        <Space direction='vertical'>
                            <Checkbox 
                                checked={startTimerOnBeginSupport}
                                onChange={(e) => setStartTimerOnBeginSupport(e.target.checked)}
                                disabled={isWeeklyLimitReached()}
                            >
                                Start my billable timer too
                            </Checkbox>
                            {
                                isWeeklyLimitReached() &&
                                <Space align='top' className='mb-2'>
                                    <InfoCircleOutlined style={{position:"relative", top:"3px"}}/>
                                    <Text type='secondary' className='font-size-14'>  
                                        Your hourly limit has been reached. You cannot start a billable timer. Please contact the customer to request an increase in the hourly limit.
                                    </Text>
                                </Space>
                            }
                        </Space>
                    </Space>
                    <Space align='center' className='w-100 justify-content-end'>
                        <Button type='text'
                                onClick={() => isBeginSupportApiCallInProgress ? false : setIsBeginSupportModalVisible(false)}>Cancel</Button>
                        <Button
                            type='primary'
                            loading={isBeginSupportApiCallInProgress}
                            onClick={handleBeginSupportClick}
                        >
                            Begin
                        </Button>
                    </Space>
                </Modal>
                {/* <Modal
					visible={isReleasePaymentModalVisible}
					title={null}
					onCancel={() => isReleasePaymentApiCallInProgress ? false : setIsReleasePaymentModalVisible(false)}
					className="resolve-ticket-modal"
					footer={false}
				>
					<Title level={4} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
					<Space className='w-100' direction='vertical'>
						<Text strong>{contractDetails?.Type} Contract at ${Number(contractDetails?.Billing_Rate) / 100}/hr</Text>
						<Space className='w-100 disclaimer p-2' size={20} align="start">
							<Image src={Bulb} preview={false} alt="bulb" width={28} height={28} className='ms-2' style={{ marginTop: 2 }} />
							<Text>
								You will be charged <Text strong>${ticketInvoiceInfo.price}</Text> for <Text strong>{ticketInvoiceInfo.duration}</Text> immediately for this ticket.
							</Text>
						</Space>
					</Space>
					<Space align='center' className='w-100 justify-content-end mt-3'>
						<Button type='text' onClick={() => isReleasePaymentApiCallInProgress ? false : setIsReleasePaymentModalVisible(false)}>
							Cancel
						</Button>
						<Button
							type='primary'
							loading={isReleasePaymentApiCallInProgress}
							onClick={async () => {
								setIsReleasePaymentApiCallInProgress(true)
								const res = await ticketRepository?.payInvoice(ticketDetails?.id)
								if(res?.data?.status === "success") {
									await getAndSetTicketDetails()
									await getAndSetActivities()
								}
								setIsReleasePaymentApiCallInProgress(false)
								setIsReleasePaymentModalVisible(false)
							}}
						>
							Pay Invoice
						</Button>
					</Space>
				</Modal> */}
                <Modal
                    visible={isResumeSupportModalVisible}
                    title={null}
                    onCancel={() => isResumeSupportApiCallInProgress ? false : setIsResumeSupportModalVisible(false)}
                    className="resolve-ticket-modal"
                    footer={false}
                >
                    <Title level={3} className='font-weight-500' style={{marginTop: '-0.8rem'}}>Are you sure?</Title>
                    <Space className='w-100' direction='vertical'>
                        <div className='disclaimer p-2'>
                            <Text>
                                Are you sure you want to resume the support for this ticket?
                            </Text>
                        </div>
                        <Checkbox 
                            checked={startTimerOnResumeSupport}
                            onChange={(e) => setStartTimerOnResumeSupport(e.target.checked)}
                        >
                           Start my billable timer too
                        </Checkbox>
                        {
                            isWeeklyLimitReached() &&
                            <Space align='top' className='mb-2'>
                                <InfoCircleOutlined style={{position:"relative", top:"3px"}}/>
                                <Text type='secondary' className='font-size-14'>  
                                    Your hourly limit has been reached. You cannot start a billable timer. Please contact the customer to request an increase in the hourly limit.
                                </Text>
                            </Space>
                        }
                    </Space>
                    <Space align='center' className='w-100 justify-content-end'>
                        <Button type='text'
                                onClick={() => isResumeSupportApiCallInProgress ? false : setIsResumeSupportModalVisible(false)}>Cancel</Button>
                        <Button
                            type='primary'
                            loading={isResumeSupportApiCallInProgress}
                            onClick={handleResumeSupportClick}
                        >
                            Resume
                        </Button>
                    </Space>
                </Modal>
                {
                    contractDetails && ticketDetails && showTimeEntryModal &&
                    <ManualTimeModal
                        showTimeEntryModal={showTimeEntryModal}
                        setShowTimeEntryModal={setShowTimeEntryModal}
                        repository={repository}
                        contractId={ticketDetails?.Contract?.id}
                        totalHours={billingWeekTotalHours}
                        maxAllowableHours={contractDetails?.Hours_Upper_Limit}
                        contractDetails={contractDetails}
                        ticketId={ticketDetails?.id}
                        getAndSetTicketDetails={getAndSetTicketDetails}
                        getAndSetActivities={getAndSetActivities}
                        getAndSetFilteredTimeSheetsData={getTimeSheetData}
                        ticketDetails={ticketDetails}
                        upComingInvoice={upComingInvoice}
                    />
                }


                {/*/>*/}
                {(isMobile || isTablet) && (
                    <BottomNavigation
                        activeBgColor={SECONDARY_COLOR}
                        activeTextColor='white'
                        items={getItems()}
                        defaultSelected={selectedTab.id}
                        onItemClick={(item) => {
                            setSelectedTab({
                                id: item.id,
                                title: item.title,
                            })
                            document.querySelector("body")?.scrollIntoView()
                        }}
                    />
                )}
                {
                    tech &&
                    <TechConnectModal
                        tech={tech}
                        serviceDetails={isTechnicianAccountSetupModalVisible}
                        onCancel={() => setIsTechnicianAccountSetupModalVisible(false)}
                    />
                }
            </>
        )
    }

    export const LocationTechnicians = ({
                                            selectedContract,
                                            setSelectedContract,
                                            isFromTechSelectModal = false,
                                            apiCallInProgress,
                                            title,
                                            onOk,
                                            longLatTechnicians,
                                            filteredContractsList,
                                            setFilteredContractsList,
                                            ticketDetails,
                                            isFromReassignAction
                                        }) => {
        const user = useSelector(state => state.user)
        const [contractsList, setContractsList] = useState([])
        const [loading, setLoading] = useState(true)
        const [selectedItem, setSelectedItem] = useState('')
        const [noActiveContractsExists, setNoActiveContractsExists] = useState(false);

        useEffect(() => {
            if (!!user) {
                ApiBaseHelper.get({
                    url: "contract?role=customer", headers: {
                        Authorization: 'Bearer ' + user?.token
                    }
                }).then((res) => {
                    if (isFromTechSelectModal) setLoading(false)
                    if (res?.data?.data?.length) {
                        const filteredData = res.data.data.filter(contract => contract.Status === "Active");
                        if (filteredData?.length) {
                            setContractsList(filteredData)
                            const selectedContractDetails = filteredData?.find(item => ticketDetails?.Provider_Contact?.id == item.Provider_Org?.HIPAA_Primary_Contact)
                            setSelectedItem(selectedContractDetails)
                            if (!isFromTechSelectModal) {
                                setSelectedContract(selectedContractDetails)
                            }
                        } else {
                            setNoActiveContractsExists(true)
                        }
                    }
                }).catch((error) => console.error(error))
            }
        }, [])

        useEffect(() => {
            //   if(longLatTechnicians?.length && contractsList?.length) {
            if (contractsList?.length) {
                // const filertedList = contractsList.filter(contract => {
                // 	const hipaaId = contract.Provider_Org?.HIPAA_Primary_Contact;
                // 	if(longLatTechnicians.find(({ data }) => data?.Hipaa_id === hipaaId)) {
                // 		return true
                // 	}

                // 	return false
                // })

                // if(filertedList?.length) setFilteredContractsList(filertedList)
                if (!isFromTechSelectModal) {
                    setFilteredContractsList(contractsList)
                }
                setLoading(false)
            }
        }, [contractsList?.length])
// }, [longLatTechnicians?.length, contractsList?.length])

        const list = isFromTechSelectModal ? contractsList : filteredContractsList

        return loading && (isFromTechSelectModal ? true : !selectedContract) ? (
            <Card className='d-flex justify-content-center'>
                <LoadingOutlined
                    style={{
                        color: SECONDARY_COLOR,
                        fontSize: 30
                    }}
                />
            </Card>
        ) : (
            <>
                {
                    (!list?.length || noActiveContractsExists) ? (
                        <EmptyState isFromTechSelectModal={isFromTechSelectModal}/>
                    ) : (
                        <>
                            {isFromTechSelectModal && title}
                            {list.map((item) => {
                                return (
                                    <Card
                                        key={item.Provider_Org?.id}
                                        className={`location-technician-card ${selectedItem?.Name == item.Name && 'location-technician-card-active'}`}
                                        onClick={() => {
                                            setSelectedItem(item)
                                            if (!isFromTechSelectModal) {
                                                setSelectedContract(item)
                                            }
                                        }}
                                    >
                                        <Space align='start'>
                                            <Avatar shape='circle' className='mt-1' size={46}
                                                    src={item?.Provider_Org?.Contact_Image}
                                                    alt={(item?.Provider_Org?.HIPAA_Contact_Name || item?.Provider_Org?.Name)}>
                                                {item?.Provider_Org?.HIPAA_Contact_Name ? item?.Provider_Org?.HIPAA_Contact_Name?.[0] : item?.Provider_Org?.Name?.[0]}
                                            </Avatar>
                                            <div>
                                                <Space>
                                                    <Paragraph level={5}
                                                               style={{marginBottom: 0}}>{item?.Provider_Org?.HIPAA_Contact_Name || item?.Provider_Org?.Name}</Paragraph>
                                                    {/* <Space size={4}>
													<EnvironmentOutlined className="location-icon"/>
													<Text className='location-city'>{item?.location}</Text>
												</Space> */}
                                                </Space>
                                                <div>
                                                    <Text style={{color: "#787878"}}>
                                                        {item?.Type === 'Fixed' ? 'Retainer' : item?.Type === 'Per Ticket' ? 'Per Issue' : item?.Type} Contract
                                                        (${Number(item.Billing_Rate || 0) / 100}{item.Billing_Frequency ? (item.Billing_Frequency === 'Bi-weekly' ? ' bi-weekly' : `/${item.Billing_Frequency}`) : '/hr'})
                                                    </Text>
                                                </div>
                                                <Paragraph type='secondary' className='text-wrap'
                                                           ellipsis={{rows: 2, expandable: true}}>
                                                    {item.Description}
                                                </Paragraph>
                                            </div>
                                        </Space>
                                    </Card>
                                )
                            })}
                            {isFromTechSelectModal && (
                                <Space className='w-100 justify-content-end mt-3'>
                                    <Button type='text' onClick={onOk}>
                                        Cancel
                                    </Button>
                                    <Button type='primary' onClick={() => {
                                        if (selectedItem?.id === ticketDetails?.Contract?.id) {
                                            onOk()
                                        } else {
                                            setSelectedContract(selectedItem)
                                        }
                                    }} loading={apiCallInProgress}>
                                        {isFromReassignAction ? "Reassign" : "Assign"}
                                    </Button>
                                </Space>
                            )}
                        </>
                    )
                }
            </>
        )
    }

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    export const AvailabilitySection = ({timeSlot, setTimeSlot, schedule, value, onChange}) => {
        const [unavailableDates, setUnavailableDates] = useState({});
        const [dateSet, setDateSet] = useState(false);
        const [availableSlots, setAvailableSlots] = useState([]);
        // const [timeSlot, setTimeSlot] = useState("")

        useEffect(() => {
            const newUnavailableDates = {}
            for (let i = 0; i < schedule['Schedule_Amendments']?.length; i++) {
                let itemSchedule = schedule['Schedule_Amendments']?.[i];
                newUnavailableDates[itemSchedule['Date']] = itemSchedule;
            }
            // const getActiveDate = () => {
            // 	if(validateDateForBooking(value)) return value

            // 	for(let i = 1; i < 10; i++) {
            // 		const date = new Date();
            // 		date.setDate(date.getDate() + i);
            // 		if(!validateDateForBooking(date)) {
            // 			return date
            // 		}
            // 	}
            // }
            setUnavailableDates(newUnavailableDates)
            populateAvailableSlots(value)
        }, [])

        const populateAvailableSlots = (date) => {
            let selectedDay = days[date.getDay()];
            let slotInterval = 900;
            let scheduleForSelectedDay = schedule['Schedule'][selectedDay];
            if (scheduleForSelectedDay == undefined) {
                return;
            }
            // empty/remove all slots if date change
            setAvailableSlots([])
            setTimeSlot(null)
            let availableSlots = [];
            let selectedDate = moment(date).format('YYYY-MM-DD');

            for (let k = 0; k < scheduleForSelectedDay.length; k++) {
                let startTime = moment(selectedDate + 'T' + scheduleForSelectedDay[k]['Start_Time']);
                let endTime = moment(selectedDate + 'T' + scheduleForSelectedDay[k]['End_Time']);
                startTime = moment(`${selectedDate} ${startTime.format('HH:mm:ss')}`, 'YYYY-MM-DD HH:mm:ss')
                endTime = moment(`${selectedDate} ${endTime.format('HH:mm:ss')}`, 'YYYY-MM-DD HH:mm:ss')
                let dayEndTime = moment(`${selectedDate} 23:59:00`, "YYYY-MM-DD HH:mm:ss");

                let startTimeSlot = startTime;
                let isEndTimeBeforeStart = false;

                if (endTime.isBefore(startTime)) {
                    isEndTimeBeforeStart = true;
                    startTimeSlot = moment(`${selectedDate} 00:00:00`, "YYYY-MM-DD HH:mm:ss");
                    for (; (startTimeSlot.isBefore(endTime));) {
                        let endTimeSlot = moment(startTimeSlot);
                        let startTimeSlot2 = moment(startTimeSlot);

                        endTimeSlot = endTimeSlot.add(slotInterval, 'seconds');

                        let slot = {
                            "start": startTimeSlot2,
                            "end": endTimeSlot,
                        };

                        if (selectedDate in unavailableDates) {
                            let uStartTime = moment(selectedDate + 'T' + unavailableDates[selectedDate]['Start_Time']);
                            let uEndTime = moment(selectedDate + 'T' + unavailableDates[selectedDate]['End_Time']);
                            let unavailableRange = moment.range(uStartTime, uEndTime);
                            let currentTimeSlotRange = moment.range(startTimeSlot2, endTimeSlot);
                            if (currentTimeSlotRange.overlaps(unavailableRange) == false) {
                                availableSlots.push(slot);
                            }
                        } else {
                            availableSlots.push(slot);
                        }
                        startTimeSlot = startTimeSlot.add(slotInterval, 'seconds');
                    }
                }

                startTimeSlot = startTime;

                for (; (startTimeSlot.isBefore(endTime) || (startTimeSlot.isBefore(dayEndTime) && isEndTimeBeforeStart));) {
                    let endTimeSlot = moment(startTimeSlot);
                    let startTimeSlot2 = moment(startTimeSlot);

                    endTimeSlot = endTimeSlot.add(slotInterval, 'seconds');

                    let slot = {
                        "start": startTimeSlot2,
                        "end": endTimeSlot,
                    };

                    if (selectedDate in unavailableDates) {
                        let uStartTime = moment(selectedDate + 'T' + unavailableDates[selectedDate]['Start_Time']);
                        let uEndTime = moment(selectedDate + 'T' + unavailableDates[selectedDate]['End_Time']);
                        let unavailableRange = moment.range(uStartTime, uEndTime);
                        let currentTimeSlotRange = moment.range(startTimeSlot2, endTimeSlot);
                        if (currentTimeSlotRange.overlaps(unavailableRange) == false) {
                            availableSlots.push(slot);
                        }
                    } else {
                        availableSlots.push(slot);
                    }
                    startTimeSlot = startTimeSlot.add(slotInterval, 'seconds');
                }
            }

            const selectedTimeSlot = availableSlots?.find(slot => slot.start?._d?.toString() === value?.toString())
            if (selectedTimeSlot) {
                setTimeSlot(selectedTimeSlot)
            }
            setAvailableSlots(availableSlots)
        }

        const validateDateForBooking = (date) => {
            let currentDate = new Date();
            let slotInterval = 900;
            if (date.getTime() <= currentDate.getTime())
                return true;
            let selectedDay = days[date.getDay()];
            let scheduleForSelectedDay = schedule['Schedule'][selectedDay];
            if (scheduleForSelectedDay == undefined) {
                return true;
            }
            let availableSlots = [];
            let selectedDate = moment(date).format('YYYY-MM-DD');

            for (let k = 0; k < scheduleForSelectedDay.length; k++) {
                let startTime = moment(selectedDate + 'T' + scheduleForSelectedDay[k]['Start_Time']);
                let endTime = moment(selectedDate + 'T' + scheduleForSelectedDay[k]['End_Time']);
                startTime = moment(`${selectedDate} ${startTime.format('HH:mm:ss')}`, 'YYYY-MM-DD HH:mm:ss')
                endTime = moment(`${selectedDate} ${endTime.format('HH:mm:ss')}`, 'YYYY-MM-DD HH:mm:ss')
                let dayEndTime = moment(`${selectedDate} 23:59:00`, "YYYY-MM-DD HH:mm:ss");

                let startTimeSlot = startTime;
                let isEndTimeBeforeStart = false;

                if (endTime.isBefore(startTime)) {
                    isEndTimeBeforeStart = true;
                    startTimeSlot = moment(`${selectedDate} 00:00:00`, "YYYY-MM-DD HH:mm:ss");
                    for (; (startTimeSlot.isBefore(endTime));) {
                        let endTimeSlot = moment(startTimeSlot);
                        let startTimeSlot2 = moment(startTimeSlot);

                        endTimeSlot = endTimeSlot.add(slotInterval, 'seconds');

                        let slot = {
                            "start": startTimeSlot2,
                            "end": endTimeSlot,
                        };

                        if (selectedDate in unavailableDates) {
                            let uStartTime = moment(selectedDate + 'T' + unavailableDates[selectedDate]['Start_Time']);
                            let uEndTime = moment(selectedDate + 'T' + unavailableDates[selectedDate]['End_Time']);
                            let unavailableRange = moment.range(uStartTime, uEndTime);
                            let currentTimeSlotRange = moment.range(startTimeSlot2, endTimeSlot);
                            if (currentTimeSlotRange.overlaps(unavailableRange) == false) {
                                availableSlots.push(slot);
                            }
                        } else {
                            availableSlots.push(slot);
                        }
                        startTimeSlot = startTimeSlot.add(slotInterval, 'seconds');
                    }
                }

                startTimeSlot = startTime;

                for (; (startTimeSlot.isBefore(endTime) || (startTimeSlot.isBefore(dayEndTime) && isEndTimeBeforeStart));) {
                    let endTimeSlot = moment(startTimeSlot);
                    let startTimeSlot2 = moment(startTimeSlot);

                    endTimeSlot = endTimeSlot.add(slotInterval, 'seconds');

                    let slot = {
                        "start": startTimeSlot2,
                        "end": endTimeSlot,
                    };

                    if (selectedDate in unavailableDates) {
                        let uStartTime = moment(selectedDate + 'T' + unavailableDates[selectedDate]['Start_Time']);
                        let uEndTime = moment(selectedDate + 'T' + unavailableDates[selectedDate]['End_Time']);
                        let unavailableRange = moment.range(uStartTime, uEndTime);
                        let currentTimeSlotRange = moment.range(startTimeSlot2, endTimeSlot);
                        if (currentTimeSlotRange.overlaps(unavailableRange) == false) {
                            availableSlots.push(slot);
                        }
                    } else {
                        availableSlots.push(slot);
                    }
                    startTimeSlot = startTimeSlot.add(slotInterval, 'seconds');
                }
            }

            availableSlots.pop();
            if (availableSlots.length > 0 && dateSet == false) {
                // onChange(date);
                setDateSet(true);
                // populateAvailableSlots(date);
            }
            return availableSlots.length === 0;
        }

        const scheduledDate = moment(moment.utc(value).toDate()).local();
        let scheduledDateTime;
        scheduledDateTime = scheduledDate.format('MMM Do YYYY');
        if (typeof timeSlot !== 'string') {
            const scheduledTime = moment(moment.utc(timeSlot?.start).toDate()).local();
            scheduledDateTime = scheduledDate.format('MMM Do YYYY') + ' ' + scheduledTime.format('h:mm a');
        }

        return (
            <div>
                <p style={{margin: 5}}>{scheduledDateTime}</p>
                <Row gutter={[15, 15]}>
                    <Col xl={14}>
                        <Calendar
                            tileDisabled={({activeStartDate, date, view}) => {
                                return validateDateForBooking(date);
                            }}
                            tileClassName={({activeStartDate, date, view}) => {
                                let className = [];
                                // let className = ['react-calendar_enable'];
                                if (validateDateForBooking(date))
                                    className = ['react-calendar__disable'];
                                // if (moment(date).isSame(moment(), 'day'))
                                // 	className.push('react-calendar-current-date');
                                return className.join(' ');
                            }}
                            className={"tech-availability-calendar"}
                            value={value}
                            onChange={newDate => {
                                onChange(newDate)
                                populateAvailableSlots(newDate);
                            }}
                            nextLabel={<RightOutlined style={{color: "rgba(0, 0, 0, 0.25)"}}/>}
                            prevLabel={<LeftOutlined style={{color: "rgba(0, 0, 0, 0.25)"}}/>}
                            next2Label={null}
                            // next2Label={<DoubleRightOutlined style={{color:"rgba(0, 0, 0, 0.25)"}} />}
                            prev2Label={null}
                            // prev2Label={<DoubleLeftOutlined style={{color:"rgba(0, 0, 0, 0.25)"}}/>}
                            formatMonthYear={(locale, date) => moment(date).format("MMM  YYYY")}
                        />
                    </Col>
                    <Col xl={10}>
                        <div
                            className={`time-slots-ctn scroll ${!availableSlots.length ? 'd-flex align-items-center' : ''}`}>
                            <Radio.Group
                                className="radio-gray"
                                onChange={(e) => {
                                    setTimeSlot(e.target.value)
                                }}
                                value={timeSlot}
                            >
                                <Row gutter={[10, 10]}>
                                    {availableSlots.map(function (slot, index) {
                                        return (
                                            <Col span={24} key={index} className="time-slot">
                                                <Radio className="time-slot-radio" value={slot}>
                                                    {slot?.['start'].format('hh:mma')}
                                                </Radio>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Radio.Group>
                            {!availableSlots.length && (
                                <Col span={24} className="time-slot">
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No slot available"/>
                                </Col>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        )

    }

    const EmptyState = ({isFromTechSelectModal = false}) => {
        const history = useHistory()

        const handleButtonClick = () => history.push(ROUTES.DISCOVERY_EXPERIENCE)

        return (
            <>
                <div className='empty-tech-row' style={{
                    borderColor: isFromTechSelectModal ? SECONDARY_COLOR : '#D9D9D9'
                }}>
                    <Space align='center' size={15}>
                        {isFromTechSelectModal && (
                            <BulbOutlined style={{
                                color: SECONDARY_COLOR,
                                fontSize: 30
                            }}/>
                        )}
                        <div>
                            <Paragraph underline={!isFromTechSelectModal}>
                                {isFromTechSelectModal ? 'You have no technicians with any active contract.' : 'No local technicians found for this location'}
                            </Paragraph>
                            <Paragraph>
                                {isFromTechSelectModal ? 'To fix assign technician, create a contract with a technician has the skill set you need.' : 'To get help, create a contract with a technician in the area that matches the skills needed for this ticket.'}
                            </Paragraph>
                        </div>
                    </Space>
                    {!isFromTechSelectModal && (
                        <Button style={{marginTop: 10}} onClick={handleButtonClick}>View Matching Technicians</Button>
                    )}
                </div>
                {isFromTechSelectModal && (
                    <>
                        <Divider/>
                        <Space className='w-100 justify-content-end'>
                            <Button onClick={handleButtonClick}>Find Technicians</Button>
                        </Space>
                    </>
                )}
            </>
        )
    }

    export default TicketDetails
