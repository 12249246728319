import { ApiBaseHelper } from "../../../../../../utils/api-base-helper";

export class LocationRepository {
    constructor(accessToken) {
        this.accessToken = accessToken;
    }

    getLocations() {
        return ApiBaseHelper.get(
            {
                url: 'home',
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    getLocation(locationId) {
        return ApiBaseHelper.get(
            {
                url: `home/${locationId}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    editLocation(locationId, data) {
        return ApiBaseHelper.put(
            {
                url: `home/${locationId}`,
                payload: data,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    addLocation(data) {
        return ApiBaseHelper.post(
            {
                url: `home`,
                payload: data,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    locationDelete(locationId) {
        return ApiBaseHelper.delete(
            {
                url: `home/${locationId}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }
}

