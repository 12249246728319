import React from 'react'
import { MetaTags } from 'react-meta-tags'
import { Button, Col, Layout, Progress, Row, Space, Steps, Typography } from 'antd'
import UtilityHeader from '../../../../components/utility-header'
import '../assets/css/steps-layout.scss'
import { META_DESCRIPTION } from '../../../../../utils/constants'

const {Content} = Layout
const {Paragraph, Title} = Typography
const { Step } = Steps;

const StepsLayout = ({progressSteps, progressIndex, pageTitle, stepsTitle, children}) => {

    return (
        <>
            <MetaTags>
                <title>{pageTitle} - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <Content className="main">
                <div>
                    <Row className='client-onboarding-row'>
                        <Col xs={24} sm={24} md={24} lg={16} xl={18} className='content-section'>
                            <UtilityHeader />
                            <div className="content-ctn">
                               {children}
                            </div>
                        </Col>
                        <Col xs={0} sm={0} md={0} lg={8} xl={6} className='steps-section'>
                            <div className="steps-ctn">
                                <div className='steps-inner'>
                                    <div className="steps-title">
                                        <Title level={4}>{stepsTitle}</Title>
                                    </div>
                                    <div className="steps">
                                        <Steps current={progressIndex} className='onboarding-steps' direction='vertical'  size="small"> 
                                            {
                                                progressSteps.map((item, index) => <Step key={index} title={item?.title} description={item?.description} />
                                                )
                                            }
                                        </Steps>
                                    </div>
                                </div>
                                <div className="step-footer">
                                   <Paragraph style={{marginBottom:0, color:"#FFF", fontSize:14}}>Need help? <a href='https://tecme.io/contact-us/' style={{color:"#FFF"}} target='_blank'>Connect with support</a></Paragraph>         
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Content>
        </>
    )
}

export default StepsLayout