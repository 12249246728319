import routes from "./routes";

// User with role `manager` can read only on these routes
export const  ManagerRouteBlacklist = [
    routes.TEAM_MANAGEMENT,
    routes.PAYMENT,
    routes.ADD_PAYMENT,
    routes.ACCOUNT_SETTING,
]

// User with role `recruiter` can read only on these routes
export const RecruiterRouteBlacklist = [
    routes.TEAM_MANAGEMENT,
    routes.CONTRACTS,
    routes.CREATE_SEND_CONTRACT,
    routes.VIEW_EDIT_CONTRACT,
    routes.TICKET_LISTING,
    routes.TICKET_DETAILS,
    routes.PAYMENT,
    routes.ADD_PAYMENT,
    routes.ACCOUNT_SETTING,
    routes.MANAGE_BOOKING,
    routes.BOOKING_DETAILS,
    routes.BOOKING_DETAILS_NEW,
    routes.SERVICE_DETAILS,
    routes.SERVICE_DETAILS_NEW,
    routes.INVOICE,
    routes.NEW_INVOICE,
]