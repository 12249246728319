import React, {Component, Fragment} from "react";
import {Button, Card, Col, Collapse, Divider, Layout, Row, Skeleton, Typography} from 'antd';

import ServicesTabsComponent from "./components/services-tabs";
import {ApiBaseHelper} from "../../../../../../utils/api-base-helper";
import {LoadingOutlined} from '@ant-design/icons';
import {connect} from "react-redux";
import {META_DESCRIPTION} from "../../../../../../utils/constants";
import MetaTags from "react-meta-tags";
import AddIcon from '@mui/icons-material/Add';
import ROUTES from '../../../../../../utils/routes';
import Icon from '../../../../../../assets/images/icon.png'
import PageHeaderWrapper from "../../../../../components/page-header-breadcrumbs";
import {Link} from "react-router-dom";
import ServicesTable from "./components/services-table";

const antIcon = <LoadingOutlined style={{fontSize: 54}} spin/>;
const {Content} = Layout;
const {Title, Paragraph, Text} = Typography;
const {Panel} = Collapse;

class MyServices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            loading: true,
            dialogBox: true,
            isHipaaExpert: false,
            dialogMessage: '',
            data: null,
            schedule: {},
            services: []
        };
        this.currentUrl = window.location.href.replace("#", "%23");
        this.showModal = this.showModal.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.getTech = this.getTech.bind(this);

    }

    showModal() {
        this.setState({
            isModalVisible: true
        });
    };

    getTech() {
        let code = this.props['location']['pathname'].split('/')[2];
        if (this.props['location']['hash'] != '') {
            code = `${code}${this.props['location']['hash']}`;
        }
        this.setState({
            loading: true
        });

        // Get
        ApiBaseHelper.get({url: "web/search-tech?contactId=" + this.props.tech.id}).then((res) => {
            let services = [];
            res.data['data']['Groups'][0]['Services'].map((service, index) => {
                if (service['Pricing'].length != 0) {
                    services.push(service);
                }
            });

            let skills = res.data['data']['Skills'];
            let isHipaaExpert = false
            skills.map((item, index) => {
                if (item['Title'].toString().toLowerCase() == 'hipaa')
                    isHipaaExpert = true
            });

            this.setState({
                loading: false,
                isHipaaExpert,
                data: res.data['data'],
                services
            });
        }).catch((res) => {
            this.props.history.push('/');
        });
    };

    handleCancel() {
        this.setState({
            isModalVisible: false
        });
    };

    componentDidMount() {
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
          }
        if (this.props['location']['state'] == undefined) {
            this.getTech();
        } else {
            this.setState({
                data: this.props['location']['state']['data'],
                loading: false
            });
        }
    }

    render() {
        const {loading, data, schedule, services,isHipaaExpert} = this.state;
        // if (loading)
        //     return this.renderSkelton();

        return (
            <Fragment>
                <MetaTags>
                    <title>Manage Services | TecMe </title>
                    <meta name="description" content={META_DESCRIPTION}/>
                    <meta name="robots" content="noindex, nofollow"/>
                </MetaTags>
                <Content className="main">
                    <div className="page">
                        <Row gutter={[20, 20]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <PageHeaderWrapper
                                    routes={[
                                        {
                                            path: ROUTES.MY_SERVICE,
                                            breadcrumbName: 'Services',
                                        },
                                    ]}
                                  
                                />
                                <div className="auth-container">
                                    <ServicesTable history={this.props.history} schedule={schedule} techProfile={data?.Data} data={services} isHipaaExpert={isHipaaExpert} loading={loading}/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </Fragment>
        );
    }

    renderSkelton() {

        return <Fragment>
            <Content className="main">
                <div className="page">

                    <div className="container">
                        <Row gutter={[30, 20]}>

                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className="tabs-wrp">
                                <Card style={{marginBottom:30}}>
                                    <Skeleton loading={true} active={true}/>
                                </Card>
                                <Skeleton.Input
                                    style={{ width: "300px", marginTop:20}}
                                    active={true}
                                />  
                                {
                                    [...Array(10).keys()].map(() => (
                                        <Skeleton.Input
                                            style={{ width: "100%", height:70, marginTop:20}}
                                            active={true}
                                            size="default"
                                        />  
                                    ))
                                        
                                }
                                

                               
                                    {/* <Row gutter={[15, 15]}>
                                        {
                                            Array.from({length: 8}).map((item, index) => {
                                                return <Col key={index} xs={24} sm={12} md={8} lg={6} xl={6}>
                                                    <Card>
                                                        <Skeleton loading={true}/>
                                                        <Skeleton loading={true}/>
                                                        <Skeleton loading={true}/>
                                                    </Card>
                                                </Col>
                                            })
                                        }

                                    </Row> */}

                                    <ServicesTabsComponent/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Content>
        </Fragment>
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(MyServices);
