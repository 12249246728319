import { Modal } from 'antd'
import React from 'react'
import { Button, Space, Typography } from 'antd'

const {Title, Paragraph} = Typography

const ActionModal = ({visible, handleCancel, message, loading, deleteText, handleClick, title="Are you sure?"}) => {
  return (
    <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={null}
    >
        <Title level={3} className='font-weight-500' style={{marginTop: '-0.8rem'}}>{title}</Title>
        <Paragraph className='my-2 disclaimer p-2'>
           {message}
        </Paragraph>
        <Space align='center' className='w-100 justify-content-end'>
            <Button type='text' onClick={handleCancel}>Cancel</Button>
            <Button 
                type='primary' 
                loading={loading}
                onClick={handleClick}>{deleteText}</Button>
        </Space>
    </Modal>
  )
}

export default ActionModal