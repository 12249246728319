import React from 'react'
import { MetaTags } from 'react-meta-tags'
import { META_DESCRIPTION } from '../../../../utils/constants'
import { Button, Col, Layout, Progress, Row, Space, Steps, Typography } from 'antd'
import './assets/css/styles.scss'
import { LeftOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ROUTES from '../../../../utils/routes'
import { useHistory } from 'react-router-dom'
import  './assets/css/styles.scss'
import PageHeaderWrapper from '../../../components/page-header-breadcrumbs'
import GettingStarted from './components/getting-started'
import JobTitle from './components/job-title'
import JobDescription from './components/job-description'
import JobCategory from './components/job-category'
import JobSkills from './components/job-skills'
import { ApiBaseHelper } from '../../../../utils/api-base-helper'
import JobType from './components/job-type'
import JobBudget from './components/job-budget'
import JobDuration from './components/job-duration'
import JobPreview from './components/job-preview'
import SuccessJobPost from './components/success-job-post'
import { getSpecializationTitle } from '../../common/private/job-post/utils/helper'
import axios from 'axios';
import { generateAiResponse } from '../../../../utils/openai-helper'

const {Content} = Layout
const {Paragraph, Title} = Typography
const { Step } = Steps;

const AddJobPosting = () => {
    const user = useSelector((state) => state.user)
    const generalSkills = useSelector((state) => state.generalSkillsGlobal)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [progress, setProgress] = useState(0)
    const history = useHistory()
    const [selectedCategoryId, setSelectedCategoryId] = useState(null)
    const [generatedSkills, setGeneratedSkills] = useState([])
    const [loadingGeneratedSkills, setLoadingGeneratedSkills] = useState(false)
    const [jobPostingData, setJobPostingData] = useState({
        job_title: null,
        job_description: null,
        job_category: null,
        job_skills: [],
        job_generated_skills: [],
        job_type: null,
        job_budget_from: null,
        job_budget_to: null,
        job_budget_fixed: null,
        job_duration: null,
        job_payment_type: null,
        job_files: [],
    })

    const [updatedJobPostingData, setUpdatedJobPostingData] = useState({
        job_title: null,
        job_description: null,
        job_category: null,
        job_skills: [],
        job_generated_skills: [],
        job_type: null,
        job_budget_from: null,
        job_budget_to: null,
        job_budget_fixed: null,
        job_duration: null,
        job_payment_type: null,
        job_files: [],
    })


    const handleNextClick = () => {
        if(currentIndex < jobPostingSteps.length - 1){
            setCurrentIndex(currentIndex + 1)
            setProgress(((currentIndex+1) / 7) * 100)
            
        }
        window.scrollTo(0, 0)
        // history.push(`${ROUTES.ADD_JOB_POSTING}?step=${currentIndex + 1}`)
    }

    const handleBackClick = () => {
        if(currentIndex > 0){
            setCurrentIndex(currentIndex - 1)
            setProgress(((currentIndex-1) / 7) * 100)
        }
    }


    const getGeneratedSkills = async () => {

        const prompt = `List the essential skills for a \"${jobPostingData?.job_title}\" specialized in \"${getSpecializationTitle(generalSkills, selectedCategoryId)}\". Separated by comma. A maximum of 10 skills and remove the noun adjunct (like "understanding of", "knowledge of", etc) and just give only the skills`;

        setLoadingGeneratedSkills(true)
        const response = await generateAiResponse(prompt, user?.token)
        setLoadingGeneratedSkills(false)
        console.log(response)
        if(response){
            // Extract and format the IT skills from the response
            const skillsList = response?.content?.split(', ')
            setGeneratedSkills(skillsList);
        }  
    };


    const jobPostingSteps = [
        {
            step:1,
            content:<GettingStarted handleNextClick={handleNextClick}/>
        },
        {
            step:2,
            content: <JobTitle 
                handleNextClick={handleNextClick}
                jobPostingData={jobPostingData}
                setJobPostingData={setJobPostingData}
            />
        },
        {
            step:3,
            content:<JobCategory 
                setSelectedCategoryId={setSelectedCategoryId}
                handleNextClick={handleNextClick} 
                allAvailableSkills={generalSkills}
                jobPostingData={jobPostingData}
                setJobPostingData={setJobPostingData}
                getGeneratedSkills={getGeneratedSkills}
                user={user}
            />
        },
        {
            step:4,
            content: <JobSkills 
                handleNextClick={handleNextClick} 
                selectedCategoryId={selectedCategoryId} 
                allAvailableSkills={generalSkills}
                jobPostingData={jobPostingData}
                setJobPostingData={setJobPostingData}
                loadingGeneratedSkills={loadingGeneratedSkills}
                generatedSkills={generatedSkills}
                getGeneratedSkills={getGeneratedSkills}
                user={user}
            />
        },
        {
            step:5,
            content: <JobDescription 
                handleNextClick={handleNextClick}
                jobPostingData={jobPostingData}
                setJobPostingData={setJobPostingData}
            />
        },
        {
            step:6,
            content: <JobType 
                handleNextClick={handleNextClick} 
                user={user}
                jobPostingData={jobPostingData}
                setJobPostingData={setJobPostingData}
            />
        },
        {
            step:7,
            content: <JobBudget
                handleNextClick={handleNextClick} 
                jobPostingData={jobPostingData}
                setJobPostingData={setJobPostingData}
                user={user}
            />
        },
        {
            step:8,
            content: <JobDuration
                handleNextClick={handleNextClick} 
                jobPostingData={jobPostingData}
                setJobPostingData={setJobPostingData}
                user={user}
            />
        },
        {
            step:9,
            content:<JobPreview 
                handleNextClick={handleNextClick} 
                allAvailableSkills={generalSkills}
                setSelectedCategoryId={setSelectedCategoryId}
                selectedCategoryId={selectedCategoryId}
                jobPostingData={jobPostingData}
                setJobPostingData={setJobPostingData}
                loadingGeneratedSkills={loadingGeneratedSkills}
                generatedSkills={generatedSkills}
                getGeneratedSkills={getGeneratedSkills}
                currentStep={currentIndex}
                updatedJobPostingData={updatedJobPostingData}
                setUpdatedJobPostingData={setUpdatedJobPostingData}
                user={user}
            />
        },
        {
            step:10,
            content:<SuccessJobPost />
        }
    ]

    const stepsLength = jobPostingSteps.length - 1



   
    return (
        <>
            <MetaTags>
                <title>Add Job Posting - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <Content className="main">
                <div className='page'>
                    <div className='job-posting-ctn'>
                        <div className='job-posting-header'>
                            { 
                                (currentIndex != 1 &&  currentIndex < jobPostingSteps.length - 1) &&   
                                <Space onClick={handleBackClick}>
                                    <LeftOutlined />
                                    <Paragraph style={{marginBottom:0, color:"rgba(0, 0, 0, 0.85)", fontWeight:500, cursor:"pointer"}}> Go Back</Paragraph>
                                </Space>
                            }
                           {  
                                (currentIndex !== 0 && jobPostingSteps[currentIndex].step <= 8) &&
                                <div className='mb-2 mt-4'>
                                    <Paragraph style={{fontWeight:500, color:"#000", marginBottom:10}}>Post a job  ({currentIndex}/7)</Paragraph> 
                                    <Progress percent={progress} showInfo={false} />
                                </div>
                            }
                        </div>
                        <div className="job-posting-content">
                            {jobPostingSteps[currentIndex].content}
                        </div>
                    </div>
                </div>
                
            </Content>
        </>
    )
}

export default AddJobPosting