import { CloseOutlined, MailOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Space, Tooltip, Typography } from 'antd';
import React from 'react'
import { useState } from 'react';
import InviteMembersForm from '../../team-management/components/invite-members-form';
import { ApiBaseHelper } from '../../../../../utils/api-base-helper';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';


const { Text, Title, Paragraph } = Typography
const { useForm } = Form

const selectTeamRoles = [
    {
        name:"Administrator",
        value:"administrator",
        description:"Have unrestricted access to all features and functionalities of the platform. Can manage organization team members"
    },
    {
        name:"Manager",
        value:"manager",
        description:"Have all recruiter permissions. Can hire technicians, purchase IT services and manage payment methods."
    },
    {
        name:"Recruiter",
        value:"recruiter",
        description:"Can search, message technicians and manage saved lists."
    }
]

const TeamDetails = ({handleNextClick, setProgressIndex}) => {
    const user = useSelector((state) => state.user)
    const [form] = useForm()
    const [teamMembersList, setTeamMembersList] = useState([])

    
    const getTeamMembersList = () => {
        ApiBaseHelper.get({
            url: 'user-management/users',
            headers: {
                Authorization: 'Bearer ' + user?.token,
            },
        }).then((res) => {
            setTeamMembersList(res?.data?.data?.data)
        })
    }
    
    useEffect(() => {
        getTeamMembersList()
    }, [])
    

    return (
        <div className='team-details-section'>
            <div className="team-details-heading">
                <Title level={5} className='team-heading'>Team</Title>
                <Paragraph className='team-text'>Here you can invite other members to join your team.  By adding team members, you'll be able to delegate tasks, share resources, and foster a collaborative environment.</Paragraph>
            </div>
            <div className="team-details-form-ctn">
                <InviteMembersForm 
                    teamMembersList={teamMembersList}
                    getTeamMembersList={getTeamMembersList}
                    setProgressIndex={setProgressIndex} // setProgressIndex(3)
                    handleNextClick={handleNextClick}
                    isFromOnboarding={true}
                />
            </div>
        </div>
    )
}

export default TeamDetails