import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Space, Tooltip, notification } from 'antd';
import { PlayCircleTwoTone } from "@ant-design/icons"
import moment from "moment";
import Timer from "react-compound-timer";

import PlayIcon from '../assets/icons/play-icon.svg'
import PauseIcon from '../assets/icons/pause-icon.svg'
import { LoadingOutlined } from '@ant-design/icons';
import { ContractRepository } from '../../contracts/contract-details/repository';
import { SECONDARY_COLOR } from '../../../../../../utils/constants';
import { filterWeeklyTimeSheet } from '../../../../../../utils/helper';
import { useTimeSheet } from '../../contracts/contract-details/hooks/useTimesheet';
import {contractStatuses} from "../../contracts/contract-details";

const TicketDuration = ({ ticketDetails, getAndSetTicketDetails, ticketRepository, getAndSetActivities, isContractPaused,billingWeekTotalHours }) => {
    const timerStart = ticketDetails?.custom_data?.timer_start;
    let quantity = ticketDetails?.custom_data?.duration;

    const tech = useSelector(state => state.tech)
    const user = useSelector(state => state.user)
    const token = user?.token || tech?.token;
    const role = user ? 'customer' : 'technician'
	const [loading, setLoading] = useState(true)
	const [time, setTime] = useState(false);
	const [contractDetails, setContractDetails] = useState({});
    const getAndSetTimeSheetAndContractData = async () => {
        const contractRepository = new ContractRepository(token);
        const contractId = ticketDetails?.Contract?.id

        try {
            const contractsRes = await contractRepository.getContract(contractId, role);

            if(contractsRes.data?.data?.length) {
                setContractDetails(contractsRes.data.data[0])
            }
        } catch (error) {
            // notification.error({
            //     message: 'TecMe',
            //     description: 'get contract failed'
            // })
        }
        setLoading(false)
    }

    useEffect(() => {
       getAndSetTimeSheetAndContractData()
    }, [])

   

    if(quantity) {
        quantity = Number(quantity)
    }

    const isWeeklyLimitReached = () => {
        if(contractDetails?.Type === "Per Ticket"){ 
            return false
        }else{
            return !!(contractDetails?.Hours_Upper_Limit != '0'  && billingWeekTotalHours >= Number(contractDetails?.Hours_Upper_Limit));
        }
    }

    const getTooltipTitle = () => {
        if(ticketDetails?.Status === 'Pending') return "Resume support to clock more time"
        if(isWeeklyLimitReached()) {
            return "Your hourly limit has been reached. You cannot log more hours at this time. Please contact the customer to request an increase in the hourly limit."
        }

        return ''
    }

    return (
        <Timer
            initialTime={
                ticketDetails?.Status === 'In Progress' || ticketDetails?.Status === 'Open' ?
                    (
                        (!!timerStart ? moment.duration(moment().diff(moment(timerStart).utc(false))).asMilliseconds() : 0) + (quantity ? quantity * 60 * 1000 : 0)
                    )
                    : (quantity ? quantity * 60 * 1000 : 0)
            }
            lastUnit="h"
            startImmediately={(ticketDetails?.Status === 'Closed' || ticketDetails?.Status === 'Pending') ? false : !!timerStart}
            formatValue={(value) => `${(value < 10 && value > -1 ? `0${value}` : value)}`}
        >
            {!!user || isContractPaused ? (
                <Space
                    className='ticket-timing'
                    align='center'
                >
                    <span className='font-weight-500'><Timer.Hours />:<Timer.Minutes/>:<Timer.Seconds/></span> - <span className='time-worked-text'>Time Worked</span>
                </Space>
            ) : (
                ({start, pause, getTime}) => (
                    <Space align='center' className='justify-content-end ticket-duration' style={{ minWidth: 220 }}>
                            <>
                                {loading ? <LoadingOutlined size={25} /> : (
                                (tech && (tech.id === ticketDetails?.Reporter_Contact?.id) && (tech.id !== ticketDetails?.Provider_Contact) || (tech && tech.id !== ticketDetails?.Provider_Contact)) ? null :
                                !!tech && ticketDetails?.Status !== 'Assigned' && ticketDetails?.Status !== 'Resolved' && ticketDetails?.Status !== 'Closed' && ticketDetails?.Status !== 'Pending' && (
                                    !timerStart ? (
                                        <Tooltip title={getTooltipTitle()}>
                                            {isWeeklyLimitReached() ? (
                                                <img
                                                    src={PlayIcon}
                                                    alt="disabled play icon"
                                                    className='resume-support-disabled-play-icon'
                                                />
                                            ) : (
                                                <PlayCircleTwoTone
                                                    className='play-icon-two-tone-icon'
                                                    twoToneColor={SECONDARY_COLOR}
                                                    onClick={async () => {
                                                        start();
                                                        setLoading(true)
                                                        
                                                        try {
                                                            await ticketRepository?.clockInTech(ticketDetails?.id)
                                                            await getAndSetTicketDetails()
                                                            await getAndSetActivities()
                                                        } catch (error) {
                                                            notification.error({
                                                                message: 'TecMe',
                                                                description: 'Resume support failed'
                                                            })
                                                        }
                                                        setLoading(false)
                                                    }}
                                                />
                                            )}
                                        </Tooltip>
                                    ) : (
                                        <img src={PauseIcon} alt="pause icon" className='cursor-pointer' onClick={async () => {
                                                pause();
                                                const time = getTime();
                                                setLoading(true)
                                                setTime(time)
    
                                                try {
                                                    const payload = { Duration: (time / 60 / 1000) }
                                                    await ticketRepository?.clockOutTech(ticketDetails?.id, payload)
                                                    await getAndSetTicketDetails()
                                                    await getAndSetActivities()
                                                } catch (error) {
                                                    notification.error({
                                                        message: 'TecMe',
                                                        description: 'Pause support failed'
                                                    })
                                                }
                                                setLoading(false)
                                            }
                                        }/>
                                    )
                                ))}
                                <Space
                                    className='ticket-timing'
                                    align='center'
                                >
                                    <span className='font-weight-500'><Timer.Hours />:<Timer.Minutes/>:<Timer.Seconds/></span> - <span className='time-worked-text'>Time Worked</span>
                                </Space>
                            </>
                    </Space>
                )
            )}
        </Timer>
    )
}

export default TicketDuration