import { Layout, Select, Tabs, Typography } from 'antd'
import React from 'react'
import { META_DESCRIPTION } from '../../../../../utils/constants'
import ROUTES from '../../../../../utils/routes'
import './css/styles.scss'
import { MetaTags } from 'react-meta-tags'
import PageHeaderWrapper from "../../../../components/page-header-breadcrumbs";
import MyDetails from './components/my-details'
import CompanyDetails from './components/company-details'
import AccountPassword from './components/account-password'
import AccountNotifications from '../account-notifications'
import useWindowSize from '../../../../../hooks/useWindowSize'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'


const { Content } = Layout
const { Title, Paragraph, Text } = Typography

const AccountSettings = () => {
    const [selectedMenu, setSelectedMenu] = useState('my-details')
    const {windowWidth} = useWindowSize()
    const tech = useSelector(state => state.tech)
    const user = useSelector(state => state.user)
    const history = useHistory()

    const params = new URLSearchParams(window.location.search)

    const accountSettingsMenu = [
        {
            name: 'My details',
            value: 'my-details',
            content: <MyDetails />
        },
        {
            name: 'Company details',
            value: 'company-details',
            content: <CompanyDetails />
        },
        {
            name: 'Password',
            value: 'password',
            content: <AccountPassword />
        },
        {
            name: 'Notifications',
            value: 'notifications',
            content: <AccountNotifications />
        },
    ]

    const handleSelectChange = (value) => {
        setSelectedMenu(value)
        history.push({pathname:ROUTES.ACCOUNT_SETTING,  search:`?tab=${value}`})
    };
    
    const getSelectedMenu = () => {
        return (accountSettingsMenu.find((item) => item.value === selectedMenu)?.content || accountSettingsMenu[0]?.content)
    }

    const handleTabChange = (activeKey) => {
        history.push({pathname:ROUTES.ACCOUNT_SETTING,  search:`?tab=${activeKey}`})
        setSelectedMenu(params.get('tab'))
    };
    

    useEffect(() => {
        window.scrollTo(0, 0)
        setSelectedMenu(accountSettingsMenu[0]?.value)
    }, [])

    useEffect(() => { 
        setSelectedMenu(params.get('tab'))
    }, [params])

    return (
        <>
            <MetaTags>
                <title>Manage Skills | TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow"/>
            </MetaTags>
            <Content className="main">
                <div className="page">
                    <PageHeaderWrapper
                        routes={[
                            {
                                breadcrumbName: 'Account Settings',
                            },
                        ]}
                    />
                    <div className='auth-container'>
                        <div className='auth-page-ctn account-settings-ctn'>
                            <div className="heading-section"> 
                                <Title level={2} style={{fontSize:30, marginBottom:8}}>Account Settings</Title>
                                <Paragraph style={{margin:0, color:"color: rgba(0, 0, 0, 0.65)"}}>Manage your account settings and preferences here.</Paragraph>
                                {
                                    windowWidth <= 991 &&
                                    <div className="select-menu-ctn">
                                        <Select
                                            defaultValue={accountSettingsMenu[0]?.value}
                                            value={selectedMenu || accountSettingsMenu[0]?.value}
                                            onChange={handleSelectChange}
                                            options={accountSettingsMenu.map((item) => ({label: item.name, value: item.value}))}
                                            className='select-menu'
                                        />
                                    </div>
                                }
                            </div>
                            <div className="account-settings-content">
                                {
                                    windowWidth > 991 ? 
                                    <Tabs defaultActiveKey={accountSettingsMenu[0]?.value} activeKey={selectedMenu  || accountSettingsMenu[0]?.value} tabPosition='left' onChange={handleTabChange}>
                                        {
                                            accountSettingsMenu.filter((ele) => tech ? ele.value !== "company-details" : ele ).map((item) => (
                                                <Tabs.TabPane tab={item.name} key={item.value}>
                                                    {item.content}
                                                </Tabs.TabPane>
                                            ))
                                        }
                                    </Tabs> : getSelectedMenu()
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </>
    )
}

export default AccountSettings