import React, { useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import { META_DESCRIPTION } from '../../../../../utils/constants'
import { Button, Card, Col, Layout, Modal, Radio, Rate, Row, Skeleton, Space, Tag, Typography } from 'antd'
import './assets/css/styles.scss'
import SafetyIcon from './assets/icons/safety-icon.svg'
import TechReviews from '../../../tech/tech-profile/components/reviews'
import ClientJobDetails from './components/client-details'
import { useHistory, useLocation } from 'react-router-dom'
import ROUTES from '../../../../../utils/routes'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { useParams } from 'react-router-dom'
import { capitalizeFirstLetter, truncateText } from '../../../../../utils/helper'
import { getSpecializationTitle } from './utils/helper'
import JobPostDetails from './components/job-post-details'
import PageHeaderWrapper from '../../../../components/page-header-breadcrumbs'
import { PaperClipOutlined } from '@ant-design/icons'


const {Content} = Layout
const {Paragraph, Title} = Typography

const JobPost = () => {
    const generalSkills = useSelector(state => state.generalSkillsGlobal)
    const tech = useSelector(state => state.tech)
    const client = useSelector(state => state.user)
    const [loadingJob, setLoadingJob] = useState(false)
    const [jobPost, setJobPost] = useState(null)
    const [attachments, setAttachments] = useState([])
    const history = useHistory()
    const location = useLocation()
    const { id } = useParams()

    const authUser = tech || client

    const fetchJobPosting = () => {
        setLoadingJob(true)
        ApiBaseHelper.get({
            url: `job-post/${id}`, 
            headers: {
                Authorization: 'Bearer ' + authUser?.token
            }
        }).then((res) => {
            console.log(res)
            setJobPost(res?.data?.data[0])
            setLoadingJob(false)
        }).catch((error) => {
            console.error(error)
            setLoadingJob(false)
        })       
    }

    const fetchAttachments = () => {
        ApiBaseHelper.get({
            url: `job-post/media/${id}`, 
            headers: {
                Authorization: 'Bearer ' + authUser?.token
            }
        }).then((res) => {
            console.log(res?.data?.data)
            setAttachments(res?.data?.data)
        }).catch((error) => {
            console.error(error)
        })       
    }

    useEffect(() => {
        fetchJobPosting()
        fetchAttachments()
        window.scrollTo(0, 0)
    }, [id])


    return (
        <>
            <MetaTags>
                <title>Job Post - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <PageHeaderWrapper
                routes={[
                    {
                        path: tech ? ROUTES.BROWSE_JOBS : ROUTES.JOB_POSTINGS,
                        breadcrumbName: tech ? 'Browse Jobs' : 'Job Postings',
                    },
                    {
                        breadcrumbName: truncateText(capitalizeFirstLetter(jobPost?.Title), 22),
                    }
                ]}
            />
            <Content className="main">
                <div className='page'>
                    {
                        loadingJob ? <LoadingSkeleton /> : 
                        <div className='auth-container job-post-ctn'>
                            <div className='job-post-header'>
                                <Title level={3}>Job Post Details</Title>
                            </div>
                            <div className="job-post-content">
                                <div className='job-post-card'>
                                    <Row gutter={80} align={"top"}>
                                        <Col xs={24} sm={24} md={24} lg={17} xl={17} className='job-post-details'>
                                            <Title level={4}>{capitalizeFirstLetter(jobPost?.Title)}</Title>
                                            <Paragraph style={{color:"#F04A22", fontWeight:500, marginBottom:30}}>{getSpecializationTitle(generalSkills, jobPost?.Category)}</Paragraph>
                                            <div className='job-post-description'>
                                                <Title level={5}>Job Overview</Title>
                                                {
                                                    jobPost?.Description?.split('\n').map((line, index) => (
                                                        <Paragraph key={index}>
                                                        {line}
                                                        </Paragraph>
                                                    ))
                                                }
                                                {
                                                    attachments?.data?.length > 0 &&
                                                    attachments?.data?.map((item, index) => {
                                                        return(
                                                            <div key={item?.id} className='mb-1'>
                                                                <Space align='center' className="download-cv">
                                                                    <PaperClipOutlined style={{color:"rgba(0, 0, 0, 0.45)", position:"relative", top:2}}/>
                                                                    <a href={item?.Body}>{item?.Title}</a>
                                                                </Space>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="job-skills">
                                                <Title level={5}>Skills and Expertise</Title>
                                                {
                                                    jobPost?.Skills_Required?.split(', ').map((item, index) => {
                                                        return (
                                                            <Tag className="custom-ant-tag" key={item} style={{marginBottom:10}}>{item}</Tag>
                                                        )   
                                                    }
                                                    )
                                                }
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                                            <div className="job-post-cta-ctn">
                                                {
                                                    tech && 
                                                    <div className='job-post-cta'>
                                                        <Button type='primary' size='large' className='submit-application' 
                                                        onClick={() => history.push({
                                                            pathname: ROUTES.SUBMIT_JOB_APPLICATION.replace(":title", jobPost.Title),
                                                            state: {jobPostId: jobPost?.id}
                                                        })}>Submit Application
                                                        </Button>
                                                    </div>
                                                }
                                                <JobPostDetails jobPost={jobPost}/>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <ClientJobDetails clientDetails={jobPost?.custom_data}/>
                                {/* <div className="tech-reviews">
                                    {
                                        tech &&
                                        <TechReviews techId={tech?.id}/>
                                    }
                                </div> */}
                                {/* <div className='similar-jobs'>
                                    <Title level={4} style={{marginBottom:24}}>Similar jobs to apply</Title>
                                    {
                                        (allJobPosts?.length > 0 && tech) &&
                                        allJobPosts?.filter(item => item?.id !== id)?.slice(0, 3)?.reverse()?.map((item, index) => {
                                            return (
                                                <Paragraph ellipsis={{rows:1}} key={item?.id}><Link to={ROUTES.JOB_POST.replace(":id", item?.id)}>{capitalizeFirstLetter(item.Title)}</Link> {capitalizeFirstLetter(item.Description)}</Paragraph>
                                            )
                                        })
                                    }
                                </div> */}
                            </div>
                        </div>
                    }
                </div> 
            </Content>
        </>
    )
}

const LoadingSkeleton = () => {
    return(
        <div className='auth-container job-post-ctn'>
        <div className='job-post-header'>
            <Skeleton.Input style={{width: 300, height: 30}} active={true} size={"default"} />
        </div>
        <div className="job-post-content">
            <div className='job-post-card'>
                <Row gutter={80} align={"top"}>
                    <Col xs={24} sm={24} md={24} lg={17} xl={17} className='job-post-details'>
                        <Skeleton active={true} paragraph={{rows: 2}} />
                        <Space className="job-skills" wrap>
                            {
                                 [0, 1, 2, 3].map((item, index) => {
                                    return (
                                        <Skeleton.Button key={index} style={{width: 200, height: 30, marginRight: 10}} active={true} size={"default"} />
                                    )   
                                }
                                )
                            }
                        </Space>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                        <div className="job-post-cta-ctn">
                            <Skeleton.Button style={{width: '100%', height: 50, marginBottom:30}} active={true} size={"default"} />
                            <div className="job-post-details" style={{padding:20}}>
                               <Skeleton active={true} paragraph={{rows: 3}} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>   
            <div className='similar-jobs'>
               <Skeleton active={true} paragraph={{rows: 3}} />
            </div>
        </div>
    </div>
    )
}

export default JobPost