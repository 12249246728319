import React, {Component, Fragment} from 'react';
import {Avatar, Button, Card, Col, DatePicker, Empty, Layout, Result, Row, Table, Tag, Typography} from 'antd'
import {connect} from "react-redux";
import ROUTES from '../../../../../utils/routes';
import {InvoiceRepository} from './repository/repository';
import {META_DESCRIPTION, SECONDARY_COLOR} from "../../../../../utils/constants";
import MetaTags from "react-meta-tags";
import PageHeaderWrapper from "../../../../components/page-header-breadcrumbs";
import {Link} from "react-router-dom";
import Moment from 'moment';
import {extendMoment} from "moment-range";
import {isDesktop, isMobile} from "react-device-detect";
import {capitalizeFirstLetter, capitalizeFirstWordOfEveryLetter, nameFormat, ucwords} from "../../../../../utils/helper";
import QuestionOutlined from "@ant-design/icons/lib/icons/QuestionOutlined";
import CustomRenderEmpty from '../../../../components/custom-render-empty';
import PageTitle from '../../../../components/page-title';

const {RangePicker} = DatePicker;
const moment = extendMoment(Moment);

const {Content,} = Layout
const {Text, Title, Paragraph} = Typography;

class InvoiceListing extends Component {
    serviceTableRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showFilters: isDesktop,
            invoices: [],
        };
        this.getInvoices = this.getInvoices.bind(this);
        this.user = this.props.user ? this.props.user : this.props.tech;
        this.invoiceRepository = new InvoiceRepository(this.props.user ? this.props.user.token : this.props.tech.token, this.props.user ? 'customer' : 'tech')
    }


    componentDidMount() {
        window.scrollTo(0, 0);
        if (!["paid", "unpaid"].includes(this.props.match.params.type)) {
            this.props.history.push(ROUTES.DASHBOARD);
            return;
        }
        this.getInvoices();

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.match.params.type != prevProps.match.params.type)
            this.getInvoices();
    }

    async getInvoices() {
        this.setState({
            loading: true,
            invoices:[]
        })
        let invoiceType = this.props.match.params.type;
        let bookingsResponse = await this.invoiceRepository.getInvoices(invoiceType);
        let bookings = bookingsResponse.data.data;

        let invoices = [];
        let invoicesIds = [];
        bookings?.map((booking, bookingIndex) => {
            booking?.items?.map((service, serviceIndex) => {
                if (service.type == 'service' && !invoicesIds.includes(booking.bookingId)) {
                    let serviceInsert = {
                        serviceId: service.scheduledServiceId,
                        title: service.issue ? service.issue.title : 'N/A',
                        user: {
                            name: service.user.name,
                            type: service.user.type,
                            id: service.user.type == 'customer' ? service.customerContact : service.techContact,
                            profilePic: service.user.profilePic,
                        },
                        userName: service.user.name != null ? service.user.name : 'N/A',
                        supportType: ucwords(service.mode.replace("_", " ")),
                        status: service.status == 'Accepted' ? 'Scheduled' : service.status,
                        scheduledDateTime: service.scheduledDateTime,
                        bookingRealStatus: booking.status,
                        bookingDisplayStatus: booking.status == 'Confirmed' ? "Paid" : "Unpaid",
                        bookingId: booking.bookingId,
                        logs: booking.logs,
                    }
                    invoices.push(serviceInsert);
                    invoicesIds.push(booking.bookingId);
                }
            })

        });
        this.setState({
            invoices,
            loading: false
        })
    }

    render() {
        const {loading, invoices} = this.state;
        const invoiceType = this.props.match.params.type;
        return (
            <Fragment>
                <MetaTags>
                    <title>{ucwords(invoiceType)} Invoices - TecMe </title>
                    <meta name="description" content={META_DESCRIPTION}/>
                    <meta name="robots" content="noindex, nofollow"/>
                </MetaTags>
                <Content className="main">
                    <div className="page">
                        <div>
                            <Row gutter={[20, 20]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <PageHeaderWrapper
                                        routes={[
                                            {
                                                path: ROUTES.MANAGE_BOOKING,
                                                breadcrumbName: `${ucwords(invoiceType)} Invoices`,
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div className="auth-container">
                                        <div className='auth-page-ctn'>
                                            <PageTitle 
                                                title={`${ucwords(invoiceType)} Invoices`}
                                            />
                                            {
                                                isMobile && invoices.length == 0 && loading == false &&
                                                <Result
                                                    style={{marginTop:100,marginBottom:100}}
                                                    title={`No ${(invoiceType)} invoices found.`}
                                                />

                                            }

                                            <Row gutter={[15, 15]}>
                                                { // mobile only
                                                    loading &&
                                                    <>
                                                        <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                                                            <Card
                                                                loading={true}
                                                            >
                                                                <Card.Meta
                                                                    avatar={<Avatar src="https://joeschmoe.io/api/v1/random"/>}
                                                                    title="Card title"
                                                                    description="This is the description"
                                                                />
                                                            </Card>
                                                        </Col>
                                                        <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                                                            <Card
                                                                loading={true}
                                                            >
                                                                <Card.Meta
                                                                    avatar={<Avatar src="https://joeschmoe.io/api/v1/random"/>}
                                                                    title="Card title"
                                                                    description="This is the description"
                                                                />
                                                            </Card>
                                                        </Col>
                                                    </>
                                                }
                                                {
                                                    invoices.length > 0 && loading == false &&
                                                    <>
                                                        <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                                                            <Row gutter={[10, 10]}>
                                                                {
                                                                    invoices.map((record, index) => {
                                                                        return <Col key={`service-${index}`} span={24}>
                                                                            <Card
                                                                                title={
                                                                                    <Row align={"middle"} justify={"space-between"}>
                                                                                        <Col>
                                                                                            <Text ellipsis={{rows: 2}}
                                                                                                  strong>#{record.bookingId.toUpperCase()}</Text>
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <Tag>{record.bookingDisplayStatus}</Tag>
                                                                                        </Col>
                                                                                    </Row>
                                                                                }
                                                                            >
                                                                                <Row align={"middle"} gutter={[20, 20]}>
                                                                                    <Col>
                                                                                        {
                                                                                            record['user']['name'] == null ?
                                                                                                ''
                                                                                                : record['user']['profilePic'] != null ?
                                                                                                <Avatar src={record['user']['profilePic']}/>
                                                                                                : <Avatar style={{
                                                                                                    color: '#f56a00',
                                                                                                    backgroundColor: '#fde3cf',
                                                                                                    fontSize: 20
                                                                                                }}>{record['user']['name'][0].toUpperCase()}</Avatar>
                                                                                        }
                                                                                    </Col>
                                                                                    {
                                                                                        this.props.user ?
                                                                                            <Col>
                                                                                                {record['user']['name'] != null ?
                                                                                                    <Button type="link" style={{color: SECONDARY_COLOR}}
                                                                                                            href={ROUTES.TECH_PROFILE_WITH_ID.replace(':id', record['user']['id'])}>{record['user']['name']}</Button>
                                                                                                    : 'N/A'}
                                                                                            </Col>
                                                                                            :
                                                                                            <Col>
                                                                                                <Text>{record['user']['name'] != null ? capitalizeFirstLetter(record['user']['name']) : 'N/A'}</Text>
                                                                                            </Col>
                                                                                    }

                                                                                    <Col xs={24} sm={24} md={12} lg={0} xl={0}>
                                                                                        <Button
                                                                                            danger
                                                                                            style={{width: '100%'}}
                                                                                        >
                                                                                            <Link to={{
                                                                                                pathname: `${ROUTES.INVOICE.replace(':id', record.bookingId)}`,
                                                                                            }}>
                                                                                                VIEW INVOICE
                                                                                            </Link>
                                                                                        </Button>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Card>
                                                                        </Col>
                                                                    })
                                                                }
                                                            </Row>

                                                        </Col>
                                                    </>
                                                }
                                                <Col xs={0} sm={0} md={0} lg={24} xl={24}>
                                                    <CustomRenderEmpty description={"No invoices found"}>
                                                    <Table
                                                        // locale={{
                                                        //     emptyText: loading? "Loading invoices..." : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{paddingTop:100,paddingBottom:100}}
                                                        //     description={<Typography.Text strong={true}>No {invoiceType} invoices found.</Typography.Text>} />
                                                        // }}
                                                        ref={this.serviceTableRef}
                                                        loading={loading}
                                                        pagination={{
                                                            onChange: (e) => {
                                                                // window.scrollTo(0, 0);
                                                                this.serviceTableRef.current.scrollIntoView()
                                                            },
                                                            showSizeChanger: true
                                                        }}
                                                        rowClassName={(record, index) => {
                                                            let rowClass = '';
                                                            // if (record.isRequest)
                                                            //     rowClass = 'service-request-row';
                                                            // else if (record.bookingDisplayStatus == 'Payment Required' && this.props.user)
                                                            //     rowClass = 'service-payment-required-row';

                                                            return rowClass;
                                                        }}
                                                        columns={[
                                                            {
                                                                title: 'Invoice #',
                                                                dataIndex: 'bookingId',
                                                                key: 'bookingId',
                                                                render: (record) => {
                                                                    return <Text>#{record.toString().toUpperCase()}</Text>
                                                                },
                                                                // defaultSortOrder: 'descend',
                                                                web: true,
                                                                mobile: false,
                                                                // sorter: {
                                                                //     compare: (a, b) => a.bookingId.localeCompare(b.bookingId),
                                                                // },
                                                            },
                                                            {
                                                                title: this.props.user ? 'Technician' : 'Customer',
                                                                dataIndex: 'user',
                                                                key: 'user',
                                                                render: (record) => {
                                                                    return <Row align={"middle"} gutter={[5]}>
                                                                        <Col>
                                                                            {
                                                                                record['name'] == null ?
                                                                                    <Avatar style={{
                                                                                        color: '#f56a00',
                                                                                        backgroundColor: '#fde3cf',
                                                                                        fontSize: 20,
                                                                                    }}
                                                                                            icon={<QuestionOutlined style={{verticalAlign: 0}}/>}
                                                                                    />
                                                                                    : record['profilePic'] != null ?
                                                                                    <Avatar src={record['profilePic']}/>
                                                                                    : <Avatar style={{
                                                                                        color: '#f56a00',
                                                                                        backgroundColor: '#fde3cf',
                                                                                        fontSize: 20
                                                                                    }}>{record['name'][0].toUpperCase()}</Avatar>
                                                                            }
                                                                        </Col>
                                                                        {
                                                                            this.props.user ?
                                                                                <Col>
                                                                                    {record['name'] != null ?
                                                                                        <Button type="link" style={{color: SECONDARY_COLOR}}
                                                                                                href={ROUTES.TECH_PROFILE_WITH_ID.replace(':id', record['id'])}>{capitalizeFirstWordOfEveryLetter(nameFormat(record['name']))}</Button>
                                                                                        : 'Searching for a tech'}
                                                                                </Col>
                                                                                :
                                                                                <Col>
                                                                                    <Text
                                                                                        style={{padding: '4px 15px'}}>{record['name'] != null ? capitalizeFirstWordOfEveryLetter(nameFormat(record['name'])) : 'N/A'}</Text>
                                                                                </Col>
                                                                        }
                                                                    </Row>
                                                                },
                                                            },
                                                            // {
                                                            //     title: 'Status',
                                                            //     key: 'bookingDisplayStatus',
                                                            //     dataIndex: 'bookingDisplayStatus',
                                                            //     render: (record) => {
                                                            //         if (record == 'Payment Required')
                                                            //             return <Badge status={'success'} text={record}/>
                                                            //         return record == 'New Request' ?
                                                            //             <Badge status={'error'} text={record}/>
                                                            //             : <Text>{record}</Text>
                                                            //     },
                                                            // },
                                                            {
                                                                title: 'Created At',
                                                                key: 'logs',
                                                                dataIndex: 'logs',
                                                                render: (record) => {
                                                                    let log = record.filter((item)=> (item.Message == 'Reviewed and sent invoice'))
                                                                    return log.length > 0 ? moment(log[0]['CreatedDate']).format('llll') : 'N/A';
                                                                },
                                                            },
                                                            invoiceType == 'paid' ?
                                                                {
                                                                    title: 'Paid At',
                                                                    key: 'logs',
                                                                    dataIndex: 'logs',
                                                                    render: (record) => {
                                                                        let log = record.filter((item)=> (item.Message == 'Paid the invoice'))
                                                                        return log.length > 0 ? moment(log[0]['CreatedDate']).format('llll') : 'N/A';
                                                                    },
                                                                } : {} ,
                                                            {
                                                                title: 'View',
                                                                key: 'action',
                                                                render: (text, record, index) => {
                                                                    return <Button type={"default"}>
                                                                        <Link to={{
                                                                            pathname: `${ROUTES.INVOICE.replace(':id', record.bookingId)}`,
                                                                        }}>
                                                                            VIEW INVOICE
                                                                        </Link>
                                                                    </Button>
                                                                },
                                                            },
                                                        ]}
                                                        dataSource={invoices}/>
                                                        </CustomRenderEmpty>
                                                </Col>

                                            </Row>


                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Content>
            </Fragment>
        )
    }

}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(InvoiceListing);
