import {DatePicker, Form, notification, Modal, Select, Space, Button, Typography} from 'antd'
import moment from 'moment';
import React, {useEffect, useState} from 'react'
import {useTimeSheet} from '../hooks/useTimesheet';
import TextArea from "antd/es/input/TextArea";
import {ContractRepository} from '../repository';
import {useSelector} from "react-redux";

const {RangePicker} = DatePicker;
const {useForm} = Form

/*
    The purpose of the "Report an Issue" pop-up is to provide customers and technicians with a user-friendly and efficient method to report any problems or discrepancies they encounter in the time entry log on a contract. By collecting essential information about the issue and allowing for additional comments, the pop-up enables relevant parties to quickly address and resolve the reported concerns. This contributes to maintaining accurate records, fostering clear communication, and ensuring a smooth and effective workflow for all parties involved.
*/

const ReportIssue = ({
                         showReportIssueModal,
                         setShowReportIssueModal,
                         contractDetails,
                         setSelectedTimeEntry,
                         selectedTimeEntry,
                     }) => {


    const user = useSelector(state => state.user)
    const tech = useSelector(state => state.tech)
    const token = user?.token || tech?.token
    const [loading, setLoading] = useState(false);

    const [form] = useForm();

    const closeModal = () => {
        setShowReportIssueModal(false)
        setSelectedTimeEntry(undefined);
        form.resetFields()
        setLoading(false)
    };

    // initiate constant with ContractRepository
    let contractRepository = new ContractRepository(token);


    const onSubmit = (values) => {
        const payload = {
            Contract: contractDetails?.id,
            Contract_Description: contractDetails?.Description,
            Reason: values.reason,
            Comments: values.comments,
            Clock_In_Date_Time:  moment(selectedTimeEntry.Clock_In_Date_Time).subtract(5,'hours').format('YYYY-MM-DD HH:mm:ss'),
            Clock_Out_Date_Time: moment(selectedTimeEntry.Clock_Out_Date_Time).subtract(5,'hours').format('YYYY-MM-DD HH:mm:ss'),
            Total_Time: selectedTimeEntry.Total_Time,
        }


        setLoading(true)
        contractRepository.reportIssue(payload).then(async (res) => {
            if (res.data) {
                Modal.success({
                    content: 'Your time entry issue has been reported. We\'ll get back to you on this.',
                });
                closeModal()
            }
        }).catch(error => {
            console.error(error)
            notification['error']({
                message: 'TecMe',
                description: "Something went wrong"
            });
            closeModal()
        })
    }

    return (
        <Modal title="Report an Issue: Time Entry Log"
               open={showReportIssueModal}
               okText={"Save"}
               onOk={() => {
                   form.submit()
               }}
               onCancel={() => {
                   closeModal()
                   form.resetFields()
               }}
               confirmLoading={loading}
        >
            <Typography.Paragraph className='disclaimer p-2'>
                Use this 'Report an Issue' pop-up to quickly and easily notify us about any discrepancies or problems
                you encounter with the time entry log on this contract.
                Your feedback is crucial in helping us maintain accurate records and promptly address any issues.
                Once submitted, our team will review the reported issue and take appropriate action to resolve it
            </Typography.Paragraph>
            <Form
                onFinish={(values) => onSubmit(values)}
                layout={'vertical'}
                form={form}
            >
                <Form.Item
                    label="Reason"
                    name="reason"
                    rules={[
                        {
                            required: true,
                            message: 'This field is required',
                        },
                    ]}
                >
                    <Select placeholder="Select any reason">
                        {

                            [
                                "Incorrect hours",
                                "Wrong date",
                                "Missing entry",
                                "Duplicate entry",
                                "Mismatched task or project",
                                "Incorrect billing rate",
                                "Inconsistent formatting",
                                "Unauthorized entry",
                                "Incorrect status",
                                "Data entry errors"
                            ].map((item) =>
                                <Select.Option value={item}>{item}</Select.Option>
                            )
                        }

                    </Select>
                </Form.Item>

                <Form.Item
                    label="Additional Details"
                    name="comments"
                    rules={[
                        {
                            required: true,
                            message: 'This field is required',
                        },
                    ]}
                >
                    <TextArea
                        placeholder="Please briefly describe the issue you encountered (e.g., incorrect hours, wrong date, missing entry, etc.)"
                        rows={5}
                        showCount
                        maxLength={500}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ReportIssue