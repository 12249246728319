import { ApiBaseHelper } from "../../../../../../utils/api-base-helper";

export class BookingRepository {
    constructor(accessToken) {
        this.accessToken = accessToken;
    }

    getBookings(user) {
        return ApiBaseHelper.get(
            {
                url: `v2/booking/?type=${user}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    getBookingDetails({bookingId,userType}) {
        return ApiBaseHelper.get(
            {
                url: `v2/booking/${bookingId}?type=${userType}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    getRequests() {
        return ApiBaseHelper.get(
            {
                url: `v2/booking/request`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

}

