import React, {Component, Fragment} from "react";
import {Badge, Button, Card, Col, Modal, notification, Row, Spin, Tabs, Typography, List, Image, Result} from 'antd';
import {ApiBaseHelper} from "../../../../../../../utils/api-base-helper";
import {connect} from "react-redux";
import OwlCarousel from "react-owl-carousel";
import {Link} from "react-router-dom";
import {ExclamationCircleOutlined, LoadingOutlined} from '@ant-design/icons';
import ROUTES from '../../../../../../../utils/routes'
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {SECONDARY_COLOR} from "../../../../../../../utils/constants";
import NoServiceFound from '../../assets/images/service ui-whitebg.png'

import {convertDeliverables} from "../../../../../../../utils/helper";
import CheckOutlined from "@ant-design/icons/lib/icons/CheckOutlined";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import NoResultsImage from "../../../../../common/private/home/assets/images/no-results.png";
import AddIcon from "@mui/icons-material/Add";
import HipaaBadge from '../../../../../../../assets/images/hipaa-compliant-badge.png';

const antIcon = <LoadingOutlined style={{fontSize: 22, color: "black"}} spin/>;
const {TabPane} = Tabs;
const {Meta} = Card;
const {Paragraph, Title, Text} = Typography;
const {confirm} = Modal;

class ServicesTabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            loading: true,
            deleteLoading: false,
            deleteIndex: null,
            data: null
        };
        this.showModal = this.showModal.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.showConfirm = this.showConfirm.bind(this);
    }

    showModal() {
        this.setState({
            isModalVisible: true
        });
    };

    handleCancel() {
        this.setState({
            isModalVisible: false
        });
    };

    showConfirm(serviceId, serviceIndex) {
        let _this = this
        let token = this.props.isAdmin ? this.props.admin.token : _this.props.tech.token;

        confirm({

            title: 'Are you sure you want to delete this service?',
            icon: <ExclamationCircleOutlined style={{color: '#F04A22'}}/>,
            footer: false,
            cancelText: "No",
            okText: "Yes",
            centered: true,

            onOk() {
                _this.setState({
                    deleteLoading: true,
                    deleteIndex: serviceIndex
                });

                let _data = _this.state.data;

                ApiBaseHelper.delete({
                    url: "services/" + serviceId,
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                }).then((response) => {
                    notification['success']({
                        message: 'TecMe',
                        description:
                            'Service deleted successfully',
                    });

                    if (_this.props.isAdmin) {
                        window.location.reload();
                        return;
                    }

                    _data.splice(serviceIndex, 1);

                    _this.setState({
                        data: _data,
                        deleteLoading: false,
                        deleteIndex: null
                    });

                }).catch((error) => {
                    console.log(error)
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    componentDidMount() {
        this.setState({
            data: this.props.data
        })
    }

    render() {
        let _this = this;

        const {schedule, techProfile, isHipaaExpert} = this.props;
        const {data} = this.state;
        if (data == undefined)
            return <div></div>;

        return (
            <Fragment>
                {
                    data.length > 0 &&
                    <Row gutter={[15, 15]}>
                        {data.map(function (service, index2) {
                            let greenCheckMark = convertDeliverables(service['Deliverables'], 'green_check_mark');
                            let redCrossMark = convertDeliverables(service['Deliverables'], 'red_cross_mark');
                            const carouselClasses = service['Images'].length == 1 ? "owl-theme owl-navcheck" : "owl-theme"
                            // if no pricing available, hide this service
                            if (service['Pricing'].length == 0)
                                return <div key={index2}></div>;

                            let pricingModel = '';
                            if (service['Pricing'][0]['Type'] == 'Recurring') {
                                if (service['Pricing'][0]['Interval_Count'] == 1)
                                    pricingModel = `/${service['Pricing'][0]['Interval']}`;
                                else if (service['Pricing'][0]['Interval_Count'] > 1)
                                    pricingModel = ` every ${service['Pricing'][0]['Interval_Count']} ${service['Pricing'][0]['Interval']}s`
                            } else {
                                if (service['Pricing'][0]['Usage_Type'] == 'Metered')
                                    pricingModel = `/hour`;
                            }
                            return <Col xs={24} sm={12} md={8} lg={6} xl={6} key={index2}>
                                <Card
                                    className="service-card"
                                    cover={
                                        <Badge.Ribbon text={service['Location'] != null ? service['Location'].replace('_', ' ').toUpperCase() : ''}
                                                      placement={"start"}
                                                      color={SECONDARY_COLOR} style={{zIndex: 100, top: 20, paddingLeft: 20}}>
                                            {
                                                service.Device_Types != null && service.Device_Types.includes('HIPAA') &&
                                                <img loading="lazy"  src={HipaaBadge} className={"service-hipaa-badge"}/>
                                            }

                                            <div className="carousel-slider">
                                                <OwlCarousel
                                                    className={carouselClasses}
                                                    margin={10}
                                                    loop
                                                    dots={false}
                                                    responsiveClass={true}
                                                    responsive={{
                                                        0: {
                                                            items: 1,
                                                            nav: true,
                                                        },
                                                        480: {
                                                            items: 1
                                                        },
                                                        768: {
                                                            items: 1
                                                        }
                                                    }}
                                                >
                                                    {service['Images'].map(function (image, index3) {
                                                        return <div className="item" key={index3}>
                                                            <img loading="lazy"  style={{height: "15em", objectFit: 'cover'}} alt="thumbnail" src={image['URL']}/>
                                                        </div>
                                                    })}
                                                    {
                                                        service['Images'].length == 0 &&
                                                        <div className="item">
                                                            <img loading="lazy"  style={{height: "15em"}} alt="thumbnail" src="/images/thubnail1.png"/>
                                                        </div>
                                                    }

                                                </OwlCarousel>
                                            </div>
                                        </Badge.Ribbon>
                                    }
                                >
                                    <Meta title={service['Service_Title']}
                                          description={
                                              <Fragment>
                                                  <Paragraph
                                                      ellipsis={{
                                                          rows: 1,
                                                          expandable: false
                                                      }}
                                                  >{service['Description']}</Paragraph>
                                              </Fragment>
                                          }
                                          style={{marginBottom: 50}}
                                    />
                                    <div className="view-detail">
                                        <Row gutter={[20, 20]} align="middle" justify="center">
                                            <Col span={24}>

                                                <div>
                                                    <strong> ${(service['Pricing'][0]['Unit_Amount'] / 100).toFixed(2)}{pricingModel}
                                                    </strong>
                                                </div>
                                                {
                                                    service['Pricing'][0]['Type'] == 'Recurring' && service['Pricing'][0]['Free_Trial_Days'] != null &&
                                                    <span className="datetime text-muted">
                                                                    {service['Pricing'][0]['Free_Trial_Days'] > 0 ?
                                                                        `${service['Pricing'][0]['Free_Trial_Days']} ${service['Pricing'][0]['Free_Trial_Days'] > 1 ? 'Days' : 'Day'} Free Trial`
                                                                        : 'No Free Trial'}
                                                                    </span>
                                                }
                                                {
                                                    service['Pricing'][0]['Type'] != 'Recurring' &&
                                                    <span className="datetime text-muted">Standard Price</span>
                                                }

                                            </Col>
                                            <Col span={24}>
                                                <Row gutter={[10, 10]}>
                                                    <Col span={12}>
                                                        <Button
                                                            className='booking-btn' style={{
                                                            borderColor: SECONDARY_COLOR,
                                                            color: SECONDARY_COLOR,
                                                            width: '100%',
                                                            height: 40,
                                                            fontSize: 16
                                                        }}
                                                        >
                                                            <Link to={
                                                                (_this.props.isAdmin) ? {
                                                                        pathname: ROUTES.ADMIN_EDIT_SERVICE,
                                                                        state: {service, techProfile, schedule}
                                                                    }
                                                                    : {pathname: ROUTES.EDIT_SERVICES, state: {service, techProfile, schedule, isHipaaExpert}}}>
                                                                Edit
                                                            </Link>
                                                        </Button>
                                                    </Col>
                                                    <Col span={12} align={"middle"}>
                                                        {

                                                            <Button style={{width: '100%'}} size="large"
                                                                    type={"primary"}
                                                                    onClick={(e) => {
                                                                        if (_this.state.deleteLoading && _this.state.deleteIndex == index2)
                                                                            return;
                                                                        _this.showConfirm(service.Id ? service.Id : service.id, index2)
                                                                    }}
                                                            >
                                                                {
                                                                    _this.state.deleteLoading && _this.state.deleteIndex == index2 ?
                                                                        <Spin indicator={<LoadingOutlined style={{fontSize: 22, color: "black"}} spin/>}/>
                                                                        : "Delete"
                                                                }
                                                            </Button>
                                                        }
                                                    </Col>

                                                </Row>
                                            </Col>

                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        })}
                    </Row>
                }
                {
                    data.length == 0 &&
                    <Result
                        icon={<Image loading="lazy"  src={NoServiceFound} preview={false} style={{maxWidth: "250px"}}/>}
                        title={"Add Your IT Services"}
                        subTitle={"Add the IT services you can do to your tech profile so customers can book them."}
                        extra={
                            <Button type={"primary"}
                                    onClick={() => {
                                        if (_this.props.isAdmin)
                                            _this.props.history.push(ROUTES.ADMIN_ADD_SERVICE)
                                        else
                                            _this.props.history.push(ROUTES.ADD_SERVICES)
                                    }}
                            >
                                <AddIcon fontSize="medium"/> Add New Service
                            </Button>
                        }
                    />
                }
            </Fragment>
        );
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(ServicesTabs);

