import { Modal, Typography } from 'antd'
import React from 'react'
import { JobTitleForm } from './job-title'
import { JobDescriptionForm } from './job-description'
import { JobCategoryForm } from './job-category'
import { JobTypeForm } from './job-type'
import { JobBudgetForm } from './job-budget'
import { JobDurationForm } from './job-duration'
import { JobSkillsForm } from './job-skills'
import { getSpecializationTitle } from '../../../common/private/job-post/utils/helper'

const { Paragraph } = Typography
const EditJobModal = ({title, subTitle, selectedSection, setSelectedSection, selectedCategoryId, setSelectedCategoryId, allAvailableSkills,  setIsModalVisible, isModalVisible, handleEditSubmit, jobPostingData, setJobPostingData, loadingGeneratedSkills, generatedSkills, getGeneratedSkills, currentStep, updatedJobPostingData, setUpdatedJobPostingData }) => {

    const getModalContent = () => {
        switch (title) {
            case "Title":
                return {
                    content: <JobTitleForm 
                        jobPostingData={jobPostingData}
                        setJobPostingData={setJobPostingData}
                    />,
                    onClick: () => setUpdatedJobPostingData({...updatedJobPostingData, job_title: jobPostingData?.job_title}),
                    onCancel: () => setJobPostingData({...jobPostingData, job_title: updatedJobPostingData?.job_title})
                }
            case "Description":
                return {
                    content: <JobDescriptionForm 
                        jobPostingData={jobPostingData}
                        setJobPostingData={setJobPostingData}
                    />,
                    onClick: () => setUpdatedJobPostingData({...updatedJobPostingData, job_description: jobPostingData?.job_description}),
                    onCancel: () => setJobPostingData({...jobPostingData, job_description: updatedJobPostingData?.job_description})
                }
            case "Job Category":
                return {
                    content:<JobCategoryForm 
                    setSelectedCategoryId={setSelectedCategoryId} 
                    allAvailableSkills={allAvailableSkills}
                    jobPostingData={jobPostingData}
                    setJobPostingData={setJobPostingData}
                    setCategoryChanged={() => {}}
                />,
                onClick: () => {
                    setUpdatedJobPostingData({...updatedJobPostingData, job_category: jobPostingData?.job_category})
                    setSelectedSection("Skills") 
                    getGeneratedSkills()
                },
                onCancel: () => setJobPostingData({...jobPostingData, job_category: updatedJobPostingData?.job_category})
            }
            case "Skills":
                return {
                    content: 
                    <>
                        <Paragraph style={{fontWeight:500}}>Please enter skills for the {(allAvailableSkills && getSpecializationTitle(allAvailableSkills, updatedJobPostingData?.job_category))} category</Paragraph> 
                        <JobSkillsForm
                            allAvailableSkills={allAvailableSkills}
                            jobPostingData={jobPostingData}
                            setJobPostingData={setJobPostingData}
                            selectedCategoryId={selectedCategoryId}
                            generatedSkills={generatedSkills}
                            loadingGeneratedSkills={loadingGeneratedSkills}
                            getGeneratedSkills={getGeneratedSkills}
                        />
                    </>,
                    onClick: () => setUpdatedJobPostingData({...updatedJobPostingData, job_skills: jobPostingData?.job_skills}),
                    onCancel: () => setJobPostingData({...jobPostingData, job_skills: updatedJobPostingData?.job_skills})
                }
            case "Work Type":
                return {
                    content: <>
                        <Paragraph style={{fontWeight:500}}>What is your preferred work type?</Paragraph> 
                        <JobTypeForm
                            jobPostingData={jobPostingData}
                            setJobPostingData={setJobPostingData}
                        />
                    </>,
                    onClick: () => setUpdatedJobPostingData({...updatedJobPostingData, job_type: jobPostingData?.job_type}),
                    onCancel: () => setJobPostingData({...jobPostingData, job_type: updatedJobPostingData?.job_type})
                }
            case "Project Budget":
                case "Project Budget":
                    return { 
                        content: <>
                            <Paragraph style={{fontWeight:500}}>Set your budget</Paragraph>
                            <JobBudgetForm
                                jobPostingData={jobPostingData}
                                setJobPostingData={setJobPostingData}
                                currentStep={currentStep}
                            />
                        </>,
                        onClick: () => setUpdatedJobPostingData({...updatedJobPostingData, job_budget_from: jobPostingData?.job_budget_from, job_budget_to: jobPostingData?.job_budget_to, job_budget_fixed: jobPostingData?.job_budget_fixed, job_payment_type: jobPostingData?.job_payment_type}),
                        onCancel: () => setJobPostingData({...jobPostingData, job_budget_from: updatedJobPostingData?.job_budget_from, job_budget_to: updatedJobPostingData?.job_budget_to, job_budget_fixed: updatedJobPostingData?.job_budget_fixed, job_payment_type: updatedJobPostingData?.job_payment_type})
                    }
            case "Project Duration":
                return{ 
                    content: <>
                             <Paragraph style={{fontWeight:500}}>Set project duration</Paragraph>
                             <JobDurationForm
                                 jobPostingData={jobPostingData}
                                 setJobPostingData={setJobPostingData}
                             />
                         </>,
                    onClick: () => setUpdatedJobPostingData({...updatedJobPostingData, job_duration: jobPostingData?.job_duration}),
                    onCancel: () => setJobPostingData({...jobPostingData, job_duration: updatedJobPostingData?.job_duration})
                 }
            default:
                return null 
        }
    }

    return (
        <Modal
            title={`Edit ${title}`}
            visible={!!title}
            onOk={() => {
                setSelectedSection("")
                getModalContent()?.onClick()
            }}
            onCancel={() => {
                setSelectedSection("")
                getModalContent()?.onCancel()
            }}
            okText={"Save"}
            width={(title === "Title" || title === "Job Category") ? 572 : 801}
            cancelButtonProps={{type:'secondary'}}
        >
            <Paragraph>{subTitle}</Paragraph>
            {getModalContent()?.content}
        </Modal>
    )
}

export default EditJobModal