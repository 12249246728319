import {getDatabase, ref, set, get, child, onValue, update} from "firebase/database";
import {initializeApp} from "firebase/app";
import {getAuth, signInWithEmailAndPassword, signOut} from "firebase/auth";
import {getMessaging, getToken, onMessage, isSupported} from "firebase/messaging";
import {
    getFirestore,
    query,
    orderBy,
    onSnapshot,
    collection,
    getDoc,
    getDocs,
    addDoc,
    updateDoc,
    doc,
    where,
    serverTimestamp,
    collectionGroup,
    setDoc,
    deleteDoc,
    arrayUnion,
    writeBatch
} from "firebase/firestore";
import {off} from "@firebase/database";
import moment from "moment";
import {ACCEPTANCE_SERVER, DEFAULT_SERVER, DEV_SERVER, LOCAL_SERVER, STAGING_SERVER} from "./constants";
import {BASE_URL} from "./api-base-helper";

export const getFirebaseConfig = () => {
    const DEV_FIREBASE_CONFIG = {
        projectId: "tecme-mobile-app",
        appId: "1:105412557412:web:5dd7b922483c32e805fd71",
        storageBucket: "gs://tecme-mobile-app.appspot.com",
        messagingSenderId: "105412557412",
        apiKey: "AIzaSyAzue8zygkyA6CF2SL1kuYq4tGt5bMOZB0",
        authDomain: "tecme-mobile-app.firebaseapp.com",
        databaseURL: "https://tecme-mobile-app.firebaseio.com/",
        vapidKey: "BA0QDd0AjjGQ_mmBFCM7IhcDGpAiqfcK6zwfICSjHLaaFTsq2yMQUWFCXkWUSUeBKepcI4dSHgfz3-vsf4pGtf0"
    };

    const PROD_FIREBASE_CONFIG = {
        projectId: "tecme-mobile-prod",
        appId: "1:699315926937:web:f9694d97cb897e43c80508",
        storageBucket: "gs://tecme-mobile-prod.appspot.com",
        messagingSenderId: "699315926937",
        apiKey: "AIzaSyCHQhJc8TYj75nrQd48ZSFCBsKkn3JC-zo",
        authDomain: "tecme-mobile-prod.firebaseapp.com",
        databaseURL: "https://tecme-mobile-prod-default-rtdb.firebaseio.com",
        measurementId: "G-GYZ6WQZR6J",
        vapidKey: "BOz9CWhviZ5LgmRte685q83Z_ekpLSLIlhGz-9zBi_p-krZdWT_Etx-HjVe5KNsX5KwUoJijfYQKrYAQYV8zwfM"
    };

    return PROD_FIREBASE_CONFIG;
    // return DEV_FIREBASE_CONFIG;
}

const FIREBASE_CONFIG = getFirebaseConfig();
const firebaseApp = initializeApp(FIREBASE_CONFIG);
const firebaseDatabase = getDatabase(firebaseApp);
const dbRef = ref(firebaseDatabase);

const FirestoreDatabase = getFirestore(firebaseApp);
const auth = getAuth();

export class FirebaseHelper {
    static async get({path}) {
        return get(child(dbRef, path));
    }

    static async login() {
        let email = process.env.REACT_APP_FIREBASE_ADMIN_EMAIL ? process.env.REACT_APP_FIREBASE_ADMIN_EMAIL : "info@tecme.io";
        let password = process.env.REACT_APP_FIREBASE_ADMIN_PASSWORD ? process.env.REACT_APP_FIREBASE_ADMIN_PASSWORD : "^X9k1S@IeYoQ";
        if ([STAGING_SERVER, DEV_SERVER, ACCEPTANCE_SERVER, LOCAL_SERVER].includes(BASE_URL)) {
            email = "info@tecme.io";
            password = "^X9k1S@IeYoQ";
        }
        await signInWithEmailAndPassword(auth, email, password);
    }

    static async logout() {
        signOut(auth);
    }

    static async event({path, callback}) {
        const data = ref(firebaseDatabase, path);
        return onValue(data, callback);
    }

    static async set({path, data}) {
        return set(ref(firebaseDatabase, path), data);
    }

    static async update({updates}) {
        return update(ref(firebaseDatabase), updates);
    }

    static async delete({updates}) {
        return update(ref(firebaseDatabase), updates);
    }

    static async detachAllEventListeners({path}) {
        off(ref(firebaseDatabase, path));
    }

    static async getFcmToken() {
        const hasFirebaseMessagingSupport = await isSupported();

        if (hasFirebaseMessagingSupport) {
            const messaging = getMessaging(firebaseApp);
            try {
                return await getToken(messaging, {
                    vapidKey: FIREBASE_CONFIG['vapidKey'],
                });
            } catch (e) {
                return null
            }
        }

    }

    static async handleNotification({onReceive}) {
        const hasFirebaseMessagingSupport = await isSupported();
        if (hasFirebaseMessagingSupport) {
            const messaging = getMessaging(firebaseApp);
            onMessage(messaging, (payload) => {
                onReceive(payload);
            });
        }

    }
}

export class FirestoreHelper {
    static getConversations({user_id, snapshot, error}) {
        const q = query(collection(FirestoreDatabase, "/conversations"),
            where("participants", "array-contains", user_id),
            where("booking_id", "==", null),
            orderBy("recent_message.sent_at", "desc"));
        return onSnapshot(q, snapshot, error);
        /*
        Example:
        FirestoreChatHelper.getConversations({
            user_id: {{USER_ID_HERE}},
            snapshot: (querySnapshot)=>{
                querySnapshot.forEach(async (doc) => {
                    let item = doc.data();
                });
            }
        });
         */
    }

   
    static async getConversationBetweenUsers({user_1, user_2}) {
        const conversationsRef = collection(FirestoreDatabase, "/conversations");

        // Query for documents containing "value1" in the "participants" array
        const queryValue1 = query(conversationsRef, where("participants", "array-contains", user_1), where("group", "==", null), where("booking_id", "==", null));
        const querySnapshot1 = await getDocs(queryValue1);
        const resultDocs1 = querySnapshot1.docs.map(doc => ({id: doc.id, data: doc.data()}));

        // Query for documents containing "value2" in the "participants" array
        const queryValue2 = query(conversationsRef, where("participants", "array-contains", user_2), where("group", "==", null), where("booking_id", "==", null));
        const querySnapshot2 = await getDocs(queryValue2);
        const resultDocs2 = querySnapshot2.docs.map(doc => ({id: doc.id, data: doc.data()}));

        // Merge the results, keeping only documents that appear in both queries
        const mergedResults = resultDocs1.filter(doc1 =>
            resultDocs2.some(doc2 => doc1.id === doc2.id)
        );

        // filter conversations which has only 2 participants
        const filteredResults = mergedResults.filter(doc => doc.data.participants.length === 2);

        // console.log({mergedResults,filteredResults})

        return filteredResults;
    }

    static async getAllGroupConversations() {
        // const q = query(collectionGroup(FirestoreDatabase, 'conversations'),
        //     where("booking_id", "==", null),
        //     orderBy("recent_message.sent_at", "desc")
        // );
        const q = query(collectionGroup(FirestoreDatabase, 'conversations'));
        return await getDocs(q);
    }

    static async getConversationById(conversationId) {
        const docRef = doc(FirestoreDatabase, `conversations/${conversationId}`);
        return await getDoc(docRef);
    }

    static getMessages({conversation_id, snapshot, error}) {
        const q = query(collection(FirestoreDatabase, `/conversations/${conversation_id}/messages`), orderBy("sent_at", "desc"));
        return onSnapshot(q, snapshot, error);
        /*
        Example:
        FirestoreChatHelper.getMessages({
            conversation_id: {{CONVERSATION_ID_HERE}},
            snapshot: (querySnapshot)=>{
                querySnapshot.forEach(async (doc) => {
                    let item = doc.data();
                });
            }
        });
         */
    }

    static async sendMessage({conversation_id, user_id, message, mediaURL, type = 'image', file_name = ''}) {
        const colRef = collection(FirestoreDatabase, `/conversations/${conversation_id}/messages`);
        let messagePayload = {
            sent_at: serverTimestamp(),
            message,
            sent_by: user_id
        }
        if (mediaURL) {
            messagePayload['media'] = [
                {
                    type,
                    url: mediaURL,
                    file_name
                }
            ]
        }
        await addDoc(colRef, messagePayload);

        const conversationRef = doc(FirestoreDatabase, `/conversations/${conversation_id}`)

        return updateDoc(conversationRef, {
            recent_message: {
                message,
                sent_at: serverTimestamp(),
                sent_by: user_id
            }
        });
    }

    static async createConversation({
                                        conversation_id,
                                        message,
                                        participants,
                                        group = null,
                                        user_id,
                                        booking_id = null
                                    }) {
        return await setDoc(doc(FirestoreDatabase, "conversations", conversation_id), {
            created_at: serverTimestamp(),
            booking_id,
            group,
            is_read: false,
            participants,
            marked_favorite: [],
            archived: [],
            recent_message: {
                message,
                sent_at: serverTimestamp(),
                sent_by: user_id
            }
        });
    }

    static async removeConvesation(conversationId) {
        const docRef = doc(FirestoreDatabase, "conversations", conversationId);
        return await deleteDoc(docRef);
    }

    static async updateGroupConversationMembers({ conversation_id, participants, group = null, owners=[] }) {
        const conversationRef = doc(FirestoreDatabase, `/conversations/${conversation_id}`)
        let payload = {
            participants
        };
        if (group == 'empty') {
            payload['group'] = null;
        }
        else if (group) {
            payload['group'] = group;
        }
        if (owners.length > 0) {
            payload['owners'] = owners;
        }
        return await updateDoc(conversationRef, payload);
    }

    static async updateGroupTitle({ conversation_id, group}) {
        const conversationRef = doc(FirestoreDatabase, `/conversations/${conversation_id}`)
        let payload = {
            group
        };
      
        return await updateDoc(conversationRef, payload);
    }


    static async createSetNewPasswordRecord({email, org_id, hipaa_id}) {
        try {
            return await setDoc(doc(FirestoreDatabase, "set-password", hipaa_id), {
                email,
                org_id,
                hipaa_id
            });
        } catch (error) {
        }
    }

    static async markFavorite({conversation_id, marked_favorite}) {
        const conversationRef = doc(FirestoreDatabase, `/conversations/${conversation_id}`)
        return updateDoc(conversationRef, {
            marked_favorite
        });
    }

    static async markArchived({conversation_id, archived}) {
        const conversationRef = doc(FirestoreDatabase, `/conversations/${conversation_id}`)
        return updateDoc(conversationRef, {
            archived
        });
    }

    static async createUser({hipaa_id, display_name, profile_picture,role}) {
        return await setDoc(doc(FirestoreDatabase, "users", hipaa_id), {
            hipaa_id,
            display_name,
            profile_picture,
            role: role ? role.toLowerCase() : 'customer'
        });
    }

    static async bulkUpdateUsers(users) {

        const batch = writeBatch(FirestoreDatabase);
        const usersCollection = collection(FirestoreDatabase, 'users');

        console.log("users",users)
        users.forEach(user => {
            const userDocRef = doc(usersCollection, user.hipaa_id);
            batch.set(userDocRef, user);
        });
    
        try {
            await batch.commit();
            console.log('Batch write completed successfully');
        } catch (error) {
            console.error('Error writing batch:', error);
        }
    }

    static async saveEmailVerificationCode({user_id, code, verified = false}) {
        return await setDoc(doc(FirestoreDatabase, "verification", user_id), {
            sent_at: serverTimestamp(),
            code,
            verified
        });
    }

    static async getVerificationObject(user_id) {
        const docRef = doc(FirestoreDatabase, "verification", user_id);
        return await getDoc(docRef);

        /*
        Example:
        let user = await FirestoreChatHelper.getUser({{USER_ID_HERE}});
        user.data();
         */
    }

    static async getUser(user_id) {
        const docRef = doc(FirestoreDatabase, "users", user_id);
        return await getDoc(docRef);

        /*
        Example:
        let user = await FirestoreChatHelper.getUser({{USER_ID_HERE}});
        user.data();
         */
    }

    static async getSetPassworRecord(hipaa_id) {
        const docRef = doc(FirestoreDatabase, "set-password", hipaa_id);
        return await getDoc(docRef);
    }

    static async removeSetPassworRecord(hipaa_id) {
        const docRef = doc(FirestoreDatabase, "set-password", hipaa_id);
        return await deleteDoc(docRef);
    }

    static async getUsers(collection_of_user_ids) {
        const q = query(collectionGroup(FirestoreDatabase, 'users'), where("hipaa_id", "in", collection_of_user_ids));
        return await getDocs(q);
        /*
        Example:
        let users = await FirestoreChatHelper.getUsers({{collection_of_user_ids}});
        users.forEach((doc) => {
            let item = doc.data();
        });
         */
    }
}



