import { UploadOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Form, Input, Space, Typography, Upload, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import useWindowSize from '../../../../../hooks/useWindowSize'
import { useDispatch, useSelector } from 'react-redux'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { getBase64 } from '../../../../../utils/helper'
import { updateOrganizations, updateUser } from '../../../../../redux/reducers/app'
import { useHistory } from 'react-router-dom'
import ROUTES from '../../../../../utils/routes'

const {Title, Paragraph} = Typography
const { useForm } = Form


const CompanyDetails = ({handleNextClick, setProgressIndex, companyDetails, setCompanyDetails, user}) => {
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState(null)
    const [profileUpdateMessage, setProfileUpdateMessage] = useState("")
    const [logoUrl, setLogoUrl] = useState(companyDetails?.logoUrl);
    const [form] = useForm();
    const dispatch = useDispatch()
    const history = useHistory()

    const {windowWidth} = useWindowSize()
    const websiteAddon = "https://"
    const websiteUrlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/;

    const props = {
        accept: 'image/*',
        multiple: false,
        showUploadList: false,
        beforeUpload: (file) => {
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                setProfileUpdateMessage('Image must smaller than 5MB!');
            } else {
                getBase64(file, imageUrl =>{
                    setLogoUrl(imageUrl)
                    setCompanyDetails({
                        ...companyDetails,
                        logoUrl: imageUrl
                    })
                });
                setFile(file)
                console.log(file)
                setProfileUpdateMessage(file.name)
            }
            return false;
        },
        onDrop(e) {
           setFile(e.dataTransfer.files[0]);
        },
        onRemove: (file) => {
            setFile(null);
            return true;
        }
    };

    const handleCompanyDetailsSubmit = async (values) => {
        setLoading(true)

        const data = new FormData();

        data.append("Name", values.companyName === null ? "" : values.companyName)
        data.append("Website", values.companyWebsite === null ? "" : `${websiteAddon}${values.companyWebsite.replace(websiteAddon, "").replace("www.", "").replace("http://", "")}`)
        data.append("Description", values.companyDescription === null ? "" : values.companyDescription)
        data.append('file', file)
    
       

        ApiBaseHelper.put({
            url: `user-management/organization/${user?.orgId}`,	
            headers: {
                Authorization: 'Bearer ' + user['token'],
            },
            payload: data
        }).then(async (res) => {
            console.log(res)
            if(res){
                dispatch(updateUser({
                    ...user,
                    new_signup: false,
                    organization:{
                        ...user.organization,
                        Name: res?.data?.data?.Name,
                        Website: res?.data?.data?.Website,
                        Description: res?.data?.data?.Description,
                    }
                }))
                form.setFieldsValue({
                    companyName: res?.data?.data?.Name,
                })

                ApiBaseHelper.get({
                    url: 'user-management/organizations',
                    headers: {
                        Authorization: 'Bearer ' + user?.token,
                    },
                }).then((res) => {
                    console.log(res)
                    dispatch(updateOrganizations(res?.data?.data))
                })
            }
        }).then(() => {
            setLoading(false)
            
        }).catch((e) => {
            console.log(e)
            setLoading(false)
            notification.error({
                message: 'TecMe',
                description: 'Company Details Update Failed',
            });
        })

        handleNextClick()
        setProgressIndex(0)
        setCompanyDetails({
            ...companyDetails,
            ...values
        })
    }

    useEffect(() => {
        form.setFieldsValue({
            companyName: companyDetails?.companyName,
            companyWebsite: companyDetails?.companyWebsite,
            companyDescription: companyDetails?.companyDescription,
        })
        setLogoUrl(companyDetails?.logoUrl)
    }, [])

   
    if(user?.new_signup === false){
        history.push(ROUTES.DASHBOARD) 
    }

    return (
        <div className='company-details-section'>
            <div className="company-details-form-ctn">
                <Title level={4} className='form-heading'>Let’s get to know you {windowWidth > 991 && '(1/2 steps)'}</Title>
                <Form 
                    onFinish={handleCompanyDetailsSubmit}
                    form={form}
                    layout='vertical'
                    className="company-details-form">
                    <Form.Item  
                    name="companyName"  
                    label="Company Name" 
                    className='company-details-form-item'
                    rules={[{ required: true, message: 'Please input your company name' }]}
                    >
                        <Input 
                            type="text" 
                            placeholder="Enter your company name" 
                            className="company-name-input"
                        />
                    </Form.Item>  
                    <Space size={21} className='company-details-form-item'>
                        <Avatar size={64} src={logoUrl} style={{background:"#F04A22"}} icon={<UserOutlined />}>
                            {"T"}
                        </Avatar>
                        <div>
                            <Paragraph style={{color: "rgba(0, 0, 0, 0.85)", fontWeight:500}}>Logo</Paragraph>
                            <Space size={16}>
                                <Upload {...props}>
                                    <Button style={{height:40}} type='secondary' icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                                <Button  style={{height:40}} type='primary' onClick={() => { 
                                    setLogoUrl(null)
                                    setCompanyDetails({
                                        ...companyDetails,
                                        logoUrl: null
                                    })
                                    }} 
                                    disabled={logoUrl === null || logoUrl === undefined}>
                                        Remove
                                </Button>
                            </Space>
                        </div>
                    </Space>
                    <Form.Item  name="companyDescription"  label="Description" className='company-details-form-item'
                    rules={[{ required: true, message: 'Please input your company description' }, 
                    { min: 25, message: 'Minimum of 25 characters allowed'},
                    { max: 300, message: 'Maximum of 300 characters allowed.' }]}
                    >
                        <Input.TextArea   
                            showCount
                            placeholder='Type here briefly what your company does'
                            maxLength={300}
                            className='company-details-form-textarea'
                        />
                    </Form.Item> 
                    <Form.Item name="companyWebsite"  label="Website" className='company-details-form-item'
                    rules={[
                        { 
                            required: true, 
                            message: 'Please input your company website' 
                        },
                        {
                            pattern: websiteUrlRegex,
                            message: 'Invalid website URL',
                        },
                    ]}
                    >
                        <Input 
                            type="text" 
                            placeholder="Enter your company website (e.g www.tecme.io)" 
                            className="company-name-input"
                        />
                    </Form.Item> 
                    <Form.Item  className='company-details-form-item'>
                        <Button className='onboarding-next-btn' type='primary' htmlType='submit' loading={loading}>Next</Button>
                    </Form.Item> 
                </Form>
            </div>
        </div>
    )
}

export default CompanyDetails