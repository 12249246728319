import React from 'react'
import { Typography } from 'antd'
import { useState } from 'react'
import Linkify from 'react-linkify';
import { capitalizeFirstLetter } from '../../../../../../utils/helper'


const { Title, Paragraph } = Typography

const ItcFaq = ({ brandColor, itcContent }) => {
    const [openAccordion, setOpenAccordion] = useState(null)

    const handleAccordion = (index) => {
        if(openAccordion === index){
            setOpenAccordion(null)
        }else{
            setOpenAccordion(index)
        }
    }

    if(itcContent["FAQ"]?.length < 1) return null

    return (
        <section className='itc-faq-section' id="faqs">
            <div className="itc-faq-ctn">
                <div className="faq-title">
                    <div className="itc-top-border" style={{background:brandColor}}></div>
                    <Title level={1} className="itc-faq-heading">FAQ</Title>
                    <Paragraph className="itc-faq-subtitle">Have a question? Check out our FAQ section for answers to common inquiries.</Paragraph>
                </div>
                <div className="faq-content">
                    {
                        itcContent["FAQ"]?.map((item, index) => {
                            return(
                                <div key={item.id} className="itc-accordion">
                                    <div className="itc-accordion-header" onClick={() => handleAccordion(index)}>
                                        <div className='itc-accordion-num' style={{backgroundColor:brandColor}}>
                                            {index+1}
                                        </div>
                                        <p>{capitalizeFirstLetter(item.Question)}</p>
                                    </div>
                                    {
                                        openAccordion === index &&
                                        <div className="itc-accordion-body">
                                            <Linkify>
                                                <p>{capitalizeFirstLetter(item.Answer)}</p>
                                            </Linkify>
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default ItcFaq