import SecurityIcon from "../views/pages/common/public/it-industry/assets/icons/security.png";
import CostIcon from "../views/pages/common/public/it-industry/assets/icons/cost.png";
import EhrIcon from "../views/pages/common/public/it-industry/assets/icons/ehr.png";
import SettingIcon from "../views/pages/common/public/it-industry/assets/icons/setting.png";
import FranchiseIcon1 from "../views/pages/common/public/it-industry/assets/icons/franchises-icon1.svg";
import FranchiseIcon2 from "../views/pages/common/public/it-industry/assets/icons/franchises-icon2.svg";
import FranchiseIcon3 from "../views/pages/common/public/it-industry/assets/icons/franchises-icon3.svg";
import FranchiseIcon4 from "../views/pages/common/public/it-industry/assets/icons/franchises-icon4.svg";
import ProviderIcon1 from "../views/pages/common/public/it-industry/assets/icons/provider-icon1.svg";
import ProviderIcon2 from "../views/pages/common/public/it-industry/assets/icons/provider-icon2.svg";
import ProviderIcon3 from "../views/pages/common/public/it-industry/assets/icons/provider-icon3.svg";
import ProviderIcon4 from "../views/pages/common/public/it-industry/assets/icons/provider-icon4.svg";
import InhouseIcon1 from "../views/pages/common/public/it-industry/assets/icons/inhouse-icon1.svg";
import InhouseIcon2 from "../views/pages/common/public/it-industry/assets/icons/inhouse-icon2.svg";
import InhouseIcon3 from "../views/pages/common/public/it-industry/assets/icons/inhouse-icon3.svg";
import InhouseIcon4 from "../views/pages/common/public/it-industry/assets/icons/inhouse-icon4.svg";
import OutsourcingIcon1 from "../views/pages/common/public/it-industry/assets/icons/outsourcing-icon1.svg";
import OutsourcingIcon2 from "../views/pages/common/public/it-industry/assets/icons/outsourcing-icon2.svg";
import OutsourcingIcon3 from "../views/pages/common/public/it-industry/assets/icons/outsourcing-icon3.svg";
import OutsourcingIcon4 from "../views/pages/common/public/it-industry/assets/icons/outsourcing-icon4.svg";
import HealthcareHero from "../views/pages/common/public/it-industry/assets/images/healthcare-hero.png"
import FranchiseHero from "../views/pages/common/public/it-industry/assets/images/franchise-hero.png"
import NoItProviderHero from "../views/pages/common/public/it-industry/assets/images/no-it-provider-hero.png"
import InHouseItHero from "../views/pages/common/public/it-industry/assets/images/in-house-it-hero.png"
import OutSourcingItHero from "../views/pages/common/public/it-industry/assets/images/out-sourcing-it-hero.png"

export default [
    // industry pages
    {
        'route': 'healthcare',
        'hero-section': {
            'heading': 'Leading the Way in',
            'span':'Healthcare IT',
            'sub-heading': "Comprehensive IT management solutions that meet the specific requirements of the healthcare industry. From HIPAA compliance to secure data management, we've got you covered.",
            'image': HealthcareHero
        },
        'it-solutions':{
            'heading':'IT solutions for improved healthcare operations and patient care',
            'sub-heading':'The healthcare industry is facing a number of challenges when it comes to IT, from ensuring patient data is secure and compliant with regulations, to keeping up with the latest technology and trends. At TecMe, we understand these challenges and have developed a range of IT solutions and expertise that can help healthcare providers address them.',
            'solutions-points':[
                {
                    'icon':SecurityIcon,
                    'heading':'Compliance and Security',
                    'content':'Our managed compliance can help you maintain compliance with HIPAA. We offer comprehensive compliance solutions, including regular security risk assessments and compliance audits, to ensure that you remain compliant and avoid costly penalties.'
                },
                {
                    'icon':CostIcon,
                    'heading':'Cost Savings',
                    'content':'By working with TecMe IT specialist marketplace, healthcare providers can access a wide range of IT solutions and specialists at a fraction of the cost of building and maintaining an in-house IT team.'
                },
                {
                    'icon':EhrIcon,
                    'heading':'EHR and Telemedicine',
                    'content':'TecMe can help healthcare providers implement and optimize electronic health record systems and telemedicine solutions, making it easier for patients to access care and for providers to manage patient data.'
                },
                {
                    'icon':SettingIcon,
                    'heading':'Increased Operational Efficiency',
                    'content':'TecMe can help healthcare providers streamline their IT operations, from end-user support to cybersecurity strategy, so they can focus on providing high-quality care to patients.'
                },
            ]
        },
        'comprehensive-solutions':'Focus on what you do best: our managed solutions provide hassle-free IT management, so you can focus on providing high-quality patient care',
        'expertise':{
            'paragraph1':'We are proud to have served a variety of healthcare organizations, from medical centers to nursing homes and clinics. Our clients have seen improved security, efficiency, and patient care as a result of our managed IT services.',
            'paragraph2':'Our team of IT professionals includes experts in healthcare IT, with years of experience working with healthcare organizations. We stay up-to-date best practices to ensure that we provide the most effective and reliable solutions for our clients.'
        },
        'meta-data':{
            'meta-title': "Comprehensive Healthcare IT Management Solutions | TecMe",
            'meta-description': "TecMe offers a range of comprehensive IT management solutions that cater to the specific needs of the healthcare industry. From compliance and security to EHR and telemedicine, our expert team can help healthcare providers streamline their IT operations, increase operational efficiency, and focus on providing high-quality care to patients.",
            'keywords': "Healthcare IT, IT Solutions for Healthcare, Data Management, Healthcare IT Security"
        }
    },
    {
        'route': 'franchises',
        'hero-section': {
            'heading': 'Helping franchises grow with',
            'span':'scalable IT',
            'sub-heading': "Discover flexible IT management solutions designed to cater to your entire franchise's needs, ensuring that each franchisee receives customized services that perfectly align with their unique requirements. ",
            'image': FranchiseHero
        },
        'it-solutions':{
            'heading':'Overcoming IT Challenges for Franchise Operations with TecMe',
            'sub-heading':'Franchise operations have unique IT challenges due to their distributed nature and the need to maintain a consistent brand identity and streamlined processes. With franchisees operating in different regions, it can be difficult to ensure uniformity in IT infrastructure, security, and support. In addition, franchise operations must comply with industry-specific regulations such as PCI DSS for retail or HIPAA for healthcare franchises.',
            'solutions-points':[
                {
                    'icon':FranchiseIcon1,
                    'heading':'Consistent Branding and Operations',
                    'content':'We can help you maintain consistency in branding and operations across all franchise locations. Our solutions ensure that each franchisee is using the same IT infrastructure and operating under the same standards, so your brand can stay strong and consistent.'
                },
                {
                    'icon':FranchiseIcon2,
                    'heading':'Scale Your IT Infrastructure',
                    'content':"We understand that as your franchise expands, so do your IT needs too. By documenting the franchise's tech stack and scaling it to all technicians working with the franchisees, we ensure that every franchise location has access to the same top-quality IT solutions and expertise."
                },
                {
                    'icon':FranchiseIcon3,
                    'heading':'Uniform Cybersecurity and Compliance',
                    'content':'Maintain uniform cybersecurity and compliance across all franchise locations. Our comprehensive cybersecurity and compliance solutions help protect your franchise from cyber threats, and ensure that you meet industry-specific regulations across the board.'
                },
                {
                    'icon':FranchiseIcon4,
                    'heading':'Streamlined IT Management and Support',
                    'content':'We provide 24/7 remote monitoring and support, which minimizes downtime and quickly resolves IT issues. Our centralized IT management and support also reduce the need for franchisees to spend time and resources on managing their own IT, allowing them to focus on running their business and serving customers.'
                },
            ]
        },
        'comprehensive-solutions':'Customizable managed IT services designed to meet the unique requirements of the franchise industry.',
        'expertise':{
            'paragraph1':'At TecMe, we have a proven track record of success in the franchise business. Our team has implemented customized IT solutions across all areas of the business that help them succeed and grow.',
            'paragraph2':'Whether you need help with IT infrastructure, security, compliance, or support, we have the industry expertise to provide effective solutions that can help your franchise succeed.'
        },
        'meta-data':{
            'meta-title': "Overcoming IT Challenges for Franchise Operations | TecMe",
            'meta-description': "Discover flexible IT management solutions designed to cater to your entire franchise's needs with TecMe. From maintaining consistent branding and operations to scaling your IT infrastructure and ensuring uniform cybersecurity and compliance, TecMe provides customizable managed IT services that meet the unique requirements of the franchise industry. Contact us today to learn how we can help your business succeed.",
            'keywords': "Franchise IT Solutions, IT Solutions for Franchises, Business Process Improvement, Business Growth"
        }
    },
    // situation pages
    {
        'route': 'customizable-it-management',
        'hero-section': {
            'heading': 'Focus on your business and leave',
            'span':'IT to us',
            'sub-heading': "We understand that every business has its own set of challenges, and we will work closely with you to understand your business processes and design a solution that works for you and your budget.",
            'image': NoItProviderHero
        },
        'it-solutions':{
            'heading':'Overcoming the Challenges of Running a Business without an IT Provider',
            'sub-heading':'Running a business without an IT provider can be a daunting task. From dealing with computer glitches to ensuring the security of sensitive information, there are a myriad of challenges that arise. Without proper expertise and support, these challenges can easily become overwhelming and hinder the success of your business. Partnering with an IT provider can help ensure the smooth operation of your business and give you peace of mind.',
            'solutions-points':[
                {
                    'icon':ProviderIcon1,
                    'heading':'Access to Expertise',
                    'content':'Gain access to a team of experienced IT professionals who have the technical expertise needed to manage complex IT infrastructure. This ensures that your business has the knowledge and support they need to avoid costly errors, security breaches, and downtime.'
                },
                {
                    'icon':ProviderIcon2,
                    'heading':'Streamlined Operations',
                    'content':'Optimize your business operations, increasing efficiency and profitability. By outsourcing IT management, you can focus on your core competencies, while leaving the complexities of IT infrastructure to the experts.'
                },
                {
                    'icon':ProviderIcon3,
                    'heading':'Enhanced Security',
                    'content':'Implement robust security measures to protect your business from cyber threats, data breaches, and other security risks. By working with TecMe, businesses can ensure that their IT infrastructure is secure and compliant with the latest regulations.'
                },
                {
                    'icon':ProviderIcon4,
                    'heading':'Tailored and scalable IT management',
                    'content':'We are designed to be customizable and scalable, ensuring our solutions can grow and expand as your business does. This means that as your business needs evolve, you can rely on TecMe to provide the additional IT resources and capacity you require.'
                },
            ]
        },
        'comprehensive-solutions':'Customizable managed IT services designed to meet the unique requirements of your business.',
        'expertise':{
            'paragraph1':'At TecMe, we understand the unique challenges that businesses face when it comes to managing their IT. Our team of experts has the knowledge and experience necessary to provide customized solutions that address the specific needs of your business.',
            'paragraph2':'We work with you to create a tailored IT strategy that helps your business run smoothly and efficiently. With TecMe as your IT provider, you can focus on growing your business with the peace of mind that your technology is being expertly managed.'
        },
        'meta-data':{
            'meta-title': "Customizable IT Management for Business | TecMe",
            'meta-description': "Running a business without an IT provider can be overwhelming. TecMe's customizable IT management solutions can optimize your business operations, enhance security, and provide strategic guidance to help your business succeed.",
            'keywords': "customizable IT management, business IT solutions, IT provider, business operations, enhance security, strategic guidance, small business IT, TecMe."
        }
    },
    {
        'route': 'supercharge-in-house-it',
        'hero-section': {
            'heading': 'Supercharge Your In-House',
            'span':'IT Team',
            'sub-heading': "Customized solutions tailored to your team’s requirements. From niche expertise and additional capacity to technology solutions, we can help you achieve your IT objectives.",
            'image':InHouseItHero
        },
        'it-solutions':{
            'heading':'Overcoming the Challenges of Managing an In-House IT Team in a Growing Business',
            'sub-heading':'As your business grows, so too does your need for robust IT support. But managing an in-house IT team can be challenging, especially as your business continues to expand. The demands of keeping up with the latest technology and ensuring that your systems are running smoothly can take up valuable time and resources.',
            'solutions-points':[
                {
                    'icon':InhouseIcon1,
                    'heading':'Access to Niche Expertise',
                    'content':'Our talent marketplace for IT professionals ensures that you have access to the specialized skills and experience you need to tackle any IT challenge.'
                },
                {
                    'icon':InhouseIcon2,
                    'heading':'Additional Capacity',
                    'content':'Hiring full-time employees can be costly and time-consuming. With our talent marketplace, you can quickly and efficiently scale up your IT resources to meet your needs without hiring more heads.'
                },
                {
                    'icon':InhouseIcon3,
                    'heading':'Customized Solutions',
                    'content':'Get customized solutions that are tailored to your specific requirements. Whether you need help with a one-time project or ongoing IT support, we\'ll work with you to develop a solution that fits your needs.'
                },
                {
                    'icon':InhouseIcon4,
                    'heading':'Expert Guidance',
                    'content':'Managing IT can be complex, especially as your business continues to expand. Our team of experts is here to provide you with the guidance and support you need to make informed decisions about your IT strategy.'
                },
            ]
        },
        'comprehensive-solutions':'Customizable managed IT services designed to meet the unique requirements of your business.',
        'expertise':{
            'paragraph1':'At TecMe, we understand that working with an existing in-house IT team requires a unique approach. We\'re dedicated to working with your team to ensure that you have the resources and support you need to scale and work more efficiently.',
            'paragraph2':'We work with you to create a tailored IT strategy that helps your business run smoothly and efficiently. With extra support, you can focus on growing your business with the peace of mind that your technology is being expertly managed.'
        },
        'meta-data':{
            'meta-title': "Supercharge Your In-House IT Team with Customized Solutions | TecMe",
            'meta-description': "TecMe offers customized IT solutions to help businesses overcome the challenges of managing an in-house IT team. From compliance and security to additional capacity and niche expertise, we have everything you need to succeed.",
            'keywords': "In-house IT Team, IT Solutions, IT Infrastructure, Expert IT Solutions, Supercharge IT Team"
        }
    },
    {
        'route': 'ultimate-it-partner',
        'hero-section': {
            'heading': 'TecMe: Your Ultimate',
            'span':'IT Partner',
            'sub-heading': "Our nationwide specialist marketplace and scalable IT management services are designed to meet your business's unique needs, no matter what they may be, to achieve your goals.",
            'image': OutSourcingItHero
        },
        'it-solutions':{
            'heading':'Is Your IT Management Holding You Back?',
            'sub-heading':'Is your business facing issues with slow response times, lack of communication, or consistently failing to resolve IT issues? Perhaps your business is experiencing frequent downtime or security concerns. If any of these situations sound familiar, it might be time to consider an IT partner that can meet the needs of your business, budget, and scale!',
            'solutions-points':[
                {
                    'icon':OutsourcingIcon1,
                    'heading':'Nationwide Talent Marketplace',
                    'content':'Access a vast pool of IT professionals from all over the country. This means you get access to the top expertise for your projects and support. With our nationwide talent pool, we can always ensure that you have the expertise you need to achieve your business goals.'
                },
                {
                    'icon':OutsourcingIcon2,
                    'heading':'Seamless Integration with Existing MSPs',
                    'content':"We can work with your existing MSP for a seamless integration of IT services. Our nationwide talent marketplace and specialized IT solutions can supplement or take over certain IT functions, allowing existing MSPs to focus on their core areas of expertise."
                },
                {
                    'icon':OutsourcingIcon3,
                    'heading':'Expandable IT',
                    'content':'As your business grows and your IT needs evolve, you need an IT partner that can grow with you. Our scalable IT management services ensure that we can seamlessly scale our services to meet your needs.'
                },
                {
                    'icon':OutsourcingIcon4,
                    'heading':'Transparent and Customized Pricing',
                    'content':'With tailored solutions comes tailored pricing to meet the unique needs of your business. Our pricing is designed to be flexible and transparent, so you can choose the services that meet your specific needs.'
                },
            ]
        },
        'comprehensive-solutions':'Customizable managed IT services designed to meet the unique requirements of your business.',
        'expertise':{
            'paragraph1':'At TecMe, we\'re committed to providing our clients with the highest level of IT management. Our expert IT professionals have years of experience in delivering top-quality support to businesses of all sizes and in a wide variety of industries.',
            'paragraph2':'We work with you to create a tailored IT strategy that helps your business run smoothly and efficiently. With extra support, you can focus on growing your business with the peace of mind that your technology is being expertly managed.'
        },
        'meta-data':{
            'meta-title': "TecMe: Your Ultimate IT Partner for Scalable and Comprehensive Solutions",
            'meta-description': "TecMe: Your Ultimate IT Partner for Scalable and Comprehensive IT business. TecMe offers customizable and scalable IT management services for businesses, with access to a nationwide talent marketplace, seamless integration with existing MSPs, and transparent pricing.",
            'keywords': "IT Partner, IT Solutions, Business Growth, Customized IT Solutions, Reliable IT Solutions"
        }
    },
]