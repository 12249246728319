import React, { useState, useEffect } from 'react'
import { MetaTags } from 'react-meta-tags'
import PageHeaderWrapper from '../../../../components/page-header-breadcrumbs'
import { META_DESCRIPTION } from '../../../../../utils/constants'
import ROUTES from '../../../../../utils/routes'
import { Layout } from 'antd'
import PageFallback from '../../../../components/page-fallback'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter } from '../../../../../utils/helper'
import ProfileTechTeam from '../itc-profile/components/tech-team'

const { Content } = Layout

const ItcTechTeam = () => {
    const tech = useSelector(state => state.tech)
    const [itcDetails, setItcDetails] = useState({})
    const [loadingDetails, setLoadingDetails] = useState(false)
    const history = useHistory()
    
    const param = useParams()

    const getItcDetails = () => {
        setLoadingDetails(true)
        ApiBaseHelper.get({
            url: `itc/${param?.id}`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setItcDetails(res?.data?.data)
            setLoadingDetails(false)
            if(tech.id !== res?.data?.data?.Owner_Contact){
                history?.push(`${ROUTES.ITC_INVITES_LISTING}?tab=requests`)
                return
            }
        }).catch((error) => {
            console.error(error)
            setLoadingDetails(false)
        })       
    }


    useEffect(() => {
        getItcDetails()
    }, [])


    
    if(loadingDetails) {
        return <PageFallback />
    }

    return (
        <>
            <MetaTags>
                <title>Manage IT Services Company Profile Team - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <PageHeaderWrapper
                routes={[
                    {
                        path: ROUTES.ITC_PROFILES,  
                        breadcrumbName: 'ITSC Profiles',
                    },
                    {
                        path: ROUTES.ITC_PROFILE,
                        breadcrumbName: `${capitalizeFirstLetter(itcDetails?.Company_Name)} Team`,
                    },
                ]}
            />
            <Content className="main">
                <div className='page'>
                    <div className="auth-container">
                        <div className="auth-page-ctn itc-team-ctn mt-5">
                            <ProfileTechTeam
                                itcDetails={itcDetails}
                                itcId = {param?.id}
                            />
                        </div>
                    </div>
                </div>
            </Content>
        </>
    )
}

export default ItcTechTeam