import React, { useEffect, useState } from 'react'
import { Avatar, Button, Col, Form, Input, Row, Space, Typography, Upload, notification } from 'antd'
import { AddedItemCard, AddedItemMessage } from './added-item'
import { UploadOutlined } from '@ant-design/icons'
import { getBase64, isValidUrl, nameFormat } from '../../../../../../utils/helper'
import BackIcon from '../assets/images/back-icon.svg'
import { useParams } from 'react-router-dom'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import { useSelector } from 'react-redux'
import TestimonialList from './testimonial-list'

const { Title, Paragraph } = Typography

const CreateItcTestimonials = ({handleNextClick, setShowBackBtn, itcId}) => {
    const [showAddedTestimonial, setShowAddedTestimonial] = useState(false)
    const [loadingTestimonials, setLoadingTestimonials] = useState(false)
    const [testimonials, setTestimonials] = useState([])
    const [editTestimonialData, setEditTestimonialData] = useState(null)
    const tech = useSelector(state => state.tech)

    const fetchTestimonials = () => {
        setLoadingTestimonials(true)
        ApiBaseHelper.get({
            url: `itc/testimonial/all?itc=${itcId}`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setTestimonials(res?.data?.data)
            setLoadingTestimonials(false)
        }).catch((error) => {
            console.error(error)
            setLoadingTestimonials(false)
        })       
    }

    useEffect(() => {
       setShowBackBtn(showAddedTestimonial ? false : true)
    }, [showAddedTestimonial])

    useEffect(() => {
        if(testimonials?.length > 0){
            setShowAddedTestimonial(true)
        }   
    }, [testimonials])

    useEffect(() => {
        fetchTestimonials()
    }, [])


    return (
        <div className="create-itc-testimonial-section">
            <div className="create-itc-testimonial-ctn">
                {
                    showAddedTestimonial ? 
                    <AddedTestimonial 
                        handleNextClick={handleNextClick} 
                        setShowAddedTestimonial={setShowAddedTestimonial}
                        showAddedTestimonial={showAddedTestimonial}
                        setShowBackBtn={setShowBackBtn}
                        testimonials={testimonials}
                        setTestimonials={setTestimonials}
                        itcId={itcId}
                        fetchTestimonials={fetchTestimonials}
                        loadingTestimonials={loadingTestimonials}
                        setEditTestimonialData={setEditTestimonialData}
                    /> :
                    <div className="testimonial-form-ctn">
                        <Title level={4} className="itc-form-title">Add New Testimonial</Title>
                        <TestimonialForm 
                            handleNextClick={handleNextClick}
                            setShowAddedTestimonial={setShowAddedTestimonial}
                            itcId={itcId}
                            fetchTestimonials={fetchTestimonials}
                            editTestimonialData={editTestimonialData}
                            setEditTestimonialData={setEditTestimonialData}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export const TestimonialForm = ({handleNextClick, setShowAddedTestimonial, itcId, fetchTestimonials, editTestimonialData, setEditTestimonialData, isFromItcProfile = false}) => {
    const tech = useSelector(state => state.tech)
    const [form] = Form.useForm()
    const [submittingTestimonials, setSubmittingTestimonials] = useState(false)
    const [logoUrl, setLogoUrl] = useState();
    const [file, setFile] = useState(null)
    const [profileUpdateMessage, setProfileUpdateMessage] = useState("")
    const param = useParams()

    const props = {
        accept: 'image/*',
        multiple: false,
        showUploadList: false,
        beforeUpload: (file) => {
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                setProfileUpdateMessage('Image must smaller than 5MB!');
            } else {
                getBase64(file, imageUrl =>{
                    setLogoUrl(imageUrl)
                });
                setFile(file)
                setProfileUpdateMessage(file.name)
            }
            return false;
        },
        onDrop(e) {
           setFile(e.dataTransfer.files[0]);
        },
        onRemove: (file) => {
            setFile(null);
            return true;
        }
    };

    const handleTestimonialSubmit = (values) => {
        setSubmittingTestimonials(true)
        if(editTestimonialData){
            let payload = {
                Client_Company:values.companyName,
                Client_First_Name: values.firstName,
                Client_Last_Name: values.lastName,
                Client_Title: values.designation,
                Contents: values.clientTestimonial,
            }
            ApiBaseHelper.put({
                url: `itc/testimonial/${editTestimonialData.id}`,	
                headers: {
                    Authorization: 'Bearer ' + tech['token'],
                },
                payload: !file ?  {
                    ...payload,
                    img_Url: null 
                } : payload
                
            }).then( async (res) => {
                if(res){
                    if(file && isValidUrl(file) === false){
                        handleTestimonialFileSubmit(editTestimonialData.id)
                        return
                    }
                }
                setSubmittingTestimonials(false)
                notification.success({
                    message: 'TecMe',
                    description: 'Testimonial updated successfully'
                })
                setEditTestimonialData(null)
                await fetchTestimonials()
                await setShowAddedTestimonial(isFromItcProfile ? false : true)
            }).catch((err) => {
                setSubmittingTestimonials(false)
                notification.error({
                    message: 'TecMe',
                    description: 'An error occurred while updating testimonial'
                })
                console.log(err)
            })
        }else{
            ApiBaseHelper.post({
                url: `itc/testimonial`,	
                headers: {
                    Authorization: 'Bearer ' + tech['token'],
                },
                payload:  {
                    Client_Company:values.companyName,
                    Client_First_Name: values.firstName,
                    Client_Last_Name: values.lastName,
                    Client_Title: values.designation,
                    Contents: values.clientTestimonial,
                    ITC: isFromItcProfile ? param.id : itcId,
                }
                
            }).then( async (res) => {
                if(res){
                    if(file){
                        handleTestimonialFileSubmit(res.data.data.id)
                        return
                    }
                }
                setSubmittingTestimonials(false)
                notification.success({
                    message: 'TecMe',
                    description: 'Testimonial added successfully'
                })
                await fetchTestimonials()
                await setShowAddedTestimonial(isFromItcProfile ? false : true)
            }).catch((err) => {
                setSubmittingTestimonials(false)
                notification.error({
                    message: 'TecMe',
                    description: 'An error occurred while adding testimonial'
                })
                console.log(err)
            })
        }
    }

    const handleTestimonialFileSubmit = (id) => {
        const payload = new FormData();
        payload.append('file', file)
        ApiBaseHelper.post({
            url: `itc/testimonial/image/${id}`,	
            headers: {
                Authorization: 'Bearer ' + tech['token'],
            },
            payload: payload
        }).then(async (res) => {
            notification.success({
                message: 'TecMe',
                description: 'Testimonial added successfully'
            })
            setSubmittingTestimonials(false)
            await fetchTestimonials()
            await setShowAddedTestimonial(isFromItcProfile ? false : true)
        }).catch((err) => {
            setSubmittingTestimonials(false)
            notification.error({
                message: 'TecMe',
                description: 'An error occurred while adding testimonial'
            })
            setShowAddedTestimonial(isFromItcProfile ? false : true)
            console.log(err)
        })
    }

    useEffect(() => {
        if(editTestimonialData){
            form.setFieldsValue({
                firstName: editTestimonialData?.Client_First_Name,
                lastName: editTestimonialData?.Client_Last_Name,
                designation: editTestimonialData?.Client_Title,
                companyName: editTestimonialData?.Client_Company,
                clientTestimonial: editTestimonialData?.Contents
            })
            setLogoUrl(editTestimonialData?.img_Url)
        }
    }, [editTestimonialData])

    useEffect(() => {
        if(editTestimonialData){
            setFile(editTestimonialData?.img_Url)
        }
    }, [])

    return(
        <Form
            onFinish={handleTestimonialSubmit}
            form={form}
            layout='vertical'
            className="create-itc-testimonial-form"
        >   
            <div className="testimonial-avatar">
                <Space size={21}>
                    <Avatar
                        size={81} 
                        src={logoUrl} 
                        style={{
                            background:"#FFFFFF", 
                            border:"1px solid #F04A22",
                            color:"#F04A22", 
                            fontSize:20}}
                    >
                        {"T"}
                    </Avatar>
                    <div>
                        <Paragraph style={{color: "rgba(0, 0, 0, 0.85)", fontWeight:500}}>Profile Picture (optional)</Paragraph>
                        <Space size={16}>
                            <Upload {...props}>
                                <Button style={{height:40}} type='secondary' icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                            <Button style={{height:40}} type='primary' onClick={() => { 
                                setLogoUrl(null)
                                setFile(null)
                                // setCompanyDetails({
                                //     ...companyDetails,
                                //     logoUrl: null
                                // })
                                }} 
                                disabled={logoUrl === null || logoUrl === undefined}>
                                    Remove
                            </Button>
                        </Space>
                    </div>
                </Space>
            </div>
            <Row gutter={24} className='testimonial-primary-details'>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="firstName"  
                        label="First Name" 
                        className='itc-testimonial-form-label-item'
                        rules={[{ required: true, message: "Please input your client's first name" }]}
                    >
                        <Input 
                            type="text" 
                            placeholder="Ex. John" 
                            size="large"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="lastName"  
                        label="Last Name" 
                        className='itc-testimonial-form-label-item'
                        rules={[{ required: true, message: "Please input your client's last name" }]}
                    >
                        <Input 
                            type="text" 
                            placeholder="Ex. Doe"
                            size="large"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <p>Add your client’s contact details. Don’t worry—we’ll only display their first name and last initial.</p>
            <Row gutter={24} className='testimonial-primary-details'>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="designation"  
                        label="Current Designation" 
                        className='itc-testimonial-form-label-item'
                        rules={[{ required: true, message: "Please input your client's designation" }]}
                    >
                        <Input 
                            type="text" 
                            placeholder="Ex. CTO" 
                            size="large"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="companyName"  
                        label="Client Company Name" 
                        className='itc-testimonial-form-label-item'
                        rules={[{ required: true, message: "Please input your company name" }]}
                    >
                        <Input 
                            type="text" 
                            placeholder="Ex. Tech Innovations" 
                            size="large"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <p>Enter the client's job title and company name (e.g., 'CTO at Tech Innovations Inc.').</p>
            <Form.Item  
                name="clientTestimonial"  
                label="Client Testimonial" 
                className='itc-form-label-item'
                rules={[{ required: true, message: "Please input your client's testimonial" }]}
            >
                <Input.TextArea   
                    showCount
                    placeholder="Enter a testimonial here. Share a client's feedback or a success story that highlights the effectiveness and value of your services."
                    maxLength={500}
                    className='create-itc-testimonial-form-textarea'
                    size='large'
                />
            </Form.Item> 
            {
                !isFromItcProfile ?
                <Form.Item>
                    <Space>
                        <Button
                            className='itc-next-btn' 
                            type='primary' 
                            htmlType='submit'
                            loading={submittingTestimonials}
                        >Next</Button>
                        <Button 
                            className='itc-next-btn' 
                            type='secondary'
                            onClick={() => handleNextClick()}
                        >Skip</Button>
                    </Space>
                </Form.Item> :
                <Form.Item>
                    <Space>
                        <Button 
                            className='itc-next-btn' 
                            type='secondary'
                            onClick={() => {
                                setShowAddedTestimonial(false)
                                setEditTestimonialData(null)
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            className='itc-next-btn' 
                            type='primary' 
                            htmlType='submit'
                            loading={submittingTestimonials}
                        >  
                            Submit
                        </Button>
                    </Space>
                </Form.Item>
            }
        </Form>
    )
}


const AddedTestimonial = ({handleNextClick, showAddedTestimonial, setShowAddedTestimonial, fetchTestimonials, setShowBackBtn, testimonials, loadingTestimonials, setEditTestimonialData}) => {

    useEffect(() => {
        setShowBackBtn(showAddedTestimonial ? false : true)
     }, [showAddedTestimonial])

    return(
        <div className='added-testimonial-section'>
            <Space size={10} 
                onClick={() => {
                    setShowAddedTestimonial(false)
                }} 
                className='itc-back-btn'
            >
                <img src={BackIcon} alt="Back Icon" />
                <span>Go Back</span>
            </Space>
            <div className="added-testimonial-ctn">
                {/* <AddedItemMessage message={"Testimonial added successfully"} /> */}
                <Title level={4} className="itc-form-title">Testimonials</Title>
                <TestimonialList 
                    testimonials={testimonials}
                    fetchTestimonials={fetchTestimonials}
                    setShowAddedTestimonial={setShowAddedTestimonial}
                    handleNextClick={handleNextClick}
                    loadingTestimonials={loadingTestimonials}
                    setEditTestimonialData={setEditTestimonialData}
                />
            </div>
        </div>
    )
}

export default CreateItcTestimonials