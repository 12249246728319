import React, { useEffect, useState } from 'react'
import AboutImg from '../assets/images/about-img.png'
import { Button, Form, Input, message, Modal, Rate, Select, Typography, Upload } from 'antd'
import { brandColor } from '..'
import VerifiedIcon from '../assets/images/verified-icon.svg'
import LocationIcon from '../assets/images/location-icon.svg'
import PeopleIcon from '../assets/images/people-icon.svg'
import TimeIcon from '../assets/images/time-icon.svg'
import { capitalizeFirstLetter, getBase64 } from '../../../../../../utils/helper'
import { decode } from 'html-entities'
import { ClockCircleOutlined, EditOutlined, EnvironmentOutlined, GlobalOutlined, TeamOutlined, UploadOutlined } from '@ant-design/icons'
import { ensureHttps } from './nav'
import { SECONDARY_COLOR } from '../../../../../../utils/constants'
import ReactPlayer from 'react-player'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import htmlToDraft from 'html-to-draftjs'

const { Paragraph, Title } = Typography

const ItcAbout = ({itcContent, brandColor, canEdit, token}) => {
    const [openMediaModal, setOpenMediaModal] = useState(false)
    const [mediaUrl, setMediaUrl] = useState("")
    const [mediaType, setMediaType] = useState ("")
    const [altMediaUrl, setAltMediaUrl] = useState("")
    const [altMediaType, setAltMediaType] = useState ("")
    const [preview, setPreview] = useState(null);
    const [submitting, setSubmitting] = useState(false)
    const [aboutImgFile, setAboutImgFile] = useState(null)
    const [form] = Form.useForm();

    const handlePreview = () => {
        if (mediaType === "image") {
          setPreview(<img src={mediaUrl} alt="preview" style={{ width: '100%', height: '45vh', objectFit:"contain" }} />);
        } else if (mediaType === "video") {
          setPreview(
          <ReactPlayer  
            width='100%'
            height='45vh' 
            url={mediaUrl} />
        );
        } else {
          setPreview(<p>URL is not a valid image or video link.</p>);
        }
    };

    const aboutImgProps = {
        accept: 'image/*',
        multiple: false,
        showUploadList: false,
        beforeUpload: (file) => {
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                message.error('Image must be smaller than 5MB!');
            } else {
                getBase64(file, imageUrl =>{
                    setMediaUrl(imageUrl)
                });
                setAboutImgFile(file)
            }
            return false;
        },
        onDrop(e) {
            setAboutImgFile(e.dataTransfer.files[0]);
        },
        onRemove: (file) => {
            setAboutImgFile(null);
            return true;
        }
    };

    const onFinish = (values) => {
        if(!canEdit){
            return
        }
        setSubmitting(true)
        // if image is uploaded instead of inputting link
        if(mediaType === "image" && aboutImgFile){
            let formData = new FormData()
            formData.append("file", aboutImgFile)
            ApiBaseHelper.post({
                url: `itc/about-image/${itcContent?.id}`,	
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                payload: formData
            }).then((res) => {
                // remove the mediaUrl and mediaType from custom_data
                if(itcContent?.custom_data?.media && (JSON.parse(itcContent?.custom_data?.media)?.type || JSON.parse(itcContent?.custom_data?.media)?.url)){
                    ApiBaseHelper.put({
                        url: `itc/${itcContent?.id}`,	
                        headers: {
                            Authorization: 'Bearer ' + token,
                        },
                        payload: {
                            Active:true,
                            Media: "{}"
                        }
                    }).then((res) => {
                        console.log("success")
                        setSubmitting(false)
                        setOpenMediaModal(false)
                        setAltMediaUrl(mediaUrl)
                        setAltMediaType(mediaType)
                    }).catch((err) => {
                        console.log(err)
                        setSubmitting(false)
                    })
                }else{
                    setSubmitting(false)
                    setOpenMediaModal(false)
                    setAltMediaUrl(mediaUrl)
                    setAltMediaType(mediaType)
                }
            }).catch((err) => {
                console.log(err)
                setSubmitting(false)
            })
            return
        }

        // if media link is added to input submit the data to custom_data
        ApiBaseHelper.put({
            url: `itc/${itcContent?.id}`,	
            headers: {
                Authorization: 'Bearer ' + token,
            },
            payload: {
                Active:true,
                Media: JSON.stringify({
                    type: values.mediaType,
                    url: values.mediaUrl
                }),
            }
        }).then((res) => {
           // remove About_Image 
            if(itcContent?.About_Image){
                ApiBaseHelper.put({
                    url: `itc/${itcContent?.id}`,	
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                    payload: {
                        Active:true,
                        About_Image: null
                    }
                }).then((res) => {
                    console.log("success")
                    setSubmitting(false)
                    setOpenMediaModal(false)
                    setAltMediaUrl(mediaUrl)
                    setAltMediaType(mediaType)
                }).catch((err) => {
                    console.log(err)
                    setSubmitting(false)
                })
            }else{
                setSubmitting(false)
                setOpenMediaModal(false)
                setAltMediaUrl(mediaUrl)
                setAltMediaType(mediaType)
            }
        }).catch((err) => {
            console.log(err)
            setSubmitting(false)
        })
    };

    useEffect(() => {
        if(mediaUrl){
            handlePreview()
        }
    }, [mediaUrl])

    useEffect(() => {
        if(itcContent?.custom_data?.media) {
            setAltMediaType(JSON.parse(itcContent?.custom_data?.media)?.type)
            setAltMediaUrl(JSON.parse(itcContent?.custom_data?.media)?.url)
            setMediaType(JSON.parse(itcContent?.custom_data?.media)?.type)
            setMediaUrl(JSON.parse(itcContent?.custom_data?.media)?.url)
            form.setFieldsValue({
                mediaType: JSON.parse(itcContent?.custom_data?.media)?.type,
                mediaUrl: JSON.parse(itcContent?.custom_data?.media)?.url
            })
        }
    }, [itcContent])

    return (
        <section className="itc-about-section" id="about-us">
            <div className="itc-about-ctn">
                <div className="itc-about-media">
                    <div className="itc-about-img">
                        {
                            ((altMediaUrl === "" && !itcContent?.About_Image )||(altMediaUrl === undefined && !itcContent?.About_Image)) &&
                            <img src={AboutImg} alt="about image" />
                        }
                        {
                            itcContent?.About_Image && (altMediaUrl === "" || altMediaUrl === undefined) &&
                            <img src={itcContent?.About_Image} alt="about image" />
                        }
                        {
                            altMediaUrl !== undefined && altMediaType === "image" &&
                            <img src={altMediaUrl} alt="about image" />
                        }
                        {
                            altMediaUrl !== undefined && altMediaType === "video" &&
                            <ReactPlayer
                                width='100%'
                                height='45vh' 
                                url={altMediaUrl}
                                controls
                            />
                        }
                    </div>
                    { 
                        canEdit && 
                        <div className="landing-edit-icon">
                            <EditOutlined 
                                style={{fontSize:25, color:SECONDARY_COLOR}} 
                                onClick={() => setOpenMediaModal(true)}
                            />
                        </div>
                    }
                </div>
                <div className="itc-about-content">
                    <div className="itc-top-border" style={{background:brandColor}}></div>
        	        <Title level={2} className="itc-about-title">Welcome to  <br />{capitalizeFirstLetter(itcContent?.Company_Name)} <img src={VerifiedIcon} alt="verified icon" /> </Title>
                    {/* <div className='company-ratings-ctn'>
                        <Paragraph className="company-ratings">Exceptional 5.0</Paragraph>
                        <Rate disabled defaultValue={5} style={{color:"#F04A22", fontSize:15}} />
                        <Paragraph className="company-reviews">(90)</Paragraph>
                    </div> */}
                    <div className="company-details-ctn">
                        <div className="company-details-item">
                            <EnvironmentOutlined style={{color:"#000", fontSize:20}}/>
                            <Paragraph className="company-details">{capitalizeFirstLetter(itcContent?.Location?.City)}{itcContent?.Location?.State ? `, ${itcContent?.Location?.State}` : ""}</Paragraph>
                        </div>
                       {
                        itcContent?.Members?.length > 0 &&
                        <div className="company-details-item">
                             <TeamOutlined style={{color:"#000", fontSize:20}}/>
                            <Paragraph className="company-details">{itcContent?.Members?.length} {`IT Professional${itcContent?.Members?.length > 1 ? 's' : ''}`}</Paragraph>
                        </div>
                        }
                        <div className="company-details-item">
                            <ClockCircleOutlined style={{color:"#000", fontSize:20}}/>
                            <Paragraph className="company-details">{itcContent?.Years_Experience}  years in business</Paragraph>
                        </div>
                       { 
                        itcContent?.custom_data?.websiteUrl &&
                            <div className="company-details-item">
                                <GlobalOutlined style={{color:"#000", fontSize:20}}/>
                                <a className="company-details" href={ensureHttps(itcContent?.custom_data?.websiteUrl)} target="_blank" rel="noopener noreferrer">{itcContent?.custom_data?.websiteUrl}</a>
                            </div>
                        }
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: itcContent?.Description
                        }}>
                    </div>
                    {/* <Paragraph className="company-description">{capitalizeFirstLetter(decode(itcContent?.Description))}</Paragraph> */}
                </div>
            </div>
            <Modal
                title="Insert a media link"
                visible={openMediaModal}
                onOk={() => setOpenMediaModal(false)}
                onCancel={() => setOpenMediaModal(false)}
                footer={null}
                width={650}
            >
                <Form
                    layout='vertical'
                    className="itc-media-form"
                    onFinish={onFinish}
                    form={form}
                >
                    <Form.Item
                        label="Media Type"
                        name="mediaType"
                        className='itc-form-label-item'
                        style={{marginBottom:20}}
                        rules={[{ required: true, message: 'Please select a media type!' }]}
                    >
                        <Select
                            placeholder="Select a media type"
                            size="large"
                            onChange={(value) => {
                                setMediaType(value)
                            }}
                        >
                            <Select.Option value="image">Image</Select.Option>
                            <Select.Option value="video">Video</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Media Link"
                        name="mediaUrl"
                        className='itc-form-label-item'
                        style={{marginBottom:20}}
                        extra={ mediaType === "video" && "Video links from youtube, facebook, vimeo, twitch, soundcloud, streamable, mux, wistia, vidyard, dailymotion and kaltura are supported"}
                        rules={
                            [
                                { 
                                    required: !mediaUrl && true, 
                                    message: 'Please input your media link!' 
                                },
                                {
                                    max: 2000,
                                    message: 'Media link must not exceed 2000 characters'
                                }
                            ]
                        }
                    >
                        <Input 
                            type="text" 
                            placeholder="Enter a link to your media type" 
                            size="large"
                            onChange={(e) => setMediaUrl(e.target.value)}
                        />
                    </Form.Item>
                    {
                        mediaType === "image" && 
                        <Form.Item
                            style={{marginBottom:20}}   
                        >
                            <Upload {...aboutImgProps}>
                                <Button style={{height:40}} type='secondary' icon={<UploadOutlined />}>Upload Image Instead</Button>
                            </Upload>
                        </Form.Item>
                    }
                    <div className='mb-4'>
                        {preview}
                    </div>
                    <Form.Item>
                        <Button type='primary' htmlType='submit' style={{height:40}} loading={submitting}>   
                            Save Changes
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </section>
    )
}

export default ItcAbout