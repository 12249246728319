import React, { useEffect, useState } from 'react'
import { AddedItemCard } from '../../create-itc/components/added-item'
import { Button, Typography } from 'antd'
import { SpecializationForm } from '../../create-itc/components/specializations'
import SpecializationList from '../../create-itc/components/specialization-list'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'

const { Title } = Typography

const ProfileSpecializations = ({itcId, token}) => {
    const [showAddedSpecialization, setShowAddedSpecialization] = useState(false)
    const [specializations, setSpecializations] = useState([])
    const [loadingSpecializations, setLoadingSpecializations] = useState(false)
    const [editSpecializationData, setEditSpecializationData] = useState(null)

    const fetchSpecializations = () => {
        setLoadingSpecializations(true)
        ApiBaseHelper.get({
            url: `itc/specialization/all?itc=${itcId}`, 
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setSpecializations(res?.data?.data)
            setLoadingSpecializations(false)
        }).catch((error) => {
            console.error(error)
            setLoadingSpecializations(false)
        })       
    }


    useEffect(() => {
        fetchSpecializations()
    }, [])

    useEffect(() => {
        fetchSpecializations()
    }, [showAddedSpecialization])
  
    if(showAddedSpecialization) {
        return(
            <div className="profile-specialization-form-ctn">
                <SpecializationForm 
                    setShowAddedSpecialization={setShowAddedSpecialization}
                    isFromItcProfile={true}
                    fetchSpecializations={fetchSpecializations}
                    editSpecializationData={editSpecializationData}
                    setEditSpecializationData={setEditSpecializationData}
                />
            </div>
        )
    }

    return (
        <>
            <Title level={4} className="itc-form-title">Specializations</Title>
            <SpecializationList
                specializations={specializations} 
                setShowAddedSpecialization={setShowAddedSpecialization}
                fetchSpecializations={fetchSpecializations}
                isFromItcProfile={true}
                loadingSpecializations={loadingSpecializations}
                setEditSpecializationData={setEditSpecializationData}
            />
        </>
    )
}

export default ProfileSpecializations