import React, { useEffect } from 'react'
import { MetaTags } from 'react-meta-tags'
import { GTM_ID, META_DESCRIPTION } from '../../../../../../utils/constants'
import { Button, Checkbox, Col, Form, Input, Layout, Row, Typography, notification } from 'antd'
import { Link } from 'react-router-dom';
import './styles.scss';
import { useState } from 'react';
import ROUTES from '../../../../../../utils/routes'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper';
import { useHistory, useParams } from 'react-router';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { TouchPointLogout, decryptString, getUserProfile } from '../../../../../../utils/helper';
import {isBrowser, isTablet, isMobileOnly, isMobile} from 'react-device-detect';
import { FirebaseHelper } from '../../../../../../utils/firebase-helper';
import { updateTech, updateUser } from '../../../../../../redux/reducers/app';
import TagManager from 'react-gtm-module';

const {Title, Paragraph, Text} = Typography
const {Content} = Layout

const TeamLogin = (props) => {
    const user = useSelector(state => state.user)
    const organizations = useSelector(state => state.organizations)
    const encryptedPassword = useSelector(state => state.encryptedPassword)
    const [cookies, setCookie, removeCookie] = useCookies(['email', 'password']);
    const [check, setCheck] = useState(cookies.email ? true : false);
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch();
    const params = useParams()

 
    let team = organizations.find(org => org.id === params.orgId)

    const onFinish = async () => {
        if (check) {
            setCookie('email', user?.email, {path: '/'});
            setCookie('password', decryptString(encryptedPassword), {path: '/'});
            setCheck(true)
        } else if (!check) {
            removeCookie("email")
            removeCookie("password")
        }
        if (loading) {
            return
        }
        setLoading(true);

        let fcmToken = props.fcmToken;
        let deviceType = 'Desktop';
        let frontEnd = 'Website';
        if (isMobileOnly) {
            deviceType = 'Phone';
        } else if (isTablet) {
            deviceType = 'Tablet';
        }

        ApiBaseHelper.post({
            url: `login`,
            payload: {
                "email": user?.email,
                "password": decryptString(encryptedPassword),
                "org_id": params?.orgId,
                "switch": true,
                "device_type": deviceType,
                "front_end": frontEnd,
            }
        }).then(async (res) => {
            await getUserProfile({
                orgId: res.data.data.orgId,
                token: res.data.data.token,
                tech: res.data.data.technician,
                dispatch: dispatch,
                redirectTarget: "_self",
                touchPoint: res.data.data.touchPoint,
                redirect:true,
                role: res.data.data.role,
                organization: res.data.data?.organization,
                email: user?.email,
                password:  decryptString(encryptedPassword),
            })
            setLoading(false)
            openNotification({message: 'TecMe', description: `You are now logged into ${team?.Name} team successfully!`})
        }).catch((e) => {
            setLoading(false)
            openNotification({message: 'TecMe', description: 'Login Failed! Email or Password is invalid'})
        })
    };

    const openNotification = (value) => {
        notification.open({
            message: value.message,
            description: value.description,
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    const handleLogout = () => {
        FirebaseHelper.detachAllEventListeners({path: `users/${user?.id}`});
        if (user?.touch != null) {
            TouchPointLogout(user?.touch)
        }
        history.push(ROUTES.LOGIN);
        dispatch(updateTech(null));
        dispatch(updateUser(null));
        const tagManagerArgs = {
            gtmId: GTM_ID,
            dataLayer: {
                user_id: null,
                org_id: null
            }
        }
        TagManager.initialize(tagManagerArgs)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

   
    return (
        <>
            <MetaTags>
                <title>Team Login - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <Content className="main">
                <div className="page">
                    <div className="team-login-section">
                        <div className="team-login-ctn">
                            <div className="heading-section">
                                <Title level={2} style={{color:"rgba(0, 0, 0, 0.85)", marginBottom:14}}>Login to the {team?.Name} team</Title>
                                {/* <Title level={4} style={{color:"#1A1D1F", marginBottom:32}}>Re-enter your password</Title> */}
                                <div className="email-info">
                                    <Paragraph style={{marginBottom:8, color:"#202223", fontWeight:500}}>Email</Paragraph>
                                    <Paragraph style={{marginBottom:8, color:"#202223"}}>{user?.email}</Paragraph>
                                    <Paragraph style={{color: "#F04A22", fontWeight:500, cursor:"pointer"}}onClick={handleLogout}>Not you?</Paragraph>
                                </div>
                            </div>
                            <div className="form-section">
                                <Row gutter={32}>
                                    <Col lg={12} xl={12}>
                                        <Button type="secondary" className="team-login-form-button" onClick={() => history.goBack()}>
                                            Go Back
                                        </Button>
                                    </Col>
                                    <Col lg={12} xl={12}>
                                        <Button 
                                            type="primary" 
                                            className="team-login-form-button" 
                                            loading={loading}
                                            onClick={() => onFinish()}
                                        >
                                            Sign in
                                        </Button>
                                    </Col>
                                </Row>
                                {/* <Form
                                    name="team-login"
                                    className="team-login-form"
                                    layout="vertical"
                                    initialValues={{remember: true}}
                                    onFinish={onFinish}
                                >
                                    <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            min: 0,
                                            message: 'Please enter a valid password',
                                        },
                                        {

                                            min: 8,
                                            message: 'Password should be at least 8 characters or more',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        className="team-login-form-input"	
                                        type="password"
                                    />
                                </Form.Item> 
                                <Row justify={"space-between"} align={"middle"} className="mb-4">
                                    <Col>
                                        <Button 
                                            className='border-0 bg-white' 
                                            style={{color: 'rgba(0, 0, 0, 0.85)', fontSize:14, padding: 0, fontWeight:500}} 
                                            type="link"
                                            htmlType="button"
                                            onClick={() => history.push(ROUTES.FORGOT_PASSWORD)}>
                                            Forgot Password?
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Form.Item name="remember" noStyle>
                                            <Checkbox 
                                                style={{color: 'rgba(0, 0, 0, 0.85)', fontSize:14, fontWeight:500}} 
                                                checked={check}
                                                onChange={event => setCheck(event.target.checked)}>
                                                Remember me
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row> 
                               
                                </Form> */}
                            </div>
                        </div> 
                    </div>
                </div>
            </Content>
        </>
    )
}

export default TeamLogin