import React from 'react'
import {  Checkbox, Collapse, Radio, Slider, Space, Typography } from 'antd'
import '../assets/css/job-filter.scss'


const {Paragraph} = Typography
const {Panel} = Collapse

const JobFilter = ({
    workCategories,
    queryParams,
    setCategory,
    setPaymentType,
    setFixedPrice,
    setMinimumBudget,
    setMaximumBudget,
    setWorkType,
    setDuration,
    setPaymentStatus,
    category,
    fixedPrice,
    rangeValues,
    setRangeValues,
    workType,
    duration,
    paymentStatus
}) => {
  return (
    <div>
        <div className="job-browsing-category">
            <Collapse defaultActiveKey={["category"]} className='job-browsing-collapse' ghost>
                <Panel header="Category" key="category">
                    {
                        workCategories.map((item, index) => (
                        <Paragraph 
                        className={`job-browsing-category-item ${item?.Title === category && 'job-browsing-category-item-active'}`} 
                        key={index}
                        onClick={() => setCategory(item?.Title)}
                        >
                            {item?.Title}
                        </Paragraph>
                    ))
                    }
                </Panel>
            </Collapse>
        </div>
        <div className="job-browsing-payment">
            <Collapse defaultActiveKey={["payment"]} className='job-browsing-collapse' ghost>
                <Panel header="Payment" key="payment">
                    <div className='hourly'>
                        <Checkbox
                            onChange={(e) => {
                                setPaymentType(e.target.checked ? 'Hourly' : '')
                                setFixedPrice('')
                            }}  
                            checked={queryParams.get('paymentType') === 'Hourly'}
                        >
                            Hourly
                        </Checkbox>
                        {
                            queryParams.get('paymentType') === 'Hourly' &&
                            <div className='hourly-rate-slider'>
                                <Paragraph>${rangeValues.min} - ${rangeValues.max}</Paragraph>
                                <Slider 
                                    range 
                                    defaultValue={[rangeValues.min, rangeValues.max]} 
                                    min={10}
                                    max={300}
                                    onChange={(value) => setRangeValues({min: value[0], max: value[1]})}
                                />
                            </div>
                        }
                    </div>
                    <div className='fixed'>
                        <Checkbox 
                            onChange={(e) => {
                                setPaymentType(e.target.checked ? 'Fixed' : '')
                                setMinimumBudget('')
                                setMaximumBudget('')
                                setRangeValues({
                                    min: 10,
                                    max: 300
                                })
                            }}
                            checked={queryParams.get('paymentType') === 'Fixed'}
                        >
                            Fixed Price
                        </Checkbox>
                        {
                            queryParams.get('paymentType') === 'Fixed' &&
                            <div className='fixed-rate-checkboxes'>
                                    <Radio.Group
                                    onChange={(e) => setFixedPrice(e.target.value)}
                                    value={fixedPrice}
                                >
                                        <Space direction="vertical">
                                        {
                                            ["Less than $100", "$100 - $500", "$500 - $1k", "$1k - $5k", "$5k+"].map((item) => (
                                                <Radio  key={item} value={item}>{item}</Radio>
                                            ))
                                        }
                                    </Space>
                                </Radio.Group>
                            </div>
                        }
                    </div>
                </Panel>
            </Collapse>
        </div>
        <div className="job-browsing-work-type">
            <Collapse defaultActiveKey={[]} className='job-browsing-collapse' ghost>
                <Panel header="Work type" key="work-type">
                    <div className='work-type-checkboxes'>
                            <Radio.Group
                            onChange={(e) => setWorkType(e.target.value)}
                            value={workType}
                            >
                            <Space direction="vertical">
                                <Radio value="Both">Remote & On-site</Radio>
                                <Radio value="Remote">Remote</Radio>
                                <Radio value="On_Site">On-site</Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                </Panel>
            </Collapse>
        </div>
        <div className="job-browsing-project">
            <Collapse defaultActiveKey={['project']} className='job-browsing-collapse' ghost>
                <Panel header="Project duration" key="project-duration">
                    <div className='project-checkboxes'>  
                        <Radio.Group
                            onChange={(e) => setDuration(e.target.value)}
                            value={duration}
                        >
                            <Space direction="vertical">
                                <Radio value="Short">Short-term (1-3 months)</Radio>
                                <Radio value="Medium">Medium-term (3 - 6 months)</Radio>
                                <Radio value="Long">Long-term (more then 6 months)</Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                </Panel>
            </Collapse>
        </div>
        <div className="job-browsing-payment-status">
            <Collapse defaultActiveKey={[]} className='job-browsing-collapse' ghost>
                <Panel header="Payment status" key="payment-status">
                    <div className='project-checkboxes'>  
                        <Checkbox.Group
                            value={paymentStatus}
                            onChange={(value) => setPaymentStatus(value)}
                        >
                            <Space direction="vertical">
                                <Checkbox value="Verified">Verified</Checkbox>
                                <Checkbox value="Unverified">Unverified</Checkbox>
                            </Space>
                        </Checkbox.Group>
                    </div>
                </Panel>
            </Collapse>
        </div>
    </div>
  )
}

export default JobFilter