// External Imports
import { Modal, Typography, Button, Space } from 'antd'

const { Text, Title } = Typography

const GatherInformationModal = ({ visible, onOk, onCancel, technicianCardPaymentCapabilities }) => {
    return (
        <Modal
            title={<Title level={3} className="m-0 font-weight-500">Information Required</Title>}
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            footer={false}
            width={550}
            className='edit-contract-modal'
        >
            <Space direction='vertical' className='w-100'>
                <Space className='disclaimer p-2'>
                    <Text>
                        To receive your earnings from TecMe, please submit the requested information via Stripe, our secure payments processor. Then, simply refresh the page to accept the contract.
                    </Text>
                </Space>
                <ul>
                    <li>SSN last 4</li>
                    <li>Email</li>
                    <li>Phone Number</li>
                    <li>Date of birth</li>
                    <li>Complete Address (City, State, Postal Code)</li>
                </ul>
                <Space className='w-100 justify-content-end'>
                    <Button type='ghost' onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button type='primary' onClick={() => {
                        window.open(technicianCardPaymentCapabilities?.login_link, '_blank');
                    }}>
                        Submit Information
                    </Button>
                </Space>
            </Space>
        </Modal>
    )
}

// const GatherInformationModal = ({ visible, onOk, onCancel }) => {
//     const [form] = useForm()
//     const [loading, setLoading] = useState(false)

//     const handleOnFinish = (values) => {
//         console.log({ values })
//     }

//     return (
//         <Modal
//             title={<Text strong>Needed Information</Text>}
//             visible={visible}
//             onCancel={onCancel}
//             onOk={onOk}
//             footer={false}
//             centered
//             width={550}
//             className='edit-contract-modal'
//         >
//             <Space className='disclaimer p-2'>
//                 <Text>
//                     We require certain details in order to set up a business account and process payments directly into your bank account. Please provide this information as soon as possible for us to begin processing payments.
//                 </Text>
//             </Space>
//             <Form
//                 className='mt-2'
//                 layout="vertical"
//                 onFinish={handleOnFinish}
//                 onFinishFailed={error => console.error(error)}
//                 form={form}
//             >
//                 {/* <Item
//                     label="Industry"
//                     name="Industry"
//                     rules={[
//                         {
//                             required: true,
//                             message: 'Please select industry'
//                         }
//                     ]}
//                 >
//                     <Select
//                         placeholder='Select industry'
//                         className="w-100"
//                         options={industries?.map(industry => ({ label: industry, value: industry }))}
//                     />
//                 </Item> */}
//                 <Row gutter={[12,12]}>
//                     <Col xs={24}>
//                         <Item
//                             label='Email'
//                             name="Email"
//                             rules={[
//                                 {
//                                     type: "email",
//                                     required: true,
//                                     message: 'Please enter email'
//                                 }
//                             ]}
//                         >
//                             <Input
//                                 placeholder='Enter email'
//                             />
//                         </Item>
//                     </Col>
//                     <Col xs={24} md={12}>
//                         <Item
//                             label='SSN'
//                             name="SSN"
//                             rules={[
//                                 {
//                                     required: true,
//                                     message: 'Please enter last 4 SSN digits'
//                                 },
//                                 {
//                                     pattern: /^\d{4}$/,
//                                     message: 'Please enter only last 4 SSN digits',
//                                 },
//                             ]}
//                         >
//                             <Input
//                                 placeholder='Enter last 4 SSN digits'
//                                 onKeyPress={(event) => {
//                                     if (!/[0-9]/.test(event.key)) {
//                                         event.preventDefault();
//                                     }
//                                 }}
//                                 onPaste={(e)=>{
//                                     e.preventDefault()
//                                     return false;
//                                 }}
//                             />
//                         </Item>
//                     </Col>
//                     <Col xs={24} md={12}>
//                         <Item
//                             label='Representative phone number'
//                             name="PhoneNumber"
//                             rules={[
//                                 {
//                                     required: true,
//                                     message: 'Please enter phone number',
//                                 },
//                                 {
//                                     pattern: /^\(?\d{3}\)?[-\s]\d{3}-\d{4}$/,
//                                     message: 'Please enter a valid phone number!',
//                                 }
//                             ]}
//                         >
//                             <Input
//                                 placeholder='Enter phone number'
//                             />
//                         </Item>
//                     </Col>
//                     <Col xs={24}>
//                         <Item
//                             label="Date Of Birth"
//                             name="DateOfBirth"
//                             rules={[
//                                 {
//                                     required: true,
//                                     message: 'Please select date of birth'
//                                 }
//                             ]}
//                         >
//                             <DatePicker
//                                 format="MMM D, YYYY"
//                                 className='w-100'
//                                 placeholder="Select your date of birth"
//                                 disabledDate={(current) => {
//                                     return current && current >= moment().endOf('day');
//                                 }}
//                             />
//                         </Item>
//                     </Col>
//                     <Col xs={24} md={12}>
//                         <Item
//                             label='City'
//                             name="City"
//                             rules={[
//                                 {
//                                 required: true,
//                                 message: 'Please enter your city',
//                                 },
//                                 {
//                                 pattern: /^[a-zA-Z\s]*$/,
//                                 message: 'Please enter a valid city name',
//                                 },
//                             ]}
//                         >
//                             <Input
//                                 placeholder='Enter your city'
//                             />
//                         </Item>
//                     </Col>
//                     <Col xs={24} md={12}>
//                         <Item
//                             label="Zip Code"
//                             name="ZipCode"
//                             rules={[
//                                 {
//                                     required: true,
//                                     message: 'Please enter your zip code',
//                                 },
//                                 {
//                                     pattern: /^\d{5}(?:[-\s]\d{4})?$/,
//                                     message: 'Please enter a valid zip code',
//                                 },
//                             ]}
//                         >
//                             <Input
//                                 placeholder="Enter your zip code"
//                                 onKeyPress={(event) => {
//                                     if (!/[0-9]/.test(event.key)) {
//                                         event.preventDefault();
//                                     }
//                                 }}
//                                 onPaste={(e)=>{
//                                     e.preventDefault()
//                                     return false;
//                                 }}    
//                             />
//                         </Item>
//                     </Col>
//                     <Col xs={24}>
//                         <Item
//                             label="State"
//                             name="State"
//                             rules={[
//                                 {
//                                     required: true,
//                                     message: 'Please select your state'
//                                 }
//                             ]}
//                         >
//                             <Select
//                                 placeholder='Select your state'
//                                 className="w-100"
//                                 options={STATES.map(data => ({ label: data.State, value: data.Code }))}
//                             />
//                         </Item>
//                     </Col>
//                     <Col xs={24}>
//                         <Item
//                             label="Address"
//                             name="Address"
//                             rules={[
//                                 {
//                                     required: true,
//                                     message: 'Please enter your address'
//                                 },
//                                 {
//                                     min: 25,
//                                     message: 'It should be at least 25 characters or more.',
//                                 }
//                             ]}
//                         >
//                             <TextArea
//                                 placeholder='Enter your address'
//                                 rows={4}
//                                 showCount
//                                 maxLength={250}
//                             />
//                         </Item>
//                     </Col>
//                 </Row>
//                 <Item>
//                     <Space className='mt-3 w-100 justify-content-end'>
//                         <Button type="text" onClick={onCancel}>
//                             Cancel
//                         </Button>
//                         <Button type="primary" htmlType="submit" loading={loading}>
//                             Submit
//                         </Button>
//                     </Space>
//                 </Item>
//             </Form>
//         </Modal>
//     )
// }

export default GatherInformationModal