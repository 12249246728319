import { combineReducers } from 'redux'
import app from './app'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web


// export default combineReducers({
//   app
// })
const persistConfig = {
  key: 'root',
  storage,
}

export default persistReducer(persistConfig, app)