import { Button, Col, Form, Input, Modal, Row, Typography, notification } from 'antd';
import React, { useState, useEffect } from 'react'
import { FirebaseHelper, FirestoreHelper } from '../../../../../../utils/firebase-helper';
import { RtmRepository } from '../../../../common/private/real-time-messaging/repository/rtm-repository';
import { useHistory } from 'react-router-dom';
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import ROUTES from '../../../../../../utils/routes';
import { useSelector } from 'react-redux';
import { encryptString } from '../../../../../../utils/helper';
import { getAlgoliaIndex } from '../../../../common/public/instant-search/utils/helper';
import axios from 'axios';	
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper';

const moment = extendMoment(Moment);

const { TextArea } = Input;
const { Title } = Typography;
const algoliaAppID =   process.env.REACT_APP_ALGOLIA_APPLICATION_ID;
const algoliaSearchKey =  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY;
const algoliaIndex = getAlgoliaIndex();

const NewMessageModal = ({messageModalVisible, setMessageModalVisible, itcContent, activeConversationId}) => {
    const [sendingMessage, setSendingMessage] = useState(false)

    const user = useSelector(state => state.user)
    const tech = useSelector(state => state.tech)
    const [ownerProfile, setOwnerProfile] = useState({})

    const token = user?.token || tech?.token
    const activeUser = user || tech
    const history = useHistory()

    const fetchOwnerProfile = async () => {
        ApiBaseHelper.get({
            url: `web/search-tech?contactId=${itcContent?.Owner_Contact}`, 
            headers: {
                Authorization: 'Bearer ' + activeUser?.token
            }
        }).then((res) => {
            const ownerDetails  = res?.data?.data?.Data
            setOwnerProfile(ownerDetails)
        }).catch((error) => {
            console.error(error)
        })       
        // try {
        //     const response = await axios.get(
        //       `https://${algoliaAppID}-dsn.algolia.net/1/indexes/${algoliaIndex}`,
        //       {
        //         headers: {
        //           'X-Algolia-API-Key': algoliaSearchKey,
        //           'X-Algolia-Application-Id': algoliaAppID,
        //         },
        //         params: {
        //           query: "",
        //         },
        //       }
        //     );
      
        //     console.log(response.data.hits, itcContent?.Owner_Contact)
        //     // Extract and store the search results from the response
        //     setOwnerProfile(response.data.hits.find((hit) => hit.objectID === itcContent?.Owner_Contact));
        // } catch (error) {
        //     console.error('Error fetching data:', error);
        // }
    }

    useEffect(() => {
        if(itcContent){
            fetchOwnerProfile()
        }
    }, [itcContent])

    const rtmRepository = new RtmRepository(token)
    return (
        <Modal
            className="rounded" 
            visible={messageModalVisible}
            onCancel={() => setMessageModalVisible(false)}     
            header={<Title level={4}>Send a message</Title>}
            footer={null}>
            <Form
                name="message_form"
                onFinish={async (values) => {
                    if (!token) {
                        notification['error']({
                            message: 'TecMe',
                            description:
                                'Please log into your account first.',
                        });
                        return;
                    }
                    setSendingMessage(true)
                    await FirebaseHelper.login();
                    let conversationId = activeConversationId;
                    
                    if(!conversationId){
                        await rtmRepository.createConversation(itcContent?.Owner_Contact).then((response) => {
                            conversationId = response.data.data.id;
                        })
                    }
                    await FirestoreHelper.createUser({
                        hipaa_id: activeUser.id,
                        display_name: `${activeUser['firstname']} ${activeUser['lastname'][0]}.`,
                        profile_picture: activeUser['imageUrl'],
                        role: 'customer'
                    });

                    await FirestoreHelper.createUser({
                        hipaa_id: itcContent?.Owner_Contact,
                        display_name: ownerProfile?.Full_Name,
                        profile_picture: ownerProfile?.imgUrl,
                        role: 'technician'
                    });

                    await FirestoreHelper.createConversation({
                        conversation_id: conversationId,
                        message: encryptString(values.message),
                        participants: [activeUser.id, itcContent?.Owner_Contact],
                        user_id: activeUser.id
                    });

                    await FirestoreHelper.sendMessage({
                        conversation_id: conversationId,
                        message: encryptString(values.message),
                        user_id: activeUser.id
                    });

                    rtmRepository.sendMessage({
                        conversationId: conversationId,
                        message: values.message,
                        createdAt: moment().utc().toISOString()
                    }).then((response) => {
                        setMessageModalVisible(false)
                        setSendingMessage(false)
                        history.push(`${ROUTES.INBOX}?id=${conversationId}`)
                    }).catch((error) => {
                        console.log(error)
                        setSendingMessage(false)
                    })

                }}
            >
                <Row gutter={[10, 10]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Title level={5}>Send a message</Title>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item
                            name="message"
                            style={{width: "100%"}}
                            rules={[{required: true, message: 'Please type something ...'}]}
                        >
                            <TextArea
                                size={"large"}
                                rows={5}
                                style={{borderRadius: "0.3rem"}}
                                placeholder="Type your message here ..."
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <Button 
                            type="primary" 
                            htmlType="submit"
                            style={{width: "100%", backgroundColor: "#FF4D4F", borderColor: "#FF4D4F"}}
                            loading={sendingMessage}
                        >
                            Send
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default NewMessageModal