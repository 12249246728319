import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {updateUser, updateTech} from '../../../../../redux/reducers/app';
import {
    Layout,
    Upload,
    Form,
    Alert,
    Input,
    Button,
    Slider,
    notification,
    Col,
    Row,
    Typography,
    Divider,
    Modal,
    Space,
    Collapse,
    Empty,
    Skeleton
} from 'antd';
import {message} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import {capitalizeFirstLetter, getBase64, sendVerificationEmail, sortDates} from "../../../../../utils/helper";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import {SECONDARY_COLOR} from "../../../../../utils/constants";
import { EditProfileRepository } from './repository';
import AddEditModal from './components/add-edit-modal';

// Assets Imports
import "./assests/css/index.css"
import { Link } from 'react-router-dom';
import ROUTES from '../../../../../utils/routes';
import { useLocation, useHistory } from 'react-router-dom';

const {Content} = Layout;
const {Title,Text, Paragraph} = Typography;
const { Panel } = Collapse;

const EditProfile = ({ isFromProfileDetails = false }) => {
    const customer = useSelector(state => state.user)
    const tech = useSelector(state => state.tech)
    const user = customer || tech
    const dispatch = useDispatch();
    const location = useLocation()
    const history = useHistory()

    let existingHomeRate = null;
    let existingRemoteRate = null;
    // check if tech has provider service available then populate fields
    if(tech !=null && tech['Provider_Service'].length > 0){
        tech['Provider_Service'].map((service,index)=>{
            if('Home' in service){
                existingHomeRate = service['Home']['price'] / 100;
            }
            if('Remote' in service){
                existingRemoteRate = service['Remote']['price'] / 100;
            }
        });
    }

    const [file, setFile] = useState(null);
    const [email, setEmail] = useState();
    const [homeRate, setHomeRate] = useState(existingHomeRate ? existingHomeRate : null);
    const [remoteRate, setRemoteRate] = useState(existingRemoteRate? existingRemoteRate : null);
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [phone, setPhone] = useState();
    const [imageUrl, setImageUrl] = useState(user.imageUrl);
    const [loading, setLoading] = useState(false);
    const [bio, setBio] = useState(false);
    const [radius, setRadius] = useState(tech ? tech.radius : 10);
    const [info, setInfo] = useState(false);
    const [editProfileRepository, setEditProfileRepository] = useState(undefined);
    const [workHistoryAndProjects, setWorkHistoryAndProjects] = useState({
        workHistory: [],
        itProjects: []
    });
    const [isWorkHistoryAndProjectsLoading, setIsWorkHistoryAndProjectsLoading] = useState(true);
    const [addEditModalInfo, setAddEditModalInfo] = useState({
        isOpen: false,
        data: undefined,
        mode: undefined,
        key: undefined
    });
    const [showWorkHistoryModal, setShowWorkHistoryModal] = useState(false)
    const [workHisDeleteId, setWorkHisDeleteId] = useState()
    const [showItProjectDeleteModal, setShowItProjectDeleteModal] = useState(false)
    const [itProjectDeleteId, setItProjectDeleteId] = useState() 

    const params = new URLSearchParams(location.search);

    useEffect(() => {
        if(user && !editProfileRepository) {
            setEditProfileRepository(new EditProfileRepository(user.token))
        }
    }, [user])

    useEffect(() => {
      if(editProfileRepository) {
        editProfileRepository.getWorkHistoryAndProjects().then(res => {
            if(res?.data?.data?.length) {
                const workHistory = res.data.data.filter(item => item.Tags === "work_history");
                const itProjects = res.data.data.filter(item => item.Tags === "project");
                setWorkHistoryAndProjects({
                    workHistory,
                    itProjects
                })
            }
            setIsWorkHistoryAndProjectsLoading(false)
        }).catch(err => {
            setIsWorkHistoryAndProjectsLoading(false)
            console.error(err)
        })
      }
    }, [editProfileRepository])

    useEffect(() => {
        if(params.get('section') === 'work-history') {
            handleAddClick('workHistory')
        }else if(params.get('section') === 'it-projects') {
            handleAddClick('itProjects')
        }else{
            setAddEditModalInfo({
                isOpen: false,
                data: undefined,
                mode: undefined,
                key: undefined
            })
        }
    }, [params.get('section')])
    
    
    const onFormSubmitHandler = async (values) => {
        setLoading(true)
        // if (user.skillExp == values.skills && user.radius == radius
        //     && file == null && user.firstname == values.firstName
        //     && user.lastname == values.lastName
        //     && user.email == values.email && user.phone == values.phone
        //     && (user.bio == null || user.bio == values.profileBio)) {
        //     setLoading(false)
        //     setInfo(true)
        //     return
        // }


        setInfo(false)
        setEmail(values.email)
        setFirstName(values.firstName)
        setLastName(values.lastName)
        setPhone(values.phone)
        setBio(values.profileBio)

        // setSkill(values.skills)
        const data = new FormData();
        data.append("firstName", values.firstName)
        data.append("lastName", values.lastName)
        if (values.email != user.email) {
            let validateAccountResponse = await editProfileRepository.validateEmail(values.email);

            if (validateAccountResponse.data['data'].length > 0) { // account exists
                Modal.info({
                    title: "Email already exists",
                    content: 'Email address that have entered is already registered in our database. Please use different email to continue.',
                });
                setLoading(false);
                return;
            }
            sendVerificationEmail({
                email: values.email,
                userId: user.id,
                sendNotification: false,
                token: user['token']
            });
            Modal.info({
                title: "Verify New Email Address",
                content: 'In order to change the email address of your account, you are required to verify the ownership of email address. Please check your inbox and verify the new email address with the given link.',
            });
            setLoading(false);
            return;
        }
        // data.append("email", values.email)
        data.append("phoneNumber", values.phone)
        data.append("bio", values.profileBio)
        data.append("radius", radius)
        // data.append("skillsExp", values.skills)
        data.append('file', file)
        data.append('homeRate', homeRate)
        data.append('remoteRate', remoteRate)

        setFile(null)

        editProfileRepository.saveUserData(data).then((res) => {
            const name = res.data.data.Full_Name.split(" ");
            tech ? dispatch(updateTech({
                    ...tech,
                    "firstname": name[0].charAt(0).toUpperCase() + name[0].slice(1),
                    "lastname": name[1].charAt(0).toUpperCase() + name[1].slice(1),
                    "phone": res.data.data.Personal_Phone,
                    "imageUrl": res.data.data.imgUrl,
                    "email": res.data.data.Email_Address,
                    "bio": res.data.data.Profile_Bio,
                    "radius": res.data.data.Radius,
                    "skillExp": res.data.data.Skills_Experience,
                    'Stripe_Connect_Id': res.data.data.Stripe_Connect_Id,
                    'Provider_Service': 'Provider_Service' in res.data.data ? res.data.data.Provider_Service : [],
                    'Drive_URL': res.data.data.Drive_URL,
                    'verified': res.data.data.Email_Verified,
                }))
                : dispatch(updateUser({
                    ...customer,
                    "firstname": name[0].charAt(0).toUpperCase() + name[0].slice(1),
                    "lastname": name[1].charAt(0).toUpperCase() + name[1].slice(1),
                    "phone": res.data.data.Personal_Phone,
                    "imageUrl": res.data.data.imgUrl,
                    "email": res.data.data.Email_Address,
                    'Provider_Service': 'Provider_Service' in res.data.data ? res.data.data.Provider_Service : [],
                    'verified': res.data.data.Email_Verified,
                }))

        }).then(() => {
            setLoading(false)
            openNotification({message: 'TecMe', description: 'Profile updated successfully.'})

        }).catch((e) => {
            console.log(e)
            setLoading(false)
            openNotification({message: 'TecMe', description: 'Something went wrong, please try again later.'})
        })
    }
    const openNotification = (value) => {
        notification.open({
            message: value.message,
            description: value.description,
        });
    };

    const handleAddClick = (key) => {
        const newAddEditModalInfo = {
            ...addEditModalInfo,
            isOpen: true,
            mode: 'Add',
            key
        }
        setAddEditModalInfo(newAddEditModalInfo)
    }

    const handleEditClick = (e, key, data) => {
        e.stopPropagation();
        const newAddEditModalInfo = {
            ...addEditModalInfo,
            isOpen: true,
            mode: 'Save',
            key,
            data
        }
        setAddEditModalInfo(newAddEditModalInfo)
    }
    
    const handleDeleteClick = (key, id) => {
        setWorkHistoryAndProjects(prevData => ({...prevData, [key]: prevData[key].map(d => d.id === id ? ({...d, isLoading: true}) : d) }))
        editProfileRepository.deleteWorkHistoryAndProjects(id).then(() => {
            setWorkHistoryAndProjects(prevData => ({...prevData, [key]: prevData[key].filter(d => d.id !== id) }))
            notification.success({
                message: 'TecMe',
                description: `${key === "workHistory" ? "Work history" : "IT project"} deleted successfully`
            })
            setShowWorkHistoryModal(false)
        }).catch(err => {
            console.error(err);
            setWorkHistoryAndProjects(prevData => ({...prevData, [key]: prevData[key].map(d => d.id === id ? ({...d, isLoading: false}) : d) }))
            notification.error({
                message: 'TecMe',
                description: `Failed to delete ${key === "workHistory" ? "Work history" : "IT project"}, please try again`
            })
        })
    }

    const updatedWorkHistoryAndProjects = () => {
        editProfileRepository.getWorkHistoryAndProjects().then(res => {
            if(res?.data?.data?.length) {
                const workHistory = res.data.data.filter(item => item.Tags === "work_history");
                const itProjects = res.data.data.filter(item => item.Tags === "project");
                setWorkHistoryAndProjects({
                    workHistory,
                    itProjects
                })
            }
        }).catch(err => {
            console.error(err)
        })
    }

    return (
        <Content style={{
            backgroundColor:"#FFFFFF",
            padding:"20px 11px"
        }}>
            <div>
                <Form
                    className={"mt-4"}
                    layout="vertical"
                    name="edit-profile"
                    initialValues={{
                        firstName: user.firstname,
                        lastName: user.lastname,
                        email: user.email,
                        phone: user.phone,
                        radius: user.radius,
                        // skills: user.skillExp,
                        file: file,
                        homeRate: existingHomeRate? existingHomeRate : null,
                        remoteRate: existingRemoteRate? existingRemoteRate : null,

                    }}
                    onFinish={onFormSubmitHandler}
                >
                    {/* <div className="ant-col ant-form-item-label ">
                        <label htmlFor=""
                            title="Profile Picture">Profile Picture
                        </label>
                    </div> */}
                    <Row gutter={[16, 16]} align='center' justify='middle'>
                        <Col xs={24} sm={8} md={9} lg={6} xl={5} className='profile-image' style={{marginTop: '1.5rem', margin: 'auto', textAlign: 'center'}}>
                            <ImgCrop  modalTitle='Crop Image' rotate>
                                <Upload
                                    name={'file'}
                                    required
                                    className={"avatar-uploader profile-image ant-border-check"}
                                    accept={'image/*'}
                                    listType={"picture-card"}
                                    style={{width: '140px', height: '140px', objectFit: 'cover', borderRadius: '50%', border: '1px solid black'}}
                                    showUploadList={false}
                                    beforeUpload={(file) => {
                                        const isLt2M = file.size / 1024 / 1024 < 5;
                                        if (!isLt2M) {
                                            message.error('Image must smaller than 5MB!');
                                        } else {
                                            getBase64(file, imageUrl =>
                                                setImageUrl(imageUrl)
                                            );
                                            setFile(file)
                                            // forceUpdate();
                                        }
                                        return false;
                                    }}
                                    onRemove={(file) => {
                                        setFile(null);
                                        return true;
                                    }}

                                >
                                    {imageUrl ? <div>
                                            <EditRoundedIcon fontSize="large" style={{
                                                position: 'relative',
                                                zIndex: 50,
                                                color: 'white',
                                                backgroundColor: '#F04A22',
                                                top: 150,
                                                left: 45,
                                                padding: '0.4rem',
                                                borderRadius: '50%'
                                            }}/>
                                            <img loading="lazy"  src={imageUrl} alt="avatar"
                                                 style={{width: '155px', height: '155px', objectFit: 'cover', borderRadius: '50%', border: '1px solid black'}}/>
                                        </div> :
                                        <div style={{marginTop: 25,}}>
                                            <PlusOutlined/>
                                            <div>Upload</div>
                                        </div>
                                    }

                                </Upload>

                            </ImgCrop>
                        </Col>
                        <Col xs={0} sm={16} md={15} lg={18} xl={19} style={{paddingLeft: 40}}>
                            <Title style={{marginTop: '-1rem'}} level={2}>{user.firstname + " " + user.lastname}</Title>
                        </Col>
                        <Col xs={24} sm={0} md={0} lg={0} xl={0}>
                            <Title style={{marginTop: '-1rem', textAlign: 'center'}} level={2}>{user.firstname + " " + user.lastname}</Title>
                        </Col>
                    </Row>


                    <Row gutter={[16, 16]} style={{marginTop: '2rem'}}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="First Name" required
                                name="firstName"
                                rules={[
                                    {
                                        required: true,

                                        message: 'Please enter your first name',
                                    },
                                    {

                                        max: 10,
                                        message: 'First name should not be more than 10 characters',
                                    },
                                ]}
                            >
                                <Input
                                    size={"large"}
                                    placeholder="e.g John"
                                    style={{borderRadius: '0.3rem'}}
                                />
                            </Form.Item></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="Last Name" required
                                name="lastName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your last name',
                                    },
                                ]}
                            >
                                <Input
                                    size={"large"}
                                    style={{borderRadius: '0.3rem'}}
                                    placeholder="e.g John"

                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={12}> <Form.Item
                            label="Email address" required
                            name="email"

                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your email',
                                },
                                {
                                    type: 'email',
                                    message: 'Invalid email address format'
                                },
                            ]}
                        >
                            <Input
                                size={"large"}
                                style={{borderRadius: '0.3rem'}}
                                placeholder="john@doe.com"
                                type={"email"}

                            />
                        </Form.Item></Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="Phone number"
                                name="phone"
                                rules={[
                                    {
                                        required: tech ? true : false,
                                        pattern: new RegExp(/^[0-9+]{3}[0-9+]{3}[0-9+]{4}$/),
                                        message: "Phone number should be 10 digits"
                                    },
                                ]}
                            >

                                <Input
                                    size={"large"}

                                    placeholder="Phone Number"


                                />


                            </Form.Item>
                        </Col>
                        {tech && isFromProfileDetails &&

                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                label="Profile Bio" required
                                name="profileBio"
                                // tooltip={"This will show on your profile"}
                                initialValue={tech.bio}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your profile bio',
                                    },
                                    {
                                        min: 25,
                                        message: 'It should be at least 25 characters or more.',
                                    },
                                ]}
                            >
                                <Input.TextArea
                                    size={"large"}
                                    showCount={true}
                                    rows={5}
                                    placeholder="This will appear on your tech profile"
                                    // value={this.state.profileBio}
                                    // onChange={e => this.setState({profileBio: e.target.value})}
                                />
                            </Form.Item>
                        </Col>

                        }
                        {/* {tech &&

                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                label="Skills & Experience" required
                                name="skills"
                                // tooltip={"This will show on your profile"}

                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your skills and experience',
                                    },
                                    {
                                        min: 25,
                                        message: 'It should be at least 25 characters or more.',
                                    },
                                ]}
                            >
                                <Input.TextArea
                                    size={"large"}
                                    showCount={true}
                                    rows={5}
                                    placeholder="e.g I have over 7 years of professtional IT experience and have actively working in the industry since the start of my career..."
                                    // value={this.state.profileBio}
                                    // onChange={e => this.setState({profileBio: e.target.value})}
                                />
                            </Form.Item>
                        </Col>

                        } */}
                        {tech && isFromProfileDetails && <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                label={
                                <div className='profile-radius-label'>
                                    <Text>How far you are willing to travel for on-site services?</Text>
                                    {/* <Button className='edit-service-btn'>
                                        <Link to={{pathname:ROUTES.ACCOUNT_SETTING,  state:{service_location:true} }}>
                                            Edit Service Location
                                        </Link>
                                    </Button>    */}
                                </div>}
                                name="radius"
                                // initialValue={this.props.onboardingForm.radius}
                            >
                                <Row gutter={[10]}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                                        <Slider

                                            onChange={(radius) => {
                                                setRadius(radius)
                                            }}
                                            max={100}
                                            defaultValue={radius}
                                            trackStyle={{
                                                background: SECONDARY_COLOR
                                            }}
                                            handleStyle={{
                                                border: "2px solid " + SECONDARY_COLOR
                                            }}
                                        />
                                    </Col>
                                    <Col xs={8} sm={4} md={4} lg={4} xl={4}>{radius} miles</Col>

                                </Row>
                            </Form.Item>
                           
                        </Col>}
                        {tech && isFromProfileDetails && <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="Home service rate (per hour)"
                                name="homeRate"
                                // initialValue={this.props.onboardingForm.homeRate}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter hourly rate of home service',
                                    },
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (value >= 10) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('It should be at least $10'));
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    style={{width: '100%'}}
                                    onChange={(e) => {
                                        setHomeRate(e.target.value);
                                    }}
                                    type={"number"}
                                    prefix={"$"}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        }
                        {tech && isFromProfileDetails && <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item
                                label="Remote service rate (per hour)"
                                name="remoteRate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter hourly rate of remote service',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value >= 10) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('It should be at least $10'));
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    style={{ width: '100%' }}
                                    onChange={(e) => {
                                        setRemoteRate(e.target.value)
                                    }}
                                    type={"number"}
                                    prefix={"$"}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        }
                        {tech && isFromProfileDetails && <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Typography.Text>
                                These rates will appear on your tech profile for Custom Support Tickets. Remote service rate will be used for both Web Conferencing and Remote Access services.
                            </Typography.Text>
                        </Col>
                        }
                        {tech && isFromProfileDetails && <Divider className='horizontal-line'/>}
                        {tech && isFromProfileDetails && (
                            <Col xs={24}>
                                <Space className='justify-content-between align-items-center w-100 mb-3' id='work-history'>
                                    <Title level={4} className="mb-0">Work History</Title>
                                    {!!workHistoryAndProjects?.workHistory.length && (
                                        <Button type='primary' onClick={() => handleAddClick('workHistory')}><PlusOutlined /> Add New Work History</Button>
                                    )}
                                </Space>
                                {workHistoryAndProjects?.workHistory.length ? (
                                    <Collapse accordion className='account-settings-tech-info-wrapper'>
                                        {sortDates(workHistoryAndProjects.workHistory).map(item => (
                                            <Panel header={capitalizeFirstLetter(item.Name)} key={item.id} extra={(
                                                <Space>
                                                    <Button onClick={(e) => handleEditClick(e,'workHistory',item)}>Edit</Button>
                                                        <Button loading={item.isLoading} onClick={(e) => {
                                                            e.stopPropagation();
                                                            setShowWorkHistoryModal(true)
                                                            setWorkHisDeleteId(item.id)
                                                        }}>
                                                            Delete
                                                        </Button>
                                                </Space>
                                            )}>
                                                <Row gutter={[24,24]}>
                                                    {item.Description && (
                                                        <Col xs={24} md={12}>
                                                            <Space direction='vertical'>
                                                                <Text strong>Description</Text>
                                                                <Text>{capitalizeFirstLetter(item.Description)}</Text>
                                                            </Space>
                                                        </Col>
                                                    )}
                                                    {item.Organization_Name && (
                                                        <Col xs={24} md={12}>
                                                            <Space direction='vertical'>
                                                                <Text strong>Company Name</Text>
                                                                <Text>{capitalizeFirstLetter(item.Organization_Name)}</Text>
                                                            </Space>
                                                        </Col>
                                                    )}
                                                    {item.Start_Date && (
                                                        <Col xs={24} md={12}>
                                                            <Space direction='vertical'>
                                                                <Text strong>Start Date</Text>
                                                                <Text>{item.Start_Date}</Text>
                                                            </Space>
                                                        </Col>
                                                    )}
                                                    <Col xs={24} md={12}>
                                                        <Space direction='vertical'>
                                                            <Text strong>End Date</Text>
                                                            <Text>{item.End_Date || 'Present'}</Text>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </Panel>
                                        ))}
                                    </Collapse>
                                ) : isWorkHistoryAndProjectsLoading ? <Skeleton className='my-2' /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<Row className='mt-3 text-center w-100'>
                                    <Title level={5} className="mb-0 text-center w-100">Add Your Work History</Title>
                                    <Text type='secondary' className="text-center w-100">
                                        Add information about your previous employments or job experiences. This information will appear on your technician's profile.
                                    </Text>
                                </Row>}>
                                    <Button type='primary' onClick={() => handleAddClick('workHistory')}><PlusOutlined /> Add New Work History</Button>
                                    </Empty>}
                                
                                <Modal visible={showWorkHistoryModal} centered closable={false}
                                    onCancel={(e) => setShowWorkHistoryModal(false)}
                                    okText="Delete"
                                    onOk={() => handleDeleteClick('workHistory', workHisDeleteId)}
                                >
                                    <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
                                    <Paragraph className='my-2 disclaimer p-2'>
                                    This action will permanently delete this work history and cannot be undone. Please make sure you want to delete the item before proceeding. 
                                    </Paragraph>
                                </Modal>
                            </Col>
                        )}
                        {tech && isFromProfileDetails && (
                            <Col xs={24}>
                                    <Space className='justify-content-between align-items-center w-100 mb-3' id='new-project'>
                                        <Title level={4} className="mb-0">IT Projects</Title>
                                        {!!workHistoryAndProjects?.itProjects.length && (
                                            <Button type='primary' onClick={() => handleAddClick('itProjects')}><PlusOutlined /> Add New IT Project</Button>
                                        )}
                                    </Space>
                                    {workHistoryAndProjects?.itProjects.length ? (
                                        <Collapse accordion className='account-settings-tech-info-wrapper'>
                                            {workHistoryAndProjects.itProjects.map(item => (
                                                <Panel header={capitalizeFirstLetter(item.Name)} key={item.id} extra={(
                                                    <Space>
                                                        <Button onClick={(e) => handleEditClick(e,'itProjects',item)}>Edit</Button>
                                                            <Button loading={item.isLoading} onClick={(e) => {
                                                                e.stopPropagation()
                                                                setShowItProjectDeleteModal(true)
                                                                setItProjectDeleteId(item.id)
                                                                // Modal.confirm({
                                                                //     title: 'TecMe',
                                                                //     content: "Are you sure you want to delete this IT project?",
                                                                //     okText: 'Yes',
                                                                //     cancelText: 'No',
                                                                //     onOk() {
                                                                        
                                                                //     }
                                                                // });
                                                            }}>Delete</Button>
                                                    </Space>
                                                )}>
                                                    <Row gutter={[24,24]}>
                                                        {item.Description && (
                                                            <Col xs={24} md={12}>
                                                                <Space direction='vertical'>
                                                                    <Text strong>Description</Text>
                                                                    <Text>{capitalizeFirstLetter(item.Description)}</Text>
                                                                </Space>
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Panel>
                                            ))}
                                        </Collapse>
                                    ) : isWorkHistoryAndProjectsLoading ? <Skeleton className='my-2' /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                        <Row className='mt-3 text-center w-100'>
                                            <Title level={5} className="mb-0 text-center w-100">Add Your IT Projects</Title>
                                            <Text type='secondary' className="text-center w-100">
                                                Specify projects you have programmed or worked on in IT field. This information will appear on your technician's profile.
                                            </Text>
                                        </Row>
                                    }>
                                        <Button type='primary' onClick={() => handleAddClick('itProjects')}><PlusOutlined /> Add New IT Project</Button>
                                    </Empty>}
                                    <Modal visible={showItProjectDeleteModal} centered closable={false}
                                    onCancel={(e) => setShowItProjectDeleteModal(false)}
                                    okText="Delete"
                                    onOk={() => handleDeleteClick('itProjects', itProjectDeleteId)}
                                    >
                                        <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
                                        <Paragraph className='my-2 disclaimer p-2'>
                                        This action will permanently delete this IT project and cannot be undone. Please make sure you want to delete the item before proceeding. 
                                        </Paragraph>
                                    </Modal>
                            </Col>
                        )}
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            {info && <Alert message="No changes found." type="info"/>}

                        </Col>
                        {tech && isFromProfileDetails && <Divider className='horizontal-line'/>}

                        <Col xs={24} sm={24} md={24} lg={20} xl={18}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Button type={"primary"} loading={loading} htmlType="submit" style={{ marginBottom: 0}}>
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
                {addEditModalInfo.isOpen && (
                    <AddEditModal
                        isOpen={addEditModalInfo.isOpen}
                        closeAddEditModal={() => {
                                setAddEditModalInfo({
                                isOpen: false,
                                data: undefined,
                                mode: undefined,
                                key: undefined
                            })
                            history.push(location.pathname)
                        }}
                        mode={addEditModalInfo.mode}
                        data={addEditModalInfo.data}
                        addEditModalInfoKey={addEditModalInfo.key}
                        editProfileRepository={editProfileRepository}
                        updatedWorkHistoryAndProjects={updatedWorkHistoryAndProjects}
                    />
                )}
            </div>
        </Content>
    )
}

export default EditProfile
