// External Imports
import { useState, useEffect } from 'react'
import { Button,  Space,  Typography, Layout, Tabs, Tag, Popover, Select, Input, Result, notification, Form, Modal, Dropdown, Radio, Switch } from 'antd'
import { DollarCircleOutlined, DownOutlined, ExclamationCircleOutlined, ProfileOutlined, SettingOutlined, SolutionOutlined } from '@ant-design/icons'
import { MetaTags } from 'react-meta-tags'
import { useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router'
import { capitalizeFirstLetter, getSpecializationExample } from '../../../../../../utils/helper'

// Internal Imports
import { META_DESCRIPTION } from '../../../../../../utils/constants'
import ROUTES from '../../../../../../utils/routes'
import PageHeaderWrapper from '../../../../../components/page-header-breadcrumbs'

// Assets Imports
import '../assets/css/create-specialization.scss'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import PageFallback from '../../../../../components/page-fallback'




const {useForm} = Form 
const { Content } = Layout
const { TabPane } = Tabs
const { Title, Paragraph, Text } = Typography;
const { Option, OptGroup } = Select;
const { TextArea } = Input

const UpdateSpecialization = () => {
    const tech = useSelector((state) => state.tech);
    const { token } = tech
    const history = useHistory()
    const {state, search} = useLocation()
    const {specialization_id} = useParams()
    const [form] = useForm();
    
    const params = new URLSearchParams(search)

    const [showSuccessResult, setShowSuccessResult] = useState(false)
    const [updatingSpecialization, setUpdatingSpecialization] = useState(false)
    const [saveToDraft, setSaveToDraft] = useState(false)
    const [suggestedSkills, setSuggestedSkills] = useState([])
    const [showUnpublishedModal, setShowUnpublishedModal] = useState(false)
    const [loadingSpecializations, setLoadingSpecializations] = useState(false)
    const [specializationsData, setSpecializationsData] = useState([])
    const [workType, setWorkType] = useState(null)
    const [travelChecked, setTravelChecked] = useState(false)
    const [formValues, setFormValues] = useState({specialization_title: "", specialization_description: "", specialization_skills: []})

    const getSelectectedSpecialization = params.get('option') === 'create' ? state?.categorySkillsData?.find((item) => item.id ===(specialization_id)) : state?.categorySkillsData

    const isMaxPublishedSpecializations = specializationsData?.filter((item) => item.Active === "1")?.length >= 3

    const fetchSpecializations = () => {
        setLoadingSpecializations(true)
        ApiBaseHelper.get({
            url: `work-categories/specialization`, headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setSpecializationsData(res.data.data)
            setLoadingSpecializations(false)
        }).catch((error) => {
            console.error(error)
            setLoadingSpecializations(false)
        })       
    }


    const handleUpdateSpecializationSubmit = (values) => {
        setUpdatingSpecialization(true)
        console.log(values)
        if(!isMaxPublishedSpecializations && !saveToDraft || !isMaxPublishedSpecializations && saveToDraft || isMaxPublishedSpecializations && saveToDraft){	
            ApiBaseHelper.post({
                url: `work-categories/specialization`, 
                payload: {
                    Active: saveToDraft ? 'false' : 'true', 
                    Category: specialization_id,
                    Title: values?.specialization_title,
                    Introduction: values.specialization_description, 
                    Owner_Contact: tech.id, 
                    Skills: values?.specialization_skills.join("|"),
                    Specialization: true,
                    Work_Type: values?.specialization_work_type,
                    Hourly_Rate: Number(values?.hourly_rate),
                    Includes_Travel_Fee: travelChecked ? 1 : 0,
                },
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            }).then((res) => {
                if(res){
                    notification['success']({
                        message:"TecMe",
                        description: saveToDraft ? "Specialization saved as draft" : "Specialization created successfully" 
                    })
                    setUpdatingSpecialization(false)
                    if(saveToDraft === true){
                        history.push(ROUTES.MANAGE_SKILLS)
                    }else{
                        setShowSuccessResult(true)
                    }        
                }
            }).catch((error) => {
                console.log(error)
                setUpdatingSpecialization(false)
                notification['error']({
                    message: "TecMe",
                    description:"An Error Occurred"
                })
            })
        }
        else{
            setUpdatingSpecialization(false)
            notification['error']({
                message: "TecMe",
                description:"You can't publish more than 3 specializations"
            })
        }
    }

    const handleEditSpecializationSubmit = (values) => {
        setUpdatingSpecialization(true)
        if(!isMaxPublishedSpecializations && !saveToDraft || !isMaxPublishedSpecializations && saveToDraft || isMaxPublishedSpecializations && saveToDraft || getSelectectedSpecialization?.Active === "1"){	
            ApiBaseHelper.put({
                url: `work-categories/specialization/${specialization_id}`, 
                payload: {
                    Active: saveToDraft ? 'false' : 'true', 
                    Category: getSelectectedSpecialization?.Category?.id,
                    Title: values?.specialization_title,
                    Introduction: values.specialization_description, 
                    Skills: values?.specialization_skills.join("|"),
                    Work_Type: values?.specialization_work_type,
                    Hourly_Rate: Number(values?.hourly_rate),
                    Includes_Travel_Fee: travelChecked ? 1 : 0,
                },
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            }).then((res) => {
                if(res){
                    notification['success']({
                        message:"TecMe",
                        description: saveToDraft && getSelectectedSpecialization?.Active === "1" ? "Specialization unpublished successfully" : saveToDraft && getSelectectedSpecialization?.Active === "0" ? "Draft updated successfully" : !saveToDraft && getSelectectedSpecialization?.Active === "0" ? "Specialization published successfully" : "Specialization updated successfully" 
                    })
                    setUpdatingSpecialization(false)
                    setShowUnpublishedModal(false)
                    if(!saveToDraft && getSelectectedSpecialization?.Active === "0"){
                        setShowSuccessResult(true)
                    }else{
                        history.push(ROUTES.MANAGE_SKILLS)  
                    }
                }
            }).catch((error) => {
                console.log(error)
                setUpdatingSpecialization(false)
                notification['error']({
                    message: "TecMe",
                    description:"An Error Occurred"
                })
            })
        } else{
            setUpdatingSpecialization(false)
            notification['error']({
                message: "TecMe",
                description:"You can't publish more than 3 specializations"
            })
        }
    }
    
    useEffect(() => {
        if(params.get('option') === 'edit'){
            if(getSelectectedSpecialization?.Includes_Travel_Fee === "1"){
                setTravelChecked(true)
            }
            form.setFieldsValue({
                specialization_title: getSelectectedSpecialization?.Title,
                specialization_description: getSelectectedSpecialization?.Introduction,
                specialization_skills:getSelectectedSpecialization?.Skills.split("|"),
                specialization_work_type:getSelectectedSpecialization?.Work_Type,
                hourly_rate:getSelectectedSpecialization?.Hourly_Rate,
            })
            setSuggestedSkills(getSelectectedSpecialization?.Skills.split("|"))
        }else{
            form.resetFields()
        }
    }, [params.get('option')])

    useEffect(() => {
        if(workType === "Remote"){
            setTravelChecked(false)
        }
    }, [workType])

    useEffect(() => {
        if(getSelectectedSpecialization){
            setWorkType(getSelectectedSpecialization?.Work_Type)
        }
    }, [getSelectectedSpecialization])


    const specializationExamples = getSpecializationExample(getSelectectedSpecialization?.Title.toLowerCase())?.map((item) => {
        return({
            key: item,
            label: item
        })
    })

    useEffect(() => { 
        fetchSpecializations()
        window.scrollTo(0, 0)
    },[])

    useEffect(() => { 
        fetchSpecializations()
        window.scrollTo(0, 0)
    },[showSuccessResult])

    

 
    return (
    <>
        <MetaTags>
            <title>Update Specialization | TecMe </title>
            <meta name="description" content={META_DESCRIPTION}/>
            <meta name="robots" content="noindex, nofollow"/>
        </MetaTags>
        <Content className="main">
            <div className="page">
                <div style={{minHeight: '800px'}}>
                    <PageHeaderWrapper
                        className="tech-skill-page-breadcrumb-header"
                        routes={[
                            {
                                path:  ROUTES.MANAGE_SKILLS,
                                breadcrumbName: 'Work Preferences',
                            },
                            params.get('option') === 'create' && {
                                path:  ROUTES.CREATE_SPECIALIZATION,
                                breadcrumbName: 'Create Specialization',
                            },
                            {
                                breadcrumbName: capitalizeFirstLetter(getSelectectedSpecialization?.Title),
                            },
                        ]}
                    />
                    <div className='auth-container'>
                        {
                            showSuccessResult ?
                            <div className="auth-page-ctn success-result-ctn">
                                {
                                    loadingSpecializations ? <PageFallback /> :
                                    <CreateSpecializationSuccess tech={tech} isMaxPublishedSpecializations={isMaxPublishedSpecializations}/>
                                }
                              
                            </div> :
                            <div className='create-specialization-ctn skills-ctn'>
                            <div className="create-specialization-top">
                                <Title level={4}>{capitalizeFirstLetter(getSelectectedSpecialization?.Title)}</Title>
                                <Paragraph style={{fontSize:16, margin:0}}>{ capitalizeFirstLetter(getSelectectedSpecialization?.Description)}</Paragraph>
                            </div>
                            <div className="create-specialization-body">
                                <Form
                                    form={form}
                                    onFinish={(values) => { 
                                        params.get('option') === "create" ?   handleUpdateSpecializationSubmit(values) : handleEditSpecializationSubmit(values)}
                                    }
                                    >
                                    <div className="specialization-title-section">
                                        <Space className='d-flex align-items-center'>
                                            <SettingOutlined style={{fontSize:20, color:"#F04A22"}}/>
                                            <Title level={4} style={{margin:0}}>Specialization Title</Title>
                                        </Space>
                                        <Paragraph style={{fontSize:16, color:"rgba(0, 0, 0, 0.6)"}}>Mention your main specialization</Paragraph>
                                        <Form.Item
                                            name="specialization_title"
                                            rules={[
                                                { required: true, message: 'Please enter title'}, 
                                            ]}
                                        >
                                            <Input className='specialization-input' placeholder='E.g. Business Continuity Manager'/>
                                        </Form.Item>
                                        {
                                            specializationExamples.length > 0 &&
                                            <Dropdown
                                                trigger={['click']}
                                                menu={{
                                                    items:specializationExamples,
                                                    onClick: ({ key, domEvent }) => {
                                                        domEvent.preventDefault();
                                                        form.setFieldsValue({
                                                            specialization_title: key
                                                        })
                                                    },
                                                }}
                                            >
                                                <a onClick={(e) => e.preventDefault()}>
                                                <Space style={{color:"#F04A22"}}>
                                                    Examples
                                                    <DownOutlined />
                                                </Space>
                                                </a>
                                            </Dropdown>
                                        }
                                    </div>
                                    <div className="specialization-desc-section">
                                        <Space className='d-flex align-items-center'>
                                            <SolutionOutlined style={{fontSize:20, color:"#F04A22"}}/>
                                            <Title level={4} style={{margin:0}}>Specialization Description</Title>
                                        </Space>
                                        <Paragraph style={{fontSize:16, color:"rgba(0, 0, 0, 0.6)"}}>Describe specialization in detail and help employers understand your capabilities.</Paragraph>
                                        <Form.Item
                                            name="specialization_description"
                                            rules={[
                                                { required: true, message: 'Please enter description'},
                                                { min: 200, message: 'Minimum of 200 characters allowed'},
                                                { max: 1500, message: 'Maximum of 1500 characters allowed.' }, 
                                            ]}
                                        >
                                            <TextArea 
                                                showCount 
                                                maxLength={1500} 
                                                rows={5} 
                                                placeholder={"• minimum 200 characters \n• describe your expertise \n• mention your differentiations"}/>
                                        </Form.Item>
                                    </div>
                                    <div className='specialization-work-type-section'>
                                        <Space className='d-flex align-items-center'>
                                            <SolutionOutlined style={{fontSize:20, color:"#F04A22"}}/>
                                            <Title level={4} style={{margin:0}}>Work type</Title>
                                        </Space>
                                        <Paragraph style={{fontSize:16, color:"rgba(0, 0, 0, 0.6)"}}>Please select how you’re willing to help clients.</Paragraph>
                                        <div className="work-type-radios">
                                            <Form.Item
                                                name="specialization_work_type"
                                                // rules={[
                                                //     { required: true, message: 'Please select work type'},
                                                // ]}
                                            >
                                                <Radio.Group onChange={(e) => setWorkType(e.target.value)}>
                                                    <Space direction="vertical">
                                                        <Radio value="Both">Remote & On-site</Radio>
                                                        <Radio value="Remote">Remote</Radio>
                                                        <Radio value="On-Site">On-site</Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="specialization-horly-rate-section">
                                        <Space className='d-flex align-items-center'>
                                            <DollarCircleOutlined style={{fontSize:20, color:"#F04A22"}}/>
                                            <Title level={4} style={{margin:0}}>Hourly rate</Title>
                                        </Space>
                                        <Paragraph style={{fontSize:16, color:"rgba(0, 0, 0, 0.6)"}}>Enter your hourly rate</Paragraph>
                                        <Form.Item
                                            name="hourly_rate"
                                            rules={[
                                                { required: true, message: 'Please enter your hourly rate'}, 
                                            ]}
                                        >
                                            <Input prefix={<Text style={{color:"rgba(0, 0, 0, 0.25)"}}>$</Text>}  className='specialization-input' placeholder='50'/>
                                        </Form.Item>
                                        {
                                            (workType !== "Remote") &&
                                            <div className='onsite-fee-section'>
                                                <Title level={5} style={{margin:0}}>Travel fee</Title>
                                                <Paragraph style={{fontSize:16, color:"rgba(0, 0, 0, 0.6)"}}>Travel fee covers the costs associated with travel to and from your client. Will you charge your clients for travel costs? (this information will be displayed on your public profile)</Paragraph>
                                                <Form.Item
                                                    name="travel_fee"
                                                >
                                                    <Switch checked={travelChecked} checkedChildren="YES" onChange={(checked) => setTravelChecked(checked)}/>
                                                </Form.Item>
                                            </div>
                                        }
                                    </div>
                                    <div className="specialization-skills-section">
                                        <Space className='d-flex align-items-center'>
                                            <ProfileOutlined style={{fontSize:20, color:"#F04A22"}}/>
                                            <Title level={4} style={{margin:0}}>Skills</Title>
                                        </Space>
                                        <Paragraph style={{fontSize:16, color:"rgba(0, 0, 0, 0.6)"}}>Selecting relevant skills can make your profile stand out to potential clients, helping you find jobs that match your expertise and interests while building a successful career.</Paragraph>
                                        <div className='skills-input-tags'>
                                            <Form.Item
                                                name="specialization_skills"
                                                className='mb-4'
                                                rules={[
                                                    { required: true, message: 'Please add a skill'}, 
                                                ]}
                                            >
                                                <Select 
                                                    mode="tags"
                                                    placeholder="Add skills"
                                                    popupClassName='add-skills-select'
                                                    onChange={() => {}}
                                                    // defaultValue={suggestedSkills}
                                                    listHeight={264}
                                                    virtual={false}
                                                    onDeselect={(value) => {
                                                        setSuggestedSkills(suggestedSkills.filter((ele) => ele !== value))
                                                    }}
                                                    onSelect={(value) => {
                                                        if(suggestedSkills.includes(value)) return
                                                        setSuggestedSkills([...suggestedSkills, value])
                                                        form.setFieldsValue({
                                                            specialization_skills: [...suggestedSkills, value]
                                                        })
                                                    }}
                                                    options={ 
                                                        params.get('option') === 'create' ?
                                                        getSelectectedSpecialization['other_skills'].map((ele) => {
                                                            return {
                                                                label: ele,
                                                                value: ele 
                                                            }
                                                        }) :
                                                        getSelectectedSpecialization['Skills'].split("|").map((ele) => {
                                                            return {
                                                                label: ele,
                                                                value: ele 
                                                            }
                                                        })
                                                    }
                                                />
                                            </Form.Item>
                                            {
                                                (params.get('option') === 'create' && !!getSelectectedSpecialization['other_skills'].length) &&
                                                <>
                                                    <Paragraph style={{marginBottom:5, fontSize:14, fontWeight:500, color:"color: rgba(0, 0, 0, 0.85)"}}>Suggested:</Paragraph>
                                                    {
                                                        getSelectectedSpecialization['other_skills'].map((ele) => <Tag
                                                         className='suggested-tag cursor-pointer'
                                                         onClick={() => {
                                                            if(suggestedSkills.includes(ele)) return
                                                            setSuggestedSkills([...suggestedSkills, ele])
                                                            form.setFieldsValue({
                                                                specialization_skills: [...suggestedSkills, ele]
                                                            })
                                                            // getSelectectedSpecialization['other_skills'] = getSelectectedSpecialization['other_skills'].filter((skill) => skill !== ele)
                                                            }}
                                                         >
                                                            {ele}
                                                            </Tag>)
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <Form.Item>
                                        <Space size={24} style={{paddingRight:24}} className='mt-3 mb-5 w-100 justify-content-end'>
                                            {
                                                params.get('option') === 'edit' ?  
                                                <Button type='secondary' style={{height:40, fontSize:16}} className="neutral-btn" onClick={() => {
                                                    getSelectectedSpecialization?.Active === "0" ? setSaveToDraft(false) : setSaveToDraft(true)
                                                    setShowUnpublishedModal(true)
                                                    setFormValues(form.getFieldsValue())
                                                }}>
                                                    { getSelectectedSpecialization?.Active === "0" ? "Publish" : "Unpublish"}
                                                </Button> :
                                                 <Button type='secondary' htmlType='submit' style={{height:40, fontSize:16}} className="neutral-btn" onClick={() => setSaveToDraft(true)} loading={updatingSpecialization && saveToDraft===true}>
                                                    Save to Draft
                                                </Button>
                                            }
                                            <Button type="primary" htmlType="submit" style={{height:40, fontSize:16}} onClick={() => {
                                                getSelectectedSpecialization?.Active === "0" ?setSaveToDraft(true) : setSaveToDraft(false)
                                                }} 
                                                loading={(!showUnpublishedModal && updatingSpecialization && saveToDraft===false) || (!showUnpublishedModal && updatingSpecialization && getSelectectedSpecialization?.Active === "0")}>
                                                {params.get('option') === 'edit' ?  'Save changes' : 'Publish'}
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Form>
                            </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Content> 
        <Modal
            open={showUnpublishedModal} 
            okText="Yes"
            cancelText="No"
            onOk={() => {
                handleEditSpecializationSubmit(formValues)
            }} 
            okButtonProps={{loading: updatingSpecialization}}
            cancelButtonProps={{type:'secondary'}}
            onCancel={() => {
                setShowUnpublishedModal(false)
            }}
            width={555}
            closable={false}
            centered
            className='confirm-specialization-modal'
        >
            <Space align='start'>
                <ExclamationCircleOutlined style={{fontSize:22, color:"#FAAD14"}} />
                <Typography.Paragraph style={{marginBottom:0, fontSize:16, fontWeight:500, color:"rgba(0, 0, 0, 0.85)"}}>{getSelectectedSpecialization?.Active === "1" ? "Are you sure you want to unpublish this specialization?" : "Are you sure you want to publish this specialization?"}</Typography.Paragraph>
            </Space>
        </Modal> 
    </>
  )
}

const CreateSpecializationSuccess = ({tech, isMaxPublishedSpecializations}) => {
    const history = useHistory()

    return(
        <div>
            <Result
                status="success"
                title={<Title level={3}>Congratulations!</Title>}
                subTitle={<Paragraph className='congratulations-info'>Your IT skills and expertise have been successfully published to your profile page. You can now showcase your abilities to potential employers. Don't forget to keep your information up-to-date to ensure that it accurately reflects your current skills and experience.</Paragraph>}
                extra={[
                    <Button type='secondary' style={{height:40, fontSize:16}} className="neutral-btn" onClick={() =>  history?.push(ROUTES.TECH_PROFILE_WITH_ID.replace(':id', tech?.id))}>
                        View specializations
                    </Button>,     
                    !isMaxPublishedSpecializations &&  <Button type="primary" htmlType="submit" style={{height:40, fontSize:16}} onClick={() => history.push(ROUTES.CREATE_SPECIALIZATION)}>
                        Add new specialization
                    </Button>
                    
                ]}
            />
        </div>
    )
}

export default UpdateSpecialization