export default {
    HOME: '/',
    DASHBOARD: '/account/dashboard',  
    LOGIN: '/login',
    TEAM_LOGIN: '/login/team/:orgId',
    SIGN_UP: '/sign-up',
    CLIENT_ONBOARDING: '/client-onboarding',
    GUEST_SIGN_UP: '/sign-up/guest',
    MANAGE_SCHEDULE: '/account/manage/schedule',
    JOB_POSTINGS: '/account/job-postings',
    JOB_APPLICATIONS: '/account/job-postings/applications',
    ADD_JOB_POSTING: '/account/job-postings/add',
    EDIT_JOB_POSTING: '/account/job-postings/edit/:id',
    JOB_POST: '/job-post/:id',
    SUBMIT_JOB_APPLICATION: '/job-post/apply/:title',
    MANAGE_JOB_POSTING: '/account/job-postings/:id',
    MANAGE_JOB_APPLICATIONS: '/account/job-applications',
    JOB_APPLICATION_DETAILS:  '/account/job-applications/:id',
    VIEW_JOB_APPLICATION: '/job-application/:job_id/:application_id',
    EDIT_JOB_APPLICATION: '/job-application/edit/:job_id/:application_id',
    BROWSE_JOBS: '/browse-jobs',
    CERTIFICATION: '/account/manage/certifications',
    PROFILE_UPDATE: '/account/profile/edit',
    MANAGE_SKILLS: '/account/manage/work-preferences',
    CREATE_SPECIALIZATION:'/account/manage/work-preferences/create-specialization',
    UPDATE_SPECIALIZATION:'/account/manage/work-preferences/create-specialization/:specialization_id',
    MANAGE_PROFILE_DETAILS: '/account/manage/profile',
    MY_SERVICE: '/account/manage/services',
    TECH_PROFILE_NEW: '/tech/:profileUrl',
    TECH_IT_SERVICE: '/tech/:profileUrl/:serviceUrl',
    TECH_PROFILE_WITH_ID: '/profile/:id',
    ADD_NEW_SERVICE: '/account/service/new/:id/:booking',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    ADD_SERVICES: '/account/service/add',
    EDIT_SERVICES: '/account/service/edit',
    TECH_APPLICATION: '/tech-application',
    REPORTS: '/account/reports',
    ABOUT: '/about',
    IT_SOLUTIONS: '/it-solutions',
    // NEWSROOM: '/newsroom',
    // PRESS_ARCHIVES: '/press',
    LATEST_PRESS:'/press-room',
    PRESS_LISTINGS:'/press-archive',
    BECOME_A_TECH: '/become-a-tech',
    PRIVACY_POLICY: '/privacy-policy',
    TERM_OF_SERVICES: '/terms-of-service',
    CONTACT:'/contact-us',
    BUG_REPORT: '/bug-report',
    HIPA_TRAINING: '/hipaa-training',
    BOOK_SERVICES: '/book-service',
    INBOX: '/account/messages',
    INBOX_2: '/account/inbox',
    LOCATION: '/account/manage/locations',
    ADD_LOCATION: '/account/locations/add',
    EDIT_LOCATION: '/account/locations/edit',
    PAYMENT: '/account/manage/payment-methods',
    ADD_PAYMENT: '/account/payment-method/add',
    MANAGE_BOOKING: '/account/bookings',
    OLD_BOOKING_PAGE: '/account/bookings/archived',
    BOOKING_DETAILS: '/account/bookings/:id',
    BOOKING_DETAILS_NEW: '/account/booking/details/:id',
    SERVICE_DETAILS: '/account/service/details/:service_id/:booking_id',
    SERVICE_DETAILS_NEW: '/account/service/details/new/:service_id/:booking_id',
    INVOICE: '/booking/invoice/:id',
    NEW_INVOICE: '/booking/new/invoice/:id',
    DEVICES: '/account/devices',
    ADD_DEVICES_NEW: '/account/devices/add',
    EDIT_DEVICES_NEW: '/account/devices/edit',
    CHANGE_PASSWORD: '/account/change/password',
    VIDEO_CONFERENCING: '/call/:channel',
    STRIPE_CONNECT: '/stripe/connect',
    REVIEW_INVOICE: '/review/invoice/:id',
    ACCOUNT_SETTING: '/account/settings',
    CO_MARKETING_PLAN: '/grow',
    QUICK_BOOK: '/quick/book',
    BOOK_SUBSCRIPTION: '/book/subscription',
    EMAIL_VERIFICATION: '/verify-email/:code',
    DISCOVERY_EXPERIENCE: '/wizard',
    DISCOVERY_EXPERIENCE_SEARCH: '/instant-results',
    SAVED_PROFILES:'/saved-profiles',
    SAVED_PROFILES_DETAILS:'/saved-profiles/:id',
    TECH_STATES: '/states',
    TECH_STATE_AND_CITY: '/:state/:city/it-tech-support',
    INVOICE_LISTING: '/invoices/:type',
    CREATE_SEND_CONTRACT: '/tech/:profileUrl/contract/new',
    VIEW_EDIT_CONTRACT: '/account/contracts/:contractId',
    CONTRACTS: '/account/contracts',
    TICKET_DETAILS: '/account/ticket/:ticketId',
    TICKET_LISTING:'/account/tickets',
    TEAM_MANAGEMENT:'/account/team-management',
    ACCEPT_INVITE:'/invite/accept',
    SET_PASSWORD: '/set-new-password',
    IT_INDUSTRY: '/it-industry',

    ADMIN_LOGIN: '/admin/login',
    ADMIN_DASHBOARD: '/admin/dashboard',
    ADMIN_SERVICES: '/admin/services',
    ADMIN_ADD_SERVICE: '/admin/services/new',
    ADMIN_EDIT_SERVICE: '/admin/services/edit',
    ADMIN_USERS: '/admin/users',
    ADMIN_GROUP_CONVERSATIONS: '/admin/conversations',
    ADMIN_USER_DETAILS: '/admin/user/:id',
    ADMIN_PROMO: '/admin/promo',
    ADMIN_PROMO_ADD: '/admin/promo/add',
    ADMIN_PROMO_EDIT: '/admin/promo/edit',
    ADMIN_TECH_APPLICATION: '/admin/tech-application',
    ADMIN_TECH_APPLICATION_ADD: '/admin/tech-application/add',
    ADMIN_TECH_APPLICATION_EDIT: '/admin/tech-application/edit',
    ADMIN_SKILLS: '/admin/skills',
    ADMIN_SKILLS_ADD: '/admin/skills/add',
    ADMIN_SKILLS_EDIT: '/admin/skills/edit',
    ADMIN_MANAGE_BOOKING: '/admin/manage-booking',
    ADMIN_MANAGE_BOOKING_VIEW: '/admin/manage-booking/:id',
    ADMIN_SCHEDULED_SERVICES: '/admin/scheduled-service',
    ADMIN_SCHEDULED_SERVICES_VIEW: '/admin/scheduled-service/:id',
    ADMIN_CONTRACT_DETAILS: '/admin/contracts/:contractId',
    ADMIN_CONTRACTS: '/admin/contracts',
    ADMIN_TICKET_DETAILS: '/admin/tickets/:ticketId',

    // ITC routes
    ITC_HOME: '/itsc/web/:subdomain',
    CREATE_ITC: '/itsc/create',
    ITC_PROFILES: '/itsc/profiles',
    ITC_PROFILE: '/itsc/profiles/:id',
    ITC_INVITES_LISTING: '/itsc/invites',
    ITC_INVITE_DETAILS: '/itsc/invites/:id',
    DISCOVER_ITC: '/itsc/discover',
    ITC_DASHBOARD: '/itsc/dashboard/:id',
    ITC_TECH_TEAM: '/itsc/profiles/team/:id'
};