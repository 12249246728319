import React, { useEffect, useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import { META_DESCRIPTION, SECONDARY_COLOR } from '../../../../utils/constants'
import { Avatar, Button, Card, Col, Input, Layout, List, Radio, Row, Select, Space, Tabs, Typography } from 'antd'
import ApplicationCard from './components/application-card'
import { useDispatch, useSelector } from 'react-redux'
import { ApiBaseHelper } from '../../../../utils/api-base-helper'
import { capitalizeFirstWordOfEveryLetter, getLocalTimeFormat, getTableTimeFormat, nameFormat } from '../../../../utils/helper'
import CustomRenderEmpty from '../../../components/custom-render-empty'
import useWindowSize from '../../../../hooks/useWindowSize'
import AiIcon from '../../../../assets/images/ai-icon.svg'
import AiIconWhite from '../../../../assets/images/ai-icon-white.svg'
import { useConnector } from 'react-instantsearch-hooks-web'
import connectHits from 'instantsearch.js/es/connectors/hits/connectHits'
import { syncFilterCategories } from '../../../../redux/reducers/app'
import { getSpecializationTitle } from '../../common/private/job-post/utils/helper'
import { algoliaIndex } from '../../../../utils/algolia-helper'

const {Title, Paragraph, Text} = Typography
const {Search} = Input


const JobApplications = ({jobPost}) => {
    const user = useSelector((state) => state.user)
    // const conHits = useConnector(connectHits, props);
    const dispatch = useDispatch();
    const generalSkills = useSelector((state) => state.generalSkillsGlobal)
    const [jobApplications, setJobApplications] = useState([])
    const [suggestedList, setSuggestedList] = useState([])
    const [filteredApplications, setFilteredApplications] = useState([])
    const [filteredSuggestions, setFilteredSuggestions] = useState([])
    const [loadingApplications, setLoadingApplications] = useState(false)
    const [loadingSuggestions, setLoadingSuggestions] = useState(false)
    const [jobHistory, setJobHistory] = useState([])
    const [loadingHistory, setLoadingHistory] = useState(false)
    const [activeTab, setActiveTab] = useState('all-applications')
    const [activeSuggestedTab, setActiveSuggestedTab] = useState('suggested')
    const [applicationRadio, setApplicationRadio] = useState('review-applications')
    const [invitedCandidateIds, setInvitedCandidateIds] = useState([])
    const [allCandidateIds, setAllCandidateIds] = useState([])
    const { windowWidth } = useWindowSize() 



    const fetchJobApplications = () => {
        setLoadingApplications(true)
        ApiBaseHelper.get({
            url: `job-post/${jobPost?.id}/applications`, 
            headers: {
                Authorization: 'Bearer ' + user?.token
            }
        }).then((res) => {
            setJobApplications(res?.data?.data.sort((a, b) => new Date(b?.CreatedDate) - new Date(a?.CreatedDate)))
            setLoadingApplications(false)
        }).catch((error) => {
            console.error(error)
            setLoadingApplications(false)
        })       
    }

    const fetchJobHistory = () => {
        setLoadingHistory(true)
        ApiBaseHelper.get({
            url: `event-log/${jobPost?.id}`, 
            headers: {
                Authorization: 'Bearer ' + user?.token
            }
        }).then((res) => {
            setJobHistory(res?.data?.data?.sort((a, b) => new Date(b?.CreatedDate) - new Date(a?.CreatedDate)))
            setLoadingHistory(false)
        }).catch((error) => {
            console.error(error)
            setLoadingHistory(false)
        })       
    }

    const fetchSuggestedCandidates = () => {
        setLoadingSuggestions(true)
        const query = getSpecializationTitle(generalSkills, jobPost?.Category);

        // Perform search
        algoliaIndex.search("categories", {
            query: query,
        }).then(({ hits }) => {
            // Handle search results (hits) here
            setSuggestedList(hits)
            setLoadingSuggestions(false)
        }).catch(error => {
            // Handle errors here
            console.error('Error:', error);
            setLoadingSuggestions(false)
        });
    }

   

    const handleApplicationsSearch = (e) => {
        const value = e.target.value.toLowerCase()
        const filteredData = jobApplications.filter((item) => {
            return item.Contact?.Full_Name?.toLowerCase().includes(value) || item?.Cover_Letter?.toLowerCase().includes(value)
        })
        setFilteredApplications(filteredData)
    }

    const handleSuggestionsSearch = (e) => {
        const value = e.target.value.toLowerCase()
        const filteredData = suggestedList.filter((item) => {
            return item?.name?.toLowerCase().includes(value) || item?.bio?.toLowerCase().includes(value)
        })
        setFilteredSuggestions(filteredData)
    }

    useEffect(() => {
        fetchJobApplications()
        fetchJobHistory()
    }, [])


    useEffect(() => {
        setFilteredApplications(jobApplications.filter((item) => item?.Status !== "Invited"))
    }, [jobApplications])

    useEffect(() => {
        fetchSuggestedCandidates()
    }, [jobPost])

    useEffect(() => {
        setFilteredSuggestions(suggestedList)
    }, [suggestedList])

    useEffect(() => {
        const invitedIds = jobApplications?.filter((item) => item?.Status === "Invited")?.map((item) => item?.Applicant_Contact?.id)
        setInvitedCandidateIds(invitedIds)
    }, [jobApplications])

    useEffect(() => {
        const allIds = jobApplications?.map((item) => item?.Applicant_Contact?.id)
        setAllCandidateIds(allIds)
    }, [jobApplications])

    useEffect(() => {
       if(applicationRadio === "ai-candidates"){
            setActiveTab("suggested")
         }else{
            setActiveTab("all-applications")
        }
    }, [applicationRadio])


    const applicationTabs = [
       {
            id: 'all-applications',
            title: `All Applications (${filteredApplications?.length})`,
            content: (
                <div className='job-applications-content'>
                    <CustomRenderEmpty
                        description={"There are no applications"}
                        extra={
                            <Button 
                                onClick={() => setApplicationRadio("ai-candidates")} 
                            >
                               Invite Technician
                            </Button>
                        }
                    >
                        <List
                            dataSource={[...filteredApplications?.filter((item) => item?.Status === "Accepted"), ...filteredApplications?.filter((item) => (item?.Status !== "Accepted"))]}
                            loading={loadingApplications}
                            pagination={{
                                total:filteredApplications?.length,
                                defaultPageSize:8
                            }}
                            renderItem={
                                item => {
                                    return(
                                    <List.Item key={item?.id}>
                                        <ApplicationCard 
                                            item={item} 
                                            jobPost={jobPost}
                                            type="application"
                                            fetchJobApplications={fetchJobApplications}
                                        />
                                    </List.Item>
                                )}
                            }
                        /> 
                    </CustomRenderEmpty> 
                </div>
            )
       },
       {
            id: 'shortlisted-applications',
            title: `Shortlisted (${filteredApplications?.filter((item) => item?.custom_data?.Shortlist === 'true')?.length})`,
            content: (
                <div className='job-applications-content'>
                    <CustomRenderEmpty
                    description={"There are no shortlisted applications"}>
                        <List
                            dataSource={filteredApplications?.filter((item) => item?.custom_data?.Shortlist === 'true')}
                            loading={loadingApplications}
                            pagination={{
                                total:filteredApplications?.filter((item) => item?.custom_data?.Shortlist === 'true')?.length,
                                defaultPageSize:8
                            }}
                            renderItem={
                                item => {
                                    return(
                                    <List.Item key={item?.id}>
                                        <ApplicationCard 
                                            item={item} 
                                            jobPost={jobPost} 
                                            type="application"
                                            fetchJobApplications={fetchJobApplications}
                                        />
                                    </List.Item>
                                )}
                            }
                        /> 
                    </CustomRenderEmpty>      
                </div>
            )
        },
        {
            id: 'archived-applications',
            title: `Archived (${filteredApplications?.filter((item) => item?.custom_data?.Archive === 'true')?.length})`,
            content: (
                <div className='job-applications-content'>
                    <CustomRenderEmpty
                        description={"There are no archived applications"}>
                        <List
                            dataSource={filteredApplications?.filter((item) => item?.custom_data?.Archive === 'true')}
                            loading={loadingApplications}
                            pagination={{
                                total:filteredApplications?.filter((item) => item?.custom_data?.Archive === 'true')?.length,
                                defaultPageSize:8
                            }}
                            renderItem={
                                item => {
                                    return(
                                    <List.Item key={item?.id}>
                                        <ApplicationCard 
                                            item={item} 
                                            jobPost={jobPost}
                                            type="application"
                                            fetchJobApplications={fetchJobApplications}
                                        />
                                    </List.Item>
                                )}
                            }
                        /> 
                    </CustomRenderEmpty>
                </div>
            )
        }
    ]

    const suggestedTabs = [
        {
             id: 'suggested',
             title: `Suggested (${filteredSuggestions?.filter((item) => !allCandidateIds?.includes(item?.objectID))?.length})`,
             content: (
                 <div className='job-applications-content'>
                     <CustomRenderEmpty
                     description={"No Suggested Candidate"}>
                            <List
                                dataSource={filteredSuggestions?.filter((item) => !allCandidateIds?.includes(item?.objectID))}
                                loading={loadingSuggestions}
                                pagination={{
                                total:filteredSuggestions?.filter((item) => !allCandidateIds?.includes(item?.objectID))?.length,
                                defaultPageSize:8
                                }}
                                renderItem={
                                    item => {
                                        return(
                                        <List.Item key={item?.id}>
                                            <ApplicationCard 
                                                item={item} 
                                                jobPost={jobPost}
                                                type="suggestion"
                                                fetchJobApplications={fetchJobApplications}
                                            />
                                        </List.Item>
                                    )}
                                }
                         /> 
                     </CustomRenderEmpty> 
                 </div>
             )
        },
        {
            id: 'invited',
            title: `Invited (${filteredSuggestions?.filter((item) => invitedCandidateIds?.includes(item?.objectID))?.length})`,
            content: (
                <div className='job-applications-content'>
                    <CustomRenderEmpty
                    description={"No Invited Candidates"}>
                            <List
                            dataSource={filteredSuggestions?.filter((item) => invitedCandidateIds?.includes(item?.objectID))}
                            loading={loadingSuggestions}
                            pagination={{
                                total:filteredSuggestions?.filter((item) => invitedCandidateIds?.includes(item?.objectID))?.length,
                                defaultPageSize:8
                            }}
                            renderItem={
                                item => {
                                    return(
                                    <List.Item key={item?.id}>
                                        <ApplicationCard 
                                            item={item} 
                                            jobPost={jobPost}
                                            type="suggestion"
                                            subType="invited"
                                            fetchJobApplications={fetchJobApplications}
                                        />
                                    </List.Item>
                                )}
                            }
                        /> 
                    </CustomRenderEmpty> 
                </div>
            )
       },
     ]

    return (
        <>
            <MetaTags>
                <title>Job Applications - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <div className="job-applications-ctn">
                {/* <Title level={4}>Applications</Title> */}
                <div className="mb-3">
                    <Radio.Group 
                        defaultValue={applicationRadio} 
                        value={applicationRadio}
                        buttonStyle="solid"
                        onChange={(e) => setApplicationRadio(e.target.value)}
                        >
                        <Radio.Button 
                            value="review-applications"
                        >
                            { windowWidth > 991 ? 'Review Applications' : 'Applications'}
                        </Radio.Button>
                        <Radio.Button 
                            value="ai-candidates"
                        >
                            <Space>
                                {   applicationRadio !== "ai-candidates" ?
                                    <img src={AiIcon} alt="ai-icon" style={{marginRight:2}}/> :
                                    <img src={AiIconWhite} alt="ai-icon" style={{marginRight:2}}/>
                                }
                                <Text style={{color: applicationRadio === "ai-candidates" && "#FFF"}}> 
                                { windowWidth > 991 ? 'Suggested Candidates' : 'Suggestions'}
                                </Text>
                            </Space>
                        </Radio.Button>
                    </Radio.Group>
                </div>
                <Row gutter={[20, 20]} align={"stretch"}>
                  { 
                    applicationRadio === 'review-applications' ?
                    <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                       { 
                            windowWidth > 991 ?
                            <Tabs 
                                className='job-applications-tabs' 
                                tabBarExtraContent={
                                    jobApplications?.length > 0 &&
                                    <Search 
                                        placeholder='Search applications' 
                                        size='large' 
                                        style={{width:300}}
                                        onChange={handleApplicationsSearch}
                                    />
                                }
                            >
                                {applicationTabs.map((item) => {
                                    return (
                                        <Tabs.TabPane tab={item.title} key={item.title}>
                                            <div>
                                                {item.content}
                                            </div>
                                        </Tabs.TabPane>
                                    )
                                })}
                            </Tabs> :
                            <div className="mobile-application-list">
                                <div className="mobile-select-search">
                                   { jobApplications?.length > 0 &&
                                        <Search
                                            placeholder='Search applications'
                                            size='large'
                                            onChange={handleApplicationsSearch}
                                        />
                                    }
                                    <Select
                                        onChange={(value) => 
                                            setActiveTab(value)
                                        }
                                        style={{width:"100%", marginTop:20}}
                                        size='large'
                                        defaultValue={activeTab}
                                    >
                                        {applicationTabs.map((item) => {
                                            return (
                                                <Select.Option  
                                                    value={item.id} 
                                                    key={item.id}

                                                >
                                                    {item.title}
                                                </Select.Option>
                                            )
                                        })}
                                    </Select>
                                </div>
                                <div className="mobile-application-cards">
                                    {
                                        applicationTabs.find((item) => item.id === activeTab)?.content
                                    }
                                </div>
                            </div>
                        }
                    </Col> :
                    <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                        { 
                            windowWidth > 991 ?
                            <Tabs 
                                className='job-applications-tabs' 
                                tabBarExtraContent={
                                    suggestedList?.length > 0 &&
                                    <Search 
                                        placeholder='Search applications' 
                                        size='large' 
                                        style={{width:300}}
                                        onChange={handleSuggestionsSearch}
                                    />
                                }
                            >
                                {suggestedTabs.map((item) => {
                                    return (
                                        <Tabs.TabPane tab={item.title} key={item.title}>
                                            <div>
                                                {item.content}
                                            </div>
                                        </Tabs.TabPane>
                                    )
                                })}
                            </Tabs> :
                            <div className="mobile-application-list">
                                <div className="mobile-select-search">
                                    { suggestedList?.length > 0 &&
                                        <Search
                                            placeholder='Search applications'
                                            size='large'
                                            onChange={handleApplicationsSearch}
                                        />
                                    }
                                    <Select
                                        onChange={(value) => 
                                            setActiveSuggestedTab(value)
                                        }
                                        style={{width:"100%", marginTop:20}}
                                        size='large'
                                        defaultValue={activeSuggestedTab}
                                    >
                                        {suggestedTabs.map((item) => {
                                            return (
                                                <Select.Option  
                                                    value={item.id} 
                                                    key={item.id}

                                                >
                                                    {item.title}
                                                </Select.Option>
                                            )
                                        })}
                                    </Select>
                                </div>
                                <div className="mobile-application-cards">
                                    {
                                        suggestedTabs.find((item) => item.id === activeSuggestedTab)?.content
                                    }
                                </div>
                            </div>
                        }
                    </Col>
                    }
                    <Col xs={24} sm={24} md={24} lg={6} xl={6} className='job-history-col'>
                        <Card title='Job History' className='job-history-card'  style={{width:"100%"}}>
                            <List
                                dataSource={jobHistory}
                                loading={loadingHistory}
                                renderItem={
                                    item => {
                                        return(
                                        <List.Item key={item?.id}>
                                           <div className="history-item">
                                                <Space size={16}>
                                                    <Avatar size={37} src={item?.Contact?.imgUrl}>
                                                        {item?.Contact?.Full_Name.charAt(0)?.toUpperCase()}
                                                    </Avatar>
                                                    <div>
                                                        <Paragraph style={{fontSize:14, fontWeight:500, marginBottom:4}}>{capitalizeFirstWordOfEveryLetter(nameFormat(item?.Contact?.Full_Name))} {item?.Message}</Paragraph>
                                                        <Text style={{color:"rgba(0, 0, 0, 0.45)"}}>{getTableTimeFormat(item?.CreatedDate)}</Text>
                                                    </div>
                                                </Space>
                                           </div>
                                        </List.Item>
                                    )}
                                }
                            /> 
                            <Paragraph style={{color:"#989898", fontSize:14, textAlign:"center", margintop:20}}>- End of activity log -</Paragraph>
                        </Card>
                    </Col>
                </Row>
            </div>  
        </>
    )
}

export default JobApplications