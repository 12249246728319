import { EditOutlined } from '@ant-design/icons'
import { Button, Space, Tag, Typography, notification } from 'antd'
import React, { useEffect } from 'react'
import { useState } from 'react'
import EditJobModal from './edit-job-modal'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { capitalizeFirstLetter } from '../../../../../utils/helper'
import { getDurationInfo, getSpecializationTitle } from '../../../common/private/job-post/utils/helper'

const { Title, Paragraph } = Typography

const JobPreview = ({handleNextClick, setSelectedCategoryId, selectedCategoryId, allAvailableSkills, jobPostingData, setJobPostingData, loadingGeneratedSkills, generatedSkills, getGeneratedSkills, currentStep,  updatedJobPostingData, setUpdatedJobPostingData, user }) => {

    const [selectedSection, setSelectedSection] = useState("")
    const [loading, setLoading] = useState(false)
    const {job_title, job_description, job_category, job_payment_type, job_duration, job_skills, job_type, job_budget_to, job_budget_from, job_budget_fixed, job_generated_skills} = jobPostingData

    const getSelectectedSpecialization = allAvailableSkills?.find((item) => item.id === (selectedCategoryId))


    const handlePostJob = () => {
        setLoading(true)  
        const job_budget = updatedJobPostingData?.job_payment_type === 'Hourly' ? (updatedJobPostingData?.job_budget_from * 100) + " - " + (updatedJobPostingData?.job_budget_to * 100) : (updatedJobPostingData?.job_budget_fixed * 100)

        ApiBaseHelper.post({
            url: `job-post`,	
            headers: {
                Authorization: 'Bearer ' + user['token'],
            },
            payload: {
                Category: updatedJobPostingData?.job_category,
                Title: updatedJobPostingData?.job_title,
                Description: updatedJobPostingData?.job_description,
                Duration: updatedJobPostingData?.job_duration,
                Payment_Type: updatedJobPostingData?.job_payment_type,
                Skills_Required: updatedJobPostingData?.job_skills.join(', '),
                Status: "Active",
                Work_Type: updatedJobPostingData?.job_type,
                Budget: job_budget
            }
        }).then((res) => {
            if(res){
                if(jobPostingData?.job_files?.length > 0){
                    handleFileSubmit(res?.data?.data?.id)
                    return
                }
                // notification.success({
                //     message: 'TecMe',
                //     description: 'Job Post Created Successfully',
                // });
                handleNextClick()
            }
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            notification.error({
                message: 'TecMe',
                description: 'An error occured!',
            });
            console.log(err)
        })
    }

    const handleFileSubmit = (id) => {
        const payload = new FormData();
        if (jobPostingData?.job_files?.length) {
            jobPostingData?.job_files?.forEach((file) => {
                payload.append('file', file)
            });
        }
        ApiBaseHelper.post({
            url: `job-post/media/job-posting/${id}`,	
            headers: {
                Authorization: 'Bearer ' + user['token'],
            },
            payload: payload
        }).then((res) => {
            if(res){
                // notification.success({
                //     message: 'TecMe',
                //     description: 'Job Post Created Successfully',
                // });
                handleNextClick()
            }
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            notification.error({
                message: 'TecMe',
                description: 'An error occured!',
            });
            console.log(err)
        })
    }

    useEffect(() => {
        if(jobPostingData){
            setUpdatedJobPostingData({
                job_title: job_title,
                job_description: job_description,
                job_category: job_category,
                job_skills: job_skills,
                job_type: job_type,
                job_budget_from: job_budget_from,
                job_budget_to: job_budget_to,
                job_budget_fixed: job_budget_fixed,
                job_duration: job_duration,
                job_payment_type: job_payment_type,
                job_files: []
            })
        }
    }, [])

    return (
        <div className='job-preview-ctn'>
            <div className='job-preview-header'>
                <Title level={4}>Preview</Title>
                {/* <Button type='primary' style={{height:40}}  onClick={handlePostJob} loading={loading}>Post this job</Button>        */}
            </div>
            <div className="job-preview-content">
                <div className='job-preview-section'>
                    <Space>
                        <Title level={5} className='job-preview-title'>Title</Title>
                        <EditOutlined 
                            className='job-preview-icon' 
                            onClick={() => !loading && setSelectedSection("Title")}
                            style={{cursor: loading ? 'not-allowed' : 'pointer'}}
                        />
                    </Space>
                    <Paragraph className='job-preview-subtitle'>{capitalizeFirstLetter(updatedJobPostingData?.job_title)}</Paragraph>
                </div>
                <div className='job-preview-section'>
                    <Space>
                        <Title level={5} className='job-preview-title'>Description</Title>
                        <EditOutlined 
                            className='job-preview-icon' 
                            onClick={() => !loading && setSelectedSection("Description")}
                            style={{cursor: loading ? 'not-allowed' : 'pointer'}}
                            />
                    </Space>
                    {
                        updatedJobPostingData?.job_description?.split('\n').map((line, index) => (
                            <Paragraph key={index}>
                            {line}
                            </Paragraph>
                        ))
                    }
                </div>
                <div className='job-preview-section'>
                    <Space>
                        <Title level={5} className='job-preview-title'>Job category</Title>
                        <EditOutlined 
                        className='job-preview-icon' 
                        onClick={() => !loading && setSelectedSection("Job Category")}
                        style={{cursor: loading ? 'not-allowed' : 'pointer'}}
                        />
                    </Space>
                    <Paragraph className='job-preview-subtitle'>{getSpecializationTitle(allAvailableSkills, updatedJobPostingData?.job_category)}</Paragraph>
                </div>
                <div className='job-preview-section'>
                    <Space>
                        <Title level={5} className='job-preview-title'>Skills</Title>
                        <EditOutlined 
                        className='job-preview-icon' 
                        onClick={() => !loading && setSelectedSection("Skills")}
                        style={{cursor: loading ? 'not-allowed' : 'pointer'}}
                        />
                    </Space>
                    <div>
                        <Space wrap>
                            {
                                updatedJobPostingData?.job_skills.map((item, index) => {
                                    return(
                                        <Tag className='custom-ant-tag' key={index}>{item}</Tag>
                                    )
                                })
                            }
                        </Space>
                    </div>
                </div>
                <div className='job-preview-section'>
                    <Space>
                        <Title level={5} className='job-preview-title'>Work type</Title>
                        <EditOutlined 
                            className='job-preview-icon'  
                            onClick={() => !loading && setSelectedSection("Work Type")}
                            style={{cursor: loading ? 'not-allowed' : 'pointer'}}
                            />
                    </Space>
                    <Paragraph className='job-preview-subtitle'>{updatedJobPostingData?.job_type === "Both" ? 'Remote & On-site' : updatedJobPostingData?.job_type === "Remote" ? "Remote" : "On-site"}</Paragraph>
                </div>
                <div className='job-preview-section'>
                    <Space>
                        <Title level={5} className='job-preview-title'>Budget</Title>
                        <EditOutlined 
                            className='job-preview-icon' 
                            onClick={() => !loading && setSelectedSection("Project Budget")}
                            style={{cursor: loading ? 'not-allowed' : 'pointer'}}
                            />
                    </Space>
                    <Paragraph className='job-preview-subtitle'>{updatedJobPostingData?.job_payment_type === 'Hourly' ? `$${updatedJobPostingData?.job_budget_from}` + " - " + `$${updatedJobPostingData?.job_budget_to}` + " /hr" : `$${updatedJobPostingData?.job_budget_fixed} fixed cost` }</Paragraph>
                </div>
                <div className='job-preview-section'>
                    <Space>
                        <Title level={5} className='job-preview-title'>Project Duration</Title>
                        <EditOutlined className='job-preview-icon'  
                        onClick={() => !loading && setSelectedSection("Project Duration")}
                        style={{cursor: loading ? 'not-allowed' : 'pointer'}}
                        />
                    </Space>
                    <Paragraph className='job-preview-subtitle'>{getDurationInfo(job_duration).text + ' Project'}</Paragraph>
                </div>
                <div className='job-preview-btn-ctn'>
                    <Button type='primary' className='job-preview-btn' onClick={handlePostJob} loading={loading}>Post this job</Button>
                    {/* <Button type='secondary' className='job-preview-btn'>Save as draft</Button> */}
                </div>
            </div>
            <EditJobModal 
                title={selectedSection} 
                setSelectedSection={setSelectedSection} 
                setSelectedCategoryId={setSelectedCategoryId}
                allAvailableSkills={allAvailableSkills}
                jobPostingData={jobPostingData}
                setJobPostingData={setJobPostingData}
                selectedSection={selectedSection}
                selectedCategoryId={selectedCategoryId}
                loadingGeneratedSkills={loadingGeneratedSkills}
                generatedSkills={generatedSkills}
                getGeneratedSkills={getGeneratedSkills}
                currentStep={currentStep}
                updatedJobPostingData={updatedJobPostingData}
                setUpdatedJobPostingData={setUpdatedJobPostingData}
            />
        </div>
    )
}

export default JobPreview