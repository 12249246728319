// External Imports
import { useState, useEffect } from 'react'
import { Modal, Typography, Space, Image, Divider, Button } from 'antd'

// Internal Imports
import { capitalizeFirstLetter, nameFormat } from '../../../../../../../utils/helper'
import ReasonRadioList from './reason-radio-list'
import Bulb from '../../../../public/create-send-contract/assets/images/bulb.svg'
import {extendMoment} from "moment-range";
import Moment from "moment/moment";
import { useHistory, useLocation } from 'react-router-dom'
import { ApiBaseHelper } from '../../../../../../../utils/api-base-helper'

const { Text, Title, Paragraph } = Typography

const technicianPoints = [
    "The contract is successfully completed and ready to be paid.",
    "Customer is not willing to add any more support tickets.",
    "I can no longer continue this contract."
]

const customerPoints = [
    "The contract is successfully completed.",
    "I am willing to start a new contract with the technician.",
    "I can no longer continue this contract with the technician."
]

const moment = extendMoment(Moment);


const EndContractModal = ({ visible, onOk, onCancel, repository, contractId, contractDetails, getAndSetContractDetails, sendMessage, role, user, tech,upCommingInvoice, setSelectedTab }) => {
    const [loading, setLoading] = useState(false);
    const [rating, setRating] = useState(5);
    const [showDisclaimer, setShowDisclaimer] = useState(false);
    const [disclaimerData, setDisclaimerData] = useState({
        price: 0,
        duration: ''
    });
    const [showEndContractContent, setShowEndContractContent] = useState(false)
    const history = useHistory()
    const location = useLocation()
    const [ticketData, setTicketData] = useState([])

    const getAndSetUpcommingInvoice = async () => {
        const res = await repository.getUpcommingInvoice(contractId);
        if(res?.data?.data?.amount_due && res?.data?.data?.lines?.data?.[0]?.quantity) {
            const hours = Math.floor(res?.data?.data?.lines?.data?.[0]?.quantity / 60)
            const mins = res?.data?.data?.lines?.data?.[0]?.quantity % 60
            setDisclaimerData({
                price: res?.data?.data?.amount_due / 100,
                duration: hours ? `${hours}h ${mins}m` : `${mins}m`
            })
            setShowDisclaimer(true)
        }
        if(res?.data?.data?.next_payment_attempt){
            let upComingInvoiceData = res?.data?.data;
            // convert unix timestamp to moment and subtract 1 hour and convert it back to unix
            upComingInvoiceData.next_payment_attempt = moment(upComingInvoiceData.next_payment_attempt * 1000).subtract(1, 'hour').unix();
            upCommingInvoice(upComingInvoiceData);
        }
    }

    const isUnPaidTicketInvoice = !!ticketData.find((invoice) => invoice?.Status?.toLowerCase() === "resolved")
    const isAssignedTickets = !!ticketData.find((ticket) => ticket?.Status?.toLowerCase() === "assigned")

    useEffect(() => {
        getAndSetUpcommingInvoice()
    }, [])

    useEffect(() => {
        ApiBaseHelper.get({
            url: `ticket?role=${tech ? 'technician' : 'customer'}&contract=${contractId}`, 
            headers: {
                Authorization: 'Bearer ' + (user?.token || tech?.token)
            }
        }).then((res) => {
            setTicketData(res?.data.data)
        }).catch((error) => {
            console.error(error)
        })
    }, [user, tech])

    const handleOnFinish = (values) => {
        setLoading(true)
        repository.terminateContract(contractId, role , {
            ...values,
            // Rating: rating,
            User_Contact: role === 'customer' ? contractDetails?.Provider_Org?.HIPAA_Primary_Contact : contractDetails?.Customer_Org?.HIPAA_Primary_Contact
        }).then(async (res) => {
            if(res.data?.status === 'success') {
                const name = nameFormat(role === 'customer' ? `${user?.firstname} ${user?.lastname}` : `${tech?.firstname} ${tech?.lastname}`);
                const location = window.location.href;
                // await sendMessage(`${name} has ended your contract. ${values.Reason}<br><br> To view the details, simply click on the link below:<br> ${location}`)
                await getAndSetContractDetails()
            }
            setLoading(false)
            onOk()
        }).catch(error => {
            console.error(error)
            setLoading(false)
        })
    }

    const getBillingCycle = () => {
        let billingCycle = '/hr'
        if(contractDetails?.Type === 'Fixed') {
            switch (contractDetails?.Billing_Frequency) {
                case "Monthly":
                    billingCycle = '/month'
                    break;
                case "Weekly":
                    billingCycle = '/week'
                    break;
                case "Bi-weekly":
                    billingCycle = ' bi-weekly'
                    break;
                default:
                    break;
            }
        }

        return billingCycle
    }

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            footer={false}
            centered
            className='edit-contract-modal'
        >
            {
                (!isUnPaidTicketInvoice || showEndContractContent) ?
                <>
                    <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>End Contract</Title>
                    <Paragraph className='my-2 disclaimer p-2'>
                        Are you sure you want to end this contract? This action is irreversible. {isAssignedTickets ? "There are still assigned tickets under this contract." : ""} Please carefully review the terms and conditions of the contract before proceeding. 
                    </Paragraph>
                    <Space direction='vertical' className='w-100'>
                        <Text strong>{contractDetails?.Type == 'Fixed'? 'Retainer' : contractDetails?.Type == 'Per Ticket'? 'Per Issue' : contractDetails?.Type} Contract at ${Number(contractDetails?.Billing_Rate) / 100}{getBillingCycle()}</Text>
                        <Paragraph
                            className='m-0'
                            ellipsis={{
                                rows: 2,
                                expandable: false
                            }}
                        >
                            {capitalizeFirstLetter(contractDetails?.Description)}
                        </Paragraph>
                        {contractDetails?.Type === 'Hourly' && showDisclaimer && (
                            <Space className='w-100 disclaimer p-2' size={20} align="start">
                                <Image src={Bulb} preview={false} alt="bulb" width={28} height={28} className='ms-2' style={{ marginTop: 2 }} />
                                <Text>
                                    You will be charged <Text strong>${disclaimerData.price}</Text> for <Text strong>{disclaimerData.duration}</Text> immediately when ending the contract
                                </Text>
                            </Space>
                        )}
                        <Divider className='my-1' />
                        <Space direction='vertical' className='w-100'>
                            <Text strong>Select a reason for ending the contract:</Text>
                            <ReasonRadioList
                                loading={loading}
                                points={role === 'customer' ? customerPoints : technicianPoints}
                                name={"End Contract"}
                                handleOnFinish={handleOnFinish}
                                rating={rating}
                                setRating={setRating}
                                onCancel={onCancel}
                                setShowEndContractContent={setShowEndContractContent}
                                feedbackLabel={`Give feedback to ${nameFormat(role === 'customer' ? contractDetails?.Provider_Org?.Name : contractDetails?.Customer_Org?.Name)}`}
                            />
                        </Space>
                    </Space>
                </> :
                <>
                    <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Warning: Unpaid Invoices</Title>
                    <Paragraph className='my-2 disclaimer p-2'>
                    {  user ?
                        "You have unpaid invoices associated with this contract. Please settle all outstanding invoices before attempting to end the contract." :
                        "You have unpaid invoices associated with this contract. Ending the contract now will close all unpaid invoices and you will not receive any payment for them. Are you sure you want to proceed?"
                    }
                    </Paragraph>
                    <Space className='mt-3 w-100 justify-content-end'>
                        <Button 
                            type='text' 
                            onClick={() => {
                                onCancel()
                                setShowEndContractContent(false)
                                setSelectedTab("assigned_tickets")
                                history.push(`${location.pathname}?tickets=resolved`)
                            }}>
                            View Unpaid Invoices
                        </Button>
                        <Button 
                            type="primary"
                            onClick={() => setShowEndContractContent(true)}
                        >
                            Proceed
                        </Button>
                    </Space>
                </>
            }
        </Modal>
    )
}

export default EndContractModal