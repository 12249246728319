import tecmeLogo from '../assets/images/tecme-logo.png';
import device from '../assets/images/device-icon.png';

/*
    Middleware servers
 */

export const PROD_SERVER = "https://middleware.tecme.app";
export const DEV_SERVER = "https://devmw.tecme.app";
export const LOCAL_SERVER = "http://localhost:2000";
export const STAGING_SERVER = "https://stagingmw.tecme.app";
export const ACCEPTANCE_SERVER = "https://acceptmw.tecme.app";
export const DEFAULT_SERVER = PROD_SERVER;

/*
    Other constants
 */

export const META_DESCRIPTION = "TecMe empowers home and businesses with a nationwide network of IT professionals to solve any IT problem. From little issues to complex projects, TecMe has the technician(s) for you.";
export const META_IMAGE = "https://firebasestorage.googleapis.com/v0/b/tecme-mobile-app.appspot.com/o/meta-image.png?alt=media&token=2fc69ac9-22bc-4698-b81a-40d5d3eb2260";
export const META_IMAGE_TECH_PROFILE = "https://firebasestorage.googleapis.com/v0/b/tecme-mobile-app.appspot.com/o/meta-image-tech-profile.png?alt=media&token=1d4df329-ef18-412a-a6a6-c52def10133f";
export const DEVICEIMAGE = device;
export const DEFAULT_PROFILE_PICTURE = "https://firebasestorage.googleapis.com/v0/b/tecme-mobile-prod.appspot.com/o/assets%2Fmisc%2Fdefault_profile_picture.jpeg?alt=media&token=da3e8572-780c-4b89-9944-19b82d4dee3d";
export const TECME_LOGO = tecmeLogo;
export const PRIMARY_COLOR = '#001B55';
export const SECONDARY_COLOR = '#F04A22';
export const DATE_TIME_FORMAT = 'llll'; // Fri, 25 Aug 2021 | 3:30 PM
export const GTM_ID = "GTM-PDS62HD";
export const SAMPLE_HIPAA_ID = "OIASKD82L2-HIPXA";
export const TecMeLogoURL = "https://firebasestorage.googleapis.com/v0/b/tecme-mobile-app.appspot.com/o/favicon.png?alt=media&token=1b5c9cb8-44ae-4746-a19b-d905808d046c";
export const WINDOWS = ["microsoft", "asus", "dell", "hp", "acer", 'lg', 'motorola'];
export const MAC = ["apple"];
export const ANDROID = ["samsung", "google"];
export const WINDOW_CHROMEBOOK = ["lenovo"];
export const AMAZON = ["amazon"];
export const ALTERNATIVE_REACT_APP_GOOGLE_API_KEY = "AIzaSyAA104TGF5d0eM8WoEylk-SyFjtzO4v5nM"
export const GOOGLE_MAPS_API = "https://maps.googleapis.com/maps/api/geocode/json"
export const MINIMUM_NUMBER_OF_TECHS = 3
