import React, { Component } from 'react'
import {
    Avatar,
    Button,
    Card,
    Col,
    Form,
    Image,
    Input,
    List,
    message,
    Modal,
    notification,
    Popover,
    Row,
    Space,
    Spin,
    Tooltip,
    Typography,
    Upload
} from 'antd';
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import SmileOutlined from "@ant-design/icons/lib/icons/SmileOutlined";
import ExcelIcon from '../../real-time-messaging/assets/images/excel-icon.png';
import PdfIcon from '../../real-time-messaging/assets/images/pdf-icon.png';
import WordIcon from '../../real-time-messaging/assets/images/word-icon.png';
import PowerPointIcon from '../../real-time-messaging/assets/images/point-icon.png';
import DefaultFileIcon from '../../real-time-messaging/assets/images/default-file-icon.png';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import NewArchiveIcon from '../../real-time-messaging/assets/images/archive-icon.svg';
import { isDesktop, isMobile, isMobileOnly, isTablet } from "react-device-detect";
import { CloseCircleOutlined, LeftOutlined, LoadingOutlined, PhoneOutlined, SendOutlined, UploadOutlined, UsergroupAddOutlined, VideoCameraOutlined } from "@ant-design/icons";
import Picker from 'emoji-picker-react';
import Linkify from 'react-linkify';
import Chat from '../assets/images/chat.png'
import ReactHtmlParser from 'react-html-parser';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { extendMoment } from 'moment-range';
import { capitalizeFirstLetter, decryptString, encryptString, initiateWebConference, nameFormat, normalFile } from '../../../../../../utils/helper';
import Moment from 'moment';
import { FirebaseHelper, FirestoreHelper } from '../../../../../../utils/firebase-helper';
import { SAMPLE_HIPAA_ID, SECONDARY_COLOR } from '../../../../../../utils/constants';
import { FirebaseStorageHelper } from '../../../../../../utils/firebase-storage';
import ProgressBar from './progress-bar';
import ROUTES from '../../../../../../utils/routes';
import AddChatMemberModal from './add-chat-member-modal';
import { RtmRepository } from '../repository/rtm-repository';
import { ApiBaseHelper, BASE_URL } from '../../../../../../utils/api-base-helper';
import Axios from 'axios';
import AIButton from '../../../../../components/ai-button';
import AiChatModal from './ai-chat-modal';


const moment = extendMoment(Moment);
let participantsObject = {};
const { Title, Paragraph } = Typography;


export default class MessagesRightPaneView extends Component {
    unsubscribeGetMessagesListener = null;
    formRef = React.createRef();
    chatViewRef = React.createRef()

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            participants: [],
            messageLoading: true,
            conversationParticipantsModal: false,
            displayProgressBar: false,
            fileList: [],
            selectedImagesUid: [],
            selectedImagesFile: [],
            emojiKeyboardEnabled: false,
            file: null,
            participant: {
                "display_name": ""
            },
            openAddMemberModal: false,
            removingParticipant: false,
            activeTeamMembers: [],
            openAIModal: false,
            showAIResults: false,
            chatAiText: "",
            windowWidth: window.innerWidth,

        }
        this.messageCell = this.messageCell.bind(this);
        this.sendNewMessage = this.sendNewMessage.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.rtmRepository = new RtmRepository(this.props.user['token']);
        this.inputFocus = React.createRef();
    }

    scrollToBottom = () => {
        // this.chatViewRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    handleGetConversations = async () => {
        await FirebaseHelper.login();
        this.unsubscribeConversations = FirestoreHelper.getConversations({
            user_id: this.props.user['id'],
            snapshot: (querySnapshot) => {
                let conversations = [];
                let generalConversations = [];
                let pinnedgeneralConversations = [];
                let pinnedArchivedConversations = [];
                let archivedConversations = [];

                querySnapshot.forEach(async (doc) => {
                    let item = doc.data();
                    item.id = doc.id;
                    let archived = item.archived ? item.archived : [];
                    if (archived.includes(this.props.user.id)) {
                        if (item.marked_favorite.includes(this.props.user.id))
                            pinnedArchivedConversations.push(item)
                        else
                            archivedConversations.push(item);
                    } else {
                        if (item.marked_favorite.includes(this.props.user.id))
                            pinnedgeneralConversations.push(item)
                        else
                            generalConversations.push(item);
                    }

                });

                archivedConversations = pinnedArchivedConversations.concat(archivedConversations);
                generalConversations = pinnedgeneralConversations.concat(generalConversations);

                conversations = this.props.conversationStatus == 'Active' ? generalConversations : archivedConversations;

                if (this.state.activeConversation == null && conversations.length > 0) {
                    let paramId = this.props.location?.search?.includes("id") && this.props.location.search.slice(4);
                    let selectedConversation
                    if (paramId) {
                        selectedConversation = conversations.find(({ id }) => id === paramId)
                    }
                    this.props.getConversation(conversations.find(({ id }) => id === this.props.conversation.id));
                }
            }
        });
    }

    async componentDidMount() {
        // Fetch team members
        ApiBaseHelper.get({
            url: 'user-management/users',
            headers: {
                Authorization: 'Bearer ' + this.props.user?.token,
            },
        }).then((res) => {
            this.setState({ activeTeamMembers: res?.data?.data?.data.filter((item) => item.Status === 'Active') })
        })

        this.unsubscribeGetMessagesListener = FirestoreHelper.getMessages({
            conversation_id: this.props.conversation.id,
            snapshot: (querySnapshot) => {
                let data = [];
                querySnapshot.forEach(async (doc) => {
                    let item = doc.data();
                    data.push(item);
                });
                this.setState({
                    data,
                    messageLoading: false
                })
            }
        });

        // Remove current user from participant array so that we won't display it members section
        let participantIds = this.props.conversation.participants;
        let currentUserIdIndex = participantIds.indexOf(this.props.user.id);
        // if(currentUserIdIndex > -1 && this.props.conversation.group != null)
        //     participantIds.splice(currentUserIdIndex,1);

        let participantsResponse = await FirestoreHelper.getUsers(participantIds);
        let participants = [];
        participantsResponse.forEach((doc) => {
            let item = doc.data();
            if (item.hipaa_id != SAMPLE_HIPAA_ID)
                participants.push(item);
            participantsObject[item.hipaa_id] = item
        });



        let participantIds2 = JSON.parse(JSON.stringify(this.props.conversation.participants));
        if (currentUserIdIndex > -1)
            participantIds2.splice(currentUserIdIndex, 1);
        let participant2 = this.props.user;

        if (participantIds2[0] in participantsObject) {
            participant2 = participantsObject[participantIds2[0]];
        }
        this.setState({
            participants,
            participant: participant2
        });
        // this.inputFocus.current.focus();
        window.addEventListener('resize', this.handleWindowResize);
    }


    componentWillUnmount() {
        if (this.unsubscribeGetMessagesListener != null) {
            this.unsubscribeGetMessagesListener();
        }
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleWindowResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    };

    render() {
        let { data, messageLoading, participants, displayProgressBar, conversationParticipantsModal, emojiKeyboardEnabled, windowWidth } = this.state;
        let { conversation } = this.props;
        let archived = conversation.archived ? conversation.archived : [];
        let isArchived = archived.includes(this.props.user.id);
        let user = this.props.user;

        const teamOwner = this.state.activeTeamMembers.find((member) => member?.Role_Title === "Administrator" && member?.Invited_By === this.props.user?.id)

        const conversationOwner = conversation?.owners?.some((owner) => owner?.hipaa_id === this.props.user.id)
        const tempConversationOwner = conversationOwner === undefined
        const currentConversationOwner = conversation?.owners?.find((owner) => owner?.hipaa_id !== this.props.user.id)

        return <Col
            sm={this.props.chatBoxStatus ? 24 : 0}
            xs={this.props.chatBoxStatus ? 24 : 0}
            md={this.props.isFromItc ? 24 : 15}
            lg={this.props.isFromItc ? 24 : 15}
            xl={this.props.isFromItc ? 24 : 18}
            style={{padding:0}}
        >
            <Modal
                title="Participants"
                visible={conversationParticipantsModal}
                footer={null}
                onCancel={() => (this.setState({ conversationParticipantsModal: false }))}
            >
                <List
                    itemLayout="horizontal"
                    dataSource={participants}
                    renderItem={participant => {
                        return <List.Item>
                            <List.Item.Meta
                                avatar={
                                    participant.profile_picture == null ?
                                        <Avatar
                                            style={{
                                                color: '#f56a00',
                                                backgroundColor: '#fde3cf',
                                                textTransform: 'uppercase'
                                            }}
                                        > {participant.display_name[0]} </Avatar>
                                        :
                                        <Avatar src={participant.profile_picture} />
                                }
                                title={participant.display_name}
                            />
                            {
                                (this.props.isFromItc || participant?.role == "technician") &&
                                <Button onClick={() => {
                                    console.log(conversation)
                                    window.open(
                                        `${ROUTES.TECH_PROFILE_WITH_ID.replace(":id", `${participant.hipaa_id}`)}?search=true`, '_blank'
                                    )
                                }}>
                                    View Profile
                                </Button>
                            }

                        </List.Item>
                    }}
                />
            </Modal>
            {/* Add team member to chat modal */}
            {
                this.state.openAddMemberModal &&
                <AddChatMemberModal
                    openAddMemberModal={this.state.openAddMemberModal}
                    closeAddMemberModal={() => this.setState({ openAddMemberModal: false })}
                    fetchParticipants={(participants) => this.setState({
                        participants: participants
                    })}
                    handleGetConversations={() => this.handleGetConversations()}
                    participants={participants}
                    conversation={conversation}
                    activeTeamMembers={this.state.activeTeamMembers}
                    setActiveTeamMembers={(activeTeamMembers) => this.setState({ activeTeamMembers: activeTeamMembers })}
                    fromGroupConversationsPage={this.props.fromGroupConversationsPage}
                />
            }
            {/* AI chat Modal */}
            
            <AiChatModal
                openAIModal={this.state.openAIModal}
                showAIResults={this.state.showAIResults}
                chatAiText={this.state.chatAiText}
                setChatAiText={(chatAiText) => this.setState({ chatAiText: chatAiText })}
                setShowAIResults={(showAIResults) => this.setState({ showAIResults: showAIResults })}
                onCloseAIModal={() => {
                    this.setState({ 
                        openAIModal: false,
                        showAIResults: false 
                    })
                }}
                handleSendMessage={() => {
                    this.sendNewMessage({
                        message: this.state.chatAiText
                    });
                }}
                chatUser={this.props.conversation.group == null ? this.state.participant.display_name : ""}
            />
            

            <Card className="ant-chat chat " style={{
                height: "auto",
                borderBottom: !this.props.serviceDetails && "none"
            }}>
                <Card className="ant-card-buttom chat-content-header" style={{ padding: '1rem', }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '47px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', cursor: "pointer", columnGap: "16px" }}>
                            {
                                (windowWidth < 768) &&
                                <LeftOutlined xs={0} style={{ color: '#454545', fontSize: 20, marginBottom: 5 }}
                                    onClick={() => {
                                        this.props.onClickBack();
                                    }}
                                />
                            }
                            {
                                (conversation?.group === null) ?
                                    <Space size={16} onClick={() => (this.setState({ conversationParticipantsModal: true }))}>
                                        <Avatar 
                                            size={40} 
                                            src={conversation?.recent_message?.user_data?.profile_picture || currentConversationOwner?.profile_picture || this.state.participant?.profile_picture} 
                                            style={{ backgroundColor: "#F04A22", color: "#FFF" }}>
                                            {conversation?.recent_message?.user_data?.display_name[0]?.toUpperCase() || currentConversationOwner?.display_name[0]?.toUpperCase() || this.state.participant?.display_name[0]?.toUpperCase()}
                                        </Avatar>
                                        <Paragraph style={{ margin: 0, fontSize: 16, fontWeight: 600 }}>{capitalizeFirstLetter(conversation?.recent_message?.user_data?.display_name || currentConversationOwner?.display_name) || this.state.participant?.display_name}</Paragraph>
                                    </Space> :
                                    <>
                                        <Space size={16} onClick={() => (this.setState({ conversationParticipantsModal: true }))}>
                                            <Avatar.Group maxCount={3} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', fontSize: 16 }}>
                                                {
                                                    participants.map((item, index) => (
                                                        <Tooltip title={item?.display_name} placement="top">
                                                            <Avatar src={item?.profile_picture} alt={item?.display_name}>{item?.display_name[0].toUpperCase()}</Avatar>
                                                        </Tooltip>
                                                    ))
                                                }
                                            </Avatar.Group>
                                            {
                                                windowWidth > 576 && (conversation.group) &&
                                                <Paragraph style={{ margin: 0, fontSize: 16, fontWeight: 600 }}>{capitalizeFirstLetter(conversation.group)}</Paragraph>
                                            }
                                        </Space>
                                    </>
                            }

                        </div>
                        <div style={{ display: 'flex', columnGap: "16px", alignItems: 'right', }}>
                            {
                                this.props.conversation.group == null && this.state.participant.display_name != "" &&
                                <Button type='secondary' style={{}} className="call-chat-btn" icon={
                                    this.props.contractPage ? <VideoCameraOutlined
                                        className='chat-btn-icon'
                                    /> : <PhoneOutlined
                                        className='chat-btn-icon'
                                    />
                                }
                                    onClick={async () => {
                                        await initiateWebConference({
                                            conversationId: conversation.id,
                                            caller: {
                                                id: user.id,
                                                name: `${user.firstname}`,
                                                picture: user.imageUrl
                                            },
                                            participant: {
                                                id: this.state.participant.hipaa_id,
                                                name: this.state.participant.display_name,
                                                picture: this.state.participant.profile_picture
                                            }
                                        })
                                    }}
                                >
                                    {
                                        windowWidth > 991 &&
                                        <>
                                            {this.props.contractPage ? 'Video Call' : 'Call To'} {nameFormat(this.state.participant.display_name)}
                                        </>
                                    }
                                </Button>
                            }
                            {
                                !conversation.participants.includes(SAMPLE_HIPAA_ID) &&
                                <Popover
                                    placement="bottomRight"
                                    title={"Chat Members"}
                                    overlayClassName="chat-member-popover"
                                    trigger={"click"}
                                    content={(
                                        <div className='chat-member-popover-content'>
                                            <div className="user-list">
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <Space size={8}>
                                                        <Avatar size={40} src={user.imageUrl} style={{ backgroundColor: "#F04A22", color: "#FFF" }}>
                                                            {user.firstname[0]}
                                                        </Avatar>
                                                        <Paragraph style={{ margin: 0, fontSize: 14, color: "rgba(0, 0, 0, 0.85)" }}>You</Paragraph>
                                                    </Space>
                                                </div>
                                                {
                                                    participants.filter((participant) => participant?.hipaa_id !== user?.id).map((participant, index) => {
                                                        return (
                                                            <div className="d-flex justify-content-between align-items-center w-100 mb-3 chat-member-popover-inner" key={index}>
                                                                <Space size={8}>
                                                                    <Avatar size={40} src={participant.profile_picture} style={{ backgroundColor: "#F04A22", color: "#FFF" }}>
                                                                        {participant.display_name[0]}
                                                                    </Avatar>
                                                                    <Paragraph style={{ margin: 0, fontSize: 14, color: "rgba(0, 0, 0, 0.85)" }}>{participant.display_name}</Paragraph>
                                                                </Space>
                                                                {
                                                                    ((conversationOwner || tempConversationOwner) && (conversation.participants.length > 2) && !this.props.isTech && !conversation?.owners?.some((owner) => owner.hipaa_id === participant?.hipaa_id)) &&
                                                                    <CloseCircleOutlined
                                                                        style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: 20, cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            let _this = this;
                                                                            Modal.confirm({
                                                                                className: 'messages-action-modal',
                                                                                width: 488,
                                                                                title: `Are you sure you want to remove ${participant.display_name} from the chat?`,
                                                                                icon: <ExclamationCircleOutlined style={{ fontSize: 22 }} />,
                                                                                content: `Removing ${participant.display_name} from the chat will revoke their access.`,
                                                                                okText: 'Yes',
                                                                                cancelButtonProps: { type: "secondary" },
                                                                                async onOk() {
                                                                                    // console.log("removing participant")
                                                                                    _this.setState({ removingParticipant: true })
                                                                                    const id = participant.hipaa_id;
                                                                                    const newParticipants =
                                                                                        conversation.participants?.filter(
                                                                                            (p) => p !== id
                                                                                        );

                                                                                    let owners = [];
                                                                                    let participantsNames = [];
                                                                                    let participants = conversation.participants;
                                                                                    if (conversation.owners && participants.length >= 2) {
                                                                                        conversation.owners.map((item) => {
                                                                                            participantsNames.push(item.display_name);
                                                                                        }
                                                                                        )
                                                                                        participantsNames = participantsNames.join(" | ");
                                                                                        participantsNames = `${participantsNames} & ${newParticipants.length - 2} other${newParticipants.length - 2 > 1 ? 's' : ''}`
                                                                                    }


                                                                                    await FirestoreHelper.updateGroupConversationMembers({
                                                                                        participants: newParticipants,
                                                                                        conversation_id: conversation.id,
                                                                                        group: newParticipants.length == 2 ? 'empty' : participantsNames,
                                                                                        //   owners: newParticipants.length == 2 ? newParticipants : []
                                                                                    });

                                                                                    let participantsResponse = await FirestoreHelper.getUsers(newParticipants);
                                                                                    let updatedParticipants = [];
                                                                                    participantsResponse.forEach((doc) => {
                                                                                        let item = doc.data();
                                                                                        if (item.hipaa_id != SAMPLE_HIPAA_ID)
                                                                                            updatedParticipants.push(item);
                                                                                    });
                                                                                    _this.setState({ participants: updatedParticipants })
                                                                                    _this.handleGetConversations()
                                                                                    try {
                                                                                        const payload = {
                                                                                            Title: conversation.group,
                                                                                            Participant: id
                                                                                        }

                                                                                        await Axios.delete(`${BASE_URL}/conversation/${conversation.id}/participant`, {
                                                                                            headers: {
                                                                                                Authorization: "Bearer " + _this.props.user.token,
                                                                                            },
                                                                                            data: { ...payload }
                                                                                        }).then((res) => {
                                                                                            _this.setState({ removingParticipant: false })
                                                                                            notification["success"]({
                                                                                                message: "TecMe",
                                                                                                description: `${participant.display_name} has been removed from the chat.`,
                                                                                            });


                                                                                        })
                                                                                    } catch (error) {
                                                                                        console.error(error)
                                                                                    }
                                                                                },
                                                                            });
                                                                        }}
                                                                    />
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="user-chat-action">
                                                {((conversationOwner || tempConversationOwner) && !this.props.isTech) &&
                                                    <div className='mb-3'>
                                                        <Space
                                                            size={8}
                                                            style={{ cursor: "pointer", width: "100%" }}
                                                            onClick={() => {
                                                                let _this = this;
                                                                if (this.state.activeTeamMembers.filter((item) => item?.Contact?.id !== this.props.user.id).length == 0) {
                                                                    Modal.confirm({
                                                                        className: 'messages-action-modal',
                                                                        width: 488,
                                                                        title: 'No members found in your team',
                                                                        icon: <ExclamationCircleOutlined style={{ fontSize: 22 }} />,
                                                                        content: "You can only add users from your team, but it appears there are no members currently in your team. Would you like to invite new members to your team?",
                                                                        okText: 'Yes',
                                                                        cancelButtonProps: { type: "secondary" },
                                                                        onOk() {
                                                                            window.open(ROUTES.TEAM_MANAGEMENT, "_self");
                                                                        },
                                                                    });
                                                                    return;
                                                                }
                                                                this.setState({ openAddMemberModal: true })
                                                            }}
                                                        >
                                                            <UsergroupAddOutlined />
                                                            <Paragraph style={{ margin: 0, fontSize: 14, color: "rgba(0, 0, 0, 0.85)" }}>Add team members</Paragraph>
                                                        </Space>
                                                    </div>
                                                }
                                                {
                                                    (!this.props.isFromItc && !this.props.contractPage) &&
                                                    <>
                                                        {
                                                            isArchived ?
                                                                <Space size={8} style={{ cursor: "pointer", width: "100%" }}
                                                                    onClick={() => {
                                                                        let archivedList = conversation.archived ? conversation.archived : [];
                                                                        const archiveIndex = archivedList.indexOf(this.props.user.id);
                                                                        if (archiveIndex > -1) {
                                                                            archivedList.splice(archiveIndex, 1); // 2nd parameter means remove one item only
                                                                        }

                                                                        FirestoreHelper.markArchived({
                                                                            conversation_id: conversation.id,
                                                                            archived: archivedList
                                                                        });
                                                                        this.props.onArchive();

                                                                        notification['success']({
                                                                            message: 'TecMe',
                                                                            description:
                                                                                `Conversation unarchived.`,
                                                                        });
                                                                        return;
                                                                    }}
                                                                >
                                                                    <UnarchiveIcon style={{ fontSize: 15, marginBottom: 5 }} />
                                                                    <Paragraph style={{ margin: 0, fontSize: 14, color: "rgba(0, 0, 0, 0.85)" }}>Unarchive chat</Paragraph>
                                                                </Space> :
                                                                <Space size={8} style={{ cursor: "pointer", width: "100%" }}
                                                                    onClick={() => {
                                                                        let _this = this;
                                                                        Modal.confirm({
                                                                            className: 'messages-action-modal',
                                                                            width: 488,
                                                                            title: 'Are you sure you want to archive this chat?',
                                                                            icon: <ExclamationCircleOutlined style={{ fontSize: 22 }} />,
                                                                            content: "By archiving it, members will no longer be able to send messages. However, you can still search and read its contents even after archiving. Are you sure you want to proceed with archiving this chat?",
                                                                            okText: 'Yes',
                                                                            cancelButtonProps: { type: "secondary" },
                                                                            onOk() {
                                                                                let archivedList = conversation.archived ? conversation.archived : [];
                                                                                archivedList.push(_this.props.user.id)
                                                                                FirestoreHelper.markArchived({
                                                                                    conversation_id: conversation.id,
                                                                                    archived: archivedList
                                                                                });
                                                                                _this.props.onArchive();
                                                                                notification['success']({
                                                                                    message: 'TecMe',
                                                                                    description:
                                                                                        `Conversation archived.`,
                                                                                });
                                                                            },
                                                                        });
                                                                    }}
                                                                >
                                                                    <img src={NewArchiveIcon} style={{ width: 14 }} />
                                                                    <Paragraph style={{ margin: 0, fontSize: 14, color: "rgba(0, 0, 0, 0.85)" }}>Archive chat</Paragraph>
                                                                </Space>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    )}>
                                    <UsergroupAddOutlined style={{ color: "#F04A22", fontSize: 30, cursor: "pointer" }} />
                                </Popover>
                            }

                        </div>
                    </div>
                </Card>
                <div className={"chat-wrapper"}>
                    <div className={`chat-container scroll`} style={{
                        height: isMobile ? `${window.innerHeight * (.078)}vh` : '55vh'
                    }}
                    >
                        {(messageLoading) &&
                            <Card style={{ overflow: 'hidden', border: 'none', height: "100%" }}>
                                <div className="user-card" style={{ height: 'auto', marginTop: 100 }}>
                                    <img loading="lazy" src={Chat} style={{ width: '100px' }} />
                                    <Title level={5}>Loading your messages</Title>
                                    <Spin indicator={<LoadingOutlined style={{ fontSize: 22, color: SECONDARY_COLOR }} spin />} />
                                </div>
                            </Card>
                        }
                        {messageLoading == false && data.map((item, index) => {
                            return <Card key={`messages-pane-${index}-${Date.now()}`} className="ant-chat-buttom message-container"
                                title={false}
                                style={{ padding: '1rem', width: 'auto' }}
                            >
                                {
                                    this.messageCell(item)
                                }
                            </Card>
                        })}
                    </div>
                </div>
                <div className={`chat-form ${isMobile ? 'mobile-chat-form' : ''}`} style={{ bottom: 5 }}>
                    <Form
                        name="rtm"
                        ref={this.formRef}
                        onFinish={async (values) => {
                            let file = values.file ? values.file : [];
                            let chatMessage = values.message ? values.message : 'Attachment';
                            chatMessage = chatMessage.trim();
                            // this.formRef.current.resetFields();
                            this.formRef.current.setFieldsValue({
                                "message": "",
                                "file": null
                            });

                            if (file.length > 0) {
                                file = file[0];
                                let invalidFileType = false;

                                if (![
                                    'image/jpeg', 'image/png', 'image/gif', 'image/jpg', 'image/webp',
                                    'application/vnd.ms-excel', 'application/msword', 'application/vnd.ms-powerpoint',
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                    'application/pdf',
                                ].includes(file.type)) {
                                    message.error('You can upload word, excel, pdf, powerpoint and image files only.');
                                    invalidFileType = true;
                                }

                                const isLt2M = file.size / 1024 / 1024 < 5;

                                if (!isLt2M) {
                                    message.error('File must be smaller than 5MB!');
                                }

                                if (invalidFileType || !isLt2M)
                                    return;

                                // this.formRef.current.resetFields();
                                this.setState({
                                    displayProgressBar: true,
                                    file: null
                                })
                                await FirebaseStorageHelper.uploadFile({
                                    path: `conversation/${this.props.conversation.id}/${file.name}`,
                                    file: file,
                                    setImgUrl: (downloadURL) => {
                                        this.sendNewMessage({
                                            message: chatMessage.length == 0 ? 'Attachment' : chatMessage,
                                            mediaURL: downloadURL,
                                            type: ['image/jpeg', 'image/png', 'image/gif', 'image/jpg', 'image/webp'].includes(file.type) ? 'image' : file.type,
                                            file_name: file.name
                                        });
                                    }
                                });
                            } else {
                                this.sendNewMessage({
                                    message: chatMessage
                                });
                            }

                        }}
                        style={{
                            // paddingLeft: 10,
                            // paddingRight: 10,
                            // position: 'absolute',
                            // bottom: 15,
                            // width: '100%',
                            background: 'white'
                        }}
                    >
                        <div className={"chat-input-area"}>
                            {/* <Divider style={{marginTop: 10}}/> */}
                            <Row align={"bottom"}>
                                {
                                    emojiKeyboardEnabled &&
                                    <Col span={24}>
                                        <Picker
                                            className={"tecme-picker"}
                                            onEmojiClick={(e, emoji) => {
                                                let message = this.formRef.current.getFieldValue('message');
                                                message = message ? message : '';
                                                this.formRef.current.setFieldsValue({
                                                    message: `${message}${message.length > 0 ? " " : ""}${emoji.emoji}`
                                                })
                                                this.setState({
                                                    emojiKeyboardEnabled: !emojiKeyboardEnabled
                                                })
                                            }} />
                                    </Col>
                                }
                                <Col sm={24} xs={24} md={24} lg={24} xl={24}>
                                    <Form.Item className='message-input-item' name={"message"}
                                        rules={
                                            [
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        let file = getFieldValue('file') ? getFieldValue('file') : [];
                                                        if (file.length == 0 && !value) {
                                                            return Promise.reject(new Error('You need to type something ...'));
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),

                                            ]
                                        }
                                    >
                                        <Input.TextArea
                                            ref={this.inputFocus}
                                            // rows={1}
                                            style={{
                                                border: 'none',
                                                resize: 'none',
                                                backgroundColor: "#FAFBFB",
                                                padding: 24,
                                            }}
                                            autoSize={true}
                                            onKeyDown={(evt) => {
                                                if (evt.keyCode == 13 && !evt.shiftKey) {
                                                    evt.preventDefault();
                                                    this.formRef.current.submit();
                                                } else if (evt.keyCode == 13 && evt.shiftKey) {
                                                    let message = this.formRef.current.getFieldValue('message');
                                                    message = message ? message : '';
                                                    this.formRef.current.setFieldsValue({
                                                        message: `${message}${message.length > 0 ? " " : ""}\n`
                                                    })
                                                    evt.preventDefault();
                                                }
                                            }}
                                            placeholder="Write a message ..."
                                        />
                                    </Form.Item>
                                </Col>
                                <Col sm={24} xs={24} md={24} lg={24} xl={24}>
                                    <Row gutter={[7, 7]} justify={"space-between"} align={"middle"} style={{ paddingRight: 7 }}>
                                        <Col style={{ display: "flex", columnGap: 16, padding: "16px 0px 16px 24px" }}>
                                            <Button className='message-action-btn' icon={<SmileOutlined className='message-action-icon' />}
                                                onClick={(e) => (this.setState({ emojiKeyboardEnabled: !emojiKeyboardEnabled }))}
                                            >Emoji</Button>
                                            <Form.Item
                                                name="file"
                                                valuePropName="list"
                                                getValueFromEvent={normalFile}
                                                rules={
                                                    [
                                                        ({ getFieldValue, setFieldsValue }) => ({
                                                            validator(_, value) {
                                                                setFieldsValue({
                                                                    message: getFieldValue('message') || ' '
                                                                })
                                                                return Promise.resolve();
                                                            },
                                                        }),

                                                    ]
                                                }
                                            >
                                                <Upload {...this.props}
                                                    // accept={'image/*'}
                                                    accept={'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/pdf, application/ppt, image/*, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.wordprocessingml.document'}
                                                    multiple={false}
                                                    beforeUpload={(file) => {
                                                        this.setState({
                                                            file
                                                        })
                                                        return false;
                                                    }}
                                                    listType="text"
                                                    itemRender={(originNode, file, fileList, actions) => {
                                                        return <div></div>
                                                        return <Row gutter={[5, 5]} style={{}}>
                                                            <Col>
                                                                <Typography.Paragraph>{file.name}</Typography.Paragraph>
                                                            </Col>
                                                            <Col>
                                                                <Button shape="circle" size={"small"} icon={<CloseOutlined />}
                                                                    onClick={(e) => {
                                                                        actions.remove()
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    }}
                                                    maxCount={1}
                                                >
                                                    <Button className='message-action-btn' icon={<UploadOutlined className='message-action-icon' />}>{ isMobile ? "Upload" : "Upload a file"}</Button>
                                                </Upload>
                                            </Form.Item>
                                            <AIButton
                                                type="chat"
                                                onClick={() => {
                                                    this.setState({
                                                        openAIModal: true
                                                    })
                                                }}
                                            >
                                                { isMobile ? "Write" : "Help me write"}
                                            </AIButton>
                                        </Col>
                                        <Col style={{ padding: "16px 24px 16px 16px" }}>
                                            <Button type="primary" shape="default"
                                                htmlType="submit" style={{ marginBottom: 10 }}>
                                                    {isMobile ? <SendOutlined /> : "Send"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>

                                {
                                    (displayProgressBar) &&
                                    <Col span={24} style={{ paddingLeft: 13, paddingRight: 10 }}>
                                        <ProgressBar />
                                    </Col>
                                }
                                {
                                    this.state.file &&
                                    <Col span={24} style={{ paddingLeft: 13 }}>
                                        <Row gutter={[5, 5]} style={{}}>
                                            <Col>
                                                <Typography.Paragraph>{this.state.file.name}</Typography.Paragraph>
                                            </Col>
                                            <Col>
                                                <Button shape="circle" size={"small"} icon={<CloseOutlined />}
                                                    onClick={(e) => {
                                                        this.setState({
                                                            file: null
                                                        });
                                                        this.formRef.current.setFieldsValue(
                                                            {
                                                                "file": null
                                                            }
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                            </Row>
                        </div>
                    </Form>
                </div>
            </Card>
        </Col>;
    }

    async sendNewMessage({ message, mediaURL, type, file_name }) {
        if (message.length == 0)
            return;

        FirestoreHelper.sendMessage({
            conversation_id: this.props.conversation.id,
            message: encryptString(message.replace(/(?:\r\n|\r|\n)/g, '<br>')),
            user_id: this.props.fromGroupConversationsPage ? SAMPLE_HIPAA_ID : this.props.user.id,
            mediaURL,
            type,
            file_name
        });
        this.rtmRepository.sendMessage({
            conversationId: this.props.conversation.id,
            message,
            createdAt: moment().utc().toISOString(),
            serviceId: this.props.service ? this.props.service.scheduledServiceId : null,
            bookingId: this.props.booking ? this.props.booking.bookingId : null,
        });
        // this.formRef.current.resetFields();
        this.inputFocus.current.focus();
        this.setState({
            displayProgressBar: false,
        });
        this.scrollToBottom();
    }

    messageCell(item) {
        let participant = null;
        if (item.sent_by in participantsObject) {
            participant = participantsObject[item.sent_by];
        }

        if (participant == null)
            return <div></div>

        let message = decryptString(item.message);
        let isMedia = (item.media && item.media.length);
        let mediaType = isMedia ? item.media[0]['type'] : '';
        let mediaIcon = DefaultFileIcon;
        if (['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(mediaType))
            mediaIcon = ExcelIcon;
        else if (['application/pdf'].includes(mediaType))
            mediaIcon = PdfIcon;
        else if (['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(mediaType))
            mediaIcon = WordIcon;
        else if (['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'].includes(mediaType))
            mediaIcon = PowerPointIcon;

        return <div >
            <Space align={"top"} size={14}>
                <div>
                    {
                        participant.profile_picture == null ?
                            <Avatar
                                style={{
                                    color: '#f56a00',
                                    backgroundColor: '#fde3cf',
                                    textTransform: 'uppercase'
                                }}
                            > {participant.display_name[0]} </Avatar>
                            :
                            <Avatar src={participant.profile_picture} />
                    }
                </div>
                <div>
                    <Row gutter={[5, 5]} className={"chat-bubble-text"}>
                        <Col span={24}>
                            <Row gutter={[5, 5]}>
                                <Col>
                                    <Typography.Text
                                        strong>{this.props.user.id == participant.hipaa_id ? "You" : participant.display_name}</Typography.Text>
                                </Col>
                                <Col>
                                    <Typography.Text style={{
                                        fontSize: 12,
                                        color: 'grey'
                                    }}>{item.sent_at ? moment.utc(item.sent_at.toDate()).local(false).format("llll") : moment().local(false).format("llll")}</Typography.Text>
                                </Col>
                            </Row>
                        </Col>


                        {
                            ((message != "" && !isMedia) || (mediaType != 'image' && mediaType != "") || (mediaType == 'image' && message != "Attachment")) &&
                            <Col>
                                <div className={`chat-bubble ${this.props.user.id == participant.hipaa_id ? 'your-chat' : ''}`}>
                                    {
                                        message != 'Attachment' &&
                                        <>
                                            <Linkify>
                                                <Paragraph style={{margin:0}}>{ReactHtmlParser(message)}</Paragraph>
                                            </Linkify> 
                                        </>
                                    }

                                    {
                                        (isMedia && ('file_name' in item.media[0]) && mediaType != 'image') &&
                                        <>
                                            <Typography.Text strong>{item.media[0]['file_name']}</Typography.Text> <br />
                                        </>
                                    }

                                    {
                                        isMedia && mediaType != 'image' &&
                                        <Button className={"attachment-button border-0"} type="link" style={{ padding: 0 }} danger target={"_blank"}
                                            href={item.media[0]['url']}
                                            icon={<Image loading="lazy" preview={false} src={mediaIcon} height={30} width={30} />}
                                        >
                                            Download attachment
                                        </Button>
                                    }
                                </div>
                            </Col>
                        }

                        {
                            isMedia && mediaType == 'image' &&
                            <Col span={24}>
                                <Image loading="lazy"
                                    src={item.media[0]['url']}
                                    style={{
                                        height: 160,
                                        width: 170,
                                        objectFit: 'cover',
                                        borderRadius: '0px 10px 10px 10px'
                                    }}
                                />
                            </Col>
                        }


                    </Row>
                </div>
            </Space>
        </div>
    }

}
