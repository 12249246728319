// External Imports
import { useState, useEffect } from "react";
import {
  Layout,
  Typography,
  Row,
  Col,
  Button,
  Image,
  Space,
  Divider,
  Progress,
  Tooltip,
  Input,
  notification,
  Grid,
  Radio,
  Modal,
  InputNumber
} from "antd";
import MetaTags from "react-meta-tags";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  MessageOutlined,
  PhoneOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import Geocode from "react-geocode";

// Internal Imports
import {
  ALTERNATIVE_REACT_APP_GOOGLE_API_KEY,
  META_DESCRIPTION,
} from "../../../../../utils/constants";
import ContactModal from "../../../../components/modals/tech-contact-modal";
import ROUTES from "../../../../../utils/routes";
import { DiscoveryExperienceRepository } from "./repository";
import { getZipCodeFromGeocodeResponse } from "../../../../../utils/helper";

// Assets Imports
import "./assets/css/index.css";
import MainPng from "./assets/images/main.png";
import LogoSvg from "./assets/images/logo.svg";
import OtherManuacturerIcon from "../../../customer/manage/devices/assets/images/other-manufacturer.webp";
import {getBrandIcon} from "../instant-search/utils/helper";
import { useSelector } from "react-redux";
import { ApiBaseHelper } from "../../../../../utils/api-base-helper";
import PageFallback from "../../../../components/page-fallback";

const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const getProgressPercentage = (stepCount) => {
  switch (stepCount) {
    case 1:
      return 0;
    case 2:
      return 20;
    case 3:
      return 40;
    case 4:
      return 60;
    case 5:
      return 80;
    case 6:
      return 100;
    default:
      return 0;
  }
};
const getValidationStatus = (stepCount, stepsInformation) => {
  switch (stepCount) {
    case 1:
      return !stepsInformation.stepOne.techFor;
    case 2:
      return !stepsInformation.stepTwo.workMode
    case 3:
      {
        if(!stepsInformation.stepThree.workType) return true
        if(stepsInformation.stepThree.workType === "onsite" && !stepsInformation.stepThree.location) return true

        return false
      }
    case 5:
      return !stepsInformation.stepFour.complianceLevel
    default:
      return false;
  }
};

const DiscoveryExperience = (props) => {
  const { history } = props;
  const user = useSelector(state => state.user)
  const breakPoint = useBreakpoint();
  const isBigScreen = breakPoint.lg || breakPoint.xl || breakPoint.xxl;
  const [stepCount, setStepCount] = useState(1);
  const [stepsInformation, setStepsInformation] = useState({
    stepOne: {
      techFor: undefined,
    },                        
    stepTwo: {
      workMode: undefined,
    },
    stepThree: {
      workType: undefined,
      location: undefined
    },
    stepFour: {
      complianceLevel: undefined,
    },
    optionalStep: {
      hourlyRates: {
        min: '',
        max: ''
      }
    }
  });
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [isContactModalVisible, setIsContactModalVisible] = useState(false);
  const [lngLat, setLngLat] = useState({
    lng: null,
    lat: null,
  });
  const [deviceRepository, setDeviceRepository] = useState(undefined);
  const [itProjects, setItProjects] = useState([]);
  const [workCategoriesData, setWorkCategoriesData] = useState([])
  const [loadingCategories, setLoadingCategories] = useState(false)
  const [childCategories, setChildCategories] = useState([])

  const fetchWorkCategories = () => {
      setLoadingCategories(true)
      ApiBaseHelper.get({
          url: `web/work-categories`,
      }).then((res) => {
          setWorkCategoriesData(res.data)
          setLoadingCategories(false)
      }).catch((error) => {
          console.error(error)
          setLoadingCategories(false)
      })       
  }

  useEffect(() => {
    history.push(
        `${ROUTES.DISCOVERY_EXPERIENCE_SEARCH}`
    );
      fetchWorkCategories()
  }, [])

  // useEffect(() => {
  //   const deviceRepositoryInstance = new DiscoveryExperienceRepository();
  //   setDeviceRepository(deviceRepositoryInstance);
  //
  //   deviceRepositoryInstance
  //     .getDeviceTypes()
  //     .then((response) => setDeviceTypes(response.data?.data))
  //     .catch((error) => console.error(error));
  //
  //   deviceRepositoryInstance
  //     .getITProjects()
  //     .then((response) => setItProjects(response.data?.data))
  //     .catch((error) => console.error(error));
  // }, []);

  useEffect(() => {
    // Debounce location value
    let timeout;
    if (stepsInformation.stepThree.location && (!lngLat.lng || !lngLat.lat)) {
      timeout = setTimeout(() => {
        deviceRepository
          .getLngAndLatFromZipCode(stepsInformation.stepThree.location)
          .then((response) => {
            if (response.status === 200) {
              const lng = response.data?.results[0]?.geometry?.location?.lng;
              const lat = response.data?.results[0]?.geometry?.location?.lat;
              if (lng && lat) {
                setLngLat({
                  lng,
                  lat,
                });
              }
            }
          })
          .catch((error) => console.error(error));
      }, 500);
    }

    return () => clearTimeout(timeout);
  }, [stepsInformation.stepThree.location]);

  useEffect(() => {
    if (stepsInformation.stepOne.techFor && stepCount === 1) handleNextClick();
  }, [stepsInformation.stepOne.techFor]);

  useEffect(() => {
    if (stepsInformation.stepTwo.workMode && stepCount === 2) handleNextClick();
  }, [stepsInformation.stepTwo.workMode]);

  useEffect(() => {
    const { techFor } = stepsInformation.stepOne
    const { location, workType } = stepsInformation.stepThree
    let stepThreeCheck = false;

    if(workType === "remote") {
      stepThreeCheck = true
    }
    if(workType === "onsite" && location) {
      stepThreeCheck = true
    }
    
    if (stepThreeCheck && (stepCount === 3)) {
      handleNextClick(); 
    }
  }, [stepsInformation.stepThree.workType]);

  

  useEffect(() => {
    if (stepsInformation.stepFour.complianceLevel && stepCount === 5)
      handleNextClick();
  }, [stepsInformation.stepFour.complianceLevel]);

  const handlePageChange = () => {
    let queryParameters = [];
    const { techFor } = stepsInformation.stepOne;
    const { workType, location } = stepsInformation.stepThree;
    const { complianceLevel } = stepsInformation.stepFour;
    const { workMode } = stepsInformation.stepTwo;
    const { hourlyRates } = stepsInformation.optionalStep;

    if(techFor) queryParameters.push(`categories=${techFor}`);
    if(workMode) queryParameters.push(`${techFor.replaceAll(" ","_")}_sub_categories=${workMode}`);
    if(hourlyRates.min) queryParameters.push(`min=${hourlyRates.min}`);
    if(hourlyRates.max) queryParameters.push(`max=${hourlyRates.max}`);
    if (workType === "onsite" && location && lngLat.lng) queryParameters.push(`zip_code=${location}`);
      // queryParameters.push(`lng=${lngLat.lng}&lat=${lngLat.lat}`);
    if (complianceLevel && complianceLevel !== 'none') queryParameters.push(`compliance=${complianceLevel}`);

    history.push(
      `${ROUTES.DISCOVERY_EXPERIENCE_SEARCH}${queryParameters.length ? `?${queryParameters.join("&")}` : ""}`
    );
  };

  const handleNextClick = () => {
    if (stepCount === 6) {
      handlePageChange();
      return
    };
    const { workType, location } = stepsInformation.stepThree;
    const isClickNotValid = getValidationStatus(stepCount, stepsInformation);
    if (isClickNotValid) {
      return notification.info({
        message: "TecMe",
        description:
          workType === "onsite" && !location
            ? "Please enter your zip code"
            : "Please select at least one option of your choice",
      });
    }
    if (stepCount <  6) setStepCount((prevCount) => prevCount + 1);
    if(stepCount == 1 && !childCategories.length)setStepCount(3);
  };

  const handlePreviousClick = () => {
    if (stepCount === 1) {
      history.goBack();
      return;
    }
    if (stepCount > 1) setStepCount((prevCount) => prevCount - 1);
    if(stepCount ==3 && !childCategories.length)setStepCount(1);
  };

  const progressPercentage = getProgressPercentage(stepCount);

  const handleStepOneSelection = (selectedOption) => {
    setChildCategories(workCategoriesData?.find((category) => category.Title.toLowerCase() === selectedOption)?.Child_Categories || []);
   
    setStepsInformation(() => ({
      stepOne: {
        techFor: selectedOption,
      },
      stepTwo: {
        workMode: undefined,
      },
      stepThree: {
        workType: undefined,
        location: undefined
      },
      stepFour: {
        complianceLevel: undefined,
      },
      optionalStep: {
        hourlyRates: {
          min: '',
          max: ''
        }
      }
    }));
  }

  const handleStepTwoSelection = (value) => {
    setStepsInformation((prevStepsInformation) => ({
      ...prevStepsInformation,
      stepTwo: {
        workMode: value,
      }
    }));
  };

  const handleStepThreeSelection = (value, isWorkType = false, isLocation = false) => {
    if (isWorkType) {
      return setStepsInformation((prevStepsInformation) => ({
        ...prevStepsInformation,
        stepThree: {
          ...prevStepsInformation.stepThree,
          workType: value,
        },
      }));
    }

    if(isLocation) {
      return setStepsInformation((prevStepsInformation) => ({
        ...prevStepsInformation,
        stepThree: {
          ...prevStepsInformation.stepThree,
          location: value,
        },
      }));
    }

  }

  const handleStepFourSelection = (selectedOption) =>
    setStepsInformation((prevStepsInformation) => ({
      ...prevStepsInformation,
      stepFour: {
        complianceLevel: selectedOption,
      },
    }));

  const getLocationFromGoogle = (latitude, longitude) => {
    Geocode.setApiKey(
      process.env.REACT_APP_GOOGLE_API_KEY ||
        ALTERNATIVE_REACT_APP_GOOGLE_API_KEY
    );
    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        const zipCode = getZipCodeFromGeocodeResponse(response);
        if(zipCode == ""){
          Modal.info({
            title: 'TecMe',
            content: "We were not able to find your zip code from your location, please enter the valid zip code to contine.",
          });
        }
        handleStepThreeSelection(zipCode, false, true);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const getUserLocation = () => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const lng = position.coords?.longitude;
        const lat = position.coords?.latitude;
        setLngLat({
          lng,
          lat,
        });
        getLocationFromGoogle(lat, lng);
      }, null);
    }
  };

  const locationDetails = (
    <>
      <Title level={isBigScreen ? 2 : 3} className={`text-center ${!isBigScreen && 'pt-2'}`}>
        Are you open to having a technician
        {isBigScreen ? <Divider className="m-0 p-0 border-0" /> : " "}
        help you remotely?
      </Title>
      <Space className="pt-4" direction="vertical">
        <Radio.Group
          size="large"
          value={stepsInformation.stepThree.workType}
          onChange={(e) => handleStepThreeSelection(e.target.value, true)}
        >
          <Space size="middle" className="mb-3 flex-wrap justify-content-center">
            <Radio.Button
              className="discovery-content-large-button"
              value="onsite"
            >
              No
            </Radio.Button>
            <Radio.Button
              className="discovery-content-large-button"
              value="remote"
            >
              Yes
            </Radio.Button>
          </Space>
        </Radio.Group>
        {stepsInformation.stepThree.workType === "onsite" && (
          <>
            <Text strong className="mb-0">
              Please enter your zip code to see techs near you
            </Text>
            <Input
              size="large"
              placeholder="Zip Code"
              value={stepsInformation.stepThree.location}
              onChange={(e) => {
                const zipcode = e.target.value;
                if (zipcode.length <= 5) handleStepThreeSelection(zipcode, false, true);
              }}
            />
            {stepsInformation.stepThree.location &&
              !(stepsInformation.stepThree.location.length === 5) && (
                <Text
                  role="alert"
                  className="ant-form-item-explain-error"
                  type="danger"
                >
                  Please enter valid zip code of 5 digits
                </Text>
              )}
            <Button
              type="text"
              danger
              className="text-end w-100 border-0 pe-0 bg-white wizard-use-my-device-location"
              onClick={getUserLocation}
            >
              Use my device location
            </Button>
          </>
        )}
      </Space>
    </>
  )

  const hipaaStep = (
    <>
      <Title level={isBigScreen ? 2 : 3} className={`text-center ${!isBigScreen && 'pt-2'}`}>
        Do you require a HIPAA-compliant technician?
      </Title>
      <Radio.Group
        size="large"
        value={stepsInformation.stepFour.complianceLevel}
        onChange={(e) => handleStepFourSelection(e.target.value)}
      >
        <Space size="middle" className="pt-4 flex-wrap justify-content-center">
          <Radio.Button
            className="discovery-content-large-button"
            value="hipaa"
          >
            Yes
          </Radio.Button>
          <Radio.Button
            className="discovery-content-large-button"
            value="none"
          >
            No
          </Radio.Button>
        </Space>
      </Radio.Group>
    </>
  )

  const budgetStep = (
    <>
      <Title level={isBigScreen ? 2 : 3} className={`text-center ${!isBigScreen && 'pt-2'}`}>
        Do you have a budget?
      </Title>
      <Space className="pt-4" direction="vertical">
        <Space direction={isBigScreen ? 'horizontal' : 'vertical'} className='text-center'>
          <InputNumber
            prefix="$"
            addonAfter="/hour"
            value={stepsInformation.optionalStep.hourlyRates.min}
            placeholder="min"
            controls={false}
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            onPaste={(e)=>{
              e.preventDefault();
              return false;
            }}
            onChange={(min)=>{
              setStepsInformation((prevStepsInformation) => ({
                ...prevStepsInformation,
                optionalStep: {
                  hourlyRates: {
                    ...stepsInformation.optionalStep.hourlyRates,
                    min
                  },
                },
              }));
            }}
          />
          <Text>to</Text>
          <InputNumber
            prefix="$"
            addonAfter="/hour"
            value={stepsInformation.optionalStep.hourlyRates.max}
            placeholder="max"
            controls={false}
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
            onPaste={(e)=>{
              e.preventDefault();
              return false;
            }}
            onChange={(max)=>{
              setStepsInformation((prevStepsInformation) => ({
                ...prevStepsInformation,
                optionalStep: {
                  hourlyRates: {
                    ...stepsInformation.optionalStep.hourlyRates,
                    max
                  },
                },
              }));
            }}
          />
        </Space>
        <Button
          type="text"
          danger
          className="text-center w-100 border-0 pe-0 bg-white wizard-use-my-device-location"
          onClick={handleNextClick}
        >
          Continue without setting a budget
        </Button>
      </Space>
    </>
  )

  const seeResults = (
    <>
      <Title level={isBigScreen ? 2 : 3} className={`text-center ${!isBigScreen && 'pt-2'}`}>
        Fantastic-you're all set!
      </Title>
      <Space size="middle" className="pt-4">
        <Button
          size="large"
          className="discovery-content-large-button"
          onClick={handlePageChange}
        >
          See my results
        </Button>
      </Space>
    </>
  )

  const getSelectedStepContent = () => {
    switch (stepCount) {
      case 1:
        return (
          <>
            {
              loadingCategories ? <PageFallback /> : (
                <>
                 <Title level={isBigScreen ? 2 : 3} className={`text-center ${!isBigScreen && 'pt-2'}`}>
              What do you need help with?
                  </Title>
                  <Radio.Group
                    size="large"
                    value={stepsInformation.stepOne.techFor}
                    onChange={(e) => handleStepOneSelection(e.target.value)}
                  >
                    <Space size={isBigScreen ? "large": "middle"} className="pt-4 align-items-start justify-content-center flex-wrap radio-categories">
                      {
                        workCategoriesData?.map((category) => {
                          return(
                            <Space key={category.id} className="align-items-center">
                              <Radio.Button
                                className="discovery-content-large-button"
                                value={category?.Title.toLowerCase()}
                              >
                                {category?.Title}
                              </Radio.Button>
                            </Space>
                          )
                        })
                      }
                    </Space>
                  </Radio.Group>
                </>
              )
            }
          </>
        );
      case 2:
        return (
          <>
            <Title level={isBigScreen ? 2 : 3} className={`text-center ${!isBigScreen && 'pt-2'}`}>
            Narrow down your search
            </Title>
            <Row
              gutter={[18, 18]}
              className={` m-auto descovery-experience-device-types ${
                !isBigScreen ? "pb-5 mb-5" : "pt-4"
              }`}
            >
               <Radio.Group
                size="large"
                value={stepsInformation.stepTwo.workMode}
                onChange={(e) =>
                  handleStepTwoSelection(e.target.value.toLowerCase())
                }
              >
                <Space size={isBigScreen ? "large": "middle"} className="pt-4 align-items-start justify-content-center flex-wrap radio-categories">
                  {
                    childCategories?.map((category) => {
                      return(
                        <Space key={category.id} className="align-items-center">
                          <Radio.Button
                            className="discovery-content-large-button"
                            value={category?.Title.toLowerCase()}
                          >
                            {category?.Title}
                          </Radio.Button>
                        </Space>
                      )
                    })
                  }
                </Space>
              </Radio.Group>
            </Row>
          </>
        ) 
      case 3:
          return locationDetails
      case 4:
        return budgetStep
      case 5:
        return hipaaStep
      case 6:
        return seeResults
      default:
        return <Text strong>Something went wrong...</Text>;
    }
  };

  const stepCountElement = (
    <Text disabled className="w-100 text-start" style={{wordBreak:'inherit'}}>
      {stepCount}/{stepsInformation.stepOne.techFor === "device_issue" ? 7 : 6}
    </Text>
  );
  const logoElement = (
    <Image
      src={LogoSvg}
      alt="TecMe"
      height={50}
      width={isBigScreen ? 120 : 100}
      preview={false}
      className={(!isBigScreen && "pb-2") || ""}
    />
  );
  const progressBar = (
    <Tooltip
      title={
        !!(progressPercentage && progressPercentage !== 100) &&
        `${progressPercentage}% complete, keep it up!`
      }
      className={!isBigScreen && "mt-1"}
    >
      <Progress percent={progressPercentage} showInfo={false} status="normal" />
    </Tooltip>
  );
  const contactTecMe = (
    <Button
      type={isBigScreen ? "primary" : "text"}
      className="d-flex align-items-center justify-content-center"
      size={isBigScreen ? "middle" : "large"}
      onClick={() => setIsContactModalVisible(true)}
    >
      {<MessageOutlined />} Contact TecMe
    </Button>
  );
  const callTecMe = (
    <Button
      type={isBigScreen ? "primary" : "text"}
      href="tel:+1 (888) 678-3263"
      size={isBigScreen ? "middle" : "large"}
      className="d-flex align-items-center discovery-content-call justify-content-center"
    >
      {<PhoneOutlined />} Call 888 678 3263
    </Button>
  );

  return (
    <Content className="discovery-content-main" style={{
      backgroundColor: user && "#FFFFFF",
      margin: user && "20px"
    }}>
      <MetaTags>
        <title>Find Technician | TecMe</title>
        <meta name="description" content={META_DESCRIPTION} />
        <meta name="robots" content="noindex, nofollow" />
      </MetaTags>
      <Content>
        <Row className="discovery-content-main-row">
          {
            !user &&
            <Col xs={0} lg={6} xl={5} className="position-relative">
              <Space className="position-absolute w-100 justify-content-center pt-4 discovery-experience-logo">
                {logoElement}
              </Space>
              <Image
                src={MainPng}
                alt="find technician TecMe"
                preview={false}
                height="100vh"
              />
            </Col>
          }
          
          <Col
            xs={24}
            lg={!user ? 18 : 24}
            xl={!user ? 19 : 24}
            className={`${isBigScreen ? "p-5" : "p-3"}`}
            style={{height:user && "80vh"}}
          >
            <Row className="discovery-content-header">
              {!isBigScreen && logoElement}
              {isBigScreen && (
                <Space size="large" className="me-1">
                  {stepCountElement}
                  <Button
                    size={`${isBigScreen ? "middle" : "large"}`}
                    onClick={handlePageChange}
                  >
                    Skip Wizard
                  </Button>
                </Space>
              )}
              <Space size="middle" className="w-100 justify-content-end flex-wrap">
                {!isBigScreen && stepCountElement}
                {isBigScreen && <Text strong>Let us help you find the right technician</Text>}
                {isBigScreen && contactTecMe}
                {isBigScreen && callTecMe}
              </Space>
            </Row>
            {!isBigScreen && (
              <Row
                className={`discovery-content-contact-call-wrapper border-top border-bottom mt-1 ${
                  stepCount === 3 ? "" : ""
                }`}
              >
                {contactTecMe}
                {callTecMe}
              </Row>
            )}
            <Space
              direction="vertical"
              align="center"
              className={`w-100 justify-content-center ${!user && "h-75"}`}
              style={{height:(user && isBigScreen) && '85%', minHeight: !isBigScreen && "65vh"}}
            >
              {getSelectedStepContent()}
            </Space>
            <Row
              className={`${
                !isBigScreen ? "discovery-content-fixed-footer" : ""
              }`}
            >
              {!isBigScreen && progressBar}
              <Row
                className={`${
                  (isBigScreen && !user) ? "pt-4 mt-4" : (isBigScreen && user) ? "progress-mt" : "mt-3"
                } discovery-content-footer w-100`}
              >
                <Button
                  type="primary"
                  size={isBigScreen ? "middle" : "large"}
                  icon={
                    stepCount === 1 ? <CloseOutlined /> : <ArrowLeftOutlined />
                  }
                  onClick={handlePreviousClick}
                />
                {isBigScreen && progressBar}
                <Button
                  type="primary"
                  size={isBigScreen ? "middle" : "large"}
                  onClick={handleNextClick}
                  className={!isBigScreen ? "w-100" : ""}
                >
                  {stepCount === (stepsInformation.stepOne.techFor === "device_issue" ? 7 : 6) ? "Let's do this" : "Next Question"}
                  <ArrowRightOutlined className="ms-3" />
                </Button>
              </Row>
            </Row>
          </Col>
        </Row>
      </Content>
      {isContactModalVisible && (
        <ContactModal
          isContactModalVisible={isContactModalVisible}
          closeContactModal={() => setIsContactModalVisible(false)}
        />
      )}
    </Content>
  );
};

export default DiscoveryExperience;
