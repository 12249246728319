// External Imports
import { useState, useEffect } from 'react'
import { Modal, Form, Input, Space, Typography, Button, Tag } from 'antd'

// Internal Imports
import { nameFormat } from '../../../../../../../utils/helper'

const { Item, useForm } = Form
const { TextArea } = Input
const { Text, Title, Paragraph } = Typography

const RequestChangeModal = ({ visible, onOk, onCancel, repository, contractId, contractDetails, sendMessage, tech }) => {
    const [form] = useForm()
    const [loading, setLoading] = useState(false);
    
    
    const populateDefaultValue = () => {
        const techName = nameFormat(`${tech?.firstname} ${tech?.lastname}`) 
        form?.setFieldsValue({
            Change: `Hi ${nameFormat(contractDetails?.Customer_Org?.Name)}, could you please change \n\nThank you,\n${techName}`
        })
    }

    useEffect(() => {
        populateDefaultValue()
    }, [])

    const handleOnFinish = (values) => {
        setLoading(true)
        repository.requestAChange(contractId, {
            ...values,
            Customer_Contact: contractDetails?.Customer_Org?.HIPAA_Primary_Contact
        }).then(async (res) => {
            if(res.data?.status === 'success') {
                const techName = nameFormat(`${tech?.firstname} ${tech?.lastname}`);
                const location = window.location.href;
                // await sendMessage(`${techName} has requested a revision to your contract proposal as follow: ${values.Change}<br><br> To view the details, simply click on the link below:<br> ${location}`)
            }
            setLoading(false)
            populateDefaultValue()
            form?.resetFields()
            onOk()
        }).catch(error => {
            console.error(error)
            setLoading(false)
        })
    }
    
  return (
    <Modal
        visible={visible}
        onCancel={onCancel}
        onOk={onOk}
        footer={false}
        centered
    >
        <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
        <Paragraph className='my-2 disclaimer p-2'>
            Are you sure you want to request a change? This request will be sent as a chat message to the customer
        </Paragraph>
        <Paragraph strong>What would you like changed in the contract proposal?</Paragraph>
        <Form
            form={form}
            onFinish={handleOnFinish}
            onFinishFailed={error => console.error(error)}
            // size="large"
        >
            <Item
                // initialValue="please enter the change"
                name="Change"
                rules={[
                    {
                        required: true,
                        message: 'Please enter the message'
                    }
                ]}
            >
                <TextArea
                    rows={6}
                    showCount
                    maxLength={250}
                />
            </Item>
            <Item>
                <Space className='w-100 justify-content-end'>
                    <Button type="text" className="mt-2" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="submit" loading={loading} className="mt-2">
                        Request
                    </Button>
                </Space>
            </Item>
        </Form>
    </Modal>
  )
}

export default RequestChangeModal