import {useEffect, useState} from 'react'
import {Modal, Typography, Form, Space, Input, Divider, Button, notification, Row, Col} from 'antd'
import moment from "moment";
import {SECONDARY_COLOR} from "../../../../../../utils/constants";
import {TicketDetailsRepository} from "../repository";
import {ViewEditContractRepository} from "../../contracts/contract-details/repository";
import { vi } from 'date-fns/locale';
import { get } from 'firebase/database';

const { Text, Paragraph, Title } = Typography
const { Item, useForm } = Form
const { TextArea } = Input

const resolveList = [
    'The customer has been notified about the ticket resolution.',
    'You can still resume the support for this ticket if asked by customer.',
    'Once payment has been made, ticket will be marked as "Closed"'
]

const ResolveTicketModal = ({ visible, onOk,contractDetails, onCancel, ticketDetails, ticketRepository, getAndSetTicketDetails, getAndSetActivities }) => {
    const [form] = useForm()
    const [loading, setLoading] = useState(false)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const timerStart = ticketDetails?.custom_data?.timer_start;
    let quantity = ticketDetails?.custom_data?.duration;

    const [hoursFormValue, setHoursFormValue] = useState(0)
    const [minutesFormValue, setMinutesFormValue] = useState(0)

    if(quantity) {
        quantity = Number(quantity);
    }
    let Duration = (!!timerStart ? moment.duration(moment().diff(moment(timerStart).utc(false))).asMilliseconds() : 0) + (quantity ? quantity * 60 * 1000 : 0)
    Duration = Duration / 1000 / 60;

    const getExpendedTime = () => {
        if(Duration / 60 >= 1){
            setHours(Math.round(Math.floor(Duration / 60)))
            setHoursFormValue(Math.round(Math.floor(Duration / 60)))
            setMinutes(Math.round(((Duration / 60) - (Math.floor(Duration / 60))) * 60))
            setMinutesFormValue(Math.round(((Duration / 60) - (Math.floor(Duration / 60))) * 60))
            form.setFieldsValue({
                hours: Math.round(Math.floor(Duration / 60)),
                minutes: Math.round(((Duration / 60) - (Math.floor(Duration / 60))) * 60)
            })
        }
        else{
            setMinutes(Math.round(Duration))
            setMinutesFormValue(Math.round(Duration))
            form.setFieldsValue({
                minutes: Math.round(Duration)
            })
        }
    }

    useEffect(() => {
       getExpendedTime()
    }, [ticketDetails?.custom_data, visible])

    return (
        <Modal
            visible={visible}
            open={visible}
            onCancel={loading ? () => false : () => {
                onCancel()
                form.resetFields()
                getExpendedTime()
            }}
            className="resolve-ticket-modal"
            footer={false}
        >
            <Space direction='vertical'>
                <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
                <Paragraph className='my-2 disclaimer p-2'>
                    When the ticket is marked resolved, you can move it back to Open state. Once the invoice is generated and the customer is billed, the ticket will be closed for any further activities. Are you sure you want to mark this ticket as resolved?
                </Paragraph>
                <Form
                    form={form}
                    layout='vertical'
                    onFinish={async (values) => {
                        setLoading(true)
                        let Duration = (!!timerStart ? moment.duration(moment().diff(moment(timerStart).utc(false))).asMilliseconds() : 0) + (quantity ? quantity * 60 * 1000 : 0)

                        try {
                            const payload = {
                                Customer_Contact: ticketDetails?.Reporter_Contact?.id,
                                Duration: Duration / 60 / 1000,
                                Resolution_Details: values.Resolution_Details,
                                Hours: contractDetails.Type == 'Per Ticket'? values.hours : 0,
                                Minutes: contractDetails.Type == 'Per Ticket'? values.minutes : 0,
                                Invoice_Total: (((contractDetails?.custom_data.stripe_price / 100)) * ((hoursFormValue * 60) + (minutesFormValue))).toFixed(2)
                            }
                            
                            await ticketRepository?.resolveTicket(ticketDetails?.id, payload)
                            await getAndSetTicketDetails()
                            await getAndSetActivities()
                            Modal.success({
                                className: 'ticket-updated-successfully-wrapper',
                                icon: null,
                                closable: true,
                                okText: 'Okay',
                                width: 500,
                                title: <Title level={3} className='font-weight-500 m-0'>The ticket has been resolved</Title>,
                                content: (
                                    <div className='mt-2'>
                                        <Text className='font-weight-500'>What's Next:</Text>
                                        <ol>
                                            {
                                                resolveList.map((item, index) => {
                                                    return <li className={"info-list mt-2"} key={`list-item-${index}`}>{item}</li>
                                                })
                                            }
                                        </ol>
                                    </div>
                                )
                            });
                            onOk()
                        } catch (error) {
                            notification.error({
                                message: 'TecMe',
                                description: 'Ticket resolve failed'
                            })
                        }
                        setLoading(false)
                    }}
                >
                    <Item
                        name="Resolution_Details"
                        label="Resolution details"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter resolution details',
                            },
                            {
                                min: 10,
                                message: 'It should be at least 10 characters or more.',
                            },
                        ]}
                    >
                        <TextArea
                            rows={4}
                            showCount
                            maxLength={250}
                        />
                    </Item>

                    {
                        contractDetails.Type == 'Per Ticket' &&
                        <>
                            <Title level={5} >Review Ticket Invoice</Title>

                            <Paragraph className='my-2 disclaimer p-2'>
                                See or change any details in the invoice before the ticket is resolved.
                            </Paragraph>

                            <Row gutter={[10,10]} className={"mt-3"}>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Item
                                        name="hours"
                                        initialValue={hours}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter the hour(s)",
                                            },
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    let hoursFieldValue = parseInt(value) * 60;
                                                    let minutesFieldValue = parseInt(getFieldValue('minutes').toString());

                                                    if((hoursFieldValue + minutesFieldValue) > ((hours*60) + minutes))
                                                        return Promise.reject(new Error('The input must be less than or equal to the tracked time.'));

                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="Number of"
                                            addonAfter="Hour(s)"
                                            type={"number"}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            onChange={(e)=>{
                                                let value = e.target.value;
                                                if(value){
                                                    setHoursFormValue(parseInt(value));
                                                }
                                            }}
                                            onPaste={(e)=>{
                                                e.preventDefault()
                                                return false;
                                            }}
                                        />
                                    </Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                    <Item
                                        name="minutes"
                                        initialValue={minutes}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter the minute(s)",
                                            },
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    let minutesFieldValue = parseInt(value);
                                                    let hoursFieldValue = parseInt(getFieldValue('hours').toString()) * 60;

                                                    if((hoursFieldValue + minutesFieldValue) > ((hours*60) + minutes))
                                                        return Promise.reject(new Error('The input must be less than or equal to the tracked time.'));

                                                    if (value >= 60) {
                                                        return  Promise.reject(new Error('Minutes must be less than or equal to 60'));
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="Number of"
                                            addonAfter="Min(s)"
                                            type={"number"}
                                            max={60}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            onChange={(e)=>{
                                                let value = e.target.value;
                                                if(value){
                                                    setMinutesFormValue(parseInt(value));
                                                }
                                            }}
                                            onPaste={(e)=>{
                                                e.preventDefault()
                                                return false;
                                            }}
                                        />
                                    </Item>
                                </Col>
                            </Row>

                            <div style={{padding:"5px 10px 5px 10px"}}>
                                <Row justify={"space-between"}>
                                    <Col>
                                        <Typography.Text>Hourly Rate</Typography.Text>
                                    </Col>
                                    <Col>
                                        <Typography.Text strong={true}>${contractDetails?.Billing_Rate / 100}</Typography.Text>
                                    </Col>
                                </Row>
                            </div>

                            <div style={{background:"#EDEDED",padding:"5px 10px 5px 10px",marginTop:5}}>
                                <Row justify={"space-between"}>
                                    <Col>
                                        <Typography.Text strong={true}>Invoice Total</Typography.Text>
                                    </Col>
                                    <Col>
                                        <Typography.Text strong={true} style={{color:SECONDARY_COLOR}}>
                                            ${(((contractDetails?.custom_data.stripe_price / 100)) * ((hoursFormValue * 60) + (minutesFormValue))).toFixed(2)}</Typography.Text>
                                    </Col>

                                </Row>
                            </div>
                        </>
                    }

                    <Divider />
                    <Space align='center' className='w-100 justify-content-end'>
                        <Item>
                            <Button type='text' onClick={loading ? () => false : () => {
                                onCancel()
                                form.resetFields()
                                getExpendedTime()
                            }}>Cancel</Button>
                        </Item>
                        <Item>
                            <Button type='primary' htmlType="submit" loading={loading}>Resolve</Button>
                        </Item>
                    </Space>
                </Form>
            </Space>
        </Modal>
    )
}

export default ResolveTicketModal