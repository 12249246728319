import React, { useState } from 'react'
import { Avatar, Typography } from 'antd'
import QuoteIcon from '../assets/images/quote-icon.svg'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { capitalizeFirstLetter, nameFormat } from '../../../../../../utils/helper';

const { Title, Paragraph } = Typography

const ItcTestimonials = ({ brandColor, itcContent }) => {
    const [slideIndex, setSlideIndex] = useState(0)
 
    function PrevArrow({className, style, onClick}) {
        return (
            <svg className={className} onClick={onClick} style={{...style, display: "block"}} width="26" height="21" viewBox="0 0 26 21" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.42889 8.89071L13.0307 2.28894L10.9274 0.244141L0.820312 10.3513L10.9274 20.4584L13.0307 18.4136L6.42889 11.8118H26.0005V8.89071H6.42889Z"
                    fill={slideIndex > 0 ? 'black' : 'black'}/>
            </svg>
        );
    }

    function NextArrow({className, style, onClick}) {
        return (
            <svg className={className} onClick={onClick} style={{...style, display: "block"}} width="26" height="21" viewBox="0 0 26 21" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M20.2547 8.89071L13.6529 2.28894L15.7562 0.244141L25.8633 10.3513L15.7562 20.4584L13.6529 18.4136L20.2547 11.8118H0.683086V8.89071H20.2547Z"
                    fill={slideIndex == (itcContent?.Testimonials?.length - 1) ? 'black' : 'black'}/>
            </svg>
        );
    }

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 7000,
        slidesToShow: 1,
        slidesToScroll: 1,
        // centerMode: true,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        afterChange: current => setSlideIndex(current),
    };

    if(itcContent?.Testimonials?.length < 1) return null

    return (
        <section className='itc-testimonial-section' id="testimonials">
            <div className="itc-testimonial-ctn">
                <div className="testimonial-title">
                    <div>
                        <div className="itc-top-border" style={{background:brandColor}}></div>
                        <Title level={1} className="itc-testimonial-heading">What Our Client Says</Title>
                    </div>
                </div>
                <div className="testimonial-content-ctn">
                    <div className="circle-bubble" style={{backgroundColor:brandColor}}></div>
                    <div className="circle-bubble" style={{backgroundColor:brandColor}}></div>
                    <Slider {...settings}>
                        {
                            itcContent?.["Testimonials"]?.map((item) => 
                                (
                                    <div className='testimonial-content-inner'>
                                        <div className="testimonial-content">
                                            <div className="testifier-img-ctn">
                                                <div className="testifier-img-inner">
                                                        <Avatar 
                                                            size={130} 
                                                            src={item?.img_Url} 
                                                            style={{
                                                                background:"##EEE", 
                                                                color:"#F04A22", 
                                                                fontSize:50
                                                            }}
                                                        >
                                                            {item?.Client_First_Name.charAt(0)}
                                                        </Avatar>
                                                    <div className='quote-ctn' style={{backgroundColor:brandColor}}>
                                                        <img src={QuoteIcon} alt="Quote Icon" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="testifier-text-ctn">
                                                <Paragraph className="testifier-description">{capitalizeFirstLetter (item?.Contents)}</Paragraph>
                                                <Title level={4} className="testifier-name">{nameFormat(`${capitalizeFirstLetter(itcContent["Testimonials"]?.[slideIndex]?.Client_First_Name)} ${capitalizeFirstLetter(itcContent["Testimonials"]?.[slideIndex]?.Client_Last_Name)}`)}</Title>
                                                <Paragraph className="testifier-position">{capitalizeFirstLetter(itcContent["Testimonials"]?.[slideIndex]?.Client_Title)} at { capitalizeFirstLetter(itcContent["Testimonials"]?.[slideIndex]?.Client_Company)}</Paragraph>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )
                        }
                    </Slider> 

                    {/* <div className="testimonial-content">
                        <div className="testifier-img-ctn">
                            <div className="testifier-img-inner">
                                    <Avatar 
                                        size={130} 
                                        src={itcContent["Testimonials"]?.[slideIndex]?.img_Url} 
                                        style={{
                                            background:"##EEE", 
                                            color:"#F04A22", 
                                            fontSize:50
                                        }}
                                    >
                                        {itcContent["Testimonials"]?.[slideIndex]?.Client_First_Name.charAt(0)}
                                    </Avatar>
                                <div className='quote-ctn' style={{backgroundColor:brandColor}}>
                                    <img src={QuoteIcon} alt="Quote Icon" />
                                </div>
                            </div>
                        </div>
                        <div className="testifier-text-ctn">
                            <Paragraph className="testifier-description">{capitalizeFirstLetter (itcContent["Testimonials"]?.[slideIndex]?.Contents)}</Paragraph>
                            <Title level={4} className="testifier-name">{ nameFormat(`${capitalizeFirstLetter(itcContent["Testimonials"]?.[slideIndex]?.Client_First_Name)} ${capitalizeFirstLetter(itcContent["Testimonials"]?.[slideIndex]?.Client_Last_Name)}`)}</Title>
                            <Paragraph className="testifier-position">{itcContent["Testimonials"]?.[slideIndex]?.Client_Title} at {itcContent["Testimonials"]?.[slideIndex]?.Client_Company}</Paragraph>
                        </div>
                       { 
                            itcContent?.Testimonials?.length > 1 &&
                            <div className="testimonial-btns">
                                <svg onClick={handlePrev} style={{ display: "block", cursor:"pointer"}} width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.42889 8.89071L13.0307 2.28894L10.9274 0.244141L0.820312 10.3513L10.9274 20.4584L13.0307 18.4136L6.42889 11.8118H26.0005V8.89071H6.42889Z"
                                        fill={slideIndex > 0 ? 'black' : 'rgba(0, 0, 0, 0.25)'}/>
                                </svg>
                                <svg onClick={handleNext}  style={{ display: "block", cursor:"pointer"}} width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M20.2547 8.89071L13.6529 2.28894L15.7562 0.244141L25.8633 10.3513L15.7562 20.4584L13.6529 18.4136L20.2547 11.8118H0.683086V8.89071H20.2547Z"
                                        fill={slideIndex == (itcContent?.Testimonials?.length - 1) ? 'rgba(0, 0, 0, 0.25)' : 'black'}/>
                                </svg>
                            </div>
                        }
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default ItcTestimonials