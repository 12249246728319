// External Imports
import { useEffect, useState } from 'react';
import { Row, Layout, Col } from 'antd';
import MetaTags from 'react-meta-tags';

// Internal Imports
import { META_DESCRIPTION } from '../../../../utils/constants';
import ROUTES from '../../../../utils/routes';
import PageHeaderWrapper from '../../../components/page-header-breadcrumbs';
import AssignedTicketsContent from '../../../pages/common/private/contracts/contract-details/components/assigned-tickets-content';
import NewSupportTicketModal from '../../../pages/common/private/contracts/contract-details/components/new-support-ticket-modal';
import { useSelector } from 'react-redux';
import { FirebaseHelper, FirestoreHelper } from '../../../../utils/firebase-helper';
import { RtmRepository } from '../../common/private/real-time-messaging/repository/rtm-repository';
import { ContractRepository } from '../../common/private/contracts/contract-details/repository';

const { Content } = Layout

const TicketListing = () => {
    const user = useSelector((state) => state.user);
    const tech = useSelector((state) => state.tech);
    const token = user?.token || tech?.token
    const [isNewSupportTicketModalVisible, setIsNewSupportTicketModalVisible] = useState(false)
    
    const handleNewSupportTicketButtonClick = () => {
        setIsNewSupportTicketModalVisible(true)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <MetaTags>
                <title>Tickets - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <Content className="main">
                <div className="page">
                        <Row gutter={[20, 20]}>
                            <Col span={24}>
                                <PageHeaderWrapper
                                    routes={[
                                        {
                                            path: ROUTES.TICKET_LISTING,
                                            breadcrumbName: 'Tickets',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col>
                                <div className="auth-container">
                                    <AssignedTicketsContent handleNewSupportTicketButtonClick={handleNewSupportTicketButtonClick} />
                                </div>
                            </Col>
                        </Row>
                </div>
            </Content>
            <NewSupportTicketModal
                user={user}
                tech={tech}
                repository={new ContractRepository(token)}
                visible={isNewSupportTicketModalVisible}
                onOk={() => setIsNewSupportTicketModalVisible(false)}
                onCancel={() => setIsNewSupportTicketModalVisible(false)}
            />
        </>
    )
}

export default TicketListing