import React, { Component, Fragment } from 'react'
import { Row, Col, Layout, Input, Button, Popover, Card, Skeleton, Modal, Spin, notification, Typography, Avatar, Space, Table, Image, Tag, List, Badge } from 'antd';
import { connect } from "react-redux";
import { Link } from "react-router-dom"; 
import ROUTES from '../../../../../utils/routes';
import { DeviceRepository } from './repository/device-repository'
import {DEVICEIMAGE, META_DESCRIPTION} from '../../../../../utils/constants';
import MetaTags from "react-meta-tags";
import ActionIcon from  './assets/icons/action-icon.svg';
import { DeleteOutlined, EditOutlined, EyeOutlined, FolderOutlined, FolderViewOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import PageHeaderWrapper from "../../../../components/page-header-breadcrumbs";
import CustomRenderEmpty from '../../../../components/custom-render-empty';
import PageTitle from '../../../../components/page-title';
import SearchInput from '../../../../components/search-input';
import { capitalizeFirstLetter } from '../../../../../utils/helper';
import AddEditDeviceModal from './add-edit-device/modal';
import './assets/css/add-edit-device.css'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper';
import { ThirtyFpsSelect } from '@mui/icons-material';

const { confirm } = Modal;
const { Content } = Layout;
const { Paragraph, Title, Text } = Typography
const { Meta } = Card;
const listData = [];


class Devices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            showData: [],
            deleteLoading: false,
            formStatus: false,
            dataLoading: false,
            sortedInfo: null,
            deleteModal: false,
            activateModal: false,
            selectedDevice: "",
            selectedRecord:{},
            deviceDetailsModalVisible:false,
            windowWidth: window.innerWidth,
        };

        const devicesPopoverContent = (item) => {
            return(
                <div className="devices-popover-content">   
                    <Space
                    onClick = {() => 
                        this.setState({selectedRecord:item, deviceDetailsModalVisible:true
                    })}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <EyeOutlined />
                            <Paragraph style={{marginBottom:0, whiteSpace:"nowrap", marginLeft: 8}}>View Device Details</Paragraph> 
                        </div>
                    </Space>
                    <Space className='popover-action-item' align='center'>
                        <Link 
                            onClick={(e) => {
                                e.stopPropagation();
                            }} 
                                to={{pathname: ROUTES.DEVICES, search: '?option=edit', state: { item }}}
                                style={{textDecoration:"none", display:"flex", whiteSpace:"nowrap"}}
                                >
                                <EditOutlined style={{ marginRight: 8, color:"#000000D9"}}/>
                                <Paragraph style={{marginBottom:0}}>Edit Device</Paragraph>
                        </Link>
                    </Space>
                    <Space 
                        onClick={(e) => {
                            if (item.Active === "true") {
                                this.setState({ selectedDevice: item, deleteModal: true });
                            } else {
                                this.setState({ selectedDevice: item, activateModal: true });
                            }
                            e.stopPropagation();
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {item.Active === "true" ? 
                                <CloseCircleOutlined />
                                :
                                <CheckCircleOutlined />
                            }
                            <Paragraph style={{marginBottom:0, marginLeft: 8}}>{item.Active === "true" ? "Deactivate Device" : "Activate Device"}</Paragraph> 
                        </div>
                    </Space>
                
                </div>
            )
        }

        this.columns = [
            {
                title: 'Device',
                dataIndex: 'HardwareName',
                key: 'HardwareName',
                width: '15%',
                web: true,
                mobile: true,
                sorter: {
                    compare: (a, b) => a.HardwareName.localeCompare(b.HardwareName),
                    multiple: 1,
                },
                render: (text, record) => <div style={{ display: 'flex', alignItems: 'center', cursor:'pointer'}}>
                    <Avatar shape="square" size="large" style={{ marginRight: '0.5rem', minWidth: '50px' }} src={<img loading="lazy"  src={record.Pic_1 ? record.Pic_1 : DEVICEIMAGE} style={{ width: '100%', height: '100%' }} />} />
                    <span>{capitalizeFirstLetter(record.HardwareName)}</span>
                </div>
            },
            /*{
               
                title: 'Device',
                dataIndex: 'HardwareName',
                key: 'HardwareName',
                width: '15%',
                web: false,
                mobile: true,
                sorter: {
                    compare: (a, b) => a.HardwareName.localeCompare(b.HardwareName),
                    multiple: 1,
                },
                render: (text, record) => <div style={{ display: 'flex', alignItems: 'center', }}>
                    {capitalizeFirstLetter(record.HardwareName)}
                </div>
             },*/
            {
               
                title: 'Brand',
                dataIndex: 'Make',
                key: 'Make',
                width: '15%',
                web: true,
                mobile: true,
                sorter: {
                    compare: (a, b) => a.Make.localeCompare(b.Make),
                    multiple: 1,
                },
                render: (text, record) => <div>
                {capitalizeFirstLetter(record.Make)}
                </div>
            },
            {
                title: 'Model',
                dataIndex: 'Model',
                key: 'Model',
                width: '15%',
                web: true,
                mobile: false,
                sorter: {
                    compare: (a, b) => a.Model.localeCompare(b.Model),
                    multiple: 1,
                },
                render: (text, record) => <div>
                {record?.Model ? capitalizeFirstLetter(record.Model) : "N/A"}
                </div>
            },
            {
                title: 'Type',
                dataIndex: 'Type',
                key: 'Type',
                web: true,
                mobile: false,
                sorter: {
                    compare: (a, b) => a.Type.localeCompare(b.Type),
                    multiple: 1,
                },
                width: '15%',
                render: (_, record) => <div ><p style={{ backgroundColor: '#FFF2E8', margin: 0, color: '#DB5E3F', border: '1px solid #FEBA98', width: 'max-content', padding: '0 0.5rem', alignItems: 'center' }}>{record?.Type === "Other" ?  record?.Other_Type : record?.Type}</p></div>
            },
            {
                title: 'Status',
                dataIndex: 'Status',
                key: 'Status',
                web: true,
                mobile: false,
                sorter: {
                    compare: (a, b) => a.Active.localeCompare(b.Active),
                },
                width: '15%',
                render: (_, record) => <Tag color={record.Active === "true" ? "green" : "red"}>{record.Active === "true" ? "Active" : "Inactive"}</Tag>
            },
            {
             
                title: 'Action',
                width: '15%',
                dataIndex: 'Id',
                key: "Id",
                web: true,
                mobile: true,
                render: (text, record) => <Space size={15} wrap>
                    <Link
                      style={{color:"#F04A22", textDecoration:"none", border:"1px solid #F04A22", padding:"3px 15px", borderRadius:"2px"}}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                        to={{ pathname: ROUTES.DEVICES, search:'?option=edit', state: { record } }}>
                        Edit
                    </Link>
                    <div>   
                        {
                            record.Active === "true" ?
                            <Text  
                            style={{color:"#F04A22"}} 
                            onClick={(e) => { 
                                this.setState({ selectedDevice: record, deleteModal: true }) 
                                e.stopPropagation()
                            }}>Deactivate</Text> :
                            <Text  
                            style={{color:"#F04A22"}} 
                            onClick={(e) => { 
                                this.setState({ selectedDevice: record, activateModal: true }) 
                                e.stopPropagation()
                            }}>Activate</Text>
                        }
                    </div>
                </Space>,
            },

        ];

        this.openNotification = (value) => {
            notification.open({
                message: value.message,
                description: value.description,
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
        };

        this.display = (responsive) => {
            return (  
                <CustomRenderEmpty
                    description={"Save your device information to quickly book repair services in the future."} 
                    extra={  
                    <Button onClick={() => { this.props.history.push({
                        pathname:ROUTES.DEVICES,
                        search:'?option=add'
                    }) }} block> 
                        + Add Device 
                    </Button>
                    }>

                {
                    responsive == true ?
                    <Table  
                        onRow={(record) => {
                         return {
                            onClick: () => this.setState({
                                selectedRecord:record,
                                deviceDetailsModalVisible:true
                            })
                         }; 
                        }} sticky={true} grid={{
                            gutter: 16,
                            xs: 0,
                            sm: 0,
                            md: 0,
                            lg: 1,
                            xl: 1,
                            xxl: 1,
                        }}rowKey='Id' showSorterTooltip={false} columns={this.columns} dataSource={this.state.showData} scroll={{ x: '100%' }} loading={this.state.dataLoading || this.state.deleteLoading} 
                    />
                    :
                    <List
                        grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 3,
                        }}
                        dataSource={this.state.showData}
                        loading={this.state.dataLoading || this.state.deleteLoading}
                        columns={this.columns}
                        rowKey="Id" 
                        pagination={{
                        onChange: page => {
                            window.scrollTo({
                            top:0,
                            behavior:"smooth"
                            })
                        },
                        pageSize: 10,
                        }}
                    renderItem={
                        item => {
                            return (
                            <List.Item 
                            //onClick = {() => this.setState({selectedRecord:item, deviceDetailsModalVisible:true})} 
                            key={item?.id}>
                                <Card
                                    style={{width:"100%", minHeight:"200px", maxHeight:"350px"}} 
                                    title={
                                        <div style={{ display: "flex", justifyContent: "space-between", whiteSpace:"wrap"}}>
                                            <Space>
                                                <Avatar shape="square" size={30} src={item.Pic_1 ? item.Pic_1 : DEVICEIMAGE} /> 
                                                {capitalizeFirstLetter(item.HardwareName)}
                                            </Space>
                                            <Popover content={devicesPopoverContent(item)}  trigger="click" placement='bottom' overlayClassName="devices-popover" arrowPointAtCenter>
                                                <img src={ActionIcon} 
                                                alt="action icon" 
                                                onClick={
                                                    (e) => {
                                                        e.stopPropagation()
                                                    }}/>  
                                            </Popover>
                                        </div>
                                    } 
                                >
                                    <Space style={{display: 'flex', justifyContent: 'space-between', whiteSpace:"wrap"}} direction="horizontal">
                                        <Space direction="vertical" size="large">
                                            <div class="left-side-1">
                                                <div class="text1" style={{color:"#00000073", fontSize:14, fontWeight:400}}> 
                                                    Brand 
                                                </div>
                                                <div class="text2" style={{color:"#000000", fontSize:16, fontWeight:400}}>
                                                    {item.Make}
                                                </div>
                                            </div>
                                            <div class="right-side-1">
                                                <div class="text1" style={{color:"#00000073", fontSize:14, fontWeight:400}}> 
                                                    Model
                                                </div>
                                                <div class="text2" style={{color:"#000000", fontSize:16, fontWeight:400}}>
                                                    {item.Model === null ? "N/A" : <>{item.Model}</>}
                                                </div>
                                            </div>
                                        </Space>
                                        <Space direction="vertical" size="large">
                                            <div class="left-side-2">
                                                <div class="text1" style={{color:"#00000073", fontSize:14, fontWeight:400}}> 
                                                    Type 
                                                </div>
                                                <div class="text2" style={{color:"#000000", fontSize:16, fontWeight:400}}>
                                                    {item.Type}
                                                </div>
                                            </div>
                                            <div class="right-side-2">
                                                <div class="text1" style={{color:"#00000073", fontSize:14, fontWeight:400}}> 
                                                    Status
                                                </div>
                                                <div class="text2">
                                                    <Badge text={<span style={{ fontSize: "16px", color:"#000000", fontWeight:400}}>{item.Active === "true" ? "Active" : "Inactive"}</span>} color={item.Active === "true" ? "green" : "red"}/>
                                                </div>
                                            </div>
                                        </Space>
                                    </Space> 
                                </Card>
                            </List.Item>
                        )}
                    }
                />
             }
          </CustomRenderEmpty>
        )}
        
        this.searchData = [];
        this.deviceRepository = new DeviceRepository(this.props.user.token)
        this.handleGetDevices = this.handleGetDevices.bind(this)


    }

    handleGetDevices(){
        console.log("..fetching")
        this.deviceRepository.getDevices()
            .then(res => {
                this.setState({ 
                    data: res.data.data, 
                    dataLoading: false, 
                    showData: res.data.data });
                console.log(res.data.data)
            })
            .catch(err => {
                this.setState({ dataLoading: false });
            })
        
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({ dataLoading: true })
        this.handleGetDevices()
        
    }

    componentDidUpdate(prevProps, prevState) { 
        // check whether client has changed 
        if(prevState.updatedData !== this.state.updatedData) this.handleGetDevices()
        
      } 
    render() {
        let { sortedInfo, filteredInfo } = this.state;
        sortedInfo = sortedInfo || {};
        let _this = this
        const params = new URLSearchParams(_this.props.location.search)
    
        return (
            <Fragment>
                <MetaTags>
                    <title >My devices | TecMe </title>
                    <meta name="description" content={META_DESCRIPTION} />
                    <meta name="robots" content="noindex, nofollow" />
                </MetaTags>
                <AddEditDeviceModal 
                    open={params.get('option')} 
                    onOk={() => {
                        this.props.history.push(this.props.location.pathname)
                    }} 
                    onCancel={() => {
                        this.props.history.push(this.props.location.pathname)
                    }} 
                    history={this.props.history}
                    location={this.props.location}
                    option={params.get('option')}
                    fetchDevices={this.handleGetDevices}
                />
                <Modal   
                    title={capitalizeFirstLetter(this.state.selectedRecord?.HardwareName)}
                    visible={this.state.deviceDetailsModalVisible}
                    onOk={() => this.setState({deviceDetailsModalVisible:false})}
                    onCancel={() => this.setState({deviceDetailsModalVisible:false})}
                    width={800}
                    footer={null}
                >
                    <Row gutter={[24, 24]} align="middle">
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <div className='device-details-image-ctn'>
                                <img className='device-image' src={this.state.selectedRecord?.Pic_1 ? this.state.selectedRecord?.Pic_1 : DEVICEIMAGE} alt="" />
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Row gutter={[12, 12]}>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Paragraph style={{margin:0, fontWeight:600}}>Device Nickname</Paragraph>
                                    <Title style={{margin:0, fontWeight:400}} level={5}>{capitalizeFirstLetter(this.state.selectedRecord?.HardwareName || '--')}</Title>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Paragraph style={{margin:0, fontWeight:600}}>Device Type</Paragraph>
                                    <Title style={{margin:0, fontWeight:400}} level={5}>{this.state.selectedRecord?.Other_Type || this.state.selectedRecord?.Type || '--'}</Title>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Paragraph style={{margin:0, fontWeight:600}}>Device Brand</Paragraph>
                                    <Title style={{margin:0, fontWeight:400}} level={5}>{this.state.selectedRecord?.Make || '--'}</Title>
                                </Col>
                                {   this.state.selectedRecord?.Operating_System_Name &&
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Paragraph style={{margin:0, fontWeight:600}}>Operating System</Paragraph>
                                        <Title style={{margin:0, fontWeight:400}} level={5}>{this.state.selectedRecord?.Operating_System_Name || '--'}</Title>
                                    </Col>
                                }
                                {
                                    this.state.selectedRecord?.Model &&
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Paragraph style={{margin:0, fontWeight:600}}>Device Model</Paragraph>
                                        <Title style={{margin:0, fontWeight:400}} level={5}>{this.state.selectedRecord?.Model|| '--'}</Title>
                                    </Col>
                                }
                               { this.state.selectedRecord?.MAC_Address &&
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Paragraph style={{margin:0, fontWeight:600}}>MAC Address</Paragraph>
                                    <Title style={{margin:0, fontWeight:400}} level={5}>{this.state.selectedRecord?.MAC_Address|| '--'}</Title>
                                </Col>}
                                { this.state.selectedRecord?.IP_Address &&
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Paragraph style={{margin:0, fontWeight:600}}>IP Address</Paragraph>
                                        <Title style={{margin:0, fontWeight:400}} level={5}>{this.state.selectedRecord?.IP_Address || '--'}</Title>
                                    </Col>
                                }
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Paragraph style={{margin:0, fontWeight:600}}>Device Description</Paragraph>
                                    <Title style={{margin:0, fontWeight:400}} level={5}>{this.state.selectedRecord?.HardwareDescription || '--'}</Title>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal>
                <Modal visible={this.state.deleteModal} centered closable={false}
                okText="Deactivate"
                onOk={() => {
                    this.setState({
                        deleteModal: false,
                        deleteLoading: true

                    });
                    this.deviceRepository.deviceDelete(this.state.selectedDevice.id).then((response) => {

                        notification['success']({
                            message: 'TecMe',
                            description:
                                'Device deactivated successfully',
                        });
                        let filterData = _this.state.data.filter(device => device.id != this.state.selectedDevice.id)
                        this.setState({
                            // data: filterData,
                            // showData: filterData,
                            deleteLoading: false,

                        });
                        this.handleGetDevices()

                    }).catch((error) => {
                        this.setState({
                            deleteLoading: false,

                        })
                        console.log(error)
                    })
                }}
                       onCancel={(e)=>this.setState({ deleteModal: false })}
                >
                     <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
                    <Paragraph className='my-2 disclaimer p-2'>
                    By performing this action, the device will be deactivated and won't show up when you're trying to create a ticket or book a service. Please make sure you're certain about deactivating the item before you proceed.
                    </Paragraph>
                </Modal>
                {/* Activate Modal */}
                <Modal visible={this.state.activateModal} centered closable={false}
                okText="Activate"
                onOk={() => {
                    this.setState({
                        activateModal: false,
                        deleteLoading: true

                    });
                    ApiBaseHelper.put({
                        url: `device/v2/${this.state.selectedDevice?.id}`,	
                        headers: {
                            Authorization: 'Bearer ' + this.props.user.token,
                        },
                        payload: {
                            // ...this.state.selectedDevice,
                            Active:  1
                        }
                    }).then((res) => {
                        if(res){
                            notification.success({
                                message: 'TecMe',
                                description: 'Device Activated Successfully',
                            });
                            this.setState({
                                deleteLoading: false
                            });
                            this.handleGetDevices()
                        }
                    }).catch((e) => {
                        console.log(e)
                        this.setState({
                            deleteLoading: false
                        });
                        notification.error({
                            message: 'TecMe',
                            description: 'Activating Device Failed',
                        });
                    })
                }}
                onCancel={(e)=>this.setState({ 
                    activateModal: false
                })}
                > 
                     <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
                    <Paragraph className='my-2 disclaimer p-2'>
                        By performing this action, the device will be activated and will show up when you're trying to create a ticket or book a service. 
                    </Paragraph>
                </Modal>
                <Content className="main">
                    <div className="page">
                        <div style={{ minHeight: '800px' }}>

                            <Row gutter={[15, 15]} justify={"center"}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div >
                                        <div>
                                            <Row gutter={[20, 20]} align={"middle"} justify={"space-between"}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                    <PageHeaderWrapper
                                                        routes={[
                                                            {
                                                                path: ROUTES.DEVICES,
                                                                breadcrumbName: 'Devices',
                                                            },
                                                        ]}
                                                    />
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <div className={"auth-container"}>
                                                       
                                                        <div className="auth-page-ctn">
                                                            <PageTitle 
                                                                title="Devices" 
                                                                extra={ 
                                                                    <Button onClick={() => { this.props.history.push({
                                                                        pathname:ROUTES.DEVICES,
                                                                        search:'?option=add'
                                                                    }) }}>+ Add New Device </Button>
                                                                }
                                                                search={
                                                                    <SearchInput 
                                                                        placeholder="Search Devices"
                                                                        onChange={(event) => {

                                                                            this.searchData = this.state.data.filter((val) => {
                                                                                if (event.target.value == "") {

                                                                                    return val
                                                                                }
                                                                                else if (val.HardwareName.toLowerCase().includes(event.target.value.toLowerCase()) || val.Model.toLowerCase().includes(event.target.value.toLowerCase()) || val.Make.toLowerCase().includes(event.target.value.toLowerCase()) || val.Other_Type.toLowerCase().includes(event.target.value.toLowerCase())) {

                                                                                    return val
                                                                                }

                                                                            })

                                                                            this.setState({ showData: this.searchData })
                                                                        }}
                                                                    />
                                                                } 
                                                            />
                                                            <Row gutter={[16, 16]}  >
                                                                <Col xs={0} sm={0} md={0} lg={24} xl={24}>
                                                                    {this.display(true)}
                                                                </Col>
                                                                <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                                                                    {this.display(false)}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </div>
                    </div>
                </Content>
            </Fragment>
        )
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(Devices);
