import React, { Component, Fragment } from "react";
import {

    isMobile
} from "react-device-detect";
import {
    Layout,
    Select,
    Row,
    Col,
    Divider,
    Typography,
    Button,
    Card,
    Spin,
    Form,
    Checkbox,
    message,
    notification
} from 'antd';
import moment from 'moment';
import { ApiBaseHelper } from "../../../../../utils/api-base-helper";
import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import { Skeleton, Switch, List, Avatar } from 'antd';
import { StarOutlined, LikeOutlined, MessageOutlined } from '@ant-design/icons';
import {META_DESCRIPTION} from "../../../../../utils/constants";
import MetaTags from "react-meta-tags";
import ROUTES from "../../../../../utils/routes";
import PageHeaderWrapper from "../../../../components/page-header-breadcrumbs";
import PageTitle from "../../../../components/page-title";
const antIcon = <LoadingOutlined style={{ fontSize: 22, color: "black" }} spin />;
const { Content } = Layout;
const { Title, Paragraph, Text } = Typography;

const { Option } = Select;
const listData = [];
for (let i = 0; i < 9; i++) {
    listData.push({
        href: 'https://ant.design',
        title: `ant design part ${i}`,
        avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
        description:
            'Ant Design, a design language for background applications, is refined by Ant UED Team.',
        content:
            'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
    });
}
class ManageSchedule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            schedule: [],
            IsformChange: 0,
            formLoading: false,
            currentStartTime: ""
        };
        this.openNotification = (value) => {
            notification.open({
                message: value.message,
                description: value.description,
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
        };

        this.scheduleForm = this.scheduleForm.bind(this);

        this.timeSlots = [];
        for (let hour = 0; hour < 24; hour++) {
            for (let min = 0; min < 60; min = min + 15) {
                this.timeSlots.push(moment(`${hour}:${min}`, 'HH:mm').format('h:mm a'));
            }
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        ApiBaseHelper.get({
            url: `web/tech/${this.props.tech.id}/schedule`,
        }).then((response) => {
            this.setState({ formLoading: true })
            const value = response.data.data.Schedule
            let currentDate = moment().format('YYYY-MM-DD');
            let scheduleSlots = []
            let week = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
            let scheduleDays = []
            Object.keys(value).map(val => {
                if (value[val].length != 0) {
                    scheduleDays.push(val)
                    scheduleSlots.push({
                        day: val.slice(0, 3).toUpperCase(),
                        fullDay: val,
                        active: true,
                        slots: value[val].map(value => {
                            let startTime = moment(currentDate + 'T' + value['Start_Time']).local();
                            let endTime = moment(currentDate + 'T' + value['End_Time']).local();
                            return {
                                Start_Time: startTime,
                                End_Time: endTime,
                            }
                        })
                    })
                }

            })


            let unique1 = week.filter((o) => scheduleDays.indexOf(o) === -1);
            let unique2 = scheduleDays.filter((o) => week.indexOf(o) === -1);

            const unique = unique1.concat(unique2);
            unique.map(day => {
                scheduleSlots.push({
                    day: day.slice(0, 3).toUpperCase(),
                    fullDay: day,
                    active: false,
                    slots: [{
                        Start_Time: moment('09:00:00', 'HH:mm:ss').local(),
                        End_Time: moment('18:00:00', 'HH:mm:ss').local(),
                    }]

                })
            })
            const sorter = {
                "monday": 1,
                "tuesday": 2,
                "wednesday": 3,
                "thursday": 4,
                "friday": 5,
                "saturday": 6,
                "sunday": 7
            }

            scheduleSlots.sort(function sortByDay(a, b) {
                let day1 = a.fullDay.toLowerCase();
                let day2 = b.fullDay.toLowerCase();
                return sorter[day1] - sorter[day2];
            });
            this.setState({ schedule: scheduleSlots })
            this.setState({ formLoading: false })


        }).catch((error) => {

        })

    }

    render() {
        return <Fragment>
            <MetaTags>
                <title >Manage Schedule | TecMe </title>
                <meta name="description" content={META_DESCRIPTION} />
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <Content className="main">
                <div className="page">
                    <Row gutter={[20, 20]} justify={"center"}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <PageHeaderWrapper
                                // title="Availability"
                                // subTitle={"Select days and time slots based on your availability for work."}
                                routes={[
                                    {
                                        path: ROUTES.MANAGE_SCHEDULE,
                                        breadcrumbName: 'Availability',
                                    },
                                ]}
                            />
                        </Col>
                        <div className="auth-container">
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <div className="steps-content auth-page-ctn">
                                    <PageTitle 
                                        title="Availability"
                                    />
                                    {this.scheduleForm()}
                                </div>
                            </Col>
                        </div>
                    </Row>
                </div>
            </Content>
        </Fragment>
    }


    scheduleForm() {
        let count = 0;
        return <div className="schedule-availability-form">

            {!this.state.schedule.length ?
                
                    <List
                        itemLayout="vertical"
                        size="large"
                        dataSource={listData}
                        renderItem={item => (
                            <List.Item
                                key={item.title}


                            >
                                <Skeleton >
                                    <List.Item.Meta
                                        avatar={<Avatar src={item.avatar} />}
                                        title={<a href={item.href}>{item.title}</a>}
                                        description={item.description}
                                    />
                                    {item.content}
                                </Skeleton>
                            </List.Item>
                        )}
                    />
               : <Form
                    layout="vertical"
                    scrollToFirstError
                    name="schedule_form"
                    onFinish={(values) => {

                        let { schedule } = this.state;
                        let activeDays = 0;
                        schedule.map((item, index) => {
                            if (item['active']) {
                                activeDays++;
                            }
                        });
                        if (activeDays == 0) {
                            message.error('Please add schedule for at least one day.');
                            return;
                        }
                        // if (this.state.IsformChange == 0) {
                        //     return
                        // }
                        this.setState({ IsformChange: 0 })
                        const payload = [];
                        this.state.schedule.map(val => {
                            if (val.active == true) {
                                payload.push({
                                    day: val.fullDay,
                                    slots: val.slots.map(va => {
                                        return {
                                            startTime: moment(va['Start_Time']).utc().format('HH:mm:ss'),
                                            endTime: moment(va['End_Time']).utc().format('HH:mm:ss')

                                        }
                                    })
                                })
                            }
                            else if (val.active == false) {
                                payload.push({
                                    day: val.fullDay,
                                    slots: []
                                })
                            }

                        })

                        const sorter = {
                            "monday": 1,
                            "tuesday": 2,
                            "wednesday": 3,
                            "thursday": 4,
                            "friday": 5,
                            "saturday": 6,
                            "sunday": 7
                        }

                        payload.sort(function sortByDay(a, b) {
                            let day1 = a.day.toLowerCase();
                            let day2 = b.day.toLowerCase();
                            return sorter[day1] - sorter[day2];
                        });

                        const payloadData = { schedule: payload }


                        this.setState({ loading: true })
                        ApiBaseHelper.put({
                            url: "web/v2/tech/schedule",
                            headers: {
                                Authorization: 'Bearer ' + this.props.tech['token'],
                            },
                            payload: payloadData
                        }).then((response) => {
                            // this.props.history.push("/")
                            this.openNotification({ message: 'Manage Schedule Notification', description: 'Schedule saved successfully', })
                            this.setState({ loading: false })
                        }).catch((error) => {
                            this.openNotification({ message: 'Manage Schedule Notification', description: 'Failed to save schedule. Try again!', })
                            this.setState({ loading: false })

                        })

                    }}
                    onFinishFailed={(e) => {
                        // this.nextStep();
                    }}
                >
                    <div className="techinfo text-center rounded">
                        {this.state.schedule.map((scheduleItem, scheduleIndex) => {
                            return <Fragment key={scheduleIndex}>
                                <Row gutter={[20, 5]} key={`${scheduleIndex}-schedule`}>
                                    <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                                        <Form.Item
                                            name={`${scheduleIndex}-active`}
                                            initialValue={scheduleItem['active']}
                                            valuePropName={"checked"}
                                        >
                                            <Checkbox
                                                writable={true}
                                                onChange={(e) => {
                                                    this.setState({ IsformChange: 1 })
                                                    let currentSchedule = JSON.parse(JSON.stringify(this.state.schedule[scheduleIndex]));
                                                    currentSchedule['active'] = e.target.checked;
                                                    let schedule = JSON.parse(JSON.stringify(this.state.schedule));
                                                    schedule[scheduleIndex] = currentSchedule;
                                                    this.setState({
                                                        schedule
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                        <Paragraph style={{ fontWeight: "bold", padding: 0, marginTop: "5px", width: "32px" }}>{scheduleItem['day']}</Paragraph>
                                    </Col>

                                    {
                                        scheduleItem['active'] == true &&
                                        <Col xs={24} sm={24} md={20} lg={18} xl={18}>
                                            {scheduleItem['slots'].map((slot, slotIndex) => {
                                                return <Row key={`${slotIndex}-slot`} gutter={[5, 5]}>
                                                    <Col xs={8} sm={8} md={7} lg={7} xl={7}>
                                                        <Form.Item
                                                            name={`${scheduleItem['day']}_start_${slotIndex}`}
                                                            initialValue={moment(slot['Start_Time']).format('h:mm a')}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Select start time',
                                                                },
                                                                ({ getFieldValue }) => ({
                                                                    validator(_, value) {
                                                                        let fieldName = _.field.split("_");
                                                                        let currentDay = fieldName[0];
                                                                        let index = fieldName[2];
                                                                        
                                                                        let currentTimeSlotStartTime = value;
                                                                        let currentTimeSlotEndTime = getFieldValue(`${currentDay}_end_${index}`);

                                                                        let duplicateTimeSlot = false;
                                                                        for (let i = 0; i < index + 1; i++) {
                                                                            if (i == index)
                                                                                continue;
                                                                            let startFieldName = getFieldValue(`${currentDay}_start_${i}`);
                                                                            let endFieldName = getFieldValue(`${currentDay}_end_${i}`);

                                                                            if (currentTimeSlotStartTime == startFieldName && currentTimeSlotEndTime == endFieldName) {
                                                                                duplicateTimeSlot = true;
                                                                                break;
                                                                            }
                                                                        }
                                                                        if (moment(currentTimeSlotStartTime, 'hh:mm a').isAfter(moment(currentTimeSlotEndTime, 'hh:mm a'))) {
                                                                            return Promise.reject(new Error('Start time should be before end time'));
                                                                        }
                                                                        if (currentTimeSlotStartTime == currentTimeSlotEndTime) {
                                                                            return Promise.reject(new Error('Start time should be different from end time.'));
                                                                        }
                                                                        if (duplicateTimeSlot) {
                                                                            return Promise.reject(new Error('Duplicate time slot'));
                                                                        }
                                                                        return Promise.resolve();
                                                                    },
                                                                }),
                                                            ]}
                                                        >
                                                            <Select
                                                                // showSearch={!isMobile}
                                                                placeholder={"Start Time"}
                                                                style={{ fontSize: "12px" }}
                                                                onChange={time => {
                                                                    this.setState({ IsformChange: 1 })
                                                                    this.setState({currentStartTime: time})
                                                                    let currentSchedule = JSON.parse(JSON.stringify(this.state.schedule[scheduleIndex]));
                                                                    currentSchedule['slots'][slotIndex]['Start_Time'] = moment(time, 'h:mm a');
                                                                    let schedule = JSON.parse(JSON.stringify(this.state.schedule));
                                                                    schedule[scheduleIndex] = currentSchedule;
                                                                    this.setState({
                                                                        schedule
                                                                    });
                                                                }}
                                                                value={moment(slot['Start_Time']).format('h:mm a')}
                                                            >
                                                                {
                                                                     this.timeSlots.filter(time => { 
                                                                        let endTime =  moment(slot['End_Time']).format('h:mm a')

                                                                        return moment(time, 'hh:mm a').isBefore(moment(endTime, 'hh:mm a'))
                                                                        }).map((slot, index) => {
                                                                        return <Option key={index} value={slot}>{slot}</Option>
                                                                    })
                                                                }
                                                            </Select>

                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={1} sm={1} md={2} lg={2} xl={2} style={{ paddingTop: "10px" }}>
                                                        -
                                                    </Col>
                                                    <Col xs={8} sm={8} md={7} lg={7} xl={7}>
                                                        <Form.Item
                                                            name={`${scheduleItem['day']}_end_${slotIndex}`}
                                                            initialValue={moment(slot['End_Time']).format('h:mm a')}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Select end time',
                                                                },
                                                                ({ getFieldValue }) => ({
                                                                    validator(_, value) {
                                                                        let fieldName = _.field.split("_");
                                                                        let currentDay = fieldName[0];
                                                                        let index = fieldName[2];

                                                                        let currentTimeSlotStartTime = getFieldValue(`${currentDay}_start_${index}`);
                                                                        let currentTimeSlotEndTime = value;

                                                                        let duplicateTimeSlot = false;
                                                                        for (let i = 0; i < index + 1; i++) {
                                                                            if (i == index)
                                                                                continue;
                                                                            let startFieldName = getFieldValue(`${currentDay}_start_${i}`);
                                                                            let endFieldName = getFieldValue(`${currentDay}_end_${i}`);

                                                                            if (currentTimeSlotStartTime == startFieldName && currentTimeSlotEndTime == endFieldName) {
                                                                                duplicateTimeSlot = true;
                                                                                break;
                                                                            }
                                                                        }
                                                                        if (moment(currentTimeSlotStartTime, 'hh:mm a').isAfter(moment(currentTimeSlotEndTime, 'hh:mm a'))) {
                                                                            return Promise.reject(new Error('End time should be after start time'));
                                                                        }
                                                                        if (duplicateTimeSlot) {
                                                                            return Promise.reject(new Error('Duplicate time slot'));
                                                                        }
                                                                        return Promise.resolve();
                                                                    },
                                                                }),
                                                            ]}
                                                        >
                                                            <Select
                                                                // showSearch={!isMobile}
                                                                placeholder={"End Time"}
                                                                style={{ fontSize: "12px" }}
                                                                onChange={time => {
                                                                    this.setState({ IsformChange: 1 })
                                                                    let currentSchedule = JSON.parse(JSON.stringify(this.state.schedule[scheduleIndex]));
                                                                    currentSchedule['slots'][slotIndex]['End_Time'] = moment(time, 'h:mm a');
                                                                    let schedule = JSON.parse(JSON.stringify(this.state.schedule));
                                                                    schedule[scheduleIndex] = currentSchedule;
                                                                    this.setState({
                                                                        schedule
                                                                    });
                                                                }}
                                                                value={moment(slot['End_Time']).format('h:mm a')}
                                                            >
                                                                {
                                                                    this.timeSlots.filter(time => 
                                                                        {
                                                                            let startTime = moment(slot['Start_Time']).format('h:mm a')

                                                                            return moment(time, 'hh:mm a').isAfter(moment(startTime, 'hh:mm a'))
                                                                        }
                                                                        ).map((slot, index) => {
                                                                            return <Option key={index} value={slot}>{slot}</Option>
                                                                    })
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col xs={7} sm={7} md={8} lg={8} xl={8} align={"end"}>
                                                        <Row gutter={[5]} align={"end"}>

                                                            {
                                                                slotIndex > 0 &&
                                                                <Col align={"end"}>
                                                                    <Button shape="circle" icon={<DeleteOutlined />}
                                                                        style={{ fontSize: "10px" }}
                                                                        onClick={(e) => {
                                                                            this.setState({ IsformChange: 1 })
                                                                            let currentSchedule = JSON.parse(JSON.stringify(this.state.schedule[scheduleIndex]));
                                                                            currentSchedule['slots'].splice(slotIndex, 1);
                                                                            let schedule = JSON.parse(JSON.stringify(this.state.schedule));
                                                                            schedule[scheduleIndex] = currentSchedule;

                                                                            this.setState({
                                                                                schedule
                                                                            });
                                                                        }}
                                                                    />
                                                                </Col>
                                                            }
                                                            {
                                                                (slotIndex == scheduleItem['slots'].length - 1) &&
                                                                <Col align={"end"}>
                                                                    <Button shape="circle" icon={<PlusOutlined style={{}} />}
                                                                        style={{ fontSize: "10px" }}

                                                                        onClick={(e) => {
                                                                            this.setState({ asd: 1 })
                                                                            let currentSchedule = JSON.parse(JSON.stringify(this.state.schedule[scheduleIndex]));
                                                                            currentSchedule['slots'].push(
                                                                                {
                                                                                    Start_Time: moment('09:00:00', 'HH:mm:ss').local(),
                                                                                    End_Time: moment('18:00:00', 'HH:mm:ss').local(),
                                                                                },

                                                                            );



                                                                            let schedule = JSON.parse(JSON.stringify(this.state.schedule));
                                                                            schedule[scheduleIndex] = currentSchedule;
                                                                            this.setState({
                                                                                schedule
                                                                            });
                                                                        }}
                                                                    />
                                                                </Col>
                                                            }
                                                        </Row>

                                                    </Col>

                                                </Row>
                                            })}

                                        </Col>
                                    }
                                    {
                                        scheduleItem['active'] == false &&
                                        <Col xs={20} sm={6} md={6} lg={6} xl={6}><Paragraph style={{ marginTop: "5px", textAlign: 'center' }}>Unavailable</Paragraph></Col>
                                    }


                                </Row>
                                {count == 7 ? null : <Divider />}
                            </Fragment>
                        })}


                    </div>
                    <Button type={"primary"} block htmlType="submit" style={{ margin: "0 0 20px 20px" }} loading={this.state.loading}>
                       Save
                    </Button>
                </Form>}
        </div >
    }

}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(ManageSchedule);
