import { async } from '@firebase/util';
import { Button, Form, Input, Modal, notification, Space, Typography } from 'antd'
import React from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ApiBaseHelper } from '../../../../../utils/api-base-helper';
import '../assets/css/create-list-modal.scss'


const { Title, Paragraph } = Typography
const { TextArea } = Input;

const CreateListModal = ({setShowCreateListModal, showCreateListModal, setEditState, editState, form,  getSavedTechniciansList, addTechnicianToList, listId, isAddTechnician, selectedTech }) => {
    const user = useSelector((state) => state.user);
    const [creatingList, setCreatingList] = useState(false)

    const handleCreateListFormSubmit = (values) => {
        setCreatingList(true)
        ApiBaseHelper.post({
            url: 'saved-technician/list', 
            payload: values,
            headers: {
                Authorization: 'Bearer ' + user?.token,
            },
        }).then((res) => {
            if(res){
                setShowCreateListModal(false)
                notification['success']({
                    message:"TecMe",
                    description:"List has been created successfully"
                })
                form.resetFields();
                setCreatingList(false)
                if(isAddTechnician){
                    addTechnicianToList({Technician_HIPAA_Contact:selectedTech?.Hipaa_id, Technician_Organization:selectedTech?.Org_id }, res?.data?.data, values.Group_Name)
                }else{
                    getSavedTechniciansList()
                }
            }
        }).catch((error) => {
            console.log(error)
            setCreatingList(false)
            notification['error']({
                message: "TecMe",
                description:"An Error Occurred"
            })
        })
    } 
    
    const handleEditListFormSubmit = (values) => {
        setCreatingList(true)
        ApiBaseHelper.put({
            url: `saved-technician/list/${listId}`, 
            payload: values,
            headers: {
                Authorization: 'Bearer ' + user?.token,
            },
        }).then((res) => {
            if(res){
                setShowCreateListModal(false)
                notification['success']({
                    message:"TecMe",
                    description:"List has been updated successfully"
                })
                getSavedTechniciansList()
                setCreatingList(false)
            }
        }).catch((error) => {
            console.log(error)
            setCreatingList(false)
            notification['error']({
                message: "TecMe",
                description:"An Error Occurred"
            })
        })
    }  


    return (
        <Modal
            open={showCreateListModal} 
            onOk={() => setShowCreateListModal(false)} 
            onCancel={() => {
                setShowCreateListModal(false)
                setEditState(false)
            }}
            width={702}
            footer={null}
            centered
            >
            <div className="create-list-heading">
                <Title level={2} className="list-header">{ editState  ? 'Edit' : 'Create'} List</Title>
                <Typography className="list-info">{editState ? 'Make the necessary changes and save your edited list to access your updated information.' : 'A list is a useful tool that helps you keep track of your favorite or frequently used technicians. By creating a list, you can easily access the information of all the profiles you have saved in one place.'}</Typography>
            </div>
            <Form
                onFinish={(values) => editState ? handleEditListFormSubmit(values) : handleCreateListFormSubmit(values)}
                layout="vertical"
                className="create-list-form"
                form={form}
            >
                <Form.Item
                    name="Group_Name"
                    label={<Paragraph className="list-title">List Title</Paragraph>}
                    style={{width: "100%", marginBottom:24}}
                    rules={[
                        { required: true, message: 'Please Enter List Title'},
                        { min: 3, message: 'Minimum of 3 characters allowed'},
                        { max: 80, message: 'Maximum of 80 characters allowed.' }, 
                    ]}
                >
                        <Input minLength={3} maxLength={80} placeholder="Enter list title"/>         
                </Form.Item>
                <Form.Item
                    name="Description"
                    label={<Paragraph className="list-description">Description <span>(optional)</span> </Paragraph>}
                    style={{width: "100%"}}
                >
                        <TextArea rows={4} maxLength={150} showCount placeholder="Enter list description"/>        
                </Form.Item>
                <Form.Item>
                    <Space size={16} className='mt-3 w-100 justify-content-end'>
                        <Button type='secondary' style={{height:40, fontSize:16}} className="neutral-btn" onClick={() => {
                            setShowCreateListModal(false)
                            setEditState(false)
                        }}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" style={{height:40, fontSize:16}} loading={creatingList}>
                        {editState? 'Save changes' : 'Create list'} 
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CreateListModal