import { useEffect, useState } from 'react';
import { Avatar, Button, Col, Form, Input, Modal, Radio, Row, Space, Timeline, Tooltip, Typography, notification } from 'antd'
import {BulbOutlined, BulbTwoTone, FileOutlined, FileTwoTone, LoadingOutlined, SendOutlined, UserOutlined} from '@ant-design/icons';
import { capitalizeFirstLetter, capitalizeFirstWordOfEveryLetter, getTableTimeFormat } from '../../../../../../utils/helper';
import { useSelector } from 'react-redux';
import {SECONDARY_COLOR, TECME_LOGO} from '../../../../../../utils/constants';
import {Image} from "antd/es";
import DownloadFile from "../assets/icons/download-file.png";
import ExcelFileIcon from "../../real-time-messaging/assets/images/excel-icon.png";
import PdfFileIcon from "../../real-time-messaging/assets/images/pdf-icon.png";
import PPTFileIcon from "../../real-time-messaging/assets/images/point-icon.png";
import WordFileIcon from "../../real-time-messaging/assets/images/word-icon.png";
import AIButton from '../../../../../components/ai-button';
import BackIcon from '../assets/icons/back-icon.svg';
import AiIcon from '../assets/icons/ai-icon.svg';
import { generateAiResponse } from '../../../../../../utils/openai-helper';

const { Title, Paragraph, Text } = Typography

const ActivitiesPanel = ({ activities, ticketDetails, ticketRepository, getAndSetActivities, isContractPaused }) => {
    const user = useSelector(state => state.user)
    const tech = useSelector(state => state.tech)
    const [activityText, setActivityText] = useState('')
    const [activityAiText, setActivityAiText] = useState('')
    const [loading, setLoading] = useState(false)
    const [openAIModal, setOpenAIModal] = useState(false)
    const [showAIResults, setShowAIResults] = useState(false)
    const [loadingGeneratedLog, setLoadingGeneratedLog] = useState(false)
    const [form] = Form.useForm()
    const contact = tech ? ticketDetails?.Reporter_Contact : ticketDetails?.Provider_Contact;
    const activeUser = user || tech
    const isTicketDisabled = ticketDetails?.Status === 'Pending' || ticketDetails?.Status === 'Closed'

    const handleSendActivityClick = async () => {
        if((activityText || activityAiText) && ticketDetails?.Status !== 'Closed' && !isContractPaused) {
            setLoading(true)
            const payload = { Message: activityText ? activityText : activityAiText }
            await ticketRepository?.createActivity(ticketDetails?.id, payload)
            await getAndSetActivities()
            setActivityText('')
            setLoading(false)
            setOpenAIModal(false)
            setShowAIResults(false)
        }
    }

    const getActivityMessage = (activity) => {
        let activityMessage = activity.Message || ""
        if (activityMessage.includes('UTC_TIME:')) {
            activityMessage = activityMessage.split('UTC_TIME:');

            return (
                <>
                    <Text className='fw-normal text-lowercase'>
                        {`${activityMessage[0]}`}
                    </Text>
                    <Text className='fw-normal'>
                        {`${getTableTimeFormat(activityMessage[1].toString().trim())}`}
                    </Text>
                </>
            )
        } else {
            return (
                <Text className='fw-normal text-lowercase'>
                    {activityMessage}           
                </Text>
            )
        }
    }

    const handleGenerateLogWithAI = async (values) => {   
      
        const prompt = `generate activity log ${values?.tone ? `with ${values?.tone} tone` :  ""} in one paragraph and less than 200 words for the following: ${values.keywords}`

        setLoadingGeneratedLog(true)
        const response = await generateAiResponse(prompt, activeUser?.token)
        setLoadingGeneratedLog(false)
        if(response){
            // setJobPostingData({...jobPostingData, job_description: response?.content})
            setShowAIResults(true)
            form.setFieldsValue({
                "ai-activity-log": response?.content,
                keywords: values?.keywords,
                tone: values?.tone
            })
            setActivityAiText(response?.content)
        }else{
            notification.error({
                message: "TecMe",
                description: "We are unable to generate a response. Please try again."
            })
        }
    }

    useEffect(() => {
        if(openAIModal === false){
            form.setFieldsValue({
                "ai-activity-log": "",
                keywords: "",
                tone: ""	
            })
        }
    }, [openAIModal])


    return (
        <div className="activity-ctn">
            <div className="activity-title">
                <Space size={16}>
                    <Title level={4}>Activity</Title>
                    {
                         (isTicketDisabled) ? null : (
                            <AIButton
                                onClick={() => setOpenAIModal(true)}
                            >
                                Generate Log
                            </AIButton>
                        )
                    }
                </Space>
            </div>
            <div className="activity-content scroll">
                <Space className='w-100 send-activity-message-wrapper' size={18}>
                    <Avatar
                        shape='circle'
                        size={49}
                        src={activeUser?.imageUrl}
                        alt={activeUser?.firstname}
                    >
                        {capitalizeFirstLetter(activeUser?.firstname?.[0])}
                    </Avatar>
                    <Input
                        disabled={isTicketDisabled}
                        style={{ height:38 }}
                        className='activity-msg-input'
                        placeholder='Write something here ...'
                        value={activityText}
                        onChange={e => setActivityText(e?.target?.value)}
                        onKeyDown={e => {
                            if(e.key === 'Enter') {
                                handleSendActivityClick();
                            }
                        }}
                        suffix={
                            <Tooltip title={(isTicketDisabled) ? `Cannot send new messages once the ticket is ${ticketDetails?.Status === 'Closed' ? 'closed' : 'paused'}` : ""}>
                                {loading ? <LoadingOutlined /> : (
                                    <SendOutlined
                                        className={(isTicketDisabled) ? 'disabled-send-button' : ''}
                                        onClick={handleSendActivityClick}
                                        disabled={isTicketDisabled}
                                    />
                                )}
                            </Tooltip>
                        }
                    />
                </Space>
                <div className='avatar-line'></div>
                <div className='timeline-ctn'>
                    <Timeline>
                        {
                             activities.map((activity) => {
                                return(
                                    <Timeline.Item key={activity.id}>
                                        <div className='card-top-activity'>
                                            <Space className='activity-card-title'>
                                                <Avatar shape='circle' size={42} src={activity.Contact?.imgUrl} alt={activity.Contact?.Full_Name || 'TecMe'} style={{background: SECONDARY_COLOR}}>
                                                    {capitalizeFirstLetter(activity.Contact?.Full_Name?.[0]) || 'T'}
                                                </Avatar>
                                                <Title style={{margin:0}} level={5}>
                                                    {capitalizeFirstWordOfEveryLetter(activity.Contact?.Full_Name || 'TecMe')}
                                                    {` `}{getActivityMessage(activity)}
                                                </Title>

                                            </Space>
                                            <small>{getTableTimeFormat(activity?.CreatedDate)}</small>
                                        </div>
                                        {!!activity?.custom_data?.cta_text && (
                                            <div className="card-description">
                                                <a
                                                    href={!!user ? activity.custom_data.customer_remote_url : activity.custom_data.tech_remote_url}
                                                    className="tecme-secondary-color cursor-pointer text-decoration-none"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {activity?.custom_data?.cta_text}
                                                </a>
                                            </div>
                                        )}
                                        {!!activity?.custom_data?.message && (
                                            <div className="card-description">
                                                {capitalizeFirstLetter(activity?.custom_data?.message)}
                                            </div>
                                        )}
                                        {!!activity?.custom_data?.images && (
                                            <div className="card-description">
                                                <Row gutter={[10,10]}>
                                                {
                                                    activity?.custom_data?.images.split("|").map((file)=>{
                                                        let fileIcon = DownloadFile;
                                                        let fileExt = file.split(".").pop();

                                                        if(["pdf"].includes(fileExt))
                                                            fileIcon = PdfFileIcon;
                                                        else if(["csv","xls","xlsx"].includes(fileExt))
                                                            fileIcon = ExcelFileIcon;
                                                        else if(["doc","word","docx"].includes(fileExt))
                                                            fileIcon = WordFileIcon;
                                                        else if(["ppt","pptx"].includes(fileExt))
                                                            fileIcon = PPTFileIcon;

                                                        if(["jpg","jpeg","png","gif","svg","bmp"].includes(fileExt))
                                                            return <Col>
                                                                <Image src={file} height={100} width={128} style={{objectFit:"cover"}} />
                                                            </Col>
                                                        else
                                                            return <Col>
                                                                <a href={file} target={"_blank"} style={{color:SECONDARY_COLOR,fontSize:11}}>
                                                                    {/*<Row gutter={[10,10]}>*/}
                                                                        <Col span={24}>
                                                                            <Image preview={false} src={fileIcon} height={100}  />
                                                                        </Col>
                                                                        {
                                                                            activity?.custom_data?.fileNames?.split("|").map((fileName)=>{
                                                                                if(file.includes(fileName))
                                                                                    return <Col span={24}>{fileName}</Col>

                                                                                return <></>
                                                                            })
                                                                        }

                                                                    {/*</Row>*/}
                                                                </a>
                                                            </Col>
                                                    })
                                                }
                                                </Row>
                                            </div>
                                        )}
                                    </Timeline.Item>
                                )
                            })
                        }
                    </Timeline>
                    <Paragraph className='w-100 text-center'>- End of activity logs -</Paragraph>
                </div>
            </div>
            <Modal
                title={
                    <Space>
                        <img src={AiIcon} alt="ai icon"  style={{width:21}}/>
                        <Title level={4} style={{marginBottom:0}}>Generate Activity Log with AI</Title>
                    </Space>
                }
                className='ai-log-modal'
                visible={openAIModal}
                onOk={() => {}}
                onCancel={() => {
                    setOpenAIModal(false)
                    setShowAIResults(false)
                }}
                width={550}
                footer={
                    !showAIResults ? [
                    <Button key="back" type='secondary' onClick={() => setOpenAIModal(false)}>
                       Not Now
                    </Button>,
                    <Button 
                        key="submit" 
                        type="primary" 
                        htmlType='submit' 
                        form='generate-log-with-ai-form'
                        loading={loadingGeneratedLog}
                    >
                        Generate
                    </Button>,
                ] :
                [
                    <Button key="back" type='secondary' onClick={() => {
                        setOpenAIModal(false)
                        setShowAIResults(false)
                    }}>
                        Cancel
                    </Button>,
                    <Button 
                        key="submit" 
                        type="primary"
                        loading={loading} 
                        onClick={() => {
                        handleSendActivityClick()
                    }}>
                        Send as activity log
                    </Button>,
                ]
            }
            >
                {
                    !showAIResults ? (
                        <Form
                            form={form}
                            name='generate-log-with-ai-form'
                            layout='vertical'
                            onFinish={handleGenerateLogWithAI}
                        >
                            <Form.Item
                                label="Write some keywords or give some hints for the activity log"
                                name="keywords"
                                rules={[{ required: true, message: 'Please input some keywords' }]}
                                style={{marginBottom:30}}
                            >
                                <Input.TextArea 
                                    rows={4} 
                                    placeholder="We are connecting a printer to the network to print to pdf could not get the path working properly ..."
                                    maxLength={500}
                                    showCount
                                />
                            </Form.Item>
                            <Form.Item
                                label="Tone of voice"
                                name="tone"   
                            >
                                <Radio.Group
                                    
                                >
                                    <Space size={10} direction='vertical'>
                                    {
                                        ["Expert", "Supportive", "Casual", "Professional"].map((tone) => {
                                            return(
                                                <Radio value={tone}>{tone}</Radio>
                                            )
                                        })
                                    }
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Form>
                    ) : (
                        <div>
                            <Space className='mb-4 cursor-pointer' onClick={() => setShowAIResults(false)}>
                                <img src={BackIcon} alt="back icon" />
                                <Text style={{fontSize:14, color:"#000", fontWeight:300}}>Back</Text>
                            </Space>
                            <Form
                                form={form}
                                name='ai-activity-log-form'
                                layout='vertical'
                                // onFinish={handleGenerateLogWithAI}
                            >
                                <Form.Item
                                    label="AI generated activity log:"
                                    name="ai-activity-log"
                                >
                                    <Input.TextArea 
                                        rows={8} 
                                        style={{color:"#000"}}
                                        onChange={(e) => {setActivityAiText(e?.target?.value)}}
                                    />
                                </Form.Item>
                            </Form>
                            <Space align='center'>
                                <BulbTwoTone twoToneColor={SECONDARY_COLOR} style={{fontSize:12}} />
                                <Text style={{fontSize:12, color:"#000", fontWeight:300}}>Note: You can modify the text above before submitting it as an activity log.</Text>
                            </Space>
                        </div>
                    )
                }
            </Modal>
        </div>
    )
}

export default ActivitiesPanel