import { ApiBaseHelper } from "../../../../../../utils/api-base-helper";

export class DeviceRepository {
  constructor(accessToken) {
    this.accessToken = accessToken;
  }

  getDevices() {
    return ApiBaseHelper.get({
      url: "device",
      headers: {
        Authorization: "Bearer " + this.accessToken,
      },
    });
  }

  editDevice(deviceId, data) {
    return ApiBaseHelper.put({
      url: `device/${deviceId}`,
      payload: data,
      headers: {
        Authorization: "Bearer " + this.accessToken,
      },
    });
  }

  addDevice(data) {
    return ApiBaseHelper.post({
      url: `device`,
      payload: data,
      headers: {
        Authorization: "Bearer " + this.accessToken,
      },
    });
  }

  deviceDelete(deviceId) {
    return ApiBaseHelper.delete({
      url: `device/${deviceId}`,
      headers: {
        Authorization: "Bearer " + this.accessToken,
      },
    });
  }

  getDeviceBrands() {
    return ApiBaseHelper.get({
      url: `web/device/brands`,
      headers: {
        Authorization: "Bearer " + this.accessToken,
      },
    });
  }

  getDeviceTypes() {
    return ApiBaseHelper.get({
      url: `web/groups`,
      headers: {
        Authorization: "Bearer " + this.accessToken,
      },
    });
  }

  getDeviceBrands() {
    return ApiBaseHelper.get({ url: "web/device/brands" });
  }

  getDeviceTypes() {
    return ApiBaseHelper.get({ url: "web/groups" });
  }
}
