// External Imports
import { SearchOutlined } from '@material-ui/icons';
import { Row, Layout, Card, Col, Button, Space, Typography, Input, Table, Tooltip, Badge, Tag, Avatar, List, Skeleton, Empty, } from 'antd';
import { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper';
import moment from 'moment'

// Internal Imports
import { META_DESCRIPTION } from '../../../../../../utils/constants';
import { capitalizeFirstLetter, capitalizeFirstWordOfEveryLetter, convertIfLight, getLocalTimeFormat, getTableTimeFormat } from '../../../../../../utils/helper';
import ROUTES from '../../../../../../utils/routes';
import PageHeaderWrapper from '../../../../../components/page-header-breadcrumbs';
import SearchInput from '../../../../../components/search-input';
import './assets/css/styles.css'
import RightArrow from '../../../../../../assets/images/right-arrow.svg'
import TimeIcon from './assets/icons/time.svg'
import CustomRenderEmpty from '../../../../../components/custom-render-empty';
import PageTitle from '../../../../../components/page-title';
import { useLocation } from 'react-router';
import { is } from 'immutable';

const { Content } = Layout
const {Title, Text, Paragraph} = Typography

const contractTypes = [
    {
        id: "all",
        contract_type: "All",
        hint:""
    },
    {
        id: "draft",
        contract_type: "Draft",
        hint:"Contract proposals awaiting technician’s acceptance."
    },
    {
        id: "active",
        contract_type: "Active",
        hint:"Scheduled contracts currently in progress."
    },
    {
        id: "paused",
        contract_type: "Paused",
        hint: "Contains contracts that are temporarily paused by the customer for further service"
    },
    {
        id: "terminated",
        contract_type: "Ended",
        hint:"Contains contracts that are successfully completed, withdrawn by customer or rejected by the technician."
    },
]

const Contracts = () => {
    const history = useHistory();
    const customer = useSelector(state => state.user)
    const tech = useSelector(state => state.tech)
    const {state, search} = useLocation()

    const [ loadingContracts, setLoadingContracts ] = useState(false)
    const [ contractData, setContractData ] = useState([])
    const [ filteredContracts, setFilteredContracts ] = useState([])
    const [ contractStatus, setContractStatus ] = useState("all")

    const user = customer ? customer : tech
    const params = new URLSearchParams(search)
    const isContractProposals = params.get('status') === 'draft'

    const fetchContracts = () => {
        setLoadingContracts(true)
        ApiBaseHelper.get({
            url: `contract?role=${tech ? 'technician' : 'customer'}`, headers: {
                Authorization: 'Bearer ' + user?.token
            }
        }).then((res) => {
            let sortedContractData =  [...res?.data.data]
            sortedContractData?.sort((a, b) => {
                const dateA = new Date(a.Active_Date_Time);
                const dateB = new Date(b.Active_Date_Time);
                return dateB - dateA;
            });
            setContractData(sortedContractData)
            setLoadingContracts(false)
        }).catch((error) => {
            console.error(error)
            setLoadingContracts(false)
        })       
    }

    const filterByStatus = () => {
        let filterResults =  contractData.filter((ele) => (ele?.Status?.toLowerCase().includes(contractStatus?.toLowerCase())))
        setFilteredContracts(filterResults)
        if(contractStatus == "" || contractStatus === "all"){
            setFilteredContracts(contractData)
        }
    }

    const handleContractSearch = (e) => {
        if(!e.target.value){
            if(isContractProposals){
                setFilteredContracts(contractData.filter((ele) => ele?.Status?.toLowerCase().includes("draft")))
                return
            }
            setFilteredContracts(contractData)
            return
        }

        let targetValue = e.target.value.toLowerCase()

        let filterResults =  contractData.filter((ele) => {
            let timeFormat =  getTableTimeFormat(ele?.Active_Date_Time)
            if("ended".includes(targetValue)){
                return ele?.Status.toLowerCase().includes("terminated")
            }
            if("per issue".includes(targetValue)){
                return ele?.Type.toLowerCase().includes("per ticket")
            }
            if("retainer".includes(targetValue)){
                return ele?.Type.toLowerCase().includes("fixed")
            }
            return  ele?.Description.toLowerCase().includes(targetValue) || 
            (user === tech ? ele?.Customer_Org : ele?.Provider_Org)?.Name.toLowerCase().includes(targetValue) || 
            ele?.Type.toLowerCase().includes(targetValue) || 
            ele?.Status.toLowerCase().includes(targetValue) || 
            timeFormat.includes(targetValue)
        })
        if(isContractProposals){
            filterResults = filterResults.filter((ele) => ele?.Status?.toLowerCase().includes("draft"))
        }
        setFilteredContracts(filterResults)
    }

    useEffect(() => {
        setFilteredContracts(contractData)
    }, [contractData])

    useEffect(() => {
        fetchContracts()
    }, [])

    // Fetch contracts when user changes view from customer to technician or vice versa
    useEffect(() => {
        if(customer || tech){
            fetchContracts()
        }
    }, [customer, tech])

    useEffect(() => {
        filterByStatus()
    }, [contractStatus])

    const contractColumns =  [
        {
            title: 'Support Description',
            dataIndex: 'Description',
            key: 'Description',
            width:"20%",
            ellipsis:true,
            render:(_, record) => (
                <Text ellipsis={true} style={{color:"#F04A22", cursor:"pointer"}}>{ capitalizeFirstLetter(record.Description)}</Text>
            ),
            sorter: {
                compare: (a, b) => a.Description.localeCompare(b.Description),
            },
        },
        {
            title: `${user == tech ? 'Client' : 'Technician'}`,
            dataIndex: 'tech_name',
            key: 'tech_name',
            width:"18%",
            render:(_, record) => {
                return (
                <div className='contract-tech-details'>
                    {
                       user === tech ?
                        <Avatar src={record?.Customer_Org?.Logo} size={'small'} className='contract-avatar'>
                           {`${capitalizeFirstLetter(record?.Customer_Org?.Name?.split(" ")[0][0])}` }
                        </Avatar> :
                        record.ITC?.id ?
                        <Avatar 
                            src={record?.ITC?.Brand} 
                            size={'small'} 
                            className='contract-avatar'
                            style={{
                                backgroundColor: convertIfLight(`#${record?.ITC?.Brand_Color}`)
                            }}
                        >
                            {`${capitalizeFirstLetter(record?.ITC?.Company_Name[0])}`}
                        </Avatar> :
                        <Avatar src={record?.Provider_Org?.Contact_Image} size={'small'} className='contract-avatar'>
                            {`${capitalizeFirstLetter(record?.Provider_Org?.HIPAA_Contact_Name?.split(" ")[0][0] || record?.Provider_Org?.Name?.split(" ")[0][0])}`}
                        </Avatar>

                    }
                    <div>
                        { user === tech ? capitalizeFirstWordOfEveryLetter(record.Customer_Org?.Name)  :  
                         record.ITC?.id ?
                         <div className='cursor-pointer' onClick={(e) =>{ 
                            e.stopPropagation()
                        }}>
                            {capitalizeFirstWordOfEveryLetter(record?.ITC?.Company_Name)}
                        </div> :
                        <div className='cursor-pointer' onClick={(e) =>{ 
                            e.stopPropagation()
                            history.push(ROUTES.TECH_PROFILE_WITH_ID.replace(':id', record?.Provider_Org?.HIPAA_Primary_Contact))
                        }}>
                            {capitalizeFirstWordOfEveryLetter(record?.Provider_Org?.HIPAA_Contact_Name || record?.Provider_Org?.Name)}
                        </div> 
                        }
                    </div>
                </div>
             )},
             sorter: {
                compare: (a, b) => (user === tech ? a.Customer_Org?.Name : (a.Provider_Org?.HIPAA_Contact_Name || a.Provider_Org?.Name)).localeCompare(user === tech ? b.Customer_Org?.Name : (b.Provider_Org?.HIPAA_Contact_Name || b.Provider_Org?.Name)),
            },
        },

        {
            title: 'Type',
            dataIndex: 'Type',
            key: 'Type',
            render:(_, record) =>  {
                return(
                    record?.Type !== null ?
                    <Text>{record.Type == 'Fixed' ? 'Retainer' : record.Type == 'Per Ticket' ? 'Per Issue' : record.Type}</Text> :
                    <Text>N/A</Text>
                )
            }, 
             sorter: {
                compare: (a, b) => {
                    if(a.Type) return a.Type.localeCompare(b.Type)
                },
            },
        },
        {
            title: 'Contract Via',
            dataIndex: 'Contract_Via',
            key: 'Contract_Via',
            render:(_, record) => (
                <Text>{record?.ITC?.id ? `${record?.ITC?.Company_Name} ITSC` : "Technician"}</Text>
            ),
            sorter: {
                compare: (a, b) => a?.Contract?.Description.localeCompare(b?.Contract?.Description),
            },
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            render:(_, record) => {
                return(
                    record?.Status !== null ?
                    <Tag>{record.Status === 'Terminated' ? 'Ended' : record?.Status}</Tag> :
                    <Text>N/A</Text>
                )
            },
            sorter: {
                compare: (a, b) => {
                    if(a.Status) return a.Status.localeCompare(b.Status)
                },
            },
        },
        {
            title: 'Created At',
            dataIndex: 'Active_Date_Time',
            key: 'Active_Date_Time',
            width:"22%",
            render:(_, record) => (
                <Text>{record.Active_Date_Time !== null ? getTableTimeFormat(record.Active_Date_Time)  : 'N/A' }</Text>
            ),
            sorter: {
                compare: (a, b) =>{ 
                    if(a.Active_Date_Time) return a.Active_Date_Time.localeCompare(b.Active_Date_Time)
                },
            },
        },
    ];


    return (
        <>
            <MetaTags>
                <title>Contracts - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <Content className="main">
                <div className="page">
                        <Row gutter={[20, 20]}>
                            <Col span={24}>
                                <PageHeaderWrapper
                                    routes={[
                                        {
                                            path: ROUTES.CONTRACTS,
                                            breadcrumbName: 'Contracts',
                                        },
                                    ]}
                                />
                            </Col>
                            <Col span={24}>
                                <div className="auth-container">
                                    <div className='contract-listing-container'>
                                        <PageTitle
                                            title={isContractProposals ? 'Contract Proposals' : 'Contracts'}
                                            search={  <SearchInput
                                                placeholder="Search Contracts"
                                                onChange={(e) => {
                                                   handleContractSearch(e)
                                                }}
                                            />}
                                        />
                                        <div className='contract-filter-section'>
                                            <div className='contract-type-list'>
                                                {
                                                    !isContractProposals && contractTypes.map((item) => {
                                                        return(
                                                            <Tooltip placement="top" title={item.hint}>
                                                                <div key={item?.id} className={`contract-type-item ${item?.id == contractStatus && 'contract-type-item-active'}`}
                                                                onClick={() => {
                                                                    setContractStatus(item?.id)
                                                                }}
                                                                >
                                                                    {item.contract_type}
                                                                </div>
                                                            </Tooltip>
                                                        )
                                                    })
                                                }
                                            </div>
                                               
                                        </div>
                                        <Row>
                                            <Col xs={0} sm={0} md={0} lg={24} xl={24}>
                                                {
                                                    // loadingContracts ? <LoadingTable /> : 
                                                    <CustomRenderEmpty 
                                                        description={`No ${contractStatus === "all" ? "" : contractStatus === "draft" ? " " : contractStatus} contracts found in your account`} 
                                                        extra={
                                                            customer && 
                                                            <Button href={ROUTES.DISCOVERY_EXPERIENCE}> 
                                                                + Find IT Professionals
                                                            </Button>
                                                    }>
                                                        <Table 
                                                            loading={loadingContracts} 
                                                            dataSource={isContractProposals ? filteredContracts.filter((ele) => ele?.Status?.toLowerCase().includes("draft")) : filteredContracts} 
                                                            columns={contractColumns}
                                                            onRow={(record) => {
                                                                return {
                                                                    onClick: () => history?.push(ROUTES.VIEW_EDIT_CONTRACT.replace(':contractId', `${record.id}`))
                                                                };
                                                            }}
                                                        />  
                                                    </CustomRenderEmpty>
                                                }     
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                                                {
                                                    loadingContracts? <LoadingCards/> : 
                                                    <CustomRenderEmpty 
                                                    description="No contracts found in your account" 
                                                    extra={
                                                        customer && 
                                                        <Button href={ROUTES.DISCOVERY_EXPERIENCE}> 
                                                            + Find IT Professionals
                                                        </Button>
                                                    }> 
                                                        <List
                                                            grid={{ gutter: 16,  xs: 1,
                                                                sm: 2,
                                                                md: 2,
                                                                lg: 3,
                                                                xl: 3,
                                                                xxl: 3, }}
                                                            dataSource={isContractProposals ? filteredContracts.filter((ele) => ele?.Status?.toLowerCase().includes("draft")) : filteredContracts}
                                                            pagination={{
                                                                pageSize: 10,
                                                            }}
                                                            renderItem={item => (
                                                            <List.Item>
                                                                <Card title={
                                                                     <div className='contract-tech-details'>
                                                                     {
                                                                        user === tech ?
                                                                         <Avatar src={item?.Customer_Org?.Logo} size={'small'} className='contract-avatar'>
                                                                            {`${capitalizeFirstLetter(item?.Customer_Org?.Name?.split(" ")[0][0])}` }
                                                                         </Avatar> :
                                                                         item.ITC?.id ?
                                                                         <Avatar 
                                                                             src={item?.ITC?.Brand} 
                                                                             size={'small'} 
                                                                             className='contract-avatar'
                                                                             style={{
                                                                                 backgroundColor: convertIfLight(`#${item?.ITC?.Brand_Color}`)
                                                                             }}
                                                                         >
                                                                             {`${capitalizeFirstLetter(item?.ITC?.Company_Name[0])}`}
                                                                         </Avatar> :
                                                                         <Avatar src={item?.Provider_Org?.Contact_Image} size={'small'} className='contract-avatar'>
                                                                             {`${capitalizeFirstLetter(item?.Provider_Org?.HIPAA_Contact_Name?.split(" ")[0][0] || item?.Provider_Org?.Name?.split(" ")[0][0])}`}
                                                                         </Avatar>
                                                 
                                                                     }
                                                                     <div>
                                                                         { user === tech ? capitalizeFirstWordOfEveryLetter(item.Customer_Org?.Name)  : 
                                                                        item.ITC?.id ?
                                                                        <div className='cursor-pointer' onClick={(e) =>{ 
                                                                           e.stopPropagation()
                                                                        }}>
                                                                           {capitalizeFirstWordOfEveryLetter(item?.ITC?.Company_Name)}
                                                                       </div> :
                                                                        <div className='cursor-pointer' onClick={() => history.push(ROUTES.TECH_PROFILE_WITH_ID.replace(':id', item?.Provider_Org?.id))}>
                                                                                 {capitalizeFirstWordOfEveryLetter(item?.Provider_Org?.HIPAA_Contact_Name || item?.Provider_Org?.Name)}
                                                                         </div> 
                                                                         }
                                                                     </div>
                                                                 </div>
                                                                } 
                                                                extra={ 
                                                                    item?.Status !== null ?
                                                                    <Tag>{item.Status === 'Terminated' ? 'Ended' : item.Status}</Tag> :
                                                                    <Text>N/A</Text>
                                                                }
                                                                >
                                                                    <Paragraph ellipsis={true} style={{color:"rgba(0, 0, 0, 0.85)"}}>{capitalizeFirstLetter(item.Description)}</Paragraph> 
                                                                    <Space>
                                                                        <img src={TimeIcon} alt="time icon" />
                                                                        <Text>{item.Active_Date_Time !== null ? getTableTimeFormat(item.Active_Date_Time)  : 'N/A' }</Text>
                                                                    </Space>
                                                                    <Paragraph className="mt-4"><strong>Contract Via:</strong> {item?.ITC?.id ? `${item?.ITC?.Company_Name} ITSC` : "Technician"}</Paragraph>
                                                                    <div className='contract-card-footer'>
                                                                        <div>
                                                                        { 
                                                                        item?.Type !== null ?
                                                                            <Text>{item.Type == 'Fixed' ? 'Retainer' : item.Type == 'Per Ticket' ? 'Per Issue' : item.Type}</Text> :
                                                                            <Text>N/A</Text>
                                                                            }
                                                                        </div>
                                                                        <Space align='center'>
                                                                            <Button className='borderless-btn'  onClick={() => history?.push(ROUTES.VIEW_EDIT_CONTRACT.replace(':contractId', `${item.id}`))}>View Contract</Button>
                                                                            <img src={RightArrow} alt="right arrow" />
                                                                        </Space>
                                                                    </div>
                                                                </Card>
                                                            </List.Item>
                                                            )}
                                                        />  
                                                    </CustomRenderEmpty>    
                                                }
                                            </Col>
                                        </Row>
                                        {/* <Button type='primary' onClick={() => history?.push(ROUTES.VIEW_EDIT_CONTRACT.replace(':contractId', 'a327F000001MapTAAG'))}>
                                            View Edit Contract
                                        </Button> */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                </div>
            </Content>
        </>
    )
}

const LoadingTable = () => {
    return(
        <div>
            {
                [...Array(10).keys()].map(() => (
                    <Skeleton.Input
                        style={{ width: "100%", height:70, marginTop:20}}
                        active={true}
                        size="default"
                    />  
                ))
                    
            }
        </div>
    )
}

const LoadingCards = () => {
    return(
        <Row gutter={[20, 20]}>
        { [...Array(10).keys()].map(() => {
            return(
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <Card
                        style={{
                            height: "auto",
                            width:"auto",
                        }}
                    >
                        <Skeleton />
                        <Skeleton />
                    </Card> 
                </Col>
            )
        }) }
     </Row>
    )
}

export default Contracts