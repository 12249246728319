import { Avatar, Space, Typography } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { CloseOutlined } from '@ant-design/icons'
import '../assets/css/help-sticker.scss'

const { Paragraph } = Typography

const HelpSticker = () => {

    const client = useSelector(state => state.user)
    const tech = useSelector(state => state.tech)
    const [showSticker, setShowSticker] = useState(true)

    const user = client || tech

    if(!showSticker) return null

    return (
        <div className='help-sticker'>
            <div className='help-sticker-content'>
                <Space>
                    <Avatar src={user.imageUrl} alt={user.firstname}>{user.firstname[0].toUpperCase()}</Avatar>
                    <Paragraph style={{marginBottom:0, color:"#fff", fontSize:14}}>Hi {user.firstname}!👋 Do you need some help? Visit our <a href="https://help.tecme.io/" target='_blank' style={{textDecoration:"underline", color:"#fff"}}>Help Page</a> for guidance on getting started</Paragraph>
                </Space>
                <CloseOutlined style={{color:"#fff"}} onClick={() => setShowSticker(false)}/>
            </div> 
        </div>
    )
}

export default HelpSticker