import React from 'react'
import TecmeLogo from '../../../assets/images/logo2.png'
import './styles.scss'
import {Link, useLocation, useHistory, useParams } from 'react-router-dom'
import {  } from '@material-ui/core'
import ROUTES from '../../../utils/routes'
import { useState } from 'react'
import { useEffect } from 'react'
import { CaretDownFilled, UserOutlined } from '@ant-design/icons'
import { Avatar, Popover, Space, Typography } from 'antd'
import { useSelector } from 'react-redux'


const clientTeams = [
  {
      name:"TecMe",
      logo:"https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=869&q=80",
      active:true
  },
  {
      name:"Stratos",
      logo:"",
      active:false
  },
]


const {Title, Paragraph, Text} = Typography

const UtilityHeader = () => {

  const location = useLocation()
  const history = useHistory()
  const params = useParams()
  const user = useSelector(state => state.user)
  const organizations = useSelector(state => state.organizations)
  const [hasLogin, setHasLogin] = useState(false)
  const [hasTeam, setHasTeam] = useState(false)
  const [team, setTeam] = useState(null)

  useEffect(() => {
    if (location.pathname.includes('profile') || location.pathname.includes('tech')){
      setHasLogin(true)
    } else {
      setHasLogin(false)
    }
  }, [location.pathname])

  useEffect(() => {
    if (location.pathname.includes(ROUTES.TEAM_LOGIN?.replace(":orgId", params?.orgId))){
      setHasTeam(true)
    } else {
      setHasTeam(false)
    }
  }, [location.pathname])

  useEffect(() => {
    if (user && params?.orgId){
      const team = organizations.find(org => org.id === params.orgId)
      setTeam(team)
    }
  }, [params?.orgId])

  console.log(params?.orgId)


  return (
    <div className={`utility-header ${(hasLogin || hasTeam) && 'login-utility-header'}`}>
      <div className="utility-tecme-logo"
        onClick={() => {
            location.pathname.includes('itsc') && history.push(ROUTES.DASHBOARD) 
        }}
      >
        <img src={TecmeLogo} alt="tecme logo"/>
      </div>
      {
        hasLogin &&  
        <div className="login-link">
          <Link to={ROUTES.LOGIN}>Login</Link>
        </div>
      }
      {
        hasTeam && 
        <Popover content={
          <>
            {
              user && organizations.filter((item) => item?.id !== user?.orgId).map((org, index) => {  
                  return (
                      <div className="nav-menu-popover-content" key={index}>
                          <div onClick={() => {
                                if (org?.id !== user?.orgId){
                                    history.push(ROUTES.TEAM_LOGIN.replace(":orgId", org?.id))
                                }
                              }} className={`team-menu-link ${org?.id === user?.orgId && 'team-menu-link-active'}`}>
                              <Space align='top' size={8}>
                                  <Avatar src={org?.Logo} size={40} icon={<UserOutlined />}>
                                      {org?.Name[0]?.toUpperCase()}
                                  </Avatar> 
                                  <div className="sub-menu-item-text">
                                      <Paragraph style={{color: "rgba(0, 0, 0, 0.85)", fontWeight:500, marginBottom:0}}>{org?.Name}</Paragraph>
                                      <Paragraph style={{fontSize:12, marginBottom:0, color: "rgba(0, 0, 0, 0.5)"}}>Team</Paragraph>
                                  </div>
                              </Space>
                          </div>
                      </div>
                  )
              })
            }
          </>
      }
      trigger="hover"
      placement='bottomLeft'
      overlayClassName="nav-menu-popover"
      >
        <Space align='center' size={12} className='cursor-pointer'>
          <Avatar src={team?.Logo} size={32} icon={<UserOutlined />}>
              {team?.Name && team?.Name[0]?.toUpperCase()}
          </Avatar> 
            <Space size={9}>
                <Paragraph className='team-name'>{team?.Name}</Paragraph>
                <CaretDownFilled style={{position:"relative", top:-2}} />
            </Space>
        </Space>
      </Popover>
      }
    </div>
  )
}

export default UtilityHeader