// External Imports
import { useState, useEffect } from 'react'
import { Modal, Typography, Space, Image, Divider, Switch, Select, Form, Input, Button, Tooltip, notification } from 'antd'
import moment from "moment"
import { InfoCircleOutlined } from "@ant-design/icons"

// Internal Imports
import { capitalizeFirstLetter, getTableTimeFormat, nameFormat } from '../../../../../../../utils/helper'
import ReasonRadioList from './reason-radio-list'
import Bulb from '../../../../public/create-send-contract/assets/images/bulb.svg'
import { contractStatuses } from '../index'

const { Text, Title, Paragraph } = Typography
const { Item, useForm } = Form
const { TextArea } = Input

const customerPoints = [
    "Inaccurate or non-matching invoice amount.",
    "Errors or discrepancies on the invoice related to technical work performed.",
    "Invoicing for unauthorized or unapproved work by the technician."
]

const FileDisputeModal = ({ visible, onOk, onCancel, repository, contractId, contractDetails, getAndSetContractDetails, sendMessage, user, invoicesData, activeInvoice, getAndSetActivities }) => {
    const [loading, setLoading] = useState(false);
    const [pauseContract, setPauseContract] = useState(false);
    const [form] = useForm()

    const handleOnFinish = async (values) => {
        setLoading(true)
        let selectedInvoice = values.Invoice || activeInvoice
        if(typeof selectedInvoice === 'string') {
            selectedInvoice = invoicesData?.find(data => data.id === selectedInvoice)
        }
        const createdDate = moment(moment.utc(selectedInvoice?.created*1000).toDate()).local().format('MM/DD/YYYY')
        const amount = selectedInvoice?.total > 0 ? selectedInvoice?.total/100 : selectedInvoice?.total
        const payload = {
            "Name": `${user?.firstname} ${user?.lastname}`,
            "Email": user?.email,
            "Invoice": `$${amount} - ${createdDate}`,
            "Invoice_Url": selectedInvoice?.hosted_invoice_url,
            "Contract_Id": contractId,
            "Contract_Description" : contractDetails.Description,
            "Dispute_Message": values.Reason
        }

        try {
            await repository.fileDispute(payload)
            if(!pauseContract) {
                await getAndSetActivities();
                notification.success({
                    message: "TecMe",
                    description: "The dispute has been successfully submitted.",
                });
            }
            if(pauseContract) {
                const res = await repository.pauseContract(contractId, {
                    ...values,
                    Technician_Contact: contractDetails?.Provider_Org?.HIPAA_Primary_Contact
                })
                if(res.data?.status === 'success') {
                    const name = nameFormat(`${user?.firstname} ${user?.lastname}`);
                    const location = window.location.href;
                    // await sendMessage(`${name} has paused this contract. ${values.Reason}<br><br> To view the details, simply click on the link below:<br> ${location}`)
                    await getAndSetContractDetails()
                    await getAndSetActivities()
                    notification.success({
                        message: "TecMe",
                        description: "This contract has been paused.",
                    });
                }
            }

            setLoading(false)
            setPauseContract(false)
            form.resetFields()
            onOk()
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        if(!!activeInvoice) {
            const createdDate = moment(moment.utc(activeInvoice?.created*1000).toDate()).local().format('MM/DD/YYYY')
            const amount = activeInvoice?.total > 0 ? activeInvoice?.total/100 : activeInvoice?.total
            const Invoice = {
                ...activeInvoice,
                value: `$${amount} - ${createdDate}`,
                label: `$${amount} - ${createdDate} - ${capitalizeFirstLetter(activeInvoice?.status)}`
            }
            
            form.setFieldsValue({ Invoice })
        }
        if(!activeInvoice) {
            form.setFieldsValue({ Invoice: undefined })
        }
    }, [activeInvoice])

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            footer={false}
            centered
            className='edit-contract-modal'
            destroyOnClose
        >
            <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
            <Paragraph className='my-2 disclaimer p-2'>
                Are you sure you want to proceed with the file dispute? In the event of a dispute, please be advised that such action will not interfere with ongoing contract operations (unless you pause the contract).
                Rather, it will serve to notify TecMe’s support team of the matter, who will reach out to you accordingly.
            </Paragraph>
            <Space direction='vertical' className='w-100'>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleOnFinish}
                    onFinishFailed={error => console.error(error)}
                    className="w-100"
                >
                    <Space direction='vertical' className="w-100">
                        <Item
                            label="Invoice:"
                            name="Invoice"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the invoice'
                                }
                            ]}
                        >
                            <Select
                                placeholder="Please select invoice"
                                className="w-100"
                                options={invoicesData.map(data => ({ ...data, value: data.id, label: `$${data?.total > 0 ? data?.total/100 : data?.total} - ${moment(moment.utc(data?.created*1000).toDate()).local().format('MM/DD/YYYY')} - ${capitalizeFirstLetter(data?.status)}` }))}
                                disabled={!!activeInvoice}
                            />
                        </Item>
                        <Item
                            label="Reason for dispute:"
                            name="Reason"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the reason'
                                },
                                {
                                    min: 25,
                                    message: 'It should be at least 25 characters or more.',
                                }
                            ]}
                        >
                            <TextArea
                                placeholder='Please specify the reason'
                                rows={4}
                                showCount
                                maxLength={250}
                            />
                        </Item>
                        {(contractDetails.Status !== contractStatuses.Paused && contractDetails.Status !== contractStatuses.Terminated) && (
                            <Space size="large" className='w-100 justify-content-between mt-3'>
                                <Space>
                                    <Paragraph strong className='mb-0' style={{ marginTop: '3px' }}>Would you like to "Pause" the contract?</Paragraph>
                                    <Tooltip
                                        title="During the pause period of the contract, no additional actions can be taken, including creating and assigning tickets. Technicians will not be able to clock in or out, resolve, or close tickets, and any automatic or manual billing will be halted. To make the contract active again, you will have to resume the contract."
                                    >
                                        <InfoCircleOutlined  />
                                    </Tooltip>
                                </Space>
                                <Switch onChange={setPauseContract} size="default" />
                            </Space>
                        )}
                        <Item>
                            <Space className='mt-3 w-100 justify-content-end'>
                                <Button type='text' onClick={() => {
                                    form.resetFields()
                                    setPauseContract(false)
                                    onCancel()
                                }}>
                                    Cancel
                                </Button>
                                <Button type="primary" htmlType="submit" loading={loading}>
                                    File Dispute
                                </Button>
                            </Space>
                        </Item>
                    </Space>
                </Form>
                
            </Space>
        </Modal>
    )
}

export default FileDisputeModal