import { createStore, applyMiddleware, compose } from 'redux'
import persistReducer from './reducers'

// export const history = createHistory()

const store = createStore(
    persistReducer,
)


export default store
