import { Modal, Typography, Upload, message, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { PrimaryButton } from './button'
import HeroImage from '../assets/images/hero-img-new.png'
import FriendlyIcon from '../assets/images/friendly-icon.svg'	
import TechSupportIcon from '../assets/images/tech-support-icon.svg';
import WorryFreeIcon from '../assets/images/worry-free-icon.svg';
import ROUTES from '../../../../../../utils/routes'
import { useHistory } from 'react-router-dom'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import { FirebaseHelper } from '../../../../../../utils/firebase-helper'
import NewMessageModal from './new-message-modal'
import { useSelector } from 'react-redux'
import { getBase64, removeSubdomainFromUrl } from '../../../../../../utils/helper'
import { SECONDARY_COLOR } from '../../../../../../utils/constants'
import { EditOutlined, EnterOutlined } from '@ant-design/icons'



const { Paragraph, Title } = Typography

const ItcHero = ({ brandColor, memberItcProfiles, itcContent, activeConversationId, groupConversationId, canEdit, token}) => {
    
    const history = useHistory()
    const tech = useSelector(state => state.tech)
    const user = useSelector(state => state.user)
    const [messageModalVisible, setMessageModalVisible] = useState(false)
    const [heroHeader, setHeroHeader] = useState(itcContent?.custom_data?.heroHeader || "")
    const [tagline, setTagline] = useState(itcContent?.custom_data?.tagline || "")
    const [heroImgUrl, setHeroImgUrl] = useState(itcContent?.Hero_Image)
    const [heroImgFile, setHeroImgFile] = useState(null)
   
    const activeUser = user || tech

    const HERO_POINTS = [
        {
            icon: FriendlyIcon,
            text: <span>Friendly, US-Based <br /> Support</span>
        },
        {
            icon: TechSupportIcon,
            text: <span>Simplest Way To <br /> Get Tech Support</span>
        },
        {
            icon: WorryFreeIcon,
            text: <span>Worry-Free <br /> Guarantee</span>
        }
    ]

    const handleContentEdit = (content) => {
        ApiBaseHelper.put({
            url: `itc/${itcContent?.id}`,	
            headers: {
                Authorization: 'Bearer ' + tech['token'],
            },
            payload: {
                Active:true,
                ...content,
            }
        }).then((res) => {
            console.log("success")
        }).catch((err) => {
            console.log(err)
        })
    }

    const uploadHeroImage = (file) => {
        let formData = new FormData()
        formData.append("file", file)
        ApiBaseHelper.post({
            url: `itc/hero-image/${itcContent?.id}`,	
            headers: {
                Authorization: 'Bearer ' + tech['token'],
            },
            payload: formData
        }).then((res) => {
            console.log("success")
        }).catch((err) => {
            console.log(err)
        })
    }

    const heroImgProps = {
        accept: 'image/*',
        multiple: false,
        showUploadList: false,
        beforeUpload: (file) => {
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                message.error('Image must be smaller than 5MB!');
            } else {
                getBase64(file, imageUrl =>{
                    setHeroImgUrl(imageUrl)
                });
                setHeroImgFile(file)
                uploadHeroImage(file)
            }
            return false;
        },
        onDrop(e) {
            setHeroImgFile(e.dataTransfer.files[0]);
        },
        onRemove: (file) => {
            setHeroImgFile(null);
            return true;
        }
    };



    const chatButtonLogic = () => {
        if(!activeUser){
            return <PrimaryButton
                brandColor={brandColor}
                buttonStyle={{
                    fontSize: '14px',
                    width:180
                }}
                onClick={() => {
                    window.open(`${removeSubdomainFromUrl(itcContent?.Subdomain)}${ROUTES.TECH_PROFILE_WITH_ID.replace(":id", `${itcContent?.Owner_Contact}`)}`, "_self")	
                }}
            >
                START A CHAT
            </PrimaryButton>
        }
        if((tech && memberItcProfiles.map((item) => item.id).includes(itcContent?.id) || (tech?.id === itcContent?.Owner_Contact))){
            return <PrimaryButton
                brandColor={brandColor}
                buttonStyle={{
                    fontSize: '14px',
                    width:180
                }}
            onClick={() => {
                if(groupConversationId){
                    history.push(`${ROUTES.INBOX}?id=${groupConversationId}`)
                }else{
                    notification.info({
                        message: 'TecMe',
                        description: 'Seems group chat is inactive, kindly reach out to support',
                    })
                }
            }}
        >
            GO TO GROUP CHAT
        </PrimaryButton>
        }
        if(activeUser !== null && activeConversationId  && !memberItcProfiles.map((item) => item.id).includes(itcContent?.id)){
            return <PrimaryButton
                brandColor={brandColor}
                buttonStyle={{
                    fontSize: '14px',
                    width:180
                }}
                onClick={() => history.push(`${ROUTES.INBOX}?id=${activeConversationId}`)}
            >
                GO TO CHAT
            </PrimaryButton>
        }
        if(activeUser !== null && !activeConversationId && !memberItcProfiles.map((item) => item.id).includes(itcContent?.id)){
            return <PrimaryButton
                brandColor={brandColor}
                buttonStyle={{
                    fontSize: '14px',
                    width:180
                }}
                onClick={() => setMessageModalVisible(true)}
            >
                START A CHAT
            </PrimaryButton>
        }
    }

    return (
        <section className='itc-hero-section' id="home">
            <div className="itc-hero-ctn">
                <div className="left-section">
                    <div className="text-content">
                        <Paragraph className="hero-sponsor">Powered by <a href="https://tecme.io" target="_blank" rel="noopener noreferrer">TecMe.io</a></Paragraph>
                        <Title 
                            level={1} 
                            className="hero-title" 
                            editable={ canEdit ? { 
                                onChange: function(value){
                                    setHeroHeader(value)
                                    handleContentEdit({Hero_Header: value})
                                },
                                maxLength:100,
                                icon: <EditOutlined style={{color:SECONDARY_COLOR, fontSize:25}} />,
                                enterIcon: <EnterOutlined style={{color:SECONDARY_COLOR}} />,
                            } : false}
                        >
                            {heroHeader || "Empowering Your Business with Premier IT Services"}
                            {/* Empowering Your Business with <span style={{color:brandColor}}>Premier IT Services</span> */}
                        </Title>
                        <Paragraph 
                            className="hero-subtitle"
                            editable={canEdit ? { 
                                onChange: function(value){
                                    setTagline(value)
                                    handleContentEdit({Tagline: value})
                                },
                                maxLength:100,
                                icon: <EditOutlined style={{color:SECONDARY_COLOR, fontSize:25, position:"relative", top:3}} />,
                                enterIcon: <EnterOutlined style={{color:SECONDARY_COLOR}} />
                            } : false}
                        >
                                {tagline || "The best tech solution, ready to help you - anytime, anywhere."}
                        </Paragraph>
                       {chatButtonLogic()}
                    </div>
                </div>
                <div className="right-section">
                    {/* <div className="hero-gradient" style={{background: `radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.00) 0%, ${brandColor} 100%)`}}></div>
                    <div className="itc-hero-img">
                        <img src={HeroImage} alt="hero image" />
                    </div> */}
                    <div className='itc-hero-img'>
                        <img src={heroImgUrl || HeroImage} alt="hero image" />
                    </div>
                   { 
                        canEdit &&
                        <Upload {...heroImgProps}>
                            <div className="landing-edit-icon">
                                <EditOutlined style={{fontSize:25, color:SECONDARY_COLOR}} />
                            </div>
                        </Upload>
                    }
                </div>
            </div>
            <div className='hero-points-section'>
                <div className="hero-points-ctn">
                    {
                        HERO_POINTS.map((point, index) => {
                            return (
                                <div className="hero-point-item" key={index}>
                                    <div className="hero-point-icon" style={{backgroundColor:brandColor}}>
                                        <img src={point.icon} alt="icon" />
                                    </div>
                                    <Paragraph className="hero-point-text">{point.text}</Paragraph>
                                </div>
                            )
                        })
                    }           
                </div>
            </div>
            <NewMessageModal
                messageModalVisible={messageModalVisible}
                setMessageModalVisible={setMessageModalVisible}
                itcContent={itcContent}
                activeConversationId={activeConversationId}
            />
        </section>
    )
}

export default ItcHero