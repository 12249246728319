import React, { useState } from 'react'
import { AddedItemCard } from './added-item'
import { Button, List, Space, Typography, notification } from 'antd'
import ActionModal from './delete-modal'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import { useSelector } from 'react-redux'
import CustomRenderEmpty from '../../../../../components/custom-render-empty'
import '../assets/css/added-item.scss'


const {Paragraph} = Typography

const FaqList = ({faqs, setShowAddedFaq, fetchFaqs, handleNextClick, setShowBackBtn, loadingFaqs, setEditFaqData, isFromItcProfile=false}) => {
    const tech = useSelector(state => state.tech)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deletingFaq, setDeletingFaq] = useState(false)
    const [deleteId, setDeleteId] = useState(null)

    const deleteFaq = () => {
        setDeletingFaq(true)
        ApiBaseHelper.delete({
            url: `itc/faq/${deleteId}`,
            headers: {
                Authorization: `Bearer ${tech.token}`
            },
        }).then((res) => {
            if (res.data) {
                notification['success']({
                    message: 'TecMe',
                    description: "FAQ deleted successfully",
                });
                fetchFaqs()
                setDeleteModal(false)
                setDeletingFaq(false)
            }
        }).catch((error) => {
            setDeletingFaq(false)
            notification['error']({
                message: 'TecMe',
                description: "An error Occurred",
            });
        })
    }

    return(
        <>
            <div className="added-item-list added-faq-list">
              
                 <CustomRenderEmpty
                    description={"No FAQ Added yet"}
                >
                    <List
                        loading={loadingFaqs}
                        dataSource={faqs}         renderItem={(ele, index)=> (
                            <List.Item>
                                <AddedItemCard
                                    key={ele.id}
                                    onClose={() => {
                                        setDeleteId(ele.id)
                                        setDeleteModal(true)
                                    }}
                                    onEdit={() => {
                                        setShowAddedFaq(isFromItcProfile ? true : false)
                                        setEditFaqData(ele)   
                                    }}
                                >
                                    <div className="added-faq-content">
                                        <div className="top-content-details">
                                            <Space align='top'>
                                                <div className="number-faq">
                                                    {index+1}
                                                </div>
                                                <div>
                                                    <Paragraph style={{color: "rgba(0, 0, 0, 0.85)", fontWeight:500, margin:0}}>{ele?.Question}</Paragraph>
                                                    <div className="bottom-content-details">
                                                        <Paragraph style={{margin:0, color:"#000", fontSize:14, fontWeight:300}}>{ele?.Answer}</Paragraph>
                                                    </div>
                                                </div>
                                            </Space>
                                        </div>
                                    </div>
                                </AddedItemCard>
                            </List.Item>
                        )}
                    >

                    </List>
                </CustomRenderEmpty>
                

                {/* {
                    faqs.length > 0 ?
                    faqs.map((ele, index) => {
                        return (
                            <AddedItemCard
                                key={ele.id}
                                onClose={() => {
                                    setDeleteId(ele.id)
                                    setDeleteModal(true)
                                }}
                            >
                                <div className="added-faq-content">
                                    <div className="top-content-details">
                                        <Space>
                                            <div className="number-faq">
                                                {index+1}
                                            </div>
                                            <Paragraph style={{color: "rgba(0, 0, 0, 0.85)", fontWeight:500, margin:0}}>{ele?.Question}</Paragraph>
                                        </Space>
                                    </div>
                                    <div className="bottom-content-details">
                                        <Paragraph style={{margin:0, color:"#000", fontSize:14, fontWeight:300}}>{ele?.Answer}</Paragraph>
                                    </div>
                                </div>
                            </AddedItemCard>
                        )
                    }) :
                    <div className="no-result-itc">
                        <Paragraph style={{margin:0, color:"#000", fontSize:14}}>No FAQ added yet</Paragraph>
                    </div>
                } */}
                <Space direction='vertical' className='d-block'>
                    <Button 
                        className='add-new-btn'
                        onClick={() => setShowAddedFaq(isFromItcProfile ? true : false)}
                    >
                        + Add New FAQ
                    </Button>
                   { 
                    !isFromItcProfile &&	
                        <Button 
                            className='itc-next-btn' 
                            type='primary' 
                            onClick={() =>{ 
                                handleNextClick()
                                setShowBackBtn(true)
                            }}
                        >
                            Next
                        </Button>
                    }
                </Space>
            </div>
            <ActionModal 
                visible={deleteModal}
                handleCancel={() => setDeleteModal(false)}
                handleClick={() => deleteFaq()}
                message={"This action will permanently delete the FAQ. Please make sure you want to delete the item before proceeding."}
                loading={deletingFaq}
                deleteText={"Delete"}
            />
        </>
    )
}

export default FaqList