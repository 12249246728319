import React, { useEffect, useState } from 'react'
import { AddedItemCard } from '../../create-itc/components/added-item'
import { Avatar, Button, Modal, Space, Typography, notification } from 'antd'
import { FaqForm } from '../../create-itc/components/faq'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import { LoadingOutlined } from '@ant-design/icons'
import FaqList from '../../create-itc/components/faq-list'

const { Title, Paragraph } = Typography

const ProfileFaq = ({itcId, token}) => {
    const [showAddedFaq, setShowAddedFaq] = useState(false)
    const [faqs, setFaqs] = useState([])
    const [loadingFaqs, setLoadingFaqs] = useState(false)
    const [editFaqData, setEditFaqData] = useState(null)
   
    const fetchFaqs = () => {
        setLoadingFaqs(true)
        ApiBaseHelper.get({
            url: `itc/faq/all?itc=${itcId}`, 
            headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setFaqs(res?.data?.data)
            setLoadingFaqs(false)
        }).catch((error) => {
            console.error(error)
            setLoadingFaqs(false)
        })       
    }

   

    useEffect(() => {
        fetchFaqs()
    }, [])

    useEffect(() => {
        fetchFaqs()
    }, [showAddedFaq])


    if(showAddedFaq) {
        return(
            <div className="profile-faq-form-ctn">
               <FaqForm
                    setShowAddedFaq={setShowAddedFaq}
                    isFromItcProfile={true}
                    fetchFaqs={fetchFaqs}
                    editFaqData={editFaqData}
                    setEditFaqData={setEditFaqData}
               />
            </div>
        )
    }

    return (
        <>
            <Title level={4} className="itc-form-title">FAQs</Title>
            <FaqList
                faqs={faqs} 
                setShowAddedFaq={setShowAddedFaq}
                fetchFaqs={fetchFaqs}
                isFromItcProfile={true}
                loadingFaqs={loadingFaqs}
                setEditFaqData={setEditFaqData}
            />
        </>
    )
}


export default ProfileFaq