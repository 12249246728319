import React, { useEffect } from 'react'
import '../assets/css/job-card.scss'
import { Button, Col, Drawer, Rate, Row, Space, Tag, Typography } from 'antd'
import { ClockCircleOutlined, DollarCircleOutlined, EnvironmentFilled, EnvironmentOutlined, HomeOutlined, LeftOutlined, PaperClipOutlined, SafetyCertificateFilled, SafetyCertificateOutlined, WalletOutlined } from '@ant-design/icons'
import { useState } from 'react'
import ClientJobDetails from '../../../../common/private/job-post/components/client-details'
import { useHistory, useLocation, Link } from 'react-router-dom'
import ROUTES from '../../../../../../utils/routes'
import moment from 'moment'
import { convertBudget, getDurationInfo, getSpecializationTitle, getWorkTypeInfo } from '../../../../common/private/job-post/utils/helper'
import { capitalizeFirstLetter, getFromNowDateFormat, getTableTimeFormat } from '../../../../../../utils/helper'
import useWindowSize from '../../../../../../hooks/useWindowSize'
import { useSelector } from 'react-redux'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import { SECONDARY_COLOR } from '../../../../../../utils/constants'


const { Title, Paragraph, Text } = Typography
 
const JobCard = ({item, generalSkills}) => {
    const tech = useSelector(state => state.tech)
    const [openDrawer, setOpenDrawer] = useState(false);
    const history = useHistory()
    const [attachments, setAttachments] = useState([])
    const location = useLocation()
    const { windowWidth } = useWindowSize()

    const fetchAttachments = () => {
        ApiBaseHelper.get({
            url: `job-post/media/${item?.id}`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setAttachments(res?.data?.data)
        }).catch((error) => {
            console.error(error)
        })       
    }

    useEffect(() => {
        fetchAttachments()
    }, [item])

    console.log(attachments)


    return (
        <>
            <div key={item.id} className='job-browsing-card'
             onClick={() => {
                if(windowWidth > 991) {
                    setOpenDrawer(true)
                }else{
                    history.push({
                        pathname: ROUTES.JOB_POST.replace(":id", item?.id),
                        state: {jobPostId: item?.id}
                    })
                }
            }}>
                <div className='job-browsing-card-heading'>
                    <Space wrap>
                        <Title level={5} style={{margin:0}}>{capitalizeFirstLetter(item?.Title)}</Title>
                        { windowWidth > 991 && <span className='dot'>•</span>}
                        <Text style={{fontSize:12, fontWeight:500, color:"rgba(0, 0, 0, 0.65)", flexShrink:0}}>{getFromNowDateFormat(item?.CreatedDate)}</Text>
                    </Space>
                </div>
                <div className='job-browsing-card-subheading'>
                    <Space 
                        direction={windowWidth > 576 ? 'horizontal' : 'vertical'} 
                        size={windowWidth > 576 ? 8 : 0}
                    >
                        <Paragraph><span>Budget: </span>{convertBudget(item?.Budget, item?.Payment_Type)}</Paragraph>
                        <span className='dot'>•</span>
                        <Paragraph><span>Work Type: </span>{getWorkTypeInfo(item?.Work_Type)?.text}</Paragraph>
                        <span className='dot'>•</span>
                        <Paragraph><span>Duration: </span>{getDurationInfo(item?.Duration)?.subText}</Paragraph>
                    </Space>
                </div>
                <Paragraph ellipsis={{rows:3}} style={{fontSize:14}}>{capitalizeFirstLetter(item.Description)}</Paragraph>
                <div className="job-browsing-skills">
                    {
                         item?.Skills_Required?.split(', ').map((item, index) => {
                            return (
                                <Tag key={index} className="custom-ant-tag">{item}</Tag>
                            )
                        })
                    }
                </div>
                <Space>
                    {
                        (item?.custom_data?.Payment_Verified === "1" || item?.custom_data?.Payment_Verified === "true") ? 
                        <Space>
                            <SafetyCertificateFilled className='safety-icon' style={{color:"#36A7F9D9"}}/>
                            <Paragraph style={{margin:0, fontSize:12, fontWeight:500}}>Payment verified</Paragraph>
                        </Space> :
                        <Space>
                            <WalletOutlined className='safety-icon'/>
                            <Paragraph style={{margin:0, fontSize:12, fontWeight:500}}>Payment unverified</Paragraph>
                        </Space>
                    }
                    
                    {/*<span className='dot'>•</span>
                     <Space>
                        <Rate disabled defaultValue={item?.rating}  style={{fontSize:12, marginBottom:5, color:"#FEB000"}}/>
                        <Paragraph style={{margin:0, fontSize:12}}>{item?.rating}</Paragraph>
                    </Space> */}
                    {/* <span className='dot'>•</span>
                    <Space>
                        <EnvironmentOutlined />
                        <Paragraph style={{margin:0, fontSize:12, fontWeight:500}}>{item?.location}</Paragraph>
                    </Space> */}
                </Space>
            </div>
            <Drawer className='job-drawer' width={976} placement="right" closable={false} onClose={() => setOpenDrawer(false)} open={openDrawer}>
                <div className="job-drawer-ctn">
                    <div className="drawer-navigation">
                        <LeftOutlined onClick={() => setOpenDrawer(false)} />
                        <Link to={ROUTES.JOB_POST.replace(":id", item?.id)} target="_blank">Open job post in new window</Link>
                    </div>
                    <div className='drawer-job-title'>
                        <div>
                            <Title level={4} style={{margin:0}}>{capitalizeFirstLetter(item?.Title)}</Title>
                            <Space align='center'>
                                {/* <Space>
                                    <EnvironmentFilled />
                                    <Paragraph style={{margin:0, fontSize:12, fontWeight:500}}>{item?.location}</Paragraph>
                                </Space>
                                <span className='dot'>•</span> */}
                                <Paragraph style={{fontSize:12, margin:0, fontWeight:500, color:"rgba(0, 0, 0, 0.65)"}}>{getFromNowDateFormat(item?.CreatedDate)}</Paragraph>
                            </Space>
                        </div>
                        <Button type="primary" size='large' onClick={() => history.push({
                                pathname: ROUTES.SUBMIT_JOB_APPLICATION.replace(":title", item.Title),
                                state: {jobPostId: item?.id}
                            })}>
                            Submit application
                        </Button>
                    </div>
                    <div className="drawer-info-card">
                        <Row>
                            <Col lg={8} xl={8}>
                                <Space align='start'>
                                    <DollarCircleOutlined />
                                    <div>
                                        <Paragraph style={{margin:0, fontSize:14, fontWeight:500}}>Budget</Paragraph>
                                        <Paragraph style={{margin:0,  fontSize:14, fontWeight:500, color:"rgba(0, 0, 0, 0.65)"}}>{convertBudget(item?.Budget, item?.Payment_Type)}</Paragraph>
                                    </div>
                                </Space>  
                            </Col>
                            <Col lg={8} xl={8}>
                                <Space align='start'>
                                    <HomeOutlined />
                                    <div>
                                        <Paragraph style={{margin:0, fontSize:14, fontWeight:500}}>{getWorkTypeInfo(item?.Work_Type)?.text}</Paragraph>
                                        <Paragraph style={{margin:0,  fontSize:14, fontWeight:500, color:"rgba(0, 0, 0, 0.65)"}}>{getWorkTypeInfo(item?.Work_Type)?.subText}</Paragraph>
                                    </div>
                                </Space>  
                            </Col>
                            <Col lg={8} xl={8}>
                                <Space align='start'>
                                    <ClockCircleOutlined />
                                    <div>
                                        <Paragraph style={{margin:0, fontSize:14, fontWeight:500}}>{getDurationInfo(item?.Duration)?.text}</Paragraph>
                                        <Paragraph style={{margin:0,  fontSize:14, fontWeight:500, color:"rgba(0, 0, 0, 0.65)"}}>{getDurationInfo(item?.Duration)?.subText}</Paragraph>
                                    </div>
                                </Space>  
                            </Col>
                        </Row>
                    </div>
                    <Title level={5} style={{color:"#F04A22", marginBottom:34}}>{getSpecializationTitle(generalSkills, item?.Category)}</Title>
                    <div className="about-job">
                        <Title level={5} style={{color:"#000"}}>About the job</Title>
                        {
                            item?.Description?.split('\n').map((line, index) => (
                                <Paragraph key={index}>
                                {line}
                                </Paragraph>
                            ))
                        }
                        {
                            attachments?.data?.length > 0 &&
                            attachments?.data?.map((item, index) => {
                                return(
                                    <div key={item?.id} className='mb-1'>
                                        <Space align='center' className="download-cv">
                                            <PaperClipOutlined style={{color:"rgba(0, 0, 0, 0.45)", position:"relative", top:2}}/>
                                            <a href={item?.Body} style={{color:SECONDARY_COLOR, textDecoration:"none"}}>{item?.Title}</a>
                                        </Space>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='job-skills'>
                        <Title level={5}>Skills and Expertise</Title>
                        {
                            item?.Skills_Required?.split(', ').map((item, index) => {
                                return (
                                    <Tag key={index} className="custom-ant-tag">{item}</Tag>
                                )
                            })
                        }
                    </div>
                    <div className="browse-jobs-client-details">
                        <ClientJobDetails clientDetails={item?.custom_data} fromBrowseJobs={true} />
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default JobCard