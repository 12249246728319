import { Button, Form, Input, Typography, notification } from 'antd'
import React from 'react'
import '../css/styles.scss'
import '../css/account-password.scss'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { ChangePasswordRepository } from '../../change-password/repository/repository'

const {Paragraph, Title, Text} = Typography
const { useForm } = Form

const AccountPassword = () => {
	const [loading, setLoading] = useState(false)
	const client = useSelector(state => state.user)
    const tech = useSelector(state => state.tech)
	const [form] = useForm();

	const authUser = client || tech

	const changePasswordRepository = new ChangePasswordRepository(authUser?.token)

	const handlePasswordSubmit = (values) => {
		console.log(values)
		setLoading(true)
		let data = {
			email: authUser?.email,
			oldPassword: values.currentPassword,
			newPassword: values.newPassword
		}
		changePasswordRepository.changePassword(data)
		.then(res => {
			if(res){
				notification.success({
					message: 'TecMe',
					description: 'Password changed successfully'
				})
				setLoading(false)
				form.resetFields()
			}
		}).catch(err => {
			console.log(err.response.data.message)
			notification.error({
				message: 'TecMe',
				description: err.response.data.message
			})
			setLoading(false)
		})
	}

	return (
		<div className="password-section settings-tab-ctn">
			<div className="password-ctn">
				<div className="password-heading">
					<Title level={4} style={{color:"#101828", fontSize:18, marginBottom:4}}>Password</Title>
					<Paragraph style={{color: "rgba(0, 0, 0, 0.65)", margin:0}}>Please enter your current password to change your password.</Paragraph>
				</div>
				<div className="password-form-section">
					<Form
						form={form}
						onFinish={handlePasswordSubmit}
						layout='vertical'
					>
						<Form.Item 
							className='mb-4'
							label="Current password"
							name="currentPassword"
							rules={[{ required: true, min: 0, message: 'Please enter a valid current password'},
								{min: 8, message: 'Password should be at least 8 characters or more'},
							]}
						>
							<Input.Password className='password-form-input' />
						</Form.Item>
						<div className='mb-4'>
							<Form.Item 
								label="New password"
								name="newPassword"
								dependencies={['currentPassword']}
								rules={[
									{ required: true, min: 0, message: 'Please enter a valid new password'},
									{min: 8, message: 'Password should be at least 8 characters or more'},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue('currentPassword') !== value) {
												return Promise.resolve();
											}
											return Promise.reject(new Error('The new password must be different from the current password!'));
										},
									}),
								]}
							>
								<Input.Password className='password-form-input' />
							</Form.Item>
							<Text style={{color: "rgba(0, 0, 0, 0.65)"}}>Your new password must be at least 8 characters</Text>
						</div>
						<Form.Item 
							className='mb-5'
							label="Confirm new password"
							name="confirmPassword"
							dependencies={['newPassword']}
							rules={[
								{
									required: true,
									message: 'Please confirm your new password.',
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue('newPassword') === value) {
											return Promise.resolve();
										}
										return Promise.reject(new Error('The two passwords do not match!'));
									},
								}),
							]}
						>
							<Input.Password className='password-form-input' />
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit" loading={loading}>Save</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
		</div>
	)
}

export default AccountPassword