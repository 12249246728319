import React, { useEffect, useRef, useState } from 'react'
import PageHeaderWrapper from '../../../../components/page-header-breadcrumbs'
import { MetaTags } from 'react-meta-tags'
import { META_DESCRIPTION } from '../../../../../utils/constants'
import { Avatar, Badge, Button, DatePicker, Layout, List, Popover, Select, Space, Table, Tag, Typography, notification } from 'antd'
import ROUTES from '../../../../../utils/routes'
import ActionIcon from  './assets/icons/action-icon.svg'
import { capitalizeFirstLetter, capitalizeFirstWordOfEveryLetter, contactSupportModal, getDottedTimeFormat, getReportTimeFormat, getTableTimeFormat } from '../../../../../utils/helper'
import { CalendarOutlined, DownloadOutlined, EyeOutlined, SwapLeftOutlined } from '@ant-design/icons'
import useWindowSize from '../../../../../hooks/useWindowSize'
import { useHistory } from 'react-router-dom'
import ReportChartImg from './assets/icons/report-chart.png' 
import { addDays, set } from 'date-fns';
import { DateRange, DateRangePicker } from 'react-date-range'
import moment from 'moment'
import { CSVLink } from 'react-csv';

import './assets/css/styles.scss'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { useSelector } from 'react-redux'



const { Content } = Layout
const { Title, Paragraph } = Typography

const statusColor = (status) => {
    switch(status){
        case 'paid':
            return 'success'
        case 'unpaid':
            return 'error'
        default:
            return 'default'
    }
}

const csvFileTimeFormat = (timeStamp) => {
    const localTimestamp = moment(moment.utc(timeStamp).toDate()).local()
    return localTimestamp.format("MM/DD/YYYY")
}

const Reports = () => {
    const tech = useSelector(state => state.tech)    
    const client = useSelector(state => state.user)
    const {windowWidth} = useWindowSize()
    const history = useHistory()
    const ref = useRef(null);
    const [reportDetails, setReportDetails] = useState({})
    const [reportList, setReportList] = useState([])
    const [reportSheet, setReportSheet] = useState([])
    const [loadingReports, setLoadingReports] = useState(false)
    const [contractsList, setContractsList] = useState([])
    const [filteredContractOptions, setFilteredContractOptions] = useState([]);
    const [openDateRangePicker, setOpenDateRangePicker] = useState(false)
    const [contractDetails, setContractDetails] = useState(null)
    const [dateState, setDateState] = useState([
        {   
            startDate: addDays(new Date(), -30),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const authUser = tech || client

    const options = { year: 'numeric', month: 'short', day: 'numeric', };
    const locale = 'en-US';

    const handleDatePickerClose = () => {
        setOpenDateRangePicker(false);
    } 

    const fetchReports = () => {
        setLoadingReports(true)
        ApiBaseHelper.post({
            url: `reports/invoices`,	
            headers: {
                Authorization: 'Bearer ' + authUser['token'],
            },
            payload: {
                customer_id: authUser?.organization?.Stripe_Customer_Id,
                subscription_id: (contractDetails?.Type === "Hourly" || contractDetails?.Type === "Fixed") && contractDetails?.custom_data?.stripe_subscription,
                contract_id: contractDetails?.Type === "Per Ticket" && contractDetails?.id,
                start_date: dateState[0]?.startDate?.toISOString(),
                end_date: dateState[0]?.endDate?.toISOString()
            }
        }).then((res) => {
            if(res){
                console.log(res)
                setReportDetails(res?.data?.data)
                setLoadingReports(false)
            } 
           
        }).catch((error) => {
            setLoadingReports(false)
            notification.error({
                message: 'TecMe',
                description: "Unable to fetch reports",
            });
            console.error(error)
        })
    }

    const fetchContracts = () => {
        ApiBaseHelper.get({
            url: "contract?role=customer", headers: {
                Authorization: 'Bearer ' + authUser?.token
            }
        }).then((res) => {
            if (res?.data?.data?.length) {
                setContractsList(res.data.data.filter(contract => contract.Status === "Active"))
            }
        }).catch((error) => console.error(error))
    }

    const handleContractSearch = (value) => {
       const filteredContractOptions = contractsList.filter(option => option.Description.toLowerCase().includes(value.toLowerCase()));
       setFilteredContractOptions(filteredContractOptions);
    }

    useEffect(() => {
        fetchReports()
    }, [authUser])

    useEffect(() => {
        fetchContracts()
    }, [])

    useEffect(() => {
        const invoiceData = [
            ["", "", ""], // Empty row for spacing
            ["Invoice #", "Paid On", "Amount($)"], // Header row for data
            ...reportList.map(item => [
              item?.invoice_number,
              csvFileTimeFormat(moment.unix(item?.created)),
              `$${item?.amount_paid?.toFixed(2)}`,
            ]),
            ["", "", ""], // Empty row for spacing
            ["", "Total", `$${reportDetails?.total?.toFixed(2)}`], // Adding the total amount as the last row
        ]

        let newReportSheet = [
            ["Start Date", csvFileTimeFormat(dateState[0]?.startDate?.toLocaleDateString())],
            ["End Date", csvFileTimeFormat(dateState[0]?.endDate?.toLocaleDateString())],
            ...invoiceData
        ];

        if(contractDetails){
            newReportSheet = [
                ["Start Date", csvFileTimeFormat(dateState[0]?.startDate?.toLocaleDateString())],
                ["End Date", csvFileTimeFormat(dateState[0]?.endDate?.toLocaleDateString())],
                ["Technician", contractDetails.Provider_Org?.HIPAA_Contact_Name || contractDetails?.Provider_Org?.Name || ""],
                ["Hourly Rate", contractDetails ? `${(Number(contractDetails?.Billing_Rate || 0) / 100).toString()}${contractDetails?.Billing_Frequency === "Bi-weekly" ? ' bi-weekly' : contractDetails?.Billing_Frequency || '/hr'}` : ""], 
                ["Contract", contractDetails?.Description || ""],
                ...invoiceData
            ]
        }

        setReportSheet(newReportSheet);
    }, [reportList, contractDetails])

    useEffect(() => {
        if(reportDetails?.invoices){
            setReportList(reportDetails?.invoices)
        }
    }, [reportDetails])

    useEffect(() => {
        setFilteredContractOptions(contractsList)
    },[contractsList])
        

    useEffect(() => {
        const handleHoverOutside = (event) => {
            if (openDateRangePicker && ref.current && !ref.current.contains(event.target)) {
                handleDatePickerClose && handleDatePickerClose();
            }
        };
        document.addEventListener('click', handleHoverOutside);
        return () => {
          document.removeEventListener('click', handleHoverOutside);
        };
      }, [openDateRangePicker]);

    const reportPopoverContent = (item) => {
        return(
            <div className="global-popover-content">   
                <Space 
                    className='popover-action-item' align='center'
                    onClick={(e) => { 
                        e.stopPropagation()
                        // history.push(ROUTES.EDIT_JOB_POSTING.replace(":id", item?.id))
                    }}
                >
                    <DownloadOutlined />
                    <Paragraph style={{marginBottom:0}}>Download Invoice</Paragraph> 
                </Space>
                <Space className='popover-action-item' 
                    align='center' 
                    onClick={(e) => {
                        e.stopPropagation()
                        // history.push(ROUTES.MANAGE_JOB_POSTING.replace(":id", item?.id))
                    }}
                >
                    <EyeOutlined />
                    <Paragraph style={{marginBottom:0}}>View Contract</Paragraph> 
                </Space>
            </div>
        )
    }


    const reportsColumns = [
        {
            title: 'Invoice #',
            dataIndex: 'invoice',
            key: 'invoice',
            // width:"40%",
            render:(_, record) => {
                return(
                    <Paragraph style={{margin:0, fontWeight:500}}>{record?.invoice_number}</Paragraph>
                )
            },
            sorter: {
                compare: (a, b) => {
                    if(a.invoice_number) return a.invoice_number.localeCompare(b.invoice_number)
                },
            },
        },
        {
            title: 'Paid On',
            key: 'paid',
            render: (_, record) => (
                <Paragraph style={{margin:0, fontWeight:500}}>{getTableTimeFormat(moment.unix(record?.created))}</Paragraph>
            ),
            sorter: {
                compare: (a, b) => {
                    if(a.created) return a.created.toString().localeCompare(b.created)
                },
            },
                 },
        {
            title:'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render:(_, record) => {
                return(
                    <Space size={20}>
                        <Paragraph style={{margin:0, fontWeight:500, width:60}}>${record?.amount_paid?.toFixed(2)}</Paragraph>
                        <Paragraph style={{margin:0, fontWeight:500, color:"#6A6A6A", width:60}}>USD</Paragraph>
                        <Tag color={statusColor(record?.status)}>{capitalizeFirstLetter(record?.status)}</Tag>
                    </Space>
                )
            },
            sorter: {
                compare: (a, b) => {
                    if(a.amount_paid) return a.amount_paid.toString()?.localeCompare(b.amount_paid)
                },
            },
        },
        // {
        //     title: 'Actions',
        //     key: 'actions',
        //     // width:"30%",
        //     render: (_, record) => (
        //     <Space className='table-action-ctn' wrap>
        //         <Popover content={reportPopoverContent(record)}  trigger="click" placement='bottom' overlayClassName="global-popover" arrowPointAtCenter>
        //             <img src={ActionIcon} alt="action icon" onClick={
        //                 (e) => {
        //                     e.stopPropagation()
        //                 }}/>  
        //         </Popover>
        //     </Space> 
        //     ),
        // },
    ];


    const emptyReportState = () => {
        return(
            <div className="empty-state-ctn">
                <div className="empty-state-img">
                    <img src={ReportChartImg} alt="empty state" />
                </div>
                <div className="empty-state-content">
                    <Title level={5} className='empty-state-title'>There are no reports to display for the selected date range.</Title>
                    <Paragraph className='empty-state-subtitle'>If you believe this is a mistake, please contact customer support.</Paragraph>
                    <Button type="primary" onClick={() => contactSupportModal()}>Contact Customer Support</Button>
                </div>
            </div>
        )
    }

    return (
        <>
            <MetaTags>
                <title>Report | TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow"/>
            </MetaTags>
            <Content className="main">
                <div className="page">
                    <div style={{minHeight: '800px'}}>
                        <PageHeaderWrapper
                            routes={[
                                {
                                    breadcrumbName: 'Reports',
                                },
                            ]}
                        />
                        <div className='auth-container'>
                            <div className='auth-page-ctn'>
                                <div className="reports-ctn">
                                    <div className="reports-title">
                                        <Title level={3} style={{marginBottom:8, color:"#000"}}>Reports</Title>
                                        <Paragraph style={{color:"rgba(0, 0, 0, 0.65)"}}>Here you can view your billing history reports across all contracts for the past 5 years.</Paragraph>
                                    </div>
                                    <div className="reports-action-ctn">
                                        <div className='filter-options'>
                                            <div ref={ref}>
                                                <div 
                                                    onClick={() => setOpenDateRangePicker(!openDateRangePicker)}
                                                    className="range-picker-value-card"
                                                >
                                                    <div className="start-date">
                                                    <p>{dateState[0]?.startDate?.toLocaleDateString(locale, options)}</p>
                                                    </div>
                                                    <div className='end-date'>
                                                        <Space>
                                                            <SwapLeftOutlined style={{color:"rgba(0, 0, 0, 0.25)"}} />
                                                            <p>{dateState[0]?.endDate?.toLocaleDateString(locale, options)}</p>
                                                        </Space>
                                                    </div>
                                                    <CalendarOutlined style={{color:"rgba(0, 0, 0, 0.25)"}}/>
                                                </div>
                                                {
                                                    openDateRangePicker &&
                                                    <div className='date-range-picker-ctn'>
                                                        <div className="date-range-picker">
                                                        { 
                                                                windowWidth > 991 ?
                                                                <DateRangePicker
                                                                    onChange={item => setDateState([item.selection])}
                                                                    showSelectionPreview={true}
                                                                    moveRangeOnFirstSelection={false}
                                                                    months={2}
                                                                    ranges={dateState}
                                                                    direction="horizontal"
                                                                    rangeColors={["#F04A22"]}
                                                                    color='#000'
                                                                /> :
                                                                <DateRange
                                                                    // editableDateInputs={true}
                                                                    onChange={item => setDateState([item.selection])}
                                                                    moveRangeOnFirstSelection={false}
                                                                    ranges={dateState}
                                                                    rangeColors={["#F04A22"]}
                                                                />
                                                            
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <Select 
                                                placeholder="Select Contract" 
                                                style={{ width: windowWidth > 475 ? 432 : "100%"}}
                                                virtual={true}
                                                allowClear
                                                // showSearch
                                                // optionFilterProp="children"
                                                // filterOption={(input, option) =>
                                                //     option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                //   }
                                                // onSearch={handleContractSearch}
                                                onChange={(value) => {
                                                    const contract = contractsList.find(contract => contract.id === value)
                                                    setContractDetails(contract)
                                                }}
                                            >
                                                {
                                                    contractsList.filter((item) => (item?.Status === "Active" || item?.Status === "Terminated")).map((contract, index) => {
                                                        return(
                                                            <Select.Option key={index} value={contract?.id }>
                                                                <Space direction='vertical' size={0}>
                                                                    <Space align='start'>
                                                                        <Avatar style={{position:"relative", top:-2}} size={14} src={contract?.Provider_Org?.Contact_Image || contract?.Customer_Org?.Contact_Image}>
                                                                            {(contract.Provider_Org?.HIPAA_Contact_Name?.charAt(0)?.toUpperCase() || contract.Provider_Org?.Name?.charAt(0)?.toUpperCase()) || contract.Customer_Org?.Name?.charAt(0)?.toUpperCase()}
                                                                        </Avatar>
                                                                        <Paragraph style={{margin:0, fontWeight:500, fontSize:12}}>{capitalizeFirstWordOfEveryLetter(contract.Provider_Org?.HIPAA_Contact_Name || contract.Provider_Org?.Name) || capitalizeFirstWordOfEveryLetter(contract.Customer_Org?.Name)}</Paragraph>
                                                                        <Paragraph type='secondary' style={{margin:0, fontWeight:500, fontSize:12}}>{contract.Type == 'Fixed' ? 'Retainer' : contract.Type == 'Per Ticket' ? 'Per Issue' : contract.Type} Contract (${Number(contract.Billing_Rate || 0) / 100}{contract.Billing_Frequency === "Bi-weekly" ? ' bi-weekly' : contract.Billing_Frequency || '/hr'})</Paragraph>
                                                                    </Space>
                                                                    <Paragraph type='secondary' style={{margin:0, fontWeight:500, fontSize:12}}  ellipsis={{rows: 1}}>{contract?.Description}</Paragraph>
                                                                </Space>
                                                            </Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                            <Button
                                                onClick={() => fetchReports()}
                                                className='go-btn'
                                            >Go</Button>
                                        </div>
                                        {
                                            reportList.length !== 0 &&
                                            <div className="download-csv-cta">
                                                <CSVLink data={reportSheet}  filename={`${csvFileTimeFormat(dateState[0]?.startDate?.toLocaleDateString())}-${csvFileTimeFormat(dateState[0]?.endDate?.toLocaleDateString())}-report.csv`}>
                                                    <Button type="primary" className='download-csv-btn'>Download CSV</Button>
                                                </CSVLink>
                                            </div>
                                        }
                                    </div>
                                    {
                                        (reportList.length === 0 && !loadingReports) ?
                                        emptyReportState() :
                                        <div className="reports-table">
                                        {
                                            windowWidth > 991 ?
                                            <Table 
                                                columns={reportsColumns} 
                                                dataSource={reportList} 
                                                loading={loadingReports} 
                                                onRow={(record) => {
                                                    return {
                                                        onClick: (() => {})
                                                    };
                                                }}
                                                // scroll up on page change
                                                pagination={{
                                                    onChange: page => {
                                                        window.scrollTo({
                                                            top: 0,
                                                            behavior: 'smooth'
                                                        })
                                                    },
                                                    pageSize: 10,
                                                }}
                                            /> :
                                            <List
                                                dataSource={reportList}
                                                loading={loadingReports}
                                                pagination={{
                                                    onChange: page => {
                                                        window.scrollTo({
                                                            top: 0,
                                                            behavior: 'smooth'
                                                        })
                                                    },
                                                    pageSize: 10,
                                                }}
                                                renderItem={
                                                    item => {
                                                        return(
                                                        <List.Item key={item?.invoice_number}>
                                                            <div 
                                                                className="report-list-item"
                                                                onClick={() => {}}
                                                            >
                                                                <div className="report-list-item-content">
                                                                    <Title level={5} style={{marginBottom:4}}>Invoice {capitalizeFirstLetter(item?.invoice_number)}</Title>
                                                                    <Paragraph style={{marginBottom:4}}>{getTableTimeFormat(moment.unix(item?.created))}</Paragraph>
                                                                    <Paragraph style={{marginBottom:4, color:"rgba(0, 0, 0, 0.45)"}}>${item?.amount_paid?.toLocaleString()}</Paragraph>
                                                                    <Badge status={statusColor(item?.status)} text={capitalizeFirstLetter(item?.status)} />
                                                                </div>
                                                                {/* <div className='job-post-list-item-cta'>
                                                                    <Popover content={activeJobPostPopoverContent(item)}  trigger="click" placement='bottom' overlayClassName="job-posting-popover" arrowPointAtCenter>
                                                                        <img src={ActionIcon} 
                                                                        alt="action icon" 
                                                                        onClick={
                                                                            (e) => {
                                                                                e.stopPropagation()
                                                                            }}/>  
                                                                    </Popover>
                                                                </div> */}
                                                            </div>
                                                        </List.Item>
                                                    )}
                                                }
                                            /> 
                                        }
                                    </div>
                                    }
                                    {
                                        (reportList.length !== 0 && !loadingReports) &&
                                        <Title className='total-invoice-amount' level={3}>Total Amount: <span>${reportDetails?.total?.toFixed(2)}</span> </Title>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        </>
  )
}

export default Reports