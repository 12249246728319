import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import CheckCircle from '../assets/images/check-circle.svg';
import '../assets/css/added-item.scss'
import { Space, Tooltip } from 'antd';

export const AddedItemCard = ({children, isSpecialization = false, onClose, onEdit}) => {
    return(
        <div className={`added-item-card ${isSpecialization ? 'align-items-center' : ''}`}>
            {children}
            <Space>
                <Tooltip title="Edit">
                    <EditOutlined className='edit-outlined' onClick={() => onEdit()} />
                </Tooltip>
                <Tooltip title="Delete">
                    <DeleteOutlined className='close-outlined' onClick={() => onClose()} />
                </Tooltip>
            </Space>
        </div>
    )
}

export const AddedItemMessage = ({message}) => {
    return(
        <div className='added-item-message'>
            <img src={CheckCircle} alt="check circle" />
            <p>{message}</p>
        </div>
    )
}