// External Imports
import { useState, useEffect } from 'react'
import { Modal, Typography, Space, Image, Divider, Switch, Select } from 'antd'

// Internal Imports
import { capitalizeFirstLetter, nameFormat } from '../../../../../../../utils/helper'
import ReasonRadioList from './reason-radio-list'
import Bulb from '../../../../public/create-send-contract/assets/images/bulb.svg'

const { Text, Title, Paragraph } = Typography

const customerPoints = [
    "Technician is currently busy on other contracts.",
    "An unexpected budgetary constraint or financial hardship.",
    "Temporary suspension of services.",
]

const PauseContractModal = ({ visible, onOk, onCancel, repository, contractId, contractDetails, getAndSetContractDetails, sendMessage, user }) => {
    const [loading, setLoading] = useState(false);

    const handleOnFinish = (values) => {
        setLoading(true)
        repository.pauseContract(contractId, {
            ...values,
            Technician_Contact: contractDetails?.Provider_Org?.HIPAA_Primary_Contact
        }).then(async (res) => {
            if(res.data?.status === 'success') {
                const name = nameFormat(`${user?.firstname} ${user?.lastname}`);
                const location = window.location.href;
                // await sendMessage(`${name} has paused this contract. ${values.Reason}<br><br> To view the details, simply click on the link below:<br> ${location}`)
                await getAndSetContractDetails()
            }
            setLoading(false)
            onOk()
        }).catch(error => {
            console.error(error)
            setLoading(false)
        })
    }

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            footer={false}
            centered
            className='edit-contract-modal'
        >
            <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
            <Paragraph className='my-2 disclaimer p-2'>
                During the pause period of the contract, no additional actions can be taken, including creating and assigning tickets. Technicians will not be able to clock in or out, resolve, or close tickets, and any automatic or manual billing will be halted. To make the contract active again, you will have to resume the contract.
            </Paragraph>
            <Space direction='vertical' className='w-100'>
                <Text strong>Select a reason for pausing the contract:</Text>
                <ReasonRadioList
                    loading={loading}
                    points={customerPoints}
                    name="Pause Contract"
                    handleOnFinish={handleOnFinish}
                    onCancel={onCancel}
                />
            </Space>
        </Modal>
    )
}

export default PauseContractModal