import {useState, useEffect} from 'react';
import {
    ArrowRightOutlined,
    CalendarOutlined,
    ExclamationCircleOutlined,
    InfoCircleOutlined,
    LoadingOutlined,
    PlusOutlined,
    UserOutlined
} from '@ant-design/icons'
import {
    Avatar,
    Button,
    Card,
    Col,
    List,
    Rate,
    Row,
    Space,
    Tabs,
    Typography,
    Upload,
    Modal,
    Image,
    notification,
    Input,
    Form,
    Skeleton,
    Tooltip, Empty
} from 'antd'
import {isMobile, isTablet} from 'react-device-detect';
import {Editor} from "react-draft-wysiwyg";
import '../../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {useSelector} from 'react-redux';
import CustomRenderEmpty from '../../../../../components/custom-render-empty';
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import moment from 'moment'
import {DATE_TIME_FORMAT, SECONDARY_COLOR} from '../../../../../../utils/constants';
import {LocationRepository} from '../../../../customer/manage/locations/repository/location-repository';
import {ApiBaseHelper} from '../../../../../../utils/api-base-helper';
import CancelScheduleModal from './cancel-schedule-modal';
import {convertIfLight, getTableTimeFormat, stripHtml} from '../../../../../../utils/helper';
import {capitalizeFirstLetter, capitalizeFirstWordOfEveryLetter} from '../../../../../../utils/helper';
import DownloadFile from "../assets/icons/download-file.png";
import PdfFileIcon from "../../real-time-messaging/assets/images/pdf-icon.png";
import ExcelFileIcon from "../../real-time-messaging/assets/images/excel-icon.png";
import WordFileIcon from "../../real-time-messaging/assets/images/word-icon.png";
import PPTFileIcon from "../../real-time-messaging/assets/images/point-icon.png";
import {Divider} from "antd/es";
import { useHistory } from 'react-router-dom';
import ROUTES from '../../../../../../utils/routes';
import { decode } from 'html-entities';

const {Title, Text, Link, Paragraph} = Typography
const {useForm} = Form

const DetailsPanel = ({
                          ticketDetails,
                          contractDetails,
                          selectedTab,
                          handleTechSelectionClick,
                          deviceInfo,
                          ticketRepository,
                          setIsOnSiteVisitModalOpen,
                          handleRescheduleClick,
                          getAndSetActivities,
                          getAndSetTicketDetails,
                          isContractPaused,
                          onClickAddDevice,
                      }) => {
    const tech = useSelector(state => state.tech)
    const user = useSelector(state => state.user)
    const history = useHistory()
    const [media, setMedia] = useState([])
    const [previewImage, setPreviewImage] = useState('');
    const [isImageModalVisible, setIsImageModalVisible] = useState(false);
    const [isFileUploading, setIsFileUploading] = useState(false);
    let fileUploading = false;
    const [editorState, setEditorState] = useState('');
    const [isNotesApiCallInprogress, setIsNotesApiCallInprogress] = useState(false)
    const [location, setLocation] = useState(undefined);
    const [loadingParts, setLoadingParts] = useState(false)
    const [partData, setPartData] = useState([])
    const [showPartModal, setShowPartModal] = useState(false)
    const [showDeletePartModal, setShowDeletePartModal] = useState(false)
    const [deletePartId, setDeletePartId] = useState()
    const [updatingPart, setUpdatingPart] = useState(false)
    const [selectedPart, setSelectedPart] = useState({})
    const [editPart, setEditPart] = useState(false)
    const [isCancelScheduleModalVisible, setIsCancelScheduleModalVisible] = useState(false)
    const [itcDetails, setItcDetails] = useState({})
    const [loadingItc, setLoadingItc] = useState(false)



    const [form] = useForm();
    const getAndSetLocation = async () => {
        const locationRepository = new LocationRepository(user?.token || tech?.token)
        try {
            const res = await locationRepository.getLocation(ticketDetails?.Facility);
            if (res.data?.data) {
                setLocation(res.data.data)
            }
        } catch (error) {
            notification.error({
                message: 'TecMe',
                description: 'Fetch locations failed'
            })
        }
    }

    const fetchParts = () => {
        const token = tech ? tech?.token : user?.token
        setLoadingParts(true)
        ApiBaseHelper.get({
            url: `ticket/${ticketDetails?.id}/parts`, headers: {
                Authorization: 'Bearer ' + token
            }
        }).then((res) => {
            setPartData(res.data.data)
            setLoadingParts(false)
        }).catch((error) => {
            console.error(error)
            setLoadingParts(false)
        })
    }

    useEffect(() => {
        fetchParts()
        if (ticketDetails?.id) {
            ticketRepository.getTicketMedia(ticketDetails?.id).then(res => {
                if (res.data?.data?.data?.length) {
                    setMedia(res.data.data.data.reverse())
                }
            }).catch(error => console.error(error))
        }

        const contentBlock = htmlToDraft(ticketDetails?.Provider_Notes || "");
        const contentState = ContentState.createFromBlockArray(contentBlock?.contentBlocks);
        setEditorState(EditorState.createWithContent(contentState))
    }, [])

    useEffect(() => {
        if(contractDetails?.ITC){
            setLoadingItc(true)
            ApiBaseHelper.get({
                url: `itc/${contractDetails?.ITC}`
            }).then((res) => {
                setItcDetails(res?.data?.data)
                setLoadingItc(false)
            }).catch((error) => {
                console.error(error)
                setLoadingItc(false)
            })
        }
    }, [contractDetails])

    useEffect(() => {
        if (ticketDetails?.Status === 'Pending' && !!ticketDetails?.Scheduled_Date_Time && !location) {
            getAndSetLocation();
        }
    }, [ticketDetails?.Status, ticketDetails?.Scheduled_Date_Time])


    const handleCancelScheduleClick = () => {
        setIsCancelScheduleModalVisible(true)
    }
console.log(itcDetails)
    const getProfileDetails = () => {
        const contact = tech ? ticketDetails?.Reporter_Contact : ticketDetails?.Provider_Contact

        return (
            <>
                <div>
                    <div>
                        {!!tech && <Paragraph strong className='ps-3 fs-6' style={{marginBottom: 5}}>{contractDetails?.ITC ? "Client"  : "Customer"}</Paragraph>}
                        {!!user && <Paragraph strong className='ps-3 fs-6' style={{marginBottom: 5}}>Assigned Technician</Paragraph>}
                        <div className='top-profile-details ps-3'>
                            <div className='details-info'>
                                <Avatar
                                    shape='circle'
                                    size={41}
                                    src={(tech && contractDetails?.ITC) ? contractDetails?.Customer_Org?.Logo : contact?.imgUrl}
                                    alt={(tech && contractDetails?.ITC) ? contractDetails?.Customer_Org?.Name : contact?.Full_Name}
                                    icon={(tech && contractDetails?.ITC) ? contractDetails?.Customer_Org?.Name : !contact?.Full_Name?.[0] ? <UserOutlined/> : null}
                                >
                                    {(tech && contractDetails?.ITC) ? contractDetails?.Customer_Org?.Name : contact?.Full_Name?.[0].toUpperCase()}
                                </Avatar>
                                <div className="profile-details">
                                    <Paragraph style={{fontWeight: 500, marginBottom: 0}}>
                                        {(tech && contractDetails?.ITC) ? contractDetails?.Customer_Org?.Name : capitalizeFirstWordOfEveryLetter(contact?.Full_Name) || 'Unassigned'}
                                    </Paragraph>
                                    {!!user && !!ticketDetails?.Provider_Org?.Average_Rating && (
                                        <Space align='baseline' size={5}>
                                            <>
                                                <Rate
                                                    disabled
                                                    style={{color: " #FEB000", fontSize: 12}}
                                                    allowHalf
                                                    value={ticketDetails?.Provider_Org?.Average_Rating}
                                                />
                                                <Text
                                                    style={{fontSize: 12}}>{ticketDetails?.Provider_Org?.Average_Rating?.toFixed(1)}</Text>
                                            </>
                                        </Space>
                                    )}
                                </div>
                            </div>
                            {(!!user && user?.role !== "recruiter") && (ticketDetails?.Status === 'Pending' || ticketDetails?.Status === 'Assigned' || ticketDetails?.Status === 'New') && !isContractPaused && (
                                <Tooltip
                                    title={!ticketDetails?.Provider_Contact?.id ? "Use this button to assign a technician to this ticket." : "Use this button to change the technician assigned to this ticket."}>
                                    <div className='reassign-cta'
                                        onClick={() => handleTechSelectionClick(!ticketDetails?.Provider_Contact?.id ? false : true)}>
                                        {!ticketDetails?.Provider_Contact?.id ? 'Assign' : 'Reassign'}
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                    {
                        (tech && contractDetails?.ITC) &&
                        <Space direction='vertical mb-3'>
                            <Paragraph strong className='ps-3 fs-6' style={{marginBottom: 0}}>Ticket Assigned To:</Paragraph>
                            <Space className='ps-3'>
                                <Avatar
                                    shape='circle'
                                    size={41}
                                    src={ticketDetails?.custom_data?.Provider_Contact?.imgUrl}
                                    alt={ticketDetails?.custom_data?.Provider_Contact?.Full_Name}
                                    icon={!ticketDetails?.custom_data?.Provider_Contact?.Full_Name?.[0] ? <UserOutlined/> : null}
                                >
                                    {ticketDetails?.custom_data?.Provider_Contact?.Full_Name?.[0].toUpperCase()}
                                </Avatar>
                                <div className="profile-details">
                                    <Paragraph style={{fontWeight: 500, marginBottom: 0}}>
                                        {capitalizeFirstWordOfEveryLetter(ticketDetails?.custom_data?.Provider_Contact?.Full_Name)}
                                    </Paragraph>
                                </div>
                            </Space>
                        </Space>
                    }
                </div>
                {
                    loadingItc ? 
                    <>
                         <Skeleton
                            avatar
                            active
                            paragraph={{
                                rows: 2,
                            }}
                            style={{marginLeft:10}}
                        />
                    </> :
                    (contractDetails?.ITC) &&
                    <Space direction='vertical' className='ps-3'>
                        <Text strong className='fs-6'>
                            Contract Via:
                        </Text> 
                        <Space size={10}>
                            <Avatar 
                                src={itcDetails?.Brand} 
                                size={'small'} 
                                className='contract-avatar'
                                style={{
                                    backgroundColor: convertIfLight(`#${itcDetails?.Brand_Color}`)
                                }}
                            >
                                {`${capitalizeFirstLetter(itcDetails?.Company_Name?.[0])}`}
                            </Avatar>
                            <Title level={5} className='m-0'>
                                {capitalizeFirstWordOfEveryLetter(itcDetails?.Company_Name)}
                            </Title>
                        </Space>
                        <Text strong>Company Bio:</Text>
                        <Paragraph className='m-0 fw-normal' ellipsis={{ rows: 7 }} >
                            {(itcDetails?.Description && itcDetails.Description.toLowerCase() !== 'undefined') ? capitalizeFirstLetter(decode(stripHtml(itcDetails.Description))) : 'Company bio unavailable'}
                        </Paragraph>
                        <Text 
                            strong 
                            className='tecme-secondary-color d-flex align-items-center cursor-pointer mb-4' 
                            onClick={(e) => {
                                history?.push(ROUTES.ITC_HOME.replace(':subdomain', itcDetails?.Subdomain))
                                e.stopPropagation()
                            }}
                        >
                            View ITSC Webpage <ArrowRightOutlined
                                className='ms-2' 
                                style={{
                                    position: 'relative',
                                    top: '2px'
                                }}
                            />
                        </Text>
                    </Space>
                }
                <div className='summary-section-ctn'>
                    <Space>
                        <CalendarOutlined style={{marginBottom: 7}}/>
                        <Paragraph style={{margin: 0}}>
                            {getTableTimeFormat(ticketDetails?.CreatedDate)}
                        </Paragraph>
                    </Space>
                    <div className='summary-det'>
                        <Title level={5}>Summary</Title>
                        <Paragraph className='fw-normal'>{capitalizeFirstLetter(ticketDetails?.Summary)}</Paragraph>
                    </div>
                    <div className='summary-det'>
                        <Title level={5}>Description</Title>
                        <Paragraph className='fw-normal'>{capitalizeFirstLetter(ticketDetails?.Description)}</Paragraph>
                    </div>
                    {!!ticketDetails?.custom_data?.resolution_details && (
                        <div className='summary-det'>
                            <Title level={5}>Resolution Details</Title>
                            <Paragraph>{capitalizeFirstLetter(ticketDetails.custom_data.resolution_details)}</Paragraph>
                        </div>
                    )}
                </div>

                <div className='attachment-section'>
                    <Title level={5}>Attachments</Title>
                    {
                        (ticketDetails?.Status !== 'Closed' && ticketDetails?.Status !== "Pending") &&
                        <>
                            {
                                user?.role !== "recruiter" &&
                                (
                                    <div className='attachment-list'>
                                    <Row gutter={[15,15]} align={"middle"} justify={"center"}>
                                        <Col>
                                            <Tooltip title="Use this button to upload images and documents (PDF, Word, Excel) to this ticket.">
                                                <Upload
                                                    className='avatar-uploader'
                                                    multiple
                                                    accept={
                                                        [
                                                            "image/png",
                                                            "image/jpg",
                                                            "image/jpeg",
                                                            "image/gif",
                                                            "image/webp",
                                                            "image/bmp",
                                                            "application/pdf",
                                                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                                            "application/vnd.oasis.opendocument.spreadsheet",
                                                            "application/vnd.ms-excel",
                                                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                                            "text/csv",
                                                        ].join(",")
                                                    }
                                                    fileList={[]}
                                                    listType="picture-card"
                                                    onChange={async (e) => {
                                                        if (e?.fileList?.length && fileUploading == false) {
                                                            let invalidFiles = false
                                                            e.fileList.forEach((file) => {
                                                                if (![
                                                                    "image/png",
                                                                    "image/jpg",
                                                                    "image/jpeg",
                                                                    "image/gif",
                                                                    "image/webp",
                                                                    "image/bmp",
                                                                    "application/pdf",
                                                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                                                    "application/vnd.oasis.opendocument.spreadsheet",
                                                                    "application/vnd.ms-excel",
                                                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                                                    "text/csv",
                                                                ].includes(file.type))
                                                                    invalidFiles = true;
                                                            })
                                                            if (invalidFiles) {
                                                                notification.error({
                                                                    message: 'TecMe',
                                                                    description: `We are unable to process the file type you are attempting to upload - please only upload image and document files.`
                                                                })
                                                                return false;
                                                            }
                                                            setIsFileUploading(true)
                                                            fileUploading = true;
                                                            const payload = new FormData();
                                                            e?.fileList?.forEach((file) => payload.append('file', file.originFileObj));
                                                            console.log(e.fileList);
                                                            try {
                                                                await ticketRepository?.uploadTicketMedia(ticketDetails?.id, payload);
                                                            } catch (error) {
                                                                notification.error({
                                                                    message: 'TecMe',
                                                                    description: 'upload attachment failed'
                                                                })
                                                            }
                                                            try {
                                                                await getAndSetActivities()
                                                            } catch (error) {
                                                            }
                                                            try {
                                                                const res = await ticketRepository.getTicketMedia(ticketDetails?.id);
                                                                if (res?.data?.data?.data?.length) {
                                                                    setMedia(res.data.data.data)
                                                                }
                                                            } catch (error) {
                                                                notification.error({
                                                                    message: 'TecMe',
                                                                    description: 'get attachments failed'
                                                                })
                                                            }
                                                            setIsFileUploading(false)
                                                            fileUploading = false;
                                                        }
                                                    }}
                                                    beforeUpload={() => false}
                                                    disabled={user?.role?.toLowerCase() === 'recruiter'}
                                                >
                                                    {isFileUploading ? <LoadingOutlined className="add-attachment-plus-icon"/> : (
                                                        <PlusOutlined className="add-attachment-plus-icon"/>
                                                    )}
                                                </Upload>
                                            </Tooltip>
                                        </Col>
                                        <Col>
                                            <Typography.Text>Upload Attachment</Typography.Text>
                                        </Col>
                                    </Row>
                                </div>
                                )
                            }
                            <Divider/>
                        </>
                    }
                    {
                        !!media?.length ?
                        <div>
                            <Row>
                                <Col>
                                    <List
                                        dataSource={media}
                                        grid={{
                                            column:1
                                        }}
                                        renderItem={(file) => {
                                            let fileIcon = DownloadFile;
                                            let fileExt = file.Title.split(".").pop();

                                            if(["pdf"].includes(fileExt))
                                                fileIcon = PdfFileIcon;
                                            else if(["csv","xls","xlsx"].includes(fileExt))
                                                fileIcon = ExcelFileIcon;
                                            else if(["doc","word","docx"].includes(fileExt))
                                                fileIcon = WordFileIcon;
                                            else if(["ppt","pptx"].includes(fileExt))
                                                fileIcon = PPTFileIcon;
                                            else if (["jpg","jpeg","png","gif","svg","bmp"].includes(fileExt))
                                                fileIcon = file.Body

                                            return <Row gutter={[15,15]} align={"middle"} className={"mb-2"}>
                                                <Col>
                                                    <Image src={fileIcon} preview={["jpg","jpeg","png","gif","svg","bmp"].includes(fileExt)}/>
                                                </Col>
                                                <Col>
                                                    <a style={{color:SECONDARY_COLOR}} href={file.Body}>{file.Title}</a>
                                                </Col>
                                            </Row>
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>:
                        <div className='d-flex justify-content-center' >
                            <Paragraph style={{color:"#D9D9D9"}}>No attachments added</Paragraph> 
                        </div>
                    }
                </div>
                {!!Object.keys(deviceInfo).length && (
                    <div className='device-info-section'>
                        <Title level={5}>Device Info</Title>
                        <Row gutter={[10, 10]}>
                            <Col>
                                {!!deviceInfo.Pic_1 && (
                                    <div className='device-img'>
                                        <Image src={deviceInfo.Pic_1} alt={deviceInfo.HardwareName}/>
                                    </div>
                                )}
                            </Col>
                            <Col>
                                <Row>
                                    <Col className='profile-device-info' span={12}>
                                        <p>Type</p>
                                        <small>{deviceInfo.Other_Type}</small>
                                    </Col>
                                    <Col className='profile-device-info' span={12}>
                                        <p>Model</p>
                                        <small>{deviceInfo.Model}</small>
                                    </Col>
                                    <Col className='profile-device-info' span={12}>
                                        <p>Brand</p>
                                        <small>{deviceInfo.Make}</small>
                                    </Col>
                                    {
                                        deviceInfo.Operating_System_Name && deviceInfo.Operating_System_Name.includes("a0") == false &&
                                        <Col className='profile-device-info' span={12}>
                                            <p>OS</p>
                                            <small>{deviceInfo.Operating_System_Name}</small>
                                        </Col>
                                    }

                                </Row>
                            </Col>
                        </Row>
                    </div>
                )}
                {ticketDetails.Hardware == null && (
                    <div className='device-info-section'>
                        <Title level={5}>Device Info</Title>
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={"No device is added"}
                        >
                            {
                                ["New", "Assigned", "Open"].includes(ticketDetails.Status) && user &&
                                <Button id="outline-default-btn" disabled={user?.role?.toLowerCase() === 'recruiter'} onClick={() => {
                                    onClickAddDevice()
                                }}>+ Add Device</Button>
                            }

                        </Empty>

                    </div>
                )}
            </>
        )
    }

    const getOnsiteVisitDetails = () => {
        return (
            <div>
                <div className='visit-schedule'>
                    <div className='visit-schedule-title flex-wrap'>
                        <Title level={5} style={{margin: 0}}>Visit Scheduled</Title>
                        <Space>
                            <Tooltip title="Use this button to cancel your scheduled on-site visit.">
                                <Button
                                    className='white-btn d-flex align-items-center justify-content-center'
                                    onClick={handleCancelScheduleClick}
                                >
                                    Cancel
                                </Button>
                            </Tooltip>
                            {!!user && (
                                <Tooltip title="Use this button to reschedule your on-site visit.">
                                    <Button
                                        className='white-btn d-flex align-items-center justify-content-center'
                                        onClick={handleRescheduleClick}
                                    >
                                        Reschedule
                                    </Button>
                                </Tooltip>
                            )}
                        </Space>
                    </div>
                    <Space className='w-100 justify-content-between'>
                        <Space align='center'>
                            <CalendarOutlined/>
                            <Paragraph style={{margin: 0}}>
                                {getTableTimeFormat(ticketDetails?.Scheduled_Date_Time)}
                            </Paragraph>
                        </Space>
                    </Space>
                </div>
                <div className='location-visit-info'>
                    {!location && (
                        <div style={{height: '40vh'}} className="d-flex align-items-center">
                            <Skeleton paragraph={{rows: 5}}/>
                        </div>

                    )}
                    {!!location && (
                        <div className='visit-schedule-title'>
                            <Title level={5} style={{margin: 0}}>Location Info</Title>
                            {!!user && (
                                <Tooltip title="Use this button to change the location of your on-site visit.">
                                    <Button className='white-btn' onClick={setIsOnSiteVisitModalOpen}>Change</Button>
                                </Tooltip>
                            )}
                        </div>
                    )}
                    {!!location && (
                        <div style={{marginTop: 10}}>
                            <iframe
                                width={'100%'}
                                height={150}
                                style={{border: 0}}
                                allowFullScreen
                                referrerPolicy="no-referrer-when-downgrade"
                                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAA104TGF5d0eM8WoEylk-SyFjtzO4v5nM&q=${location.Latitude},${location.Longitude}`}>
                            </iframe>
                        </div>
                    )}
                    {!!location && (
                        <>
                            <Title level={5} style={{marginTop: 10}}>Address</Title>
                            <Text>{capitalizeFirstLetter(location.Address_Full)}
                                <br/> {capitalizeFirstLetter(location.City)}, {location.State} {location.Zip_Code}
                            </Text>
                        </>
                    )}
                    {!!ticketDetails?.custom_data?.entry_instructions && ticketDetails?.custom_data?.entry_instructions !== ' ' && (
                        <>
                            <Title level={5} style={{marginTop: 5}}>Entry Instructions</Title>
                            <Text>{ticketDetails.custom_data.entry_instructions}</Text>
                        </>
                    )}
                </div>
            </div>
        )
    }

    const getPartsDetails = () => {
        function getSelectedPart(id) {
            const selectedPart = partData.find((item) => item.id === id)
            setSelectedPart((prevPart) => ({
                ...prevPart,
                ...selectedPart
            }))
            form.setFieldsValue({
                Part_Name: selectedPart?.Part_Name,
                Part_Url: selectedPart?.Part_Url,
                Description: selectedPart?.Description
            })
            setEditPart(true)
            setShowPartModal(true)
        }

        const handleAddPartSubmit = (values) => {
            const payload = {
                ...values,
                Ticket: ticketDetails?.id,
                Customer_Contact: ticketDetails?.Reporter_Contact?.id
            }
            setUpdatingPart(true)
            ApiBaseHelper.post({
                url: 'ticket/part',
                headers: {
                    Authorization: `Bearer ${tech?.token}`
                },
                payload
            }).then((res) => {
                if (res.data) {
                    notification['success']({
                        message: 'TecMe',
                        description: "Device part added successfully",
                    });
                    setUpdatingPart(false)
                    form.resetFields()
                    setShowPartModal(false)
                    fetchParts()
                }
            }).catch((error) => {
                console.error(error)
                setUpdatingPart(false)
                notification['error']({
                    message: 'TecMe',
                    description: "An error Occurred",
                });
            })
        }

        const handleEditPartSubmit = (values, part_id) => {
            const payload = {
                ...values,
                Ticket: ticketDetails?.id,
            }
            setUpdatingPart(true)
            ApiBaseHelper.put({
                url: `ticket/part/${part_id}`,
                headers: {
                    Authorization: `Bearer ${tech?.token}`
                },
                payload
            }).then((res) => {
                if (res.data) {
                    notification['success']({
                        message: 'TecMe',
                        description: "Device part updated successfully",
                    });
                    setUpdatingPart(false)
                    form.resetFields()
                    setShowPartModal(false)
                    fetchParts()
                }
            }).catch((error) => {
                console.error(error)
                setUpdatingPart(false)
                notification['error']({
                    message: 'TecMe',
                    description: "An error Occurred",
                });
            })
        }

        const handlePartDelete = () => {
            setShowDeletePartModal(true)
            setUpdatingPart(true)
            ApiBaseHelper.delete({
                url: `ticket/part/${deletePartId}`,
                headers: {
                    Authorization: `Bearer ${tech?.token}`
                },
            }).then((res) => {
                if (res.data) {
                    notification['success']({
                        message: 'TecMe',
                        description: res.data.message,
                    });
                    setUpdatingPart(false)
                    fetchParts()
                    setShowDeletePartModal(false)
                }
            }).catch((error) => {
                setUpdatingPart(false)
                notification['error']({
                    message: 'TecMe',
                    description: "An error Occurred",
                });
            })
        };

        return (
            <div className='parts-details-ctn'>
                {
                    (partData.length !== 0) &&
                    <div className='parts-details-title'>
                        <Title level={5} style={{margin: 0}}>Device Parts</Title>
                        {
                            (tech && tech.id === ticketDetails?.Reporter_Contact?.id) ? null :
                             tech && (ticketDetails?.Status !== 'Closed' && ticketDetails?.Status !== "Pending") && (
                                <Button
                                    className='white-btn d-flex justify-content-center align-items-center'
                                    onClick={() => {
                                        setShowPartModal(true)
                                        setEditPart(false)
                                        form.setFieldsValue({Part_Name: "", Part_Url: "", Description: ""})
                                    }}
                                    icon={<PlusOutlined/>}
                                >
                                    Add New Device Part
                                </Button>
                            )}
                    </div>
                }
                <CustomRenderEmpty
                    description="No device parts found in this ticket"
                    extra={
                        (tech && tech.id === ticketDetails?.Reporter_Contact?.id) ? null :
                        tech && (ticketDetails?.Status !== 'Closed' && ticketDetails?.Status !== "Pending") &&
                        <Button onClick={() => setShowPartModal(true)} icon={<PlusOutlined/>}>
                            Add New Device Part
                        </Button>
                    }>
                    <List
                        dataSource={partData}
                        loading={loadingParts}
                        renderItem={item => (
                            <List.Item>
                                <Card
                                    className='parts-card'>
                                    <Title level={5}>{capitalizeFirstLetter(item?.Part_Name)}</Title>
                                    <Paragraph style={{
                                        marginBottom: 2,
                                        fontWeight: 400
                                    }}>{capitalizeFirstLetter(item?.Description)}</Paragraph>
                                    <Link style={{color: '#F04A22', fontWeight: 400}} href={item?.Part_Url}
                                          target="_blank">
                                        {item?.Part_Url}
                                    </Link>
                                    {tech && ticketDetails?.Status !== 'Closed' && !isContractPaused &&
                                        <div className='parts-card-footer'>
                                            <Paragraph
                                                style={{margin: 0, visibility: "hidden"}}>{item?.price}</Paragraph>
                                            <Space>
                                                <Button className='card-naked-btn edit'
                                                        onClick={() => getSelectedPart(item?.id)}>Edit</Button>
                                                <Button className='card-naked-btn delete' onClick={() => {
                                                    setDeletePartId(item?.id)
                                                    setShowDeletePartModal(true)
                                                }}>Delete</Button>
                                            </Space>
                                        </div>}
                                </Card>
                            </List.Item>
                        )}
                    />
                </CustomRenderEmpty>
                <Modal title={editPart ? "Edit Device Part" : "Add Device Part"}
                       visible={showPartModal}
                       onCancel={() => {
                           setShowPartModal(false)
                           setEditPart(false)
                       }}
                       footer={null}
                >
                    <Title style={{marginBottom: 0, fontWeight: 500}} level={5}>What are device parts?</Title>
                    <Paragraph>Device parts can be anything needed or recommended to perform the IT service. Device
                        parts can be purchased by either the technician or the customer.</Paragraph>
                    <Form
                        onFinish={(values) => !editPart ? handleAddPartSubmit(values) : handleEditPartSubmit(values, selectedPart?.id)}
                        layout={'vertical'}
                        form={form}

                    >
                        <Form.Item
                            label="Device Part Name"
                            name="Part_Name"
                            initialValue={selectedPart?.Part_Name}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input device part name',
                                },
                            ]}
                        >
                            <Input placeholder='Enter device part name'/>
                        </Form.Item>
                        <Form.Item
                            label="Device Part URL"
                            name="Part_Url"
                            initialValue={selectedPart?.Part_Url}
                            rules={[
                                {
                                    type: "url",
                                    message: "This field must be a valid url."
                                }
                            ]}
                        >
                            <Input placeholder='Enter device part url'/>
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="Description"
                            className='pb-2 mb-4'
                            initialValue={selectedPart?.Description}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input description',
                                },
                            ]}
                        >
                            <Input.TextArea rows={4} placeholder="Enter device part description" showCount
                                            maxLength={250}/>
                        </Form.Item>
                        <Form.Item className='border-top pt-3'>
                            <Space size={12} className="d-flex justify-content-end">
                                <Button className="white-btn" onClick={() => {
                                    setShowPartModal(false)
                                    setEditPart(false)
                                }}>Cancel</Button>
                                {
                                    editPart ? <Button type="primary" htmlType="submit"
                                                       loading={updatingPart}>Update</Button> :
                                        <Button type="primary" htmlType="submit" loading={updatingPart}>Add</Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    visible={showDeletePartModal}
                    onCancel={() => {
                        setShowDeletePartModal(false)
                    }}
                    footer={null}
                >
                    <Title level={3} className='font-weight-500' style={{marginTop: '-0.8rem'}}>Are you sure?</Title>
                    <Paragraph className='my-2 disclaimer p-2'>
                        This action will permanently delete the device part and cannot be undone. Please make sure you
                        want to delete the item before proceeding.
                    </Paragraph>
                    <Space align='center' className='w-100 justify-content-end'>
                        <Button type='text' onClick={() => setShowDeletePartModal(false)}>Cancel</Button>
                        <Button type='primary' htmlType="submit" loading={updatingPart}
                                onClick={handlePartDelete}>Delete</Button>
                    </Space>
                </Modal>
            </div>
        )
    }

    const getNoteDetails = () => {
        return (
            <div className='note-editor-ctn-fluid'>
                <Paragraph style={{marginBottom: 10, fontWeight: 400}}>
                    <Tooltip title="A spot to save any important information related to the service">
                        <InfoCircleOutlined style={{position: 'relative', top: -1}} className="me-2"/>
                    </Tooltip>
                    This section is not visible to the customer
                </Paragraph>

                <Editor
                    readOnly={ticketDetails?.Status === 'Closed' || isContractPaused}
                    editorState={editorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbarHidden={ticketDetails?.Status === 'Closed' || isContractPaused}
                    onEditorStateChange={(newEditorState) => {
                        setEditorState(newEditorState)
                    }}
                    wrapperStyle={{minHeight: "calc(100vh - 380px)", border: '1px solid #eaeaea'}}
                    toolbar={{
                        options: ['blockType', 'inline', 'list', 'link', 'emoji'],

                        list: {
                            options: ['unordered', 'ordered'],
                            blockType: {
                                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                            },
                        },
                    }}
                />
                {ticketDetails?.Status !== 'Closed' && !isContractPaused && (
                    <Button
                        className='white-btn mt-2'
                        loading={isNotesApiCallInprogress}
                        disabled={typeof editorState?.getCurrentContent === 'function' ? draftToHtml(convertToRaw(editorState?.getCurrentContent?.())) === '<p></p>\n' : false}
                        onClick={async () => {
                            setIsNotesApiCallInprogress(true)
                            try {
                                const payload = {
                                    Provider_Notes: draftToHtml(convertToRaw(editorState.getCurrentContent()))
                                }
                                await ticketRepository?.savePrivateNotes(ticketDetails?.id, payload);
                            } catch (error) {
                                notification.error({
                                    message: 'TecMe',
                                    description: 'Save notes failed'
                                })
                            }
                            setIsNotesApiCallInprogress(false)
                        }}
                    >
                        Save
                    </Button>
                )}
            </div>
        )
    }

    const tabList = [
        {
            title: "Details",
            content: getProfileDetails()
        },
        {
            title: "Device Parts",
            content: getPartsDetails()
        }
    ]

    if (ticketDetails?.Status === 'Pending' && !!ticketDetails?.Scheduled_Date_Time) {
        tabList.push({
            title: 'On-Site Visit',
            content: getOnsiteVisitDetails()
        })
    }

    if (tech && (tech.id !== ticketDetails?.Reporter_Contact?.id)) {
        tabList.push({
            title: 'Private Notes',
            content: getNoteDetails()
        })
    }

    return (
        <>
            <div className='details-panel-ctn'>
                <div className="details-header-section">
                    {(isMobile || isTablet) ? tabList.find(({title}) => title === selectedTab.title)?.content : (
                        <Tabs className='support-ticket-tabs'>
                            {tabList.map((item) => {
                                return (
                                    <Tabs.TabPane tab={item.title} key={item.title}>
                                        <div className='details-content scroll'>
                                            {item.content}
                                        </div>
                                    </Tabs.TabPane>
                                )
                            })}
                        </Tabs>
                    )}
                </div>
            </div>
            <Modal
                visible={isImageModalVisible}
                title={'TecMe'}
                footer={null}
                onCancel={() => setIsImageModalVisible(false)}
            >
                <img loading="lazy" alt="image" className='w-100' src={previewImage}/>
            </Modal>
            <CancelScheduleModal
                visible={isCancelScheduleModalVisible}
                onCancel={() => setIsCancelScheduleModalVisible(false)}
                onOk={() => setIsCancelScheduleModalVisible(false)}
                ticketDetails={ticketDetails}
                ticketRepository={ticketRepository}
                getAndSetActivities={getAndSetActivities}
                getAndSetTicketDetails={getAndSetTicketDetails}
                tech={tech}
            />
        </>
    )
}

export default DetailsPanel