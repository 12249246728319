import React, {Component, Fragment} from 'react'
import {Row, Col, Layout, Popover, Input, Button, Card, Skeleton, Modal, Spin, notification, Typography, Space, Table, List, Form, Alert} from 'antd';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add'; 
import {connect} from "react-redux"; 
import {ApiBaseHelper} from "../../../../../utils/api-base-helper";
import NoPaymentFound from './assets/images/payment ui-whitebg.png';
import {DeleteOutlined, LoadingOutlined, PlusOutlined, ExclamationCircleOutlined, EyeOutlined, EditOutlined} from '@ant-design/icons';
import Cards from 'react-credit-cards';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {Link} from "react-router-dom";
import ActionIcon from  './assets/icons/action-icon.svg';
import ROUTES from '../../../../../utils/routes';
import {PaymentRepository} from './payment-repository/payment-repository'
import MaskedInput from 'antd-mask-input'
import CircleIcon from '@mui/icons-material/Circle';
import InputMask from 'react-input-mask';
import {META_DESCRIPTION} from "../../../../../utils/constants";
import MetaTags from "react-meta-tags";
import PageHeaderWrapper from "../../../../components/page-header-breadcrumbs";
import { fontWeight } from '@mui/system';
import CustomRenderEmpty from '../../../../components/custom-render-empty';
import PageTitle from '../../../../components/page-title';
import SearchInput from '../../../../components/search-input';
import AddEditPaymentModal from './add-payment/modal'
import { canReadPageOnly, capitalizeFirstLetter } from '../../../../../utils/helper';
import "./assets/css/styles.css"

const antIcon = <LoadingOutlined style={{fontSize: 22, color: "black"}} spin/>;
const {confirm} = Modal;
const {Content} = Layout;
const {Paragraph, Title, Text} = Typography
const listData = [];

for (let i = 0; i < 12; i++) {
    listData.push({
        href: 'https://ant.design',
        title: `ant design part ${i}`,
        description:
            'Ant Design, a design language for background applications, is refined by Ant UED Team.',
    });
}


class Payment extends Component {
    form = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            showData: [],
            deleteLoading: false,
            formStatus: false,
            dataLoading: false,
            sortedInfo: null,
            deleteModal: false,
            addModalVisible:false,
            selectedPayment: "",
            visible: false,
            currentId: null,
            cardNumber: null,
            expiry: null,
            expiryError: false,
            errorMessage: "",
            isFormChange: false,
            paymentDetailsModalVisible:false,
            selectedRecord:{},
            windowWidth: window.innerWidth,
        };
        const paymentPopoverContent = (item) => {
            return(
                <div className="payment-popover-content">   
                    <Space
                    onClick = {() => 
                        this.setState({selectedRecord:item, paymentDetailsModalVisible:true
                    })}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <EyeOutlined />
                            <Paragraph style={{marginBottom:0, whiteSpace:"nowrap", marginLeft: 8}}>View Payment</Paragraph> 
                        </div>
                    </Space>
                    <Space className='popover-action-item' align='center'>
                        <Link 
                            onClick={(e) => {
                                e.stopPropagation()
                                let val = item.card.exp_month < 10 ? "0" + item.card.exp_month : item.card.exp_month;
                                this.setState({
                                    selectedRecord:item,
                                    currentId: item.id,
                                    cardNumber: item.card.last4,
                                    expiry: val + "/" + Array.from(item.card.exp_year.toString(), val => Number(val)).slice(2, 4).join("")
                                });
                            }}
                                to={{pathname: ROUTES.PAYMENT, search: '?option=edit', state: { item }}}
                                style={{textDecoration:"none", display:"flex", whiteSpace:"nowrap"}}
                                >
                                <EditOutlined style={{ marginRight: 8, color: "#000000D9"}}/>
                                <Paragraph style={{marginBottom:0}}>Edit Payment</Paragraph>
                        </Link>
                    </Space>
                    <Space 
                        onClick={(e) => {
                            this.setState({selectedPayment: item, deleteModal: true})
                            e.stopPropagation()
                            }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <DeleteOutlined />
                            <Paragraph style={{marginBottom:0, marginLeft: 8}}>Delete Payment</Paragraph> 
                        </div>
                    </Space>
                
                </div>
            )
        }
        this.columns = [
            {
                title: 'Card Type',
                dataIndex: ["card", "brand"],
                width: '15%',
                key: "card",
                sorter: {
                    compare: (a, b) => a.card.brand.localeCompare(b.card.brand),
                    multiple: 1,
                },
                render:(_, record) => {
                    return(
                        <Text>{capitalizeFirstLetter(record.card.brand)}</Text>
                    )
                },
                web: true,
                mobile: true,

            },
            {
                title: 'Card Number',
                dataIndex: ["card", "last4"],
                width: '15%',
                key: "number",
                sorter: {
                    compare: (a, b) => a.card.last4.localeCompare(b.card.last4),
                    multiple: 1,
                },
                web: true,
                mobile: false,
                render: (text, record) => {
                    return(
                            record.card?.brand.toLowerCase() === "amex" ?
                            <div style={{display:'flex', alignItems: 'center'}}>
                                <Title style={{margin:0, fontWeight:600}} level={2}> •••• •••••• •</Title>
                                <Title style={{margin:0, fontWeight:400}} level={5}> {record.card?.last4 || '--'}</Title> 
                            </div> :
                            <div style={{display:'flex', alignItems: 'center', columnGap:"6px"}}>
                                <Title style={{margin:0, fontWeight:600}} level={2}>•••• •••• •••• </Title>
                                <Title style={{margin:0, fontWeight:400}} level={5}>{record.card?.last4 || '--'}</Title> 
                            </div>
                    )
                }
            },
            {
                title: 'Last Digits',
                dataIndex: ["card", "last4"],
                width: '15%',
                key: "last4",
                sorter: {
                    compare: (a, b) => a.card.last4.localeCompare(b.card.last4),
                    multiple: 1,
                },
                web: false,
                mobile: true,
                render: (text, record) => {
                    return <div style={{display: 'flex', alignItems: 'center'}}>

                        <p style={{margin: 0, fontWeight: 500}}>{record.card.last4}</p>
                    </div>
                }
            },


            {
                title: 'Action',
                width: '15%',
                dataIndex: 'Id',
                key: 'Id',
                web: true,
                mobile: true,
                render: (text, record) => <div style={{display: 'flex', alignItems: 'center', columnGap:'20px'}}>
                     <Link
                         to={{pathname: ROUTES.PAYMENT, search:'?option=edit', state: {record}}}
                         style={{color:"#F04A22", textDecoration:"none", border:"1px solid #F04A22", padding:"3px 15px", borderRadius:"2px"}}
                        onClick={(e) => {
                            e.stopPropagation()
                             let val = record.card.exp_month < 10 ? "0" + record.card.exp_month : record.card.exp_month;

                             this.setState({
                                 selectedRecord:record,
                                //  visible: true,
                                 currentId: record.id,
                                 cardNumber: record.card.last4,
                                 expiry: val + "/" + Array.from(record.card.exp_year.toString(), val => Number(val)).slice(2, 4).join("")
                             });

                             // this.form.current.setFieldsValue({ visible: true, currentId: record.id, cardNumber: record.card.last4, expiry: val + "/" + Array.from(record.card.exp_year.toString(), val => Number(val)).slice(2, 4).join("") });
                         }}>Edit</Link>
                    <div> 
                        {
                            <Text
                                style={{color:"#F04A22"}} 
                                onClick={(e) => {
                                    e.stopPropagation()
                                    this.setState({selectedPayment: record, deleteModal: true})
                                }}>Delete</Text> 
                        }
                    </div>
                </div>,
            },


        ];

        this.openNotification = (value) => {
            notification.open({
                message: value.message,
                description: value.description,
                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });
        };
        this.searchData = [];
        this.paymentRepository = new PaymentRepository(this.props.user.token)
        this.display = (responsive) => {

            return (
                <CustomRenderEmpty
                       description={"Save your payment information to quickly pay for future services."} 
                       extra={  
                            !canReadPageOnly(this.props?.user?.role) && 
                            <Button style={{fontSize: "12px", width: '180px', textAlign: 'center'}} onClick={() => {
                                this.props.history.push(`${ROUTES.PAYMENT}?option=add`)
                                }}>
                                + Add Payment Method 
                            </Button>
                           }>
                {
                   responsive == true ?
                   <Table 
                        onRow={(record) => {
                            return {
                                onClick: () => this.setState({
                                    selectedRecord:record,
                                    paymentDetailsModalVisible:true
                                })
                            };
                        }}
                        sticky={true} rowKey='id' 
                        grid={{
                            gutter: 16,
                            xs: 0,
                            sm: 0,
                            md: 0,
                            lg: 1,
                            xl: 1,
                            xxl: 1,
                        }}
                        showSorterTooltip={false}
                        columns={this.columns.filter((item) => canReadPageOnly(this.props?.user?.role) ? item?.title.toLowerCase() !== "action" : item).filter(item => responsive == true ? item.web : item.mobile == true)} dataSource={this.state.showData}
                        scroll={{x: '100%'}} 
                        loading={this.state.dataLoading || this.state.deleteLoading}
                    />
                    :
                    <List
                        grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 3,
                        }}
                        dataSource={this.state.showData}
                        loading={this.state.dataLoading || this.state.deleteLoading}
                        columns={this.columns.filter((item) => canReadPageOnly(this.props?.user?.role) ? item?.title.toLowerCase() !== "action" : item)}
                        rowKey="Id" 
                        pagination={{
                        onChange: page => {
                            window.scrollTo({
                            top:0,
                            behavior:"smooth"
                            })
                        },
                    pageSize: 10,
                     }}
                    renderItem={
                        item => {
                         return (
                         <List.Item 
                         //onClick = {() => this.setState({selectedRecord:item, paymentDetailsModalVisible:true})} 
                         key={item?.id}>
                            <Card style={{width:"100%", /*minHeight:"210px"*/ }}>
                                <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                                    <div style={{display:"flex", justifyContent:"space-between"}}>
                                        <div style={{color:"#000000", fontSize:18, fontWeight:"bold"}}>
                                            {capitalizeFirstLetter(item.card.brand)}
                                        </div>
                                        <Popover content={paymentPopoverContent(item)}  trigger="click" placement='bottom' overlayClassName="payments-popover" arrowPointAtCenter>
                                            <img src={ActionIcon} 
                                            alt="action icon" 
                                            onClick={
                                                (e) => {
                                                    e.stopPropagation()
                                                }}/>  
                                        </Popover>
                                    </div>
                                    <div>
                                        {item.card?.brand.toLowerCase() === "amex" ?
                                            <div style={{display:'flex', alignItems: 'center', whiteSpace:'nowrap'}}>
                                                <Title style={{margin:0, fontWeight:600}} level={2}> •••• •••••• •</Title>
                                                <Title style={{margin:0, fontWeight:400}} level={5}> {item.card?.last4 || '--'}</Title> 
                                            </div> :
                                            <div style={{display:'flex', alignItems: 'center', columnGap:"6px", whiteSpace:'nowrap'}}>
                                                <Title style={{margin:0, fontWeight:600}} level={2}>•••• •••• •••• </Title>
                                                <Title style={{margin:0, fontWeight:400}} level={5}>{item.card?.last4 || '--'}</Title> 
                                            </div>
                                        }
                                    </div>
                                </Space>
                            </Card>
                        </List.Item>
                       
                        
                    )}


                }
                />
                }
               </CustomRenderEmpty>
            )
        }

        this.fetchPaymentMethods = this.fetchPaymentMethods.bind(this)
    }

    fetchPaymentMethods(){
        this.paymentRepository.getPayments()
            .then(res => {
                this.setState({data: res.data.data, dataLoading: false, showData: res.data.data});
            })
            .catch(err => {
                this.setState({dataLoading: false});
            })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({dataLoading: true})
        this.fetchPaymentMethods()
    }
 
    render() {
        let {sortedInfo, filteredInfo} = this.state;
        sortedInfo = sortedInfo || {};
        let _this = this

        const params = new URLSearchParams(this.props.location.search)
        return (
            <Fragment>
                <MetaTags>
                    <title>Manage Payment Methods | TecMe </title>
                    <meta name="description" content={META_DESCRIPTION}/>
                    <meta name="robots" content="noindex, nofollow" />
                </MetaTags>
                    
                <AddEditPaymentModal 
                    open={params.get('option')} 
                    onOk={() => {
                        this.props.history.push(this.props.location?.pathname)
                    }} 
                    onCancel={() => {
                        this.props.history.push(this.props.location?.pathname)
                    }} 
                    history={this.props.history}
                    option={params.get('option')}
                    fetchPaymentMethods={this.fetchPaymentMethods}
                    paymentDetails={this.state.selectedRecord}
                    expiryDate={this.state.expiry}
                    user={this.props?.user}
                />
                <Modal   
                    title={"Payment Type"}
                    visible={this.state.paymentDetailsModalVisible}
                    onOk={() => this.setState({paymentDetailsModalVisible:false})}
                    onCancel={() => this.setState({paymentDetailsModalVisible:false})}
                    width={800}
                    footer={null}
                >
                    <Row gutter={[24, 24]} style={{padding:"20px 0"}} align="middle">
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Cards
                                cvc=""
                                expiry={`${this.state.selectedRecord?.card?.exp_month < 10 ? `0${this.state.selectedRecord?.card?.exp_month}` : this.state.selectedRecord?.card?.exp_month}/${this.state.selectedRecord?.card?.exp_year}`}
                                preview={true}
                                issuer={this.state.selectedRecord?.card?.brand}
                                number={this.state.selectedRecord?.card?.brand.toLowerCase() === "amex" ? `***********${this.state.selectedRecord?.card?.last4}` : `************${this.state.selectedRecord?.card?.last4}`}
                                name=" "
                                // name={this.state.selectedRecord?.billing_details?.name || `${this.props.user?.firstname} ${this.props.user?.lastname}`}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row gutter={[12, 12]}>
                                <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                                    <Paragraph style={{margin:0, fontWeight:600}}>Card Brand</Paragraph>
                                    <Title style={{margin:0, fontWeight:400}} level={5}>{capitalizeFirstLetter(this.state.selectedRecord?.card?.brand || '--')}</Title>
                                </Col>
                                <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                                    <Paragraph style={{margin:0, fontWeight:600}}>Card Number</Paragraph>
                                    { this.state.selectedRecord?.card?.brand.toLowerCase() === "amex" ?
                                        <div style={{display:'flex', alignItems: 'center'}}>
                                            <Title style={{margin:0, fontWeight:600}} level={2}> •••• •••••• •</Title>
                                            <Title style={{margin:0, fontWeight:400}} level={5}> {this.state.selectedRecord?.card?.last4 || '--'}</Title> 
                                        </div> :
                                        <div style={{display:'flex', alignItems: 'center', columnGap:"6px"}}>
                                            <Title style={{margin:0, fontWeight:600}} level={2}>•••• •••• •••• </Title>
                                            <Title style={{margin:0, fontWeight:400}} level={5}>{this.state.selectedRecord?.card?.last4 || '--'}</Title> 
                                        </div>
                                    }
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Paragraph style={{margin:0, fontWeight:600}}>Expiry Date</Paragraph>
                                    <Title style={{margin:0, fontWeight:400}} level={5}>{`${this.state.selectedRecord?.card?.exp_month < 10 ? `0${this.state.selectedRecord?.card?.exp_month}` : this.state.selectedRecord?.card?.exp_month}/${this.state.selectedRecord?.card?.exp_year}`}</Title>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Paragraph style={{margin:0, fontWeight:600}}>Funding Type</Paragraph>
                                    <Title style={{margin:0, fontWeight:400}} level={5}>{capitalizeFirstLetter(this.state.selectedRecord?.card?.funding) || '--'}</Title>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal>

                {
                    this.state.visible &&
                    <Modal                              
                        destroyOnClose={true}
                        className="ant-mdl"
                        visible={this.state.visible}
                        forceRender={this.state.visible ? false : true}
                        title={false}
                        footer={false}
                        onCancel={() => {
                            this.setState({visible: false, formStatus: false})
                        }}

                    >
                        <Title level={3}>Edit payment method</Title>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            
                            {listData.map((item, index) => {
                                const marginCard = (index + 1) % 4 == 0 ? {width: '10px', height: '10px', marginRight: '1rem'} : {width: '10px', height: '10px'}
                                return <CircleIcon key={index} style={marginCard}/>
                            })}
                            <p style={{margin: 0, fontWeight: 500}}>{this.state.cardNumber}</p>
                        </div>

                        <Form
                            style={{width: '100%', margin: '1rem 0'}}
                            layout="vertical"
                            name="paymentForm"
                            initialValues={{
                                expiry: this.state.expiry
                            }}
                            ref={this.form}
                            onFinish={(values) => {
                                if (this.state.expiry == values.expiry || this.state.expiryError == true) {
                                    this.setState({isFormChange: true})
                                    return
                                }

                                this.setState({isFormChange: false,})
                                let expiry = values.expiry.split("/")
                                this.setState({isFormChange: false, loading: true})
                                this.forceUpdate()
                                const data = new FormData();

                                data.append("exp_month", String(expiry[0]))
                                data.append("exp_year", String(expiry[1]))


                                this.paymentRepository.editPayment(this.state.currentId, data)
                                    .then(res => {

                                        const objIndex = this.state.data.findIndex(obj => obj.id == this.state.currentId)

                                        let dataCopy = [...this.state.data];
                                        dataCopy[objIndex] = {
                                            ...dataCopy[objIndex],
                                            card: {...dataCopy[objIndex].card, exp_month: parseInt(expiry[0]), exp_year: parseInt("20" + String(expiry[1]))}
                                        };

                                        this.setState({data: dataCopy, showData: dataCopy});
                                        this.setState({loading: false, visible: false})

                                        this.openNotification({
                                            message: 'Payment Method Update Notification',
                                            description: 'Payment Method Successfully Updated'
                                        })


                                    })
                                    .catch(err => {
                                        this.setState({loading: false, visible: false})
                                        this.openNotification({message: 'Payment Method Update Notification', description: 'Payment Method Update Failed'})
                                        this.props.history.push(ROUTES.PAYMENT)
                                    })

                            }}
                        >

                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item
                                        style={{padding: 0, margin: 0}}
                                        label="Expiry" required
                                        name="expiry"
                                        rules={[
                                            {
                                                required: true,

                                                message: 'Please enter a valid CVC',
                                            },


                                        ]}

                                    >
                                        <InputMask mask="\ 99/99" maskChar=" "
                                                   onChange={(e) => {
                                                       if (e.target.value.search("_") == -1 && e.target.value != "") {
                                                           this.setState({errorMessage: "", expiryError: false})
                                                           let expiry = e.target.value.split("/")
                                                           let month = new Date().getMonth()
                                                           let year = new Date().getFullYear()
                                                           year = parseInt(year.toString().substr(-2));
                                                           let current_month = parseInt(expiry[0])
                                                           let current_year = parseInt(expiry[1])
                                                           if (current_month > 0 && current_month <= 12 && current_year >= year && current_year != year) {

                                                               this.setState({errorMessage: "", expiryError: false})

                                                           } else if (current_month >= month + 1 && current_month <= 12) {
                                                               if (current_year >= year) {
                                                                   this.setState({errorMessage: "", expiryError: false})
                                                               } else {
                                                                   this.setState({errorMessage: "Expiry year cannot be in the past", expiryError: true})

                                                               }
                                                           } else if (current_year < year) {
                                                               this.setState({errorMessage: "Expiry year cannot be in the past", expiryError: true})
                                                           } else {
                                                               this.setState({errorMessage: "Expiry month must be between 01 and 12", expiryError: true})

                                                           }
                                                       } else {
                                                           this.setState({errorMessage: "", expiryError: true})
                                                       }

                                                   }}
                                        >{(inputProps) => <Input size='large' placeholder='MM/YY' style={{
                                            padding: '7px',
                                            borderRadius: '0.3rem',
                                            border: this.state.expiryError == true ? '1px solid red' : '',
                                            marginBottom: '1rem'
                                        }}/>}</InputMask>

                                    </Form.Item>
                                </Col>
                            </Row>
                            {this.state.isFormChange && <Alert message="No changes found." type="info"/>}
                            {this.state.expiryError && <p style={{margin: 0, color: 'red'}}>{this.state.errorMessage}</p>}
                            <Button type={"primary"} block htmlType="submit" loading={this.state.loading} style={{marginTop: '1rem'}}>
                                Save
                            </Button>
                        </Form>

                    </Modal>
                }

                <Modal visible={this.state.deleteModal} centered closable={false}
                       onCancel={(e) => this.setState({deleteModal: false})}
                       okText="Delete"
                       onOk={() => {
                           this.setState({
                               deleteModal: false,
                               deleteLoading: true

                           });
                           this.paymentRepository.paymentDelete(this.state.selectedPayment.id).then((response) => {

                               notification['success']({
                                   message: 'TecMe',
                                   description:
                                       'Payment method deleted successfully',
                               });
                               let filterData = _this.state.data.filter(payment => payment.id != this.state.selectedPayment.id)

                               this.setState({
                                   data: filterData,
                                   showData: filterData,
                                   deleteLoading: false,

                               });


                           }).catch((error) => {
                               this.setState({
                                   deleteLoading: false,

                               })
                               console.log(error)
                           })
                       }}
                >
                    <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
                    <Paragraph className='my-2 disclaimer p-2'>
                    This action will permanently delete this payment method and cannot be undone. Please make sure you want to delete the item before proceeding. 
                    </Paragraph>
                </Modal>
                <Content className="main">
                    <div className="page">
                        <div className="" style={{minHeight: '800px'}}>

                            <Row gutter={[15, 15]} justify={"center"}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div>
                                        <div>
                                            <Row gutter={[20, 20]} align={"middle"} justify={"space-between"}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>


                                                    <PageHeaderWrapper
                                                        routes={[
                                                            {
                                                                path: ROUTES.PAYMENT,
                                                                breadcrumbName: 'Payment Methods',
                                                            },
                                                        ]}
                                                    />
                                                </Col>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <div className='auth-container' >
                                                    <div className="auth-page-ctn">
                                                        <PageTitle 
                                                            title={"Payment Methods"}
                                                            extra={ !canReadPageOnly(this.props?.user?.role) &&
                                                                <Button onClick={() => {
                                                                    this.props.history.push({
                                                                        pathname: ROUTES.PAYMENT,
                                                                        search: '?option=add'
                                                                      })
                                                                }}>
                                                                    + Add Payment Method
                                                                </Button>
                                                            }
                                                            search={
                                                                <SearchInput 
                                                                    placeholder="Search Payment Methods"
                                                                    onChange={(event) => {
                                                                        this.searchData = this.state.data.filter((val) => {
                                                                            if (event.target.value == "") {

                                                                                return val
                                                                            } else if (val.card.brand.toLowerCase().includes(event.target.value.toLowerCase()) || val.card.last4.toLowerCase().includes(event.target.value.toLowerCase())) {

                                                                                return val
                                                                            }

                                                                        })

                                                                        this.setState({showData: this.searchData})

                                                                    }}
                                                                />
                                                            }
                                                        />
                                                        <Row gutter={[16, 16]}>
                                                            <Col xs={0} sm={0} md={0} lg={24} xl={24}>
                                                                {this.display(true)}
                                                            </Col>
                                                            <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                                                                {this.display(false)}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </div>
                    </div>
                </Content>
            </Fragment>
        )
    }
}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(Payment);
