import React from 'react'
import { MetaTags } from 'react-meta-tags'
import { Badge, Button, Input, Layout, List, Popover, Space, Table, Tabs, Tag, Typography } from 'antd'
import './assets/css/styles.scss'
import { useHistory } from 'react-router-dom'
import { META_DESCRIPTION } from '../../../../../utils/constants'
import { Link } from 'react-router-dom'
import ROUTES from '../../../../../utils/routes'
import { useState } from 'react'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import {capitalizeFirstLetter, getTableTimeFormat, truncateText } from '../../../../../utils/helper'
import PageHeaderWrapper from '../../../../components/page-header-breadcrumbs'
import { CloseCircleOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import ActionIcon from './assets/icon/action-icon.svg'
import WithdrawModal from './components/withdraw-modal'
import CustomRenderEmpty from '../../../../components/custom-render-empty'
import useWindowSize from '../../../../../hooks/useWindowSize'

const { Content } = Layout
const {Title, Paragraph} = Typography
const {Search} = Input

const statusColor = (status) => {
    switch(status) {
        case 'Accepted':
            return 'success'
        case 'Submitted':
            return 'processing'
        case 'Withdrawn':
            return 'error'
        case 'Closed':
            return 'default'
        default:
            return 'default'
    }
}

const ManageJobApplications = () => {

    const history = useHistory()
    const tech = useSelector(state => state.tech)   
    const [jobApplications, setJobApplications] = useState([])
    const [filteredJobApplications, setFilteredJobApplications] = useState([])
    const [loadingApplications, setLoadingApplications] = useState(false)
    const [visible, setVisible] = useState(false)
    const [selectedJobApplication, setSelectedJobApplication] = useState(null)
    const { windowWidth } = useWindowSize()

    const handleApplicationSearch = (e) => {
        const value = e.target.value.toLowerCase()
        const filteredData = jobApplications.filter((item) => {
            return item?.Job_Posting?.Title?.toLowerCase().includes(value) 
        })
        setFilteredJobApplications(filteredData)
    }

    const activeJobPostPopoverContent = (item) => {
        return(
            <div className="job-posting-popover-content">   
                {
                    (item?.Status === "Accepted" || item?.Status === "Closed" || item?.Job_Posting?.Status === "Closed" || item?.Status === "Withdrawn") ?
                    <Space className='popover-action-item' 
                        align='center' 
                        onClick={(e) =>{ 
                            history?.push({
                                pathname: ROUTES.JOB_APPLICATION_DETAILS.replace(':id', item.id),
                                state: { jobPostingId: item?.Job_Posting?.id, action:'view' }
                            })
                            e.stopPropagation()
                        }}>
                        <EyeOutlined className='popover-action-icon' />
                        <Paragraph style={{marginBottom:0}}>View Application</Paragraph> 
                    </Space> :
                    <>
                        <Space className='popover-action-item' align='center'
                            onClick={(e) => {
                                history.push({
                                    pathname: `${ROUTES.EDIT_JOB_APPLICATION.replace(':job_id', item?.Job_Posting?.id).replace(':application_id', item?.id)}`,
                                    state: { jobPostId: item?.Job_Posting?.id }
                                })
                                e.stopPropagation()
                            }}>
                            <EditOutlined className='popover-action-icon'/>
                            <Paragraph style={{marginBottom:0}}>Edit Application</Paragraph> 
                        </Space>
                        <Space className='popover-action-item' 
                            align='center' 
                            onClick={(e) =>{ 
                                setSelectedJobApplication(item)
                                setVisible(true)
                                e.stopPropagation()
                            }}>
                                <CloseCircleOutlined className='popover-action-icon'/>
                                <Paragraph style={{marginBottom:0}}>Withdraw Application</Paragraph> 
                        </Space>
                    </>
                }
            </div>
        )
    }

    const submittedApplicationsColumns = [
        {
            title: 'Job Title',
            dataIndex: 'job-post',
            key: 'job-post',
            width: '40%',
            render:(_, record) => {
                return(
                    <Paragraph style={{margin:0, fontWeight:500, fontSize:14, color:"#F04A22"}}>{ truncateText(capitalizeFirstLetter(record?.Job_Posting?.Title), 70)}</Paragraph>
                )
            },
            sorter: {
                compare: (a, b) => {
                    if(a?.Job_Posting?.Title) return a?.Job_Posting?.Title.localeCompare(b?.Job_Posting?.Title)
                },
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render:(_, record) => {
                if(record?.Status === "Submitted" && record?.Job_Posting?.Status === "Closed") {
                    return(
                        <Tag color={statusColor("Closed")}>Closed</Tag>
                    )
                }
                return(
                    <Tag color={statusColor(record?.Status)}>{record?.Status}</Tag>
                )
            },
            sorter: {
                compare: (a, b) => {
                    if(a?.Status) return a?.Status.localeCompare(b?.Status)
                },
            },
        },
        {
            title: 'Applied on',
            dataIndex: 'date',
            key: 'date',
            render:(_, record) => {
                return(
                    <Paragraph style={{margin:0, fontSize:14}}>{getTableTimeFormat(record?.CreatedDate)}</Paragraph>
                )
            },
            sorter: {
                compare: (a, b) => {
                    if(a?.CreatedDate) return new Date(a?.CreatedDate) - new Date(b?.CreatedDate)
                },
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Popover content={activeJobPostPopoverContent(record)}  trigger="click" placement='bottom' overlayClassName="job-posting-popover" arrowPointAtCenter>
                    <img src={ActionIcon} alt="action icon" onClick={
                        (e) => {
                            e.stopPropagation()
                        }}/>  
                </Popover>
            ),
        },
    ];

    const fetchJobApplications = () => {
        setLoadingApplications(true)
        ApiBaseHelper.get({
            url: `job-post/applications`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setJobApplications(res?.data?.data.filter((item) => item?.Status !== "Invited")?.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)))
            setLoadingApplications(false)
        }).catch((error) => {
            console.error(error)
            setLoadingApplications(false)
        })       
    }


    useEffect(() => {
        fetchJobApplications()
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        setFilteredJobApplications(jobApplications)
    }, [jobApplications])


    return (
        <>
            <MetaTags>
                <title>Manage Job Applications - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <PageHeaderWrapper
                routes={[
                    {
                        path: ROUTES.MANAGE_JOB_APPLICATIONS,
                        breadcrumbName: 'My Applications'
                    },
                ]}
            />
            <Content className="main">
                <div className='page'>
                    <div className="auth-container">
                        <div className="auth-page-ctn manage-applications-ctn">
                            <div className="manage-applications-header">
                                <Title level={3}>My Applications</Title>
                            </div>
                            <div className="manage-applications-table"> 
                                <div className="manage-applications-table-header">
                                    <Title level={5}>Submitted Applications</Title>
                                    {
                                        jobApplications?.length > 0 &&
                                        <Search 
                                            placeholder="Search by keyword" className="search-manage-applications" 
                                            onChange={handleApplicationSearch}
                                        />
                                    }
                                </div>
                                <CustomRenderEmpty
                                    description="You have not applied to any job postings yet."
                                    extra={
                                        <Button onClick={() => history.push(ROUTES.BROWSE_JOBS)}>Browse Jobs</Button>
                                    }
                                >
                                    {
                                        windowWidth > 768 ?
                                        <Table 
                                            columns={submittedApplicationsColumns} 
                                            dataSource={filteredJobApplications} 
                                            loading={loadingApplications}
                                            onRow={(record) => {
                                                return {
                                                    onClick: () => history?.push({
                                                    pathname: ROUTES.JOB_APPLICATION_DETAILS.replace(':id', record.id),
                                                    state: { jobPostingId: record?.Job_Posting?.id, action:'view' }
                                                    })
                                                };
                                            }}
                                            pagination={{
                                                onChange: page => {
                                                    window.scrollTo({
                                                        top: 0,
                                                        behavior: 'smooth'
                                                    })
                                                },
                                            }}
                                        /> :
                                        <List
                                            dataSource={filteredJobApplications}
                                            loading={loadingApplications}
                                            pagination={{
                                                onChange: page => {
                                                    window.scrollTo({
                                                        top: 0,
                                                        behavior: 'smooth'
                                                    })
                                                },
                                                pageSize: 10,
                                            }}
                                            renderItem={
                                                item => {
                                                    return(
                                                    <List.Item key={item?.id}>
                                                        <div 
                                                            className="job-application-list-item"
                                                            onClick={() => history?.push({
                                                                pathname: ROUTES.JOB_APPLICATION_DETAILS.replace(':id', item.id),
                                                                state: { jobPostingId: item?.Job_Posting?.id, action:'view' }
                                                                })}
                                                            >
                                                            <div className="job-application-list-item-content">
                                                                <Paragraph style={{marginBottom:4, fontWeight:500, fontSize:14, color:"#F04A22"}}>{capitalizeFirstLetter(item?.Job_Posting?.Title)}</Paragraph>
                                                                <Paragraph style={{marginBottom:4, fontSize:14}}>{getTableTimeFormat(item?.CreatedDate)}</Paragraph> 
                                                                
                                                                {
                                                                    (item?.Status === "Submitted" && item?.Job_Posting?.Status === "Closed") ? 
                                                                    <Badge status={statusColor("Closed")} text="Closed" /> : 
                                                                    <Badge status={statusColor(item?.Status)} text={item?.Status} />

                                                                }
                                                            </div>  
                                                            <div className="job-application-list-item-action">
                                                            <Popover 
                                                                content={activeJobPostPopoverContent(item)}  trigger="click" placement='bottom' overlayClassName="job-posting-popover" arrowPointAtCenter>
                                                                <img src={ActionIcon} alt="action icon" onClick={
                                                                    (e) => {
                                                                        e.stopPropagation()
                                                                    }}/>  
                                                            </Popover>
                                                            </div>
                                                        </div>
                                                    </List.Item>
                                                )}
                                            }
                                        /> 
                                    }
                                </CustomRenderEmpty>
                                <div className="search-for-jobs">
                                    <Link to={ROUTES.BROWSE_JOBS}>Search for jobs</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Content>
            <WithdrawModal 
                visible={visible}
                setVisible={setVisible}
                jobApplication={selectedJobApplication}
                fetchData={fetchJobApplications}
            />
        </>
    )
}

export default ManageJobApplications