import { useState } from 'react'
import { Modal, Typography, Button, Space, Avatar, Divider } from 'antd'

const { Title, Text } = Typography

const RemoteAccessSessionModal = ({ visible, onOk, onCancel, ticketDetails, ticketRepository, getAndSetTicketDetails, getAndSetActivities }) => {
    const [isApiCallInProgress, setIsApiCallInProgress] = useState(false)
    const handleStartNewSessionClick = async () => {
        setIsApiCallInProgress(true)
        const payload = {
            Customer_Contact: ticketDetails?.Reporter_Contact?.id
        }
        const res = await ticketRepository?.startRemoteSession(ticketDetails?.id, payload);
        if(res) {
            await getAndSetActivities()
            await getAndSetTicketDetails()
            onOk()
        }
        setIsApiCallInProgress(false)
    }

    return (
    <Modal
        visible={visible}
        onCancel={onCancel}
        className="remote-access-session-modal"
        footer={false}
        width={600}
    >
        <Title level={3} style={{ marginTop: '-0.6rem' }}>Start a remote access session</Title>
        <Text>
            Remote into the customers device for troubleshooting or to perform a service. Follow these instructions to make sure your session starts smoothly! 
        </Text>
        <div className='step-wrapper mt-3'>
            <Avatar className='step-number-wrapper' size={30}>
                <Text className='step-number'>1</Text>
            </Avatar>
            <Text className='fs-6 font-weight-500'>Get your links to join the session</Text>
        </div>
        <Text>
            Remote access is completely ran through the browser. Once you've started this session, two join links will be created. One for the customer and one for you, the technician. Find yours at the top of the ticket details page and your customer's in the activities.  
        </Text>
        <Divider className='my-2' />
        <div className='step-wrapper'>
            <Avatar className='step-number-wrapper' size={30}>
                <Text className='step-number'>2</Text>
            </Avatar>
            <Text className='fs-6 font-weight-500'>
                Ensure the customer opens the link from the right device
            </Text>
        </div>
        <Text>
            Make sure the customer understands that they need to click on their link from the device you need access to. Their link can be accessed from the activities or email sent to the customer when the session is started. If it’s the customers first time using remote access on that device, they’ll need to install a small agent.
        </Text>
        <Divider className='my-2' />
        <div className='step-wrapper'>
            <Avatar className='step-number-wrapper' size={30}>
                <Text className='step-number'>3</Text>
            </Avatar>
            <Text className='fs-6 font-weight-500'>
                Connect once both you and the customer have opened the link
            </Text>
        </div>
        <Text>
            As soon as both you and the customer have opened the link, the remote session will begin. To learn more about how the remote session works & controls, click here
        </Text>
        <Space className='w-100 justify-content-end mt-3'>
            {!ticketDetails?.custom_data?.tech_remote_url && (
                <Button type="text" onClick={onCancel}>
                    Cancel
                </Button>
            )}
            <Button type={ticketDetails?.custom_data?.tech_remote_url ? 'ghost' : 'primary'} onClick={handleStartNewSessionClick} loading={isApiCallInProgress}>
                Start New Remote Session
            </Button>
            {!!ticketDetails?.custom_data?.tech_remote_url && (
                <Button type='primary'>
                    <a
                        href={ticketDetails.custom_data.tech_remote_url}
                        className="text-decoration-none"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Join Remote Session
                    </a>
                </Button>
            )}
        </Space>
    </Modal>
    )
}

export default RemoteAccessSessionModal