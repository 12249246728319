// External Imports
import { useState, useEffect } from 'react'
import { Button, Typography, Modal, notification } from 'antd'
import moment from 'moment'

// Internal Imports
import { AvailabilitySection } from '..';
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper';

const { Title, Paragraph } = Typography

const RescheduleModal = ({ isVisible, onOk, onCancel, ticketDetails, ticketRepository, getAndSetTicketDetails, getAndSetActivities }) => {
	const [timeSlot, setTimeSlot]  = useState("")
    const [schedule, setSchedule] = useState({});
    const [apiCallInProgress, setApiCallInProgress] = useState(false);
    // console.log({ beforeStateSet: date })
    const [value, onChange] = useState(new Date());

    const getAndSetSchedule = async (techId) => {
		try {
			const res = await ApiBaseHelper.get({ url: `web/tech/${techId}/schedule` });
			setSchedule(res.data['data'])
		} catch (error) {
			// notification.error({
			// 	message: 'TecMe',
			// 	description: 'Fetch schedule failed'
			// })
		}
    }

    useEffect(() => {
        if(ticketDetails?.Provider_Contact?.id) {
            getAndSetSchedule(ticketDetails?.Provider_Contact?.id)
        }
    }, [ticketDetails?.Provider_Contact])

    useEffect(() => {
        if(!!ticketDetails?.Scheduled_Date_Time) {
            const date = moment.utc(ticketDetails?.Scheduled_Date_Time).local(false).toDate();
            onChange(date)
        }
    }, [ticketDetails?.Scheduled_Date_Time])

    const handleScheduleClick = async () => {
        setApiCallInProgress(true)

        try {
            const scheduledDate = moment(value);
            const scheduledTime = moment(timeSlot.start);
            const scheduledDateTime = moment(scheduledDate.format('YYYY-MM-DD') + ' ' + scheduledTime.format('HH:mm:s'), 'YYYY-MM-DD HH:mm:s').utc().format();

            const payload = {
                Technician_Contact: ticketDetails?.Provider_Contact?.id,
                Scheduled_Date_Time: scheduledDateTime
            }

            await ticketRepository?.reScheduleOnSiteVisit(ticketDetails?.id, payload);
            await getAndSetTicketDetails()
            await getAndSetActivities()
            onOk()
        } catch (error) {
            // notification.error({
            //     message: 'TecMe',
			// 	description: 'Reschedule visit failed'
            // })
        }

        setApiCallInProgress(false)
    }

    return (
        <Modal
			className='find-tech-modal'
			width={550}
			footer={null}
			visible={isVisible}
			onOk={onOk}
			onCancel={onCancel}
		>
			<div className='tech-list-location'>
				<div className='tech-list-content'>
                <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
                <Paragraph className='my-2 disclaimer p-2'>
                Are you sure you want to reschedule the on-site visit? This action will change the date and time of the visit.
                </Paragraph>
                    <Paragraph style={{marginTop:15}}>
                        Choose a new date & time within {ticketDetails?.Provider_Contact?.Full_Name?.split(" ")?.[0] || ''}’s availability
                    </Paragraph>
                    <AvailabilitySection
                        timeSlot={timeSlot}
                        setTimeSlot={setTimeSlot}
                        schedule={schedule}
                        value={value}
                        onChange={onChange}
                        isFromRescheduleModal
                    />
				</div>
                <div className='tech-location-btn'>
                    <Button type='text' onClick={onCancel} className="me-2">
                        Cancel
                    </Button>
                    <Button
                        disabled={!timeSlot}
                        type='primary'
                        onClick={handleScheduleClick}
                        loading={apiCallInProgress}
                    >
                        Reschedule
                    </Button>
                </div>
			</div>
      	</Modal>
    )
}

export default RescheduleModal