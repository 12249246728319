import React, { Component, Fragment, useState } from "react";
import { Layout, Row, Col, Divider, Image, Skeleton, List, Typography, Collapse, Space, Button, Card, Modal, Avatar, Input, Spin, Tag, Select } from 'antd';
import { ApiBaseHelper } from "../../../../../utils/api-base-helper";
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from "react-redux";

const antIcon = <LoadingOutlined style={{ fontSize: 54 }} spin />;
const { Content } = Layout;
const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;

class PaymentMethods extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            value: this.props.value ? this.props.value : 'new'
        };
    }


    componentDidMount() {
        if (this.props.user != null) {
            this.setState({ loading: true })
            ApiBaseHelper.get({
                url: `stripe/payment-method`, headers: {
                    'Authorization': `Bearer ${this.props.token ? this.props.token : this.props.user['token']}`
                }
            }).then((res) => {
                if(this.props.setIsPaymentMethodExists) {
                    this.props.setIsPaymentMethodExists(!!(res.data?.data?.length))
                }
                this.setState({
                    data: res.data['data'],
                    loading: false
                });
                this.forceUpdate();
            }).catch((res) => {
                this.setState({
                    loading: false
                });
            });
        }

    }

    render() {
        const { loading, data } = this.state;
        return ((this.state.loading==false && this.state.data.length==0)?null:
            <Fragment>
                <Col
                    // xs={this.props.xs ? this.props.xs : 24}
                    // sm={this.props.sm ? this.props.sm : 24}
                    // md={this.props.md ? this.props.md : 12}
                    // lg={this.props.lg ? this.props.lg : 8}
                    // xl={this.props.xl ? this.props.xl : 8}
                    span={24}
                    className={this.props.className || ''}
                >
                    {/*{this.props.xl ? null : <Paragraph className="text">Select an existing payment method</Paragraph>}*/}

                    {this.state.loading ? <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>


                            <Skeleton.Input style={{ width: '100%' }} loading={true} active />


                        </Col>
                    </Row>
                        : <Select
                            size="large"
                            style={{ width: "100%", borderRadius: 3 }}
                            allowClear={false}
                            onChange={e => {

                                this.setState({ value: e });
                                this.props.onChange(e);
                            }}
                            defaultValue={this.state.loading ? 'new' : this.state.value}
                            placeholder={this.props.placeholder || "Select payment method"}
                            className={"lh-40"}
                        >
                            <Select.Option value={'new'}>{this.props.placeholder ? (
                                <Text type="secondary">{this.props.placeholder}</Text>
                            ) : 'Choose a previous payment method'}</Select.Option>
                            {
                                data.map((item, index) => {
                                    return <Select.Option key={item['id']} value={item['id']}>{item?.card?.brand.toLowerCase() === "amex" ? `**** ****** *${item?.card?.last4}` : `**** **** **** ${item?.card?.last4}`}</Select.Option>
                                })
                            }
                            {!!this.props.showNewOptionText && (
                                <Select.Option value={'add-new-method'}>
                                    <Text type="secondary">
                                        {this.props.showNewOptionText}
                                    </Text>
                                </Select.Option>
                            )}
                        </Select>}
                </Col>
                {/*{(this.props.xl || data.length == 0) ? null : <Col xs={24} sm={24} md={24} lg={24} xl={24}>*/}
                {/*    <Divider className="mb-2 mt-2" />*/}
                {/*</Col>}*/}
            </Fragment>
        );
    }

}

const mapStateToProps = store => {
    return store;
};

export default connect(mapStateToProps)(PaymentMethods);
