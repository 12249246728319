import { CheckCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Radio, Space, Typography } from 'antd'
import React from 'react'

const { Title, Paragraph } = Typography

const JobType = ({handleNextClick, jobPostingData, setJobPostingData}) => {

  return (
    <div className='job-type-ctn'>
        <div className="job-type-content">
            <div className="job-type-heading">
                <Space>
                    <CheckCircleOutlined style={{color:"#F04A22", fontSize:22}} />
                    <Title level={4} style={{marginBottom:0, fontSize:20, color:"#000"}}>Let’s define the work type preferences</Title>
                </Space>
                <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", fontSize:14, marginTop:17}}>Select the preferred work type for the job post to indicate whether it is remote, on-site, or a combination of remote and on-site work. This helps IT professionals understand the expected work arrangement and choose opportunities that align with their preferences and availability.</Paragraph>
            </div>
            <div className="job-type-form-ctn">
                <JobTypeForm jobPostingData={jobPostingData} setJobPostingData={setJobPostingData}/>
            </div>
            <div className="job-post-next-btn-ctn">
                <Button type='primary' className='job-post-next-btn' onClick={handleNextClick} disabled={!jobPostingData?.job_type}>Next</Button>
            </div>
        </div>
    </div>
  )
}

export const JobTypeForm = ({jobPostingData, setJobPostingData}) => {

    const handleJobTypeChange = (e) => {
        setJobPostingData({...jobPostingData, job_type: e.target.value})
    }

    return(
        <div className="job-type-form">
            <Form>
                <Form.Item>
                    <Radio.Group 
                        value={jobPostingData.job_type}
                        onChange={handleJobTypeChange}>
                        <Space direction="vertical">
                            <Radio value="Both">Remote & On-site</Radio>
                            <Title level={5} style={{fontWeight:400,  color:"rgba(0, 0, 0, 0.65)", fontSize:14}}>This work type combines remote and on-site work, providing a hybrid arrangement. IT professionals will have the flexibility to work remotely for a portion of the time and be present on-site for specific activities, meetings, or collaboration as needed.</Title>


                            <Radio value="Remote">Remote</Radio>
                            <Title  level={5} style={{fontWeight:400,  color:"rgba(0, 0, 0, 0.65)", fontSize:14}}>This work type indicates that the job can be performed remotely, without the need for physical presence at a specific location. IT professionals can work from their preferred location, providing flexibility and eliminating geographical constraints.</Title>

                            <Radio value="On_Site">On-site</Radio>
                            <Title  level={5} style={{fontWeight:400,  color:"rgba(0, 0, 0, 0.65)", fontSize:14}}>With this work type, the job requires physical presence at a designated location. IT professionals will need to be available to work at the specified workplace, ensuring close collaboration and direct engagement with the team or project</Title>
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </div>
    )
}

export default JobType