// External Imports
import {useState, useEffect} from 'react'
import {
    Modal,
    Typography,
    Form,
    Select,
    Radio,
    Badge,
    Input,
    Upload,
    Button,
    Row,
    Col,
    Space,
    Divider,
    Avatar,
    notification,
    message
} from 'antd'
import {InfoCircleOutlined, UploadOutlined, PlusOutlined, UserOutlined} from '@ant-design/icons'
import {useHistory} from 'react-router-dom'

// Internal Imports
// import { nameFormat } from '../../../../../../utils/helper'
import ROUTES from '../../../../../../../utils/routes'
import {SECONDARY_COLOR} from '../../../../../../../utils/constants'
import {getBrandIcon} from '../../../../public/instant-search/utils/helper'
import {ApiBaseHelper} from '../../../../../../../utils/api-base-helper'
import {useSelector} from "react-redux";
import { TicketDetailsRepository } from '../../../ticket-details/repository'
import { capitalizeFirstWordOfEveryLetter, nameFormat } from '../../../../../../../utils/helper'

const {Text, Paragraph} = Typography
const {Item, useForm} = Form
const {Option} = Select
const {TextArea} = Input

const NewSupportTicketModal = ({
                                   visible,
                                   onOk,
                                   onCancel,
                                   repository,
                                   ticketRepository,
                                   contractId,
                                   contractDetails,
                                   getAndSetContractDetails,
                                   sendMessage,
                                   user,
                                   tech,
                                   isFromViewEditContractPage,
                                   ticketDetails,
                                   editTicketModalVisible,
                                   getAndSetTicketDetails,
                                   getAndSetActivities,
                                   getAndSetDevice,
                                   getAndSetTicketMedia,
                                   media
                               }) => {
    const [loading, setLoading] = useState(false);
    const [devices, setDevices] = useState([])
    const [previewImage, setPreviewImage] = useState('');
    const [isImageModalVisible, setIsImageModalVisible] = useState(false);
    const [contractsList, setContractsList] = useState([]);
    const [selectedContractId, setSelectedContractId] = useState('');
    const [selectedProviderContact, setSelectedProviderContact] = useState('')
    // const [media, setMedia] = useState([])
    const [itcMembers, setItcMembers] = useState([])
    const [fileList, setFileList] = useState([])
    const [form] = useForm();
    const history = useHistory();
    const name = (contractDetails?.Provider_Org?.HIPAA_Contact_Name || contractDetails?.Provider_Org?.Name) || ticketDetails?.Provider_Contact?.Full_Name
    const image = contractDetails?.Provider_Org?.Contact_Image || ticketDetails?.Provider_Contact?.imgUrl
    const techId = contractDetails?.Provider_Org?.HIPAA_Primary_Contact || ticketDetails?.Provider_Contact?.id

    useEffect(() => {
        if (user)
            repository?.getDevices().then(res => {
                if (res?.data?.status === "success") setDevices(res.data.data)
            }).catch(error => console.error(error))

        if (!isFromViewEditContractPage) {
            ApiBaseHelper.get({
                url: "contract?role=customer", headers: {
                    Authorization: 'Bearer ' + user?.token
                }
            }).then((res) => {
                if (res?.data?.data?.length) {
                    setContractsList(res.data.data.filter(contract => contract.Status === "Active"))
                }
            }).catch((error) => console.error(error))
        }
    }, [])

    useEffect(() => {
        if (user && !!ticketDetails)
            repository?.getDevices().then(res => {
                if (res?.data?.status === "success") setDevices(res.data.data)
            }).catch(error => console.error(error))
    }, [ticketDetails])
 
    useEffect(() => {
        if(!!ticketDetails){
            let updatedTicketDetails = {
                Summary: ticketDetails?.Summary,
                Hardware: ticketDetails?.Hardware,
                Description: ticketDetails?.Description,
                Priority: ticketDetails?.Priority?.toLowerCase()
              };
            if(media){
                updatedTicketDetails = {...updatedTicketDetails, Attachments:media}
            }
            form.setFieldsValue(updatedTicketDetails);
        }
       

    },[editTicketModalVisible])

    useEffect(() => {
        if(contractDetails?.ITC){
            ApiBaseHelper.get({
                url: `itc/${contractDetails?.ITC}`
            }).then((res) => {
                ApiBaseHelper.get({
                    url: `web/itc/contents/${res.data.data.Subdomain}`
                }).then((res) => {
                    setItcMembers(res.data.data[0]?.Members)
                }).catch((error) => {
                    console.error(error)
                })
            }).catch((error) => {
                console.error(error)
            })
        }
    }, [contractDetails])
    

    const handleOnFinish = (values) => {
        const payload = new FormData();
        if (isFromViewEditContractPage) {
            payload.append('Contract', contractId)
        } else if (selectedContractId) {
            payload.append('Contract', selectedContractId)
        }
        if (isFromViewEditContractPage) {
            payload.append('Provider_Contact', contractDetails?.ITC ? selectedProviderContact : techId);
        } else {
            if (!!contractsList?.length && values.Provider_Contact && values.Provider_Contact !== 'unassigned') {
                const contract = contractsList?.find(contract => contract.id === values.Provider_Contact)
                if (contract) {
                    payload.append('Provider_Contact', contract.Provider_Org?.HIPAA_Primary_Contact);
                }
            }
        }
        payload.append('Priority',  values.Priority);
        if (values.Hardware && !ticketDetails) payload.append('Hardware', values.Hardware);
        payload.append('Summary', values.Summary.toString().trim());
        payload.append('Description', values.Description.toString().trim());

        if(!ticketDetails){
            if (values.Attachments?.length) {
                values.Attachments.forEach((file) => {
                    payload.append('file', file.originFileObj)
                });
            }
        }
       

        setLoading(true)
        if(!!ticketDetails){
            repository.editSupportTicket(payload, ticketDetails?.id).then(async (res) => {
                setLoading(false)
                // history.push(ROUTES.TICKET_DETAILS.replace(':ticketId', res.data?.data?.id) + `?from=${isFromViewEditContractPage ? 'contracts' : 'desk'}`)
                onCancel()
                getAndSetTicketDetails()
                getAndSetActivities()
                getAndSetDevice()
                getAndSetTicketMedia()
                notification['success']({
                    message: 'TecMe',
                    description: 'Ticket updated successfully',
                })
            }).catch(error => {
                console.error(error)
                setLoading(false)
            })
        } else{
            repository.addSupportTicket(payload).then(async (res) => {
                setLoading(false)
                form.resetFields()
                history.push(ROUTES.TICKET_DETAILS.replace(':ticketId', res.data?.data?.id) + `?from=${isFromViewEditContractPage ? 'contracts' : 'desk'}`)
            
            }).catch(error => {
                console.error(error)
                setLoading(false)
                form.resetFields()
            })
    
        }
    }

    return (
        <Modal
            title={<Text strong>{!!ticketDetails ? 'Edit Ticket Details' : `Create A New Ticket ${name ? `For ${name}` : ''}`}</Text>}
            visible={visible}
            onCancel={() => {
                form.resetFields()
                onCancel()
            }}
            onOk={onOk}
            footer={false}
            centered
            className='new-support-ticket-modal'
        >
            <Form
                form={form}
                onFinish={handleOnFinish}
                onFinishFailed={error => console.error(error)}
                layout="vertical"
            >
                <Item
                    name="Provider_Contact"
                    label="Technician"
                    rules={[{
                        required: isFromViewEditContractPage ? false : true,
                        message: 'Please select a technician'
                    }]}
                >
                    <Select
                        defaultValue={(!!isFromViewEditContractPage  && !contractDetails?.ITC) ? name : null}
                        disabled={(!!isFromViewEditContractPage  && !contractDetails?.ITC) || !!ticketDetails}
                        onChange={(_, element) => {
                            if (element?.key === "discover-techs" && contractDetails?.ITC && !!tech) {
                                history.push(`${ROUTES.ITC_TECH_TEAM.replace(':id', contractDetails?.ITC)}?tab=invite-more`) 
                                return
                            }
                            if (element?.key === "discover-techs") {
                                history.push(ROUTES.DISCOVERY_EXPERIENCE)
                            }
                            if(contractDetails?.ITC){
                                setSelectedProviderContact(element?.value)
                            }
                            setSelectedContractId(element?.key)
                        }}
                        placeholder="Select a technician"
                    >
                        {
                            (!isFromViewEditContractPage) && (
                                <Option value="unassigned">
                                    <Space align='center'>
                                        <Avatar size={22} icon={<UserOutlined/>}/>
                                        <Text>Unassigned</Text>
                                    </Space>
                                </Option>
                            )
                        }
                        {(isFromViewEditContractPage && !contractDetails?.ITC) ? (
                            <Option value={name}>
                                <Space align='center'>
                                    <Avatar size={20} src={image} alt={name} icon={<UserOutlined/>}/>
                                    <Text>
                                        {name}
                                    </Text>
                                </Space>
                            </Option>
                        ) :
                            itcMembers.map((member) => {
                                return(
                                    <Option value={member?.Member_Contact?.id} key={member.id}>
                                        <Space align='center'>
                                            <Avatar 
                                                size={20} 
                                                src={member?.Member_Contact?.imgUrl}
                                                alt={member?.Member_Contact?.First_Name} 
                                                icon={<UserOutlined/>}
                                            />
                                            <Text>
                                                {capitalizeFirstWordOfEveryLetter(nameFormat(`${member?.Member_Contact?.First_Name} ${member?.Member_Contact?.Name}`))}
                                            </Text>
                                        </Space>
                                    </Option>

                                )
                            })
                        }
                        {(!!contractsList?.length && !isFromViewEditContractPage) ? contractsList.map(contract => (
                            <Option
                                key={contract.id}
                                value={contract.id}
                            >
                                <Space align='center'>
                                    <Avatar size={20} src={contract.Provider_Org?.Contact_Image}
                                            alt={contract.Provider_Org?.HIPAA_Contact_Name || contract.Provider_Org?.Name} icon={<UserOutlined/>}/>
                                    <Text>
                                        {contract.Provider_Org?.HIPAA_Contact_Name || contract.Provider_Org?.Name}
                                    </Text>
                                    <Text type='secondary' style={{fontSize: 12}}>
                                        {contract.Type == 'Fixed' ? 'Retainer' : contract.Type == 'Per Ticket' ? 'Per Issue' : contract.Type} Contract
                                        (${Number(contract.Billing_Rate || 0) / 100}{contract.Billing_Frequency === "Bi-weekly" ? ' bi-weekly' : contract.Billing_Frequency || '/hr'})
                                    </Text>
                                </Space>
                                <Paragraph type='secondary' style={{fontSize: 12}}
                                           ellipsis={{rows: 1}}>{contract?.Description}</Paragraph>
                            </Option>
                        )) : (user && !isFromViewEditContractPage && !contractDetails?.ITC) ? (
                            <Option
                                key="discover-techs"
                                value="discover-techs"
                            >
                                <Text>
                                    + Find IT Professionals
                                </Text>
                            </Option>
                        ) : (tech && isFromViewEditContractPage && contractDetails?.ITC)&& (
                        <Option
                            key="discover-techs"
                            value="discover-techs"
                        >
                            <Text>
                                + Invite Members
                            </Text>
                        </Option>
                        )
                    }
                    </Select>
                </Item>
                <Item
                    name="Priority"
                    label="Priority"
                    rules={[
                        {
                            required: true,
                            message: 'Please select a priority'
                        }
                    ]}
                >
                    <Radio.Group>
                        <Radio.Button value="low">
                            <Badge status="default" text="Low"/>
                        </Radio.Button>
                        <Radio.Button value="medium">
                            <Badge status="warning" text="Medium"/>
                        </Radio.Button>
                        <Radio.Button value="high">
                            <Badge status="error" text="High"/>
                        </Radio.Button>
                    </Radio.Group>
                </Item>
                <Item
                    name="Summary"
                    label="Ticket Summary"
                    // initialValue={ticketDetails?.Summary}
                    rules={[
                        {
                            required: true,
                            message: 'Please enter ticket summary'
                        }
                    ]}
                >
                    <Input
                        placeholder='Give your ticket a brief summary'
                    />
                </Item>
                {
                    (!ticketDetails && user) && (
                    <Item
                        name="Hardware"
                        label="Device"
                       
                        tooltip={{
                            title: 'Select a device if your ticket is based on any hardware',
                            icon: <InfoCircleOutlined/>,
                        }}
                    >
                        <Select  placeholder="Select your device">
                            {devices.filter((device) => device.Active === "true").map(device => (
                                    <Option value={device.id} key={device.id}>
                                        <Space align='center'>
                                            <Avatar
                                                size={22}
                                                shape="square"
                                                src={device.Pic_1 || getBrandIcon(device.Make)}
                                                alt={device.Make}
                                            >
                                                {device.Make?.[0]}
                                            </Avatar>
                                            <Text>
                                                {device.Make} {device.HardwareName}{device.Operating_System_Name != null && device.Operating_System_Name.includes("a0") == false ? `, ${device.Operating_System_Name}` : ""}
                                            </Text>
                                        </Space>
                                    </Option>
                                )
                            )}
                            <Option>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        history.push(`${ROUTES.DEVICES}?option=add`)
                                    }}
                                    className="tecme-secondary-color w-100 text-start border-0 px-2 fw-bold"
                                    type='link'
                                > 
                                    <PlusOutlined className='fs-6' style={{
                                        strokeWidth: "40",
                                        stroke: SECONDARY_COLOR
                                    }}/>
                                    Add New Device
                                </Button>
                            </Option>
                        </Select>
                    </Item>
                    )
                }
               
                <Item
                    name="Description"
                    label="Issue Description"
                    // initialValue={ticketDetails?.Description}
                    rules={[
                        {
                            required: true,
                            message: 'Please enter description'
                        },
                        {
                            min: 10,
                            message: 'It should be at least 10 characters or more.',
                        }
                    ]}
                >
                    <TextArea
                        rows={6}
                        showCount
                        maxLength={500}
                    />
                </Item>
                {
                    !ticketDetails &&
                    (
                        <Item
                            name="Attachments"
                            label="Attachment(s)"
                            valuePropName="fileList"
                            getValueFromEvent={(e) => {
                                if (Array.isArray(e)) {
                                    return e;
                                }
                                return e?.fileList;
                            }}
                        >
                            <Upload
                                multiple
                                accept={
                                    [
                                        "image/png",
                                        "image/jpg",
                                        "image/jpeg",
                                        "image/gif",
                                        "image/webp",
                                        "image/bmp",
                                        "application/pdf",
                                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                        "application/vnd.oasis.opendocument.spreadsheet",
                                        "application/vnd.ms-powerpoint",
                                        "application/vnd.ms-excel",
                                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                        "text/csv",
                                    ].join(",")
                                }
                                onPreview={async (file) => {
                                    if (!file.url && !file.preview) {
                                        file.preview = await new Promise((resolve, reject) => {
                                            const reader = new FileReader();
                                            reader.readAsDataURL(file.originFileObj);
                                            reader.onload = () => resolve(reader.result);
                                            reader.onerror = error => reject(error);
                                        });
                                    }
                                    if (["image/png",
                                        "image/jpg",
                                        "image/jpeg",
                                        "image/gif",
                                        "image/webp",
                                        "image/bmp",
                                    ].includes(file.type)) {
                                        setPreviewImage(file.url || file.preview)
                                        setIsImageModalVisible(true)
                                    }
        
                                }}
                                onChange={async (e) => {
                                    if (e?.fileList?.length) {
                                        let invalidFiles = false
                                        e.fileList.forEach((file) => {
                                            if (![
                                                "image/png",
                                                "image/jpg",
                                                "image/jpeg",
                                                "image/gif",
                                                "image/webp",
                                                "image/bmp",
                                                "application/pdf",
                                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                                "application/vnd.oasis.opendocument.spreadsheet",
                                                "application/vnd.ms-powerpoint",
                                                "application/vnd.ms-excel",
                                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                                "text/csv",
                                            ].includes(file.type))
                                                invalidFiles = true;
                                        })
                                        if (invalidFiles) {
                                            notification.error({
                                                message: 'TecMe',
                                                description: `We are unable to process the file type you are attempting to upload - please only upload image and document files.`
                                            })
                                            return false;
                                        }
                                    }
                                }}
                                listType="picture"
                                beforeUpload={() => false}
                                // defaultFileList={[...fileList]}
                            >
                                <Button icon={<UploadOutlined/>}>Click to upload</Button>
                            </Upload>
                        </Item>
                    )
                }
              
                <Divider/>
                <Space className='w-100 justify-content-end'>
                    <Button
                        type='text'
                        onClick={() => {
                            form.resetFields()
                            onCancel()
                        }}
                    >
                        Cancel
                    </Button>
                    <Button type='primary' htmlType="submit" loading={loading}>
                       { !!ticketDetails ? 'Save' : 'Create'}
                    </Button>
                </Space>
            </Form>
            <Modal
                visible={isImageModalVisible}
                title={'TecMe'}
                footer={null}
                onCancel={() => setIsImageModalVisible(false)}
            >
                <img loading="lazy" alt="example" className='w-100' src={previewImage}/>
            </Modal>
        </Modal>
    )
}

export default NewSupportTicketModal