import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import React from 'react'
import './styles.css'

const SearchInput = (props) => {
  return (
        <div className='search-input-style'>
            <Input
                {...props}
                suffix={<SearchOutlined style={{color:"#959595"}}/>}
                size="large"
                allowClear
            />
        </div>
    )
}

export default SearchInput