import { CheckCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, InputNumber, Radio, Space, Typography } from 'antd'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'


const { Title, Paragraph } = Typography

const JobBudget = ({handleNextClick, jobPostingData, setJobPostingData}) => {
   
    return (
    <div className='job-budget-ctn'>
        <div className="job-budget-content">
            <div className="job-budget-heading">
                <Space>
                    <CheckCircleOutlined style={{color:"#F04A22", fontSize:22}} />
                    <Title level={4} style={{marginBottom:0, fontSize:20, color:"#000"}}>Tell us about your budget</Title>
                </Space>
                <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", fontSize:14, marginTop:17}}>Provide details about your budget for the job to help IT professionals understand the compensation range or project budget. This will help us match you to talent within your range.</Paragraph>
            </div>
            <div className="job-budget-form-ctn">
                <JobBudgetForm 
                    jobPostingData={jobPostingData}
                    setJobPostingData={setJobPostingData}
                    handleNextClick={handleNextClick}
                />
            </div>
        </div>
    </div>
  )
}

export const JobBudgetForm = ({jobPostingData, setJobPostingData, handleNextClick, currentStep}) => {
    const [budgetType, setBudgetType] = useState(null)

    const [error, setError] = useState(null)
    const [fixedError, setFixedError] = useState(null)
    const location = useLocation()

    useEffect(() => {
        if(!jobPostingData?.job_budget_from || !jobPostingData?.job_budget_to){
            setError("empty")
        }else if(parseInt(jobPostingData?.job_budget_from) > parseInt(jobPostingData?.job_budget_to)){
            setError("Ensure that the lower rate is less than the upper rate.")
        }else if(jobPostingData?.job_budget_from < 1 || jobPostingData?.job_budget_to < 1){
            setError("Budget must be greater than 0")
        }else{
            setError(null)
        }
        if(!jobPostingData?.job_budget_fixed || jobPostingData?.job_budget_fixed === ""){
            setFixedError("empty")
        }else if(jobPostingData?.job_budget_fixed < 1){
            setFixedError("Budget must be greater than 0")
        }else{
            setFixedError(null)
        }
    }, [jobPostingData?.job_budget_from , jobPostingData?.job_budget_to, jobPostingData?.job_budget_fixed])


    useEffect(() => {
        if(jobPostingData?.job_payment_type === "Fixed"){
            setJobPostingData({
                ...jobPostingData,
                job_budget_from: null,
                job_budget_to: null,
            })
            // setFixedError("Fill in the required field")
        }
        if(jobPostingData?.job_payment_type === "Hourly"){
            setJobPostingData({
                ...jobPostingData,
                job_budget_fixed: null,
            })
            // setError("Fill in the required field")
        }
    }, [jobPostingData?.job_payment_type])
    
    return(
        <>
            <div className="job-budget-form">
                    <Radio.Group 
                        onChange={(e) => {
                            setBudgetType(e.target.value)
                            setJobPostingData({...jobPostingData, job_payment_type: e.target.value})
                        }}
                        value={jobPostingData?.job_payment_type}
                    >
                        <Space direction="vertical">
                            <Radio value="Hourly">Hourly payment</Radio>
                            <Title level={5} style={{fontWeight:400,  color:"rgba(0, 0, 0, 0.65)", fontSize:14}}>Use hourly payment when the job requires flexible working hours or ongoing support, allowing IT professionals to be compensated based on the time spent.</Title>
                            {
                            ( budgetType === 'Hourly' || jobPostingData?.job_payment_type === "Hourly") &&
                                <div>
                                    <div className='hourly-budget-ctn' style={{display:"flex", columnGap:56}}>
                                        <div className='mb-1'>
                                            <Paragraph className='input-budget-label'>From</Paragraph>
                                            <Input
                                                name='from' 
                                                label="From" 
                                                className='hourly-budget-input' 
                                                prefix={"$"} 
                                                addonAfter={"/hr"}
                                                value={(budgetType === 'Hourly' || jobPostingData?.job_payment_type === "Hourly") ? jobPostingData?.job_budget_from : null}
                                                onChange={(e) => {
                                                    setJobPostingData({...jobPostingData, job_budget_from: e.target.value.replace(/[^0-9.]/g, '')})
                                                    if(e.target.value < 1){
                                                        setError("Budget must be greater than 0")
                                                    }else{
                                                        setError(null)
                                                    }
                                                }}
                                                
                                            />
                                        </div>
                                        <div>
                                            <Paragraph className='input-budget-label'>To</Paragraph>
                                            <Input
                                                name='to' 
                                                className='hourly-budget-input' 
                                                prefix={"$"} 
                                                addonAfter={"/hr"}
                                                value={(budgetType === 'Hourly' || jobPostingData?.job_payment_type === "Hourly") ? jobPostingData?.job_budget_to : null}
                                                onChange={(e) => {
                                                    setJobPostingData({...jobPostingData, job_budget_to:e.target.value.replace(/[^0-9.]/g, '')})
                                                    if(e.target.value < 1){
                                                        setError("Budget must be greater than 0")
                                                    }else{
                                                        setError(null)
                                                    }
                                                }}
                                                disabled={jobPostingData?.job_budget_from ? false : true}
                                            />
                                        </div>
                                    </div>
                                   { error !== "empty" &&
                                        <Paragraph className='job-budget-error mt-2'>{error}</Paragraph>
                                   }
                                    {/* <Paragraph className='job-budget-subtext mt-2'>This is the average rate for similar projects.</Paragraph> */}
                                </div>
                            }
                            
                            <Radio value="Fixed">Fixed payment</Radio>
                            <Title level={5} style={{fontWeight:400,  color:"rgba(0, 0, 0, 0.65)", fontSize:14}}>Fixed payment can be used for the job that has a well-defined scope or deliverables, providing a predetermined compensation for the completion of specific project milestones or tasks.</Title>
                            {
                                (budgetType === 'Fixed' || jobPostingData?.job_payment_type === "Fixed") &&
                                <div>    
                                    <Paragraph className='input-budget-label'>Project Budget</Paragraph>
                                    <Input
                                        className='fixed-budget-input'
                                        prefix={"$"} 
                                        // addonAfter={"hr"}
                                        value={(budgetType === 'Fixed' || jobPostingData?.job_payment_type === "Fixed") ? jobPostingData.job_budget_fixed : ''}
                                        onChange={(e) => {
                                            setJobPostingData({...jobPostingData, job_budget_fixed: e.target.value.replace(/[^0-9.]/g, '')})
                                            if(e.target.value < 1){
                                                setFixedError("Budget must be greater than 0")
                                            }else{
                                                setFixedError(null)
                                            }
                                        }}
                                    />
                                   { fixedError !== "empty" &&
                                        <Paragraph className='job-budget-error mt-2'>{fixedError}</Paragraph>
                                   }
                                </div>
                            }
                        </Space>
                    </Radio.Group>
            </div>
            {
                (location.pathname === "/account/job-postings/add" && currentStep !== 8) ?
                <div className="job-post-next-btn-ctn">
                    <Button 
                    type='primary' 
                    className='job-post-next-btn' 
                    onClick={handleNextClick}
                    disabled={error && fixedError}
                    >Next</Button>
                </div> : null
            }
        </>
    )
}

export default JobBudget