// Strategic Guidance
import FeatureStrategic1 from '../views/pages/common/public/it-solutions/assets/images/strategic-it-guidance/feature-strategic-1.png'
import FeatureStrategic2 from '../views/pages/common/public/it-solutions/assets/images/strategic-it-guidance/feature-strategic-2.png'
import FeatureStrategic3 from '../views/pages/common/public/it-solutions/assets/images/strategic-it-guidance/feature-strategic-3.png'

// IT Project Management
import FeatureProjectMgt1 from '../views/pages/common/public/it-solutions/assets/images/it-project-management/feature-project-mgt-1.png'
import FeatureProjectMgt2 from '../views/pages/common/public/it-solutions/assets/images/it-project-management/feature-project-mgt-2.png'
import FeatureProjectMgt3 from '../views/pages/common/public/it-solutions/assets/images/it-project-management/feature-project-mgt-3.png'

// Help Desk Support
import FeatureHelpDesk1 from "../views/pages/common/public/it-solutions/assets/images/help-desk-support/feature-help-desk-1.png"
import FeatureHelpDesk2 from "../views/pages/common/public/it-solutions/assets/images/help-desk-support/feature-help-desk-2.png"
import FeatureHelpDesk3 from "../views/pages/common/public/it-solutions/assets/images/help-desk-support/feature-help-desk-3.png"

// Network Administration
import FeatureNetwork1 from "../views/pages/common/public/it-solutions/assets/images/network-administration/feature-network-1.png"
import FeatureNetwork2 from "../views/pages/common/public/it-solutions/assets/images/network-administration/feature-network-2.png"
import FeatureNetwork3 from "../views/pages/common/public/it-solutions/assets/images/network-administration/feature-network-3.png"

//Cybersecurity
import FeatureCybersecurity1 from "../views/pages/common/public/it-solutions/assets/images/cybersecurity/feature-cybersecurity-1.png"
import FeatureCybersecurity2 from "../views/pages/common/public/it-solutions/assets/images/cybersecurity/feature-cybersecurity-2.png"
import FeatureCybersecurity3 from "../views/pages/common/public/it-solutions/assets/images/cybersecurity/feature-cybersecurity-3.png"

//Managed IT Services
import FeatureMngdServices1 from "../views/pages/common/public/it-solutions/assets/images/managed-it-services/feature-mngd-services-1.png"
import FeatureMngdServices2 from "../views/pages/common/public/it-solutions/assets/images/managed-it-services/feature-mngd-services-2.png"
import FeatureMngdServices3 from "../views/pages/common/public/it-solutions/assets/images/managed-it-services/feature-mngd-services-3.png"

//Monitoring and Protection
import FeatureMonitoring1 from "../views/pages/common/public/it-solutions/assets/images/monitoring-protection/feature-monitoring-1.png"
import FeatureMonitoring2 from "../views/pages/common/public/it-solutions/assets/images/monitoring-protection/feature-monitoring-2.png"
import FeatureMonitoring3 from "../views/pages/common/public/it-solutions/assets/images/monitoring-protection/feature-monitoring-3.png"

// Continuous Backups
import FeatureBackup1 from "../views/pages/common/public/it-solutions/assets/images/continous-backups/feature-backup-1.png"
import FeatureBackup2 from "../views/pages/common/public/it-solutions/assets/images/continous-backups/feature-backup-2.png"
import FeatureBackup3 from "../views/pages/common/public/it-solutions/assets/images/continous-backups/feature-backup-3.png"

// Website Management
import FeatureWebsite1 from  "../views/pages/common/public/it-solutions/assets/images/website-management/feature-website-1.png"
import FeatureWebsite2 from  "../views/pages/common/public/it-solutions/assets/images/website-management/feature-website-2.png"
import FeatureWebsite3 from  "../views/pages/common/public/it-solutions/assets/images/website-management/feature-website-3.png"

// ManagedCompliance
import FeatureCompliance1 from  "../views/pages/common/public/it-solutions/assets/images/managed-compliance/feature-compliance-1.png"
import FeatureCompliance2 from  "../views/pages/common/public/it-solutions/assets/images/managed-compliance/feature-compliance-2.png"
import FeatureCompliance3 from  "../views/pages/common/public/it-solutions/assets/images/managed-compliance/feature-compliance-3.png"


export default [
    {
        'route': 'strategic-it-guidance',
        'hero-section': {
            'heading': 'Strategic IT Guidance',
            'sub-heading': "Maximize your IT potential with customized guidance, support, and expertise to help your business navigate the constantly changing world of technology.",
            'image': "https://firebasestorage.googleapis.com/v0/b/tecme-mobile-prod.appspot.com/o/it-solutions-images%2Fbg%20(1).png?alt=media&token=d9d810a4-d453-4602-8952-2065234d3740"
        },
        'middle-section': {
            'heading': 'Navigate Your IT Landscape with Confidence',
            'cards': [
                {
                    'heading': 'Fractional CIO Services',
                    'sub-heading': 'Expert IT leadership to help align your IT strategy with your business needs & goals.',
                    'image': FeatureStrategic1,
                    'checklist': [
                        'Access a IT leader who understands your unique needs and priorities',
                        'Tailored IT strategy that aligns with your business goals and budget',
                        'Improved efficiency and productivity through technology',
                        'Increased competitiveness in your market',
                    ],
                },
                {
                    'heading': 'Comprehensive IT Strategy',
                    'sub-heading': 'Create a comprehensive IT strategy that aligns with your business goals and budget.',
                    'image': FeatureStrategic2,
                    'checklist': [
                       'Prioritize your IT investments and allocate your budget more effectively',
                       'A roadmap for implementing and maintaining your IT solutions ',
                       'Make informed decisions that benefit your business',
                       'Clarify your IT needs and priorities',
                    ],
                },
                {
                    'heading': 'A Team of IT Experts',
                    'sub-heading': 'Expert guidance in all areas of technology, from end-user support to cybersecurity strategy.',
                    'image': FeatureStrategic3,
                    'checklist': [
                       'A partner in your IT journey than can grow as you grow',
                       'Tailored team to match your unique needs and budget',
                       'In-depth knowledge of the latest technologies',
                       'Access the largest network of IT specialists',
                    ],
                },
            ]
        },
        'complimentory-solutions': [
            {
                'heading': 'Cybersecurity',
                'sub-heading': 'Cyber threats are a growing concern for businesses of all sizes. Don\'t wait until it\'s too late to protect your business...',
                'route':"/cybersecurity"
            },
            {
                'heading': 'IT Project Management',
                'sub-heading': 'Effortlessly manage your IT projects with TecMe. With our proven process and specialist network, you can...',
                'route':"/it-project-management"
            },
            {
                'heading': 'Help Desk Support',
                'sub-heading': 'Get the support you need, when you need it. Our team is available 24/7 to provide the support and assistance you need...',
                'route':"/help-desk-support"
            },
            {
                'heading': 'Monitoring & Protection',
                'sub-heading': 'Keep your devices safe and secure 24/7. Our combination of human monitoring and protection software powered by...',
                'route':"/monitoring-and-protection"
            },

        ],
        'meta-data':{
            'meta-title': "Strategic IT Guidance | TecMe - Advanced Affordable IT Guidance",
            'meta-description': "Maximize your technology investments with TecMe's expert Strategic IT Guidance. Ensure your business stays competitive and efficient. Contact us today!",
            'keywords': "msp it solution, msp, strategic IT guidance, technology investments, business success, expert consultation, TecMe, technology strategy, cybersecurity, competitive, efficient."
        }
    },
    {
        'route': 'it-project-management',
        'hero-section': {
            'heading': 'IT Project Management',
            'sub-heading': "Effortlessly manage your IT projects with TecMe. With our proven process and specialist network, you can rest easy knowing that your projects are in good hands.",
            'image': "https://firebasestorage.googleapis.com/v0/b/tecme-mobile-prod.appspot.com/o/it-solutions-images%2Fbg%20(2).png?alt=media&token=f6d8ef22-1bb3-49d1-8577-bda7be80a620"
        },
        'middle-section': {
            'heading': 'Start your next IT project with confidence',
            'cards': [
                {
                    'heading': 'Streamlined Project Management',
                    'sub-heading': 'From project planning to execution, ensures every step is completed on time and on budget.',
                    'image': FeatureProjectMgt1,
                    'checklist': [
                        'A proactive, results-driven approach to project management',
                        'Peace of mind knowing that your projects are in good hands',
                        'Reduced stress and increased productivity for your team',
                        'An efficient and effective project management process',
                    ],
                },
                {
                    'heading': 'Collaborative Approach',
                    'sub-heading': 'Our teams work together to ensure that everyone is on the same page for seamless execution.',
                    'image': FeatureProjectMgt2,
                    'checklist': [
                       'Increased transparency and accountability throughout the project',
                       'A unified approach to problem solving and decision making',
                       'A shared understanding of project goals and objectives',
                       'A positive, productive working relationship',
                    ],
                },
                {
                    'heading': 'Tailored Project Teams',
                    'sub-heading': 'Access a network of IT specialists, allowing us to build a tailored project team for your specific needs.',
                    'image': FeatureProjectMgt3,
                    'checklist': [
                       'Choose from IT specialists to fit your needs',
                       'Better expertise, better decisions',
                       'Utilize a network of IT specialists with a wide range of skills and expertise',
                       'The ability to scale your project team up or down as needed',
                    ],
                },
            ]
        },
        'complimentory-solutions': [
            {
                'heading': 'Cybersecurity',
                'sub-heading': 'Cyber threats are a growing concern for businesses of all sizes. Don\'t wait until it\'s too late to protect your business...',
                'route':'/cybersecurity'
            },
            {
                'heading': 'Strategic IT Guidance',
                'sub-heading': 'Maximize your IT potential with customized guidance, support, and expertise to help your business navigate the...',
                'route':"/strategic-it-guidance"
            },
            {
                'heading': 'Help Desk Support',
                'sub-heading': 'Get the support you need, when you need it. Our team is available 24/7 to provide the support and assistance you need...',
                'route':"/help-desk-support"
            },
            {
                'heading': 'Monitoring & Protection',
                'sub-heading': 'Keep your devices safe and secure 24/7. Our combination of human monitoring and protection software powered by...',
                'route':"/monitoring-and-protection"
            },

        ],
        'meta-data':{
            'meta-title': "IT Project Management | TecMe - Streamline Your Technology Implementations",
            'meta-description': "Ensure successful technology implementations with TecMe's IT Project Management services. Streamline your processes and get the most out of your investments. Contact us today!",
            'keywords': "IT project management, technology implementations, successful, investments, streamline processes, TecMe, technology strategy, expert consultation."

        }
    },
    {
        'route': 'help-desk-support',
        'hero-section': {
            'heading': 'Help Desk Support',
            'sub-heading': "Get the support you need, when you need it. Our team is available 24/7 to provide the support and assistance you need to keep your business running smoothly.",
            'image': "https://firebasestorage.googleapis.com/v0/b/tecme-mobile-prod.appspot.com/o/it-solutions-images%2Fbg%20(3).png?alt=media&token=b9bfc33a-4331-40cb-b142-26300d2611e3"
        },
        'middle-section': {
            'heading': 'say hello to peace of mind',
            'cards': [
                {
                    'heading': 'Round-the-Clock Support',
                    'sub-heading': 'From project planning to execution, ensures every step is completed on time and on budget.',
                    'image': FeatureHelpDesk1,
                    'checklist': [
                        'A proactive, results-driven approach to project management',
                        'Peace of mind knowing that your projects are in good hands',
                        'Reduced stress and increased productivity for your team',
                        'An efficient and effective project management process',
                    ],
                },
                {
                    'heading': 'Experienced IT Specialists',
                    'sub-heading': 'Access a team of experienced IT specialists with years of experience ready to handle all your support needs.',
                    'image': FeatureHelpDesk2,
                    'checklist': [
                       'Know that your IT support needs are in good hands',
                       'Receive detailed explanations and advice',
                       'Reduce the need for in-house IT staff',
                       'Resolve issues quickly and effectively',
                    ],
                },
                {
                    'heading': 'Personalized Support Plans',
                    'sub-heading': 'Our help desk support plans are tailored to meet the unique needs of each business.',
                    'image': FeatureHelpDesk3,
                    'checklist': [
                       'Better alignment of support with business objectives and priorities',
                       'Customized support to meet your specific needs',
                       'Scalability to accommodate changes and growth',
                       'Optimal utilization of resources',
                    ],
                },
            ]
        },
        'complimentory-solutions': [
            {
                'heading': 'Managed IT Services',
                'sub-heading': 'Everything you need to keep your IT systems running smoothly and securely. Stay focused on growing your business while we...',
                'route':'/managed-it-services'
            },
            {
                'heading': 'IT Project Management',
                'sub-heading': 'Effortlessly manage your IT projects with TecMe. With our proven process and specialist network, you can...',
                'route':"/it-project-management"
            },
            {
                'heading': 'Network Administration',
                'sub-heading': 'A comprehensive solution that takes care of all your network management needs. Enjoy seamless network performance...',
                'route':"/network-administration"
            },
            {
                'heading': 'Monitoring & Protection',
                'sub-heading': 'Keep your devices safe and secure 24/7. Our combination of human monitoring and protection software powered by...',
                'route':"/monitoring-and-protection"
            },
        ],
        'meta-data':{
            'meta-title': "Help Desk Support | TecMe - Reliable Technology Solutions for Your Business",
            'meta-description': "Get US-based 24X7 technology solutions for your business with TecMe's Help Desk Support services. Contact us today for seamless support.",
            'keywords': "Help Desk Support, reliable technology solutions, business, TecMe, seamless support, technology strategy."
        }
    },
    {
        'route': 'network-administration',
        'hero-section': {
            'heading': 'Network Administration',
            'sub-heading': "A comprehensive solution that takes care of all your network management needs. Enjoy seamless network performance, increased productivity, and peace of mind.",
            'image': "https://firebasestorage.googleapis.com/v0/b/tecme-mobile-prod.appspot.com/o/it-solutions-images%2Fbg%20(4).png?alt=media&token=3e490f7d-ef98-49f7-a1bf-65e05380dc8f"
        },
        'middle-section': {
            'heading': 'reliable and secure network for your business',
            'cards': [
                {
                    'heading': 'Proactive Monitoring',
                    'sub-heading': 'Continuous monitoring your network ensuring that any problems are detected before they cause downtime.',
                    'image': FeatureNetwork1,
                    'checklist': [
                        'Early detection of network issues',
                        'Minimization of network downtime',
                        'Improved network performance',
                        'Increased productivity',
                    ],
                },
                {
                    'heading': 'Network Optimization',
                    'sub-heading': 'Ensure that your network is configured for optimal performance and stability.',
                    'image': FeatureNetwork2,
                    'checklist': [
                       'Streamlined network management',
                       'Increased network efficiency',
                       'Improved system reliability',
                       'Optimized resource utilization',
                    ],
                },
                {
                    'heading': 'Cybersecurity Protection',
                    'sub-heading': 'Our help desk support plans are tailored to meet the unique needs of each business.',
                    'image': FeatureNetwork3,
                    'checklist': [
                       'Advanced protection against cyber threats',
                       'Compliance with industry security standards',
                       'Safe and secure storage of sensitive data',
                       'Reduced risk of data breaches',
                    ],
                },
            ]
        },
        'complimentory-solutions': [
            {
                'heading': 'IT Project Management',
                'sub-heading': 'Effortlessly manage your IT projects with TecMe. With our proven process and specialist network, you can...',
                'route':"/it-project-management"
            },
            {
                'heading': 'Cybersecurity',
                'sub-heading': 'Cyber threats are a growing concern for businesses of all sizes. Don\'t wait until it\'s too late to protect your business...',
                'route':'/cybersecurity'
            },
            {
                'heading': 'Help Desk Support',
                'sub-heading': 'Get the support you need, when you need it. Our team is available 24/7 to provide the support and assistance you need...',
                'route':"/help-desk-support"
            },
            {
                'heading': 'Managed IT Services',
                'sub-heading': 'Everything you need to keep your IT systems running smoothly and securely. Stay focused on growing your business while we...',
                'route':'/managed-it-services'
            },
        ],
        'meta-data':{
            'meta-title': "Network Administration | TecMe - Streamline Your Network Operations",
            'meta-description': "Get efficient and secure Network Administration services from TecMe to ensure the smooth functioning of your IT infrastructure.",
            'keywords': "Network Administration, streamline operations, business performance, security, TecMe, technology strategy."
        }
    },
    {
        'route': 'cybersecurity',
        'hero-section': {
            'heading': 'Cybersecurity',
            'sub-heading': "Cyber threats are a growing concern for businesses of all sizes. Don't wait until it's too late to protect your business. Get comprehensive and cutting-edge protection against the latest cyber threats.",
            'image': "https://firebasestorage.googleapis.com/v0/b/tecme-mobile-prod.appspot.com/o/it-solutions-images%2Fbg%20(5).png?alt=media&token=c90b9325-12ba-46ae-a034-bd91c9f78aa3"
        },
        'middle-section': {
            'heading': 'Protect Your Business with Confidence',
            'cards': [
                {
                    'heading': 'Advanced Threat Detection',
                    'sub-heading': 'Early identification of potential cyber threats',
                    'image': FeatureCybersecurity1,
                    'checklist': [
                        'Early detection of network issues',
                        'Quick response to security incidents',
                        'Improved protection against cyber attacks',
                        'Minimized damage from security incidents',
                    ],
                },
                {
                    'heading': 'Proactive Monitoring',
                    'sub-heading': 'Real-time network & system monitoring to detect & prevent security incidents.',
                    'image': FeatureCybersecurity2,
                    'checklist': [
                       'Real-time monitoring of your network and systems',
                       'Minimized downtime due to security incidents',
                       'Improved security planning and implementation',
                       'Reduced risk of data breaches',
                    ],
                },
                {
                    'heading': 'Compliance Assistance',
                    'sub-heading': 'Guidance & support to meet industry & government security standards, improving protection & reputation.',
                    'image': FeatureCybersecurity3,
                    'checklist': [
                       'Guidance on industry and government security standards',
                       'Assistance with compliance requirements',
                       'Improved protection against legal and regulatory action',
                       'Enhanced reputation protection',
                    ],
                },
            ]
        },
        'complimentory-solutions': [
            {
                'heading': 'Compliance',
                'sub-heading': 'Stay on top of your HIPAA, CMMC, and PCI  obligations. Automate your compliance processes, stay organized, and...',
                'route':'/managed-compliance'
            },
            {
                'heading': 'Strategic IT Guidance',
                'sub-heading': 'Maximize your IT potential with customized guidance, support, and expertise to help your business navigate the...',
                'route':"/strategic-it-guidance"
            },
            {
                'heading': 'Managed IT Services',
                'sub-heading': 'Everything you need to keep your IT systems running smoothly and securely. Stay focused on growing your business while we...',
                'route':'/managed-it-services'
            },
            {
                'heading': 'IT Project Management',
                'sub-heading': 'Effortlessly manage your IT projects with TecMe. With our proven process and specialist network, you can...',
                'route':"/it-project-management"
            },
        ],
        'meta-data':{
            'meta-title': "Cybersecurity | TecMe - Protect Your Business from Threats",
            'meta-description': "Protect your business from cyber threats with TecMe's Cybersecurity services. Ensure optimal security and privacy for your sensitive information. Contact us today!",
            'keywords': "Cybersecurity, protect business, cyber threats, security, privacy, sensitive information, TecMe, PEN testing, vulnerability scanning, technology strategy."
        }
    },
    {
        'route': 'managed-it-services',
        'hero-section': {
            'heading': 'Managed IT Services',
            'sub-heading': "Everything you need to keep your IT systems running smoothly and securely. Stay focused on growing your business while we handle the technical details.",
            'image': "https://firebasestorage.googleapis.com/v0/b/tecme-mobile-prod.appspot.com/o/it-solutions-images%2Fbg%20(6).png?alt=media&token=dfcc6214-7579-485d-a00a-c5e8c704d465"
        },
        'middle-section': {
            'heading': 'Achieve seamless technology performance',
            'cards': [
                {
                    'heading': 'Remote Monitoring & Maintenance',
                    'sub-heading': 'Keep your systems performing at their best with continuous monitoring and maintenance.',
                    'image': FeatureMngdServices1,
                    'checklist': [
                        'Proactive IT support to prevent issues before they occur',
                        'Routine updates for better performance and security',
                        'Reduced downtime due to technical issues',
                        'Affordable solutions for small to medium businesses',
                    ],
                },
                {
                    'heading': 'Help Desk Support',
                    'sub-heading': 'Get fast and reliable IT support 24/7 with access to certified specialists.',
                    'image': FeatureMngdServices2,
                    'checklist': [
                       'Fast and reliable assistance for IT issues',
                       '24/7 access to certified IT specialists',
                       'Improved productivity and user satisfaction',
                       'Lower IT support costs compared to in-house teams',
                    ],
                },
                {
                    'heading': 'Network & Security Management',
                    'sub-heading': 'Protect your business with continuous threat monitoring and compliance.',
                    'image': FeatureMngdServices3,
                    'checklist': [
                       'All-in-one network and security solutions for business safety',
                       'Regular vulnerability assessments and risk management',
                       'Early detection and response to security threats',
                       'Less breaches, more compliance (HIPAA included)',
                    ],
                },
            ]
        },
        'complimentory-solutions': [
            {
                'heading': 'Cybersecurity',
                'sub-heading': 'Cyber threats are a growing concern for businesses of all sizes. Don\'t wait until it\'s too late to protect your business...',
                'route':'/cybersecurity'
            },
            {
                'heading': 'Compliance',
                'sub-heading': 'Stay on top of your HIPAA, CMMC, and PCI  obligations. Automate your compliance processes, stay organized, and...',
                'route':'/managed-compliance'
            },
            {
                'heading': 'Help Desk Support',
                'sub-heading': 'Get the support you need, when you need it. Our team is available 24/7 to provide the support and assistance you need...',
                'route':"/help-desk-support"
            },
            {
                'heading': 'Monitoring & Protection',
                'sub-heading': 'Keep your devices safe and secure 24/7. Our combination of human monitoring and protection software powered by...',
                'route':"/monitoring-and-protection"
            },
        ],
        'meta-data':{
            'meta-title': "Managed IT Services | TecMe - Optimal Technology Solutions for Your Business",
            'meta-description': "Protect your business with TecMe’s advanced Cybersecurity solutions and secure your sensitive data against cyber threats.",
            'keywords': "Managed IT services, technology solutions, business, TecMe, Cybersecurity, Continuous Backups, Monitoring & Protection, Help Desk Support, Network Administration, Website Management, Managed Compliance."
        }
    },
    {
        'route': 'monitoring-and-protection',
        'hero-section': {
            'heading': 'Monitoring & Protection',
            'sub-heading': "Keep your devices safe and secure 24/7. Our combination of human monitoring and protection software powered by Webroot offers comprehensive security for only $29/month/device",
            'image': "https://firebasestorage.googleapis.com/v0/b/tecme-mobile-prod.appspot.com/o/it-solutions-images%2Fbg%20(7).png?alt=media&token=4aa595ea-a477-4e76-9f9c-329a7e6baf7d"
        },
        'middle-section': {
            'heading': 'Protect Your Devices, Anytime, Anywhere.',
            'cards': [
                {
                    'heading': 'Real-time Threat Detection & Removal',
                    'sub-heading': 'Our technology detects and removes threats in real-time, providing you with uninterrupted protection.',
                    'image': FeatureMonitoring1,
                    'checklist': [
                        'Prevent data theft and loss',
                        'Avoid business disruptions',
                        'Stay protected against the latest threats',
                        'Keep your confidential information safe',
                    ],
                },
                {
                    'heading': '24/7 Monitoring',
                    'sub-heading': 'Our team of experts monitors your devices 24/7, ensuring that your protection never stops.',
                    'image': FeatureMonitoring2,
                    'checklist': [
                       'Receive immediate assistance in case of a threat',
                       'Have peace of mind knowing your devices are always monitored',
                       'Avoid security breaches and potential damages',
                       'Get help whenever you need it, day or night',
                    ],
                },
                {
                    'heading': 'Easy to Use',
                    'sub-heading': 'Our solution is easy to use and requires no technical expertise, making it accessible to everyone.',
                    'image': FeatureMonitoring3,
                    'checklist': [
                       'Stay protected without having to be a tech expert',
                       'Quickly and easily manage your security',
                       'Enjoy a stress-free security experience.',
                       'Save time and hassle with a simple phone call',
                    ],
                },
            ]
        },
        'complimentory-solutions': [
            {
                'heading': 'Continuous Backups',
                'sub-heading': 'A reliable and effortless backup solution ensures your important files and documents are never lost. Enjoy peace of mind...',
                'route':'/continuous-backups'
            },
            {
                'heading': 'Help Desk Support',
                'sub-heading': 'Get the support you need, when you need it. Our team is available 24/7 to provide the support and assistance you need...',
                'route':"/help-desk-support"
            },
            {
                'heading': 'Managed IT Services',
                'sub-heading': 'Everything you need to keep your IT systems running smoothly and securely. Stay focused on growing your business while we...',
                'route':'/managed-it-services'
            },
            {
                'heading': 'Website Management',
                'sub-heading': 'From website updates to security monitoring, our solution offers everything you need to streamline your website...',
                'route':'/website-management'
            },
        ],
        'meta-data':{
            'meta-title': "Monitoring & Protection 24X7 | TecMe - Keep Your Business Safe and Secure",
            'meta-description': "Keep your business safe and secure with TecMe's US-based 24X7 Monitoring & Protection services. Ensure optimal performance and security for your technology. Contact us today!",
            'keywords': "Monitoring & Protection, safe and secure, business, technology performance, security, TecMe, technology strategy."
        }
    },
    {
        'route': 'continuous-backups',
        'hero-section': {
            'heading': 'Continuous Backups',
            'sub-heading': "A reliable and effortless backup solution ensures your important files and documents are never lost. Enjoy peace of mind knowing that your data is always protected.",
            'image': "https://firebasestorage.googleapis.com/v0/b/tecme-mobile-prod.appspot.com/o/it-solutions-images%2Fbg%20(8).png?alt=media&token=d1c4b649-a802-4437-859d-89f848054483"
        },
        'middle-section': {
            'heading': 'Never Lose Your Data',
            'cards': [
                {
                    'heading': 'Automatic Backups',
                    'sub-heading': 'Automatically backs up your data, ensuring that you never have to worry about a manual processes.',
                    'image': FeatureBackup1,
                    'checklist': [
                        'Save time and hassle with automatic backups',
                        'Stay protected against data loss',
                        'Ensure your important files are always safe',
                        'Never forget to backup your data again',
                    ],
                },
                {
                    'heading': 'Real-Time Monitoring',
                    'sub-heading': 'Your data is monitored in real-time, alerting you of any changes or issues.',
                    'image': FeatureBackup2,
                    'checklist': [
                       'Stay informed of any changes to your data',
                       'Quickly address any issues',
                       'Avoid data loss or corruption',
                       'Keep your data secure and protected',
                    ],
                },
                {
                    'heading': 'Effortless Setup',
                    'sub-heading': 'Our solution is easy to use and requires no technical expertise, making it accessible to everyone.',
                    'image': FeatureBackup3,
                    'checklist': [
                       'Protect your data easily with minimal setup and configuration',
                       'Backup stress-free, without technical worries',
                       'Let our experts handle the setup and configuration for you',
                       'Rely on our expertise to ensure your backups are configured properly',
                    ],
                },
            ]
        },
        'complimentory-solutions': [
            {
                'heading': 'Monitoring & Protection',
                'sub-heading': 'Keep your devices safe and secure 24/7. Our combination of human monitoring and protection software powered by...',
                'route':"/monitoring-and-protection"
            },
            {
                'heading': 'Help Desk Support',
                'sub-heading': 'Get the support you need, when you need it. Our team is available 24/7 to provide the support and assistance you need...',
                'route':"/help-desk-support"
            },
            {
                'heading': 'Managed IT Services',
                'sub-heading': 'Everything you need to keep your IT systems running smoothly and securely. Stay focused on growing your business while we...',
                'route':'/managed-it-services'
            },
            {
                'heading': 'Website Management',
                'sub-heading': 'From website updates to security monitoring, our solution offers everything you need to streamline your website...',
                'route':'/website-management'
            },
        ],
        'meta-data':{
            'meta-title': "Continuous Backups | TecMe - Protect Your Critical Data",
            'meta-description': "Protect your critical data with TecMe's 24X7 Continuous Backups services. Ensure data recovery and continuity for your business. Contact us today!",
            'keywords': "Continuous Backups, protect critical data, data recovery, business continuity, TecMe, technology strategy."
        }
    },
    {
        'route': 'website-management',
        'hero-section': {
            'heading': 'Website Management',
            'sub-heading': "From website updates to security monitoring, our solution offers everything you need to streamline your website and ensure it's always running smoothly.",
            'image': "https://firebasestorage.googleapis.com/v0/b/tecme-mobile-prod.appspot.com/o/it-solutions-images%2Fbg%20(9).png?alt=media&token=f4d76af0-7ac5-4658-9a9e-e33fb5309459"
        },
        'middle-section': {
            'heading': 'Take Control of Your Online Presence',
            'cards': [
                {
                    'heading': 'Automated Updates',
                    'sub-heading': 'Ensure your website is always up-to-date and running the latest versions of software and plugins.',
                    'image': FeatureWebsite1,
                    'checklist': [
                        'Ensure your website is secure and running smoothly',
                        'Avoid downtime and compatibility issues',
                        'Stay current with the latest software and plugins',
                        'Keep your website up-to-date',
                    ],
                },
                {
                    'heading': 'Performance Monitoring',
                    'sub-heading': 'Constant monitoring of your website’s performance, including uptime, page speed, and security.',
                    'image': FeatureWebsite2,
                    'checklist': [
                       'Ensure your website is always performing at its best',
                       'Quickly address any performance issues',
                       'Avoid downtime and lost revenue',
                       'Stay on top of website performance and security',
                    ],
                },
                {
                    'heading': 'Content Management',
                    'sub-heading': 'Let our experts handle the website updates for you, so you can focus on your business.',
                    'image': FeatureWebsite3,
                    'checklist': [
                       'Update your website content quickly and easily',
                       'Stay in control of your website content',
                       'No need for developers for updates',
                       'Let us update your website content and save time',
                    ],
                },
            ]
        },
        'complimentory-solutions': [
            {
                'heading': 'Managed IT Services',
                'sub-heading': 'Everything you need to keep your IT systems running smoothly and securely. Stay focused on growing your business while we...',
                'route':'/managed-it-services'
            },
            {
                'heading': 'Cybersecurity',
                'sub-heading': 'Cyber threats are a growing concern for businesses of all sizes. Don\'t wait until it\'s too late to protect your business...',
                'route':'/cybersecurity'
            },
            {
                'heading': 'Help Desk Support',
                'sub-heading': 'Get the support you need, when you need it. Our team is available 24/7 to provide the support and assistance you need...',
                'route':"/help-desk-support"
            },
            {
                'heading': 'Monitoring & Protection',
                'sub-heading': 'Keep your devices safe and secure 24/7. Our combination of human monitoring and protection software powered by...',
                'route':"/monitoring-and-protection"
            },
        ],
        'meta-data':{
            'meta-title': "Website Management | TecMe - Streamline Your Online Presence",
            'meta-description': "Streamline your online presence with TecMe's Website Management services. Ensure optimal performance and functionality for your website. Contact us today!",
            'keywords': "Website Management, online presence, website performance, functionality, TecMe, technology strategy."
        }
    },
    {
        'route': 'managed-compliance',
        'hero-section': {
            'heading': 'Managed Compliance',
            'sub-heading': "Stay on top of your HIPAA, CMMC, and PCI  obligations. Automate your compliance processes, stay organized, and communicate with your team in real-time.",
            'image': "https://firebasestorage.googleapis.com/v0/b/tecme-mobile-prod.appspot.com/o/it-solutions-images%2Fbg%20(10).png?alt=media&token=d36b0c5f-6347-4c4e-96e3-43e75abf2f62"
        },
        'middle-section': {
            'heading': 'Protect Your reputation and budget',
            'cards': [
                {
                    'heading': 'Compliance Automation',
                    'sub-heading': 'Organize, automate, and communicate your compliance using our user-friendly application.',
                    'image': FeatureCompliance1,
                    'checklist': [
                        'Streamline your compliance processes with an automated workflow',
                        'Stay organized with a centralized system',
                        'Improve communication and collaboration within your team',
                        'Reduce manual effort and eliminate the risk of errors',
                    ],
                },
                {
                    'heading': 'Compliance Specialists',
                    'sub-heading': 'Get access to experienced compliance specialists who can help you implement and maintain your compliance.',
                    'image': FeatureCompliance2,
                    'checklist': [
                       'Personalized support from experts who understand your needs',
                       'Ensure that your compliance is implemented correctly',
                       'Get guidance on how to handle complex compliance issues',
                       'Ensure your compliance is maintained over time with ongoing support',
                    ],
                },
                {
                    'heading': 'Compliance Monitoring',
                    'sub-heading': 'Stay up to date with the latest regulations and receive alerts for any changes to your compliance status.',
                    'image': FeatureCompliance3,
                    'checklist': [
                       'Never worry about missing an important update',
                       'Reduce your workload and free up time for more important tasks',
                       'Save money by avoiding costly fines and penalties',
                       'Protect your reputation by demonstrating a commitment to compliance',
                    ],
                },
            ]
        },
        'complimentory-solutions': [
            {
                'heading': 'Cybersecurity',
                'sub-heading': 'Cyber threats are a growing concern for businesses of all sizes. Don\'t wait until it\'s too late to protect your business...',
                'route':'/cybersecurity'
            },
            {
                'heading': 'Managed IT Services',
                'sub-heading': 'Everything you need to keep your IT systems running smoothly and securely. Stay focused on growing your business while we...',
                'route':'/managed-it-services'
            },
            {
                'heading': 'Continuous Backups',
                'sub-heading': 'A reliable and effortless backup solution ensures your important files and documents are never lost. Enjoy peace of mind...',
                'route':'/continuous-backups'
            },
            {
                'heading': 'Monitoring & Protection',
                'sub-heading': 'Keep your devices safe and secure 24/7. Our combination of human monitoring and protection software powered by...',
                'route':"/monitoring-and-protection"
            },
        ],
        'meta-data':{
            'meta-title': "Managed Compliance | TecMe - Ensure Regulatory Compliance for Your Business",
            'meta-description': "At TecMe, our Managed Compliance services offer a one-stop solution for ensuring your business stays up-to-date with industry regulations such as HIPAA, PCI, CMMC, Joint-Commission. Rely on our expertise and advanced technology for a hassle-free compliance experience",
            'keywords': "Managed Compliance, Compliance Management, Regulatory Compliance, Compliance Services, Compliance Solutions, Compliance Support, Compliance Consultancy, TecMe, Compliance Assistance, HIPAA, PCI, CMMC, Compliance Advisory, Compliance Management Systems."
        }
    },
]