// External Imports
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Col, Row, Space, Typography, Modal, Select, Card, Skeleton, notification, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment'

// Internal Imports
import { AvailabilitySection, LocationTechnicians } from '..';
import ROUTES from '../../../../../../utils/routes';
import { SECONDARY_COLOR } from '../../../../../../utils/constants';
import { LocationRepository } from '../../../../customer/manage/locations/repository/location-repository';
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper';

const LoadingCards = () => {
    return(
        <Row gutter={[20, 20]}>
		<Title level={5} style={{margin:"10px 0px 0px 10px"}}>Finding your technicians in this area...</Title>
        { [...Array(2).keys()].map(() => {
            return(
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Card className='tech-loading-card'>
                        <Skeleton avatar active={true} />
                    </Card> 
                </Col>
            )
        }) }
     </Row>
    )
}

const { Paragraph, Title, Text } = Typography
const { TextArea } = Input

const OnSiteVisitModal = ({ isVisible, onOk, onCancel, ticketDetails, ticketRepository, getAndSetTicketDetails, getAndSetActivities }) => {
    const history = useHistory()
    const user = useSelector(state => state.user);
	const [selectedLocation, setSelectedLocation] = useState()
	const [findingTechnicians, setFindingTechnicians] = useState(false)
	const [selectedTechId, setSelectedTechId] = useState()
	const [timeSlot, setTimeSlot]  = useState("")
	const [index, setIndex] = useState(0);
	const [locationsList, setLocationsList] = useState([]);
	const [longLatTechnicians, setLongLatTechnicians] = useState([]);
    const [selectedContract, setSelectedContract] = useState();
    const [filteredContractsList, setFilteredContractsList] = useState([]);
    const [schedule, setSchedule] = useState({});
    const [apiCallInProgress, setApiCallInProgress] = useState(false);
    const [entryInstructions, setEntryInstructions] = useState(' ');
    const [value, onChange] = useState(new Date());

    const getAndSetSchedule = async (techId) => {
		try {
			const res = await ApiBaseHelper.get({ url: `web/tech/${techId}/schedule` });
			setSchedule(res.data['data'])
		} catch (error) {
			// notification.error({
			// 	message: 'TecMe',
			// 	description: 'Fetch schedule failed'
			// })
		}
    }

    const getAndSetLocations = async () => {
        const locationRepository = new LocationRepository(user?.token)
        try {
            const res = await locationRepository.getLocations();
            if(res.data?.data?.length) {
                setLocationsList(res.data.data)
            }
        } catch (error) {
            // notification.error({
			// 	message: 'TecMe',
			// 	description: 'Fetch locations failed'
			// })
        }
    }

    // const getAndSetTechniciansFromLongLat = async () => {
	// 	try {
    //         const location = locationsList?.find(loc => loc?.Id === selectedLocation)
	// 		const res = await ApiBaseHelper.get({
	// 			url: `technician?latitude=${location.Latitude}&longitude=${location.Longitude}&bookingType=home`,
	// 			headers: {
	// 				'Authorization': `Bearer ${user?.token}`
	// 			}
	// 		});

    //         if(res?.data?.data?.length && res.data.data.filter(({ data }) => data.imgUrl && data.imgUrl !== 'TO BE IMPLEMENTED IN MIDDLEWARE')?.length) {
    //             setLongLatTechnicians(res.data.data)
    //         }
	// 	} catch (error) {
	// 		// notification.error({
	// 		// 	message: 'TecMe',
	// 		// 	description: 'Fetch technicians failed'
	// 		// })
	// 	}
	// }

    useEffect(() => {
        if(user) {
            getAndSetLocations();
        }
    }, [])

    useEffect(() => {
      if(selectedLocation && user) {
        // getAndSetTechniciansFromLongLat();
      }
    }, [selectedLocation])

    useEffect(() => {
        if(ticketDetails?.Facility) {
            setSelectedLocation(ticketDetails?.Facility)
        }
    }, [ticketDetails?.Facility])

    useEffect(() => {
        if(!!ticketDetails?.Scheduled_Date_Time) {
            const date = moment.utc(ticketDetails?.Scheduled_Date_Time).local(false).toDate();
            onChange(date)
        }
    }, [ticketDetails?.Scheduled_Date_Time])

    useEffect(() => {
        if(!!ticketDetails?.custom_data?.entry_instructions) {
            setEntryInstructions(ticketDetails?.custom_data?.entry_instructions)
        }
    }, [ticketDetails?.custom_data?.entry_instructions])

    const handleAddressChange = async (value) => {
        if(value === 'new-location') {
            history.push(ROUTES.ADD_LOCATION)
            return
        }
		setSelectedLocation(value)
	};

    const findTechSteps =  [
		{
			name:"technician-list",
			content: () => {
				return (
					<>
						<Title level={5} style={{marginTop:15}}>Local technicians</Title>
						{
							!selectedLocation ? (
                                <div className='choose-location'>Choose a location to see local technicians.</div>
                            ) : findingTechnicians ? (
                                <LoadingCards />
                            ) : (
                                <LocationTechnicians
                                    selectedTechId={selectedTechId}
                                    setSelectedTechId={setSelectedTechId}
                                    longLatTechnicians={longLatTechnicians}
                                    setSelectedContract={(contract) => {
                                        getAndSetSchedule(contract?.Provider_Org?.HIPAA_Primary_Contact)
                                        setSelectedContract(contract)
                                    }}
                                    selectedContract={selectedContract}
                                    filteredContractsList={filteredContractsList}
                                    setFilteredContractsList={setFilteredContractsList}
                                    ticketDetails={ticketDetails}
                                />
                            )
						}
					</>
				)
			}
		},
		{
			name:"availaibility",
			content: () => {
				return (
					<>
						<Title level={5} style={{marginTop:15}}>
                            Choose a date & time within {selectedContract?.Provider_Org?.HIPAA_Contact_Name?.split(" ")?.[0] || selectedContract?.Provider_Org?.Name?.split(" ")?.[0] || ''}’s availability
                        </Title>
						<AvailabilitySection
                            timeSlot={timeSlot}
                            setTimeSlot={setTimeSlot}
                            schedule={schedule}
                            value={value}
                            onChange={onChange}
                        />
                        <Title level={5} className='mt-3 mb-1'>Entry Instructions</Title>
                        <TextArea
                            className='mb-5'
                            value={entryInstructions}
                            onChange={e => setEntryInstructions(e.target.value)}
                            minLength={10}
                            showCount
                            maxLength={500}
                            rows={4}
                        />
					</>
				)
			}
		}
	]

    const handleNext = () => {
		if(index > findTechSteps.length - 1 ){
			return findTechSteps.length - 1 
		}
		setIndex((prevIndex) => prevIndex + 1)
	}

	const handlePrev = () => {
		if(index < 0 ){
			return 0
		}	
		setIndex((prevIndex) => prevIndex - 1)
	}

    const reAssignTicketTech = async () => {
        try {
            const payload = {
                Technician_Contact: selectedContract?.Provider_Org?.HIPAA_Primary_Contact,
                Technician_Name: (selectedContract?.Provider_Org?.HIPAA_Contact_Name || selectedContract?.Provider_Org?.Name),
                Contract: selectedContract?.id
            }
            await ticketRepository?.reassignTicket(ticketDetails?.id, payload)
        } catch (error) {
            // notification.error({
            //     message: 'TecMe',
			// 	description: 'Tech selection failed'
            // })
        }
        
    }

    const handleScheduleClick = async () => {
        const selectedTechId = selectedContract?.Provider_Org?.HIPAA_Primary_Contact;
        const ticketTechId = ticketDetails?.Provider_Contact?.id;
        setApiCallInProgress(true)
        
        if((selectedTechId !== ticketTechId) || ticketDetails?.Contract?.id !== selectedContract?.id) {
            // Reassign ticket to the new tech
            await reAssignTicketTech()
        }

        try {
            const scheduledDate = moment(value);
            const scheduledTime = moment(timeSlot.start);
            const scheduledDateTime = moment(scheduledDate.format('YYYY-MM-DD') + ' ' + scheduledTime.format('HH:mm:s'), 'YYYY-MM-DD HH:mm:s').utc().format();

            const payload = {
                Technician_Contact: selectedContract?.Provider_Org?.HIPAA_Primary_Contact,
                Scheduled_Date_Time: scheduledDateTime,
                Entry_Instructions: entryInstructions,
                Location: selectedLocation
            }

            await ticketRepository?.scheduleOnSiteVisit(ticketDetails?.id, payload);
            await getAndSetTicketDetails()
            await getAndSetActivities()
            setIndex(0)
            onOk()
        } catch (error) {
            // notification.error({
            //     message: 'TecMe',
			// 	description: 'Schedule visit failed'
            // })
        }

        setApiCallInProgress(false)
    }

    return (
        <Modal
			className='find-tech-modal'
			title={<Title level={3} style={{marginBottom:0}}>On-site visit</Title>}
			width={550}
			footer={null}
			visible={isVisible}
			onOk={onOk}
			onCancel={() => {
                setIndex(0)
                onCancel()
            }}
            destroyOnClose
		>
			<Paragraph style={{color:"#000000", fontWeight:400, marginBottom:0}}>
				Schedule an on-site visit with a local technician to resolve this issue by choosing a location, technician, and date/time for the visit.
			</Paragraph>
			<Title level={5} style={{marginTop:10}}>Location</Title>
			<Select
				showSearch
				placeholder="Choose a location"
				optionFilterProp="label"
				onChange={handleAddressChange}
                value={selectedLocation}
				className="location-select-input w-100"
				filterOption={(input, option) => (typeof option?.label === 'string' && option?.label || '').toLowerCase().includes(input.toLowerCase())}
				options={[
				    ...locationsList.map(location => ({
                        value: location.Id,
                        label: `${location.Address_Full || ''}, ${location.City || ''}, ${location.State} ${location.Zip_Code}`
                    })),
                    {
                        value: 'new-location',
                        label: (
                            <Text className='d-flex align-items-center'>
                                <PlusOutlined style={{ color: SECONDARY_COLOR }} className="me-2" />
                                Add a new location
                            </Text>
                        )
                    }
				]}
			>
            </Select>
			<div className='tech-list-location'>
				<div className='tech-list-content'>
					{findTechSteps[index]?.content()}
				</div>
				<div className='tech-location-btn'>
					{index === 1 ? (
                        <Space>
                            <Button
                                className='sch-back-btn'
                                onClick={handlePrev}
                            >
                                Back
                            </Button>
                            <Button
                                disabled={!timeSlot}
                                type='primary'
                                onClick={handleScheduleClick}
                                loading={apiCallInProgress}
                            >
                                Schedule
                            </Button>
                        </Space>
                    ) : (
                        <Button
                            type='primary'
                            onClick={handleNext}
                            disabled={!selectedContract || !Object.keys(schedule).length || !filteredContractsList?.length}
                        >
                            Next
                        </Button>
                    )}
				</div>
			</div>
      	</Modal>
    )
}

export default OnSiteVisitModal