import React from 'react'
import { Avatar, Button, Col, Form, Input, Row, Select, Typography, Upload, notification } from 'antd'
import '../css/styles.scss'
import '../css/company-details.scss'
import { InboxOutlined, UserOutlined } from '@ant-design/icons'
import { INDUSTRIES, TEAM_SIZE } from '../../../../../../utils/company-dropdown'
import { useState } from 'react'
import { canReadPageOnly, getBase64 } from '../../../../../../utils/helper'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import { updateOrganizations, updateUser } from '../../../../../../redux/reducers/app'
import CitiesAndStates from "../../../../../../utils/cities-states-with-lng-lat.json";
import { STATES } from '../../../../../../utils/us-states-city'


const {Title, Paragraph, Text} = Typography
const { Dragger } = Upload;
const { useForm } = Form;

const CompanyDetails = () => {
    const user = useSelector(state => state.user)
    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [profileUpdateMessage, setProfileUpdateMessage] = useState("")
    const [logoUrl, setLogoUrl] = useState(user?.organization?.Logo);
    const [form] = useForm();
    const dispatch = useDispatch();

    const websiteAddon = "https://"
    const websiteUrlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/;

    const props = {
        accept: 'image/*',
        multiple: false,
        showUploadList: false,
        beforeUpload: (file) => {
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                setProfileUpdateMessage('Image must smaller than 5MB!');
            } else {
                getBase64(file, imageUrl =>
                    setLogoUrl(imageUrl)
                );
                setFile(file)
                console.log(file)
                setProfileUpdateMessage(file.name)
            }
            return false;
        },
        onDrop(e) {
           setFile(e.dataTransfer.files[0]);
        },
        onRemove: (file) => {
            setFile(null);
            return true;
        }
    };

    const handleCompanyDetailsSubmit = async (values) => {
        setLoading(true)

        const data = new FormData();

        data.append("Name", values.companyName === null ? "" : values.companyName)
        data.append("Website", values.companyWebsite === null ? "" : `${websiteAddon}${values.companyWebsite.replace(websiteAddon, "").replace("www.", "").replace("http://", "")}`)
        data.append("Description", values.companyDescription === null ? "" : values.companyDescription)
        data.append('file', file)
        data.append("Industry", values.companyIndustry === null ? "" : values.companyIndustry)
        data.append("Company_Size", values.companySize === null ? "" : values.companySize)
        data.append("City", values.city === null ? "" : values.city)
        data.append("State", values.state === null ? "" : values.state)
        data.append("Address", values.street === null ? "" : values.street)
        data.append("Zip_Code", values.zipCode === null ? "" : values.zipCode)
        data.append("Address_2", values.building === null ? "" : values.building)

        ApiBaseHelper.put({
            url: `user-management/organization/${user?.orgId}`,	
            headers: {
                Authorization: 'Bearer ' + user['token'],
            },
            payload: data
        }).then((res) => {
            console.log(res)
            if(res){
                dispatch(updateUser({
                    ...user,
                    organization:{
                        ...user.organization,
                        Name: res?.data?.data?.Name,
                        Website: res?.data?.data?.Website,
                        Description: res?.data?.data?.Description,
                        Industry: res?.data?.data?.Industry,
                        Company_Size: res?.data?.data?.Company_Size,
                        Address: res?.data?.data?.Address,
                        City: res?.data?.data?.City,
                        State: res?.data?.data?.State,
                        Zip_Code: res?.data?.data?.Zip_Code,
                        Address_2: res?.data?.data?.Address_2, 
                        Logo: res?.data?.data?.Logo,   
                    }
                }))

                ApiBaseHelper.get({
                    url: 'user-management/organizations',
                    headers: {
                        Authorization: 'Bearer ' + user?.token,
                    },
                }).then((res) => {
                    console.log(res)
                    dispatch(updateOrganizations(res?.data?.data))
                })
            }
        }).then(() => {
            setLoading(false)
            notification.success({
                message: 'TecMe',
                description: 'Company Details Updated Successfully',
            });

        }).catch((e) => {
            console.log(e)
            setLoading(false)
            notification.error({
                message: 'TecMe',
                description: 'Company Details Update Failed',
            });
        })
    }

    useEffect(() => {
        form.setFieldsValue({
            companyName: user?.organization?.Name,
            companyWebsite: user?.organization?.Website?.replace(websiteAddon, ""),
            companyDescription: user?.organization?.Description,
            companyIndustry: user?.organization?.Industry,
            companySize: user?.organization?.Company_Size,
            state: user?.organization?.State,
            city: user?.organization?.City,
            street: user?.organization?.Address,
            building: user?.organization?.Address_2,
            zipCode: user?.organization?.Zip_Code,
        })
    }, [])

    return (
        <div className="company-details-section settings-tab-ctn">
            <div className="company-details-ctn">
                <div className="company-details-heading">
                    <Title level={4} style={{color:"#101828", fontSize:18, marginBottom:4}}>Company details</Title>
                    <Paragraph style={{color: "rgba(0, 0, 0, 0.65)", margin:0}}>Update your company photo and details here.</Paragraph>
                </div>
                <div className="company-details-form-section">
                    <Form 
                        layout='vertical'
                        form={form}
                        onFinish={handleCompanyDetailsSubmit}
                        >
                        <Row gutter={32} className='company-details-form-item'>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                <Title level={5} className='form-item-title'>Company name</Title>
                                <Paragraph className='form-item-sub-text'>This will be displayed on your profile.</Paragraph>
                            </Col>
                            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                                <Form.Item 
                                    className='mb-3' 
                                    name="companyName" 
                                    rules={[{ required: true, message: 'Please input your company name' }]}>
                                    <Input className='company-details-form-input' disabled={canReadPageOnly(user?.role)} />
                                </Form.Item>
                                <Form.Item 
                                    name="companyWebsite"
                                    rules={[
                                        {   required: true, 
                                            message: 'Please input your company website' 
                                        },
                                        {
                                            pattern: websiteUrlRegex,
                                            message: 'Invalid website URL',
                                        },
                                    ]}
                                    >
                                    <Input className='company-details-form-input' addonBefore={websiteAddon} disabled={canReadPageOnly(user?.role)}/>
                                </Form.Item>
                            </Col>
                        </Row>  
                        <Row gutter={32} className='company-details-form-item'>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                <Title level={5} className='form-item-title'>Logo</Title>
                                <Paragraph className='form-item-sub-text'>Update your company logo.</Paragraph>
                            </Col>
                            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                                <Form.Item
                                
                                >
                                    <Row gutter={[40, 20]} align='top'>
                                        <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                                            <Avatar size={64} src={logoUrl} icon={<UserOutlined />} />
                                        </Col>
                                        <Col xs={24} sm={24} md={21} lg={21} xl={21}>
                                            <Dragger {...props} disabled={canReadPageOnly(user?.role)}>
                                                <p className="ant-upload-drag-icon"><InboxOutlined /></p>
                                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                                <p className="ant-upload-hint">SVG, PNG, JPG or GIF (max. 800x400px)</p>
                                            </Dragger>
                                            <Text style={{color: "rgba(0, 0, 0, 0.65)"}}>{profileUpdateMessage}</Text>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>  
                        <Row gutter={32} className='company-details-form-item'>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                <Title level={5} className='form-item-title'>Description</Title>
                                <Paragraph className='form-item-sub-text'>A quick snapshot of your company.</Paragraph>
                            </Col>
                            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                                <Form.Item 
                                    name="companyDescription"
                                    rules={[
                                    { required: true, message: 'Please input your company description' }, 
                                    { min: 25, message: 'Minimum of 25 characters allowed'},
                                    { max: 300, message: 'Maximum of 300 characters allowed.' },]}
                                >
                                    <Input.TextArea   
                                        showCount
                                        maxLength={300}
                                        className='company-details-form-textarea' 
                                        disabled={canReadPageOnly(user?.role)}
                                        />
                                </Form.Item>
                            </Col>
                        </Row>  
                        <Row gutter={32} className='company-details-form-item'>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                <Title level={5} className='form-item-title'>Industry</Title>
                                <Paragraph className='form-item-sub-text'>Update your company's industry so we can provide relevant recommendations.</Paragraph>
                            </Col>
                            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                                <Form.Item 
                                    name="companyIndustry"
                                    >
                                    <Select className='company-details-form-select'
                                    placeholder="Select industry" disabled={canReadPageOnly(user?.role)}>
                                        {
                                            INDUSTRIES?.map((industry) => (
                                                <Select.Option value={industry} key={industry}>
                                                    {industry}
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>  
                        <Row gutter={32} className='company-details-form-item'>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                <Title level={5} className='form-item-title'>Copmpany size</Title>
                                <Paragraph className='form-item-sub-text'>Update your company's size to ensure accurate customization based on your organization's scale.</Paragraph>
                            </Col>
                            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                                <Form.Item 
                                    name="companySize"
                                    >
                                    <Select className='company-details-form-select'
                                    placeholder="Select company size" disabled={canReadPageOnly(user?.role)}>
                                        {
                                            TEAM_SIZE?.map((size) => (
                                                <Select.Option value={size} key={size}>
                                                    {size}
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>  
                        <Row gutter={32} className='company-details-form-item'>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                <Title level={5} className='form-item-title'>Billing address</Title>
                                <Paragraph className='form-item-sub-text'>Update your billing info for smooth and accurate invoicing and payment processesing.</Paragraph>
                            </Col>
                            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                               <Row gutter={12}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Form.Item 
                                            className='mb-3' 
                                            label="Street Address" 
                                            name="street"
                                        >
						                    <Input className='company-details-form-input' disabled={canReadPageOnly(user?.role)}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Form.Item 
                                            className='mb-3' 
                                            label="Building, floor, suite #" 
                                            name="building"
                                            >
						                    <Input className='company-details-form-input' disabled={canReadPageOnly(user?.role)}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Form.Item 
                                            className='mb-3' 
                                            label="City" 
                                            name="city"
                                        >
						                    <Input className='company-details-form-input' disabled={canReadPageOnly(user?.role)}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Form.Item 
                                            label="State"
                                            name="state"
                                            className='mb-3'
                                            >
                                            <Select className='company-details-form-select'
                                            placeholder="Select state" disabled={canReadPageOnly(user?.role)}>
                                                {
                                                   STATES?.map((state) => (
                                                        <Select.Option value={state?.Code} key={state?.Code}>
                                                            {`(${state?.Code}) ${state?.State}`}
                                                        </Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Form.Item 
                                            className='mb-3' 
                                            label="Zip code" 
                                            name="zipCode"
                                            rules={[
                                                {
                                                    pattern: new RegExp(/^(\d{5})?$/),
                                                    message: "5 digits only"
                                                },
                                            ]}
                                        >
						                    <Input className='company-details-form-input'  maxLength={5} disabled={canReadPageOnly(user?.role)}/>
                                        </Form.Item>
                                    </Col>
                                    {
                                        !canReadPageOnly(user?.role) &&
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Form.Item className='mt-5'>
                                                <Button type="primary" htmlType="submit" loading={loading} disabled={canReadPageOnly(user?.role)}>Save</Button>
                                            </Form.Item>    
                                        </Col>
                                    }
                               </Row>
                            </Col>
                        </Row>  
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default CompanyDetails