import React from 'react';
import {hydrate, render} from 'react-dom';
import './assets/css/index.css';
import App from './app';
import './views/pages/common/public/landing-page/assets/css/styles.css'
import './views/pages/common/public/instant-search/assets/css/index.css'
import store from './redux/store'
import {Provider} from 'react-redux'
import {persistStore} from 'redux-persist'
import {PersistGate} from 'redux-persist/integration/react'

let persistor = persistStore(store);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(<React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>, rootElement);
} else {
    render(<React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>, rootElement);
}
