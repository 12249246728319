import React, {useEffect, useState} from 'react'
import {DEFAULT_PROFILE_PICTURE, META_DESCRIPTION} from '../../../../utils/constants'
import ROUTES from '../../../../utils/routes';
import MetaTags from 'react-meta-tags';
import {
    Button,
    Card,
    Checkbox,
    Col,
    Form,
    Input,
    Layout,
    List,
    message,
    Modal,
    notification,
    Popover,
    Rate,
    Row,
    Skeleton,
    Space,
    Typography
} from 'antd';
import PageHeaderWrapper from '../../../components/page-header-breadcrumbs';
import './assets/css/styles.scss'
import {useHistory, useParams} from 'react-router';
import {ApiBaseHelper} from '../../../../utils/api-base-helper';
import {useSelector} from 'react-redux';
import {DeleteOutlined, EditOutlined, HeartFilled, MessageOutlined} from '@ant-design/icons';
import LocationIcon from './assets/icons/location-icon.png'
import {
    capitalizeFirstLetter,
    convertCentsIntoDollarsString,
    encryptString,
    truncateText
} from '../../../../utils/helper';
import {FirebaseHelper, FirestoreHelper} from '../../../../utils/firebase-helper';
import {RtmRepository} from '../../common/private/real-time-messaging/repository/rtm-repository';
import moment from 'moment';
import CreateListModal from '../saved-profiles-list/components/create-list-modal';
import DeleteListModal from '../saved-profiles-list/components/delete-list-modal';
import CustomRenderEmpty from '../../../components/custom-render-empty';
import EmptyStateImage from './assets/icons/empty-state-details.svg'

const { Content } = Layout
const { Title, Paragraph, Text } = Typography
const { TextArea } = Input
const {useForm} = Form

const SavedProfileDetails = () => {
    const params = useParams()
    const user = useSelector((state) => state.user);
    const [listTechnicians, setListTechnicians] = useState([])
    const [loadingList, setLoadingList] = useState(false)
    const [listDetails, setListDetails] = useState({})
    const [showMessageModal, setShowMessageModal] = useState(false)
    const [sendingMessage, setSendingMessage] = useState(false)
    const [selectedTech, setSelectedTech] = useState(null)
    const [savedLists, setSavedLists] = useState([])
    const [checkedStates, setCheckedStates] = useState([])
    const [showCreateListModal, setShowCreateListModal] = useState(false)
    const rtmRepository = new RtmRepository(user['token']) 
    const [editState, setEditState] = useState(false)
    const [showDeleteListModal, setShowDeleteListModal] = useState(false)
    const [favListContent, setFavListContent] = useState(false)
    const [form] = useForm();
    const history = useHistory()

    const techniciansInSavedList = savedLists && savedLists?.map((list) => list.Technicians.map((ele) => ele))

    const getSavedTechniciansList = () => {
        setLoadingList(true)
        ApiBaseHelper.get({
            url: 'saved-technician/list',
            headers: {
                Authorization: 'Bearer ' + user?.token,
            },
        }).then((res) => {
            setListDetails(res?.data?.data.find((ele) => ele.id === params.id))
            if(!res?.data?.data.find((ele) => ele.id === params.id)?.Technicians?.length || editState){
                setLoadingList(false)
            }
            // setLoadingList(false)
        })
    }

    const getSavedLists = () => {
        ApiBaseHelper.get({
            url: 'saved-technician/list',
            headers: {
                Authorization: 'Bearer ' + user['token'],
            },
        }).then((res) => {
            setSavedLists(res?.data?.data)
        })
    }

    const addTechnicianToList = (payload, content, titleValue) => {
        ApiBaseHelper.post({
            url: `saved-technician/list/${content?.id}`, 
            payload: payload,
            headers: {
                Authorization: 'Bearer ' + user['token'],
            },
        }).then(res => {
            if(res){
                // getSavedTechniciansList()
                setFavListContent(false)
                getSavedLists()
                message.success({
                    content: `You successfully added ${selectedTech?.Full_Name} to ${content?.Group_Name || titleValue} list`,
                    className: 'custom-success-message-alert',
                });

            }
        }).catch((error) => {
            console.log(error)
            message['error']({
                content: "An Error Occurred",
            })
        })
    }

    const removeTechnicianFromList = (content) => {
        ApiBaseHelper.delete({
            url: `saved-technician/list/${content?.id}/technician/${selectedTech?.Hipaa_id}`, 
            headers: {
                Authorization: 'Bearer ' + user['token'],
            },
        }).then(res => {
            if(res){
                // getSavedTechniciansList()
                if(params.id == content.id){
                    setListTechnicians(listTechnicians.filter((item) => (item?.data?.data.Data?.Hipaa_id != selectedTech?.Hipaa_id)))
                }
                setFavListContent(false)
                getSavedLists()
                message.success({
                    content: `You successfully removed ${selectedTech?.Full_Name} from  ${content?.Group_Name} list`,
                    className: 'custom-success-message-alert',
                });
            }
        }).catch((error) => {
            console.log(error)
            message['error']({
                content: "An Error Occurred",
            })
        })
    }
    

    const handleCheckboxChange = (content, index) => (e) => {
        const newState = [...checkedStates];
        newState[index] = e.target.checked;
        setCheckedStates(newState);
        if(e.target.checked === true){ 
            addTechnicianToList({Technician_HIPAA_Contact:selectedTech?.Hipaa_id, Technician_Organization:selectedTech?.Org_id }, content, e.target.value)
        }else{ 
            removeTechnicianFromList(content)
        }
    };

    useEffect(() => {
        if(editState){
            form.setFieldsValue({
                Group_Name: listDetails?.Group_Name,
                Description: listDetails?.Description
            })
        }else{
            form.resetFields()
        }
    }, [editState])

    useEffect(() => {
        getSavedLists()
    }, [])

    useEffect(() => {
        getSavedTechniciansList()
    }, [params?.id])

    useEffect(() => {
        if(listDetails?.Technicians?.length){
            setLoadingList(true)
            Promise.all(
                listDetails?.Technicians.map(ele => {
                  return ApiBaseHelper.get({url: `web/search-tech?contactId=${ele.id}`})
                    .then(response => response)
                    .then(data => data)
                    .catch(error => console.error(error));
                })
              )
                .then(responses => {
                  console.log(responses); // Array of responses
                  setListTechnicians(responses)
                  setLoadingList(false)
                })
                .catch(error =>{ 
                    console.error(error)
                    setLoadingList(false)
                });
        }
    }, [listDetails?.Technicians?.length])

    const loadingListSkeleton = () => {
        return(
            <div className='profile-details-ctn'>
                 <Space className='header-section'>
                    <Skeleton.Input active/>
                    <Space size={24}>
                        <Skeleton.Button active shape='square'/>
                        <Skeleton.Button active shape='square'/>
                    </Space>
                </Space>
                <Skeleton active paragraph={{ rows: 1 }} className="mt-4"/>
                <div className="profile-card-list">
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 1,
                        md: 2,
                        lg: 3,
                        xl: 3,
                        xxl: 3,
                    }}
                    dataSource={[0, 1, 2, 3, 4, 5]}
                    renderItem={item => {
                        return(
                        <List.Item>
                            <Card>
                                <Skeleton avatar paragraph={{ rows: 2 }} active/>
                            </Card>
                        </List.Item>
                    )}}
                />
                </div>
            </div>
        )
    }

    const FavListContent = (
        <div className="fav-list-content">
          <div className="list-item-ctn">
              {
                 savedLists.map((content, i) => { 
                    return(
                      <div onClick={(e) =>  e.stopPropagation()}>
                          <Checkbox value={content?.Group_Name} checked={checkedStates[i]} onChange={handleCheckboxChange(content, i)}>{truncateText(content?.Group_Name, 65)}</Checkbox>
                      </div>
                  )})
              }
          </div>
          <div className="save-new-list" onClick={(e) => {
                setShowCreateListModal(true)
                e.stopPropagation()
            }}>
              + Save in a new list
          </div>
        </div>
      );

    return (
        <>
        <MetaTags>
                <title>Saved Profiles Details - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <Content className="main">
                <div className="page">
                    <Row gutter={[20, 20]}>
                        <Col span={24}>
                            <PageHeaderWrapper
                                routes={[
                                    {
                                        path: ROUTES.SAVED_PROFILES,
                                        breadcrumbName: 'Saved Profiles'
                                    },
                                    {
                                        breadcrumbName: truncateText(capitalizeFirstLetter(listDetails?.Group_Name), 45) 
                                    },
                                ]}
                            />
                        </Col>
                        <Col style={{width:"100%"}}>
                            <div className="auth-container">
                                {
                                    loadingList ? loadingListSkeleton() :
                                    <div className='profile-details-ctn'>
                                        <Space className='header-section'>
                                            <Title className='list-name'>{capitalizeFirstLetter(listDetails?.Group_Name)}</Title>
                                            <Space size={24}>
                                                <EditOutlined style={{color:"rgba(0, 0, 0, 0.45)"}} onClick={() => {
                                                    setEditState(true)
                                                    setShowCreateListModal(true)
                                                }}/>
                                                <DeleteOutlined className='cursor-pointer' style={{color:"rgba(0, 0, 0, 0.45)"}}
                                                onClick={() => {
                                                    setShowDeleteListModal(true)
                                                }}
                                                />
                                            </Space>
                                        </Space>
                                        <Paragraph className='list-details-desc'>{capitalizeFirstLetter(listDetails?.Description)}</Paragraph>
                                        <div className="profile-card-list">
                                        <CustomRenderEmpty 
                                            image={EmptyStateImage}
                                            description={<p className='empty-list-desc'>Looks like you're ready to connect with IT professionals. Click on the "Find IT Professionals" button to discover a talented pool of experts ready to help you with your IT needs. </p>}
                                            extra={
                                                <Button type='primary' href={ROUTES.DISCOVERY_EXPERIENCE}> 
                                                    + Find IT Professionals
                                                </Button>
                                            }>
                                                <List
                                            grid={{
                                                gutter: 16,
                                                xs: 1,
                                                sm: 1,
                                                md: 2,
                                                lg: 3,
                                                xl: 3,
                                                xxl: 3,
                                            }}
                                            dataSource={listTechnicians}
                                            renderItem={item => {
                                                const remoteRate = item?.data?.data.Data?.Provider_Service?.find(s => s["Remote"])?.Remote?.price
                                                const onSiteRate = item?.data?.data.Data?.Provider_Service?.findLast(s => s["Home"])?.Home?.price
                                                let cityState = `${item?.data?.data.Data?.City ? `${item?.data?.data.Data?.City}, ` : ''}${item?.data?.data.Data?.State || ''}`;
                                                return(
                                                <List.Item>
                                                    <Card onClick={() =>  history?.push(ROUTES.TECH_PROFILE_WITH_ID.replace(':id', item?.data?.data.Data?.Hipaa_id))}>
                                                        <Space align='start' className='justify-content-between w-100' wrap>
                                                            <Space size={16} wrap>
                                                                <div className='profile-img'>
                                                                    <img src={item?.data?.data.Data?.imgUrl || DEFAULT_PROFILE_PICTURE}alt="" />
                                                                </div>
                                                                <div className='other-profile-info'>
                                                                    <Title level={5}>{item?.data?.data.Data?.Full_Name}</Title>
                                                                    <div>
                                                                    <Space size={6}>
                                                                        { cityState !== "" && <img style={{marginTop:-2}} src={LocationIcon} alt="location icon" />}
                                                                        <Paragraph>{cityState}</Paragraph>
                                                                    </Space>
                                                                    </div>
                                                                    {
                                                                        !!item?.data?.data.Data?.Average_Rating &&
                                                                        <Space          className="align-items-center">
                                                                            <Rate style={{fontSize:13}} disabled value={item?.data?.data.Data?.Average_Rating} />
                                                                            <Text style={{fontSize:13}}>
                                                                                {Math.round(item?.data?.data.Data?.Average_Rating)}.0
                                                                            </Text>
                                                                        </Space>
                                                                    }
                                                                </div>
                                                            </Space>
                                                            <Space>
                                                                <Popover
                                                                    // open={favListContent}
                                                                    onOpenChange={(favListContent)=>{setFavListContent(favListContent)}}
                                                                    placement="bottomRight" 
                                                                    content={FavListContent} 
                                                                    trigger="click"
                                                                    overlayClassName='saved-tech-popover'
                                                                    arrowPointAtCenter>
                                                                    <div className='icon-ctn'
                                                                    onClick={(e) =>  {
                                                                        setSelectedTech(item?.data?.data.Data)
                                                                        setCheckedStates(techniciansInSavedList.map(subArray => subArray.some((obj) => obj['id'] === item?.data?.data.Data?.Hipaa_id)))
                                                                        e.stopPropagation()
                                                                    }}
                                                                        >
                                                                        <HeartFilled style={{color:"#F04A22", fontSize:15}}/>
                                                                    </div>
                                                                </Popover>
                                                                <div className='icon-ctn' onClick={(e) => {      
                                                                    setShowMessageModal(true)
                                                                    setSelectedTech(item?.data?.data.Data)
                                                                    e.stopPropagation()
                                                                }}>
                                                                    <MessageOutlined style={{color:"#000000D9", fontSize:15}}/>
                                                                </div>
                                                            </Space>
                                                        </Space>
                                                        <div className='saved-profile-card-bio-ctn'>
                                                            {
                                                                item?.data?.data.Data?.Profile_Bio != "undefined" ?
                                                                <Paragraph className='saved-profile-card-bio' ellipsis={{rows:4}}>{item?.data?.data.Data?.Profile_Bio}</Paragraph> : <Paragraph className='unavailable-bio'>Bio Unavailable</Paragraph>
                                                            }
                                                        </div>
                                                        <Space size={24}>
                                                            <Text className='work-option'>On Site {onSiteRate ? (convertCentsIntoDollarsString(onSiteRate) + "/hr") : "N/A"}</Text>
                                                            <Text className='work-option'>Remote {remoteRate ? (convertCentsIntoDollarsString(remoteRate) + "/hr") : "N/A"}</Text>
                                                        </Space>
                                                    </Card>
                                                </List.Item>
                                            )}}
                                        />
                                        </CustomRenderEmpty>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </Content> 
            {/* Create and Edit List Modal */}
            <CreateListModal 
                showCreateListModal={showCreateListModal} 
                setShowCreateListModal={setShowCreateListModal}
                editState={editState}
                setEditState={setEditState}
                listId={listDetails?.id}
                getSavedTechniciansList={getSavedTechniciansList}
                form={form}
                addTechnicianToList={addTechnicianToList}
                isAddTechnician={true}
                selectedTech={selectedTech}
            />

            {/* Delete list modal */}
            <DeleteListModal
                showDeleteListModal={showDeleteListModal}
                setShowDeleteListModal={setShowDeleteListModal}
                listId={params?.id}
                getSavedTechniciansList={getSavedTechniciansList}
                routeAfterDeleting={true}
            />
            {/* Send Message Modal */}
            <Modal
                open={showMessageModal} 
                onOk={() => setShowMessageModal(false)} 
                onCancel={() => {
                    setShowMessageModal(false)
                }}
                width={609}
                footer={null}
                centered
            >
                <div className="message-modal-heading">
                    <Title level={3}>Send a message</Title>
                    <Paragraph>Ask <span>{selectedTech?.Full_Name}</span> a question or share details about your project, position, or support need.</Paragraph>
                </div>
                <Form
                    layout='vertical'
                    form={form}
                    onFinish={async(values) => {
                        setSendingMessage(true)
                        await FirebaseHelper.login();
                        
                       
                        let conversationExists = await FirestoreHelper.getConversationBetweenUsers({
                            user_1: user.id,
                            user_2: selectedTech?.Hipaa_id,
                        });

                        let conversationId =  conversationExists.length > 0 ? conversationExists[0].id : null;

                        if(conversationId === null){
                            await rtmRepository.createConversation(selectedTech?.Hipaa_id).then((response) => {
                                conversationId = response.data.data.id;
                            })
                        }

                        await FirestoreHelper.createUser({
                            hipaa_id: user.id,
                            display_name: `${user['firstname']} ${user['lastname'][0]}.`,
                            profile_picture: user['imageUrl'],
                            role: 'customer'
                        });

                        await FirestoreHelper.createUser({
                            hipaa_id: selectedTech?.Hipaa_id,
                            display_name: selectedTech?.Full_Name,
                            profile_picture: selectedTech?.imgUrl,
                            role: 'technician'
                        });

                        if(conversationExists.length === 0){
                            await FirestoreHelper.createConversation({
                                conversation_id: conversationId,
                                message: encryptString(values.message),
                                participants: [user.id, selectedTech?.Hipaa_id],
                                user_id: user.id
                            });
                        }

                        await FirestoreHelper.sendMessage({
                            conversation_id: conversationId,
                            message: encryptString(values.message),
                            user_id: user.id
                        });

                        rtmRepository.sendMessage({
                            conversationId: conversationId,
                            message: values.message,
                            createdAt: moment().utc().toISOString()
                        }).then((response) => {
                            setSendingMessage(false)
                            setShowMessageModal(false)
                            form.resetFields()
                            notification['success']({
                                message:"TecMe",
                                description: "Message Sent Successfully"
                            })
                        });

                    }}
                >
                    <Form.Item
                        label={<Paragraph style={{color:"rgba(0, 0, 0, 0.85)", fontWeight: 500,
                        fontSize: 16}}>Your Message</Paragraph>}
                        name="message"
                    >
                        <TextArea rows={4}/>
                    </Form.Item>
                    <Form.Item>
                        <Space size={16} className='mt-3 w-100 justify-content-end'>
                            <Button style={{height:40, fontSize:16}} type='secondary' className="neutral-btn" onClick={() => {
                            setShowMessageModal(false)
                            }}>
                                Cancel
                            </Button>
                            <Button type="primary" htmlType="submit" style={{height:40, fontSize:16}} loading={sendingMessage}>
                            Send Message
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default SavedProfileDetails