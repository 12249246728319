import { CheckCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select, Space, Typography } from 'antd'
import React, { useState } from 'react'


const { Title, Paragraph } = Typography
const { Option } = Select

const JobCategory = ({handleNextClick, setSelectedCategoryId, allAvailableSkills, jobPostingData, setJobPostingData, getGeneratedSkills, user}) => {

    const [categoryChanged, setCategoryChanged] = useState(false)
   
    return (
        <div className='job-category-ctn'>
            <div className="job-category-content">
                <div className="job-category-heading">
                    <Space>
                        <CheckCircleOutlined style={{color:"#F04A22", fontSize:22}} />
                        <Title level={4} style={{marginBottom:0, fontSize:20, color:"#000"}}>Great! Now let's narrow it down by selecting a job category</Title>
                    </Space>
                    <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", fontSize:14, marginTop:17}}>Choosing the right job category ensures that your job post reaches the most relevant professionals in the industry. It's an important step towards finding the perfect match for your specific requirements.</Paragraph>
                </div>
                <JobCategoryForm 
                    allAvailableSkills={allAvailableSkills} 
                    setSelectedCategoryId={setSelectedCategoryId}
                    jobPostingData={jobPostingData}
                    setJobPostingData={setJobPostingData}
                    setCategoryChanged={setCategoryChanged}
                />
                <div className="job-post-next-btn-ctn">
                    <Button type='primary' className='job-post-next-btn' onClick={() => {
                        handleNextClick()
                        if(categoryChanged){
                            getGeneratedSkills()
                            setCategoryChanged(false)
                        }
                    }}
                    disabled={!jobPostingData?.job_category}
                    >
                        Next
                    </Button>
                </div>
            </div>
        </div>
    )
}

export const JobCategoryForm = ({setSelectedCategoryId, allAvailableSkills, jobPostingData, setJobPostingData, setCategoryChanged}) => {

    function handleChange(value) {
        setSelectedCategoryId(value)
        setJobPostingData({...jobPostingData, job_category: value})
        setCategoryChanged(true)
    }
   
    function handleSearch(value) {
        console.log(`Searched ${value}`);
    }
 
    return(
        <div className="job-category-form">
        <Select
            popupClassName='select-job-category'
            showSearch
            listHeight={264}
            placeholder="Please select"
            optionFilterProp="children"
            onChange={handleChange}
            onSearch={handleSearch}
            showArrow={false}
            virtual={false}
            size='large'
            value={jobPostingData?.job_category}
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            >
                {
                    allAvailableSkills?.length && allAvailableSkills?.map((skill, index) => {
                        return (
                            <Option className={skill.id === skill.parent_id && 'title-option'} value={skill.id}>{skill.Title}</Option>
                        )
                    })
                }
        </Select>
    </div>
    )
}

export default JobCategory