import { ApiBaseHelper } from "../../../../../../utils/api-base-helper";

export class ChangePasswordRepository {
    constructor(accessToken) {
        this.accessToken = accessToken;
    }

    changePassword(payload) {
        return ApiBaseHelper.post(
            {
                url: `web/v2/change/password`,
                payload,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }



}

