import React, { useState } from 'react'
import { MembershipForm } from '../../create-itc/components/membership'

const ProfileMembership = ({itcDetails, itcId, token}) => {
    const [companyDetails, setCompanyDetails] = useState({
        membership_fee: Number(itcDetails?.Membership_Fee),
        membership_type: itcDetails?.Fee_Type
    })
    return (
        <>
            <MembershipForm 
                companyDetails={companyDetails}
                setCompanyDetails={setCompanyDetails}
                isFromItcProfile={true}
            />
        </>
    )
}

export default ProfileMembership