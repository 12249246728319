import React, {useState} from 'react'
import { AddedItemCard } from './added-item'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import ActionModal from './delete-modal'
import { useSelector } from 'react-redux'
import { Avatar, Button, List, Space, Typography, notification } from 'antd'
import { nameFormat } from '../../../../../../utils/helper'
import '../assets/css/added-item.scss'
import CustomRenderEmpty from '../../../../../components/custom-render-empty'


const {Paragraph} = Typography

const TestimonialList = ({testimonials, setShowAddedTestimonial, fetchTestimonials, handleNextClick, setShowBackBtn, loadingTestimonials, setEditTestimonialData, isFromItcProfile=false}) => {
    const tech = useSelector(state => state.tech)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deletingTestimonial, setDeletingTestimonial] = useState(false)
    const [deleteId, setDeleteId] = useState(null)

    const deleteTestimonial = () => {
        setDeletingTestimonial(true)
        ApiBaseHelper.delete({
            url: `itc/testimonial/${deleteId}`,
            headers: {
                Authorization: `Bearer ${tech.token}`
            },
        }).then((res) => {
            if (res.data) {
                notification['success']({
                    message: 'TecMe',
                    description: "Testimonial deleted successfully",
                });
                fetchTestimonials()
                setDeleteModal(false)
                setDeletingTestimonial(false)
            }
        }).catch((error) => {
            setDeletingTestimonial(false)
            notification['error']({
                message: 'TecMe',
                description: "An error Occurred",
            });
        })
    }
    return (
        <>
            <div className="added-item-list added-testimonial-list">
                <CustomRenderEmpty
                    description={"No Testimonial Added yet"}
                >
                    <List
                        loading={loadingTestimonials}
                        dataSource={testimonials}
                        renderItem={ele => (
                            <List.Item>
                                <AddedItemCard
                                onClose={() => {
                                    setDeleteId(ele.id)
                                    setDeleteModal(true)
                                }}
                                onEdit={() => {
                                    setShowAddedTestimonial(isFromItcProfile ? true : false)
                                    setEditTestimonialData(ele)   
                                }}
                            >
                                <div className="added-testimonial-content">
                                    <div className="top-content-details">
                                        <Space>
                                            <Avatar 
                                                size={38} 
                                                src={ele.img_Url} 
                                                style={{
                                                    background:"##EEE", 
                                                    color:"#F04A22", 
                                                    fontSize:16
                                                }}
                                            >
                                                {ele.Client_First_Name.charAt(0)}
                                            </Avatar>
                                            <Paragraph style={{color: "rgba(0, 0, 0, 0.85)", fontWeight:500, margin:0}}> {nameFormat(`${ele?.Client_First_Name} ${ele?.Client_Last_Name}`)} | {ele?.Client_Title} at {ele?.Client_Company}.</Paragraph>
                                        </Space>
                                    </div>
                                    <div className="bottom-content-details">
                                        <Paragraph style={{margin:0, color:"#000", fontSize:14, fontWeight:300}}>{ele?.Contents}</Paragraph>
                                    </div>
                                </div>
                            </AddedItemCard>
                            </List.Item>
                        )}
                    />
                </CustomRenderEmpty>
            {/* {
                testimonials.map((ele) => {
                    return (
                        <AddedItemCard
                            onClose={() => {
                                setDeleteId(ele.id)
                                setDeleteModal(true)
                            }}
                        >
                            <div className="added-testimonial-content">
                                <div className="top-content-details">
                                    <Space>
                                        <Avatar 
                                            size={38} 
                                            src={ele.img_Url} 
                                            style={{
                                                background:"##EEE", 
                                                color:"#F04A22", 
                                                fontSize:16
                                            }}
                                        >
                                            {ele.Client_First_Name.charAt(0)}
                                        </Avatar>
                                        <Paragraph style={{color: "rgba(0, 0, 0, 0.85)", fontWeight:500, margin:0}}> {nameFormat(`${ele?.Client_First_Name} ${ele?.Client_Last_Name}`)} | {ele?.Client_Title} at {ele?.Client_Company}.</Paragraph>
                                    </Space>
                                </div>
                                <div className="bottom-content-details">
                                    <Paragraph style={{margin:0, color:"#000", fontSize:14, fontWeight:300}}>{ele?.Contents}</Paragraph>
                                </div>
                            </div>
                        </AddedItemCard>
                    )
                })
            } */}
            <Space direction='vertical' className='d-block'>
                <Button 
                    className='add-new-btn'
                    onClick={() => setShowAddedTestimonial(isFromItcProfile ? true : false)}
                >
                    + Add New Testimonial
                </Button>
                {
                    !isFromItcProfile &&
                    <Button 
                        className='itc-next-btn' 
                        type='primary' 
                        onClick={() => handleNextClick()}
                    >
                        Next
                    </Button>
                }
            </Space>
        </div>
        <ActionModal
            visible={deleteModal}
            handleCancel={() => setDeleteModal(false)}
            handleClick={() => deleteTestimonial()}
            message={"This action will permanently delete the testimonial. Please make sure you want to delete the item before proceeding."}
            loading={deletingTestimonial}
            deleteText={"Delete"}
        />
        </>
    )
}

export default TestimonialList