import React from 'react'
import {  Collapse, Input, Radio, Space, Typography } from 'antd'
import '../assets/css/itc-filter.scss'


const {Paragraph} = Typography
const {Panel} = Collapse
const {Search} = Input

const ItcFilter = ({
    workCategories,
    queryParams,
    category,
    setCategory,
    membershipFee,
    setMembershipFee,
    itcLocation,
    setItcLocation,
}) => {
  return (
    <div>
        <div className="discover-itc-category">
            <Collapse defaultActiveKey={["category"]} className='discover-itc-collapse' ghost>
                <Panel header="Category" key="category">
                    {
                        workCategories.map((item, index) => (
                        <Paragraph 
                        className={`discover-itc-category-item ${item?.Title === category && 'discover-itc-category-item-active'}`} 
                        key={index}
                        onClick={() => setCategory(item?.Title)}
                        >
                            {item?.Title}
                        </Paragraph>
                    ))
                    }
                </Panel>
            </Collapse>
        </div>
        {/* <div className="discover-itc-payment">
            <Collapse defaultActiveKey={["payment"]} className='discover-itc-collapse' ghost>
                <Panel header="Membership Fee" key="payment">
                    <div className='fixed'>
                        <div className='fixed-rate-checkboxes'>
                            <Radio.Group
                                onChange={(e) => setMembershipFee(e.target.value)}
                                value={membershipFee}
                            >
                                    <Space direction="vertical">
                                    {
                                        ["Free", "$1 - $100", "$100 - $500", "$500 - $1k", "$1k - $5k", "$5k+"].map((item) => (
                                            <Radio  key={item} value={item}>{item}</Radio>
                                        ))
                                    }
                                </Space>
                            </Radio.Group>
                        </div>
                    </div>
                </Panel>
            </Collapse>
        </div> */}
        <div className="discover-itc-location">
            <Collapse defaultActiveKey={["location"]} className='discover-itc-collapse' ghost>
                <Panel header="Company Location" key="location">
                    <Search
                        placeholder="Search Location"
                        className='search-location-input'
                        onSearch={(value) => {
                            setItcLocation(value)
                        }}
                        onPressEnter={(e) => {
                            setItcLocation(e.target.value)
                        }}
                    />
                </Panel>
            </Collapse>
        </div>
    </div>
  )
}

export default ItcFilter