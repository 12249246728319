import React, { Component, Fragment } from 'react';
import { Row, Col, Typography, Spin, Modal, Form, Checkbox, Button } from 'antd';
import {withRouter} from 'react-router-dom';
import Blub from '../assets/images/blub.png'
import { LoadingOutlined } from "@ant-design/icons";
import Timer from "react-compound-timer";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import { SECONDARY_COLOR } from "../../../../../../utils/constants";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import InfoIcon from "@mui/icons-material/Info";
import { Link } from "react-router-dom";
import ROUTES from '../../../../../../utils/routes';


const { Title, Paragraph, Text } = Typography;

class AcceptBookingModal extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props)
    }
    render() {
       let currentPath = this.props.location.pathname;

        return (
            <Modal 
                //    className="modal-booking"
                   visible={this.props.visible}
                   okText={"Accept"}
                   cancelText={"Cancel"}
                   cancelButtonProps={{ type: 'text' }}
                   onOk={(e)=>{
                       this.formRef.current.submit();
                   }}
                   onCancel={this.props.onClose}
            >
                <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
                <Paragraph className='my-2 disclaimer p-2'>
                { currentPath.includes('contract') ? 'This contract includes legally binding terms and conditions that have significant consequences if breached. Please note that all recurring payments are processed weekly on Saturdays at 12:00 AM CT (US time zone), starting from the week the contract is accepted and service begins. Kindly review the terms and conditions thoroughly before accepting. Are you certain you want to accept this contract proposal?': 'Are you sure you want to accept this booking? Please carefully review the terms and conditions before accepting.'}
                </Paragraph>
                <Paragraph strong>Please confirm the following points:</Paragraph>
                <div>
                    <Form
                        ref={this.formRef}
                        name="accept_booking_form"
                        onFinish={this.props.onAccept}
                    >
                        {
                            this.props.points.map((item, index) => {
                                return <Form.Item key={index} name={`option-${index + 1}`}
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error('Please accept this point')),
                                        },
                                    ]}
                                >
                                    <Checkbox defaultChecked={false} >{item}</Checkbox>
                                </Form.Item>
                            })
                        }
                        {/*{*/}
                        {/*    this.props.type == 'Accept' &&*/}
                        {/*    <Typography.Text >Do you have more questions? <Link to={ROUTES.INBOX} style={{ color: SECONDARY_COLOR }}>Chat Now</Link></Typography.Text>*/}
                        {/*}*/}
                    </Form>
                </div>
            </Modal>
        )
    }
}

export default  withRouter(AcceptBookingModal)