import React, { useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import { DEFAULT_PROFILE_PICTURE, META_DESCRIPTION, SECONDARY_COLOR } from '../../../../../utils/constants'
import { Button, Card, Col, Form, Input, Layout, Modal, Radio, Rate, Row, Space, Tag, Typography, notification } from 'antd'
import './assets/css/styles.scss'
import { BulbOutlined, CheckSquareOutlined, ClockCircleOutlined, CloseOutlined, DeleteOutlined, DislikeOutlined, EditOutlined, EnvironmentOutlined, EyeOutlined, HomeOutlined, LikeOutlined, MessageOutlined, PaperClipOutlined, PushpinOutlined, SyncOutlined } from '@ant-design/icons'
import ROUTES from '../../../../../utils/routes'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import PageFallback from '../../../../components/page-fallback'
import CaretLeft from './assets/icons/caret-left.svg'
import { altNameFormat, capitalizeFirstLetter, encryptString, getDottedTimeFormat, nameFormat, truncateText } from '../../../../../utils/helper'
import HipaaBadge from './assets/icons/hipaa_badge.svg'
import { convertBudget } from '../../../common/private/job-post/utils/helper'
import PageHeaderWrapper from '../../../../components/page-header-breadcrumbs'
import { FirebaseHelper, FirestoreHelper } from '../../../../../utils/firebase-helper'
import { RtmRepository } from '../../../common/private/real-time-messaging/repository/rtm-repository'
import moment from 'moment'


const {Content} = Layout
const {Paragraph, Title, Text} = Typography
const {TextArea} = Input

const ViewApplication = () => {
    const user = useSelector(state => state.user)
    const workCategories = useSelector(state => state.workCategoriesGlobal)
    const [loadingJob, setLoadingJob] = useState(false)
    const [jobApplication, setJobApplication] = useState()
    const [techDetails, setTechDetails] = useState(null)
    const [loadingTechDetails, setLoadingTechDetails] = useState(false)
    const [attachments, setAttachments] = useState([])
    const [shortlisting, setShortlisting] = useState(false)
    const [archiving, setArchiving] = useState(false)
    const [visible, setVisible] = useState(false)
    const [requesting, setRequesting] = useState(false)
    const [showMessageModal, setShowMessageModal] = useState(false)
    const [sendingMessage, setSendingMessage] = useState(false)
    const {job_id, application_id} = useParams()
    const [jobDetails, setJobDetails] = useState(null)

    const history = useHistory()

    const [form] = Form.useForm()
    

    let cityState = `${techDetails?.City ? `${techDetails?.City}, ` : ''}${techDetails?.State || ''}`;
    const rtmRepository = new RtmRepository(user['token'])

    const fetchJobApplication = () => {
        setLoadingJob(true)
        ApiBaseHelper.get({
            url: `job-post/${job_id}/applications`, 
            headers: {
                Authorization: 'Bearer ' + user?.token
            }
        }).then((res) => {
            setJobApplication(res?.data?.data.find((app) => app?.id === application_id))
            setLoadingJob(false)
        }).catch((error) => {
            console.error(error)
            setLoadingJob(false)
        })       
    }

    const fetchTechDetails = () => {
        setLoadingTechDetails(true)
        ApiBaseHelper.get({
            url: `web/search-tech?contactId=${jobApplication?.Applicant_Contact?.id}`, 
            headers: {
                Authorization: 'Bearer ' + user?.token
            }
        }).then((res) => {
            setTechDetails(res?.data?.data?.Data)
            setLoadingTechDetails(false)
        }).catch((error) => {
            console.error(error)
            setLoadingTechDetails(false)
        })       
    }

    const fetchJobDetails = () => {
        ApiBaseHelper.get({
            url: `job-post/client`, 
            headers: {
                Authorization: 'Bearer ' + user?.token
            }
        }).then((res) => {
            setJobDetails(res?.data?.data.find((item) => item.id === job_id))
        }).catch((error) => {
            console.error(error)
        })       
    }


    const fetchAttachments = () => {
        ApiBaseHelper.get({
            url: `job-post/media/${application_id}`, 
            headers: {
                Authorization: 'Bearer ' + user?.token
            }
        }).then((res) => {
            setAttachments(res?.data?.data)
        }).catch((error) => {
            console.error(error)
        })       
    }

    const handleApplicationShortlist = (option) => {
        setShortlisting(true)
        ApiBaseHelper.post({
            url: `job-post/${job_id}/application`,	
            headers: {
                Authorization: 'Bearer ' + user['token'],
            },
            payload: {
                "Application_ID": application_id, 
                "Applicant_Name": techDetails?.Full_Name,
                "Applicant_HIPAA_Contact": techDetails?.Hipaa_id, 
                "Operation": "Shortlist", // Shortlist or Archive
                "Action": option // Add or Remove
            }
        }).then((res) => {
            if(res){
                notification.success({
                    message: 'TecMe',
                    description: option === "Add" ? 'Application Shortlisted Successfully' : 'Application Removed from Shortlist',
                });
                fetchJobApplication()
            }
            setShortlisting(false)
        }).catch((err) => {
            setShortlisting(false)
            notification.error({
                message: 'TecMe',
                description: 'An error occured!',
            });
            console.log(err)
        })
    }

    const handleApplicationArchive = (option) => {
        setArchiving(true)
        ApiBaseHelper.post({
            url: `job-post/${job_id}/application`,	
            headers: {
                Authorization: 'Bearer ' + user['token'],
            },
            payload: {
                "Application_ID": application_id, 
                "Applicant_Name": techDetails?.Full_Name,
                "Applicant_HIPAA_Contact": techDetails?.Hipaa_id, 
                "Operation": "Archive", // Shortlist or Archive
                "Action": option // Add or Remove
            }
        }).then((res) => {
            if(res){
                notification.success({
                    message: 'TecMe',
                    description: option === "Add" ? 'Application Archived Successfully' : 'Application Removed from Archive',
                });
                fetchJobApplication()
            }
            setArchiving(false)
        }).catch((err) => {
            setArchiving(false)
            notification.error({
                message: 'TecMe',
                description: 'An error occured!',
            });
            console.log(err)
        })
    }

    const handleRequestChange = () => {

    }

    // const populateDefaultValue = () => {
    //     const clientName = altNameFormat(`${user?.firstname} ${user?.lastname}`) 
    //     form?.setFieldsValue({
    //         Change: `Hi ${nameFormat(techDetails?.Full_Name)}, could you please change \n\nThank you,\n${ clientName}`
    //     })
    // }

    useEffect(() => {
        // populateDefaultValue()
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        fetchJobApplication()
        fetchJobDetails()
    }, [job_id])

    useEffect(() => {
        fetchAttachments()
    }, [application_id])

    useEffect(() => {
        if(jobApplication){
            fetchTechDetails()
        }
    }, [jobApplication])

    const dataNeededForContract = {
        techProfile: techDetails,
        isHipaaService: techDetails?.Tags === "HIPAA",
        // homeRate: this.homeRate,
        // remoteRate: this.remoteRate,
        specialization:workCategories?.find((item) => item?.id === jobDetails?.Category),
        jobId : job_id,	
        jobTitle: jobDetails?.Title,
        jobType: jobDetails?.Work_Type,
        paymentType: jobDetails?.Payment_Type,
        paymentAmount: jobApplication?.Payment_Amount,
        applicationId: application_id,
        jobDescription: jobDetails?.Description,
        jobSkills: jobDetails?.Skills_Required,
        hire: true,
    }

    const queryParams = {
        hire: true,
    };
  

    const queryString = new URLSearchParams(queryParams).toString();
   
    return (
        <>
            <MetaTags>
                <title>Manage Job Posting - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <PageHeaderWrapper
                routes={[
                    {
                        path: ROUTES.JOB_POSTINGS,
                        breadcrumbName: 'Job Postings',
                    },
                    {
                        path: ROUTES.MANAGE_JOB_POSTING.replace(':id', job_id),
                        breadcrumbName:truncateText(capitalizeFirstLetter(jobDetails?.Title), 22),
                    },
                    {
                        breadcrumbName: 'Application'
                    }
                ]}
            />
            <Content className="main">
                <div className='page'>
                   { 
                        (loadingJob || loadingTechDetails ) ? <PageFallback /> :
                        <div className='auth-container view-application-ctn'>
                            <div className="view-application-heading">
                                <Space size={16} style={{cursor:"pointer"}} onClick={() => window.history.back()}>
                                    <img src={CaretLeft} alt="caret left" />
                                    <Title level={4} style={{margin:0}}>Back to all applications</Title>
                                </Space>
                            </div>
                            {
                                jobApplication?.Status === "Withdrawn"  &&
                                <div className="withdrawn-alert-ctn">
                                    <BulbOutlined style={{fontSize:43, color:SECONDARY_COLOR}} />
                                    <div className="withdrawn-alert-content">
                                        <Paragraph>Application withdrawn by {techDetails?.Full_Name} due to following reason:</Paragraph>
                                        <Text className='sub-text'>{capitalizeFirstLetter(jobApplication?.custom_data?.Withdraw_Reason)}</Text>
                                    </div>
                                </div>
                            }
                            {
                                jobApplication?.Status === "Accepted"  &&
                                <div className="withdrawn-alert-ctn" style={{alignItems:"center"}}>
                                    <BulbOutlined style={{fontSize:43, color:SECONDARY_COLOR}} />
                                    <div className="withdrawn-alert-content">
                                        <Paragraph>{techDetails?.Full_Name} has been hired for this job</Paragraph>
                                    </div>
                                </div>
                            }
                            <div className="application-profile-section">
                                <div className="profile-info">
                                    <div className="profile-img-ctn">
                                        <div className="profile-img">
                                            <img src={techDetails?.imgUrl || DEFAULT_PROFILE_PICTURE} alt="profile image" />
                                        </div>
                                        {
                                            techDetails?.Tags === "HIPAA" && 
                                            <img src={HipaaBadge} className='app-hippa-badge' alt="Hipaa Badge" />
                                        }
                                    </div>
                                    <div className='profile-bio-rating'>
                                        <Title level={2} className='profile-bio-title'>{techDetails?.Full_Name}</Title>
                                        <Paragraph className='profile-bio-code'>Tech Code: {techDetails?.Tech_code}</Paragraph>
                                        <Space size={10} direction='vertical'>
                                            <Space style={{display:"flex"}}>
                                                <EnvironmentOutlined className='profile-bio-icon'/>
                                                <Text className='profile-bio-subinfo'>{cityState !== "" ? cityState : ""}</Text>
                                            </Space>
                                            <Space style={{display:"flex"}}>
                                                <CheckSquareOutlined className='profile-bio-icon'/>
                                                <Text className='profile-bio-subinfo'>Background Checked</Text>
                                            </Space>
                                            <Space style={{display:"flex"}}>
                                                <EnvironmentOutlined className='profile-bio-icon'/>
                                                <Text className='profile-bio-subinfo'>Services Insured</Text>
                                            </Space>
                                            <Space style={{display:"flex"}}>
                                                <Rate style={{color:"#FEB000"}} disabled value={techDetails?.Average_Rating} allowHalf/>
                                                <Text> {(techDetails?.Average_Rating)?.toFixed(1)}</Text>
                                            </Space>
                                        </Space>
                                    </div>
                                </div>
                                <div className="view-app-cta-ctn">
                                    <Space direction='vertical'>
                                       { 
                                            (jobApplication?.Status === "Submitted" && jobDetails?.Status !== "Closed") &&
                                            <Button 
                                                className='view-app-btn'  
                                                size='large' 
                                                type='primary'
                                                onClick={() =>  history.push({
                                                    pathname: `${ROUTES.CREATE_SEND_CONTRACT.replace(":profileUrl", techDetails?.Profile_URL)}`,
                                                    state: dataNeededForContract,
                                                })}
                                            >
                                                Hire Technician
                                            </Button>
                                       }
                                        <Button className='view-app-btn' 
                                            onClick={() => history.push(ROUTES.TECH_PROFILE_WITH_ID.replace(":id", techDetails?.Hipaa_id)) }
                                            icon={<EyeOutlined />}
                                            style={{color:SECONDARY_COLOR}}
                                        >    
                                            View Technician Profile
                                        </Button>
                                        <Button 
                                            className='view-app-btn'  
                                            onClick={() => setShowMessageModal(true)}
                                            icon={<MessageOutlined />}
                                            style={{color:SECONDARY_COLOR}}
                                        >
                                            Send a message
                                        </Button>
                                        {
                                            (jobApplication?.Status === "Submitted" && jobDetails?.Status !== "Closed") &&
                                            <>
                                                { 
                                                    jobApplication?.custom_data?.Shortlist === "false" ?
                                                    <Button className='view-app-btn'  loading={shortlisting} onClick={() => handleApplicationShortlist("Add")}
                                                    icon={<LikeOutlined />}
                                                    style={{color:SECONDARY_COLOR}}
                                                    >
                                                        Shortlist Application
                                                    </Button> :
                                                    <Button 
                                                        className='view-app-btn'  
                                                        loading={shortlisting} 
                                                        onClick={() => handleApplicationShortlist("Remove")}
                                                        icon={<DeleteOutlined />}
                                                        style={{color:SECONDARY_COLOR}}
                                                        >
                                                        Remove from shortlist
                                                    </Button>
                                                }
                                                {
                                                    jobApplication?.custom_data?.Archive === "false" ?
                                                    <Button 
                                                        className='view-app-btn' 
                                                        loading={archiving} 
                                                        onClick={() => handleApplicationArchive("Add")}
                                                        icon={<DislikeOutlined />}
                                                        style={{color:SECONDARY_COLOR}}
                                                    >
                                                        Archive Application
                                                    </Button> :
                                                    <Button 
                                                        className='view-app-btn' 
                                                        loading={archiving} 
                                                        onClick={() => handleApplicationArchive("Remove")}
                                                        icon={<DeleteOutlined />}
                                                        style={{color:SECONDARY_COLOR}}
                                                    >
                                                        Remove from archive
                                                    </Button>
                                                }
                                            </>
                                        }
                                    </Space>
                                </div>
                            </div>
                            <div className="job-application-section">
                                <div className="job-application-title">
                                    <Title level={4}>Job Application</Title>
                                    <Space size={10}>
                                        <Paragraph style={{margin:0}} strong>Proposed Rate: {convertBudget(jobApplication?.Payment_Amount, jobDetails?.Payment_Type)} </Paragraph>
                                        {/* {   
                                            jobApplication?.Status !== "Withdrawn" &&
                                            <Button type='secondary' onClick={() => setVisible(true)}>Request a change</Button>
                                        } */}
                                    </Space>
                                </div>
                                <div className="job-application-content">
                                    {
                                        jobApplication?.Cover_Letter.split('\n').map((line, index) => (
                                            <Paragraph>
                                              {line}
                                            </Paragraph>
                                        ))
                                    }
                                    {
                                        attachments?.data?.length > 0 &&                                    
                                            attachments?.data?.map((item, index) => {
                                                return (
                                                    <div key={item?.id}>
                                                        <Space className="download-cv">
                                                            <PaperClipOutlined style={{color:"rgba(0, 0, 0, 0.45)", position:"relative", top:2}}/>
                                                            <a href={item?.Body} download>{item?.Title}</a>
                                                        </Space>
                                                    </div>
                                                )
                                            })    
                                    }
                                </div>
                            </div> 
                        </div>
                    }
                </div>
            </Content>

            {/* Send a message modal */}
            <Modal
                open={showMessageModal}
                onOk={() => {
                    form.submit()
                }}
                onCancel={() => {
                    setShowMessageModal(false)
                }}
                okText={"Send Message"}
                cancelText={"Cancel"}
                width={609}
                confirmLoading={sendingMessage}
                // footer={null}
                centered
            >
                <div className="message-modal-heading">
                    <Title level={3}>Send a message</Title>
                    <Paragraph>Ask <span>{techDetails?.Full_Name}</span> a question or share details about your project, position, or support need.</Paragraph>
                </div>
                <Form
                    layout='vertical'
                    form={form}
                    onFinish={async(values) => {
                        setSendingMessage(true)
                        await FirebaseHelper.login();

                        let conversationExists = await FirestoreHelper.getConversationBetweenUsers({
                            user_1: user.id,
                            user_2: techDetails?.Hipaa_id,
                        });

                        let conversationId =  conversationExists.length > 0 ? conversationExists[0].id : null;

                        if(conversationId === null){
                            await rtmRepository.createConversation(techDetails?.Hipaa_id).then((response) => {
                                conversationId = response.data.data.id;
                            })
                        }

                        await FirestoreHelper.createUser({
                            hipaa_id: user.id,
                            display_name: `${user['firstname']} ${user['lastname'][0]}.`,
                            profile_picture: user['imageUrl'],
                            role: 'customer'
                        });

                        await FirestoreHelper.createUser({
                            hipaa_id: techDetails?.Hipaa_id,
                            display_name: techDetails?.Full_Name,
                            profile_picture: techDetails?.imgUrl,
                            role: 'technician'
                        });

                        if(conversationExists.length === 0){
                            await FirestoreHelper.createConversation({
                                conversation_id: conversationId,
                                message: encryptString(values.message),
                                participants: [user.id, techDetails?.Hipaa_id],
                                user_id: user.id
                            });
                        }

                        await FirestoreHelper.sendMessage({
                            conversation_id: conversationId,
                            message: encryptString(values.message),
                            user_id: user.id
                        });

                        rtmRepository.sendMessage({
                            conversationId: conversationId,
                            message: values.message,
                            createdAt: moment().utc().toISOString()
                        }).then((response) => {
                            setSendingMessage(false)
                            setShowMessageModal(false)
                            form.resetFields()
                            notification['success']({
                                message:"TecMe",
                                description: "Message Sent Successfully"
                            })
                        });

                    }}
                >
                    <Form.Item
                        label={<Paragraph style={{color:"rgba(0, 0, 0, 0.85)", fontWeight: 500,
                            fontSize: 16}}>Your Message</Paragraph>}
                        name="message"
                    >
                        <TextArea rows={4}/>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Request a change modal */}
            <Modal
                visible={visible}
                onCancel={() => setVisible(false)}
                // onOk={handleRequestChange}
                okText="Request a change"
                cancelText="Cancel"
                cancelButtonProps={{ type: 'secondary' }}
                okButtonProps={{ type: 'primary', loading:requesting, htmlType:"submit", form:'request-change-form' } }
                centered
            >
                <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
                <Paragraph className='my-2 disclaimer p-2'>
                    Are you sure you want to request a change? This request will be sent as a chat message to the customer
                </Paragraph>
                <Paragraph strong>What would you like changed in the job application?</Paragraph>
                <Form
                    name='request-change-form'
                    form={form}
                    onFinish={handleRequestChange}
                >
                    <Form.Item
                        // initialValue="please enter the change"
                        name="Change"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the message'
                            }
                        ]}
                    >
                        <TextArea
                            rows={6}
                            showCount
                            maxLength={250}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default ViewApplication