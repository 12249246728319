// External Imports
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Input, Button, notification, Typography, Select, Row, Col, Checkbox } from "antd";
import moment from "moment"

// Internal Imports
import { getSpecificYears } from "../../../../../../utils/helper";

// Assets Imports
import "../css/add-edit-modal.css"

const { Item } = Form;
const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography

const AddEditModal = ({
  isOpen = false,
  closeAddEditModal,
  data = undefined,
  mode = undefined,
  addEditModalInfoKey = undefined,
  editProfileRepository,
  updatedWorkHistoryAndProjects
}) => {
    const [form] = Form.useForm();
    const tech = useSelector((state) => state.tech);
    const [isAPICalled, setIsAPICalled] = useState(false)
    const [isEndMonthAndYearDisabled, setIsEndMonthAndYearDisabled] = useState(true)

    useEffect(() => {
      if(data) {
        let dataToFill = {
          Name: data.Name,
          Organization_Name: data.Organization_Name,
          Description: data.Description,
          startmonth: moment(data.Start_Date).format('MMMM'),
          startyear: moment(data.Start_Date).format("YYYY"),
          present: data.End_Date ? false : true
        }
        if(data.End_Date) {
          dataToFill = {
            ...dataToFill,
            endmonth: moment(data.End_Date).format('MMMM'),
            endyear: moment(data.End_Date).format("YYYY")
          }
        }
        form.setFieldsValue(dataToFill)
        setIsEndMonthAndYearDisabled(data.End_Date ? false : true)
      } else {
        form.setFieldsValue({
          present: true
        })
      }
    }, [])

  const handleOnValuesChange = (values) => {
    if("present" in values) setIsEndMonthAndYearDisabled(values.present)
  }

  return (
    <Modal
      destroyOnClose
      title={addEditModalInfoKey === 'workHistory' ? `${mode === "Add" ? "Add": "Edit"} Work History` : `${mode === "Add" ? "Add": "Edit"} IT Project`}
      visible={isOpen}
      footer={null}
      onCancel={() => {
        form?.setFieldsValue({
            Name: "",
            Organization_Name: "",
            Description: "",
            startmonth: "",
            startyear: "",
            endmonth: "",
            endyear: "",
        })
        closeAddEditModal()
      }}
    >
      <Form
        form={form}
        layout="vertical"
        size="large"
        onValuesChange={handleOnValuesChange}
        onFinish={(values) => {
            setIsAPICalled(true);
            let Start_Date = ""
            let End_Date = ""
            if(addEditModalInfoKey === 'workHistory') {
              const sMonth = values.startmonth
              const sYear = values.startyear
              const eMonth = values.endmonth
              const eYear = values.endyear
              Start_Date = `${sYear}-${moment().month(sMonth).format("MM")}-01`
              End_Date = isEndMonthAndYearDisabled ? "" : `${eYear}-${moment().month(eMonth).format("MM")}-01`
              delete values.startmonth
              delete values.startyear
              delete values.endmonth
              delete values.endyear
              delete values.present
            }
            if(mode === "Add") {
                editProfileRepository.addWorkHistoryAndProjects({
                    ...values,
                    Active: 'true',
                    Organization: tech.orgId,
                    Start_Date,
                    End_Date,
                    Tags: addEditModalInfoKey === 'workHistory' ? "work_history" : 'project'
                }).then(() => {
                    setIsAPICalled(false);
                    notification.success({
                        message: 'TecMe',
                        description: `${addEditModalInfoKey === 'workHistory' ? "Work history" : 'IT project'} added successfully`
                    })
                    closeAddEditModal()
                    form?.setFieldsValue({
                        Name: "",
                        Organization_Name: "",
                        Description: "",
                        startmonth: "",
                        startyear: "",
                        endmonth: "",
                        endyear: "",
                    })
                    updatedWorkHistoryAndProjects()
                }).catch(err => {
                    console.error(err);
                    notification.error({
                        message: 'TecMe',
                        description: `Failed to add ${addEditModalInfoKey === 'workHistory' ? "Work history" : 'IT project'}, please try again`
                    })
                    setIsAPICalled(false);
                })
            } else {
                const id = data.id
                delete data.id
                delete data.Organization
                editProfileRepository.changeWorkHistoryAndProjects(id, {
                    ...data,
                    ...values,
                    Start_Date,
                    End_Date,
                }).then(() => {
                    setIsAPICalled(false);
                    notification.success({
                        message: 'TecMe',
                        description: `${addEditModalInfoKey === 'workHistory' ? "Work history" : 'IT project'} edited successfully`
                    })
                    closeAddEditModal()
                    form?.setFieldsValue({
                        Name: "",
                        Organization_Name: "",
                        Description: "",
                        startmonth: "",
                        startyear: "",
                        endmonth: "",
                        endyear: "",
                    })
                    updatedWorkHistoryAndProjects()
                }).catch(err => {
                    console.error(err);
                    notification.error({
                        message: 'TecMe',
                        description: `Failed to edit ${addEditModalInfoKey === 'workHistory' ? "work history" : 'IT project'}, please try again`
                    })
                    form?.setFieldsValue({
                        ...values
                    })
                    setIsAPICalled(false);
                })
            }
        }}
      >
        <Item
          name="Name"
          label={addEditModalInfoKey === 'workHistory' ? "Job Title" : "Project Title"}
          rules={[
            {
              required: true,
              message: `Please enter your ${addEditModalInfoKey === 'workHistory' ? "job title" : "project title"}`,
            },
          ]}
        >
          <Input placeholder={`Enter your ${addEditModalInfoKey === 'workHistory' ? "job title" : "project title"}`} />
        </Item>
        {addEditModalInfoKey === 'workHistory' && (
            <Item
              name="Organization_Name"
              label="Company Name"
              rules={[
                  {
                  required: true,
                  message: "Please enter your company name",
                  },
              ]}
            >
              <Input placeholder="Enter company name" />
            </Item>
        )}
        <Item
          name="Description"
          label={addEditModalInfoKey === 'workHistory' ? "Job Description" : "Project Description"}
          rules={[
            {
              required: true,
              message: `Please enter your ${addEditModalInfoKey === 'workHistory' ? "job description" : "project description"}`,
            },
            {
              min: 10,
              message: 'It should be at least 10 characters or more',
            },
            {
              max: 255,
              message: 'Maximum 255 characters allowed',
            },
          ]}
        >
          <TextArea placeholder={`Enter your ${addEditModalInfoKey === 'workHistory' ? "job description" : "project description"}`} rows={3} showCount />
        </Item>
        {addEditModalInfoKey === 'workHistory' && (
          <>
            <Row gutter={[12,12]}>
              <Col span={24}>
                <Text className="ant-required-icon-copy">Start Date</Text>
              </Col>
              <Col xs={24} md={12}>
                <Item
                  name="startmonth"
                  rules={[
                      {
                        required: true,
                        message: "Please select start month",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const sYear = getFieldValue("startyear")
                          const eMonth = getFieldValue("endmonth")
                          const eYear = getFieldValue("endyear")
                          if(!sYear || !eMonth || !eYear) {
                            return Promise.resolve();
                          }

                          const sMonthInNum = moment().month(value).format("MM")
                          const eMonthInNum = moment().month(eMonth).format("MM")
                          if(moment(`${sYear}-${sMonthInNum}-01`).isSameOrBefore(`${eYear}-${eMonthInNum}-01`)) {
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error('It should be equal or less than end month'));
                        },
                      }),
                  ]}
                >
                  <Select placeholder="Month">
                    {moment?.months()?.map(month => <Option key={month}>{month}</Option>)}
                  </Select>
                </Item>
              </Col>
              <Col xs={24} md={12}>
                <Item
                  name="startyear"
                  rules={[
                      {
                        required: true,
                        message: "Please select start year",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const eYear = getFieldValue("endyear")
                          if(!eYear) {
                            return Promise.resolve();
                          }

                          if(Number(eYear) >= Number(value)){
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error('It should be less than end year'));
                        },
                      }),
                  ]}
                >
                  <Select
                    placeholder="Year"
                    showSearch
                    filterOption
                  >
                    {getSpecificYears(100)?.map(year => <Option key={year}>{year}</Option>)}
                  </Select>
                </Item>
              </Col>
            </Row>
            <Row gutter={[12,12]}>
              <Col span={24}>
                <Text className={isEndMonthAndYearDisabled ? "" : "ant-required-icon-copy"}>End Date</Text>
              </Col>
              <Col xs={24} md={12}>
                <Item
                  name="endmonth"
                  rules={[
                      {
                        required: !isEndMonthAndYearDisabled,
                        message: "Please select end month",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const sMonth = getFieldValue("startmonth")
                          const sYear = getFieldValue("startyear")
                          const eYear = getFieldValue("endyear")
                          if(!sYear || !sMonth || !eYear) {
                            return Promise.resolve();
                          }

                          const sMonthInNum = moment().month(sMonth).format("MM")
                          const eMonthInNum = moment().month(value).format("MM")
                          if(moment(`${sYear}-${sMonthInNum}-01`).isSameOrBefore(`${eYear}-${eMonthInNum}-01`)) {
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error('It should be equal or high than start month'));
                        },
                      }),
                  ]}
                >
                  <Select placeholder="Month" disabled={isEndMonthAndYearDisabled}>
                    {moment?.months()?.map(month => <Option key={month}>{month}</Option>)}
                  </Select>
                </Item>
              </Col>
              <Col xs={24} md={12}>
                <Item
                  name="endyear"
                  rules={[
                      {
                        required: !isEndMonthAndYearDisabled,
                        message: "Please select end year",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const sYear = getFieldValue("startyear")
                          if(!sYear || isEndMonthAndYearDisabled) {
                            return Promise.resolve();
                          }

                          if(Number(sYear) <= Number(value)){
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error('It should be greater than start year'));
                        },
                      }),
                  ]}
                  
                >
                  <Select
                    placeholder="Year"
                    disabled={isEndMonthAndYearDisabled}
                    showSearch
                    filterOption
                  >
                    {getSpecificYears(100)?.map(year => <Option key={year}>{year}</Option>)}
                  </Select>
                </Item>
              </Col>
            </Row>
            <Item name="present" valuePropName="checked">
              <Checkbox>I am currently working in this role</Checkbox>
            </Item>
          </>
        )}
        <div className="d-flex justify-content-end">
          <Button className="mt-2" type="primary" size="middle" htmlType="submit" loading={isAPICalled}>
            {mode}
          </Button>
        </div>        
      </Form>
    </Modal>
  );
};

export default AddEditModal;
