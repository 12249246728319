import React, { useState } from 'react';
import { Input, Radio, Space, Form, Button, Rate, Typography } from 'antd';

const { Item } = Form
const { TextArea } = Input
const { Text } = Typography

const ReasonRadioList = ({ points = [], handleOnFinish, name, loading, feedbackLabel, rating, setRating, onCancel,  setShowEndContractContent }) => {
  const [value, setValue] = useState(undefined);

  return (
      <Form
            layout="vertical"
            onFinish={(values) => {
                handleOnFinish({ Reason: values.Radio !== 'other' ? values.Radio : values.Reason  })
            }}
            onFinishFailed={error => console.error(error)}
            onValuesChange={(values) => {
                if('Radio' in values) setValue(values.Radio)
            }}
            className="w-100"
        >
        <Space direction='vertical' className="w-100">
            <Item
                name="Radio"
                rules={[
                    {
                        required: true,
                        message: 'Please pick the reason'
                    }
                ]}
            >
                <Radio.Group>
                    <Space direction="vertical">
                        {points.map(point => <Radio value={point} key={point}>{point}</Radio>)}
                        <Radio value='other'>Other</Radio>
                    </Space>
                </Radio.Group>
            </Item>
            {value === 'other' && (
                <Item
                    name="Reason"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter the reason'
                        },
                        {
                            min: 25,
                            message: 'It should be at least 25 characters or more.',
                        }
                    ]}
                >
                    <TextArea
                        placeholder='Please specify the reason'
                        rows={4}
                        showCount
                        maxLength={250}
                    />
                </Item>
            )}
            {/* {name === "End" && (
                <>
                    <Item
                        label={feedbackLabel}
                        name="Feedback"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter the feedback'
                            },
                            {
                                min: 25,
                                message: 'It should be at least 25 characters or more.',
                            }
                        ]}
                    >
                        <TextArea
                            placeholder='Please specify the feedback'
                            rows={4}
                            showCount
                            maxLength={250}
                        />
                    </Item>
                    <Space>
                        <Rate value={rating} onChange={newRating => setRating(newRating)} /> 
                        <Text>{rating}.0</Text>
                    </Space>
                </>
            )} */}
            <Item>
                <Space className='mt-3 w-100 justify-content-end'>
                    <Button 
                        type='text' 
                        onClick={() => {
                            onCancel()
                            setShowEndContractContent(false)
                            
                        }}
                    >
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        {name}
                    </Button>
                </Space>
            </Item>
        </Space>
    </Form>
  );
};

export default ReasonRadioList;