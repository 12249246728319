import { notification } from "antd";
import { ApiBaseHelper } from "../../../../../../../utils/api-base-helper";

export class ContractRepository {
    constructor(accessToken) {
        this.accessToken = accessToken;
    }

    getContract(contractId, role) {
        return ApiBaseHelper.get(
            {
                url: `contract/${contractId}?role=${role}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    rejectProposal(contractId, payload) {
        return ApiBaseHelper.post(
            {
                url: `contract/${contractId}/reject`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },
                payload
            }
        )
    }

    withdrawProposal(contractId, payload) {
        return ApiBaseHelper.post(
            {
                url: `contract/${contractId}/withdraw`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },
                payload
            }
        )
    }

    acceptProposal(contractId, payload) {
        return ApiBaseHelper.post(
            {
                url: `contract/${contractId}/accept`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },
                payload
            }
        )
    }

    requestAChange(contractId, payload) {
        return ApiBaseHelper.post(
            {
                url: `contract/${contractId}/request-change`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },
                payload
            }
        )
    }

    updateContract(contractId, payload) {
        return ApiBaseHelper.put(
            {
                url: `contract/${contractId}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },
                payload
            }
        )
    }

    terminateContract(contractId, role, payload) {
        return ApiBaseHelper.post(
            {
                url: `contract/${contractId}/terminate?role=${role}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },
                payload
            }
        )
    }

    getActivities(contractId) {
        return ApiBaseHelper.get(
            {
                url: `event-log/${contractId}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    getTimeEntryLogs(timeEntryId) {
        return ApiBaseHelper.get(
            {
                url: `event-log/${timeEntryId}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    getDevices() {
        return ApiBaseHelper.get({
            url: "device",
            headers: {
                Authorization: "Bearer " + this.accessToken,
            },
        });
    }

    addSupportTicket(payload) {
        return ApiBaseHelper.post(
            {
                url: `ticket`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },
                payload
            }
        )
    }

    editSupportTicket(payload, id) {
        return ApiBaseHelper.put(
            {
                url: `ticket/${id}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },
                payload
            }
        )
    }

    getTimesheets(contractId) {
        return ApiBaseHelper.get({
            url:`contract/${contractId}/timesheet`,
            headers: {
                Authorization: "Bearer " + this.accessToken,
            },
        });
    }

    addManualTime(contractId,payload) {
        return ApiBaseHelper.post(
            {
                url: `contract/${contractId}/manual-time`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },
                payload
            }
        )
    }

    updateTimeEntry(timeEntryId,payload) {
        return ApiBaseHelper.put(
            {
                url: `contract/${timeEntryId}/time-entry`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },
                payload
            }
        )
    }

    getTickets(contractId) {
        return ApiBaseHelper.get(
            {
                url: `ticket/?contract=${contractId}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    getInvoices(contractId) {
        return ApiBaseHelper.get(
            {
                url: `contract/${contractId}/invoices`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    async getUpcommingInvoice(contractId) {
        try {
            return await ApiBaseHelper.get(
                {
                    url: `contract/${contractId}/upcoming/invoice`,
                    headers: {
                        Authorization: 'Bearer ' + this.accessToken
                    }
                }
            )
        } catch (error) {
            // notification.error({
			// 	message: 'TecMe',
			// 	description: 'Get invoice failed'
			// })
        }
    }

    async getTechnicianCardPaymentCapabilities() {
        try {
            return await ApiBaseHelper.post(
                {
                    url: "technician/card-payment/capability",
                    headers: {
                        Authorization: 'Bearer ' + this.accessToken
                    }
                }
            )
        } catch (error) {
            return error?.response?.data?.message
            // notification.error({
			// 	message: 'TecMe',
			// 	description: 'Get technician stripe capabilities failed'
			// })
        }
    }

    pauseContract(contractId, payload) {
        return ApiBaseHelper.post(
            {
                url: `contract/${contractId}/pause`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },
                payload
            }
        )
    }

    resumeContract(contractId, payload) {
        return ApiBaseHelper.post(
            {
                url: `contract/${contractId}/resume`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },
                payload
            }
        )
    }

    fileDispute(payload) {
        return ApiBaseHelper.post(
            {
                url: "contract/dispute",
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },
                payload
            }
        )
    }

    reportIssue(payload) {
        return ApiBaseHelper.post(
            {
                url: "contract/report-issue",
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },
                payload
            }
        )
    }

    getTimeSheetTotal(contractId) {
        return ApiBaseHelper.get(
            {
                url: `contract/${contractId}/timesheet/total`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },
            }
        )
    }
}

