// External Imports
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Input, Button, notification, Row, Col, Tag, Space,Typography } from "antd";

// Internal Imports
import { ApiBaseHelper } from "../../../utils/api-base-helper";

const { Item } = Form;
const { TextArea } = Input;

const getFormRules = (value) => [
  {
    required: true,
    message: `Please enter your ${value}`,
  },
];

const ContactModal = ({
  isContactModalVisible = false,
  closeContactModal,
  metadata = false,
  isSearch = false
}) => {
  const [form] = Form.useForm();
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [filters, setFilters] = useState([]);
  const user = useSelector((state) => state.user);
  const tech = useSelector((state) => state.tech);
  const isBrandVisible = filters?.find(filter => filter.includes("Other"))

  useEffect(() => {
    form?.setFieldsValue({
      name: `${user ? `${user.firstname} ${user?.lastname || ""}` : ""}` || `${tech ? `${tech.firstname} ${(tech?.lastname?.split("")[0] + ".") || ""}` : ""}`,
      email: user?.email || tech?.email || '',
      phone: user?.phone || tech?.phone || '',
      message: "",
      brand: ""
    });
  }, [])

  useEffect(() => {
    if(metadata) {
      setFilters(metadata)
    }
  }, [metadata])
  
  const handleTagClose = (filter) => setFilters(prevFilters => prevFilters.filter(f => f !== filter));

  return (
    <Modal
      destroyOnClose
      title="Contact TecMe"
      visible={isContactModalVisible}
      footer={null}
      onCancel={closeContactModal}
    >
      <Form
        form={form}
        layout="vertical"
        size="large"
        onFinish={(values) => {
          const newFilters = filters.map(filter => filter.includes("Other") ? filter.replace("Other", values.brand) : filter)
          setIsSendingMessage(true)
          ApiBaseHelper.post({
              url: "web/contact/form",
              payload: {
                ...values,
                metadata: newFilters
              },
            }).then(() => {
              notification.success({
                message: "TecMe",
                description:
                  "Thank you for contacting us, we will get back to you soon",
              });
              setIsSendingMessage(false)
              closeContactModal();
            })
            .catch((error) => {
              console.error(error);
              notification.error({
                message: "TecMe",
                description:
                  "Something went wrong, please try again",
              });
              setIsSendingMessage(false)
              closeContactModal();
            });
        }}
      >
        <Row gutter={[24, 0]}>
          <Col xs={24} md={isSearch ? 12 : 24}>
            <Item rules={getFormRules("name")} name="name" label="Name">
              <Input placeholder="Enter name" />
            </Item>
          </Col>
          <Col xs={24} md={isSearch ? 12 : 24}>
            <Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email",
                },
                {
                  type: "email",
                  message: "Invalid email address format",
                },
              ]}
            >
              <Input type="email" placeholder="Enter email" />
            </Item>
          </Col>
        </Row>
        <Item
          name="phone"
          label="Phone Number"
          rules={[
            {
              required: true,
              message: "Please enter your number",
            },
            {
              pattern: new RegExp(/^[0-9+]{3}[0-9+]{3}[0-9+]{4}$/),
              message: "Phone number should be 10 digits",
            },
          ]}
        >
          <Input placeholder="Enter phone number" />
        </Item>
        {isBrandVisible && (
          <Item rules={getFormRules("brand")} name="brand" label="Brand">
            <Input placeholder="Enter brand" />
          </Item>
        )}
        {isSearch && (
          <Space className="flex-wrap my-2">
            <Typography.Text>We'll send following list of filters to our support team so that we can find you a best match.</Typography.Text>
            {filters.map(filter => <Tag closable onClose={() => handleTagClose(filter)} key={filter}>{filter}</Tag>)}
          </Space>
        )}
        <Item rules={getFormRules("message")} name="message" label="Message">
          <TextArea placeholder="What can we help you with?" rows={3} />
        </Item>
        <Button className="mt-2" type="primary" size="middle" htmlType="submit" loading={isSendingMessage}>
          Submit
        </Button>
      </Form>
    </Modal>
  );
};

export default ContactModal;
