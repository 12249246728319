import { Avatar, Badge, Button, Card, Form, Input, Modal, Rate, Space, Tag, Typography, notification } from 'antd'
import React, { useState } from 'react'
import '../assets/css/applications.scss'
import { useHistory } from 'react-router-dom'
import ROUTES from '../../../../../utils/routes'
import { capitalizeFirstLetter, capitalizeFirstWordOfEveryLetter, getTableTimeFormat, nameFormat } from '../../../../../utils/helper'
import { convertBudget } from '../../../common/private/job-post/utils/helper'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import useWindowSize from '../../../../../hooks/useWindowSize'
import { decode } from 'html-entities'


const { Title, Text, Paragraph } = Typography
const ApplicationCard = ({item, jobPost, type, subType="normal",  fetchJobApplications}) => {
    const user = useSelector(state => state.user)
    const history = useHistory()
    const [showInviteModal, setShowInviteModal] = useState(false)
    const [inviting, setInviting] = useState(false)
    const { windowWidth } = useWindowSize()
    const [form] = Form.useForm();

    let cityState = `${item?.city ? `${item?.city}, ` : ''}${item?.state || ''}`;


    const sendJobInvite = (values) => {
        setInviting(true)   
        ApiBaseHelper.post({
            url: `job-post/invite/${jobPost?.id}`,	
            headers: {
                Authorization: 'Bearer ' + user?.token,
            },
            payload: {
                Technician_Contact: item?.objectID,
                Technician_Name: item?.name,
                Job_Title: jobPost?.Title,
                Job_ID: jobPost?.id,
                Message: values?.message,
            }
        }).then((res) => {
            if(res){
                notification.success({
                    message: 'TecMe',
                    description: 'Candidate Invited Successfully',
                });
                setInviting(false)
                setShowInviteModal(false)
                fetchJobApplications()
            } 
           
        }).catch((error) => {
            setInviting(false)
            notification.error({
                message: 'TecMe',
                description: "An error occured! Please try again",
            });
            console.error(error)
        })
    }



    return (
        <>
            <Card className='applicant-card'>
                <div className='applicant-card-inner'>
                    {windowWidth > 991 &&
                        <Avatar size={87} src={type !== "suggestion" ? item?.Applicant_Contact?.imgUrl : item?.image}>
                            {type !== "suggestion" ? item?.Applicant_Contact?.Full_Name?.charAt(0).toUpperCase() : item?.name?.charAt(0).toUpperCase()}
                        </Avatar>
                    }
                    <div style={{width:"100%"}}>
                        <div className='applicant-card-top'>      
                            { 
                                windowWidth > 991 ?
                                <Space size={0} direction='vertical'>    
                                    <Paragraph className="applicant-name">{capitalizeFirstWordOfEveryLetter(type !== "suggestion"  ? nameFormat(item?.Applicant_Contact?.Full_Name) : nameFormat(item?.name))}</Paragraph>
                                    <div>
                                        {
                                            type === "suggestion" && 
                                            <Space size={0} direction='vertical'>
                                                <Paragraph style={{marginBottom:0, fontSize:12}}>{cityState !== "" ? cityState : ""}</Paragraph>
                                            { item?.rating &&
                                                    <Space  style={{display:"flex"}}>
                                                        <Rate style={{color:"#FEB000", fontSize:14}} disabled value={item?.rating} allowHalf/>
                                                        <Text> {(item?.rating)?.toFixed(1)}</Text>
                                                    </Space>
                                                }
                                            </Space>
                                        }   
                                    </div> 
                                </Space> :
                                <Space>
                                    <Avatar size={47} src={type !== "suggestion" ? item?.Applicant_Contact?.imgUrl : item?.image}>
                                        {type !== "suggestion" ? item?.Applicant_Contact?.Full_Name?.charAt(0).toUpperCase() : item?.name?.charAt(0).toUpperCase()}
                                    </Avatar>
                                    <Paragraph className="applicant-name">{capitalizeFirstWordOfEveryLetter(item?.Applicant_Contact?.Full_Name)}</Paragraph>
                                    {
                                        type === "suggestion" && 
                                        <Space size={0} direction='vertical'>
                                            <Paragraph style={{marginBottom:0, fontSize:12}}>{cityState !== "" ? cityState : ""}</Paragraph>
                                            { item?.rating &&
                                                <Space  style={{display:"flex"}}>
                                                    <Rate style={{color:"#FEB000", fontSize:14}} disabled value={item?.rating} allowHalf/>
                                                    <Text> {(item?.rating)?.toFixed(1)}</Text>
                                                </Space>
                                            }
                                        </Space>
                                    }   
                                </Space>
                            }
                            
                                {/* <Text className="applicant-rate">Proposed rate: {convertBudget(item?.Payment_Amount, jobPost?.Payment_Type)}</Text> */}
                            { 
                                    windowWidth > 991 &&
                                    <>
                                { 
                                        type !== "suggestion" ?
                                        <Button type='primary' onClick={() => history.push({
                                            pathname: ROUTES.VIEW_JOB_APPLICATION.replace(':job_id', item?.Job_Posting).replace(':application_id', item?.id),
                                        })}>View Application</Button> :
                                        <Space>
                                            <Button 
                                                type='secondary'
                                                onClick={() => history.push(ROUTES.TECH_PROFILE_WITH_ID.replace(":id", item?.objectID)) }
                                            >View Profile</Button>
                                           { subType !== "invited" &&
                                                <Button 
                                                    type='primary'
                                                    onClick={() => setShowInviteModal(true)}
                                                >Invite</Button>
                                            }
                                        </Space>
                                    }
                                    </>
                                }
                         
                        </div>
                        <div className="applicant-content">
                            {   
                                item?.Status === "Withdrawn" ?
                                <Space size={12} direction='vertical' wrap>
                                    <Tag style={{color:"#F04A22", backgroundColor:"#FFEEEA"}}>Withdrawn</Tag>
                                    <Paragraph ellipsis={{rows:2}} style={{fontSize:14, marginBottom:0}}><strong>Reason for withdrawing application: </strong>{capitalizeFirstLetter(decode(item?.custom_data?.Withdraw_Reason))}</Paragraph>
                                </Space>
                                :
                                <>
                                { 
                                        type !== "suggestion" ?
                                        <Paragraph ellipsis={{rows:2}} style={{fontSize:14, marginBottom:0}}><strong>Application: </strong>{decode(item?.Cover_Letter)}</Paragraph> :
                                        <Paragraph ellipsis={{rows:2}} style={{fontSize:14, marginBottom:0}}>{item?.bio}</Paragraph>
                                }

                                    <div className="mt-2">
                                        {
                                            item?.Status === "Accepted" &&
                                            <Badge status="success" text={"Hired"} />
                                        }
                                        {
                                            
                                            item?.Status === "Closed" &&
                                            <Badge status="default" text={"Closed"} />
                                            
                                        }
                                    </div>
                                </>
                            }
                        </div>
                        <Space className='d-flex justify-content-between' wrap>
                            <Text>{type !== "suggestion" ? convertBudget(item?.Payment_Amount, jobPost?.Payment_Type) : `${item?.on_site_rate ? `$${item?.on_site_rate}/hr` : ''}`}</Text>
                        {  
                            type === "application" &&
                            <Space wrap>
                                <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", marginBottom:0}}><strong>Applied On:</strong>{getTableTimeFormat(item?.CreatedDate)}</Paragraph>
                            </Space>
                            }
                        </Space>
                    </div>
                </div>
                { 
                    (windowWidth < 991) &&
                    <>
                        {
                            type !== "suggestion" ?
                            <Button type='primary' className='mobile-view-btn' onClick={() => history.push({
                                pathname: ROUTES.VIEW_JOB_APPLICATION.replace(':job_id', item?.Job_Posting).replace(':application_id', item?.id),
                                state: {
                                    jobDetails: jobPost
                                }
                            })}>View Application</Button> :
                            <>
                                <Button 
                                    type='secondary' 
                                    className='mobile-view-btn'
                                    onClick={() => history.push(ROUTES.TECH_PROFILE_WITH_ID.replace(":id", item?.objectID)) }
                                >
                                    View Profile
                                </Button>
                                <Button 
                                    type='primary' 
                                    className='mobile-view-btn'
                                    onClick={() => setShowInviteModal(true)}
                                >
                                    Invite
                                </Button>
                            </>
                        }
                        
                    </>
                }
            </Card>
            <Modal
                open={showInviteModal}
                onOk={() => {
                    form.submit()
                }}
                onCancel={() => {
                    setShowInviteModal(false)
                    form.resetFields()
                }}
                okText={"Send Invite"}
                cancelText={"Cancel"}
                width={609}
                confirmLoading={inviting}
                centered
            >
            <div className="message-modal-heading">
                <Title level={3}>Convey an invite</Title>
                <Paragraph>Connect with {item?.name} regarding this position</Paragraph>
            </div>
            <Form
                layout='vertical'
                form={form}
                onFinish={(values) => sendJobInvite(values)}
            >
                <Form.Item
                    label={<Paragraph style={{color:"rgba(0, 0, 0, 0.85)", fontWeight: 500,
                        fontSize: 16}}>Compose a personalized message to accompany the email</Paragraph>}
                    name="message"
                    required={false}
                    rules={[
                        {
                            required: true,
                            message: 'Please enter a message',
                        },
                    ]}
                >
                    <Input.TextArea rows={4}/>
                </Form.Item>
            </Form>
            </Modal>
       </>

    )
}

export default ApplicationCard