import axios from "axios";
import { DEFAULT_SERVER } from "./constants";

export const BASE_URL = process.env.REACT_APP_BASE_URL != undefined ? process.env.REACT_APP_BASE_URL : DEFAULT_SERVER;

let config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
};
export class ApiBaseHelper {
    static async get({ url, headers }) {
        if (headers != undefined) {
            config['headers'] = {
                ...config['headers'],
                ...headers
            };
        }
        return axios.get(`${BASE_URL}/${url}`, config);
    }


    static async put({ url, payload, headers }) {
        if (headers != undefined) {
            config['headers'] = {
                ...config['headers'],
                ...headers
            };
        }
        return axios.put(`${BASE_URL}/${url}`, payload, config);
    }
    static async delete({ url, payload, headers }) {
        if (headers != undefined) {

            config['headers'] = {
                ...config['headers'],
                ...headers
            };
        }
        return axios.delete(`${BASE_URL}/${url}`, config);
    }
    static async post({ url, payload, headers }) {
        if (headers != undefined) {
            config['headers'] = {
                ...config['headers'],
                ...headers
            };
        }
        return axios.post(`${BASE_URL}/${url}`, payload, config);
    }

    static async createTrelloCard(payload) {
        return axios.post(`https://api.trello.com/1/cards?idList=${process.env.REACT_APP_TRELLO_LIST_ID}&key=${process.env.REACT_APP_TRELLO_API_KEY}&token=${process.env.REACT_APP_TRELLO_API_TOKEN}${payload}`, config);
    }
    
}

