import { ApiBaseHelper } from "../../../../../../utils/api-base-helper";

export class BookingRepository {
    constructor(accessToken,role) {
        this.accessToken = accessToken;
        this.role = role;
    }

    getBookingDetails(id) {
        return ApiBaseHelper.get(
            {
                url: `v2/booking/${id}?type=${this.role}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

}

