import { notification } from "antd";
import Axios from "axios";
import { ApiBaseHelper, BASE_URL } from "../../../../../../utils/api-base-helper";

export class TicketDetailsRepository {
    constructor(accessToken) {
        this.accessToken = accessToken;
    }

    getTicket(ticketId, role) {
        return ApiBaseHelper.get(
            {
                url: `ticket/${ticketId}?role=${role}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    getDevice(deviceId) {
        return ApiBaseHelper.get(
            {
                url: `device/${deviceId}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                }
            }
        )
    }

    getDevices() {
        return ApiBaseHelper.get({
            url: "device",
            headers: {
                Authorization: "Bearer " + this.accessToken,
            },
        });
    }

    beginTicket(ticketId, payload) {
        return ApiBaseHelper.put({
            url: `ticket/${ticketId}/begin`,
            payload,
            headers: {
                Authorization: 'Bearer ' + this.accessToken
            },
        })
    }

    attachDevice(ticketId, payload) {
        return ApiBaseHelper.put({
            url: `ticket/${ticketId}/attach-device`,
            payload,
            headers: {
                Authorization: 'Bearer ' + this.accessToken
            },
        })
    }

    clockInTech(ticketId) {
        return ApiBaseHelper.put({
            url: `ticket/${ticketId}/clock-in`,
            headers: {
                Authorization: 'Bearer ' + this.accessToken
            },
        })
    }

    clockOutTech(ticketId, payload) {
        return ApiBaseHelper.put({
            url: `ticket/${ticketId}/clock-out`,
            payload,
            headers: {
                Authorization: 'Bearer ' + this.accessToken
            },
        })
    }

    resolveTicket(ticketId, payload) {
        return ApiBaseHelper.put({
            url: `ticket/${ticketId}/resolve`,
            payload,
            headers: {
                Authorization: 'Bearer ' + this.accessToken
            },
        })
    }

    getTicketMedia(ticketId) {
        return ApiBaseHelper.get({
            url: `ticket/${ticketId}/media`,
            headers: {
                Authorization: 'Bearer ' + this.accessToken
            },
        })
    }

    uploadTicketMedia(ticketId, payload) {
        return ApiBaseHelper.post({
            url: `ticket/${ticketId}/media`,
            payload,
            headers: {
                Authorization: 'Bearer ' + this.accessToken
            },
        })
    }

    resumeTicket(ticketId, payload) {
        return ApiBaseHelper.put({
            url: `ticket/${ticketId}/resume`,
            payload,
            headers: {
                Authorization: 'Bearer ' + this.accessToken
            },
        })
    }

    updateToPendingTicket(ticketId, payload) {
        return ApiBaseHelper.put({
            url: `ticket/${ticketId}/pending`,
            payload,
            headers: {
                Authorization: 'Bearer ' + this.accessToken
            },
        })
    }

    reassignTicket(ticketId, payload) {
        return ApiBaseHelper.put({
            url: `ticket/${ticketId}/reassign`,
            payload,
            headers: {
                Authorization: 'Bearer ' + this.accessToken
            },
        })
    }

    createActivity(ticketId, payload) {
        return ApiBaseHelper.post({
            url: `ticket/${ticketId}/activity`,
            payload,
            headers: {
                Authorization: 'Bearer ' + this.accessToken
            },
        })
    }

    savePrivateNotes(ticketId, payload) {
        return ApiBaseHelper.put({
            url: `ticket/${ticketId}`,
            payload,
            headers: {
                Authorization: 'Bearer ' + this.accessToken
            },
        })
    }

    scheduleOnSiteVisit(ticketId, payload) {
        return ApiBaseHelper.put({
            url: `ticket/${ticketId}/schedule/on-site`,
            payload,
            headers: {
                Authorization: 'Bearer ' + this.accessToken
            },
        })
    }

    
    reScheduleOnSiteVisit(ticketId, payload) {
        return ApiBaseHelper.put({
            url: `ticket/${ticketId}/re-schedule`,
            payload,
            headers: {
                Authorization: 'Bearer ' + this.accessToken
            },
        })
    }

    async startRemoteSession(ticketId, payload) {
        try {
            return await ApiBaseHelper.post({
                url: `ticket/${ticketId}/remote-session`,
                payload,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },
            })
        } catch (error) {
            // notification.error({
			// 	message: 'TecMe',
			// 	description: 'Start remote session failed'
			// })
        }
    }

    async cancelOnSiteVisit(ticketId, payload) {
        try {
            return await Axios.delete(`${BASE_URL}/ticket/${ticketId}/cancel/on-site`, {
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },
                data: {...payload}
            });
        } catch (error) {
            // notification.error({
			// 	message: 'TecMe',
			// 	description: 'Cancel on-site visit failed'
			// })
        }
    }

    async getTicketInvoice(ticketId, contractId) {
        try {
            return await ApiBaseHelper.get({
                url: `ticket/${ticketId}/invoice/${contractId}`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },
            })
        } catch (error) {
            // notification.error({
			// 	message: 'TecMe',
			// 	description: 'get ticket invoice failed'
			// })
        }
    }

    async payInvoice(ticketId) {
        try {
            return await ApiBaseHelper.post({
                url: `ticket/${ticketId}/pay/invoice`,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },
            })
        } catch (error) {
            // notification.error({
			// 	message: 'TecMe',
			// 	description: 'get ticket invoice failed'
			// })
        }
    }

    async addManualTimeFromTicket(ticketId, payload) {
        try {
            return await ApiBaseHelper.post({
                url: `contract/{${ticketId}}/manual-time`,
                payload,
                headers: {
                    Authorization: 'Bearer ' + this.accessToken
                },
            })
        } catch (error) {
            // notification.error({
			// 	message: 'TecMe',
			// 	description: 'Start remote session failed'
			// })
        }
    }
}

