import Lottie from "lottie-react";
import LoadingAnimation from "../../assets/animations/main-loading.json";

const PageFallback = ({loadingText=""}) => (
  <div
    className="w-100 d-flex flex-column justify-content-center align-items-center main-page-fallback-wrapper"
    style={{ height: "100vh" }}
  >
    <Lottie
      style={{ height: 120, width: 120, marginBottom: 15}}
      animationData={LoadingAnimation}
    />
    <p className="text-center mt-3">{loadingText}</p>
  </div>
);

export default PageFallback;
