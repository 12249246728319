import React from 'react'
import FreeTrialMan from '../assets/images/free-trial-man.png'
import { Typography } from 'antd'
import { PrimaryButton } from './button'

const { Title, Paragraph } = Typography

const FreeTrial = () => {
  return (
    <section className="free-trial-section">
        <div className="free-trial-ctn">
            <div className="free-trial-img">
                <img src={FreeTrialMan} alt="free trial man" />
            </div>
            <div className='free-trial-content'>
                <Title level={1} className="free-trial-title">Start your free trial today!</Title>
                <Paragraph className="free-trial-description">Experience our services risk-free with our 7-day free trial offer. Discover the difference and value we can bring to your business, with no commitment required.</Paragraph>
                <PrimaryButton
                brandColor={"#FFF"}
                buttonStyle={{
                    fontSize: '14px',
                    width:131,
                    color:"#000",
                    fontWeight:500,
                    border: "1px solid #D9D9D9"
                }}>
                    Start your trial
                </PrimaryButton>
            </div>
        </div>
    </section>
  )
}

export default FreeTrial