export const STATES = [
    {
        "State": "Alabama",
        "Abbrev": "Ala.",
        "Code": "AL"
    },
    {
        "State": "Alaska",
        "Abbrev": "Alaska",
        "Code": "AK"
    },
    {
        "State": "Arizona",
        "Abbrev": "Ariz.",
        "Code": "AZ"
    },
    {
        "State": "Arkansas",
        "Abbrev": "Ark.",
        "Code": "AR"
    },
    {
        "State": "California",
        "Abbrev": "Calif.",
        "Code": "CA"
    },
    {
        "State": "Colorado",
        "Abbrev": "Colo.",
        "Code": "CO"
    },
    {
        "State": "Connecticut",
        "Abbrev": "Conn.",
        "Code": "CT"
    },
    {
        "State": "Delaware",
        "Abbrev": "Del.",
        "Code": "DE"
    },
    {
        "State": "District Of Columbia",
        "Abbrev": "D.C.",
        "Code": "DC"
    },
    {
        "State": "Florida",
        "Abbrev": "Fla.",
        "Code": "FL"
    },
    {
        "State": "Georgia",
        "Abbrev": "Ga.",
        "Code": "GA"
    },
    {
        "State": "Hawaii",
        "Abbrev": "Hawaii",
        "Code": "HI"
    },
    {
        "State": "Idaho",
        "Abbrev": "Idaho",
        "Code": "ID"
    },
    {
        "State": "Illinois",
        "Abbrev": "Ill.",
        "Code": "IL"
    },
    {
        "State": "Indiana",
        "Abbrev": "Ind.",
        "Code": "IN"
    },
    {
        "State": "Iowa",
        "Abbrev": "Iowa",
        "Code": "IA"
    },
    {
        "State": "Kansas",
        "Abbrev": "Kans.",
        "Code": "KS"
    },
    {
        "State": "Kentucky",
        "Abbrev": "Ky.",
        "Code": "KY"
    },
    {
        "State": "Louisiana",
        "Abbrev": "La.",
        "Code": "LA"
    },
    {
        "State": "Maine",
        "Abbrev": "Maine",
        "Code": "ME"
    },
    {
        "State": "Maryland",
        "Abbrev": "Md.",
        "Code": "MD"
    },
    {
        "State": "Massachusetts",
        "Abbrev": "Mass.",
        "Code": "MA"
    },
    {
        "State": "Michigan",
        "Abbrev": "Mich.",
        "Code": "MI"
    },
    {
        "State": "Minnesota",
        "Abbrev": "Minn.",
        "Code": "MN"
    },
    {
        "State": "Mississippi",
        "Abbrev": "Miss.",
        "Code": "MS"
    },
    {
        "State": "Missouri",
        "Abbrev": "Mo.",
        "Code": "MO"
    },
    {
        "State": "Montana",
        "Abbrev": "Mont.",
        "Code": "MT"
    },
    {
        "State": "Nebraska",
        "Abbrev": "Nebr.",
        "Code": "NE"
    },
    {
        "State": "Nevada",
        "Abbrev": "Nev.",
        "Code": "NV"
    },
    {
        "State": "New Hampshire",
        "Abbrev": "N.H.",
        "Code": "NH"
    },
    {
        "State": "New Jersey",
        "Abbrev": "N.J.",
        "Code": "NJ"
    },
    {
        "State": "New Mexico",
        "Abbrev": "N.M.",
        "Code": "NM"
    },
    {
        "State": "New York",
        "Abbrev": "N.Y.",
        "Code": "NY"
    },
    {
        "State": "North Carolina",
        "Abbrev": "N.C.",
        "Code": "NC"
    },
    {
        "State": "North Dakota",
        "Abbrev": "N.D.",
        "Code": "ND"
    },
    {
        "State": "Ohio",
        "Abbrev": "Ohio",
        "Code": "OH"
    },
    {
        "State": "Oklahoma",
        "Abbrev": "Okla.",
        "Code": "OK"
    },
    {
        "State": "Oregon",
        "Abbrev": "Ore.",
        "Code": "OR"
    },
    {
        "State": "Pennsylvania",
        "Abbrev": "Pa.",
        "Code": "PA"
    },
    {
        "State": "Rhode Island",
        "Abbrev": "R.I.",
        "Code": "RI"
    },
    {
        "State": "South Carolina",
        "Abbrev": "S.C.",
        "Code": "SC"
    },
    {
        "State": "South Dakota",
        "Abbrev": "S.D.",
        "Code": "SD"
    },
    {
        "State": "Tennessee",
        "Abbrev": "Tenn.",
        "Code": "TN"
    },
    {
        "State": "Texas",
        "Abbrev": "Tex.",
        "Code": "TX"
    },
    {
        "State": "Utah",
        "Abbrev": "Utah",
        "Code": "UT"
    },
    {
        "State": "Vermont",
        "Abbrev": "Vt.",
        "Code": "VT"
    },
    {
        "State": "Virginia",
        "Abbrev": "Va.",
        "Code": "VA"
    },
    {
        "State": "Washington",
        "Abbrev": "Wash.",
        "Code": "WA"
    },
    {
        "State": "West Virginia",
        "Abbrev": "W.Va.",
        "Code": "WV"
    },
    {
        "State": "Wisconsin",
        "Abbrev": "Wis.",
        "Code": "WI"
    },
    {
        "State": "Wyoming",
        "Abbrev": "Wyo.",
        "Code": "WY"
    }
];

export const CITIES = {
    "New York": [
        "New York",
        "Buffalo",
        "Rochester",
        "Yonkers",
        "Syracuse",
        "Albany",
        "New Rochelle",
        "Mount Vernon",
        "Schenectady",
        "Utica",
        "White Plains",
        "Hempstead",
        "Troy",
        "Niagara Falls",
        "Binghamton",
        "Freeport",
        "Valley Stream"
    ],
    "California": [
        "Los Angeles",
        "San Diego",
        "San Jose",
        "San Francisco",
        "Fresno",
        "Sacramento",
        "Long Beach",
        "Oakland",
        "Bakersfield",
        "Anaheim",
        "Santa Ana",
        "Riverside",
        "Stockton",
        "Chula Vista",
        "Irvine",
        "Fremont",
        "San Bernardino",
        "Modesto",
        "Fontana",
        "Oxnard",
        "Moreno Valley",
        "Huntington Beach",
        "Glendale",
        "Santa Clarita",
        "Garden Grove",
        "Oceanside",
        "Rancho Cucamonga",
        "Santa Rosa",
        "Ontario",
        "Lancaster",
        "Elk Grove",
        "Corona",
        "Palmdale",
        "Salinas",
        "Pomona",
        "Hayward",
        "Escondido",
        "Torrance",
        "Sunnyvale",
        "Orange",
        "Fullerton",
        "Pasadena",
        "Thousand Oaks",
        "Visalia",
        "Simi Valley",
        "Concord",
        "Roseville",
        "Victorville",
        "Santa Clara",
        "Vallejo",
        "Berkeley",
        "El Monte",
        "Downey",
        "Costa Mesa",
        "Inglewood",
        "Carlsbad",
        "San Buenaventura (Ventura)",
        "Fairfield",
        "West Covina",
        "Murrieta",
        "Richmond",
        "Norwalk",
        "Antioch",
        "Temecula",
        "Burbank",
        "Daly City",
        "Rialto",
        "Santa Maria",
        "El Cajon",
        "San Mateo",
        "Clovis",
        "Compton",
        "Jurupa Valley",
        "Vista",
        "South Gate",
        "Mission Viejo",
        "Vacaville",
        "Carson",
        "Hesperia",
        "Santa Monica",
        "Westminster",
        "Redding",
        "Santa Barbara",
        "Chico",
        "Newport Beach",
        "San Leandro",
        "San Marcos",
        "Whittier",
        "Hawthorne",
        "Citrus Heights",
        "Tracy",
        "Alhambra",
        "Livermore",
        "Buena Park",
        "Menifee",
        "Hemet",
        "Lakewood",
        "Merced",
        "Chino",
        "Indio",
        "Redwood City",
        "Lake Forest",
        "Napa",
        "Tustin",
        "Bellflower",
        "Mountain View",
        "Chino Hills",
        "Baldwin Park",
        "Alameda",
        "Upland",
        "San Ramon",
        "Folsom",
        "Pleasanton",
        "Union City",
        "Perris",
        "Manteca",
        "Lynwood",
        "Apple Valley",
        "Redlands",
        "Turlock",
        "Milpitas",
        "Redondo Beach",
        "Rancho Cordova",
        "Yorba Linda",
        "Palo Alto",
        "Davis",
        "Camarillo",
        "Walnut Creek",
        "Pittsburg",
        "South San Francisco",
        "Yuba City",
        "San Clemente",
        "Laguna Niguel",
        "Pico Rivera",
        "Montebello",
        "Lodi",
        "Madera",
        "Santa Cruz",
        "La Habra",
        "Encinitas",
        "Monterey Park",
        "Tulare",
        "Cupertino",
        "Gardena",
        "National City",
        "Rocklin",
        "Petaluma",
        "Huntington Park",
        "San Rafael",
        "La Mesa",
        "Arcadia",
        "Fountain Valley",
        "Diamond Bar",
        "Woodland",
        "Santee",
        "Lake Elsinore",
        "Porterville",
        "Paramount",
        "Eastvale",
        "Rosemead",
        "Hanford",
        "Highland",
        "Brentwood",
        "Novato",
        "Colton",
        "Cathedral City",
        "Delano",
        "Yucaipa",
        "Watsonville",
        "Placentia",
        "Glendora",
        "Gilroy",
        "Palm Desert",
        "Cerritos",
        "West Sacramento",
        "Aliso Viejo",
        "Poway",
        "La Mirada",
        "Rancho Santa Margarita",
        "Cypress",
        "Dublin",
        "Covina",
        "Azusa",
        "Palm Springs",
        "San Luis Obispo",
        "Ceres",
        "San Jacinto",
        "Lincoln",
        "Newark",
        "Lompoc",
        "El Centro",
        "Danville",
        "Bell Gardens",
        "Coachella",
        "Rancho Palos Verdes",
        "San Bruno",
        "Rohnert Park",
        "Brea",
        "La Puente",
        "Campbell",
        "San Gabriel",
        "Beaumont",
        "Morgan Hill",
        "Culver City",
        "Calexico",
        "Stanton",
        "La Quinta",
        "Pacifica",
        "Montclair",
        "Oakley",
        "Monrovia",
        "Los Banos",
        "Martinez"
    ],
    "Illinois": [
        "Chicago",
        "Aurora",
        "Rockford",
        "Joliet",
        "Naperville",
        "Springfield",
        "Peoria",
        "Elgin",
        "Waukegan",
        "Cicero",
        "Champaign",
        "Bloomington",
        "Arlington Heights",
        "Evanston",
        "Decatur",
        "Schaumburg",
        "Bolingbrook",
        "Palatine",
        "Skokie",
        "Des Plaines",
        "Orland Park",
        "Tinley Park",
        "Oak Lawn",
        "Berwyn",
        "Mount Prospect",
        "Normal",
        "Wheaton",
        "Hoffman Estates",
        "Oak Park",
        "Downers Grove",
        "Elmhurst",
        "Glenview",
        "DeKalb",
        "Lombard",
        "Belleville",
        "Moline",
        "Buffalo Grove",
        "Bartlett",
        "Urbana",
        "Quincy",
        "Crystal Lake",
        "Plainfield",
        "Streamwood",
        "Carol Stream",
        "Romeoville",
        "Rock Island",
        "Hanover Park",
        "Carpentersville",
        "Wheeling",
        "Park Ridge",
        "Addison",
        "Calumet City"
    ],
    "Texas": [
        "Houston",
        "San Antonio",
        "Dallas",
        "Austin",
        "Fort Worth",
        "El Paso",
        "Arlington",
        "Corpus Christi",
        "Plano",
        "Laredo",
        "Lubbock",
        "Garland",
        "Irving",
        "Amarillo",
        "Grand Prairie",
        "Brownsville",
        "Pasadena",
        "McKinney",
        "Mesquite",
        "McAllen",
        "Killeen",
        "Frisco",
        "Waco",
        "Carrollton",
        "Denton",
        "Midland",
        "Abilene",
        "Beaumont",
        "Round Rock",
        "Odessa",
        "Wichita Falls",
        "Richardson",
        "Lewisville",
        "Tyler",
        "College Station",
        "Pearland",
        "San Angelo",
        "Allen",
        "League City",
        "Sugar Land",
        "Longview",
        "Edinburg",
        "Mission",
        "Bryan",
        "Baytown",
        "Pharr",
        "Temple",
        "Missouri City",
        "Flower Mound",
        "Harlingen",
        "North Richland Hills",
        "Victoria",
        "Conroe",
        "New Braunfels",
        "Mansfield",
        "Cedar Park",
        "Rowlett",
        "Port Arthur",
        "Euless",
        "Georgetown",
        "Pflugerville",
        "DeSoto",
        "San Marcos",
        "Grapevine",
        "Bedford",
        "Galveston",
        "Cedar Hill",
        "Texas City",
        "Wylie",
        "Haltom City",
        "Keller",
        "Coppell",
        "Rockwall",
        "Huntsville",
        "Duncanville",
        "Sherman",
        "The Colony",
        "Burleson",
        "Hurst",
        "Lancaster",
        "Texarkana",
        "Friendswood",
        "Weslaco"
    ],
    "Pennsylvania": [
        "Philadelphia",
        "Pittsburgh",
        "Allentown",
        "Erie",
        "Reading",
        "Scranton",
        "Bethlehem",
        "Lancaster",
        "Harrisburg",
        "Altoona",
        "York",
        "State College",
        "Wilkes-Barre"
    ],
    "Arizona": [
        "Phoenix",
        "Tucson",
        "Mesa",
        "Chandler",
        "Glendale",
        "Scottsdale",
        "Gilbert",
        "Tempe",
        "Peoria",
        "Surprise",
        "Yuma",
        "Avondale",
        "Goodyear",
        "Flagstaff",
        "Buckeye",
        "Lake Havasu City",
        "Casa Grande",
        "Sierra Vista",
        "Maricopa",
        "Oro Valley",
        "Prescott",
        "Bullhead City",
        "Prescott Valley",
        "Marana",
        "Apache Junction"
    ],
    "Florida": [
        "Jacksonville",
        "Miami",
        "Tampa",
        "Orlando",
        "Saint Petersburg",
        "Hialeah",
        "Tallahassee",
        "Fort Lauderdale",
        "Port Saint Lucie",
        "Cape Coral",
        "Pembroke Pines",
        "Hollywood",
        "Miramar",
        "Gainesville",
        "Coral Springs",
        "Miami Gardens",
        "Clearwater",
        "Palm Bay",
        "Pompano Beach",
        "West Palm Beach",
        "Lakeland",
        "Davie",
        "Miami Beach",
        "Sunrise",
        "Plantation",
        "Boca Raton",
        "Deltona",
        "Largo",
        "Deerfield Beach",
        "Palm Coast",
        "Melbourne",
        "Boynton Beach",
        "Lauderhill",
        "Weston",
        "Fort Myers",
        "Kissimmee",
        "Homestead",
        "Tamarac",
        "Delray Beach",
        "Daytona Beach",
        "North Miami",
        "Wellington",
        "North Port",
        "Jupiter",
        "Ocala",
        "Port Orange",
        "Margate",
        "Coconut Creek",
        "Sanford",
        "Sarasota",
        "Pensacola",
        "Bradenton",
        "Palm Beach Gardens",
        "Pinellas Park",
        "Coral Gables",
        "Doral",
        "Bonita Springs",
        "Apopka",
        "Titusville",
        "North Miami Beach",
        "Oakland Park",
        "Fort Pierce",
        "North Lauderdale",
        "Cutler Bay",
        "Altamonte Springs",
        "Saint Cloud",
        "Greenacres",
        "Ormond Beach",
        "Ocoee",
        "Hallandale Beach",
        "Winter Garden",
        "Aventura"
    ],
    "Indiana": [
        "Indianapolis",
        "Fort Wayne",
        "Evansville",
        "South Bend",
        "Carmel",
        "Bloomington",
        "Fishers",
        "Hammond",
        "Gary",
        "Muncie",
        "Lafayette",
        "Terre Haute",
        "Kokomo",
        "Anderson",
        "Noblesville",
        "Greenwood",
        "Elkhart",
        "Mishawaka",
        "Lawrence",
        "Jeffersonville",
        "Columbus",
        "Portage"
    ],
    "Ohio": [
        "Columbus",
        "Cleveland",
        "Cincinnati",
        "Toledo",
        "Akron",
        "Dayton",
        "Parma",
        "Canton",
        "Youngstown",
        "Lorain",
        "Hamilton",
        "Springfield",
        "Kettering",
        "Elyria",
        "Lakewood",
        "Cuyahoga Falls",
        "Middletown",
        "Euclid",
        "Newark",
        "Mansfield",
        "Mentor",
        "Beavercreek",
        "Cleveland Heights",
        "Strongsville",
        "Dublin",
        "Fairfield",
        "Findlay",
        "Warren",
        "Lancaster",
        "Lima",
        "Huber Heights",
        "Westerville",
        "Marion",
        "Grove City"
    ],
    "North Carolina": [
        "Charlotte",
        "Raleigh",
        "Greensboro",
        "Durham",
        "Winston-Salem",
        "Fayetteville",
        "Cary",
        "Wilmington",
        "High Point",
        "Greenville",
        "Asheville",
        "Concord",
        "Gastonia",
        "Jacksonville",
        "Chapel Hill",
        "Rocky Mount",
        "Burlington",
        "Wilson",
        "Huntersville",
        "Kannapolis",
        "Apex",
        "Hickory",
        "Goldsboro"
    ],
    "Michigan": [
        "Detroit",
        "Grand Rapids",
        "Warren",
        "Sterling Heights",
        "Ann Arbor",
        "Lansing",
        "Flint",
        "Dearborn",
        "Livonia",
        "Westland",
        "Troy",
        "Farmington Hills",
        "Kalamazoo",
        "Wyoming",
        "Southfield",
        "Rochester Hills",
        "Taylor",
        "Pontiac",
        "Saint Clair Shores",
        "Royal Oak",
        "Novi",
        "Dearborn Heights",
        "Battle Creek",
        "Saginaw",
        "Kentwood",
        "East Lansing",
        "Roseville",
        "Portage",
        "Midland",
        "Lincoln Park",
        "Muskegon"
    ],
    "Tennessee": [
        "Memphis",
        "Nashville Davidson",
        "Knoxville",
        "Chattanooga",
        "Clarksville",
        "Murfreesboro",
        "Jackson",
        "Franklin",
        "Johnson City",
        "Bartlett",
        "Hendersonville",
        "Kingsport",
        "Collierville",
        "Cleveland",
        "Smyrna",
        "Germantown",
        "Brentwood"
    ],
    "Massachusetts": [
        "Boston",
        "Worcester",
        "Springfield",
        "Lowell",
        "Cambridge",
        "New Bedford",
        "Brockton",
        "Quincy",
        "Lynn",
        "Fall River",
        "Newton",
        "Lawrence",
        "Somerville",
        "Waltham",
        "Haverhill",
        "Malden",
        "Medford",
        "Taunton",
        "Chicopee",
        "Weymouth Town",
        "Revere",
        "Peabody",
        "Methuen",
        "Barnstable Town",
        "Pittsfield",
        "Attleboro",
        "Everett",
        "Salem",
        "Westfield",
        "Leominster",
        "Fitchburg",
        "Beverly",
        "Holyoke",
        "Marlborough",
        "Woburn",
        "Chelsea"
    ],
    "Washington": [
        "Seattle",
        "Spokane",
        "Tacoma",
        "Vancouver",
        "Bellevue",
        "Kent",
        "Everett",
        "Renton",
        "Yakima",
        "Federal Way",
        "Spokane Valley",
        "Bellingham",
        "Kennewick",
        "Auburn",
        "Pasco",
        "Marysville",
        "Lakewood",
        "Redmond",
        "Shoreline",
        "Richland",
        "Kirkland",
        "Burien",
        "Sammamish",
        "Olympia",
        "Lacey",
        "Edmonds",
        "Bremerton",
        "Puyallup"
    ],
    "Colorado": [
        "Denver",
        "Colorado Springs",
        "Aurora",
        "Fort Collins",
        "Lakewood",
        "Thornton",
        "Arvada",
        "Westminster",
        "Pueblo",
        "Centennial",
        "Boulder",
        "Greeley",
        "Longmont",
        "Loveland",
        "Grand Junction",
        "Broomfield",
        "Castle Rock",
        "Commerce City",
        "Parker",
        "Littleton",
        "Northglenn"
    ],
    "District Of Columbia": [
        "Washington"
    ],
    "Maryland": [
        "Baltimore",
        "Frederick",
        "Rockville",
        "Gaithersburg",
        "Bowie",
        "Hagerstown",
        "Annapolis"
    ],
    "Kentucky": [
        "Louisville/Jefferson County",
        "Lexington-Fayette",
        "Bowling Green",
        "Owensboro",
        "Covington"
    ],
    "Oregon": [
        "Portland",
        "Eugene",
        "Salem",
        "Gresham",
        "Hillsboro",
        "Beaverton",
        "Bend",
        "Medford",
        "Springfield",
        "Corvallis",
        "Albany",
        "Tigard",
        "Lake Oswego",
        "Keizer"
    ],
    "Oklahoma": [
        "Oklahoma City",
        "Tulsa",
        "Norman",
        "Broken Arrow",
        "Lawton",
        "Edmond",
        "Moore",
        "Midwest City",
        "Enid",
        "Stillwater",
        "Muskogee"
    ],
    "Wisconsin": [
        "Milwaukee",
        "Madison",
        "Green Bay",
        "Kenosha",
        "Racine",
        "Appleton",
        "Waukesha",
        "Eau Claire",
        "Oshkosh",
        "Janesville",
        "West Allis",
        "La Crosse",
        "Sheboygan",
        "Wauwatosa",
        "Fond du Lac",
        "New Berlin",
        "Wausau",
        "Brookfield",
        "Greenfield",
        "Beloit"
    ],
    "Nevada": [
        "Las Vegas",
        "Henderson",
        "Reno",
        "North Las Vegas",
        "Sparks",
        "Carson City"
    ],
    "New Mexico": [
        "Albuquerque",
        "Las Cruces",
        "Rio Rancho",
        "Santa Fe",
        "Roswell",
        "Farmington",
        "Clovis"
    ],
    "Missouri": [
        "Kansas City",
        "Saint Louis",
        "Springfield",
        "Independence",
        "Columbia",
        "Lee's Summit",
        "O'Fallon",
        "Saint Joseph",
        "Saint Charles",
        "Saint Peters",
        "Blue Springs",
        "Florissant",
        "Joplin",
        "Chesterfield",
        "Jefferson City",
        "Cape Girardeau"
    ],
    "Virginia": [
        "Virginia Beach",
        "Norfolk",
        "Chesapeake",
        "Richmond",
        "Newport News",
        "Alexandria",
        "Hampton",
        "Roanoke",
        "Portsmouth",
        "Suffolk",
        "Lynchburg",
        "Harrisonburg",
        "Leesburg",
        "Charlottesville",
        "Danville",
        "Blacksburg",
        "Manassas"
    ],
    "Georgia": [
        "Atlanta",
        "Columbus",
        "Augusta-Richmond County",
        "Savannah",
        "Athens-Clarke County",
        "Sandy Springs",
        "Roswell",
        "Macon",
        "Johns Creek",
        "Albany",
        "Warner Robins",
        "Alpharetta",
        "Marietta",
        "Valdosta",
        "Smyrna",
        "Dunwoody"
    ],
    "Nebraska": [
        "Omaha",
        "Lincoln",
        "Bellevue",
        "Grand Island"
    ],
    "Minnesota": [
        "Minneapolis",
        "Saint Paul",
        "Rochester",
        "Duluth",
        "Bloomington",
        "Brooklyn Park",
        "Plymouth",
        "Saint Cloud",
        "Eagan",
        "Woodbury",
        "Maple Grove",
        "Eden Prairie",
        "Coon Rapids",
        "Burnsville",
        "Blaine",
        "Lakeville",
        "Minnetonka",
        "Apple Valley",
        "Edina",
        "Saint Louis Park",
        "Mankato",
        "Maplewood",
        "Moorhead",
        "Shakopee"
    ],
    "Kansas": [
        "Wichita",
        "Overland Park",
        "Kansas City",
        "Olathe",
        "Topeka",
        "Lawrence",
        "Shawnee",
        "Manhattan",
        "Lenexa",
        "Salina",
        "Hutchinson"
    ],
    "Louisiana": [
        "New Orleans",
        "Baton Rouge",
        "Shreveport",
        "Lafayette",
        "Lake Charles",
        "Kenner",
        "Bossier City",
        "Monroe",
        "Alexandria"
    ],
    "Hawaii": [
        "Honolulu"
    ],
    "Alaska": [
        "Anchorage"
    ],
    "New Jersey": [
        "Newark",
        "Jersey City",
        "Paterson",
        "Elizabeth",
        "Clifton",
        "Trenton",
        "Camden",
        "Passaic",
        "Union City",
        "Bayonne",
        "East Orange",
        "Vineland",
        "New Brunswick",
        "Hoboken",
        "Perth Amboy",
        "West New York",
        "Plainfield",
        "Hackensack",
        "Sayreville",
        "Kearny",
        "Linden",
        "Atlantic City"
    ],
    "Idaho": [
        "Boise City",
        "Nampa",
        "Meridian",
        "Idaho Falls",
        "Pocatello",
        "Caldwell",
        "Coeur d'Alene",
        "Twin Falls"
    ],
    "Alabama": [
        "Birmingham",
        "Montgomery",
        "Mobile",
        "Huntsville",
        "Tuscaloosa",
        "Hoover",
        "Dothan",
        "Auburn",
        "Decatur",
        "Madison",
        "Florence",
        "Gadsden"
    ],
    "Iowa": [
        "Des Moines",
        "Cedar Rapids",
        "Davenport",
        "Sioux City",
        "Iowa City",
        "Waterloo",
        "Council Bluffs",
        "Ames",
        "West Des Moines",
        "Dubuque",
        "Ankeny",
        "Urbandale",
        "Cedar Falls"
    ],
    "Arkansas": [
        "Little Rock",
        "Fort Smith",
        "Fayetteville",
        "Springdale",
        "Jonesboro",
        "North Little Rock",
        "Conway",
        "Rogers",
        "Pine Bluff",
        "Bentonville"
    ],
    "Utah": [
        "Salt Lake City",
        "West Valley City",
        "Provo",
        "West Jordan",
        "Orem",
        "Sandy",
        "Ogden",
        "Saint George",
        "Layton",
        "Taylorsville",
        "South Jordan",
        "Lehi",
        "Logan",
        "Murray",
        "Draper",
        "Bountiful",
        "Riverton",
        "Roy"
    ],
    "Rhode Island": [
        "Providence",
        "Warwick",
        "Cranston",
        "Pawtucket",
        "East Providence",
        "Woonsocket"
    ],
    "Mississippi": [
        "Jackson",
        "Gulfport",
        "Southaven",
        "Hattiesburg",
        "Biloxi",
        "Meridian"
    ],
    "South Dakota": [
        "Sioux Falls",
        "Rapid City"
    ],
    "Connecticut": [
        "Bridgeport",
        "New Haven",
        "Stamford",
        "Hartford",
        "Waterbury",
        "Norwalk",
        "Danbury",
        "New Britain",
        "Meriden",
        "Bristol",
        "West Haven",
        "Milford",
        "Middletown",
        "Norwich",
        "Shelton"
    ],
    "South Carolina": [
        "Columbia",
        "Charleston",
        "North Charleston",
        "Mount Pleasant",
        "Rock Hill",
        "Greenville",
        "Summerville",
        "Sumter",
        "Goose Creek",
        "Hilton Head Island",
        "Florence",
        "Spartanburg"
    ],
    "New Hampshire": [
        "Manchester",
        "Nashua",
        "Concord"
    ],
    "North Dakota": [
        "Fargo",
        "Bismarck",
        "Grand Forks",
        "Minot"
    ],
    "Montana": [
        "Billings",
        "Missoula",
        "Great Falls",
        "Bozeman"
    ],
    "Delaware": [
        "Wilmington",
        "Dover"
    ],
    "Maine": [
        "Portland"
    ],
    "Wyoming": [
        "Cheyenne",
        "Casper"
    ],
    "West Virginia": [
        "Charleston",
        "Huntington"
    ],
    "Vermont": [
        "Burlington"
    ]
};

export const MATCHED_CITIES_STATES = [
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Los Angeles",
        "longitude": -118.2436849,
        "latitude": 34.0522342,
        "count": 16
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "San Diego",
        "longitude": -117.1610838,
        "latitude": 32.715738,
        "count": 4
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Long Beach",
        "longitude": -118.1937395,
        "latitude": 33.7700504,
        "count": 8
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Oakland",
        "longitude": -122.2711137,
        "latitude": 37.8043637,
        "count": 9
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Anaheim",
        "longitude": -117.9145036,
        "latitude": 33.8352932,
        "count": 11
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Santa Ana",
        "longitude": -117.8678338,
        "latitude": 33.7455731,
        "count": 9
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Chula Vista",
        "longitude": -117.0841955,
        "latitude": 32.6400541,
        "count": 4
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Irvine",
        "longitude": -117.7946942,
        "latitude": 33.6839473,
        "count": 6
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Oxnard",
        "longitude": -119.1770516,
        "latitude": 34.1975048,
        "count": 3
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Huntington Beach",
        "longitude": -117.9992265,
        "latitude": 33.660297,
        "count": 7
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Glendale",
        "longitude": -118.255075,
        "latitude": 34.1425078,
        "count": 13
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Santa Clarita",
        "longitude": -118.542586,
        "latitude": 34.3916641,
        "count": 5
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Garden Grove",
        "longitude": -117.9414477,
        "latitude": 33.7739053,
        "count": 9
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Rancho Cucamonga",
        "longitude": -117.5931084,
        "latitude": 34.10639889999999,
        "count": 5
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Ontario",
        "longitude": -117.6508876,
        "latitude": 34.0633443,
        "count": 5
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Lancaster",
        "longitude": -118.1541632,
        "latitude": 34.6867846,
        "count": 3
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Corona",
        "longitude": -117.5664384,
        "latitude": 33.8752935,
        "count": 5
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Palmdale",
        "longitude": -118.1164613,
        "latitude": 34.5794343,
        "count": 3
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Pomona",
        "longitude": -117.7499909,
        "latitude": 34.055103,
        "count": 8
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Escondido",
        "longitude": -117.086421,
        "latitude": 33.1192068,
        "count": 5
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Torrance",
        "longitude": -118.3406288,
        "latitude": 33.8358492,
        "count": 11
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Orange",
        "longitude": -117.8531119,
        "latitude": 33.7877944,
        "count": 11
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Pasadena",
        "longitude": -118.1445155,
        "latitude": 34.1477849,
        "count": 14
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Fullerton",
        "longitude": -117.9242966,
        "latitude": 33.8703596,
        "count": 11
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Thousand Oaks",
        "longitude": -118.8375937,
        "latitude": 34.1705609,
        "count": 3
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Simi Valley",
        "longitude": -118.781482,
        "latitude": 34.2694474,
        "count": 3
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Concord",
        "longitude": -122.0310733,
        "latitude": 37.9779776,
        "count": 6
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Vallejo",
        "longitude": -122.2566367,
        "latitude": 38.1040864,
        "count": 7
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Berkeley",
        "longitude": -122.272747,
        "latitude": 37.8715926,
        "count": 9
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "El Monte",
        "longitude": -118.0275667,
        "latitude": 34.0686206,
        "count": 10
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Downey",
        "longitude": -118.1331593,
        "latitude": 33.9401088,
        "count": 12
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Costa Mesa",
        "longitude": -117.9186689,
        "latitude": 33.6411316,
        "count": 7
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Inglewood",
        "longitude": -118.3531311,
        "latitude": 33.9616801,
        "count": 15
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Fairfield",
        "longitude": -122.0399663,
        "latitude": 38.24935809999999,
        "count": 5
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "San Buenaventura (Ventura)",
        "longitude": -119.2290316,
        "latitude": 34.274646,
        "count": 3
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "West Covina",
        "longitude": -117.9389526,
        "latitude": 34.0686208,
        "count": 9
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Richmond",
        "longitude": -122.3477486,
        "latitude": 37.9357576,
        "count": 8
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Antioch",
        "longitude": -121.805789,
        "latitude": 38.0049214,
        "count": 4
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Norwalk",
        "longitude": -118.081733,
        "latitude": 33.9022367,
        "count": 11
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Burbank",
        "longitude": -118.3089661,
        "latitude": 34.1808392,
        "count": 13
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "El Cajon",
        "longitude": -116.9625269,
        "latitude": 32.7947731,
        "count": 4
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Jurupa Valley",
        "longitude": -117.4854802,
        "latitude": 33.9971974,
        "count": 5
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Compton",
        "longitude": -118.2200712,
        "latitude": 33.8958492,
        "count": 11
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "South Gate",
        "longitude": -118.2120161,
        "latitude": 33.954737,
        "count": 11
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Carson",
        "longitude": -118.281693,
        "latitude": 33.8316745,
        "count": 11
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Santa Monica",
        "longitude": -118.4911912,
        "latitude": 34.0194543,
        "count": 13
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Westminster",
        "longitude": -117.9939921,
        "latitude": 33.7513419,
        "count": 8
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "San Marcos",
        "longitude": -117.1661449,
        "latitude": 33.1433723,
        "count": 4
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "San Leandro",
        "longitude": -122.1560768,
        "latitude": 37.7249296,
        "count": 6
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Newport Beach",
        "longitude": -117.9289469,
        "latitude": 33.6189101,
        "count": 7
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Whittier",
        "longitude": -118.032844,
        "latitude": 33.9791793,
        "count": 12
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Hawthorne",
        "longitude": -118.3525748,
        "latitude": 33.9164032,
        "count": 12
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Livermore",
        "longitude": -121.7680088,
        "latitude": 37.6818745,
        "count": 5
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Alhambra",
        "longitude": -118.1270146,
        "latitude": 34.095287,
        "count": 14
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Buena Park",
        "longitude": -117.9981181,
        "latitude": 33.8675143,
        "count": 11
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Lakewood",
        "longitude": -118.1339563,
        "latitude": 33.8536269,
        "count": 10
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Chino",
        "longitude": -117.688944,
        "latitude": 34.0122346,
        "count": 6
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Lake Forest",
        "longitude": -117.689218,
        "latitude": 33.6469661,
        "count": 3
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Tustin",
        "longitude": -117.826166,
        "latitude": 33.7458511,
        "count": 7
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Bellflower",
        "longitude": -118.1170117,
        "latitude": 33.8816818,
        "count": 10
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Baldwin Park",
        "longitude": -117.9608978,
        "latitude": 34.0852868,
        "count": 9
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Chino Hills",
        "longitude": -117.7325848,
        "latitude": 33.9898188,
        "count": 7
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Alameda",
        "longitude": -122.2416355,
        "latitude": 37.7652065,
        "count": 8
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Upland",
        "longitude": -117.6483876,
        "latitude": 34.09751,
        "count": 5
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "San Ramon",
        "longitude": -121.9780153,
        "latitude": 37.7799273,
        "count": 6
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Pleasanton",
        "longitude": -121.8746789,
        "latitude": 37.6624312,
        "count": 6
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Lynwood",
        "longitude": -118.2114603,
        "latitude": 33.930293,
        "count": 11
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Redondo Beach",
        "longitude": -118.3884078,
        "latitude": 33.8491816,
        "count": 11
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Yorba Linda",
        "longitude": -117.8131125,
        "latitude": 33.8886259,
        "count": 8
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Walnut Creek",
        "longitude": -122.0651819,
        "latitude": 37.9100783,
        "count": 7
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Pittsburg",
        "longitude": -121.8846806,
        "latitude": 38.0279762,
        "count": 4
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Camarillo",
        "longitude": -119.0376023,
        "latitude": 34.2163937,
        "count": 3
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Pico Rivera",
        "longitude": -118.096735,
        "latitude": 33.9830688,
        "count": 12
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Montebello",
        "longitude": -118.1137535,
        "latitude": 34.0165053,
        "count": 13
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "La Habra",
        "longitude": -117.9461734,
        "latitude": 33.9319578,
        "count": 11
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Encinitas",
        "longitude": -117.2919818,
        "latitude": 33.0369867,
        "count": 4
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Monterey Park",
        "longitude": -118.1228476,
        "latitude": 34.0625106,
        "count": 12
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Gardena",
        "longitude": -118.3089624,
        "latitude": 33.8883487,
        "count": 11
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "National City",
        "longitude": -117.0991967,
        "latitude": 32.6781085,
        "count": 4
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Huntington Park",
        "longitude": -118.2250725,
        "latitude": 33.9816812,
        "count": 14
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "La Mesa",
        "longitude": -117.0230839,
        "latitude": 32.7678287,
        "count": 4
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Arcadia",
        "longitude": -118.0353449,
        "latitude": 34.1397292,
        "count": 10
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Fountain Valley",
        "longitude": -117.9536697,
        "latitude": 33.7091847,
        "count": 9
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Diamond Bar",
        "longitude": -117.8103367,
        "latitude": 34.0286226,
        "count": 8
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Santee",
        "longitude": -116.9739167,
        "latitude": 32.8383828,
        "count": 4
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Eastvale",
        "longitude": -117.5848025,
        "latitude": 33.952463,
        "count": 6
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Brentwood",
        "longitude": -121.6957863,
        "latitude": 37.931868,
        "count": 4
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Paramount",
        "longitude": -118.1597911,
        "latitude": 33.8894598,
        "count": 11
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Rosemead",
        "longitude": -118.072846,
        "latitude": 34.0805651,
        "count": 12
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Placentia",
        "longitude": -117.8703363,
        "latitude": 33.8722371,
        "count": 11
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Dublin",
        "longitude": -121.9357918,
        "latitude": 37.7021521,
        "count": 6
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Glendora",
        "longitude": -117.865339,
        "latitude": 34.1361187,
        "count": 9
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Cerritos",
        "longitude": -118.0647871,
        "latitude": 33.8583483,
        "count": 10
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Poway",
        "longitude": -117.0358646,
        "latitude": 32.9628232,
        "count": 4
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "La Mirada",
        "longitude": -118.0120086,
        "latitude": 33.9172357,
        "count": 12
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Cypress",
        "longitude": -118.0372852,
        "latitude": 33.8169599,
        "count": 9
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Covina",
        "longitude": -117.8903397,
        "latitude": 34.0900091,
        "count": 9
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Azusa",
        "longitude": -117.9075627,
        "latitude": 34.1336186,
        "count": 9
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Danville",
        "longitude": -121.9999606,
        "latitude": 37.8215929,
        "count": 7
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Bell Gardens",
        "longitude": -118.1514588,
        "latitude": 33.9652918,
        "count": 12
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Rancho Palos Verdes",
        "longitude": -118.3870173,
        "latitude": 33.7444613,
        "count": 7
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Brea",
        "longitude": -117.9000604,
        "latitude": 33.9166805,
        "count": 11
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "La Puente",
        "longitude": -117.9495083,
        "latitude": 34.0200114,
        "count": 10
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "San Gabriel",
        "longitude": -118.1058333,
        "latitude": 34.09611110000001,
        "count": 12
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Culver City",
        "longitude": -118.3964665,
        "latitude": 34.0211224,
        "count": 14
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Stanton",
        "longitude": -117.9931165,
        "latitude": 33.8025155,
        "count": 10
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Oakley",
        "longitude": -121.7124536,
        "latitude": 37.9974219,
        "count": 4
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Montclair",
        "longitude": -117.6897776,
        "latitude": 34.0775104,
        "count": 6
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Martinez",
        "longitude": -122.1341321,
        "latitude": 38.0193657,
        "count": 8
    },
    {
        "stateCode": "CA",
        "state": "California",
        "city": "Monrovia",
        "longitude": -118.0019482,
        "latitude": 34.1442616,
        "count": 9
    },
    {
        "stateCode": "CT",
        "state": "Connecticut",
        "city": "Stamford",
        "longitude": -73.5387341,
        "latitude": 41.0534302,
        "count": 5
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Chicago",
        "longitude": -87.6297982,
        "latitude": 41.8781136,
        "count": 9
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Aurora",
        "longitude": -88.32007150000001,
        "latitude": 41.7605849,
        "count": 5
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Joliet",
        "longitude": -88.0817251,
        "latitude": 41.525031,
        "count": 6
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Naperville",
        "longitude": -88.1535352,
        "latitude": 41.7508391,
        "count": 7
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Elgin",
        "longitude": -88.2825668,
        "latitude": 42.0354084,
        "count": 6
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Waukegan",
        "longitude": -87.84479379999999,
        "latitude": 42.3636331,
        "count": 7
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Cicero",
        "longitude": -87.7539448,
        "latitude": 41.8455877,
        "count": 8
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Arlington Heights",
        "longitude": -87.98062650000001,
        "latitude": 42.0883603,
        "count": 9
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Evanston",
        "longitude": -87.68769689999999,
        "latitude": 42.0450722,
        "count": 9
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Schaumburg",
        "longitude": -88.0834059,
        "latitude": 42.0333607,
        "count": 9
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Bolingbrook",
        "longitude": -88.0683955,
        "latitude": 41.69864159999999,
        "count": 7
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Palatine",
        "longitude": -88.03424000000001,
        "latitude": 42.1103041,
        "count": 7
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Skokie",
        "longitude": -87.7416246,
        "latitude": 42.0324025,
        "count": 9
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Des Plaines",
        "longitude": -87.88339909999999,
        "latitude": 42.0333623,
        "count": 10
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Orland Park",
        "longitude": -87.85394250000002,
        "latitude": 41.6303103,
        "count": 8
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Tinley Park",
        "longitude": -87.7932939,
        "latitude": 41.5731442,
        "count": 7
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Oak Lawn",
        "longitude": -87.7479528,
        "latitude": 41.719978,
        "count": 9
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Berwyn",
        "longitude": -87.7936685,
        "latitude": 41.85058739999999,
        "count": 8
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Mount Prospect",
        "longitude": -87.9372908,
        "latitude": 42.0664167,
        "count": 9
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Wheaton",
        "longitude": -88.1070127,
        "latitude": 41.8661403,
        "count": 8
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Hoffman Estates",
        "longitude": -88.12271989999999,
        "latitude": 42.0629915,
        "count": 7
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Oak Park",
        "longitude": -87.7845025,
        "latitude": 41.8850317,
        "count": 8
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Downers Grove",
        "longitude": -88.01117459999999,
        "latitude": 41.8089191,
        "count": 7
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Elmhurst",
        "longitude": -87.9403418,
        "latitude": 41.8994744,
        "count": 8
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Glenview",
        "longitude": -87.7878408,
        "latitude": 42.0697509,
        "count": 10
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Lombard",
        "longitude": -88.00784349999999,
        "latitude": 41.8800296,
        "count": 9
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "DeKalb",
        "longitude": -88.75036469999999,
        "latitude": 41.9294736,
        "count": 3
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Belleville",
        "longitude": -89.9839935,
        "latitude": 38.5200504,
        "count": 11
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Buffalo Grove",
        "longitude": -87.9631308,
        "latitude": 42.1662831,
        "count": 7
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Plainfield",
        "longitude": -88.2120315,
        "latitude": 41.632223,
        "count": 6
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Bartlett",
        "longitude": -88.1856301,
        "latitude": 41.9950276,
        "count": 7
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Crystal Lake",
        "longitude": -88.31619649999999,
        "latitude": 42.2411344,
        "count": 6
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Carol Stream",
        "longitude": -88.13479269999999,
        "latitude": 41.91252859999999,
        "count": 8
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Streamwood",
        "longitude": -88.17840849999999,
        "latitude": 42.0255827,
        "count": 7
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Romeoville",
        "longitude": -88.0895061,
        "latitude": 41.6475306,
        "count": 7
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Hanover Park",
        "longitude": -88.1450735,
        "latitude": 41.9994722,
        "count": 9
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Carpentersville",
        "longitude": -88.2578582,
        "latitude": 42.1211364,
        "count": 7
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Wheeling",
        "longitude": -87.9289591,
        "latitude": 42.1391927,
        "count": 7
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Park Ridge",
        "longitude": -87.84061919999999,
        "latitude": 42.0111412,
        "count": 9
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Addison",
        "longitude": -87.9889556,
        "latitude": 41.931696,
        "count": 8
    },
    {
        "stateCode": "IL",
        "state": "Illinois",
        "city": "Calumet City",
        "longitude": -87.5294871,
        "latitude": 41.6155909,
        "count": 8
    },
    {
        "stateCode": "IN",
        "state": "Indiana",
        "city": "Hammond",
        "longitude": -87.5000412,
        "latitude": 41.5833688,
        "count": 6
    },
    {
        "stateCode": "IN",
        "state": "Indiana",
        "city": "Gary",
        "longitude": -87.3464271,
        "latitude": 41.5933696,
        "count": 5
    },
    {
        "stateCode": "MO",
        "state": "Missouri",
        "city": "Saint Louis",
        "longitude": -90.19940419999999,
        "latitude": 38.6270025,
        "count": 16
    },
    {
        "stateCode": "MO",
        "state": "Missouri",
        "city": "O'Fallon",
        "longitude": -90.69984769999999,
        "latitude": 38.8106075,
        "count": 12
    },
    {
        "stateCode": "MO",
        "state": "Missouri",
        "city": "Saint Charles",
        "longitude": -90.4974359,
        "latitude": 38.7881062,
        "count": 14
    },
    {
        "stateCode": "MO",
        "state": "Missouri",
        "city": "Saint Peters",
        "longitude": -90.6298922,
        "latitude": 38.7874699,
        "count": 12
    },
    {
        "stateCode": "MO",
        "state": "Missouri",
        "city": "Florissant",
        "longitude": -90.322614,
        "latitude": 38.789217,
        "count": 13
    },
    {
        "stateCode": "MO",
        "state": "Missouri",
        "city": "Chesterfield",
        "longitude": -90.5770675,
        "latitude": 38.6631083,
        "count": 14
    },
    {
        "stateCode": "NJ",
        "state": "New Jersey",
        "city": "Newark",
        "longitude": -74.1723667,
        "latitude": 40.735657,
        "count": 8
    },
    {
        "stateCode": "NJ",
        "state": "New Jersey",
        "city": "Jersey City",
        "longitude": -74.0776417,
        "latitude": 40.72815749999999,
        "count": 11
    },
    {
        "stateCode": "NJ",
        "state": "New Jersey",
        "city": "Paterson",
        "longitude": -74.17181099999999,
        "latitude": 40.9167654,
        "count": 8
    },
    {
        "stateCode": "NJ",
        "state": "New Jersey",
        "city": "Elizabeth",
        "longitude": -74.2107006,
        "latitude": 40.6639916,
        "count": 7
    },
    {
        "stateCode": "NJ",
        "state": "New Jersey",
        "city": "Clifton",
        "longitude": -74.16375529999999,
        "latitude": 40.8584328,
        "count": 9
    },
    {
        "stateCode": "NJ",
        "state": "New Jersey",
        "city": "Passaic",
        "longitude": -74.1284764,
        "latitude": 40.8567662,
        "count": 9
    },
    {
        "stateCode": "NJ",
        "state": "New Jersey",
        "city": "Union City",
        "longitude": -74.26316349999999,
        "latitude": 40.6975898,
        "count": 7
    },
    {
        "stateCode": "NJ",
        "state": "New Jersey",
        "city": "Bayonne",
        "longitude": -74.1143091,
        "latitude": 40.6687141,
        "count": 10
    },
    {
        "stateCode": "NJ",
        "state": "New Jersey",
        "city": "East Orange",
        "longitude": -74.2048677,
        "latitude": 40.767323,
        "count": 8
    },
    {
        "stateCode": "NJ",
        "state": "New Jersey",
        "city": "New Brunswick",
        "longitude": -74.4518188,
        "latitude": 40.4862157,
        "count": 4
    },
    {
        "stateCode": "NJ",
        "state": "New Jersey",
        "city": "Hoboken",
        "longitude": -74.0323626,
        "latitude": 40.7439905,
        "count": 12
    },
    {
        "stateCode": "NJ",
        "state": "New Jersey",
        "city": "West New York",
        "longitude": -74.0143064,
        "latitude": 40.7878788,
        "count": 12
    },
    {
        "stateCode": "NJ",
        "state": "New Jersey",
        "city": "Perth Amboy",
        "longitude": -74.2654234,
        "latitude": 40.5067723,
        "count": 6
    },
    {
        "stateCode": "NJ",
        "state": "New Jersey",
        "city": "Plainfield",
        "longitude": -74.4073736,
        "latitude": 40.6337136,
        "count": 6
    },
    {
        "stateCode": "NJ",
        "state": "New Jersey",
        "city": "Sayreville",
        "longitude": -74.360846,
        "latitude": 40.45940210000001,
        "count": 4
    },
    {
        "stateCode": "NJ",
        "state": "New Jersey",
        "city": "Hackensack",
        "longitude": -74.0434736,
        "latitude": 40.8859325,
        "count": 11
    },
    {
        "stateCode": "NJ",
        "state": "New Jersey",
        "city": "Kearny",
        "longitude": -74.1454214,
        "latitude": 40.7684342,
        "count": 9
    },
    {
        "stateCode": "NJ",
        "state": "New Jersey",
        "city": "Linden",
        "longitude": -74.24459019999999,
        "latitude": 40.6220478,
        "count": 7
    },
    {
        "stateCode": "NY",
        "state": "New York",
        "city": "New York",
        "longitude": -74.0059413,
        "latitude": 40.7127837,
        "count": 11
    },
    {
        "stateCode": "NY",
        "state": "New York",
        "city": "Yonkers",
        "longitude": -73.89874689999999,
        "latitude": 40.9312099,
        "count": 11
    },
    {
        "stateCode": "NY",
        "state": "New York",
        "city": "New Rochelle",
        "longitude": -73.7823549,
        "latitude": 40.9114882,
        "count": 11
    },
    {
        "stateCode": "NY",
        "state": "New York",
        "city": "Mount Vernon",
        "longitude": -73.8370786,
        "latitude": 40.9125992,
        "count": 11
    },
    {
        "stateCode": "NY",
        "state": "New York",
        "city": "White Plains",
        "longitude": -73.7629097,
        "latitude": 41.03398620000001,
        "count": 6
    },
    {
        "stateCode": "NY",
        "state": "New York",
        "city": "Hempstead",
        "longitude": -73.6187397,
        "latitude": 40.7062128,
        "count": 10
    },
    {
        "stateCode": "NY",
        "state": "New York",
        "city": "Freeport",
        "longitude": -73.58318349999999,
        "latitude": 40.6576022,
        "count": 9
    },
    {
        "stateCode": "NY",
        "state": "New York",
        "city": "Valley Stream",
        "longitude": -73.70846449999999,
        "latitude": 40.6642699,
        "count": 10
    },
    {
        "stateCode": "PA",
        "state": "Pennsylvania",
        "city": "Reading",
        "longitude": -75.9268747,
        "latitude": 40.3356483,
        "count": 3
    },
    {
        "stateCode": "TN",
        "state": "Tennessee",
        "city": "Nashville Davidson",
        "longitude": -86.7816016,
        "latitude": 36.1626638,
        "count": 3
    },
    {
        "stateCode": "TN",
        "state": "Tennessee",
        "city": "Murfreesboro",
        "longitude": -86.39027,
        "latitude": 35.8456213,
        "count": 3
    },
    {
        "stateCode": "TN",
        "state": "Tennessee",
        "city": "Franklin",
        "longitude": -86.8688899,
        "latitude": 35.9250637,
        "count": 3
    },
    {
        "stateCode": "TN",
        "state": "Tennessee",
        "city": "Hendersonville",
        "longitude": -86.6199957,
        "latitude": 36.3047735,
        "count": 3
    },
    {
        "stateCode": "TN",
        "state": "Tennessee",
        "city": "Smyrna",
        "longitude": -86.5186045,
        "latitude": 35.9828412,
        "count": 3
    },
    {
        "stateCode": "TN",
        "state": "Tennessee",
        "city": "Brentwood",
        "longitude": -86.78277720000001,
        "latitude": 36.0331164,
        "count": 3
    },
    {
        "stateCode": "WI",
        "state": "Wisconsin",
        "city": "Kenosha",
        "longitude": -87.82118539999999,
        "latitude": 42.5847425,
        "count": 5
    },
    {
        "stateCode": "WI",
        "state": "Wisconsin",
        "city": "Racine",
        "longitude": -87.78285230000002,
        "latitude": 42.7261309,
        "count": 4
    },
    {
        "stateCode": "WI",
        "state": "Wisconsin",
        "city": "Greenfield",
        "longitude": -88.0125865,
        "latitude": 42.9614039,
        "count": 3
    }
]