import { DeleteOutlined, EditOutlined, EllipsisOutlined, ExclamationCircleOutlined, PlusCircleOutlined, UserAddOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Form, Input, Layout, List, Modal, notification, Popover, Row, Skeleton, Space, Typography } from 'antd';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ApiBaseHelper } from '../../../../utils/api-base-helper';
import { META_DESCRIPTION } from '../../../../utils/constants'
import ROUTES from '../../../../utils/routes';
import MetaTags from 'react-meta-tags';
import './assets/css/styles.scss';
import './assets/css/create-list-modal.scss';
import PageHeaderWrapper from '../../../components/page-header-breadcrumbs';
import { useHistory } from 'react-router';
import routes from '../../../../utils/routes';
import CreateListModal from './components/create-list-modal';
import DeleteListModal from './components/delete-list-modal';
import CustomRenderEmpty from '../../../components/custom-render-empty';
import { capitalizeFirstLetter, truncateText } from '../../../../utils/helper';
import EmptyStateImage from './assets/icons/empty-state-list.svg'

const {useForm} = Form
const { Title, Paragraph } = Typography
const { Content } = Layout
const { TextArea } = Input;
const { confirm } = Modal;


const SavedProfilesList = () => {
    const user = useSelector((state) => state.user);
    const [savedTechniciansList, setSavedTechniciansList] = useState([])
    const [loadingList, setLoadingList] = useState(false)
    const [showCreateListModal, setShowCreateListModal] = useState(false)
    const [showDeleteListModal, setShowDeleteListModal] = useState(false)
    const [creatingList, setCreatingList] = useState(false)
    const [editState, setEditState] = useState(false)
    const [selectedList, setSelectedList] = useState({})
    const history = useHistory()
    const [form] = useForm();

    const getSavedTechniciansList = () => {
        setLoadingList(true)
        ApiBaseHelper.get({
            url: 'saved-technician/list',
            headers: {
                Authorization: 'Bearer ' + user?.token,
            },
        }).then((res) => {
            setSavedTechniciansList(res?.data?.data)
            setLoadingList(false)
        })
    }
    
    useEffect(() => {
        getSavedTechniciansList()
    }, [])

    useEffect(() => {
        if(editState){
            form.setFieldsValue({
                Group_Name: selectedList?.Group_Name,
                Description: selectedList?.Description
            })
        }else{
            form.resetFields()
        }
    }, [editState])

    console.log(savedTechniciansList)

    const loadingListSkeleton = () => {
        return(
            <div className="">
                 <div className='saved-card-lists'>
                    <List
                        grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 3,
                            lg: 4,
                            xl: 4,
                            xxl: 4,
                        }}
                        dataSource={[0, 1, 2, 3, 4, 5, 6, 7]}
                        renderItem={item => (
                        <List.Item>
                            <Card title={<Skeleton.Input active/>}>
                                <div className="mb-2">
                                    <Skeleton.Avatar active/>
                                </div>
                                <Skeleton paragraph={{rows:1}} active/>
                            </Card>
                        </List.Item>
                        )}
                    />
                </div>
            </div>
        )
    }

    const popoverContent = (item) => {
        return(
            <div className="card-popover-content">
                <Space className='popover-action-item' onClick={(e) => { 
                    e.stopPropagation()
                    setEditState(true)
                    setShowCreateListModal(true)
                    setSelectedList({
                            Group_Name:item?.Group_Name, 
                            Description:item?.Description,
                            id:item?.id
                        })
                    }}>
                    <EditOutlined />
                    <Paragraph style={{marginBottom:0}}>Edit list</Paragraph> 
                </Space>
                <Space className='popover-action-item'
                    onClick={(e) => {
                        setShowDeleteListModal(true)
                        setSelectedList({
                           ...selectedList,
                            id:item?.id
                        })
                        e.stopPropagation()
                    }}
                >
                    <DeleteOutlined />
                    <Paragraph style={{marginBottom:0}}>Delete list</Paragraph> 
                </Space>
            </div>
        )
    }

    return (
        <>
            <MetaTags>
                <title>Saved Profiles - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <Content className="main">
                <div className="page">
                    <Row gutter={[20, 20]}>
                        <Col span={24}>
                            <PageHeaderWrapper
                                routes={[
                                    {
                                        path: ROUTES.SAVED_PROFILES,
                                        breadcrumbName: 'Saved Profiles'
                                    },
                                ]}
                            />
                        </Col>
                        <Col style={{width:"100%"}}>
                            <div className="auth-container">
                                <div className="saved-profiles-container">
                                    {
                                        !!savedTechniciansList.length &&
                                        <Space className='header-section'>
                                            <Title className='your-list'>Saved Profiles</Title>
                                            <Space className='cursor-pointer' onClick={() =>{ setShowCreateListModal(true)
                                            form.resetFields()
                                            }}>
                                                <PlusCircleOutlined className='create-list-icon'/>
                                                <Paragraph className='create-list-text'>Create New List</Paragraph>
                                            </Space>
                                        </Space>
                                    }
                                    <div className='saved-card-lists'>
                                        {
                                            loadingList ? loadingListSkeleton() :
                                            <CustomRenderEmpty  image={EmptyStateImage}
                                            description={<p className='empty-list-desc'>Keep track of profiles that you want to save with lists. Start by creating your first list and saving a profile to it! </p>} 
                                            extra={
                                                <Button type='primary' onClick={() => setShowCreateListModal(true)}> 
                                                    + Create New List
                                                </Button>
                                            }>
                                                   <List
                                                grid={{
                                                    gutter: 16,
                                                    xs: 1,
                                                    sm: 2,
                                                    md: 3,
                                                    lg: 4,
                                                    xl: 4,
                                                    xxl: 4,
                                                }}
                                                dataSource={savedTechniciansList}
                                                renderItem={item => (
                                                <List.Item>
                                                    <Card
                                                    onClick={() => history.push(routes.SAVED_PROFILES_DETAILS.replace(":id", item.id))} 
                                                    title={capitalizeFirstLetter(item?.Group_Name)}
                                                    extra={
                                                        <Popover content={popoverContent(item)} trigger="click" placement='bottomRight' overlayClassName="list-card-popover" arrowPointAtCenter>
                                                            <EllipsisOutlined onClick={(e) => e.stopPropagation()} className='card-ellipsis'/>
                                                        </Popover>
                                                    }>
                                                        <div className="mb-2">
                                                            {
                                                                !!item.Technicians?.length ?
                                                                <Avatar.Group
                                                                    maxCount={3}
                                                                    size="large"
                                                                    maxStyle={{
                                                                        color: "#f56a00",
                                                                        backgroundColor: "#fde3cf",
                                                                    }}
                                                                    >
                                                                        {
                                                                            item?.Technicians.map((ele) => (
                                                                                <Avatar 
                                                                                    src={ele?.imgUrl}
                                                                                    style={{
                                                                                        color: "#f56a00",
                                                                                        backgroundColor: "#fde3cf",
                                                                                        textTransform: "uppercase",
                                                                                        fontWeight:500,
                                                                                    }}
                                                                                >
                                                                                    {ele?.Full_Name[0]}
                                                                                </Avatar>
                                                                            ))
                                                                        }
                                                                </Avatar.Group> :
                                                                <Avatar style={{
                                                                    color: "#f56a00",
                                                                    backgroundColor: "#fde3cf",
                                                                    marginBottom:3
                                                                }} size={42} icon={<UserAddOutlined />} />
                                                            }
                                                        </div>
                                                        {
                                                            item?.Description ?  <Paragraph className='card-list-description' ellipsis={{rows:2}}>{capitalizeFirstLetter(item.Description)}</Paragraph> : <Paragraph className='card-list-no-description'>Description not provided</Paragraph>
                                                        }
                                                    
                                                    </Card>
                                                </List.Item>
                                                )}
                                            />
                                            </CustomRenderEmpty>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Content> 
            <CreateListModal 
                showCreateListModal={showCreateListModal} 
                setShowCreateListModal={setShowCreateListModal}
                editState={editState}
                setEditState={setEditState}
                listId={selectedList?.id}
                getSavedTechniciansList={getSavedTechniciansList}
                form={form}
                isAddTechnician={false}
            />
            <DeleteListModal 
                showDeleteListModal={showDeleteListModal}
                setShowDeleteListModal={setShowDeleteListModal}
                listId={selectedList?.id}
                getSavedTechniciansList={getSavedTechniciansList}
                routeAfterDeleting={false}
            />
        </>
    )
}

export default SavedProfilesList