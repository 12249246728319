import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Space, Typography, notification } from 'antd'
import { AddedItemCard, AddedItemMessage } from './added-item'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import FaqList from './faq-list'
import BackIcon from '../assets/images/back-icon.svg'

const {Title, Paragraph} = Typography

const CreateItcFaq = ({handleNextClick, setShowBackBtn, itcId}) => {
    const tech = useSelector(state => state.tech)
    const [showAddedFaq, setShowAddedFaq] = useState(false)
    const [faqs, setFaqs] = useState([])
    const [loadingFaqs, setLoadingFaqs] = useState(false)
    const [editFaqData, setEditFaqData] = useState(null)

    const fetchFaqs = () => {
        setLoadingFaqs(true)
        ApiBaseHelper.get({
            url: `itc/faq/all?itc=${itcId}`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setFaqs(res?.data?.data)
            setLoadingFaqs(false)
        }).catch((error) => {
            console.error(error)
            setLoadingFaqs(false)
        })       
    }

    useEffect(() => {
        setShowBackBtn(showAddedFaq ? false : true)
     }, [showAddedFaq])

    useEffect(() => {
        if(faqs?.length > 0){
            setShowAddedFaq(true)
        }
    }, [faqs])

    useEffect(() => {
        fetchFaqs()
    }, [])

    return (
        <div className="create-itc-faq-section">
            <div className="create-itc-faq-ctn">
                {
                    showAddedFaq ? 
                    <AddedFaq 
                        handleNextClick={handleNextClick} 
                        setShowBackBtn={setShowBackBtn}
                        setShowAddedFaq={setShowAddedFaq}
                        showAddedFaq={showAddedFaq}
                        faqs={faqs}
                        setFaqs={setFaqs}
                        fetchFaqs={fetchFaqs}
                        loadingFaqs={loadingFaqs}
                        setEditFaqData={setEditFaqData}
                    /> :
                    <div className="create-faq-form-ctn">
                        <Title level={4} className="itc-form-title">Add New FAQ</Title>
                        <FaqForm 
                            setShowAddedFaq={setShowAddedFaq}
                            handleNextClick={handleNextClick}
                            faqs={faqs}
                            setFaqs={setFaqs}
                            itcId={itcId}
                            fetchFaqs={fetchFaqs}
                            editFaqData={editFaqData}
                            setEditFaqData={setEditFaqData}
                        />
                    </div>
                }
            </div>
        </div>
    )
}


export const FaqForm = ({setShowAddedFaq, handleNextClick, itcId, fetchFaqs, editFaqData, setEditFaqData, isFromItcProfile = false}) => {
    const tech = useSelector(state => state.tech)
    const [form] = Form.useForm();
    const [submittingFaq, setSubmittingFaq] = useState(false)
    const param = useParams()

    const handleFaqSubmit = (values) => {
        setSubmittingFaq(true)

        if(editFaqData){
            ApiBaseHelper.put({
                url: `itc/faq/${editFaqData.id}`,	
                headers: {
                    Authorization: 'Bearer ' + tech['token'],
                },
                payload: {
                    Question: values.question,
                    Answer: values.answer,
                }
            }).then(async (res) => {
                setSubmittingFaq(false)
                notification.success({
                    message: 'TecMe',
                    description: 'FAQ updated successfully'
                })
                setEditFaqData(null)
                await fetchFaqs()
                await setShowAddedFaq(isFromItcProfile ? false : true)
            }).catch((err) => {
                setSubmittingFaq(false)
                notification.error({
                    message: 'TecMe',
                    description: 'An error occurred while adding FAQ'
                })
                console.log(err)
            })
        }else{
            ApiBaseHelper.post({
                url: `itc/faq`,	
                headers: {
                    Authorization: 'Bearer ' + tech['token'],
                },
                payload: {
                        Question: values.question,
                        Answer: values.answer,
                        ITC: isFromItcProfile ? param.id : itcId,
                    }
            }).then(async (res) => {
                setSubmittingFaq(false)
                notification.success({
                    message: 'TecMe',
                    description: 'FAQ added successfully'
                })
                await fetchFaqs()
                await setShowAddedFaq(isFromItcProfile ? false : true)
            }).catch((err) => {
                setSubmittingFaq(false)
                notification.error({
                    message: 'TecMe',
                    description: 'An error occurred while adding FAQ'
                })
                console.log(err)
            })
        }
    }

    useEffect(() => {
        if(editFaqData){
            form.setFieldsValue({
                question: editFaqData?.Question,
                answer: editFaqData?.Answer,
            })
        }
    
    }, [editFaqData])

    return(
        <Form
            onFinish={handleFaqSubmit}
            form={form}
            layout='vertical'
            className="create-itc-faq-form"
        >
            <Form.Item  
                name="question"  
                label="Question" 
                className='itc-form-label-item'
                rules={[{ required: true, message: 'Please input your question' }]}
            >
                <Input
                    type="text" 
                    placeholder="Ex. What types of IT services do you offer?" 
                    size="large"
                />
            </Form.Item>  
            <Form.Item  
                name="answer"  
                label="Answer" 
                className='itc-form-label-item'
                rules={[{ required: true, message: 'Please input your answer' }]}
            >
                <Input.TextArea 
                    rows={4} 
                    placeholder="Ex. We specialize in a range of IT services including cloud computing, cybersecurity, network management, and more" 
                    size="large"
                />
            </Form.Item>  
           { 
                !isFromItcProfile ?
                <Form.Item className="itc-form-label-item">
                    <Space>
                        <Button
                            className='itc-next-btn' 
                            type='primary' 
                            htmlType='submit'
                            loading={submittingFaq}
                        >Next</Button>
                        <Button className='itc-next-btn' type='secondary' onClick={() => handleNextClick()}>Skip</Button>
                    </Space>
                </Form.Item>:
                <Form.Item>
                    <Space>
                        <Button 
                            className='itc-next-btn' 
                            type='secondary'
                            onClick={() => {
                                setShowAddedFaq(false)
                                setEditFaqData(null)
                            }}
                        >
                            Back
                        </Button>
                        <Button 
                            className='itc-next-btn' 
                            type='primary' 
                            htmlType='submit'
                            loading={submittingFaq}
                        >
                            Submit
                        </Button>
                    </Space>
                </Form.Item>
            }
        </Form>
    )
}

const AddedFaq = ({handleNextClick, setShowBackBtn, setShowAddedFaq, showAddedFaq, fetchFaqs, faqs, loadingFaqs, setEditFaqData}) => {
    useEffect(() => {
        setShowBackBtn(showAddedFaq ? false : true)
     }, [showAddedFaq])

    return(
        <div className='added-faq-section'>
        <Space size={10} 
            onClick={() => {
                setShowAddedFaq(false)
            }} 
            className='itc-back-btn'
        >
            <img src={BackIcon} alt="Back Icon" />
            <span>Go Back</span>
        </Space>
        <div className="added-faq-ctn">
            {/* <AddedItemMessage message={"FAQ added successfully"} /> */}
            <Title level={4} className="itc-form-title">FAQs</Title>
            <FaqList
                faqs={faqs} 
                setShowAddedFaq={setShowAddedFaq}
                fetchFaqs={fetchFaqs}
                handleNextClick={handleNextClick}
                setShowBackBtn={setShowBackBtn}
                loadingFaqs={loadingFaqs}
                setEditFaqData={setEditFaqData}
            />
        </div>
    </div>
    )
}

export default CreateItcFaq