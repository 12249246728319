import React from 'react'
import { ClockCircleOutlined, HomeOutlined, PushpinOutlined } from '@ant-design/icons'
import '../assets/css/job-post-details.scss'
import { Typography } from 'antd'
import { convertBudget, getDurationInfo, getWorkTypeInfo } from '../utils/helper'
import { getDottedTimeFormat } from '../../../../../../utils/helper'


const {Paragraph, Title} = Typography
const JobPostDetails = ({jobPost}) => {
  return (  
        <div className="job-post-details-card">
            <div className="job-post-details-heading">
                <Paragraph style={{margin:0}}>{convertBudget(jobPost?.Budget, jobPost?.Payment_Type)}</Paragraph>
            </div>
            <div className="job-post-details-content">
                <div className="job-post-details-content-item">
                    <ClockCircleOutlined className="job-post-details-content-icon" />
                    <div className="job-post-details-content-subitem">
                        <Paragraph>{getDurationInfo(jobPost?.Duration)?.text}</Paragraph>
                        <Paragraph>{getDurationInfo(jobPost?.Duration)?.subText}</Paragraph>
                    </div>
                </div>
                <div className="job-post-details-content-item">
                    <HomeOutlined className="job-post-details-content-icon"/>
                    <div className="job-post-details-content-subitem">
                        <Paragraph>{getWorkTypeInfo(jobPost?.Work_Type)?.text}</Paragraph>
                        <Paragraph>{getWorkTypeInfo(jobPost?.Work_Type)?.subText}</Paragraph>
                    </div>
                </div>
                <div className="job-post-details-content-item">
                    <PushpinOutlined className="job-post-details-content-icon"/>
                    <div className="job-post-details-content-subitem">
                        <Paragraph>Published On</Paragraph>
                        <Paragraph>{getDottedTimeFormat(jobPost?.CreatedDate)}</Paragraph>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JobPostDetails