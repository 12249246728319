import React, { useState } from 'react'
import { Button, Select, Space, Tabs, Tag, Typography } from 'antd'
import { capitalizeFirstLetter, truncateText } from '../../../../../../utils/helper'
import { useSelector } from 'react-redux'
import { getSpecializationTitle } from '../../../../common/private/job-post/utils/helper'

const { Title, Paragraph } = Typography

const workCategories = [
    {
        Category: {
            Title: "Computer Repair"
        },
        Title: "Computer Repair",
        Hourly_Rate: 0,
        Includes_Travel_Fee: false,
        Introduction:
            "I am a professional IT technician with over 10 years of experience. I have worked with a wide range of clients, from small businesses to large corporations. I am passionate about helping people and solving their problems. I am also a certified Microsoft Office Specialist (MOS) and have been teaching Microsoft Office for the past 5 years.",
        Skills: "Computer Repair|Computer Setup|Computer Training|Data Recovery|Hardware Installation|Hardware Repair|Home Theater Setup|Network Configuration|Network Setup",
        WorkType: "Both"
    },
    {
        Category: {
            Title: "Computer Setup"
        },
        Title: "Computer Setup",
        Hourly_Rate: 0,
        Includes_Travel_Fee: false,
        Introduction:
            "I am a professional IT technician with over 10 years of experience. I have worked with a wide range of clients, from small businesses to large corporations. I am passionate about helping people and solving their problems. I am also a certified Microsoft Office Specialist (MOS) and have been teaching Microsoft Office for the past 5 years.",
        Skills: "Computer Repair|Computer Setup|Computer Training|Data Recovery|Hardware Installation|Hardware Repair|Home Theater Setup|Network Configuration|Network Setup",
        WorkType: "Both"
    },
    {
        Category: {
            Title: "Computer Training"
        },
        Title: "Computer Training",
        Hourly_Rate: 0,
        Includes_Travel_Fee: false,
        Introduction:
            "I am a professional IT technician with over 10 years of experience. I have worked with a wide range of clients, from small businesses to large corporations. I am passionate about helping people and solving their problems. I am also a certified Microsoft Office Specialist (MOS) and have been teaching Microsoft Office for the past 5 years.",
        Skills: "Computer Repair|Computer Setup|Computer Training|Data Recovery|Hardware Installation|Hardware Repair|Home Theater Setup|Network Configuration|Network Setup",
        WorkType: "Both"
    },
]



const ItcServices = ({ itcContent, brandColor }) => {

    const generalSkills = useSelector((state) => state.generalSkillsGlobal)
    const [categoryIndex, setCategoryIndex] = useState(0)
    const [workCategoryId, setWorkCategoryId] = useState(null)

    const getSelectedWorkCategory = () => {
        return workCategories.find((ele) => ele?.Title.toLowerCase().replaceAll(" ", "-") === (workCategoryId || workCategories[0]?.Title.toLowerCase().replaceAll(" ", "-")))
    }

    if(itcContent?.Categories?.length === 0) return null

    return (
        <section className='itc-services-section' id="specializations">
            <div className="itc-services-ctn">
                <div className="services-title">
                    <div className="itc-top-border" style={{background:brandColor}}></div>
                    <Title level={1} className="itc-services-heading">Our Specializations</Title>
                </div>
                <div className="services-content">
                    {/*Work Categories Desktop*/}
                    {
                        (itcContent?.Categories?.length > 0) &&
                        <div className="service-tab-ctn">
                            <div className="service-tab-left-pane">
                                <div className="service-tab-menu">
                                    {
                                        itcContent?.Categories?.map((category, index) => {
                                            return(
                                                <p 
                                                    key={category?.Title.toLowerCase().replaceAll(" ", "-")}
                                                    onClick={() => setCategoryIndex(index)}
                                                    style={{
                                                        color: index === categoryIndex ? `${brandColor}` : ''
                                                    }}
                                                >
                                                    {truncateText(capitalizeFirstLetter(category?.Title), 35)}
                                                </p>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="service-tab-right-pane">
                                <div className="work-category-info">
                                    <Space className='d-flex justify-content-between align-items-center'>
                                        <Space size={0} direction="vertical">
                                            <Title level={3} style={{margin:0}}>{capitalizeFirstLetter(itcContent?.Categories[categoryIndex]?.Category?.Title)} </Title>
                                         
                                            <Paragraph className="specialized">Specialized in <span style={{color:brandColor}}>{capitalizeFirstLetter(itcContent?.Categories[categoryIndex]?.Title)}</span> </Paragraph>
                                            {
                                                itcContent?.Categories[categoryIndex]?.Work_Type && (
                                                    <div className="specialization-work-type">
                                                    {itcContent?.Categories[categoryIndex]?.Work_Type === "Both" ? "Remote and On site" : itcContent?.Categories[categoryIndex]?.Work_Type === "Remote" ? "Remote" : "On site"}
                                                </div>
                                                )
                                            }
                                        </Space>
                                    </Space>
                                    {
                                            itcContent?.Categories[categoryIndex]?.Introduction && itcContent?.Categories[categoryIndex]?.Introduction.toLowerCase()!="undefined" &&
                                            <>
                                                <Title className="introduction-title" level={4}>Introduction</Title>
                                                <Paragraph className="introduction-text" ellipsis={{rows:8, expandable:true, symbol:"Show More"}}>{capitalizeFirstLetter(itcContent?.Categories[categoryIndex]?.Introduction)  || 'No introduction'}</Paragraph>
                                            </>
                                        }
                                </div>
                                {
                                    itcContent?.Categories[categoryIndex]?.Skills &&
                                    <div className="category-skills-ctn">
                                        <div className="category-skills-content">
                                            <Title level={4}>Skills</Title>
                                            <div className="skill-tag-list">
                                                {
                                                    itcContent?.Categories[categoryIndex]?.Skills.split("|")?.map((item) => <Tag>{item}</Tag>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }     
                            </div>
                        </div>
                    }
                    {/*Work Categories Mobile*/}
                    {
                        (itcContent?.Categories?.length > 0) && (
                            <div className="work-categories-ctn-mobile" id="work-category-section">
                                <Select
                                    defaultValue={itcContent?.Categories[0]?.Title}
                                    style={{ width: "100%", height:32, color: "rgba(0, 0, 0, 0.85)"}}
                                    onChange={(value) => setWorkCategoryId({workCategoryId: value})}
                                    options={itcContent?.Categories?.map((category) => {
                                        return {
                                            value: category?.Title.toLowerCase().replaceAll(" ", "-"),
                                            label: category?.Title
                                        }
                                    })}
                                />
                                <div className="mobile-introduction">
                                    <Title level={4}>{getSelectedWorkCategory()?.Title || 'No Title' } </Title>
                                    {
                                        getSelectedWorkCategory()?.Title != "General Work" &&
                                        <>
                                            <Paragraph className="specialized">Specialized in <span style={{color:brandColor}}>{getSelectedWorkCategory()?.Title}</span> </Paragraph>
                                            {
                                                getSelectedWorkCategory()?.WorkType && (
                                                    <div className="specialization-work-type">
                                                    {getSelectedWorkCategory()?.WorkType === "Both" ? "Remote and On site" : getSelectedWorkCategory()?.WorkType === "Remote" ? "Remote" : "On site"}
                                                </div>
                                                )
                                            }
                                        </>
                                    }
                                    {
                                    getSelectedWorkCategory()?.Title != "General Work" && 
                                    <>
                                        <Space size={8} direction="vertical" className="my-2">
                                            {
                                                getSelectedWorkCategory()?.Hourly_Rate &&
                                                <Title style={{margin:0}} level={3}>${getSelectedWorkCategory()?.Hourly_Rate}/hr</Title>
                                            }
                                            {
                                                getSelectedWorkCategory()?.Includes_Travel_Fee &&  
                                                <Paragraph style={{margin:0, color:"rgba(0, 0, 0, 0.6)"}}>Travel fees may apply for on-site help</Paragraph>
                                            }
                                        </Space>
                                        <Title level={5} style={{marginTop:10}}>Introduction</Title>
                                        <Paragraph ellipsis={{rows:8, expandable:true, symbol:"Show More"}}>{getSelectedWorkCategory()?.Introduction}</Paragraph>
                                    </>
                                    }
                                </div>
                                {
                                    getSelectedWorkCategory()?.Skills != null && (
                                        <div className="skill-tag-list-mobile">
                                            <Title level={5} style={{marginBottom:15}}>Skills</Title>
                                            {
                                                getSelectedWorkCategory()?.Skills.split("|")?.map((item) => <Tag>{item}</Tag>)
                                            }
                                        </div>
                                    )
                                }

                            </div>
                        )
                    }
                </div>
            </div>
        </section>
    )
}

export default ItcServices