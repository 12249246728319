import { Avatar, Button, Card, Col, Row, Skeleton, Space, Tooltip, Typography } from 'antd';
import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser';
import Moment from 'moment';
import {extendMoment} from 'moment-range';
import PushpinOutlined from "@ant-design/icons/lib/icons/PushpinOutlined";
import { SAMPLE_HIPAA_ID, SECONDARY_COLOR } from '../../../../../../utils/constants';
import { decryptString, nameFormat, truncateText } from '../../../../../../utils/helper';
import { FirestoreHelper } from '../../../../../../utils/firebase-helper';



const {Paragraph} = Typography
const moment = extendMoment(Moment);
let participantsObject = {};


export default class ConversationCell extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.item,
            loading: true,
            participants: [],
            participant:{
                "display_name":""
            },
        }
    }

    async componentDidMount() {
        let data = this.props.item;
        if (data.group == null) {
            let currentUser = this.props.user;
            let participantId = '';
            data.participants.map((hipaaId, index) => {
                if (hipaaId != currentUser.id)
                    participantId = hipaaId;
            })

            if (participantId != '') {
                if (participantId in participantsObject) {
                    data.recent_message.user_data = participantsObject[participantId];
                } else {
                    let user = await FirestoreHelper.getUser(participantId);
                    data.recent_message.user_data = user.data();
                    participantsObject[participantId] = user.data();
                    this.props.filteredConversations.push({
                        title: user.data().display_name,
                        conversation: data
                    })
                }
            }
            // let participantIds = data.participants;
            // let currentUserIdIndex = participantIds.indexOf(this.props.user.id);
            // let participantsResponse = await FirestoreHelper.getUsers(participantIds);
            // let participants = [];
            // participantsResponse.forEach((doc) => {
            //     let item = doc.data();
            //     participantsObject[item.hipaa_id] = item
            // });


            // let participantIds2 = JSON.parse(JSON.stringify(data.participants));
            // if (currentUserIdIndex > -1)
            //     participantIds2.splice(currentUserIdIndex, 1);
            // let participant2 = this.props.user;

            // if (participantIds2[0] in participantsObject) {
            //     participant2 = participantsObject[participantIds2[0]];
            // }


            // this.setState({
            //     participants,
            //     participant: participant2,
            // });
        } 
        else {
            // console.log(data)
            this.props.filteredConversations.push({
                title: data.group,
                conversation: data
            })
        }

      
        this.setState({
            data,
            loading: false
        })
    }

    render() {
        let {data, loading, participants, participant} = this.state;
        if (loading)
            return <div className='conversation-cell-skeleton'>
                <Skeleton loading={true}
                          active
                          avatar={<Avatar
                              className="ant-avatar-shap"
                              shape="circle"/>}
                          paragraph={false}/>
            </div>;
        let user = data.recent_message.user_data;

        let {activeConversation} = this.props;
        let isFavorite = data.marked_favorite.includes(this.props.user.id);
        let message = decryptString(data.recent_message.message);
        message = message.split('<br>')[0];
        if (message.length > 20) message = message.substring(0, 20) + '...';

        const currentChatMember = data?.owners?.find((item) => item.hipaa_id !== this.props.user.id)
        const currentGroupName = data?.group
        // console.log(data?.group)
        // console.log(currentGroupName)

        return <Card className={`ant-card-buttom user-chat ${activeConversation != null && activeConversation.id == data.id ? 'chat-selected' : ''}`}
                     onClick={() => {
                         this.props.onClickConversation(data);
                     }}>
            <div className={`${activeConversation != null && activeConversation.id == data.id ? 'inner-card-active' : "inner-card"}`}>
                <div className='chat-cell-ctn'>
                    {
                        user &&
                        <div className='chat-cell-avatar'>
                            {
                               user?.profile_picture == null ?
                                    <Avatar
                                        style={{
                                            // color: '#f56a00',
                                            backgroundColor: SECONDARY_COLOR,
                                            textTransform: 'uppercase',
                                            fontWeight: 'bold',
                                            marginTop: 5
                                        }}
                                        size={44}
                                    > {user?.display_name[0]} </Avatar>
                                    :
                                    <Avatar src={user?.profile_picture} size={44} style={{marginTop: 5}}/>
                            }

                        </div>
                    }
                    {
                        data.group != null &&
                        <div className='chat-cell-avatar'>
                            <Avatar
                                src={currentChatMember?.profile_picture}
                                style={{
                                    // color: '#f56a00',
                                    backgroundColor: SECONDARY_COLOR,
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                    marginTop: 5
                                }}
                                size={44}
                            >{ currentGroupName[0]?.toUpperCase()}</Avatar>
                        </div>
                    }

                    <div className='chat-cell-info'>
                        <Row gutter={[5, 0]}>
                            <Col span={24}>
                                <Space align={"middle"} className='d-flex justify-content-between'  style={{width:"100%"}}>
                                    <div>
                                        <Paragraph style={{
                                            fontWeight: 500,
                                            margin: 0,
                                            fontSize: '14px',
                                            textTransform: 'capitalize',
                                            color:"rgba(0, 0, 0, 0.85)"
                                        }}
                                        title= {data.group != null ? currentGroupName : nameFormat(user?.display_name)}
                                        ellipsis={{rows:1}}>
                                            {data.group != null ? (truncateText(currentGroupName, 24)) :truncateText(nameFormat(user?.display_name), 24)}
                                        </Paragraph>
                                    </div>
                                    <div>
                                        <Tooltip title={isFavorite ? "Unpin chat" : "Pin chat to the top"}>
                                            <Button
                                                type={isFavorite ? "primary" : "default"}
                                                shape="circle"
                                                size={"small"}
                                                icon={<PushpinOutlined/>}
                                                onClick={() => {
                                                    let favoriteListTemp = data.mark_favorite ? data.mark_favorite : [];
                                                    if (isFavorite) {
                                                        const favoriteIndex = favoriteListTemp.indexOf(this.props.user.id);
                                                        if (favoriteIndex > -1) {
                                                            favoriteListTemp.splice(favoriteIndex, 1); // 2nd parameter means remove one item only
                                                        }
                                                    } else
                                                        favoriteListTemp.push(this.props.user.id)

                                                    FirestoreHelper.markFavorite({
                                                        conversation_id: data.id,
                                                        marked_favorite: favoriteListTemp
                                                    })
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                </Space>
                            </Col>
                            <Col span={24}>
                                <Row justify={"space-between"} align={"middle"}>
                                    <Col>
                                        <Paragraph style={{
                                            margin: 0,
                                            color:(activeConversation != null && activeConversation.id == data.id) ? "rgba(0, 0, 0, 0.85)" : "rgba(0, 0, 0, 0.65)"
                                        }}
                                        ellipsis={{rows: 1}}
                                        >
                                            {ReactHtmlParser(message)}
                                        </Paragraph>
                                    </Col>
                                    <Col>
                                        <Paragraph style={{
                                            fontSize: '12px',
                                            margin: 0,
                                            color:"rgba(0, 0, 0, 0.65)"
                                        }}>
                                            {data.recent_message.sent_at ? moment.utc(data.recent_message.sent_at.toDate()).local(false).fromNow() : moment().local(false).fromNow()}
                                        </Paragraph>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    </div>
                </div>
            </div>
        </Card>
    }

}