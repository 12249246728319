import React from 'react'
import { Link } from 'react-router-dom';
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Button, Card, Col, Empty, Image, Input, List, Modal, notification, Result, Row, Skeleton, Space, Spin, Table, Tag, Tooltip, Typography } from 'antd';
import { useSelector } from 'react-redux';
import ROUTES from '../../../../../../../utils/routes'
import { ApiBaseHelper } from '../../../../../../../utils/api-base-helper';
import { AimOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { SECONDARY_COLOR } from '../../../../../../../utils/constants';
import NoServiceFound from '../../assets/images/service ui-whitebg.png';
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from 'react';
import SearchInput from '../../../../../../components/search-input';
import CustomRenderEmpty from '../../../../../../components/custom-render-empty';
import PageTitle from '../../../../../../components/page-title';

const ServicesTable = ({data, services, history, schedule, techProfile, isHipaaExpert, loading}) => {


    const {admin, tech} = useSelector((state) => state)
    const isAdmin = admin === null ? false : true
    const [serviceData, setServiceData] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [deletingService, setDeletingService] = useState(false)
    const [deleteServiceId, setDeleteServiceId] = useState()
    const [showServiceDeleteModal, setShowServiceDeleteModal] = useState(false)
    let token = isAdmin ? admin.token : tech.token;

    const {Search} = Input;
    const { Paragraph, Text, Title } = Typography
    const { confirm } = Modal

    const antIcon = <LoadingOutlined style={{ fontSize: 22, color: SECONDARY_COLOR}} spin />
    
    const handleServiceSearch = () => {
        const searchedServices = data.filter((service) => {
            return service.Service_Title.toLowerCase().includes(searchTerm.toLowerCase()) || service?.Device_Types?.toLowerCase().includes(searchTerm.toLowerCase())
        })

        return searchedServices
    }

    const handleServiceDelete = () => {
        setDeletingService(true)
        setDeleteServiceId(deleteServiceId)
        ApiBaseHelper.delete({
            url: `services/${deleteServiceId}`,
            headers: {
                Authorization: 'Bearer ' + token,
            },
        }).then((response) => {
            setDeletingService(false)
            notification['success']({
                message: 'TecMe',
                description:
                    response.data.message,
            });
            const filteredData = serviceData.filter((item) => (item.Id !== deleteServiceId))
            setServiceData(filteredData)
            setShowServiceDeleteModal(false)
        }).catch((error) => {
            console.log(error)
        })
            
    }

    const getPricingModel = (pricing) => {
        let pricingModel = '';
        if (pricing?.Type == 'Recurring') {
            if (pricing?.Interval_Count == 1)
                pricingModel = `/${pricing?.Interval}`
            else if (pricing?.Interval_Count > 1)
                pricingModel = ` every ${pricing?.Interval_Count} ${pricing?.Interval}s`
        } else {
            if (pricing?.Usage_Type == 'Metered')
                pricingModel = `/hour`;
        }

        return <Text>${(pricing.Unit_Amount/100).toFixed(2)}{pricingModel}</Text>
    }

    const servicesColumns =  [
        {
            title: 'Title',
            dataIndex: 'Service_Title',
            key: 'title',
            width:'20%',
            render:(_, record) => (
                <Paragraph style={{margin:0, fontWeight:600}}>{record.Service_Title}</Paragraph>
            ),
            sorter: {
                compare: (a, b) => a.Service_Title.localeCompare(b.Service_Title),
            },
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'description',
            width:'25%',
            ellipsis: {
                showTitle: false,
              },
            render: (_, record) => (
            <Tooltip placement="topLeft" title={record.Description}>
                {record.Description}
            </Tooltip>
            )
            // render:(_, record) => (
            //     <Paragraph ellipsis={true ? { rows: 2, expandable: true, symbol: <Text style={{color:"#F04A22"}}>more</Text> } : false} >{record.Description}</Paragraph>
            // )
        },
        {
            title: 'Price',
            dataIndex: 'Price',
            key: 'price',
            render: (_, record) => (
                getPricingModel(record.Pricing[0])
            )
        },
        {
            title: 'Location',
            dataIndex: 'Location',
            key: 'location', 
            render: (_, record) => (
                <Tag color={record.Location == 'on_site' ? 'blue' : 'volcano'}  style={{textTransform:"capitalize"}}>{record.Location.replaceAll("_", " ")}</Tag>
            )
        },
        {
            title: 'Device Types',
            dataIndex: 'Device_Types',
            key: 'device',
            width:'20%',
            render: (_, record) => (
                <Space wrap>
                    {
                        record.Device_Types !== null ? record.Device_Types.split(",").map((device, i) => (
                            <Tag>
                                {device}
                            </Tag>
                        
                        )) : <Text>N/A</Text>
                    }
                </Space>
            ),
            sorter: {
                compare: (a, b) => a.Device_Types !== null && a.Device_Types.split(",")[0].localeCompare(b.Device_Types !== null && b.Device_Types.split(",")[0]),
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => (
                <Space>
                    <Link to={
                       {pathname: ROUTES.EDIT_SERVICES, state: {service:record, techProfile, schedule, isHipaaExpert}}}
                    >
                        <EditRoundedIcon
                            fontSize="large" style={{
                                color: "#1D3466",
                                borderRadius: '50%',
                                backgroundColor: '#F3F3F3',
                                padding: '0.3rem',
                                marginRight: '00.5rem'
                            }} 
                        />
                    </Link>
                    {
                        deletingService && record.Id === deleteServiceId ? <Spin indicator={antIcon} /> : 
                        <DeleteRoundedIcon fontSize="large" 
                            style={{
                                color: '#F04A22',
                                borderRadius: '50%',
                                backgroundColor: '#F3F3F3',
                                padding: '0.3rem',
                                cursor:'pointer'
                            }}
                            onClick={() => {
                                setDeleteServiceId(record.Id)
                                setShowServiceDeleteModal(true)
                            }}
                        />
                    }
                </Space>
            )
        },
    ];


    const fetchService = () =>{
        setServiceData(data)
    }

    useEffect(() => {
        fetchService()
    }, [data])


    return (
        <div>
            <PageTitle 
                title="Services"
                extra={[
                    <Button>
                        <Link to={
                            {
                                pathname: ROUTES.ADD_SERVICES,
                                state: {isHipaaExpert}
                            }
                        }>
                            + Add New Service
                        </Link>
                    </Button>
                ]}
                search={
                    <SearchInput
                        placeholder="Search Services"
                        onChange={(e) => {
                            setSearchTerm(e.target.value)
                        }}
                    />
                }
                
            />
            <Row>
                <Col xs={0} sm={0} md={0} lg={24} xl={24}>
                    <CustomRenderEmpty 
                        description={"Add the IT services you can do to your tech profile so customers can book them."}
                        extra={
                            <Button>
                                <Link to={
                                    {
                                        pathname: ROUTES.ADD_SERVICES,
                                        state: {isHipaaExpert}
                                    }
                                }>
                                    + Add New Service
                                </Link>
                            </Button>
                        }>
                        <Table  scroll={{ x: 900}} dataSource={searchTerm ? handleServiceSearch() : serviceData} columns={servicesColumns} loading={loading} />
                    </CustomRenderEmpty>
                </Col>
                <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                    {
                        loading ? <LoadingCards /> :
                         <CustomRenderEmpty 
                            description={"Add the IT services you can do to your tech profile so customers can book them."}
                            extra={
                                <Button>
                                    <Link to={
                                        {
                                            pathname: ROUTES.ADD_SERVICES,
                                            state: {isHipaaExpert}
                                        }
                                    }>
                                        + Add New Service
                                    </Link>
                                </Button>
                            }> 
                             <List
                                 grid={{ gutter: 16,  xs: 1,
                                     sm: 2,
                                     md: 2,
                                     lg: 3,
                                     xl: 3,
                                     xxl: 3, }}
                                 dataSource={searchTerm ? handleServiceSearch() : serviceData}
                                 pagination={{
                                     pageSize: 10,
                                 }}
                                 renderItem={service => (
                                 <List.Item>
                                    <Card 
                                        key={service.Id} 
                                        title={
                                        <Paragraph style={{margin:0}} strong>{service?.Service_Title}</Paragraph>
                                        }
                                    style={{marginBottom:20}}>
                                    <Paragraph  ellipsis={{rows:2}} strong>
                                        {service?.Description}
                                    </Paragraph>
                                    <div style={{marginBottom:20}}>
                                        <Space wrap>
                                            <AimOutlined style={{fontSize: 20}}/>
                                            <Text style={{textTransform:"capitalize"}}>{service.Location.replaceAll("_", " ")}</Text>
                                        </Space>
                                    </div>
                                    <div style={{marginBottom:20}}>
                                        {
                                            service.Device_Types !== null && 
                                                <Space wrap>
                                                {
                                                    service.Device_Types.split(",").slice(0, 4).map((device, i) => (
                                                            <Tag>
                                                                {device}
                                                            </Tag>
                                                        ))} 
                                                </Space>
                                        }
                                    </div>
                                    <div style={{display:'flex', justifyContent:'space-between', columnGap:20}}>
                                        <Button 
                                            style={{ borderRadius: 2, border: '1px solid #F04A22', color:'#F04A22', padding:'0 12px', cursor: 'pointer', width:"100%", display:'block', height:38}}>
                                             <Link to={
                                                {pathname: ROUTES.EDIT_SERVICES, state: {service:service, techProfile, schedule, isHipaaExpert}}}
                                                >
                                                      Edit
                                                </Link>
                                        </Button> 
                                        <Button 
                                            style={{ borderRadius: 2, border: '1px solid #F04A22', color:'#F04A22', padding:'0 12px', cursor: 'pointer', width:"100%", display:'block', height:38}}
                                            onClick={() => {
                                                setDeleteServiceId(service.Id)
                                                setShowServiceDeleteModal(true)
                                            }}>
                                            {
                                                deletingService && service.Id === deleteServiceId ? <Spin indicator={antIcon} /> : "Delete" 
                                            }
                                        </Button>
                                    </div>
                                    </Card>
                                 </List.Item>
                                 )}
                             />  
                         </CustomRenderEmpty>    
                    }
                </Col>
            </Row>
            <Modal 
                    visible={showServiceDeleteModal}
                    onCancel={() => {
                        setShowServiceDeleteModal(false)
                    }} 
                    footer={null}
                    >
                         <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
                        <Paragraph className='my-2 disclaimer p-2'>
                        This action will permanently delete this service and cannot be undone. Please make sure you want to delete the item before proceeding. 
                        </Paragraph>
                        <Space align='center' className='w-100 justify-content-end'>
                            <Button type='text' onClick={() => setShowServiceDeleteModal(false)}>Cancel</Button>
                            <Button type='primary' htmlType="submit" loading={deletingService} onClick={handleServiceDelete}>Delete</Button>
                    </Space>
                </Modal>
        </div>
    )
}

const LoadingCards = () => {
    return(
        <Row gutter={[20, 20]}>
        { [...Array(7).keys()].map(() => {
            return(
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                    <Card title={<Skeleton.Input />}
                        style={{
                            height: "auto",
                            width:"auto",
                        }}
                    >
                        <Skeleton />
                    </Card> 
                </Col>
            )
        }) }
     </Row>
    )
}


export default ServicesTable