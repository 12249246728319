// External Imports
import { Modal, Typography, Button, Space, Image, Checkbox } from 'antd'
import { PlusOutlined } from "@ant-design/icons"

// Internal Imports
import { getCardDetails } from '../../../new-invoice/components/invoice-payment-method'

const { Text, Paragraph } = Typography

const PaymentChangeModal = ({ visible, onOk, onCancel, selectedPaymentMethod, setSelectedPaymentMethod, paymentMethods, disabled, handleAddNewPaymentMethodClick, handlePaymentMethodCheckboxChange, spinComponent, changingPaymentMethodId }) => {
    return (
        <Modal
            title={<Text strong>Change payment method</Text>}
            visible={visible}
            onCancel={onCancel}
            onOk={onOk}
            footer={false}
            centered
            className='payment-change-modal'
        >
            <Space direction='vertical' size={20} className='w-100'>
                {paymentMethods.filter(method => method.id !== selectedPaymentMethod?.id).map(method => {
                    const name = method.card.brand
                    const id = method.id
                    const expMonth = method.card.exp_month
                    const expYear = method.card.exp_year
                    const cardDetails = getCardDetails(name);

                    return (
                        <Space
                            className={`w-100 payment-method position-relative ${changingPaymentMethodId === id ? 'selected-payment-method justify-content-center align-items-center' : 'justify-content-between align-items-start'}`}
                            key={id}
                        >
                            {changingPaymentMethodId === id ? (
                                spinComponent
                            ) : (
                                <>
                                    <Space className='align-items-start'>
                                        <Image
                                            src={cardDetails.logo}
                                            width={40}
                                            height={25}
                                            preview={false}
                                            alt={cardDetails.title}
                                            className={name === "amex" ? "payment-image" : ""}
                                        />
                                        <Space direction='vertical' size={0}>
                                            <Paragraph className='m-0 fs-6' strong>{cardDetails.title} **** {method.card?.last4}</Paragraph>
                                            <Paragraph type='secondary' className='m-0 font-size-14'>Expiry {expMonth}/{expYear}</Paragraph>
                                        </Space>
                                    </Space>
                                    <Checkbox
                                        style={{ borderRadius: '50%' }}
                                        checked={id === selectedPaymentMethod?.id}
                                        onChange={() => handlePaymentMethodCheckboxChange(method)}
                                        disabled={disabled}
                                    />
                                </>
                            )}
                        </Space>
                    )
                })}
                <Button className='w-100 add-payment-method-button d-flex align-items-center justify-content-center' disabled={disabled} onClick={handleAddNewPaymentMethodClick}>
                    + Add new payment method
                </Button>
            </Space>
        </Modal>
    )
}

export default PaymentChangeModal