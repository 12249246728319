import React from 'react'
import { Progress, Space, Typography } from 'antd'
import './assets/css/styles.scss'
import { LeftOutlined } from '@ant-design/icons'
import CompanyDetails from './components/company-details'
import { useState } from 'react'
import ExtraCompanyDetails from './components/extra-company-details'
import PaymentDetails from './components/payment-details'
import TeamDetails from './components/team-details'
import SuccessOnboarding from './components/success-onboarding'
import { useDispatch, useSelector } from 'react-redux'
import { capitalizeFirstLetter } from '../../../../utils/helper'
import { useHistory } from 'react-router-dom'
import StepsLayout from './components/steps-layout'

const {Paragraph, Title} = Typography

const ClientOnboarding = () => {
    const user = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const [currentIndex, setCurrentIndex] = useState(0)
    const [progress, setProgress] = useState(0)
    const [progressIndex, setProgressIndex] = useState(0)
    const history = useHistory()
    const [companyDetails, setCompanyDetails] = useState({})
    const [extraCompanyDetails, setExtraCompanyDetails] = useState({})
    const [paymentDetails, setPaymentDetails] = useState({})

    const progressSteps = [
        {
            step:1,
            title: `Company details (${currentIndex == 0 ? '0' : currentIndex == 1 ? '1' : '2'}/2 steps)`,
            description: 'This will help us automate and personalize your experience',
        },
        {
            step:2,
            title: `Payment & billing details (${currentIndex >= 3 ? '1' : '0'}/1 steps)`,
            description: 'Don’t worry. This is not to be charged, but is necessary for account activation'
        },
        {
            step:3,
            title: `Team (${currentIndex >= 4 ? '1' : '0'}/1 steps)`,
            description: 'Here you can invite other members to join your team'
        }
    ]

    const handleNextClick = () => {
        if(currentIndex < onboardingSteps.length - 1){
            setCurrentIndex(currentIndex + 1)
            setProgress(((currentIndex+1) / stepsLength) * 100)
        }
    }

    const handleBackClick = () => {
        if(currentIndex > 0){
            setCurrentIndex(currentIndex - 1)
            setProgress(((currentIndex-1) / stepsLength) * 100)
            if(progressIndex > 0){
                setProgressIndex(progressIndex - 1)
            }
        }
    }

    const onboardingSteps = [
        {
            step:1,
            content:<CompanyDetails 
                handleNextClick={handleNextClick} 
                setProgressIndex={setProgressIndex} 
                companyDetails={companyDetails}
                setCompanyDetails={setCompanyDetails}
                user={user}
            />,
        },
        {
            step:2,
            content: <ExtraCompanyDetails 
                handleNextClick={handleNextClick} 
                setProgressIndex={setProgressIndex}
                extraCompanyDetails={extraCompanyDetails} 
                setExtraCompanyDetails={setExtraCompanyDetails}
                user={user}
            />
        },
        {
            step:3,
            content: <PaymentDetails 
                handleNextClick={handleNextClick} 
                setProgressIndex={setProgressIndex} 
                setPaymentDetails={setPaymentDetails}
                paymentDetails={paymentDetails}
                user={user}
            />
        },
        {
            step:4,
            content: <TeamDetails handleNextClick={handleNextClick} setProgressIndex={setProgressIndex} user={user}/>
        },
        {
            step:5,
            content: <SuccessOnboarding handleNextClick={handleNextClick} user={user}/>
        }
    ]

    const stepsLength = onboardingSteps.length - 1

    // if(!user){
    //     history.push(ROUTES.LOGIN)
    // }

    return (
        <StepsLayout
            stepsTitle="Quick & Easy Setup"
            progressSteps={progressSteps}
            progressIndex={progressIndex}
            pageTitle="Client Onboarding"    
        >
            {  
                currentIndex > 0 ?
                <div className='step-switch-cta'>
                    { currentIndex < onboardingSteps.length - 1 && 
                    <Space onClick={handleBackClick}>
                        <LeftOutlined />
                        <Paragraph style={{marginBottom:0, color:"rgba(0, 0, 0, 0.85)", fontWeight:500, cursor:"pointer"}}> Go Back</Paragraph>
                    </Space>
                    }
                    { 
                    currentIndex < onboardingSteps.length - 1 && <Paragraph className='skip-cta' onClick={handleNextClick}>Skip</Paragraph>
                    }
                </div> :
                    <div className="company-details-heading">
                    <Title level={2} className='welcome-heading'>Welcome {capitalizeFirstLetter(user?.firstname)} 👋</Title>
                    <Title level={5} className='welcome-subheading'>Thank you for signing-up to TecMe</Title>
                    <Paragraph className='welcome-text'>Let’s complete your account details. It only takes a moment, and will help us personalize your experience and provide tailored solutions to meet your business needs.</Paragraph>
                </div>
            }
            { 
                currentIndex < onboardingSteps.length - 1 && 
                <div className="mobile-progress-bar-section">
                    <div className="mobile-progress-bar">
                        <Progress percent={progress} />
                    </div>
                    <Paragraph style={{marginBottom:0, fontWeight:500, color:"rgba(0, 0, 0, 0.65)"}}>Step {currentIndex + 1}</Paragraph>
                </div>
            }
            <div className="main-form-content">
                {onboardingSteps[currentIndex].content}
            </div>
        </StepsLayout>
    )
}

export default ClientOnboarding