import React, { useEffect, useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import { META_DESCRIPTION, SECONDARY_COLOR } from '../../../../../utils/constants'
import { Button, Card, Checkbox, Col, Form, Input, Layout, Modal, Radio, Rate, Row, Select, Space, Tag, Typography, Upload, message, notification } from 'antd'
import './assets/css/styles.scss'
import { ClockCircleOutlined, EnvironmentOutlined, HomeOutlined, PaperClipOutlined, PushpinOutlined, UploadOutlined } from '@ant-design/icons'
import LockIcon from './assets/icons/lock-icon.svg'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { convertBudget, getDurationInfo, getSpecializationTitle, getWorkTypeInfo } from '../../../common/private/job-post/utils/helper'
import { capitalizeFirstLetter, getDottedTimeFormat, truncateText } from '../../../../../utils/helper'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import ROUTES from '../../../../../utils/routes'
import PageHeaderWrapper from '../../../../components/page-header-breadcrumbs'
import PageFallback from '../../../../components/page-fallback'
import JobInfo from '../manage-applications/components/job-info'


const {Content} = Layout
const {Paragraph, Title, Text} = Typography

const SubmitJobApplication = () => {
    const tech = useSelector(state => state.tech)
    const workCategories = useSelector(state => state.workCategoriesGlobal)
    const generalSkills = useSelector(state => state.generalSkillsGlobal)
    const [fileList, setFileList] = useState([]);
    const [submitting, setSubmitting] = useState(false)
    const [hourlyRate, setHourlyRate] = useState(0)
    const [paymentTerms, setPaymentTerms] = useState(false)
    const [coverLetter, setCoverLetter] = useState('')
    const [loadingDetails, setLoadingDetails] = useState(false)
    const [jobDetails, setJobDetails] = useState({})
    const [jobApplication, setJobApplication] = useState({})
    const [loadingApplication, setLoadingApplication] = useState(false)
    const [applicationAttachments, setApplicationAttachments] = useState([])
    const [form] = Form.useForm()

    const location = useLocation()
    const history = useHistory()
    const { jobPostId } = location.state
    const { application_id } = useParams()
    const editApplicationPage = location.pathname.includes('edit')

    const serviceFee = (0.2 * hourlyRate)
    const technicianFee = (hourlyRate - serviceFee)


    const handleBeforeUpload = (file) => {
        // Define the allowed file types here
        const allowedTypes = [  
          "image/png",
          "image/jpg",
          "image/jpeg",
          "image/gif",
          "image/webp",
          "image/bmp",
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.oasis.opendocument.spreadsheet",
          "application/vnd.ms-powerpoint",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "text/csv",
      ];
    
        if (allowedTypes.includes(file.type)){
            setFileList([...fileList, file]);
            return true;
        }

      
        message.error('Unsupported file type. Please upload JPEG/PNG, Word, Excel, PDF, or PPT files.');
        return false;
      };
  
    // handle file remove
    const handleFileRemove = (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
    };

    const fetchJobDetails = () => {
        setLoadingDetails(true)
        ApiBaseHelper.get({
            url: `job-post/${jobPostId}`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setJobDetails(res?.data?.data[0])
            setLoadingDetails(false)
        }).catch((error) => {
            console.error(error)
            setLoadingDetails(false)
        })       
    }

    const fetchJobApplication = () => {
        setLoadingApplication(true)
        ApiBaseHelper.get({
            url: `job-post/applications`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setJobApplication(res?.data?.data.find((item) => item?.id === application_id))
            setLoadingApplication(false)
        }).catch((error) => {
            console.error(error)
            setLoadingApplication(false)
        })       
    }

    const fetchApplicationAttachments = () => {
        ApiBaseHelper.get({
            url: `job-post/media/${application_id}`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            setApplicationAttachments(res?.data?.data)
        }).catch((error) => {
            console.error(error)
        })       
    }
  
    const editApplication = (values) => {
        ApiBaseHelper.put({
            url: `job-post/${jobApplication?.Job_Posting?.id}/application/${jobApplication?.id}`,	
            headers: {
                Authorization: 'Bearer ' + tech['token'],
            },
            payload: {
                Payment_Amount: values?.hourly_rate * 100,
                Cover_Letter: values?.cover_letter
            }
        }).then((res) => {
            if(res){
                if(fileList?.length > 0){
                    handleFileSubmit(res?.data?.data?.id)
                    return
                }
                notification.success({
                    message: 'TecMe',
                    description: 'Application Updated Successfully',
                });
                fetchJobApplication()
                setSubmitting(false)
            }
        }).catch((err) => {
            notification.error({
                message: 'TecMe',
                description: 'An error occured!',
            });
            console.log(err)
            setSubmitting(false)
        })
        
    }

    const handleSubmitApplication = (values) => {

        setSubmitting(true)  

        const data = new FormData()
        data.append('Job_Posting', jobDetails?.id)
        data.append('Job_Title', jobDetails?.Title)
        data.append('Client_Contact', jobDetails?.Posting_Contact)
        data.append('Cover_Letter', values?.cover_letter)
        data.append('Payment_Amount', values?.hourly_rate * 100)

        if(!editApplicationPage){
            ApiBaseHelper.post({
                url: `job-post/submit-application`,	
                headers: {
                    Authorization: 'Bearer ' + tech['token'],
                },
                payload: data
            }).then((res) => {
                if(res){
                    if(fileList?.length > 0){
                        handleFileSubmit(res?.data?.data?.id)
                        return
                    }
                    notification.success({
                        message: 'TecMe',
                        description: 'Application Submitted Successfully',
                    });
                    setSubmitting(false)
                    history.push(ROUTES.MANAGE_JOB_APPLICATIONS)
                } 
               
            }).catch((error) => {
                setSubmitting(false)
                notification.error({
                    message: 'TecMe',
                    description: error?.response?.data?.message,
                });
                console.error(error.response)
            })
        }else{
            editApplication(values)
        }
    }

    const handleFileSubmit = (id) => {
        const payload = new FormData();
        if (fileList?.length) {
            fileList?.forEach((file) => {
                payload.append('file', file)
            });
        }
        ApiBaseHelper.post({
            url: `job-post/media/job-application/${id}`,	
            headers: {
                Authorization: 'Bearer ' + tech['token'],
            },
            payload: payload
        }).then((res) => {
            if(res){
                if(editApplicationPage){
                    notification.success({
                        message: 'TecMe',
                        description: 'Application Updated Successfully',
                    });
                    fetchApplicationAttachments()
                    setSubmitting(false)
                    return
                }
                notification.success({
                    message: 'TecMe',
                    description: 'Application Submitted Successfully',
                });
                setSubmitting(false)
                history.push(ROUTES.MANAGE_JOB_APPLICATIONS)
            }     
        }).catch((err) => {
            setSubmitting(false)
            notification.error({
                message: 'TecMe',
                description: 'An error occured!',
            });
            console.log(err)
        })
    }

   


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    useEffect(() => {
        fetchJobDetails()
    }, [jobPostId])

    useEffect(() => {
        if(editApplicationPage){
            fetchJobApplication()
            fetchApplicationAttachments()
        }
    }, [application_id])

    useEffect(() => {
        if(editApplicationPage){
            form.setFieldsValue({
                cover_letter: jobApplication?.Cover_Letter,
                hourly_rate: jobApplication?.Payment_Amount / 100,
                paymentTerms: true,
            })
            setPaymentTerms(true)
        }
    }, [jobApplication, editApplicationPage])

    useEffect(() => {
        if(!editApplicationPage){
            const job_budget = jobDetails?.Budget?.includes('-') ? jobDetails?.Budget?.split('-')[1] : jobDetails?.Budget
            form.setFieldsValue({
                hourly_rate: job_budget/100,
            })
            setHourlyRate(job_budget/100)
        }
    }, [jobDetails])


    return (
        <>
            <MetaTags>
                <title> Submit Job Application - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <PageHeaderWrapper
                routes={[
                    {
                        path: editApplicationPage ? ROUTES.MANAGE_JOB_APPLICATIONS : ROUTES.JOB_POST.replace(':id', jobDetails?.id),
                        breadcrumbName: editApplicationPage ? 'My Applications' : truncateText(capitalizeFirstLetter(jobDetails?.Title), 22),
                    },
                    {
                        breadcrumbName: editApplicationPage ? 'Edit Application' : 'Submit Application',
                    }
                ]}
            />
            {
                (loadingDetails) ? <PageFallback /> :
                <Content className="main">
                    <div className='page'>
                        <div className='auth-container submit-application-ctn'>
                            <div className='submit-application-header'>
                                <Title level={3}>{editApplicationPage ? 'Edit' : 'Submit An'} Application</Title>
                            </div>
                            <div className="submit-application-content">
                                <JobInfo jobDetails={jobDetails}/>
                                <div className="submit-application-form">
                                    <Form
                                        form={form}
                                        name="submit_application"
                                        onFinish={handleSubmitApplication}
                                    >
                                        {/* <div className="application-profile">
                                            <Title level={5}>Profile</Title>
                                            <Paragraph>Select Profile</Paragraph>
                                            <Form.Item className="profile-form-item">
                                            <Select placeholder="Select Profile" size='large'>
                                                    <Select.Option value="general_profile">General Profile</Select.Option>
                                                    <Select.Option value="custom_profile">Custom Profile</Select.Option>
                                            </Select>
                                            </Form.Item>     
                                        </div>   */}
                                        <div className="application-cover-letter">
                                            <Title level={5}>Cover Letter</Title>
                                            <Form.Item
                                                name="cover_letter"
                                                rules={[{ required: true, message: 'Please input your cover letter!' }]}
                                            >
                                                <Input.TextArea 
                                                    placeholder='Type your cover letter here'
                                                    rows={8}
                                                    maxLength={5000}
                                                    showCount
                                                    onChange={(e) => setCoverLetter(e.target.value)}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="application-attachments">
                                            <Space size={20} align='middle'>
                                                <Paragraph style={{fontSize:14, marginTop:12, fontWeight:500}}>Attachments</Paragraph>
                                                <div>
                                                    <Upload
                                                        fileList={fileList} 
                                                        beforeUpload={handleBeforeUpload}
                                                        onRemove={handleFileRemove}
                                                        // disabled={fileList?.length >= 10}
                                                        maxCount={10}
                                                    >
                                                        <Button 
                                                            style={{height:40}} 
                                                            type='secondary' 
                                                            icon={<UploadOutlined />}
                                                            onClick={() => {
                                                                if(fileList?.length >= 10){
                                                                    message.error('You can upload maximum 10 files.')
                                                                    return
                                                                }
                                                            }}
                                                            disabled={fileList?.length >= 10}
                                                        >
                                                                Click to Upload
                                                        </Button>
                                                    </Upload>
                                                    {
                                                        fileList?.length >= 10 &&
                                                        <Paragraph style={{fontSize:14, marginTop:12, color:SECONDARY_COLOR}}>Maximum of 10 files allowed</Paragraph>
                                                    }
                                                </div>
                                            </Space>
                                            <Paragraph style={{fontSize:14, marginTop:8, color:"rgba(0, 0, 0, 0.65)"}}>You may attach up to 10 files under the size of 25 MB each.  Focus on showcasing your skills and expertise through relevant work samples and supporting documents</Paragraph>
                                            {
                                                (editApplicationPage && applicationAttachments?.data?.length > 0) &&
                                                applicationAttachments?.data?.map((item, index) => {
                                                    return(
                                                        <div key={item?.id} className='mb-1'>
                                                            <Space align='center' className="download-cv">
                                                                <PaperClipOutlined style={{color:"rgba(0, 0, 0, 0.45)", position:"relative", top:2}}/>
                                                                <a href={item?.Body} style={{color:SECONDARY_COLOR, textDecoration:"none"}}>{item?.Title}</a>
                                                            </Space>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="application-payments">
                                            <Row gutter={80} align={"middle"}>
                                                <Col xs={24} sm={24} md={24} lg={editApplicationPage ? 24 : 16} xl={editApplicationPage ? 24 : 16}>
                                                    <Title level={5}>Payment Terms</Title>
                                                    <div className="hourly-rates">
                                                        <Paragraph className='title'>{jobDetails?.Payment_Type === "Hourly" ?  'Hourly Rates' : 'Fixed Price'}</Paragraph>
                                                        <Paragraph className='sub-title'>It will be shown to client in your application</Paragraph>
                                                        <Space>
                                                            <Form.Item
                                                                name="hourly_rate"
                                                                rules={[
                                                                    { required: true, message: 'Please input your hourly rate!' },
                                                                    {pattern: /^[0-9]*$/, message: 'Please enter valid hourly rate!'},
                                                                    {
                                                                        validator: (rule, value) => {
                                                                        if (value === '0') {
                                                                            return Promise.reject('Hourly rate cannot be 0.');
                                                                        }
                                                                        return Promise.resolve();
                                                                        },
                                                                    },
                                                                ]}
                                                                
                                                            >
                                                                <Input 
                                                                    prefix="$" 
                                                                    size='large' 
                                                                    className='hourly-rate-input'
                                                                    onChange={(e) => setHourlyRate(e.target.value)}
                                                                /> 
                                                            </Form.Item>  
                                                            <Paragraph style={{fontSize:16, marginBottom:8, fontWeight:400, color:"#000"}}>{jobDetails?.Payment_Type === "Hourly" ? '/hr' : 'fixed'}</Paragraph>
                                                        </Space>    
                                                    </div>
                                                    {
                                                        !editApplicationPage &&
                                                        <>
                                                            <div className="hourly-rates">
                                                                <Paragraph className='title'>20% Technician Service Fee</Paragraph>
                                                                <Paragraph className='sub-title'>It will be shown to client in your application</Paragraph>
                                                                <Space>
                                                                    <Form.Item>
                                                                        <Input 
                                                                            prefix="$" 
                                                                            size='large' 
                                                                            className='hourly-rate-input'
                                                                            value={serviceFee} 
                                                                            disabled /> 
                                                                    </Form.Item>  
                                                                    <Paragraph style={{fontSize:16, marginBottom:8, fontWeight:400, color:"#000"}}>{jobDetails?.Payment_Type === "Hourly" ? '/hr' : 'fixed'}</Paragraph>
                                                                </Space>
                                                            </div>
                                                            <div className="hourly-rates">
                                                                <Paragraph className='title'>You’ll receive</Paragraph>
                                                                <Paragraph className='sub-title'>The amount you’ll receive after service fee</Paragraph>
                                                                <Paragraph style={{color:SECONDARY_COLOR}}>${technicianFee}</Paragraph>
                                                                {/* <Space>
                                                                    <Form.Item>
                                                                        <Input 
                                                                            prefix="$" 
                                                                            size='large' 
                                                                            className='hourly-rate-input' 
                                                                            value={technicianFee}
                                                                            disabled
                                                                        /> 
                                                                    </Form.Item>  
                                                                    <Paragraph style={{fontSize:16, marginBottom:8, fontWeight:400, color:"#000"}}>/hr</Paragraph>
                                                                </Space> */}
                                                            </div>
                                                        </>
                                                    }
                                                </Col>
                                               { 
                                               !editApplicationPage &&
                                               <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                                    <div className="submit-application-image">
                                                        <img src={LockIcon} alt="Lock Icon" />
                                                        <Paragraph>We prioritize payment protection <br /> to ensure your earnings are safe and secure.</Paragraph>
                                                    </div>
                                                </Col>
                                                }
                                            </Row>  
                                        </div>  
                                        <div className="application-check">
                                            <Form.Item
                                                name="paymentTerms"
                                                valuePropName="checked"
                                                rules={[{ required: true, message: 'Please agree with payment terms!' }]}
                                            >
                                                <Checkbox
                                                    onChange={(e) => setPaymentTerms(e.target.checked)}
                                                >I agree with above payment terms</Checkbox>
                                            </Form.Item>
                                        </div>
                                        <div className='application-buttons'>
                                            <Space>
                                                <Button
                                                    type='primary' 
                                                    size='large' 
                                                    htmlType="submit"
                                                    loading={submitting}
                                                    disabled={ editApplicationPage ? !paymentTerms : (!paymentTerms || !hourlyRate || !coverLetter)}
                                                >
                                                    { editApplicationPage ? 'Save changes' : 'Submit application'}
                                                </Button>
                                                <Button 
                                                    type='secondary' 
                                                    size='large'
                                                    onClick={() => window.history.back()}
                                                    >
                                                        Cancel
                                                </Button>
                                            </Space>
                                        </div>
                                    </Form>                 
                                </div>
                            </div>
                        </div>
                    </div>
                </Content>
            }
        </>
    )
}

export default SubmitJobApplication