// External Imports
import { Typography, Row, Card, Button, Image } from "antd";
import { CreditCardFilled } from "@ant-design/icons";

// Local Imports
import { canReadPageOnly, nameFormat } from "../../../../../../utils/helper";

// Assets Imports
import MasterCardSvg from "../assets/images/master-card.svg";
import VisaSvg from "../assets/images/visa.svg";
import UnionPaySvg from "../assets/images/union-pay.svg";
import JcbSvg from "../assets/images/jcb.svg";
import DiscoverSvg from "../assets/images/discover.svg";
import DinersClubSvg from "../assets/images/diners-club.svg";
import AmericanExpressSvg from "../assets/images/american-express.svg";

const { Title, Text } = Typography;

export const getCardDetails = (cardName) => {
  switch (cardName?.toLowerCase()) {
    case "amex":
      return {
        title: "American Express",
        logo: AmericanExpressSvg,
      };
    case "diners":
      return {
        title: "Diners Club",
        logo: DinersClubSvg,
      };
    case "discover":
      return {
        title: "Discover",
        logo: DiscoverSvg,
      };
    case "jcb":
      return {
        title: "JCB",
        logo: JcbSvg,
      };
    case "mastercard":
      return {
        title: "Master Card",
        logo: MasterCardSvg,
      };
    case "unionpay":
      return {
        title: "Union Pay",
        logo: UnionPaySvg,
      };
    case "visa":
      return {
        title: "Visa",
        logo: VisaSvg,
      };
    default:
      return {
        title: "Credit Card",
        logo: CreditCardFilled,
      };
  }
};

const InvoicePaymentMethod = ({ setInvoiceDetails, invoiceDetails, user }) => {
  const username = `${user?.firstname} ${user?.lastname}`;

  const paymentCard = getCardDetails(
    invoiceDetails.data.payment_method?.payment_method_type
  );

  return (
    <Card
      title="Payment Method"
      extra={ !canReadPageOnly(user?.role) &&
        <Button
          type="link"
          className="change-payment-method-button ms-2"
          onClick={() =>
            setInvoiceDetails((prevInvoiceDetails) => ({
              ...prevInvoiceDetails,
              changePaymentMethod: true, // This variable is not presenet in state (Please double check).
              isPaymentConfirm: true,
            }))
          }
        >
          Change
        </Button>
      }
    >
      <Row className="d-none d-sm-flex justify-content-between mb-1">
        <Title level={5}>{paymentCard.title}</Title>
        <Image loading="lazy" 
          src={paymentCard.logo}
          alt={paymentCard.title}
          width={49}
          height={30}
          preview={false}
        />
      </Row>
      <Row className="mb-2">
        <Text className="invoice-payment-title me-1 invoice-payment-method-details">
          Account Name:
        </Text>
        <Text strong className="invoice-payment-method-details">
          {nameFormat(username)}
        </Text>
      </Row>
      <Row>
        <Text className="invoice-payment-title me-1 invoice-payment-method-details">
          Card Number:
        </Text>
        <Text strong className="invoice-payment-method-details">
          **** **** **** {invoiceDetails.cardLastDigits}
        </Text>
      </Row>
    </Card>
  );
};

export default InvoicePaymentMethod;
