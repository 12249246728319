// External Imports
import axios from "axios";

// Internal Imports
import { ApiBaseHelper } from "../../../../../../utils/api-base-helper";
import {
  ALTERNATIVE_REACT_APP_GOOGLE_API_KEY,
  GOOGLE_MAPS_API,
} from "../../../../../../utils/constants";

export class DiscoveryExperienceRepository {
  getDeviceTypes() {
    return ApiBaseHelper.get({ url: "web/skills/filters" });
  }

  getLngAndLatFromZipCode(address) {
    const key =
      process.env.REACT_APP_GOOGLE_API_KEY ||
      ALTERNATIVE_REACT_APP_GOOGLE_API_KEY;

    return axios.get(
      `${GOOGLE_MAPS_API}?address=${address},united states of america&key=${key}&inputtype=textquery`
    );
  }

  getITProjects() {
    return ApiBaseHelper.get({ url: "web/skills?tags=project" });
  }
}
