import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, Modal, Row, Space, Typography } from 'antd'
import { capitalizeFirstLetter } from '../../../../../../utils/helper'
import { CloseOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { SECONDARY_COLOR } from '../../../../../../utils/constants'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'

const { Title, Paragraph, Text } = Typography


const tempProcessList = [
    {
        heading:"Service Selection",
        body:"Choose your desired IT service from our comprehensive online catalog."
    },
    {
        heading:"Appointment Scheduling",
        body:"Pick a convenient date and time for the service via our scheduling tool."
    },
    {
        heading:"Confirmation",
        body:"Receive an email or SMS confirmation of your scheduled appointment and service details."
    },
    {
        heading:"Service Delivery",
        body:"Meet with our expert technician virtually or on-site to receive your service"
    }
]

const ItcProcesses = ({brandColor, itcContent, correctJsonFormat, canEdit, token}) => {
    const [processList, setProcessList] = useState([])
    const [processTitle, setProcessTitle] = useState("Our Step By Step Processes")
    const [processSubtitle, setProcessSubtitle] = useState(`${capitalizeFirstLetter(itcContent?.Company_Name)} is the market-leading IT firm, designed to help you organize and manage your IT needs.`)
    const [openProcessModal, setOpenProcessModal] = useState(false)
    const [form] = Form.useForm();
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if(itcContent?.custom_data?.processList) {
            setProcessList(correctJsonFormat(itcContent?.custom_data?.processList))
        }
        if(itcContent?.custom_data?.processTitle) {
            setProcessTitle(itcContent?.custom_data?.processTitle)
        }
        if(itcContent?.custom_data?.processSubtitle) {
            setProcessSubtitle(itcContent?.custom_data?.processSubtitle)
        }
    }, [itcContent])
    
    const onFinish = (values) => {
        console.log(values)
        if(!canEdit){
            return
        }
        setSubmitting(true)
        ApiBaseHelper.put({
            url: `itc/${itcContent?.id}`,	
            headers: {
                Authorization: 'Bearer ' + token,
            },
            payload: {
                Active:"true",
                Process_List: JSON.stringify(values.processes),
                Process_Title: values.title,
                Process_Subtitle: values.subtitle
            }
        }).then((res) => {
            setProcessList(values.processes)
            setProcessTitle(values.title)
            setProcessSubtitle(values.subtitle)
            setSubmitting(false)
            setOpenProcessModal(false)
        }).catch((err) => {
            console.log(err)
            setSubmitting(false)
        })
    };

    useEffect(() => {
        form.setFieldsValue({
            title: processTitle,
            subtitle: processSubtitle,
        })
    }, [processTitle, processSubtitle])


    return (
    <section className='itc-processes-section'>
        <div className="itc-processes-ctn">
            <div className="processes-title">
                <div>
                    <div className="itc-top-border" style={{background:brandColor}}></div>
                    <Title level={1} className="itc-processes-heading">
                        {processTitle}
                        { 
                            canEdit && <EditOutlined 
                                style={{color:SECONDARY_COLOR, cursor:"pointer", fontSize:25, marginLeft:3}}
                                onClick={() => setOpenProcessModal(true)}
                            /> 
                        }
                    </Title>
                </div>
                <Paragraph className="itc-processes-subtitle">{processSubtitle}</Paragraph>
            </div>
            <div className="processes-content">
                <div className="process-list">
                    {
                       (processList || tempProcessList)?.map((process, index) => {
                            return (
                                <div className='process-item-card-ctn' key={index}>
                                    <div
                                        style={{
                                            content: '',
                                            position: 'absolute',
                                            top: '5px',
                                            left: '5px',
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: brandColor,
                                            transform: 'rotate(1deg)', 
                                            zIndex: '-1', 
                                        }}
                                    >
                                    </div>
                                    <div className="process-item-card">
                                        <Title level={4} className="process-item-heading">{process.heading}</Title>
                                        <Paragraph className="process-item-description">{process.body}</Paragraph>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
        <Modal
            title="Edit your step by step processes"
            visible={openProcessModal}
            onOk={() => setOpenProcessModal(false)}
            onCancel={() => setOpenProcessModal(false)}
            footer={null}
            width={650}
        >
            {/* Create dynamic form */}
            <Form
                form={form}
                style={{width:"100%"}} 
                layout='vertical'
                name="process_list_form" 
                onFinish={onFinish} 
                autoComplete="off"
                initialValues={{ items: processList }}
            >
                <Form.Item
                    label="Title"
                    name="title"
                    className='itc-form-label-item'
                    style={{marginBottom:12}}
                    rules={[{ required: true, message: 'Please input your process title!' }]}
                >
                    <Input 
                        type="text" 
                        placeholder="Enter process title" 
                        size="default"
                    />
                </Form.Item>
                <Form.Item
                    label="Sub title"
                    name="subtitle"
                    className='itc-form-label-item'
                    style={{marginBottom:12}}
                    rules={[{ required: true, message: 'Please input your process sub title!' }]}
                >
                    <Input 
                        type="text" 
                        placeholder="Enter your process sub title" 
                        size="default"
                    />
                </Form.Item>
                <Title level={5} style={{fontWeight:500}}>Processes</Title>
                <Form.List name="processes" initialValue={processList}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <Row key={field.key} gutter={[8, 14]} justify={"space-between"} align={"middle"} className='process-form-row'>
                                <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                                    <Form.Item
                                        {...field}
                                        name={[field.name, 'heading']}
                                        fieldKey={[field.fieldKey, 'heading']}
                                        rules={[
                                            // { required: true, message: 'Missing process heading' },
                                            { max: 100, message: 'Max of 100 characters' }
                                        ]}
                                    >
                                        <Input className='process-form-input' placeholder='Enter process heading' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                                <Form.Item
                                        {...field}
                                        name={[field.name, 'body']}
                                        fieldKey={[field.fieldKey, 'body']}
                                        rules={[
                                            { required: true, message: 'Missing process content' },
                                            { max: 120, message: 'Max of 120 characters' }
                                        ]}
                                    >
                                        <Input className='process-form-input' placeholder='Enter process content' />
                                    </Form.Item>
                                </Col> 
                                <Col xs={0} sm={0} md={2} lg={2} xl={2}>
                                    <CloseOutlined onClick={() => remove(field.name)} className='input-close-icon'/>
                                </Col>  
                                <Col xs={24} sm={24} md={0} lg={0} xl={0}>
                                    <Paragraph onClick={() => remove(field.name)} className='input-close-icon'>Remove</Paragraph>
                                </Col> 
                            </Row> 
                        ))}
                        <Row align={"middle"} justify={"space-between"} className='process-form-row cta-form-row'>
                                <Col xs={12} sm={12} md={13} lg={13} xl={13}>
                                    <Space 
                                        onClick={() => add()}
                                        style={{cursor:"pointer"}}>
                                        <PlusOutlined />
                                        <Text>Add Another</Text>
                                    </Space>
                                </Col>
                                <Col xs={12} sm={12} md={7} lg={7} xl={7} className='d-flex justify-content-end'>
                                    <Button type='primary' htmlType='submit' className="send-processes-btn" style={{height:40}} loading={submitting}>   
                                        Save Changes
                                    </Button>
                                </Col>
                            </Row>
                    </>
                )}
                </Form.List>
            </Form>
        </Modal>
    </section>
  )
}

export default ItcProcesses