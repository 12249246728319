import { useState } from 'react'
import { Modal, Typography, Form, Space, Input, Button } from 'antd'

const { Title, Text, Paragraph } = Typography
const { Item, useForm } = Form
const { TextArea } = Input

const CancelScheduleModal = ({ visible, onOk, onCancel, ticketDetails, ticketRepository, getAndSetTicketDetails, getAndSetActivities, tech }) => {
    const [form] = useForm()
    const [loading, setLoading] = useState(false)

    return (
        <Modal
            visible={visible}
            title={null}
            onCancel={loading ? () => false : onCancel}
            className="resolve-ticket-modal"
            footer={false}
        >
            <Title level={3} className='font-weight-500' style={{ marginTop: '-0.8rem' }}>Are you sure?</Title>
            <Paragraph className='my-2 disclaimer p-2'>
                Are you sure you would like to cancel the on-site visit?
            </Paragraph>
            <Form
                form={form}
                layout='vertical'
                onFinish={async (values) => {
                    setLoading(true)
                    const payload = {
                        User_Contact: !!tech ? ticketDetails?.Reporter_Contact?.id : ticketDetails?.Provider_Contact?.id,
                        Reason: values.Reason,
                    }
                    
                    await ticketRepository?.cancelOnSiteVisit(ticketDetails?.id, payload)
                    await getAndSetTicketDetails()
                    await getAndSetActivities()
                    setLoading(false)
                    form.resetFields()
                    onOk()
                }}
            >
                <Item
                    name="Reason"
                    label={<Text strong>Reason</Text>}
                    rules={[
                        {
                            required: true,
                            message: 'Please enter reason',
                        },
                        {
                            min: 10,
                            message: 'It should be at least 10 characters or more.',
                        },
                    ]}
                >
                    <TextArea
                        rows={4}
                        showCount
                        maxLength={500}
                    />
                </Item>
                <Space align='center' className='w-100 justify-content-end mt-4'>
                    <Item>
                        <Button type='text' onClick={loading ? () => false : onCancel}>Cancel</Button>
                    </Item>
                    <Item>
                        <Button type='primary' htmlType="submit" loading={loading}>Submit</Button>
                    </Item>
                </Space>
            </Form>
        </Modal>
    )
}

export default CancelScheduleModal