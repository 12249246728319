import { CheckCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select, Skeleton, Space, Tag, Typography } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'


const { Title, Paragraph } = Typography
const { Option } = Select
const {useForm} = Form 

const JobSkills = ({handleNextClick, selectedCategoryId, allAvailableSkills, jobPostingData, setJobPostingData, loadingGeneratedSkills, generatedSkills, getGeneratedSkills,  user}) => {

    return (
        <div className='job-skills-ctn'>
            <div className="job-skills-content">
                <div className="job-skills-heading">
                    <Space>
                        <CheckCircleOutlined style={{color:"#F04A22", fontSize:22}} />
                        <Title level={4} style={{marginBottom:0, fontSize:20, color:"#000"}}>What are the essential skills required for your work?</Title>
                    </Space>
                    <Paragraph style={{color:"rgba(0, 0, 0, 0.65)", fontSize:14, marginTop:17}}>Identifying the main skills required for your job post is crucial to attract qualified professionals who possess the expertise you're seeking. This information helps potential candidates understand the specific requirements and align their capabilities accordingly</Paragraph>
                </div>
                <JobSkillsForm 
                    allAvailableSkills={allAvailableSkills}
                    jobPostingData={jobPostingData}
                    setJobPostingData={setJobPostingData}
                    selectedCategoryId={selectedCategoryId}
                    loadingGeneratedSkills={loadingGeneratedSkills}
                    generatedSkills={generatedSkills}
                    getGeneratedSkills={getGeneratedSkills}
                />
                <div className="job-post-next-btn-ctn">
                    <Button type='primary' 
                        className='job-post-next-btn' 
                        onClick={handleNextClick}
                        disabled={jobPostingData?.job_skills?.length === 0}
                        >Next</Button>
                </div>
            </div>
        </div>
    )
}

export const JobSkillsForm = ({allAvailableSkills, jobPostingData, setJobPostingData, loadingGeneratedSkills, generatedSkills, getGeneratedSkills, selectedCategoryId}) => {

    const [suggestedSkills, setSuggestedSkills] = useState([])
    const [form] = useForm()
    
    const getSelectectedSpecialization = allAvailableSkills?.find((item) => item.id === (selectedCategoryId))

   
    useEffect(() => {
        if(jobPostingData?.job_skills){
            form.setFieldsValue({
                specialization_skills: [...suggestedSkills, ...jobPostingData?.job_skills]
            })
            setSuggestedSkills([...suggestedSkills, ...jobPostingData?.job_skills])
        }
    }, [])

    useEffect(() => {
        setJobPostingData({
            ...jobPostingData,
            job_skills: suggestedSkills
        })
    }, [suggestedSkills])

    useEffect(() => {
        if(jobPostingData?.job_generated_skills?.length > 0) return
        getGeneratedSkills()

    }, [])

    useEffect(() => {
        // if(jobPostingData?.job_generated_skills?.length > 0) return
        setJobPostingData({
            ...jobPostingData,
            job_generated_skills: generatedSkills
        })
    }, [generatedSkills])

    // useEffect(() => {
    //     getGeneratedSkills()
    //     setJobPostingData({
    //         ...jobPostingData,
    //         job_generated_skills: generatedSkills
    //     })
    // },[selectedCategoryId])


    return(
        <div className="job-skills-form">
            <Form
                layout='vertical'
                form={form}
            >
                <div>
                    <Form.Item
                        name="specialization_skills"
                        className='mb-4'
                        rules={[
                            { required: true, message: 'Please add a skill'}, 
                        ]}
                    >
                        <Select 
                            mode="tags"
                            size='large'
                            placeholder="Add skills"
                            popupClassName='add-job-skills-select'
                            // onChange={(value) => {
                            //         setJobPostingData({
                            //             ...jobPostingData,
                            //             job_skills: value
                            //         })
                            //     }
                            // }
                            listHeight={264}
                            virtual={false}
                            onDeselect={(value) => {
                                setSuggestedSkills(suggestedSkills.filter((ele) => ele !== value))
                            }}
                            onSelect={(value) => {
                                if(suggestedSkills.includes(value)) return
                                setSuggestedSkills([...suggestedSkills, value])
                                form.setFieldsValue({
                                    specialization_skills: [...suggestedSkills, value]
                                })
                            }}
                            options={(jobPostingData?.job_generated_skills || getSelectectedSpecialization?.other_skills)?.map((ele) => {
                                return {
                                    label: ele,
                                    value: ele 
                                }
                            })}
                        />
                    </Form.Item>
                </div>
            </Form>
            <Paragraph style={{marginBottom:5, fontSize:14, fontWeight:500, color:"color: rgba(0, 0, 0, 0.85)"}}>Suggested:</Paragraph>
            {
                loadingGeneratedSkills ? 
                <Space style={{width:"100%"}} wrap>
                    {
                        [1,2,3,4,5,6].map((ele) => <Skeleton.Button active size='large' shape='square' style={{width:150}}/>)
                    }
                </Space>
                :
                (jobPostingData?.job_generated_skills || getSelectectedSpecialization?.other_skills)?.map((ele) => <Tag
                    className='suggested-tag cursor-pointer custom-ant-tag'
                    onClick={() => {
                    if(suggestedSkills.includes(ele)) return
                    setSuggestedSkills([...suggestedSkills, ele])
                    form.setFieldsValue({
                        specialization_skills: [...suggestedSkills, ele]
                    })

                    }}
                    >
                    {ele}
                </Tag>)
            } 
        </div>
    )
}

export default JobSkills