import React, { useEffect, useState } from 'react'
import { MetaTags } from 'react-meta-tags'
import { Button, Card, Checkbox, Col, Collapse, Input, Layout, List, Modal, Popover, Radio, Row, Select, Slider, Space, Table, Tabs, Tag, Typography } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { META_DESCRIPTION, SECONDARY_COLOR } from '../../../../../utils/constants'
import { Link } from 'react-router-dom'
import ROUTES from '../../../../../utils/routes'
import './assets/css/styles.scss'
import { useSelector } from 'react-redux'
import { set } from 'firebase/database'
import JobCard from './components/job-card'
import { ApiBaseHelper } from '../../../../../utils/api-base-helper'
import { getDurationInfo, getSpecializationTitle, getWorkTypeInfo } from '../../../common/private/job-post/utils/helper'
import { capitalizeFirstLetter } from '../../../../../utils/helper'
import CustomRenderEmpty from '../../../../components/custom-render-empty'
import JobFilter from './components/job-filter'
import useWindowSize from '../../../../../hooks/useWindowSize'
import { AlignLeftOutlined, FilterOutlined, FilterTwoTone } from '@ant-design/icons'

const { Content } = Layout
const {Title, Paragraph} = Typography
const {Search} = Input
const {Panel} = Collapse


const JobBrowsing = () => {
    const history = useHistory()
    const location = useLocation();
    const tech = useSelector(state => state.tech)
    const workCategories  = useSelector(state => state.workCategoriesGlobal)
    const generalSkills = useSelector(state => state.generalSkillsGlobal)
    const [jobListings, setJobListings] = useState([])
    const [loadingJobs, setLoadingJobs] = useState(false)
    const [filteredJobs, setFilteredJobs] = useState([])
    const [rangeValues, setRangeValues] = useState({
        min: 10,
        max: 300
    })
    const [sortValue, setSortValue] = useState('newest')
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const [openSortModal, setOpenSortModal] = useState(false)
    const { windowWidth } = useWindowSize()

    // Extract query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    const searchQueryParam = queryParams.get('query');
    const categoryParam = queryParams.get('category');
    const paymentTypeParam = queryParams.get('paymentType');
    const workTypeParam = queryParams.get('workType');  
    const durationParam = queryParams.get('duration');
    const minimumBudgetParam = queryParams.get('minimumBudget');
    const maximumBudgetParam = queryParams.get('maximumBudget');
    const fixedPriceParam = queryParams.get('fixedPrice');
    const paymentStatusParam = queryParams.get('paymentStatus');

    // State variables to hold filter values
    const [query, setQuery] = useState(searchQueryParam || '')
    const [category, setCategory] = useState(categoryParam || '');
    const [paymentType, setPaymentType] = useState(paymentTypeParam || '');
    const [workType, setWorkType] = useState(workTypeParam || '');
    const [duration, setDuration] = useState(durationParam || '');
    const [minimumBudget, setMinimumBudget] = useState(minimumBudgetParam || '');
    const [maximumBudget, setMaximumBudget] = useState(maximumBudgetParam || '');
    const [fixedPrice, setFixedPrice] = useState(fixedPriceParam || '');
    const [paymentStatus, setPaymentStatus] = useState(paymentStatusParam || null);
  

    let filterTags = [];

    // Iterate through all key-value pairs and push them into the filter tags array, also handle close tag
    for (let [key, value] of queryParams.entries()) {
        let closeFilter = () => {}
        if(key === "query"){
            key = "Query"
            value = capitalizeFirstLetter(value) 
            closeFilter = () => setQuery('')
        }
        if(key==="category"){
            key = "Category"
            value = value
            closeFilter = () => setCategory('')
        }
        if(key==="paymentType"){
            key = "Payment Type"
            value = value
            closeFilter = () => setPaymentType('')
        }
        if(key==="workType"){
            key = "Work Type"
            value = getWorkTypeInfo(value)?.text 
            closeFilter = () => setWorkType('')
        }
        if(key==="duration"){
            key = "Duration"
            value = getDurationInfo(value)?.text
            closeFilter = () => setDuration('')
        }
        if(key==="minimumBudget"){
            key = "Minimum Budget"
            value = `$${value}`
            closeFilter = () => setMinimumBudget('')
        }
        if(key==="maximumBudget"){
            key = "Maximum Budget"
            value = `$${value}`
            closeFilter = () => setMaximumBudget('')
        }
        if(key==="fixedPrice"){
            key = "Fixed Price"
            value = `${value}`
            closeFilter = () => setFixedPrice('')
        }
        if(key==="paymentStatus"){
            key = "Payment Status"
            value = `${value}`
            closeFilter = () => setPaymentStatus('')
        }
        filterTags.push({ key, value, closeFilter });
    }


    // Update the URL with the filter query parameters when the state changes
    useEffect(() => {
        const newSearchParams = new URLSearchParams();
        if(query) {
            newSearchParams.set('query', query);
        }
        if (category) {
            newSearchParams.set('category', category);
        }
        if (paymentType) {
            newSearchParams.set('paymentType', paymentType);
        }
        if (workType) {
            newSearchParams.set('workType', workType);
        }
        if (duration) {
            newSearchParams.set('duration', duration);
        }
        if (minimumBudget) {
            newSearchParams.set('minimumBudget', minimumBudget);
        }
        if (maximumBudget) {
            newSearchParams.set('maximumBudget', maximumBudget);
        }
        if (fixedPrice) {
            newSearchParams.set('fixedPrice', fixedPrice);
        }
        if (paymentStatus) {
            newSearchParams.set('paymentStatus', paymentStatus?.join(", "));
        }
        
        // Construct the query string manually using the "&" separator
        const queryString = newSearchParams.toString();
        const newUrl = `${location.pathname}${queryString ? `?${queryString}` : ''}`;
        
        // Replace the URL without causing a full page reload
        history.replace(newUrl);
    }, [query, category, paymentType, workType, duration, history, minimumBudget, maximumBudget, fixedPrice, paymentStatus, location.pathname]);

    const handleJobsFilter = () => {

        const filteredResults = jobListings.filter((item) => {
            const realBudget = item?.Budget?.includes("-") ? item?.Budget?.split("-")[1]/100 : item?.Budget/100
            // Apply filtering logic 
            const matchesQuery = !query || item?.Title?.toLowerCase().includes(query.toLowerCase());
            const matchesCategory = !category || getSpecializationTitle(workCategories, item?.Category)?.toLowerCase() === category.toLowerCase();
            const matchesPaymentType = !paymentType || item?.Payment_Type?.toLowerCase() === paymentType.toLowerCase();
            const matchedWorkType = !workType || item?.Work_Type?.toLowerCase() === workType.toLowerCase();
            const matchedDuration = !duration || item?.Duration?.toLowerCase() === duration.toLowerCase();
            const matchedMinimumBudget = !minimumBudget || parseInt(realBudget) >= parseInt(minimumBudget);
            const matchedMaximumBudget = !maximumBudget || parseInt(realBudget) <= parseInt(maximumBudget);
            let matchedFixedPrice
            if(fixedPrice === "Less than $100"){
                matchedFixedPrice = parseInt(realBudget) <= 100
            }else if(fixedPrice === "$100 - $500"){
                matchedFixedPrice = parseInt(realBudget) >= 100 && parseInt(realBudget) <= 500
            }else if(fixedPrice === "$500 - $1k"){
                matchedFixedPrice = parseInt(realBudget) >= 500 && parseInt(realBudget) <= 1000
            }else if(fixedPrice === "$1k - $5k"){
                matchedFixedPrice = parseInt(realBudget) >= 1000 && parseInt(realBudget) <= 5000
            }else if(fixedPrice === "$5k+"){
                matchedFixedPrice = parseInt(realBudget) >= 5000
            }else{
                matchedFixedPrice = true
            }

            let matchedPaymentStatus
            if(paymentStatus?.includes("Verified") && paymentStatus?.includes("Unverified")){
                matchedPaymentStatus = true
            }else if(paymentStatus?.includes("Verified")){
                matchedPaymentStatus =(item?.custom_data?.Payment_Verified === "true" || item?.custom_data?.Payment_Verified === "1")
            }else if(paymentStatus?.includes("Unverified")){
                matchedPaymentStatus = (item?.custom_data?.Payment_Verified === "false" || item?.custom_data?.Payment_Verified === "0")
            }else{
                matchedPaymentStatus = true
            }

            return matchesQuery && matchesCategory && matchesPaymentType && matchedWorkType && matchedDuration && matchedMinimumBudget && matchedMaximumBudget && matchedFixedPrice && matchedPaymentStatus;
        });
        setFilteredJobs(filteredResults);
    }

    function handleClearFilter() {
        setQuery('')
        setCategory('')
        setPaymentType('')
        setWorkType('')
        setDuration('')
        setMinimumBudget('')
        setMaximumBudget('')
        setRangeValues({
            min: 10,
            max: 300
        })
        setFixedPrice('')
        setPaymentStatus([])
    }

    // const handleJobsSearch = () => {
    //     const value = searchQuery?.toLowerCase()
    //     const searchData = [...filteredJobs]
    //     const filteredData = searchData.filter((item) => {
    //         return item.Title.toLowerCase().includes(value) || item?.Description?.toLowerCase().includes(value)
    //     })
    //     setFilteredJobs(filteredData)
    // }

    const handleJobsSort = (value) => {
        console.log(value)
        let sortedData = [...filteredJobs]
        if(value === "newest"){
            sortedData.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate))
        }
        else if(value === "high-low"){
           sortedData.sort((a, b) => {
                if(a?.Budget.includes("-") && b?.Budget.includes("-")){
                    return parseInt(b?.Budget.split("-")[1]) - parseInt(a?.Budget.split("-")[1])
                }
                return parseInt(b?.Budget) - parseInt(a?.Budget) 
            })
        }else if(value === "low-high"){
            sortedData.sort((a, b) => {
                if(a?.Budget.includes("-") && b?.Budget.includes("-")){
                    return parseInt(a?.Budget.split("-")[1]) - parseInt(b?.Budget.split("-")[1])
                }
                return parseInt(a?.Budget) - parseInt(b?.Budget)
            })
        }
        setSortValue(value)
        setFilteredJobs(sortedData)
    }

    const fetchAllJobs = () => {
        setLoadingJobs(true)
        ApiBaseHelper.get({
            url: `job-post/browse`, 
            headers: {
                Authorization: 'Bearer ' + tech?.token
            }
        }).then((res) => {
            console.log(res)
            setJobListings(res?.data?.data?.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate))?.filter((item) => item?.Status === "Active"))
            setLoadingJobs(false)
        }).catch((error) => {
            console.error(error)
            setLoadingJobs(false)
        })       
    }

    useEffect(() => {
        fetchAllJobs()
    }, [])

    useEffect(() => {
       setFilteredJobs(jobListings)
    }, [jobListings])

    // useEffect(() => {
    //     if(searchQuery === ''){
    //         setFilteredJobs(jobListings)
    //         // handleClearFilter()
    //     }
    // }, [searchQuery])

    useEffect(() => {
        handleJobsFilter()    
        window.scrollTo(0, 0)
    }, [ query, category, paymentType, workType, duration, minimumBudget, maximumBudget, fixedPrice, paymentStatus, jobListings])

    useEffect(() => {
        if(rangeValues.min === 10 && rangeValues.max === 300){
            setMinimumBudget('')
            setMaximumBudget('')
        }else{
            setMinimumBudget(rangeValues.min)
            setMaximumBudget(rangeValues.max)
        }
    }, [rangeValues])

    useEffect(() => {
        if(paymentStatus?.length === 0){
            setPaymentStatus(null)
        }
    }, [paymentStatus])


    return (
        <>
            <MetaTags>
                <title>Job Browsing - TecMe </title>
                <meta name="description" content={META_DESCRIPTION}/>
                <meta name="robots" content="noindex, nofollow" />
            </MetaTags>
            <Content className="main">
                <div className='page'>
                    <div className="auth-container">
                        <div className="auth-page-ctn job-browsing-ctn">
                            <Row gutter={65}>
                                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                    <aside className="job-browsing-sidebar">
                                        {
                                            windowWidth > 991 &&
                                            <>
                                                <Title level={4} style={{marginBottom:24}}>Filter By</Title>
                                                <JobFilter 
                                                    workCategories={workCategories}
                                                    queryParams={queryParams}
                                                    query={query} setQuery={setQuery}
                                                    category={category} setCategory={setCategory}
                                                    paymentType={paymentType} setPaymentType={setPaymentType}
                                                    workType={workType} setWorkType={setWorkType}
                                                    duration={duration} setDuration={setDuration}
                                                    minimumBudget={minimumBudget} setMinimumBudget={setMinimumBudget}
                                                    maximumBudget={maximumBudget} setMaximumBudget={setMaximumBudget}
                                                    fixedPrice={fixedPrice} setFixedPrice={setFixedPrice}
                                                    paymentStatus={paymentStatus} setPaymentStatus={setPaymentStatus}
                                                    rangeValues={rangeValues} setRangeValues={setRangeValues}
                                                />
                                            </>
                                        }
                                    </aside>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                                    <main className="job-browsing-content">
                                        <div className="job-browsing-search">
                                            <Search
                                                placeholder="Search for jobs"
                                                allowClear
                                                enterButton={windowWidth > 991 ? "Search" : false}
                                                size="large"
                                                // value={query}
                                                // onChange={(e) => setQuery(e.target.value)}
                                                onSearch={(value) => setQuery(value)}
                                            />
                                        </div>
                                        <div className="search-num-ctn">
                                           { 
                                                loadingJobs ? <Paragraph style={{margin:0, fontWeight:600, fontSize:14}}>Loading Jobs...</Paragraph> :
                                                <Paragraph style={{margin:0, fontWeight:600, fontSize:14}}>{filteredJobs?.length} jobs found</Paragraph>
                                           }
                                            {
                                                windowWidth > 991 &&
                                                <div className="sort-ctn">
                                                    <Paragraph style={{margin:0, fontWeight:600, fontSize:14}}>Sort by:</Paragraph>
                                                    <Select 
                                                        defaultValue="newest" style={{ width: 164 }} size='large'
                                                        onChange={(value) => handleJobsSort(value)}
                                                        value={sortValue}
                                                    >
                                                        <Select.Option value="newest">Newest</Select.Option>
                                                        <Select.Option value="high-low">Price (High to Low)</Select.Option>
                                                        <Select.Option value="low-high">Price (Low to High)</Select.Option>
                                                    </Select>
                                                </div>
                                            }   
                                        </div>
                                        {
                                            windowWidth < 992 &&
                                            <div className='mobile-filter-cta'>
                                                <Button 
                                                    type='secondary' 
                                                    className='filter-btn' 
                                                    icon={<FilterTwoTone  twoToneColor={"#F04A22"}/>}
                                                    onClick={() => setOpenFilterModal(true)}
                                                    >
                                                    Filter
                                                </Button>
                                                <Button 
                                                    type='secondary' 
                                                    className='filter-btn' 
                                                    icon={<AlignLeftOutlined style={{color:"#F04A22"}}/>}
                                                    onClick={() => setOpenSortModal(true)}
                                                    >Sort</Button>
                                            </div>
                                        }
                                        {
                                            filterTags?.length > 0 &&
                                            <div className='filter-tags-ctn'>
                                                {
                                                    filterTags.map((item, index) => (
                                                    <Tag  
                                                        style={{backgroundColor:"rgb(254, 237, 233)", color:SECONDARY_COLOR, fontSize:12, border:"none"}} className='custom-ant-tag' 
                                                        key={item?.key}
                                                        closable
                                                        onClose={item.closeFilter}
                                                    >
                                                        {item?.key}: {item?.value}
                                                    </Tag>
                                                    ))
                                                }
                                                <Button
                                                    type="text"
                                                    className={"font-weight-500 text-orange mb-2"}
                                                    onClick={handleClearFilter}
                                                >  
                                                    Clear filter{filterTags.length > 1 ? 's' : ''}  
                                                </Button>
                                            </div>
                                        }
                                        <div className="job-listings-ctn">
                                            <CustomRenderEmpty
                                                description="No jobs found"
                                            >
                                                <List
                                                    dataSource={filteredJobs}
                                                    pagination={{
                                                        total:filteredJobs?.length,
                                                        defaultPageSize:8,
                                                        onChange: page => {
                                                            window.scrollTo({
                                                                top: 0,
                                                                behavior: 'smooth'
                                                            })
                                                        },
                                                    }}
                                                    loading={loadingJobs}
                                                    renderItem={
                                                        item => {
                                                            return(
                                                            <List.Item>
                                                                <JobCard item={item} generalSkills={generalSkills}/>
                                                            </List.Item>
                                                        )}
                                                    }
                                                /> 
                                            </CustomRenderEmpty>
                                        </div>        
                                    </main>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Content>
            <Modal
                title="Filter by"
                visible={openFilterModal}
                onOk={() => setOpenFilterModal(false)}
                onCancel={() => {
                    setOpenFilterModal(false)
                    handleClearFilter()
                }}
                className='filter-modal'
            >
                <JobFilter 
                    workCategories={workCategories}
                    queryParams={queryParams}
                    query={query} setQuery={setQuery}
                    category={category} setCategory={setCategory}
                    paymentType={paymentType} setPaymentType={setPaymentType}
                    workType={workType} setWorkType={setWorkType}
                    duration={duration} setDuration={setDuration}
                    minimumBudget={minimumBudget} setMinimumBudget={setMinimumBudget}
                    maximumBudget={maximumBudget} setMaximumBudget={setMaximumBudget}
                    fixedPrice={fixedPrice} setFixedPrice={setFixedPrice}
                    paymentStatus={paymentStatus} setPaymentStatus={setPaymentStatus}
                    rangeValues={rangeValues} setRangeValues={setRangeValues}
                />
            </Modal>
            <Modal
                title="Sort jobs by"
                visible={openSortModal}
                onOk={() => setOpenSortModal(false)}
                onCancel={() => {
                    setOpenSortModal(false)
                    handleJobsSort("newest")
                }}
                footer={null}
                className='filter-modal'
            >
                <div className='sort-modal-ctn'>
                    <Radio.Group onChange={(e) => setSortValue(e.target.value)} value={sortValue}>
                        <Space direction="vertical">
                            <div className='sort-modal-card'>
                                <Radio value={"newest"}>Newest</Radio>
                            </div>
                            <div className='sort-modal-card'>
                                <Radio value={"high-low"}>Price (High to Low)</Radio>
                            </div>
                            <div className='sort-modal-card'>
                                <Radio value={"low-high"}>Price (Low to High)</Radio>
                            </div>
                        </Space>
                    </Radio.Group>
                  
                </div>
                <Button type='primary' 
                className='apply-sort-btn'
                onClick={() => {
                    setOpenSortModal(false)
                    handleJobsSort(sortValue)
                }}
                >Apply</Button>
            </Modal>
        </>
    )
}

export default JobBrowsing