import { DatePicker, Form, notification, Modal, Select, Space, Button,Divider, Typography,TimePicker } from 'antd'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useTimeSheet } from '../../hooks/useTimesheet';
import TextArea from "antd/es/input/TextArea";
import { ContractRepository } from '../../repository';
import { useSelector } from "react-redux";
import { TicketDetailsRepository } from '../../../../ticket-details/repository';
import { convertToLocalTime } from '../../../../../../../../utils/helper';

const { RangePicker } = DatePicker;
const { useForm } = Form

const EditTimeModal = ({
    showTimeEntryModal,
    setShowTimeEntryModal,
    contractId,
    maxAllowableHours,
    setUpdatedTimesheetData,
    contractDetails,
    selectedTimeEntry,
    setSelectedTimeEntry,
    upComingInvoice,
    repository
}) => {

    // console.log({selectedTimeEntry, contractDetails})
    const { billingWeekTotalHours } = useTimeSheet(contractId)

    const user = useSelector(state => state.user)
    const tech = useSelector(state => state.tech)
    const token = user?.token || tech?.token

    const [addingManualTime, setAddingManualTime] = useState(false)
    const startDate = contractDetails?.Active_Date_Time
    const [selectedRange, setSelectedRange] = useState('start')
    const [form] = useForm();
    const [timeSpent, setTimeSpent] = useState(0)
    const [timeRange, setTimeRange] = useState([])
    const [ticketDetails, setTicketDetails] = useState(null)
    const [ticketCreatedDate, setTickeCreatedDate] = useState(null)
    const [timeEntryDate, setTimeEntryDate] = useState(null)
    const [loadingTicket, setLoadingTicket] = useState(false)
    const [estimatedTotalHours, setEstimatedTotalHours] = useState()

    const ticketRepository = new TicketDetailsRepository(token)

    const getAndSetTicketDetails = async () => {
        setLoadingTicket(true)
		try {
			const res = await ticketRepository?.getTicket(selectedTimeEntry?.Ticket?.id);
			const data = res?.data?.data?.[0]
			if(data) setTicketDetails(data)
            setLoadingTicket(false)
		} catch (error) {
			console.log(error)
            setLoadingTicket(false)
		}
    }

    const getTimeSheetData = () => {
        repository?.getTimesheets(contractId).then(async res => {
            if (res.data?.data?.length) {
                const sortedTimesheetData = [...res?.data.data]
                sortedTimesheetData?.sort((a, b) => moment(b.Clock_Out_Date_Time).local().diff(moment(a.Clock_Out_Date_Time).local()))
                setWeeklyTimeSpent(sortedTimesheetData)
            }
        })

    }
    const setWeeklyTimeSpent = (timeSheetData) => {
        const daysFrom = upComingInvoice ? moment.unix(upComingInvoice.next_payment_attempt).subtract(1, 'week') : moment().startOf('week');
        const daysTo = upComingInvoice ? moment.unix(upComingInvoice.next_payment_attempt) : moment().endOf('week');
        const thisWeekTimesheet = timeSheetData.filter((item) => {
            return moment(item.Clock_Out_Date_Time) >= daysFrom && moment(item.Clock_Out_Date_Time) <= daysTo
        })

        const timeSpent2 = thisWeekTimesheet.reduce((acc, item) => {
            return acc + parseInt(item.Total_Time)
        }, 0)
        setTimeSpent(timeSpent2 / 60)
    }

    // disable dates before the 7 days of the upcoming invoice next_payment_attempt date and after the current date
    const disabledDate = (current) => {
        let currentDay = moment(current).format('YYYY-MM-DD');
        currentDay = moment(currentDay);
        return currentDay > moment() || (upComingInvoice ? currentDay < moment.unix(upComingInvoice.next_payment_attempt).subtract(8, 'day') : false)
    };

    const closeModal = () => {
        setSelectedTimeEntry(undefined);
        setShowTimeEntryModal(false)
        form.resetFields()
    };

    // initiate constant with ContractRepository
    let contractRepository = new ContractRepository(token);

    const handleAddManualTimeSubmit = (values) => {
        const timeRange = values.time_range;
        const selectedDate = selectedTimeEntry.Clock_In_Date_Time;

        // Creating the moment objects
        const clockInDateTime = moment(selectedDate).set({
            hour: timeRange[0].hour(),
            minute: timeRange[0].minute(),
            second: 0,
            millisecond: 0
        });

        const clockOutDateTime = moment(selectedDate).set({
            hour: timeRange[1].hour(),
            minute: timeRange[1].minute(),
            second: 0,
            millisecond: 0
        });
        
        const payload = {
            Clock_In_Date_Time2: clockInDateTime.format(),
            Clock_Out_Date_Time2: clockOutDateTime.format(),
            Clock_In_Date_Time: clockInDateTime.utc().format(),
            Clock_Out_Date_Time: clockOutDateTime.utc().format(),
            Contract: contractId,
            Customer_Contact: contractDetails?.Customer_Org?.HIPAA_Primary_Contact,
            Ticket: selectedTimeEntry.Ticket.id,
            Notes: values?.notes,
            Total_Time: moment(timeRange[1]).diff(moment(timeRange[0]), 'minutes'),
            Timestamp: selectedTimeEntry.custom_data?.timestamp,
            Stripe_Subscription_Id: contractDetails.custom_data?.stripe_subs_item
        }

        setAddingManualTime(true)
        contractRepository.updateTimeEntry(selectedTimeEntry.id, payload).then(async (res) => {
            if (res.data) {
                notification['success']({
                    message: 'TecMe',
                    description: "Time entry updated successfully"
                });
                setAddingManualTime(false)
                setUpdatedTimesheetData(true)
                form.resetFields()
                closeModal()
            }
        }).catch(error => {
            console.error(error)
            setAddingManualTime(false)
            notification['error']({
                message: 'TecMe',
                description: "Something went wrong"
            });
            form.resetFields()
        })
    }


    useEffect(() => {
        if (showTimeEntryModal) {
            setUpdatedTimesheetData(false)
        }
    }, [showTimeEntryModal])

    useEffect(() => {
        getTimeSheetData()
    }, [contractId])

    useEffect(() => {
        if (selectedTimeEntry) {
            getAndSetTicketDetails()
            setTimeEntryDate(convertToLocalTime(selectedTimeEntry.Clock_In_Date_Time))
        }
    }, [selectedTimeEntry])

    useEffect(() => {
        if(ticketDetails){
            setTickeCreatedDate(convertToLocalTime(ticketDetails?.CreatedDate))
        }
    }, [ticketDetails])

    useEffect(() => {
        if(selectedTimeEntry){
            const clockInLocal =  convertToLocalTime(selectedTimeEntry.Clock_In_Date_Time)
            const clockOutLocal =  convertToLocalTime(selectedTimeEntry.Clock_Out_Date_Time)
        
            form.setFieldsValue({
              time_range: [clockInLocal, clockOutLocal],
            });

            setTimeRange([clockInLocal, clockOutLocal])
            let estimatedTime = moment.duration(moment(clockOutLocal).diff(clockInLocal))
            setEstimatedTotalHours((estimatedTime.hours() + (estimatedTime.minutes()/60)).toFixed(2))
        }
      }, [selectedTimeEntry]);

    const getEstimatedTime = () => {
        if(timeRange.length){
            let estimatedTime = moment.duration(moment(timeRange[1]).diff(timeRange[0]))
            estimatedTime.add(1, 'seconds')
            return `${estimatedTime.hours()}h ${estimatedTime.minutes()}m`
        }
    }

    return (
        <Modal title="Edit Time Entry"
            open={showTimeEntryModal}
            okText={"Save"}
            onOk={() => {
                form.submit()
            }}
            onCancel={() => {
                closeModal()
                form.resetFields()
            }}
            confirmLoading={addingManualTime}
        >
            {
                loadingTicket ? "Loading form. Please wait..." :
                <>
                    <Typography.Paragraph className='disclaimer p-2'>
                        Please be aware that any modifications made to the time log will be communicated to the customer via
                        email or push notification and will also be logged in the contract activity log. </Typography.Paragraph>
                    <Form
                        onFinish={(values) => handleAddManualTimeSubmit(values)}
                        layout={'vertical'}
                        form={form}
                    >
                        {
                            selectedTimeEntry &&
                            <Form.Item name="date" label="Date">
                                <DatePicker
                                    format={"ddd, MMM D, YYYY"}
                                    style={{ width: '100%' }}
                                    disabled={true}
                                    defaultValue={moment(moment.utc(selectedTimeEntry.Clock_In_Date_Time).toDate()).local()}
                                />
                            </Form.Item>
                        }

                        {
                            selectedTimeEntry &&
                            <Form.Item name="time_range" label="From"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select start and end time',
                                },
                                {
                                    validator(_, value) {
                                        if (value) {
                                            const startTime = value[0]
                                            const endTime = value[1]
                                            const timeDifferenceStartEndTime = endTime.diff(startTime, 'minutes')
                                            const hoursDiff = (timeDifferenceStartEndTime / 60).toFixed(2)
                                            const totalUsedTime = parseFloat(hoursDiff) + parseFloat(timeSpent.toFixed(2))
                                            const disableHour = ticketCreatedDate.hour();
                                            const disableMinute = ticketCreatedDate.minute();
                                            const startHour = startTime.hour();
                                            const startMinute = startTime.minute();
                
                                            // Compare only time
                                            const disableTime = disableHour * 60 + disableMinute;
                                            const startTimeInMinutes = startHour * 60 + startMinute;

                                            if (startTime.isAfter(endTime))
                                                return Promise.reject(new Error('Start time should be before than end time'))

                                            // start time and end time are same
                                            if (timeDifferenceStartEndTime == 0)
                                                return Promise.reject(new Error('Start time can not be the same as end time'))

                                            // start time should be after ticket creation time
                                            if (timeEntryDate.isSame(ticketCreatedDate, 'day') && startTimeInMinutes < disableTime) {
                                                return Promise.reject(new Error('Start time should be after ticket creation time'));
                                            }

                                            // no weekly hour limit is applied by client so no need to check
                                            if (maxAllowableHours == null || maxAllowableHours == undefined || maxAllowableHours == '' || maxAllowableHours == 0)
                                                return Promise.resolve();

                                            // if weekly hours limit crossed, throw error
                                            if ((parseFloat(totalUsedTime) - estimatedTotalHours) <= Number(maxAllowableHours))
                                                return Promise.resolve();
                                            else
                                                return Promise.reject(new Error('You have exceeded your maximum weekly work hours '))

                                        }
                                        
                                        return Promise.reject(new Error('Please select start and end time'))
                                    }
                                }
                            ]}

                        >
                            <TimePicker.RangePicker
                                style={{ width: "100%" }}
                                format="h:mm A"
                                onChange={(time, timeString) => {
                                    setTimeRange(time)
                                }}
                                use12Hours
                                inputReadOnly
                                disabledTime={(_, type) => {
                                    // current is a moment object for the current hovering date and time
                                    if (type === 'start' && timeEntryDate && timeEntryDate.isSame(ticketCreatedDate, 'day')) {
                                        const disableHour = ticketCreatedDate.hour();
                                        const disableMinute = ticketCreatedDate.minute();
                                        const isPM = disableHour >= 12;
                                    
                                        return {
                                        disabledHours: () => {
                                            const hours = [];
                                            if (isPM) {
                                                for (let i = 0; i < 12; i++) {
                                                    hours.push(i); // Disable all AM hours
                                                }
                                                for (let i = 12; i < disableHour; i++) {
                                                    hours.push(i); // Disable PM hours before ticketCreatedDate hour
                                                }
                                            } else {
                                                for (let i = 0; i < disableHour; i++) {
                                                    hours.push(i); // Disable AM hours before ticketCreatedDate hour
                                                }
                                            }
                                            return hours;
                                        },
                                        disabledMinutes: (selectedHour) => {
                                            const minutes = [];
                                            const hourIn12Format = disableHour % 12 || 12;
                                            if (selectedHour === hourIn12Format) {
                                                for (let i = 0; i < disableMinute; i++) {
                                                    minutes.push(i);
                                                }
                                            }
                                            return minutes;
                                        },
                                        };
                                    }
                                    return {};
                                }}
                            />
                        </Form.Item>
                        }
                        {
                            timeRange.length>0 &&
                            <Typography.Text>Estimated time: {getEstimatedTime()}</Typography.Text>

                        }
                        <Divider/>

                        {/* {
                            selectedTimeEntry &&
                            <Form.Item
                                key={selectedTimeEntry.Clock_In_Date_Time}
                                label="Select Date & Time"
                                name="date_time"
                                className='pb-2'
                                rules={[
                                    {
                                        validator(_, value) {
                                            if (value) {
                                                const startTime = value[0]
                                                const endTime = value[1]

                                                const minDiff = endTime.diff(startTime, 'minutes')
                                                const hoursDiff = (minDiff / 60).toFixed(2)
                                                const totalUsedTime = hoursDiff + billingWeekTotalHours.toFixed(2)

                                                // check if start time is not same as old start time and end time is not same as old end time
                                                if (
                                                    (moment(startTime).utc().format('YYYY-MM-DD HH:mm') == moment(selectedTimeEntry.Clock_In_Date_Time).format('YYYY-MM-DD HH:mm'))
                                                    &&
                                                    (moment(endTime).utc().format('YYYY-MM-DD HH:mm') == moment(selectedTimeEntry.Clock_Out_Date_Time).format('YYYY-MM-DD HH:mm'))
                                                ) {
                                                    return Promise.reject(new Error('Please select start and end time different from previous time entry'))
                                                }

                                                if (minDiff !== 0) {
                                                    let contractLimit = Math.round(Number(maxAllowableHours))
                                                    if (
                                                        (maxAllowableHours == null)
                                                        ||
                                                        (parseFloat(totalUsedTime) <= contractLimit)
                                                    ) {
                                                        return Promise.resolve();
                                                    } else {
                                                        return Promise.reject(new Error(`Selected date/time shouldn't exceed your available weekly hours limit which is ${contractLimit} hours`))
                                                    }
                                                } else {
                                                    return Promise.reject(new Error('Start time can not be the same as end time'))
                                                }
                                            } else
                                                return Promise.reject(new Error('Please select start and end time different from previous time entry'))
                                        }

                                    }
                                ]}
                            >

                                <RangePicker
                                    key={selectedTimeEntry.Clock_In_Date_Time}
                                    showTime={{
                                        format: 'HH:mm',
                                    }}
                                    disabledDate={disabledDate}
                                    disabledTime={disabledRangeTime}
                                    defaultValue={[moment(moment.utc(selectedTimeEntry.Clock_In_Date_Time).toDate()).local(), moment(moment.utc(selectedTimeEntry.Clock_Out_Date_Time).toDate()).local()]}
                                    format="YYYY-MM-DD HH:mm"
                                    placeholder={["Clock in date/time", "Clock out date/time"]}
                                    style={{ width: "100%" }}
                                />

                            </Form.Item>
                        } */}


                        <Form.Item
                            label="Memo (optional)"
                            name="Notes"
                        >
                            <TextArea
                                placeholder="If applicable, you may add any notes here"
                                rows={5}
                                showCount
                                maxLength={140}
                            />
                        </Form.Item>
                    </Form>
                </>
            }
        </Modal>
    )
}

export default EditTimeModal