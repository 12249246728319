import React from 'react'
import { MetaTags } from 'react-meta-tags'
import { META_DESCRIPTION } from '../../../../../../utils/constants'
import { Button, Form, Input, Layout, Result, Typography, notification } from 'antd'
import { Link } from 'react-router-dom';
import './styles.scss';
import { useState } from 'react';
import routes from '../../../../../../utils/routes';
import { useHistory, useLocation } from 'react-router';
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper';
import { set } from '@firebase/database';

const {Title, Paragraph} = Typography
const {Content} = Layout

const ResetPassword = () => {
    const history = useHistory()
    const [isPasswordReset, setIsPasswordReset] = useState(false)
    const [loading, setLoading] = useState(false)

    const {state} = useLocation()

    console.log(state)

    const onFinish = (values) => {
        setLoading(true)
        ApiBaseHelper.post({
            url: `password/${state?.passwordId}/verify`, payload: {
                pin: values.otp,
                password: values.password,
                userId: state?.userId
            }
        }).then((res) => {
            if(res){
                setLoading(false)
                setIsPasswordReset(true)
            }
        }).catch((e) => {
            setLoading(false)
            setIsPasswordReset(false)
            notification.error({
                message: "TecMe",
                description: "Something went wrong. Kindly confirm your OTP.",
            })
        });
    }


  return (
    <>
        <MetaTags>
            <title>Reset Password - TecMe </title>
            <meta name="description" content={META_DESCRIPTION}/>
            <meta name="robots" content="noindex, nofollow" />
        </MetaTags>
        <Content className="main">
            <div className="page">
                <div className="reset-password-section">
                    {
                        !isPasswordReset ? 
                        <div className="reset-password-ctn">
                            <div className="heading-section">
                                <Title level={2} style={{color:"rgba(0, 0, 0, 0.85)", marginBottom:10}}>Set new password</Title>
                                <Paragraph  style={{color:"rgba(0, 0, 0, 0.85)", fontSize:24}}>We just sent an email to <span style={{fontWeight:500}}>{state?.email}</span> with a pin code.</Paragraph>
                                <Paragraph style={{color:"rgba(0, 0, 0, 0.85)", fontSize:16}}>If you don’t see the email, make sure the email address is correct and check your spam folder.</Paragraph>
                            </div>
                            <div className="form-section">
                                <Form
                                    name="reset-password"
                                    className="reset-password-form"
                                    layout="vertical"
                                    onFinish={onFinish}
                                > 
                                    <>
                                        <Paragraph style={{marginBottom:4, color:"#202223"}}>6-digit pin code</Paragraph>
                                        <Form.Item
                                            name="otp"
                                            rules={[
                                                {  
                                                    required: true,
                                                    message: 'Please enter a valid 6-digit pin code.',
                                                },
                                                {
                                                    min: 6,
                                                    message: 'Enter 6-digit pin',
                                                }, 
                                                {
                                                    max: 6,
                                                    message: 'Enter 6-digit pin',
                                                }
                                            ]}
                                            style={{
                                                marginBottom: 30
                                            }}
                                        >
                                            <Input
                                                type='number'
                                                onInput={(e) => e.target.value = e.target.value.slice(0, 6)}
                                                className="reset-password-form-input"
                                            />
                                        </Form.Item> 
                                    </>
                                    <> 
                                        <Paragraph style={{marginBottom:4, color:"#202223"}}>New Password</Paragraph>
                                        <Form.Item
                                            name="password"
                                            rules={[
                                                {  
                                                    required: true,
                                                    message: 'Please input your new password',
                                                },
                                                {
                                                    min: 8,
                                                    message: 'Password should be at least 8 characters or more',
                                                },
                                            ]}
                                            style={{
                                                marginBottom: 30
                                            }}
                                        >
                                            <Input.Password 
                                                className="reset-password-form-input"
                                            />
                                        </Form.Item> 
                                    </>   
                                    <>
                                        <Paragraph style={{marginBottom:4, color:"#202223"}}>Confirm Password</Paragraph>
                                        <Form.Item
                                            name="confirmPassword"
                                            dependencies={['password']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please re-enter your password.',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('The two passwords do not match!'));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password 
                                                className="reset-password-form-input"
                                            />
                                        </Form.Item> 
                                    </>      
                                    <Form.Item>
                                        <Button 
                                            type="primary" 
                                            htmlType="submit" 
                                            loading={loading}
                                            className="reset-password-form-button">
                                            Reset password
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>  :
                        <div className='reset-password-success'>
                            <Result
                                status="success"
                                title="Congratulations!"
                                subTitle="Your password has been successfully changed. Now, you can log into your account using your new password. This security measure ensures that your account remains protected from unauthorized access. Should you have any further questions or concerns, our support team is always ready to assist you."
                                extra={[
                                <Button type="primary" onClick={() =>  history.push(routes.LOGIN)}>
                                    Go to login
                                </Button>
                                ]}
                            />
                        </div>
                    }
                    
                </div>
            </div>
        </Content>
    </>
  )
}

export default ResetPassword