import { Modal, Typography, Button, Space } from 'antd';
import React, { useEffect, useState } from 'react'
import ManagedItServices from '../assets/images/managed-it-services.png'
import ItSolutionsProvider from '../assets/images/it-solutions-provider.png'
import AiServiceProvider from '../assets/images/ai-service-provider.png'
import MsPMssp from '../assets/images/msp-mssp.png' 
import CloudManagementServices from '../assets/images/cloud-management-services.png'
import ItInfrastructureServices from '../assets/images/it-infrastructure-services.png'
import ItSupportMaintenance from '../assets/images/it-support-maintenance.png'
import Slider from "react-slick";
import TecmeLogo from '../assets/images/tecme_logo.png';
import RightArrow from '../../../../../../assets/images/right-arrow.svg'


const {Title, Paragraph} = Typography

const itcOptions = [
    {
        img: ManagedItServices,
        title: "MSP & MSSP",
        details:"With TecMe, establish your MSP and MSSP, offering comprehensive IT management and cybersecurity. Position yourself as a trusted solutions provider, ensuring efficiency and robust protection in the digital realm."
    },
    {
        img: ItSolutionsProvider,
        title: "IT solutions provider",
        details:"Leverage TecMe for specialized cloud management, optimizing security and scalability. Provide tailored cloud infrastructure management, enabling seamless integration and efficiency in the digital landscape."
    },
    {
        img: AiServiceProvider,
        title: "Artificial Intelligence Service Provider",
        details:"Provide specialized AI services, focusing on advanced technology development to address business challenges. Drive innovation with customized AI solutions, positioning yourself as a leader in transforming digital business strategies."
    },
    // {
    //     img: ManagedItServices,
    //     title: "Managed IT service provider (MITSP)",
    //     details:"Launch your MSP and MSSP with TecMe, offering comprehensive IT and cybersecurity services. Ensure operational efficiency and technological reliability. Protect businesses from digital threats. Position yourself as a leading IT solutions provider."
    // },
    // {
    //     img: MsPMssp,
    //     title: "MSP & MSSP",
    //     details:"Leverage TecMe to create your own MSP and MSSP, offering businesses comprehensive IT infrastructure management and advanced cybersecurity services. Ensure operational efficiency, technological reliability, and robust protection against digital threats. Position yourself as a complete IT solutions provider in the dynamic digital world."
    // },
    {
        img: CloudManagementServices,
        title: "Cloud Management Services",
        details:"Utilize TecMe to offer specialized cloud management services, ensuring businesses enjoy optimized, secure, and scalable cloud operation. Position yourself as a key enabler of seamless cloud integration and efficiency in the modern digital landscape."
    },
    {
        img: ItInfrastructureServices,
        title: "IT infrastructure Services",
        details:"Designing, implementing, and managing IT infrastructure components such as servers, storage, and networking equipment using TecMe as your platform."
    },
    {
        img: ItSupportMaintenance,
        title: "IT Support and Maintenance",
        details:"Establish a wide network of offering technical support services to troubleshoot issues, resolve problems, and ensure the smooth operation of IT systems and services."
    },
]

const ItcOptions = () => {
    const [open, setOpen] = useState(true)
    const settings = {
        dots: true,
        autoplay: false,
        infinite: false,
        speed:800,
        // pauseOnHover: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        arrows: false,
        responsive: [
          {
            breakpoint: 775,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 0,
            }
          },
        //   {
        //     breakpoint: 600,
        //     settings: {
        //       slidesToShow: 2,
        //       slidesToScroll: 2,
        //       initialSlide: 2
        //     }
        //   },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0,
            }
          }
        ]
    };

    return(
        <Modal
            title={null}
            centered
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            width={950}
            footer={null}
            closable={true}
            maskClosable={false}
            className='itc-options-modal'
        >
            <div className="itc-options-header">
                {/* <img src={TecmeLogo} alt="tecme logo" /> */}
                <Title level={2} style={{color:"#000", fontSize:20}}>Commence Your IT Services Company (ITSC) with an Array of Dynamic Services</Title>
                <Paragraph style={{color:"#000", fontSize:12}}>Embark on an exciting journey with TecMe to create your own IT services company (ITSC). Design and offer a diverse range of IT services, from advanced cloud infrastructure management to AI services and comprehensive security solutions. Lead a skilled team, tailor services to meet client needs, and establish a strong presence in the technology sector, shaping your future where innovation meets expertise.</Paragraph>
            </div>
            <div className="itc-options-slider-ctn">
                <Slider {...settings}>
                    {
                        itcOptions.map((option, index) => (
                            <div 
                                key={index} 
                                className='itc-option-card'
                            >
                                <div className='itc-option-card-img-ctn'>
                                    <img src={option.img} alt="itc-option-img" className='itc-option-card-img' />
                                </div>
                                <div className="itc-option-card-details-ctn">
                                    <Paragraph className='itc-option-card-title'>{option.title}</Paragraph>
                                    <Paragraph className='itc-option-card-details'>{option.details}</Paragraph>
                                </div>
                                <Space align='center'>
                                    <Paragraph className='itc-option-card-started' onClick={() => setOpen(false)}>Get Started</Paragraph>
                                    <img src={RightArrow} alt="right arrow" />
                                </Space>
                            </div>
                        ))
                    }
                </Slider>
            </div>
            {/* <div className="itc-options-footer-ctn">
                <div className="itc-options-footer">
                    <Paragraph style={{color:"#000", fontSize:18}}>Embark on an exciting journey with TecMe to create your own IT services company (ITC). Design and offer a variety of IT services, from managing advanced cloud infrastructures to providing AI services and comprehensive security solutions. Lead a skilled team, tailor services to client needs, and establish a strong presence in the technology sector.</Paragraph>
                    <Button 
                        size='large'
                        type='primary'
                        onClick={() => setOpen(false)}
                    >Let's get started</Button>
                </div>   
            </div> */}
        </Modal>
    )
}

export default ItcOptions
