import React, {useEffect, useState} from 'react'
import { Avatar, Button, Rate, Typography, Space, List, Tabs, notification  } from 'antd'
import { SECONDARY_COLOR } from '../../../../../../utils/constants'
import { EnvironmentOutlined } from '@ant-design/icons'
import { ApiBaseHelper } from '../../../../../../utils/api-base-helper'
import CustomRenderEmpty from '../../../../../components/custom-render-empty'
import ROUTES from '../../../../../../utils/routes'
import { getAlgoliaIndex } from '../../../../common/public/instant-search/utils/helper'
import axios from 'axios'
import { FirebaseHelper, FirestoreHelper } from '../../../../../../utils/firebase-helper'
import { useSelector } from 'react-redux'
import '../../create-itc/assets/css/tech-card.scss'
import ActionModal from '../../create-itc/components/delete-modal'

const { Title, Paragraph } = Typography

const algoliaAppID =   process.env.REACT_APP_ALGOLIA_APPLICATION_ID;
const algoliaSearchKey =  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY;
const algoliaIndex = getAlgoliaIndex();

const JoinRequests = ({itcDetails, fetchTechTeam, techTeam}) => {
    const tech = useSelector(state => state.tech)
    const [techProfiles, setTechProfiles] = useState([])
    const [appliedTechs, setAppliedTechs] = useState([])
    const [loadingTechProfiles, setLoadingTechProfiles] = useState(false)
    const [accepting, setAccepting] = useState(false)
    const [rejecting, setRejecting] = useState(false)
    const [groupConversation, setGroupConversation] = useState(null)
    const [acceptModal, setAcceptModal] = useState(false)
    const [rejectModal, setRejectModal] = useState(false)

      // fetch tech profiles
      const fetchTechProfiles = async () => {
        setLoadingTechProfiles(true)
        try {
            const response = await axios.get(
              `https://${algoliaAppID}-dsn.algolia.net/1/indexes/${algoliaIndex}`,
              {
                headers: {
                  'X-Algolia-API-Key': algoliaSearchKey,
                  'X-Algolia-Application-Id': algoliaAppID,
                },
                params: {
                  hitsPerPage: 1000, 
                },
              }
            );
      
            // Extract and store the search results from the response
            setTechProfiles(response.data.hits);
            setLoadingTechProfiles(false)
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoadingTechProfiles(false)
        }
    }

     // Function to get and set group conversations for the commpany
     const getAndSetCoversation = async () => {
        await FirebaseHelper.login();
        const participantsResponse = await FirestoreHelper.getAllGroupConversations();
        const groupConversations = [];
        participantsResponse.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          if (item.group && item.participants?.length) {
            groupConversations.push({ ...item});
          }
        });
        setGroupConversation(groupConversations.find((c) => c.group.toLowerCase() === (itcDetails?.Company_Name?.toLowerCase())))
    }

    // Function to add member to group chat after accepting request
    const addMemberToGroupConverstation = async (member) => {
        const techName = member?.name
        await FirestoreHelper.createUser({
            hipaa_id: member?.objectID,
            display_name: techName,
            profile_picture: member?.image,
            role: "Technician",
        });
        const newParticipants = [
            ...groupConversation.participants,
            member?.objectID,
          ];
        await FirestoreHelper.updateGroupConversationMembers({
            participants: newParticipants,
            conversation_id: groupConversation?.id,
        })

        try {
            await ApiBaseHelper.post({
              url: `conversation/${groupConversation.id}/participant?object=itc`,
              headers: {
                Authorization: "Bearer " + tech?.token,
              },
              payload: {
                Title: groupConversation.group,
                Participants: [
                    // ...groupConversation.participants,
                    member?.objectID
                ]
              }
            });
          } catch (error) {
            console.error(error)                
        }

        setAccepting(false) 
        notification.success({
            message: 'TecMe',
            description: 'Request accepted. Technician will be added to your group chat'
        })
        fetchTechTeam()
        setAcceptModal(false)
    }
  

    const handleAcceptRequest = (memberItcId, member) => {
        setAccepting(true)
        ApiBaseHelper.put({
            url: `itc/member/${memberItcId}?type=join`,	
            headers: {
                Authorization: 'Bearer ' + tech?.token,
            },
            payload: {
                Status: 'Active'
            }
        }).then((res) => {
            if(res){
                if(groupConversation){
                    addMemberToGroupConverstation(member)
                }else{
                    setAccepting(false)
                    notification.success({
                        message: 'TecMe',
                        description: 'Request accepted. Technician will be added to your group chat'
                    })
                    fetchTechTeam()
                    setAcceptModal(false)
                }   
            }
        }).catch((err) => {
            notification.error({
                message: 'TecMe',
                description: 'An error occurred while accepting request'
            })
            setAccepting(false)
            console.log(err)
        })
    }


    const handleRejectRequest = (memberItcId) => {
        setRejecting(true)
        ApiBaseHelper.put({
            url: `itc/member/${memberItcId}`,	
            headers: {
                Authorization: 'Bearer ' + tech?.token,
            },
            payload: {
                Status: 'Rejected'
            }
        }).then((res) => {
            if(res){
                setRejecting(false)
                notification.success({
                    message: 'TecMe',
                    description: 'Request rejected successfully'
                })
                fetchTechTeam()   
            }
        }).catch((err) => {
            notification.error({
                message: 'TecMe',
                description: 'An error occurred while rejecting request'
            })
            setRejecting(false)
            console.log(err)
        })
    }



    useEffect(() => {  
        fetchTechProfiles()
    }, [])

    useEffect(() => {
        if(itcDetails?.Company_Name){
            getAndSetCoversation()
        }
    }, [itcDetails])

    useEffect(() => {
        setAppliedTechs(techProfiles.filter((profile) => {
            return techTeam.some((member) => (member.Member_Contact === profile.objectID && member.Status === "Applied"))
        }))
    },[techTeam, techProfiles])

    return (
        <CustomRenderEmpty
            description={"No request found."} 
        > 
            <List
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 2,
                    lg: 2,
                    xl: 3,
                    xxl: 3
                }}
                // className="tech-team-list-ctn"
                dataSource={appliedTechs}
                loading={loadingTechProfiles}
                pagination={{ pageSize: 10 }}
                renderItem={item => (
                    <List.Item>
                        <div className="tech-team-item" key={item?.objectID}>
                            <div 
                                className="tech-team-tech-info"
                                style={{cursor:"pointer"}}
                                onClick={() => {window.open(
                                    `${ROUTES.TECH_PROFILE_WITH_ID.replace(":id", `${item.objectID}`)}`, '_blank'
                                )
                                }}
                            >
                                <Space size={12}>
                                    <Avatar 
                                        size={74} 
                                        src={item?.image}
                                        shape='square'
                                        style={{color:SECONDARY_COLOR}}
                                    >
                                        {item?.name?.split(" ")[0][0]}{item?.name?.split(" ")[1][0].toUpperCase()}
                                    </Avatar>
                                    <div className="tech-team-tech-details">
                                        <Paragraph style={{margin:0, color:"#000", fontSize:18, fontWeight:500}}>{item?.name}</Paragraph>
                                        <Space size={3}>
                                            <EnvironmentOutlined style={{marginTop:4}}/>
                                            <Paragraph  style={{margin:0, color:"#000"}}>{item?.city}{item?.state ? `, ${item?.state}` : ""}</Paragraph>
                                        </Space>
                                    { 
                                        item?.rating ?
                                        <div className="rating-label">  
                                            <Paragraph className="rating-text">{Math.round(item?.rating)}</Paragraph>
                                            <Rate disabled defaultValue={Math.round(item?.rating)} count={1} style={{color:SECONDARY_COLOR, fontSize:14, marginTop:4}} />
                                            {/* <Paragraph className="reviews-text">({item?.data?.Total_Reviews})</Paragraph> */}
                                        </div> :
                                        <div className="no-rating-label"></div>
                                        }
                                    </div>
                                </Space>
                            </div>
                            <div className="tech-team-item-summary">
                                <Paragraph ellipsis={{rows:2}} style={{fontWeight:300, color:"#000", margin:0, fontSize:14}}>{item?.bio}</Paragraph>
                                <div className='summary-btn-ctn'>
                                    <Button 
                                        type='secondary'
                                        className='summary-btn'  
                                        onClick={() => {
                                          setRejectModal(true)
                                        }}  
                                    >
                                        Reject
                                    </Button>
                                    <Button 
                                        type='primary'
                                        className='summary-btn'  
                                        onClick={() => {
                                           setAcceptModal(true)
                                        }}
                                    >
                                        Accept
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {/* Accept Modal */}
                        <ActionModal
                            visible={acceptModal}
                            handleCancel={() => setAcceptModal(false)}
                            handleClick={() => { 
                                handleAcceptRequest(
                                techTeam?.find((member) => member.Member_Contact === item.objectID)?.id, 
                                item
                            )}}
                            message={"This technician will become the member of your ITSC and be added to the team's group conversation"}
                            loading={accepting}
                            deleteText={"Confirm"}
                        />
                        {/* Reject Modal */}
                         <ActionModal
                            visible={rejectModal}
                            handleCancel={() => setRejectModal(false)}
                            handleClick={() => {
                                handleRejectRequest(
                                    techTeam?.find((member) => member.Member_Contact === item.objectID)?.id, 
                                )
                            }}
                            message={"This technician will be rejected from your team."}
                            loading={rejecting}
                            deleteText={"Confirm"}
                        />
                    </List.Item>
                )}
            />

        </CustomRenderEmpty>
    )
}

export default JoinRequests