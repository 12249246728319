import React, { useEffect, useState } from 'react'
import { Avatar, Button, Space, Typography, Upload, message } from 'antd'
import { UploadOutlined, UserOutlined } from '@ant-design/icons'
import { getBase64 } from '../../../../../../utils/helper'
import { SketchPicker } from 'react-color'
import ImgCrop from 'antd-img-crop';

const { Title, Paragraph } = Typography

const Customization = ({handleNextClick, companyDetails, setCompanyDetails, logoFile, setLogoFile}) => {


    return (
        <div className='customization-section'>
            <div className="customization-ctn">
                <div className="customization-form-ctn">
                    <CustomizationForm
                        companyDetails={companyDetails}
                        setCompanyDetails={setCompanyDetails}
                        setLogoFile={setLogoFile}
                    />
                    <Button className='itc-next-btn' type='primary' htmlType='submit' onClick={() => handleNextClick()}>Next</Button>
                </div>
            </div>
        </div>
    )
}

export const CustomizationForm = ({companyDetails, setCompanyDetails, setLogoFile}) => {
    const [profileUpdateMessage, setProfileUpdateMessage] = useState("")
    const [logoUrl, setLogoUrl] = useState();
  

    const handleChangeComplete = (color) => {
        setCompanyDetails({
            ...companyDetails,
            brand_color: color.hex
        })
    };

    const props = {
        accept: 'image/*',
        multiple: false,
        showUploadList: false,
        beforeUpload: (file) => {
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                setProfileUpdateMessage('Image must smaller than 5MB!');
                message.error('Image must smaller than 5MB!');
            } else {
                getBase64(file, imageUrl =>{
                    setLogoUrl(imageUrl)
                    setCompanyDetails({
                        ...companyDetails,
                        brand_logo: imageUrl
                    })
                });
                setLogoFile(file)
                setProfileUpdateMessage(file.name)
            }
            return false;
        },
        onDrop(e) {
            setLogoFile(e.dataTransfer.files[0]);
        },
        onRemove: (file) => {
            setLogoFile(null);
            return true;
        }
    };

    useEffect(() => {
        setLogoUrl(companyDetails?.brand_logo)
    }, [])

    useEffect(() => {
        setLogoUrl(companyDetails?.brand_logo)
    }, [companyDetails?.brand_logo])

    return(
        <>
            <Title level={4} className="itc-form-title">Business Logo</Title>
            <div className="brand-logo">
                <Space size={21}>
                    <Avatar 
                        size={81} 
                        src={logoUrl} 
                        style={{
                            background:"#FFFFFF", 
                            border:"1px solid #F04A22",
                            color:"#F04A22", 
                            fontSize:20}}
                    >
                        {companyDetails?.name?.charAt(0).toUpperCase() || "T"}
                    </Avatar>
                    <div>
                        <Paragraph style={{color: "rgba(0, 0, 0, 0.85)", fontWeight:500}}>Logo</Paragraph>
                        <Space size={16}>
                            <ImgCrop 
                                modalTitle='Crop Image (Resize using the zoom slider)'
                                rotate
                                aspect={2 / 1}
                                grid
                                minZoom={0.1}
                                zoom={true}
                                cropperProps={{ restrictPosition: false }}
                            >
                                <Upload {...props}>
                                    <Button style={{height:40}} type='secondary' icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                            </ImgCrop>
                            <Button style={{height:40}} type='primary' onClick={() => { 
                                setLogoUrl(null)
                                setLogoFile(null)
                                setCompanyDetails({
                                    ...companyDetails,
                                    brand_logo: null,
                                    logo_file: null
                                })
                                }} 
                                disabled={logoUrl === null || logoUrl === undefined}>
                                    Remove
                            </Button>
                        </Space>
                    </div>
                </Space>
            </div>
            <div className="brand-color">
                <Title level={4} className="itc-form-title">Brand Color</Title>
                <div className="color-pallete">
                    <div className="color-pallete-item" 
                        style={{backgroundColor: companyDetails?.brand_color}}
                    >
                        <p className="color-code">{companyDetails?.brand_color}</p>
                        <p>Selected Brand Color</p>
                    </div>
                    <SketchPicker
                        color={ companyDetails?.brand_color } 
                        onChangeComplete={ handleChangeComplete }
                    />
                </div>
            </div>
        </>
    )
}

export default Customization